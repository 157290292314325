import React from "react"

export const TiresFull = React.forwardRef<
	SVGSVGElement,
	React.ComponentProps<"svg">
>((props, ref) => {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			viewBox="0 0 1470.39 2276.88"
			preserveAspectRatio="xMinYMin"
			xmlSpace="preserve"
			ref={ref}
			{...props}
		>
			<g>
				<path d="M810.63,2250.51s.06,.07,.05,.12l-.53,4.45c-.01,.1-.13,.15-.21,.09l-.61-.43c-.19-.14-.46-.1-.61,.09l-.39,.52c-.14,.19-.1,.46,.09,.6l1.44,1.01s.06,.07,.05,.12l-.24,4.48c0,.1-.12,.15-.2,.1l-.63-.41c-.2-.13-.47-.08-.6,.12l-.36,.54c-.13,.2-.07,.46,.13,.59l1.5,.95s.06,.07,.06,.11c-.06,1.49-.21,2.98-.15,4.48,0,.1-.11,.16-.2,.11l-.66-.38c-.21-.12-.47-.05-.59,.15l-.32,.56c-.12,.2-.04,.47,.17,.59l1.56,.88s.07,.06,.07,.11l.05,4.48c0,.1-.11,.16-.2,.12l-.66-.35c-.21-.11-.47-.03-.59,.18l-.32,.57c-.12,.21-.04,.47,.17,.58l1.09,.57-5.93,.27,1.07-.68c.21-.13,.27-.4,.15-.6l-.34-.56c-.12-.21-.39-.27-.59-.14l-.65,.41c-.09,.05-.2,0-.2-.11l-.06-4.7s.02-.09,.06-.12l1.54-.91c.21-.12,.27-.39,.15-.6l-.34-.58c-.12-.21-.39-.29-.59-.17l-.65,.38c-.09,.05-.2-.01-.2-.12-.06-1.57,.08-3.13,.15-4.7,0-.05,.03-.09,.07-.11l1.6-.84c.21-.11,.3-.37,.19-.59l-.3-.59c-.11-.22-.37-.3-.58-.19l-.67,.35c-.09,.05-.2-.02-.19-.13l.26-4.69s.04-.09,.08-.11l1.65-.76c.22-.1,.32-.36,.23-.58l-.26-.6c-.09-.22-.35-.32-.57-.22l-.7,.32c-.09,.04-.19-.03-.18-.13l.56-4.67s.04-.09,.08-.11l.5-.21,5.89,.79,.43,.32Z" />
				<g>
					<path d="M801.32,2257.87l-.26,4.84c0,.09-.1,.13-.17,.08-2.68-2.24-5.24-3.47-6.64-4.02l.16-2.82,.32-2.81c.74,.35,1.74,.89,2.87,1.65,1.12,.76,2.36,1.75,3.68,3,.02,.02,.04,.05,.03,.09Z" />
					<path d="M800.92,2271.61l.06,4.85c0,.09-.09,.14-.16,.09-2.83-1.99-5.46-2.99-6.89-3.41l-.06-5.65c1.56,.56,4.26,1.79,7.01,4.04,.02,.02,.04,.05,.04,.08Z" />
					<path d="M802.04,2251.03l-.57,4.81c0,.08-.11,.12-.17,.07-2.54-2.36-5.02-3.7-6.38-4.3l.41-3.43,4.38,.59c.75,.63,1.54,1.35,2.31,2.18,.02,.02,.03,.05,.03,.09Z" />
					<path d="M793.98,2276.88l-.03-2.2c1.06,.34,2.66,.96,4.44,2l-4.41,.2Z" />
					<path d="M800.95,2264.74c-.13,1.61-.07,3.23-.05,4.84,0,.09-.09,.13-.16,.08-2.81-2.12-5.48-3.23-6.86-3.71l.3-5.65c1.52,.63,4.14,1.98,6.75,4.35,.02,.02,.04,.05,.03,.08Z" />
				</g>
				<g>
					<path d="M811.11,2263.16l.24-4.52s.02-.06,.04-.07c1.39-.96,2.84-1.62,4.12-2.08,1.27-.46,2.37-.72,3.17-.87l-.28,2.39-.12,2.4c-1.44,.29-4.1,1-7.01,2.85-.07,.04-.16,0-.16-.08Z" />
					<path d="M811.03,2276.01l-.05-4.53s.01-.06,.04-.08c2.7-2.17,5.38-3.15,6.92-3.6l.06,4.81c-1.42,.42-4.03,1.37-6.81,3.47-.07,.05-.16,0-.16-.07Z" />
					<path d="M811.48,2256.74l.54-4.5s.02-.06,.05-.07c.94-.57,1.86-1.02,2.73-1.37l4.38,.59-.35,2.92c-1.46,.22-4.17,.82-7.2,2.52-.07,.04-.16-.01-.15-.09Z" />
					<path d="M818.01,2273.93l.02,1.87-4.41,.2c1.76-1.09,3.34-1.72,4.39-2.07Z" />
					<path d="M810.95,2269.58c-.01-1.51-.08-3.02,.05-4.53,0-.03,.02-.06,.04-.08,2.84-2.04,5.58-2.9,7.15-3.28l-.26,4.8c-1.46,.35-4.04,1.19-6.82,3.16-.07,.05-.16,0-.16-.08Z" />
				</g>
			</g>
			<g>
				<path d="M816.33,2225.17s.04,.08,.03,.13l-1.36,4.28c-.03,.09-.15,.12-.22,.05l-.52-.53c-.16-.17-.44-.18-.62-.01-.17,.15-.31,.3-.46,.45-.17,.17-.16,.44,.01,.61l1.28,1.26s.05,.08,.04,.12l-1.09,4.35c-.02,.1-.15,.13-.22,.06l-.55-.51c-.17-.16-.45-.16-.61,.01l-.46,.47c-.17,.17-.16,.44,.01,.6l1.3,1.19s.05,.08,.04,.12c-.38,1.45-.57,2.93-.87,4.4-.02,.1-.14,.14-.21,.07l-.58-.49c-.18-.16-.46-.14-.61,.04l-.42,.49c-.15,.18-.13,.45,.05,.6l1.36,1.14s.05,.08,.04,.12c-.25,1.47-.6,2.93-.77,4.42-.01,.1-.13,.14-.21,.08l-.6-.46c-.19-.15-.46-.12-.6,.07l-.39,.51c-.14,.19-.1,.46,.09,.6l1,.76-5.89-.79,1.15-.48c.22-.09,.32-.35,.23-.57l-.26-.62c-.09-.23-.35-.34-.57-.25l-.69,.29c-.09,.04-.19-.04-.18-.14,.18-1.56,.54-3.09,.8-4.63,0-.05,.04-.09,.09-.1l1.69-.62c.23-.08,.34-.33,.26-.56l-.22-.63c-.08-.23-.33-.35-.55-.27l-.71,.25c-.1,.03-.19-.05-.17-.15,.31-1.54,.52-3.09,.91-4.61,.01-.05,.05-.08,.09-.1l1.73-.54c.23-.07,.37-.31,.3-.54l-.18-.63c-.07-.23-.31-.36-.54-.29l-.73,.22c-.1,.03-.19-.06-.16-.16l1.14-4.56s.05-.08,.09-.1l1.71-.47c.23-.06,.36-.3,.3-.54-.06-.22-.12-.43-.16-.64-.05-.23-.28-.38-.52-.32l-.75,.19c-.1,.03-.19-.07-.16-.16l1.42-4.48s.05-.08,.1-.09l.53-.12,5.66,1.81,.37,.39Z" />
				<g>
					<path d="M805.89,2230.78l-1.18,4.7c-.02,.08-.12,.11-.18,.04-2.22-2.67-4.51-4.33-5.78-5.11,.47-1.82,.89-3.66,1.41-5.46,1.3,.94,3.64,2.84,5.71,5.75,.02,.03,.02,.06,.02,.09Z" />
					<path d="M803,2244.22l-.44,2.38-.29,2.41c0,.08-.11,.12-.17,.06-1.26-1.24-2.51-2.22-3.61-2.98-1.08-.75-2-1.28-2.67-1.61l1.02-5.56c1.42,.83,3.85,2.51,6.12,5.21,.02,.02,.03,.06,.02,.09Z" />
					<path d="M807.76,2224.17c-.47,1.54-1.02,3.06-1.37,4.64-.02,.08-.12,.1-.18,.04-2.22-2.8-4.33-4.53-5.58-5.37l1.04-3.28,4.21,1.35c.63,.75,1.26,1.59,1.87,2.54,.02,.03,.02,.06,.01,.09Z" />
					<path d="M795.33,2248.17l.26-2.18c1,.52,2.49,1.42,4.11,2.77l-4.38-.59Z" />
					<path d="M804.26,2237.46l-.88,4.76c-.02,.08-.12,.11-.17,.05-2.37-2.58-4.75-4.14-6.06-4.87,.3-1.86,.79-3.68,1.24-5.5,1.39,.89,3.67,2.66,5.85,5.47,.02,.02,.03,.06,.02,.09Z" />
				</g>
				<g>
					<path d="M814.52,2237.71l1.1-4.4s.03-.05,.06-.07c3.17-1.38,5.96-1.64,7.62-1.65-.44,1.54-.79,3.11-1.19,4.67-1.47,.03-4.24,.26-7.45,1.56-.08,.03-.16-.03-.14-.11Z" />
					<path d="M812.25,2250.36l.27-2.25,.41-2.23s.02-.06,.05-.07c3.08-1.65,5.9-2.14,7.51-2.3l-.88,4.73c-.73,.08-1.8,.23-3.05,.57-1.23,.34-2.66,.85-4.16,1.64-.07,.04-.16-.02-.15-.1Z" />
					<path d="M816.08,2231.47c.32-1.48,.84-2.9,1.29-4.34,0-.03,.03-.05,.06-.06,1.03-.39,2.03-.67,2.95-.87l4.21,1.35-.9,2.81c-1.46-.03-4.33,.05-7.48,1.24-.08,.03-.16-.04-.14-.12Z" />
					<path d="M819.4,2249.54l-.22,1.86-4.38-.59c1.87-.77,3.51-1.11,4.59-1.27Z" />
					<path d="M813.27,2244.02l.82-4.46s.02-.05,.05-.07c3.13-1.51,6.04-1.87,7.64-1.96-.39,1.56-.82,3.11-1.06,4.69-1.46,.1-4.19,.45-7.31,1.89-.07,.03-.16-.03-.14-.1Z" />
				</g>
			</g>
			<g>
				<path d="M826.26,2201.14s.03,.09,0,.13c-.7,1.32-1.42,2.64-2.01,4.02-.04,.09-.17,.1-.22,.02l-.44-.62c-.14-.2-.41-.25-.6-.11l-.53,.37c-.19,.13-.24,.4-.1,.6l1.04,1.45s.03,.09,.01,.13l-1.87,4.09c-.04,.09-.17,.1-.23,.02l-.45-.6c-.14-.19-.41-.23-.61-.09l-.54,.38c-.2,.14-.24,.41-.1,.59l1.11,1.42s.04,.09,.02,.13l-1.62,4.19c-.04,.09-.16,.11-.22,.03l-.48-.58c-.15-.18-.42-.21-.61-.07l-.51,.41c-.19,.15-.21,.42-.06,.6l1.14,1.35s.04,.08,.02,.13c-.58,1.38-1.02,2.81-1.46,4.24-.03,.09-.15,.12-.22,.04l-.51-.56c-.16-.18-.43-.2-.61-.04l-.48,.43c-.18,.16-.19,.43-.03,.61l.84,.92-5.66-1.81,1.22-.27c.23-.05,.38-.28,.33-.52l-.14-.65c-.05-.24-.28-.39-.51-.34l-.74,.16c-.1,.02-.18-.07-.15-.17,.47-1.49,.93-2.99,1.53-4.44,.02-.04,.06-.08,.1-.09l1.78-.31c.24-.04,.4-.26,.36-.5l-.1-.65c-.04-.24-.26-.4-.5-.36l-.75,.13c-.1,.02-.18-.08-.14-.18l1.69-4.38s.06-.08,.1-.08l1.76-.25c.24-.03,.42-.25,.39-.48l-.06-.65c-.02-.24-.24-.41-.48-.38l-.76,.09c-.1,.01-.18-.09-.13-.18l1.95-4.27s.06-.07,.11-.08l1.78-.17c.24-.02,.41-.23,.39-.48l-.06-.66c-.02-.24-.23-.42-.47-.4l-.75,.07c-.1,0-.17-.1-.13-.19,.62-1.44,1.37-2.81,2.1-4.19,.02-.04,.07-.07,.11-.07l.54-.03,5.28,2.73,.29,.45Z" />
				<g>
					<path d="M815.04,2204.92c-.66,1.47-1.36,2.92-1.98,4.41-.03,.08-.14,.09-.18,.02-1.77-3.03-3.69-5.02-4.82-6.01l2.34-5.11c1.17,1.16,3.1,3.41,4.64,6.61,.01,.03,.01,.06,0,.09Z" />
					<path d="M809.86,2217.63c-.53,1.52-.99,3.07-1.49,4.6-.03,.08-.13,.1-.18,.03-2.02-2.87-4.18-4.73-5.39-5.62,.56-1.79,1.31-3.52,1.97-5.28,1.25,1.06,3.34,3.13,5.09,6.17,.02,.03,.02,.06,0,.09Z" />
					<path d="M817.97,2198.72c-.79,1.4-1.39,2.9-2.08,4.36-.04,.08-.14,.08-.18,0-1.66-3.11-3.58-5.22-4.66-6.25l1.6-3.03,3.92,2.03c.49,.84,.97,1.77,1.4,2.8,.01,.03,.01,.06,0,.09Z" />
					<path d="M801.67,2220.2l.67-2.09c.89,.69,2.2,1.83,3.54,3.44l-4.21-1.35Z" />
					<path d="M812.33,2211.23l-1.74,4.51c-.03,.08-.14,.09-.18,.02-1.87-2.94-3.93-4.88-5.09-5.82,.7-1.74,1.29-3.53,2.11-5.21,.6,.55,1.4,1.37,2.24,2.43,.86,1.07,1.81,2.41,2.66,3.98,.02,.03,.02,.06,0,.09Z" />
				</g>
				<g>
					<path d="M822.35,2213.21c.57-1.4,1.24-2.76,1.86-4.14,.01-.03,.04-.05,.07-.05,3.37-.82,6.22-.59,7.81-.34l-2.01,4.4c-1.45-.22-4.27-.48-7.61,.27-.08,.02-.15-.06-.12-.13Z" />
					<path d="M817.95,2225.31c.47-1.44,.89-2.89,1.39-4.32,.01-.03,.03-.05,.06-.06,3.34-1.08,6.22-1.08,7.84-.96-.56,1.51-1.22,2.98-1.69,4.52-1.46-.09-4.21-.09-7.48,.93-.08,.02-.15-.05-.13-.12Z" />
					<path d="M825,2207.34c.65-1.37,1.21-2.78,1.95-4.1,.01-.03,.04-.05,.07-.05,1.09-.21,2.12-.32,3.08-.36l3.92,2.03-1.39,2.62c-1.44-.28-4.16-.61-7.52,0-.08,.01-.15-.07-.11-.14Z" />
					<path d="M825.16,2225.75l-.57,1.79-4.21-1.35c1.99-.42,3.67-.47,4.77-.44Z" />
					<path d="M820.03,2219.22l1.64-4.23s.03-.05,.06-.06c1.65-.49,3.17-.71,4.51-.77,1.35-.06,2.48,.02,3.29,.12-.72,1.45-1.21,2.99-1.81,4.48-1.46-.16-4.23-.28-7.57,.6-.08,.02-.15-.05-.12-.13Z" />
				</g>
			</g>
			<g>
				<path d="M839.97,2179s.01,.09-.02,.13c-.93,1.18-1.72,2.46-2.58,3.68-.06,.08-.18,.07-.22-.02l-.33-.68c-.1-.22-.36-.31-.57-.21l-.59,.28c-.21,.1-.3,.36-.2,.57l.78,1.6s.02,.09,0,.13c-.83,1.25-1.73,2.45-2.5,3.74-.05,.08-.18,.08-.22-.01l-.36-.67c-.11-.21-.37-.3-.58-.19l-.57,.3c-.21,.11-.28,.37-.17,.59l.86,1.57s.02,.09,0,.13l-2.33,3.85c-.05,.08-.18,.08-.23,0l-.37-.65c-.12-.21-.38-.28-.59-.17l-.58,.32c-.21,.12-.28,.38-.17,.58l.89,1.52s.02,.09,0,.13l-2.1,3.97c-.05,.09-.17,.09-.23,0l-.41-.63c-.13-.2-.39-.26-.6-.14l-.55,.34c-.2,.13-.26,.39-.13,.59l.67,1.04-5.28-2.73,1.25-.06c.24-.01,.43-.21,.42-.45l-.02-.66c0-.24-.21-.43-.45-.42l-.76,.03c-.1,0-.17-.1-.12-.19l2.2-4.15s.07-.07,.12-.07h1.81c.24,0,.44-.2,.45-.44v-.66c.02-.24-.17-.44-.41-.44h-.76c-.1,0-.16-.11-.11-.2l2.43-4.01s.07-.07,.12-.06l1.78,.04c.24,0,.44-.18,.44-.43l.02-.67c0-.24-.18-.45-.42-.46l-.75-.02c-.1,0-.16-.12-.11-.2,.81-1.34,1.74-2.59,2.61-3.89,.03-.04,.07-.06,.12-.06l1.79,.12c.24,.02,.45-.16,.47-.4l.05-.66c.02-.24-.16-.45-.4-.47l-.76-.06c-.1,0-.15-.12-.1-.21,.9-1.28,1.73-2.61,2.69-3.84,.03-.04,.08-.06,.12-.05l.54,.06,4.78,3.53,.22,.49Z" />
				<g>
					<path d="M828.31,2180.96l-1.36,1.99-1.25,2.06c-.04,.07-.15,.06-.18-.01-.64-1.64-1.38-3.05-2.07-4.18-.68-1.13-1.31-1.98-1.78-2.56l3.16-4.62c.97,1.33,2.51,3.84,3.5,7.23,0,.03,0,.06-.01,.09Z" />
					<path d="M821.18,2192.66l-2.26,4.27c-.04,.08-.15,.07-.18,0-1.5-3.15-3.31-5.33-4.35-6.41l1.32-2.48c.46-.81,.96-1.6,1.44-2.41,.53,.62,1.22,1.53,1.93,2.68,.36,.58,.72,1.22,1.06,1.92,.36,.71,.72,1.49,1.05,2.32,.01,.03,0,.06,0,.09Z" />
					<path d="M832.18,2175.31l-2.73,3.98c-.05,.07-.15,.06-.18-.02-1.12-3.31-2.67-5.69-3.58-6.88,.64-.94,1.28-1.89,1.99-2.78l3.56,2.62c.36,.91,.65,1.88,.95,2.99,0,.03,0,.06-.02,.09Z" />
					<path d="M812.64,2193.8l1.02-1.93c.76,.82,1.85,2.16,2.9,3.96l-3.92-2.03Z" />
					<path d="M824.65,2186.75l-2.5,4.13c-.04,.07-.15,.07-.18,0-1.34-3.19-3.05-5.43-4.04-6.54l2.9-4.8c1.03,1.3,2.68,3.76,3.83,7.13,.01,.03,0,.06,0,.09Z" />
				</g>
				<g>
					<path d="M834.23,2190.34l1.18-1.95,1.28-1.88s.04-.04,.08-.04c3.48-.26,6.27,.43,7.81,.92l-2.75,4.01c-.7-.23-1.74-.52-3.02-.72-1.26-.19-2.78-.31-4.48-.2-.08,0-.14-.08-.1-.15Z" />
					<path d="M827.85,2201.55l2.12-4.02s.04-.04,.07-.05c.85-.14,1.65-.22,2.42-.25,.78-.02,1.52,0,2.19,.06,1.35,.1,2.46,.32,3.25,.51-.42,.69-.85,1.38-1.25,2.08l-1.13,2.14c-1.43-.34-4.16-.79-7.57-.33-.08,.01-.14-.07-.11-.14Z" />
					<path d="M837.77,2184.94l2.57-3.75s.04-.04,.07-.04c1.07-.06,2.15,.02,3.09,.12l3.55,2.62c-.62,.77-1.18,1.59-1.73,2.41-1.38-.5-4.03-1.27-7.46-1.21-.08,0-.13-.09-.09-.15Z" />
					<path d="M834.91,2203.19l-.88,1.67-3.92-2.03c2.05-.08,3.72,.15,4.81,.36Z" />
					<path d="M830.89,2195.86l2.35-3.89s.04-.04,.07-.05c3.44-.42,6.23,.15,7.78,.59l-2.51,4.16c-1.42-.4-4.14-.97-7.6-.66-.08,0-.14-.08-.1-.15Z" />
				</g>
			</g>
			<g>
				<path d="M856.96,2159.22s0,.09-.03,.13l-3.11,3.25c-.07,.07-.19,.04-.22-.05l-.22-.72c-.07-.23-.31-.36-.54-.29l-.63,.18c-.23,.07-.36,.3-.29,.53l.52,1.69s0,.09-.03,.12c-1.06,1.06-2.02,2.22-3,3.35-.06,.07-.19,.05-.22-.05l-.25-.71c-.08-.23-.32-.35-.55-.27l-.61,.21c-.22,.08-.34,.32-.26,.55l.6,1.68s0,.09-.02,.13l-2.94,3.41c-.07,.07-.18,.06-.22-.04l-.27-.7c-.08-.22-.33-.34-.56-.26-.21,.07-.4,.15-.6,.23-.22,.09-.32,.34-.23,.56l.67,1.66s.01,.09-.02,.13l-2.74,3.57c-.06,.08-.19,.06-.23-.03l-.3-.69c-.09-.22-.35-.32-.57-.23l-.61,.25c-.22,.09-.32,.34-.23,.56l.49,1.13-4.78-3.53,1.25,.14c.24,.03,.46-.14,.49-.38l.09-.65c.03-.24-.14-.46-.38-.48l-.76-.09c-.1-.01-.15-.13-.09-.21l2.86-3.71s.08-.06,.12-.05l1.76,.25c.24,.03,.45-.13,.49-.37,.03-.22,.06-.45,.1-.66,.04-.23-.11-.46-.35-.5l-.76-.12c-.1-.01-.15-.14-.08-.21l3.05-3.55s.08-.05,.12-.05l1.76,.32c.24,.04,.46-.11,.51-.35l.13-.65c.05-.24-.1-.47-.34-.51l-.74-.14c-.1-.02-.14-.14-.07-.22,1.02-1.18,2.02-2.38,3.12-3.49,.03-.03,.08-.05,.13-.04l1.76,.4c.23,.05,.47-.09,.53-.32l.16-.64c.06-.23-.08-.47-.32-.52l-.74-.17c-.1-.02-.13-.14-.06-.22l3.24-3.38s.08-.05,.13-.04l.52,.13,4.2,4.2,.15,.52Z" />
				<g>
					<path d="M845.1,2159.37l-3.15,3.65c-.06,.06-.16,.04-.18-.04-.75-3.43-2.02-5.96-2.79-7.25,1.2-1.43,2.54-2.71,3.81-4.07,.75,1.46,1.89,4.16,2.34,7.64,0,.03,0,.06-.03,.09Z" />
					<path d="M836.32,2169.86l-2.94,3.82c-.05,.07-.16,.05-.18-.03-.97-3.33-2.41-5.76-3.27-6.98,1.14-1.47,2.26-2.97,3.43-4.41,.84,1.38,2.23,4.03,2.98,7.51,0,.03,0,.06-.02,.09Z" />
					<path d="M849.83,2154.43l-3.33,3.48c-.06,.06-.16,.03-.17-.05-.6-3.43-1.76-6-2.48-7.31l2.36-2.46,3.12,3.12c.22,.95,.41,2,.53,3.13,0,.03,0,.06-.03,.09Z" />
					<path d="M827.68,2169.61l1.32-1.72c.62,.93,1.48,2.41,2.23,4.35l-3.56-2.62Z" />
					<path d="M840.63,2164.55c-1.04,1.23-2.12,2.41-3.08,3.71-.05,.07-.15,.05-.18-.04-.93-3.44-2.21-5.86-3.03-7.11l3.64-4.24c.81,1.43,2.06,4.1,2.66,7.59,0,.03,0,.06-.02,.09Z" />
				</g>
				<g>
					<path d="M849.57,2169.58l2.98-3.45s.05-.03,.08-.03c3.5,.29,6.17,1.39,7.62,2.12-1.11,1.19-2.3,2.31-3.34,3.56-1.31-.65-3.86-1.71-7.27-2.04-.08,0-.12-.1-.07-.16Z" />
					<path d="M841.47,2179.66l2.78-3.61s.05-.04,.08-.04c3.46-.02,6.15,.85,7.67,1.48-1.02,1.26-1.98,2.57-2.98,3.85-1.37-.56-4-1.44-7.46-1.53-.08,0-.13-.09-.08-.16Z" />
					<path d="M853.86,2164.76l3.15-3.29s.05-.03,.08-.03c1.09,.11,2.09,.31,3,.55l3.12,3.12-2.06,2.16c-1.29-.7-3.81-1.87-7.23-2.34-.08-.01-.12-.11-.06-.17Z" />
					<path d="M848.21,2182.39l-1.16,1.5-3.56-2.62c2.04,.24,3.67,.74,4.71,1.12Z" />
					<path d="M845.41,2174.54c.89-1.23,1.93-2.35,2.91-3.5,.02-.02,.05-.04,.08-.03,3.48,.13,6.17,1.13,7.65,1.79l-3.18,3.7c-1.33-.6-4-1.64-7.37-1.79-.08,0-.13-.1-.08-.16Z" />
				</g>
			</g>
			<g>
				<path d="M876.7,2142.11s-.01,.09-.05,.12l-3.59,2.75c-.08,.06-.2,.01-.21-.09l-.11-.74c-.03-.23-.25-.4-.49-.37l-.65,.09c-.24,.03-.4,.25-.37,.48l.29,1.76s0,.09-.04,.12l-3.44,2.91c-.08,.06-.19,.02-.21-.08l-.14-.74c-.04-.23-.27-.39-.5-.35l-.64,.11c-.23,.04-.39,.26-.34,.5l.34,1.74s0,.09-.04,.12l-1.72,1.45-1.64,1.54c-.07,.07-.19,.03-.21-.07l-.17-.74c-.05-.23-.28-.38-.51-.33l-.63,.14c-.23,.05-.37,.28-.32,.52l.41,1.74s0,.09-.04,.12l-3.28,3.08c-.07,.07-.19,.03-.22-.06l-.19-.72c-.06-.23-.3-.37-.53-.32l-.64,.15c-.24,.05-.37,.29-.31,.52l.34,1.21-4.2-4.2,1.2,.31c.23,.06,.47-.08,.54-.31l.19-.63c.07-.23-.06-.47-.3-.53l-.74-.2c-.1-.03-.13-.15-.05-.22l3.41-3.21s.08-.04,.13-.03l1.71,.51c.23,.07,.47-.06,.54-.29l.2-.64c.07-.23-.05-.48-.28-.55l-.72-.22c-.1-.03-.12-.15-.05-.22l1.71-1.6,1.79-1.51s.09-.04,.13-.03l1.7,.59c.23,.08,.48-.04,.56-.26l.23-.62c.08-.23-.03-.48-.26-.56l-.72-.25c-.1-.03-.12-.16-.04-.22l3.58-3.02s.09-.04,.13-.02l1.67,.64c.23,.09,.48-.02,.57-.24l.25-.61c.09-.22-.02-.47-.24-.56l-.71-.28c-.09-.04-.11-.16-.03-.22l3.7-2.84s.09-.04,.13-.02l.5,.21,3.58,4.74,.07,.53Z" />
				<g>
					<path d="M864.95,2140.58l-3.68,3.11c-.06,.05-.16,.01-.17-.07-.22-3.48-1.11-6.16-1.68-7.54,1.42-1.19,2.82-2.43,4.3-3.51,.54,1.54,1.26,4.33,1.27,7.93,0,.03-.01,.06-.04,.08Z" />
					<path d="M854.71,2149.64c-1.16,1.11-2.37,2.17-3.48,3.33-.06,.06-.16,.03-.17-.05-.51-3.49-1.53-6.04-2.2-7.39l4.06-3.82c.65,1.51,1.6,4.31,1.82,7.84,0,.03-.01,.06-.03,.08Z" />
					<path d="M870.29,2136.36l-3.8,2.92c-.06,.05-.16,.01-.16-.07-.11-3.48-.89-6.16-1.42-7.55l2.67-2.05,2.66,3.52c.09,.97,.13,2.02,.09,3.15,0,.03-.02,.06-.04,.08Z" />
					<path d="M846.2,2148.09c.49-.53,1.02-1.03,1.55-1.52,.48,1.01,1.08,2.58,1.58,4.64l-3.12-3.12Z" />
					<path d="M859.73,2145c-1.23,1.03-2.36,2.18-3.54,3.26-.06,.06-.16,.02-.17-.06-.37-3.49-1.36-6.16-1.99-7.52,1.39-1.24,2.83-2.41,4.24-3.62,.59,1.53,1.43,4.34,1.5,7.85,0,.03-.01,.06-.04,.08Z" />
				</g>
				<g>
					<path d="M867.84,2151.3l3.48-2.94s.05-.03,.08-.02c3.35,.7,5.87,2.21,7.21,3.13-1.34,.98-2.55,2.06-3.8,3.11-1.21-.84-3.58-2.27-6.93-3.11-.08-.02-.11-.12-.05-.17Z" />
					<path d="M858.34,2160.08c1.05-1.1,2.2-2.1,3.29-3.15,.02-.02,.05-.03,.08-.03,3.44,.51,6.01,1.79,7.4,2.61l-3.56,3.35c-1.26-.74-3.79-2.06-7.15-2.61-.08-.01-.12-.11-.06-.17Z" />
					<path d="M872.79,2147.13l3.63-2.79s.06-.02,.09-.02c1.07,.27,2.05,.61,2.92,.97l2.66,3.52-2.4,1.84c-1.18-.88-3.54-2.4-6.86-3.35-.08-.02-.1-.12-.04-.18Z" />
					<path d="M864.58,2163.78c-.47,.43-.93,.86-1.35,1.33l-3.12-3.12c1.94,.5,3.51,1.26,4.48,1.79Z" />
					<path d="M863.03,2155.63c1.12-1.02,2.18-2.12,3.35-3.09,.02-.02,.05-.03,.08-.02,3.45,.66,5.98,2.04,7.34,2.92-1.24,1.06-2.51,2.09-3.72,3.17-1.23-.79-3.65-2.12-7-2.81-.08-.02-.11-.12-.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M898.45,2127.46s-.02,.09-.06,.11c-1.29,.78-2.62,1.5-3.88,2.35-.08,.05-.19,0-.2-.11l-.03-.76c-.01-.24-.21-.43-.44-.42l-.65,.03c-.24,0-.42,.21-.41,.45l.08,1.79s-.02,.09-.06,.12l-3.81,2.45c-.08,.05-.2,0-.2-.1l-.05-.75c-.01-.24-.22-.42-.46-.41l-.66,.03c-.24,.01-.42,.21-.41,.45l.12,1.76s-.02,.09-.05,.12l-3.7,2.6c-.08,.06-.19,0-.2-.1l-.07-.75c-.02-.24-.23-.41-.47-.39l-.65,.06c-.24,.02-.41,.23-.39,.47l.17,1.77s-.02,.09-.05,.12c-1.23,.87-2.48,1.71-3.66,2.65-.08,.06-.19,.01-.21-.09l-.09-.75c-.03-.24-.24-.41-.48-.38l-.64,.08c-.23,.03-.4,.25-.37,.48l.16,1.24-3.58-4.74,1.15,.48c.22,.09,.47-.01,.57-.24l.26-.61c.09-.22,0-.48-.23-.57l-.69-.29c-.09-.04-.11-.17-.03-.23,1.22-.97,2.51-1.84,3.77-2.73,.04-.03,.09-.03,.13-.01l1.64,.74c.22,.1,.48,0,.58-.21l.28-.6c.1-.22,0-.48-.21-.58l-.69-.32c-.09-.04-.1-.17-.02-.23l3.81-2.68s.09-.03,.13-.01l1.62,.8c.22,.11,.48,.02,.59-.19l.3-.58c.11-.21,.02-.47-.19-.58l-.68-.34c-.09-.05-.1-.17-.01-.23l3.91-2.52s.09-.03,.13,0l1.59,.82c.21,.11,.47,.03,.59-.19l.31-.59c.11-.21,.03-.48-.18-.59l-.67-.35c-.09-.05-.09-.17,0-.23,1.29-.86,2.66-1.6,3.98-2.41,.04-.02,.09-.03,.13,0l.48,.26,3.05,5.1v.54Z" />
				<g>
					<path d="M886.95,2124.64l-4.02,2.59c-.07,.05-.16,0-.16-.09,.2-3.46-.38-6.21-.79-7.64l4.61-2.97c.38,1.6,.83,4.51,.4,8.03,0,.03-.02,.06-.05,.08Z" />
					<path d="M875.72,2132.28c-1.32,.89-2.57,1.9-3.84,2.86-.07,.05-.16,0-.16-.08-.02-3.5-.76-6.25-1.25-7.66l4.5-3.16c.46,1.57,1.05,4.44,.8,7.95,0,.03-.02,.06-.04,.08Z" />
					<path d="M892.7,2121.03c-1.38,.79-2.72,1.66-4.06,2.53-.07,.05-.16,0-.16-.09,.26-3.5-.26-6.29-.62-7.71l2.87-1.7,2.27,3.79c-.02,.97-.09,2.01-.26,3.12,0,.03-.02,.06-.05,.07Z" />
					<path d="M867.58,2129.61l1.7-1.3c.34,1.07,.77,2.74,.96,4.83l-2.66-3.52Z" />
					<path d="M881.28,2128.37l-3.92,2.75c-.07,.05-.16,0-.16-.08,.09-3.48-.56-6.23-1.01-7.65l2.25-1.58c.75-.52,1.53-1,2.3-1.5,.42,1.6,.89,4.41,.59,7.98,0,.03-.02,.06-.04,.08Z" />
				</g>
				<g>
					<path d="M888.54,2135.59l3.86-2.49s.06-.02,.09-.01c3.3,1.12,5.6,2.83,6.83,3.89l-4.19,2.7c-1.11-.98-3.32-2.67-6.57-3.91-.08-.03-.09-.13-.02-.18Z" />
					<path d="M877.94,2143.16c1.22-.92,2.4-1.88,3.68-2.74,.02-.02,.06-.02,.09-.01,3.37,.97,5.77,2.57,7.05,3.56l-4.07,2.85c-1.16-.91-3.44-2.46-6.71-3.49-.08-.02-.1-.13-.04-.18Z" />
					<path d="M894.02,2132.06c1.28-.83,2.57-1.67,3.9-2.43,.03-.02,.06-.02,.09,0,1.04,.39,1.98,.83,2.82,1.3l2.27,3.79-2.63,1.55c-1.1-1.02-3.24-2.74-6.43-4.03-.08-.03-.09-.13-.02-.18Z" />
					<path d="M883.62,2147.64l-1.53,1.17-2.66-3.52c1.88,.79,3.3,1.7,4.19,2.35Z" />
					<path d="M883.2,2139.32l3.76-2.64s.06-.02,.09-.01c3.29,.99,5.7,2.73,6.95,3.74-.69,.45-1.4,.89-2.09,1.36l-2.03,1.43c-1.13-.94-3.38-2.57-6.64-3.7-.08-.03-.1-.13-.03-.18Z" />
				</g>
			</g>
			<g>
				<path d="M921.6,2115.02s-.03,.09-.07,.11l-4.06,2.02c-.09,.04-.19-.03-.19-.13l.04-.75c.01-.24-.17-.44-.4-.46l-.65-.04c-.24-.01-.44,.17-.45,.41l-.09,1.78s-.03,.09-.07,.11c-1.35,.69-2.72,1.33-4.04,2.06-.09,.05-.19-.02-.19-.12l.02-.76c0-.24-.18-.44-.41-.45l-.65-.02c-.24,0-.44,.18-.44,.42l-.05,1.79s-.03,.09-.07,.11l-3.98,2.17c-.09,.05-.2-.02-.19-.12v-.75c.01-.24-.18-.44-.42-.44l-.66-.02c-.24,0-.44,.18-.44,.42l-.02,1.77s-.03,.09-.07,.11l-3.9,2.3c-.09,.05-.19-.01-.2-.11v-.75c-.01-.24-.21-.43-.45-.43h-.65c-.24,0-.43,.2-.42,.44l.02,1.24-3.05-5.1,1.09,.61c.21,.12,.48,.04,.59-.17l.33-.57c.12-.21,.05-.47-.16-.59l-.66-.37c-.09-.05-.09-.18,0-.23l4.01-2.36s.09-.02,.13,0l1.55,.92c.21,.12,.48,.06,.6-.15l.34-.56c.12-.2,.06-.47-.15-.59l-.65-.39c-.09-.05-.08-.18,0-.23l4.08-2.22s.09-.02,.13,0l1.52,.93c.2,.13,.47,.06,.6-.14l.35-.56c.13-.21,.07-.47-.14-.6l-.64-.4c-.09-.05-.08-.18,0-.23,1.35-.75,2.76-1.4,4.14-2.11,.04-.02,.09-.02,.13,0l1.5,.98c.2,.13,.47,.08,.6-.12l.37-.55c.13-.2,.08-.47-.12-.6l-.63-.42c-.08-.06-.08-.18,.01-.23l4.16-2.07s.09-.02,.13,.01l.45,.31,2.56,5.36-.04,.53Z" />
				<g>
					<path d="M910.44,2111.12c-1.43,.69-2.83,1.44-4.22,2.21-.07,.04-.16-.02-.15-.1,.51-3.47,.2-6.28-.07-7.72l4.87-2.44c.22,1.62,.39,4.55-.38,7.98,0,.03-.03,.06-.06,.07Z" />
					<path d="M898.53,2117.58l-4.11,2.43c-.07,.04-.16-.01-.15-.1,.36-3.47-.08-6.25-.42-7.7l2.35-1.39,2.39-1.31c.3,1.62,.57,4.47-.01,7.99,0,.03-.02,.06-.05,.07Z" />
					<path d="M916.49,2108.09l-4.26,2.13c-.08,.04-.16-.03-.15-.11,.6-3.43,.35-6.24,.11-7.71,1-.49,1.97-1.01,2.98-1.47l1.9,3.99c-.11,.95-.28,1.99-.54,3.09,0,.03-.03,.06-.06,.07Z" />
					<path d="M890.74,2114.05l1.83-1.08c.23,1.1,.47,2.8,.44,4.87l-2.27-3.79Z" />
					<path d="M904.45,2114.3l-4.19,2.28c-.07,.04-.16-.02-.15-.1,.45-3.44,.08-6.22-.22-7.68l4.79-2.61c.26,1.62,.5,4.55-.18,8.03,0,.03-.03,.06-.05,.07Z" />
				</g>
				<g>
					<path d="M910.95,2122.21c1.34-.74,2.69-1.47,4.08-2.14,.03-.01,.06-.01,.09,0,3.19,1.45,5.33,3.39,6.46,4.56l-4.49,2.25c-1.02-1.09-3.05-2.98-6.13-4.49-.07-.04-.08-.14,0-.18Z" />
					<path d="M899.58,2128.64l3.96-2.34s.06-.02,.09,0c3.22,1.26,5.49,3.15,6.66,4.26l-2.2,1.2-2.15,1.27c-1.06-1.03-3.18-2.82-6.34-4.2-.08-.03-.08-.14-.02-.18Z" />
					<path d="M916.76,2119.21l4.12-2.06s.06-.01,.09,0c.99,.47,1.88,.99,2.68,1.54l1.9,3.99c-.94,.42-1.83,.91-2.75,1.35-.99-1.1-2.98-3.03-6.03-4.63-.07-.04-.08-.14,0-.18Z" />
					<path d="M904.76,2133.72l-1.67,.99-2.27-3.79c1.8,.99,3.12,2.06,3.94,2.8Z" />
					<path d="M905.21,2125.34l4.04-2.2s.06-.02,.09,0c3.21,1.36,5.39,3.23,6.53,4.37l-4.4,2.4c-1.04-1.05-3.12-2.91-6.26-4.39-.07-.04-.08-.14-.01-.18Z" />
				</g>
			</g>
			<g>
				<path d="M945.81,2104.7s-.04,.09-.08,.1l-4.24,1.64c-.09,.04-.19-.04-.18-.14l.11-.74c.03-.24-.13-.46-.36-.49l-.65-.1c-.24-.04-.46,.12-.49,.36l-.25,1.76s-.04,.08-.08,.1l-4.19,1.76c-.09,.04-.19-.04-.18-.14l.09-.75c.03-.24-.14-.45-.37-.48l-.65-.08c-.24-.03-.45,.14-.48,.38l-.21,1.77s-.03,.09-.08,.1c-1.39,.6-2.8,1.15-4.17,1.79-.09,.04-.19-.03-.18-.13l.07-.76c.02-.24-.15-.45-.38-.48l-.65-.06c-.24-.02-.45,.15-.47,.39l-.17,1.78s-.03,.09-.08,.11l-4.12,1.9c-.09,.04-.19-.03-.19-.13l.06-.75c.02-.24-.16-.45-.4-.47l-.65-.06c-.24-.02-.45,.15-.47,.39l-.1,1.23-2.56-5.36,1.04,.71c.2,.14,.47,.09,.61-.11l.38-.54c.14-.2,.09-.47-.11-.6l-.62-.43c-.08-.06-.07-.18,.02-.23l4.22-1.94s.09-.02,.13,.01l1.45,1.04c.19,.14,.46,.09,.61-.1l.39-.54c.14-.2,.1-.47-.1-.61l-.61-.44c-.08-.06-.07-.19,.02-.23,1.4-.66,2.84-1.22,4.27-1.83,.04-.02,.09-.01,.13,.02l1.44,1.07c.19,.14,.46,.11,.61-.09l.4-.53c.15-.19,.11-.46-.08-.61l-.6-.46c-.08-.06-.06-.19,.03-.23l4.28-1.8s.09,0,.13,.02l1.42,1.11c.19,.15,.46,.12,.61-.07l.41-.51c.15-.19,.12-.46-.07-.61l-.6-.47c-.08-.06-.06-.19,.03-.22l4.33-1.67s.09,0,.13,.02l.42,.34,2.1,5.55-.08,.53Z" />
				<g>
					<path d="M935.01,2099.84l-4.39,1.85c-.08,.03-.16-.04-.14-.12,.82-3.39,.75-6.2,.6-7.68l2.5-1.05,2.53-.98c.09,1.64,0,4.51-1.05,7.91,0,.03-.03,.05-.06,.07Z" />
					<path d="M922.61,2105.23l-4.33,1.99c-.08,.04-.16-.03-.14-.11,.68-3.4,.5-6.2,.29-7.67l4.94-2.27c.15,1.64,.2,4.57-.7,7.99,0,.03-.03,.06-.06,.07Z" />
					<path d="M941.29,2097.37l-4.44,1.72c-.08,.03-.16-.04-.14-.12,.89-3.35,.88-6.16,.77-7.64l3.09-1.19,1.56,4.13c-.18,.95-.43,1.97-.79,3.04,0,.03-.03,.05-.06,.06Z" />
					<path d="M915.17,2100.94l1.92-.88c.12,1.11,.21,2.82-.02,4.87l-1.9-3.99Z" />
					<path d="M928.78,2102.47c-1.47,.6-2.92,1.25-4.35,1.93-.08,.04-.16-.03-.14-.11,.74-3.43,.61-6.24,.44-7.71l5-2.11c.12,1.63,.1,4.56-.88,7.94,0,.03-.03,.05-.06,.07Z" />
				</g>
				<g>
					<path d="M934.56,2110.94l4.25-1.8s.06,0,.09,0c3.03,1.68,5.04,3.84,6.06,5.09l-2.35,.91-2.32,.98c-.92-1.16-2.78-3.22-5.73-5.01-.07-.04-.07-.15,0-.18Z" />
					<path d="M922.62,2116.3l4.19-1.93s.06-.01,.09,0c3.11,1.57,5.17,3.58,6.24,4.8l-4.57,2.1c-.96-1.12-2.92-3.11-5.95-4.8-.07-.04-.07-.14,0-.18Z" />
					<path d="M940.6,2108.42l4.31-1.67s.06,0,.09,0c.96,.56,1.8,1.16,2.54,1.75l1.56,4.13-2.87,1.11c-.89-1.18-2.72-3.28-5.64-5.16-.07-.04-.06-.15,.01-.18Z" />
					<path d="M927.32,2121.85l-1.78,.82-1.9-3.99c1.71,1.16,2.93,2.35,3.68,3.17Z" />
					<path d="M928.56,2113.56c1.39-.65,2.79-1.28,4.22-1.87,.03-.01,.06,0,.09,0,3.09,1.66,5.11,3.72,6.15,4.96l-4.64,1.96c-.95-1.16-2.85-3.17-5.83-4.88-.07-.04-.07-.15,0-.18Z" />
				</g>
			</g>
			<g>
				<path d="M970.78,2096.31s-.04,.08-.09,.09c-1.45,.43-2.92,.82-4.36,1.29-.1,.03-.19-.05-.17-.15l.16-.74c.05-.23-.09-.47-.32-.52l-.64-.14c-.23-.05-.46,.1-.51,.33l-.38,1.75s-.04,.08-.09,.1l-4.33,1.39c-.1,.03-.19-.05-.17-.15l.15-.74c.05-.23-.1-.46-.33-.51l-.64-.14c-.23-.05-.46,.1-.51,.33l-.35,1.74s-.04,.08-.09,.1l-4.29,1.51c-.09,.03-.19-.05-.17-.15l.14-.74c.04-.24-.11-.46-.34-.51l-.64-.12c-.23-.04-.46,.11-.5,.35l-.32,1.75s-.04,.08-.08,.1c-1.42,.52-2.87,.98-4.28,1.54-.09,.04-.19-.04-.17-.14l.12-.75c.04-.24-.12-.46-.35-.5l-.64-.1c-.23-.04-.46,.12-.49,.36l-.19,1.23-2.1-5.55,.97,.78c.19,.15,.46,.12,.61-.07l.42-.51c.15-.19,.13-.46-.06-.61l-.58-.48c-.08-.06-.06-.19,.04-.23,1.44-.57,2.91-1.05,4.37-1.57,.04-.02,.09,0,.13,.02l1.37,1.16c.18,.16,.46,.13,.61-.05l.43-.5c.16-.18,.14-.46-.05-.61l-.58-.49c-.08-.07-.05-.19,.04-.22l4.38-1.54s.09,0,.13,.03l1.35,1.19c.18,.16,.46,.15,.62-.03l.44-.49c.16-.18,.15-.45-.03-.61l-.57-.51c-.08-.07-.05-.19,.05-.22l4.42-1.42s.09,0,.13,.03l1.32,1.2c.18,.16,.45,.15,.61-.03l.45-.49c.16-.18,.15-.45-.02-.61l-.56-.51c-.07-.07-.05-.19,.05-.22,1.47-.48,2.97-.88,4.45-1.32,.05-.01,.09,0,.13,.03l.39,.37,1.67,5.7-.12,.52Z" />
				<g>
					<path d="M960.4,2090.64l-4.53,1.45c-.08,.03-.15-.05-.13-.13,1.09-3.29,1.24-6.09,1.21-7.58l5.15-1.65c-.04,1.64-.34,4.56-1.64,7.84-.01,.03-.04,.05-.07,.06Z" />
					<path d="M947.61,2094.99c-1.5,.51-2.98,1.07-4.46,1.66-.08,.03-.16-.04-.13-.12,.95-3.37,.99-6.19,.91-7.66l5.1-1.8c.02,1.64-.17,4.56-1.35,7.87-.01,.03-.03,.05-.06,.06Z" />
					<path d="M966.84,2088.64c-1.53,.43-3.04,.9-4.54,1.4-.08,.03-.15-.05-.13-.13,1.14-3.31,1.34-6.12,1.34-7.59l3.17-.92,1.24,4.24c-.25,.93-.59,1.92-1.02,2.95-.01,.03-.04,.05-.07,.06Z" />
					<path d="M940.58,2090.13l1.97-.76c.04,1.12-.01,2.84-.4,4.89l-1.56-4.13Z" />
					<path d="M953.98,2092.74l-4.48,1.58c-.08,.03-.16-.05-.13-.12,1.02-3.33,1.11-6.14,1.06-7.63l2.55-.9,2.58-.83c0,1.65-.27,4.51-1.51,7.84-.01,.03-.03,.05-.06,.06Z" />
				</g>
				<g>
					<path d="M959.06,2101.64l4.4-1.41s.06,0,.09,.01c2.9,1.93,4.71,4.17,5.63,5.51l-4.81,1.55c-.82-1.23-2.53-3.44-5.33-5.48-.07-.05-.05-.15,.02-.18Z" />
					<path d="M946.72,2106.05c1.43-.56,2.87-1.11,4.33-1.61,.03-.01,.06,0,.09,0,2.98,1.84,4.88,4.02,5.85,5.32l-4.76,1.68c-.88-1.21-2.66-3.34-5.52-5.23-.07-.05-.06-.15,.02-.18Z" />
					<path d="M965.3,2099.63c1.46-.48,2.93-.94,4.42-1.36,.03,0,.06,0,.09,.01,.91,.64,1.71,1.3,2.41,1.96l1.24,4.24-2.97,.86c-.8-1.26-2.46-3.49-5.22-5.54-.07-.05-.05-.15,.03-.18Z" />
					<path d="M950.93,2111.93l-1.83,.71-1.56-4.13c1.6,1.28,2.71,2.55,3.39,3.42Z" />
					<path d="M952.87,2103.8l4.35-1.54s.06,0,.09,0c2.93,1.86,4.81,4.13,5.75,5.44l-2.4,.77-2.38,.84c-.85-1.21-2.59-3.38-5.43-5.34-.07-.05-.06-.15,.02-.18Z" />
				</g>
			</g>
			<g>
				<path d="M996.32,2089.79s-.05,.08-.1,.09c-1.48,.31-2.96,.68-4.43,1.01-.1,.02-.18-.07-.15-.17l.22-.72c.07-.23-.06-.47-.29-.54l-.62-.19c-.23-.07-.47,.06-.54,.29l-.51,1.71s-.05,.08-.09,.09c-1.47,.35-2.96,.66-4.43,1.05-.1,.03-.18-.07-.16-.16l.2-.73c.06-.23-.07-.47-.29-.53l-.63-.18c-.23-.06-.47,.07-.53,.3l-.48,1.72s-.05,.08-.09,.09l-4.4,1.15c-.1,.03-.19-.06-.16-.16l.19-.73c.06-.23-.08-.47-.31-.53l-.63-.17c-.23-.06-.47,.07-.53,.3l-.45,1.72s-.05,.08-.09,.09l-4.37,1.26c-.1,.03-.19-.06-.16-.16l.18-.73c.06-.23-.08-.47-.31-.53l-.63-.16c-.23-.06-.46,.08-.52,.32l-.29,1.21-1.67-5.7,.91,.86c.17,.17,.45,.16,.62-.01l.46-.48c.17-.17,.16-.45-.01-.61l-.55-.52c-.07-.07-.04-.19,.06-.22l4.46-1.28s.09,0,.13,.03l1.28,1.27c.17,.17,.45,.17,.62,0l.47-.46c.17-.17,.17-.44,0-.61l-.54-.54c-.07-.07-.04-.19,.06-.22l4.49-1.17s.09,0,.13,.03l1.25,1.27c.17,.17,.44,.17,.61,0l.47-.46c.17-.17,.18-.44,.01-.61l-.53-.54c-.07-.07-.04-.19,.06-.22,1.5-.39,3.01-.71,4.51-1.07,.05-.01,.09,0,.13,.04l1.23,1.3c.16,.17,.44,.18,.61,.02l.48-.45c.18-.16,.19-.44,.02-.61l-.52-.55c-.07-.07-.03-.19,.07-.22,1.51-.34,3.01-.71,4.52-1.03,.05,0,.09,0,.13,.04l.36,.4,1.25,5.81-.16,.51Z" />
				<g>
					<path d="M986.38,2083.38c-1.55,.34-3.08,.73-4.61,1.14-.08,.02-.15-.06-.12-.14,1.32-3.24,1.68-6.03,1.76-7.5l5.26-1.22c-.16,1.63-.67,4.51-2.22,7.67-.01,.03-.04,.05-.07,.06Z" />
					<path d="M973.32,2086.76l-4.56,1.33c-.08,.02-.15-.05-.12-.13,1.21-3.27,1.46-6.07,1.49-7.56,1.73-.52,3.47-.99,5.21-1.43-.1,1.64-.52,4.49-1.94,7.74-.01,.03-.04,.05-.07,.06Z" />
					<path d="M992.96,2081.86l-4.63,1.07c-.08,.02-.15-.06-.12-.14,1.38-3.2,1.79-5.98,1.9-7.47,1.07-.24,2.14-.51,3.22-.72l.93,4.32c-.32,.9-.73,1.86-1.24,2.88-.01,.03-.04,.05-.07,.06Z" />
					<path d="M966.69,2081.39l2.02-.59c-.05,1.12-.23,2.82-.78,4.82l-1.24-4.24Z" />
					<path d="M979.84,2085.02l-4.6,1.2c-.08,.02-.15-.06-.12-.13,1.27-3.22,1.58-6.02,1.63-7.5l5.23-1.36c-.13,1.64-.59,4.53-2.08,7.74-.01,.03-.04,.05-.07,.06Z" />
				</g>
				<g>
					<path d="M984.24,2094.27c1.49-.4,2.98-.77,4.49-1.11,.03,0,.06,0,.09,.02,2.76,2.16,4.4,4.54,5.22,5.94l-4.93,1.14c-.73-1.3-2.27-3.62-4.9-5.82-.06-.05-.04-.16,.04-.18Z" />
					<path d="M971.58,2097.73l4.44-1.29s.06,0,.09,.01c2.82,2.02,4.57,4.39,5.44,5.75-1.63,.41-3.26,.85-4.87,1.34-.78-1.26-2.39-3.52-5.12-5.64-.06-.05-.05-.15,.03-.18Z" />
					<path d="M990.61,2092.73l4.5-1.04s.06,0,.08,.02c.86,.68,1.61,1.41,2.26,2.12l.93,4.32c-1.01,.19-2.01,.45-3.02,.67-.71-1.3-2.19-3.64-4.8-5.91-.06-.05-.04-.16,.04-.17Z" />
					<path d="M975.35,2103.94l-1.89,.55-1.24-4.24c1.5,1.41,2.52,2.77,3.13,3.69Z" />
					<path d="M977.89,2095.93l4.47-1.17s.06,0,.09,.02c2.79,2.09,4.47,4.43,5.32,5.81l-4.89,1.28c-.75-1.27-2.33-3.57-5.02-5.77-.06-.05-.04-.15,.03-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1022.33,2085.08s-.06,.08-.1,.08l-4.54,.74c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.08-.47,.03-.56,.25l-.63,1.67s-.06,.08-.1,.08c-1.52,.24-3,.52-4.49,.77-.1,.02-.18-.08-.14-.17l.26-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.08-.47,.03-.55,.26l-.6,1.68s-.05,.08-.1,.08l-4.48,.8c-.1,.02-.18-.07-.15-.17l.24-.72c.08-.23-.04-.47-.27-.55l-.62-.21c-.22-.08-.47,.05-.55,.27l-.57,1.7s-.05,.08-.1,.09l-4.46,.91c-.1,.02-.18-.07-.15-.17l.23-.72c.07-.23-.05-.47-.28-.55l-.62-.21c-.23-.08-.47,.05-.54,.27l-.38,1.18-1.25-5.81,.84,.93c.16,.18,.44,.2,.62,.04l.49-.44c.18-.16,.2-.43,.03-.61l-.51-.57c-.07-.08-.03-.2,.07-.22l4.54-.92s.09,0,.12,.04l1.18,1.34c.16,.18,.43,.2,.61,.04l.5-.44c.18-.16,.2-.43,.05-.61l-.5-.57c-.07-.08-.02-.2,.08-.22l4.57-.81s.09,0,.12,.04l1.15,1.37c.15,.18,.43,.21,.61,.05l.51-.42c.19-.15,.21-.43,.06-.61l-.49-.58c-.06-.08-.02-.2,.08-.21,1.52-.26,3.05-.55,4.56-.78,.05,0,.09,.01,.12,.05l1.13,1.39c.15,.19,.42,.21,.61,.06l.51-.42c.19-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.53-.74s.09,.01,.12,.05l.34,.42,.96,5.86-.19,.5Z" />
				<g>
					<path d="M1012.83,2078.05l-4.68,.82c-.08,.01-.15-.07-.11-.14,1.56-3.12,2.12-5.88,2.31-7.35,1.8-.33,3.48-.58,5.21-.87-.25,1.61-.9,4.44-2.65,7.49-.02,.03-.04,.05-.07,.05Z" />
					<path d="M999.56,2080.49l-4.66,.95c-.08,.02-.15-.07-.11-.14,1.45-3.15,1.91-5.92,2.04-7.4l5.3-1.08c-.22,1.63-.84,4.5-2.5,7.62-.01,.03-.04,.05-.07,.05Z" />
					<path d="M1019.4,2076.98l-4.62,.75c-.08,.01-.14-.07-.1-.15,1.57-3.09,2.11-5.84,2.29-7.31l3.15-.51,.71,4.36c-.36,.89-.8,1.84-1.35,2.8-.02,.03-.04,.05-.07,.05Z" />
					<path d="M993.33,2074.61l2.06-.42c-.13,1.11-.44,2.79-1.13,4.74l-.93-4.32Z" />
					<path d="M1006.19,2079.22c-1.56,.27-3.12,.54-4.67,.88-.08,.02-.15-.07-.11-.14,1.5-3.17,2.01-5.92,2.18-7.39l5.33-.94c-.25,1.62-.92,4.47-2.65,7.54-.02,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M1009.89,2088.78l4.55-.8s.06,0,.08,.02c2.61,2.33,4.14,4.81,4.89,6.26-1.71,.28-3.48,.55-5.11,.85-.63-1.33-1.99-3.76-4.46-6.17-.06-.06-.03-.16,.05-.17Z" />
					<path d="M997.01,2091.27l4.53-.92s.06,0,.08,.02c2.67,2.24,4.22,4.67,4.99,6.09l-4.95,1.01c-.68-1.31-2.13-3.69-4.69-6.03-.06-.06-.04-.16,.04-.17Z" />
					<path d="M1016.38,2087.66l4.63-.75s.06,0,.08,.02c.83,.74,1.55,1.5,2.18,2.23l.71,4.36-3.16,.51c-.64-1.34-2.02-3.77-4.49-6.2-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1000.31,2097.75l-1.93,.39-.93-4.32c1.39,1.52,2.31,2.96,2.85,3.93Z" />
					<path d="M1003.44,2089.97c1.51-.32,3.02-.59,4.54-.85,.03,0,.06,0,.08,.02,2.63,2.31,4.14,4.77,4.88,6.22l-4.98,.87c-.66-1.33-2.06-3.76-4.57-6.09-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1048.62,2080.71s-.05,.08-.1,.08l-4.53,.77c-.1,.02-.18-.08-.14-.18l.26-.71c.08-.22-.03-.47-.26-.56l-.62-.23c-.22-.08-.47,.03-.56,.26l-.62,1.68s-.05,.08-.1,.08l-4.54,.76c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.62-.23c-.22-.08-.47,.03-.56,.25l-.63,1.67s-.06,.08-.1,.08l-4.54,.75c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.08-.47,.03-.56,.25l-.63,1.67s-.06,.08-.1,.08l-4.54,.74c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.08-.47,.03-.56,.25l-.44,1.17-.96-5.86,.79,.97c.15,.19,.42,.21,.61,.06l.51-.42c.18-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.53-.74s.09,0,.12,.05l1.13,1.38c.15,.19,.42,.21,.61,.06l.51-.42c.18-.15,.21-.42,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.53-.75s.09,0,.12,.05l1.13,1.38c.15,.18,.42,.21,.61,.06l.51-.42c.18-.15,.21-.42,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.52-.76s.09,0,.12,.05l1.14,1.38c.15,.18,.43,.21,.61,.06l.51-.42c.18-.15,.21-.43,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.52-.77s.09,0,.12,.05l.34,.42,1,5.85-.19,.51Z" />
				<g>
					<path d="M1039.07,2073.74l-4.62,.77c-.08,.01-.14-.07-.11-.15,1.56-3.1,2.08-5.84,2.26-7.31l5.13-.87c-.24,1.61-.87,4.45-2.6,7.51-.02,.03-.04,.05-.07,.05Z" />
					<path d="M1025.96,2075.91l-4.62,.75c-.08,.01-.14-.07-.1-.15,1.57-3.09,2.1-5.84,2.28-7.31l5.15-.85c-.24,1.61-.89,4.45-2.63,7.5-.02,.03-.04,.05-.07,.05Z" />
					<path d="M1045.62,2072.63l-4.61,.78c-.08,.01-.14-.07-.11-.15,1.55-3.1,2.07-5.85,2.24-7.32l3.14-.53,.74,4.35c-.35,.89-.78,1.84-1.33,2.81-.02,.03-.04,.05-.07,.05Z" />
					<path d="M1020.11,2069.77l2-.33c-.17,1.1-.53,2.77-1.29,4.69l-.71-4.36Z" />
					<path d="M1032.52,2074.83l-4.62,.76c-.08,.01-.14-.07-.1-.15,1.56-3.1,2.09-5.84,2.27-7.31l5.14-.85c-.24,1.61-.89,4.44-2.62,7.5-.02,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M1036.1,2084.43l4.63-.77s.06,0,.08,.02c2.63,2.32,4.18,4.79,4.94,6.23l-5.18,.88c-.65-1.33-2.04-3.76-4.52-6.19-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1022.96,2086.59l4.63-.75s.06,0,.09,.02c2.62,2.33,4.16,4.8,4.91,6.25l-5.18,.85c-.64-1.34-2.03-3.77-4.5-6.2-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1042.68,2083.33l4.63-.78s.06,0,.08,.02c.84,.74,1.57,1.49,2.19,2.22l.74,4.35-3.17,.54c-.65-1.33-2.05-3.75-4.54-6.17-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1026,2093.2l-2.01,.33-.71-4.36c1.34,1.58,2.21,3.04,2.72,4.03Z" />
					<path d="M1029.53,2085.52l4.63-.77s.06,0,.08,.02c2.63,2.33,4.17,4.8,4.92,6.25l-5.18,.86c-.65-1.34-2.04-3.76-4.51-6.19-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1074.87,2076.07s-.05,.08-.1,.08l-4.53,.82c-.1,.02-.18-.08-.15-.17l.26-.71c.08-.23-.04-.47-.26-.55l-.62-.22c-.23-.08-.47,.04-.55,.26l-.6,1.68s-.05,.08-.1,.08l-4.53,.82c-.1,.02-.18-.08-.15-.17l.26-.71c.08-.22-.03-.47-.26-.56l-.62-.23c-.23-.08-.47,.03-.56,.26l-.61,1.68s-.05,.08-.1,.08l-4.53,.79c-.1,.02-.18-.08-.15-.17l.26-.71c.08-.22-.03-.47-.26-.56l-.62-.23c-.22-.08-.47,.03-.56,.26l-.62,1.68s-.05,.08-.1,.08l-4.53,.79c-.1,.02-.18-.08-.15-.17l.26-.71c.08-.22-.03-.47-.26-.56l-.62-.23c-.22-.08-.47,.03-.56,.26l-.43,1.17-1-5.85,.8,.96c.15,.18,.43,.21,.61,.06l.5-.42c.18-.15,.21-.43,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.52-.78s.09,0,.12,.05l1.14,1.37c.15,.18,.43,.21,.61,.05l.5-.42c.18-.15,.21-.43,.05-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.52-.79s.09,0,.12,.05l1.15,1.37c.15,.18,.43,.21,.61,.05l.5-.42c.18-.15,.21-.43,.05-.61l-.49-.58c-.06-.08-.02-.2,.08-.21l4.51-.82s.09,0,.12,.04l1.15,1.36c.15,.18,.43,.21,.61,.05l.5-.42c.18-.15,.2-.43,.05-.61l-.49-.58c-.06-.08-.02-.2,.08-.21l4.51-.82s.09,0,.12,.04l.35,.41,1.08,5.84-.18,.51Z" />
				<g>
					<path d="M1065.24,2069.21l-4.6,.82c-.08,.01-.14-.07-.11-.14,1.52-3.11,2.01-5.86,2.17-7.34l5.11-.93c-.22,1.62-.82,4.46-2.5,7.54-.02,.03-.04,.05-.07,.05Z" />
					<path d="M1052.16,2071.51l-4.61,.79c-.08,.01-.14-.07-.11-.15,1.54-3.1,2.05-5.85,2.22-7.32l5.12-.9c-.23,1.61-.85,4.45-2.56,7.52-.02,.03-.04,.05-.07,.05Z" />
					<path d="M1071.77,2068.03l-4.6,.83c-.08,.01-.14-.07-.11-.14,1.51-3.12,1.99-5.87,2.14-7.35l3.12-.57,.8,4.34c-.34,.9-.76,1.85-1.29,2.83-.01,.03-.04,.05-.07,.05Z" />
					<path d="M1046.28,2065.41l2-.34c-.16,1.1-.5,2.77-1.25,4.69l-.74-4.35Z" />
					<path d="M1058.7,2070.37l-4.61,.81c-.08,.01-.14-.07-.11-.14,1.53-3.11,2.03-5.86,2.2-7.33l5.12-.9c-.22,1.61-.84,4.45-2.54,7.52-.01,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M1062.39,2079.94l4.63-.83s.06,0,.09,.02c2.66,2.29,4.24,4.74,5.02,6.17l-5.18,.94c-.66-1.32-2.09-3.73-4.6-6.14-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1049.25,2082.22l4.63-.8s.06,0,.09,.02c2.65,2.31,4.21,4.76,4.97,6.2l-5.18,.91c-.66-1.33-2.06-3.75-4.56-6.17-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1068.96,2078.76l4.62-.84s.06,0,.09,.02c.85,.73,1.59,1.47,2.23,2.19l.8,4.34-3.17,.57c-.67-1.32-2.1-3.73-4.62-6.12-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1052.34,2088.8l-2.01,.34-.74-4.35c1.35,1.57,2.24,3.03,2.76,4.01Z" />
					<path d="M1055.82,2081.08l4.63-.81s.06,0,.09,.02c2.66,2.3,4.22,4.76,4.99,6.2l-5.18,.91c-.66-1.33-2.08-3.74-4.58-6.15-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1101.06,2071.01s-.05,.08-.1,.09l-4.52,.9c-.1,.02-.18-.08-.15-.17l.24-.72c.08-.23-.04-.47-.27-.55l-.62-.21c-.23-.08-.47,.04-.55,.27l-.58,1.69s-.05,.08-.1,.09l-4.52,.9c-.1,.02-.18-.08-.15-.17l.25-.72c.08-.23-.04-.47-.27-.55l-.62-.21c-.23-.08-.47,.04-.55,.27l-.58,1.69s-.05,.08-.1,.09l-4.52,.86c-.1,.02-.18-.08-.15-.17l.25-.71c.08-.23-.04-.47-.27-.55l-.62-.22c-.23-.08-.47,.04-.55,.27l-.59,1.69s-.05,.08-.1,.09l-4.52,.86c-.1,.02-.18-.08-.15-.17l.25-.71c.08-.23-.04-.47-.26-.55l-.62-.22c-.23-.08-.47,.04-.55,.26l-.42,1.17-1.08-5.84,.81,.95c.16,.18,.43,.21,.61,.05l.5-.43c.18-.16,.2-.43,.05-.61l-.49-.57c-.07-.08-.02-.2,.08-.21l4.5-.85s.09,0,.12,.04l1.16,1.36c.16,.18,.43,.2,.61,.05l.5-.43c.18-.16,.2-.43,.04-.61l-.49-.57c-.07-.08-.02-.2,.07-.21l4.5-.85s.09,0,.12,.04l1.17,1.35c.16,.18,.43,.2,.61,.04l.49-.43c.18-.16,.2-.43,.04-.61l-.49-.57c-.07-.08-.02-.2,.07-.22l4.49-.89s.09,0,.12,.04l1.18,1.35c.16,.18,.43,.2,.61,.04l.49-.43c.18-.16,.2-.43,.04-.61l-.5-.57c-.07-.08-.03-.2,.07-.21l4.49-.89s.09,0,.12,.04l.36,.41,1.18,5.82-.17,.51Z" />
				<g>
					<path d="M1091.32,2064.32l-4.58,.9c-.08,.02-.14-.07-.11-.14,1.47-3.13,1.91-5.9,2.05-7.38l5.08-1.01c-.19,1.62-.74,4.47-2.37,7.57-.01,.03-.04,.05-.07,.05Z" />
					<path d="M1078.29,2066.82l-4.59,.86c-.08,.01-.14-.07-.11-.14,1.5-3.11,1.97-5.88,2.11-7.36l5.1-.97c-.2,1.62-.78,4.46-2.44,7.55-.01,.03-.04,.05-.07,.05Z" />
					<path d="M1097.82,2063.03l-4.58,.91c-.08,.02-.14-.07-.11-.14,1.45-3.14,1.88-5.9,2.01-7.38l3.11-.62,.88,4.33c-.32,.9-.72,1.86-1.24,2.85-.01,.03-.04,.05-.07,.05Z" />
					<path d="M1072.33,2060.8l1.99-.36c-.14,1.11-.46,2.78-1.19,4.71l-.8-4.34Z" />
					<path d="M1084.81,2065.58l-4.59,.87c-.08,.02-.14-.07-.11-.14,1.48-3.13,1.94-5.89,2.08-7.36l5.1-.97c-.19,1.62-.76,4.47-2.41,7.56-.01,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M1088.64,2075.09l4.62-.91s.06,0,.09,.02c2.7,2.24,4.32,4.66,5.13,6.09l-5.18,1.03c-.69-1.32-2.15-3.69-4.7-6.05-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1075.52,2077.57l4.62-.86s.06,0,.09,.02c2.68,2.27,4.28,4.7,5.07,6.13l-5.18,.98c-.67-1.32-2.12-3.71-4.64-6.1-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1095.19,2073.8l4.61-.92s.06,0,.09,.02c.86,.71,1.61,1.44,2.27,2.15l.88,4.33-3.16,.63c-.69-1.31-2.17-3.69-4.73-6.04-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1078.71,2084.11l-2.01,.37-.8-4.34c1.37,1.55,2.28,3,2.81,3.97Z" />
					<path d="M1082.08,2076.34l4.62-.87s.06,0,.09,.02c2.69,2.27,4.3,4.7,5.1,6.12l-5.18,.99c-.68-1.32-2.14-3.71-4.67-6.08-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1127.16,2065.41s-.05,.08-.1,.09l-4.5,1c-.1,.02-.18-.07-.15-.17l.23-.72c.07-.23-.06-.47-.28-.54l-.63-.2c-.23-.07-.47,.05-.54,.28l-.54,1.7s-.05,.08-.1,.09l-4.5,1c-.1,.02-.18-.07-.15-.17l.23-.72c.07-.23-.05-.47-.28-.54l-.63-.2c-.23-.07-.47,.05-.54,.28l-.55,1.7s-.05,.08-.1,.09l-4.51,.94c-.1,.02-.18-.07-.15-.17l.24-.72c.07-.23-.05-.47-.28-.55l-.62-.21c-.23-.07-.47,.05-.55,.28l-.56,1.7s-.05,.08-.1,.09l-4.51,.94c-.1,.02-.18-.07-.15-.17l.24-.72c.08-.23-.05-.47-.27-.55l-.62-.21c-.23-.07-.47,.05-.55,.28l-.4,1.18-1.18-5.82,.83,.94c.16,.18,.43,.19,.61,.03l.49-.44c.18-.16,.19-.43,.04-.61l-.5-.56c-.07-.08-.03-.2,.07-.22l4.48-.94s.09,0,.12,.04l1.19,1.33c.16,.18,.43,.19,.61,.03l.49-.44c.18-.16,.19-.43,.03-.61l-.5-.56c-.07-.08-.03-.2,.07-.22l4.48-.94s.09,0,.12,.04l1.2,1.33c.16,.18,.43,.19,.61,.03l.48-.44c.18-.16,.19-.44,.03-.61l-.51-.56c-.07-.07-.03-.2,.07-.22l4.47-.99s.09,0,.12,.04l1.2,1.32c.16,.18,.44,.19,.61,.03l.48-.44c.18-.16,.19-.44,.03-.61l-.51-.56c-.07-.07-.03-.19,.07-.22l4.47-.99s.09,0,.12,.04l.37,.39,1.32,5.79-.16,.52Z" />
				<g>
					<path d="M1117.27,2058.93l-4.56,1.01c-.08,.02-.15-.07-.11-.14,1.4-3.18,1.78-5.95,1.88-7.43l5.05-1.12c-.15,1.62-.63,4.48-2.19,7.62-.01,.03-.04,.05-.07,.05Z" />
					<path d="M1104.31,2061.69l-4.57,.95c-.08,.02-.14-.07-.11-.14,1.44-3.15,1.85-5.92,1.97-7.4l5.07-1.06c-.17,1.62-.69,4.48-2.29,7.6-.01,.03-.04,.05-.07,.05Z" />
					<path d="M1123.73,2057.5l-4.55,1.01c-.08,.02-.15-.06-.11-.14,1.38-3.17,1.75-5.94,1.84-7.42l3.08-.69,.98,4.31c-.29,.92-.68,1.88-1.17,2.87-.01,.03-.04,.05-.07,.05Z" />
					<path d="M1098.25,2055.8l1.97-.41c-.12,1.1-.41,2.8-1.09,4.74l-.88-4.33Z" />
					<path d="M1110.79,2060.34l-4.57,.96c-.08,.02-.15-.07-.11-.14,1.42-3.16,1.82-5.92,1.93-7.4l5.06-1.08c-.16,1.62-.65,4.5-2.24,7.61-.01,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M1114.82,2069.77l4.6-1.02s.06,0,.09,.02c2.75,2.18,4.43,4.57,5.27,5.97l-5.17,1.14c-.72-1.3-2.24-3.65-4.83-5.94-.06-.06-.04-.16,.04-.17Z" />
					<path d="M1101.74,2072.5l4.61-.96s.06,0,.09,.02c2.73,2.22,4.37,4.62,5.19,6.03l-5.17,1.08c-.7-1.31-2.19-3.67-4.76-6-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1121.35,2068.32l4.6-1.02s.06,0,.09,.02c.88,.69,1.65,1.41,2.32,2.09l.98,4.31-3.16,.7c-.72-1.3-2.26-3.64-4.87-5.93-.06-.05-.04-.16,.04-.17Z" />
					<path d="M1105.05,2078.97l-2.01,.42-.88-4.33c1.4,1.53,2.34,2.95,2.89,3.91Z" />
					<path d="M1108.28,2071.14l4.61-.96s.06,0,.09,.02c2.74,2.22,4.4,4.59,5.23,6l-5.17,1.1c-.71-1.31-2.21-3.67-4.8-5.99-.06-.06-.04-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1153.12,2059.11s-.05,.08-.09,.09l-4.47,1.13c-.1,.02-.19-.07-.16-.16l.21-.73c.07-.23-.07-.47-.3-.54l-.63-.18c-.23-.07-.47,.07-.54,.3l-.49,1.72s-.05,.08-.09,.09l-4.47,1.12c-.1,.02-.19-.07-.16-.16l.21-.73c.07-.23-.06-.47-.29-.54l-.63-.18c-.23-.07-.47,.07-.54,.3l-.5,1.72s-.05,.08-.09,.09l-4.49,1.06c-.1,.02-.19-.07-.16-.17l.22-.72c.07-.23-.06-.47-.29-.54l-.63-.19c-.23-.07-.47,.06-.54,.29l-.52,1.71s-.05,.08-.1,.09l-4.49,1.05c-.1,.02-.18-.07-.15-.17l.22-.72c.07-.23-.06-.47-.29-.54l-.63-.19c-.23-.07-.47,.06-.54,.29l-.37,1.2-1.32-5.79,.85,.91c.16,.17,.44,.18,.61,.02l.48-.45c.17-.16,.18-.44,.02-.61l-.51-.55c-.07-.07-.03-.19,.07-.22l4.46-1.05s.09,0,.12,.04l1.22,1.3c.16,.17,.44,.18,.61,.02l.48-.45c.17-.16,.18-.44,.02-.61l-.52-.55c-.07-.07-.03-.19,.06-.22l4.45-1.05s.09,0,.12,.04l1.23,1.29c.17,.17,.44,.18,.61,.01l.47-.46c.17-.17,.18-.44,.01-.61l-.52-.55c-.07-.07-.03-.19,.06-.22l4.44-1.11s.09,0,.13,.04l1.24,1.29c.17,.17,.44,.18,.61,.01l.47-.46c.17-.17,.17-.44,0-.61l-.53-.54c-.07-.07-.04-.19,.06-.22l4.44-1.12s.09,0,.13,.03l.38,.38,1.49,5.75-.14,.52Z" />
				<g>
					<path d="M1143.05,2052.91l-4.52,1.13c-.08,.02-.15-.06-.12-.14,1.31-3.21,1.62-5.99,1.68-7.47l5.01-1.25c-.1,1.62-.5,4.5-1.98,7.67-.01,.03-.04,.05-.07,.06Z" />
					<path d="M1130.18,2056l-4.54,1.06c-.08,.02-.15-.06-.11-.14,1.36-3.19,1.7-5.97,1.78-7.45l5.03-1.18c-.13,1.62-.57,4.49-2.09,7.65-.01,.03-.04,.05-.07,.06Z" />
					<path d="M1149.47,2051.31l-4.52,1.13c-.08,.02-.15-.06-.12-.14,1.29-3.21,1.58-5.98,1.62-7.46,1.02-.25,2.04-.5,3.05-.78l1.11,4.28c-.27,.92-.62,1.91-1.08,2.92-.01,.03-.04,.05-.07,.06Z" />
					<path d="M1123.99,2050.26l1.96-.46c-.09,1.11-.34,2.8-.97,4.77l-.98-4.31Z" />
					<path d="M1136.63,2054.49l-4.54,1.06c-.08,.02-.15-.06-.11-.14,1.34-3.19,1.67-5.96,1.74-7.44,1.68-.38,3.35-.79,5.02-1.22-.12,1.62-.53,4.52-2.03,7.67-.01,.03-.04,.05-.07,.06Z" />
				</g>
				<g>
					<path d="M1140.9,2063.81l4.57-1.14s.06,0,.09,.02c2.82,2.11,4.56,4.44,5.44,5.82l-5.15,1.28c-.75-1.28-2.34-3.59-4.99-5.81-.06-.05-.04-.16,.04-.18Z" />
					<path d="M1127.88,2066.88l4.59-1.08s.06,0,.09,.02c2.78,2.15,4.49,4.51,5.35,5.9l-5.16,1.21c-.73-1.29-2.28-3.62-4.91-5.88-.06-.05-.04-.16,.04-.18Z" />
					<path d="M1147.39,2062.19l4.57-1.14s.06,0,.09,.02c.9,.67,1.69,1.35,2.38,2.02l1.11,4.28c-1.04,.28-2.09,.54-3.14,.8-.76-1.28-2.37-3.58-5.05-5.8-.06-.05-.04-.16,.04-.18Z" />
					<path d="M1131.34,2073.26l-2,.47-.98-4.31c1.44,1.48,2.41,2.88,2.99,3.84Z" />
					<path d="M1134.39,2065.35l4.59-1.08s.06,0,.09,.02c2.8,2.14,4.52,4.46,5.39,5.85-1.71,.43-3.43,.85-5.15,1.25-.74-1.29-2.31-3.61-4.96-5.87-.06-.05-.04-.16,.04-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1178.89,2051.97s-.05,.08-.09,.1c-1.47,.46-2.95,.87-4.43,1.29-.1,.03-.19-.06-.16-.16l.18-.73c.06-.23-.08-.47-.32-.53l-.64-.16c-.23-.06-.47,.08-.53,.31l-.44,1.73s-.05,.08-.09,.09l-4.44,1.26c-.1,.03-.19-.06-.16-.16l.19-.73c.06-.23-.08-.47-.31-.53l-.64-.16c-.23-.06-.47,.08-.53,.31l-.45,1.74s-.05,.08-.09,.09c-1.48,.43-2.97,.8-4.45,1.2-.1,.03-.19-.06-.16-.16l.2-.73c.06-.23-.08-.47-.31-.53l-.64-.17c-.23-.06-.47,.07-.53,.31l-.47,1.73s-.05,.08-.09,.09l-4.46,1.18c-.1,.03-.19-.06-.16-.16l.2-.73c.06-.23-.07-.47-.3-.53l-.63-.17c-.23-.06-.47,.07-.53,.31l-.33,1.21-1.49-5.75,.87,.89c.17,.17,.44,.17,.61,0l.46-.46c.17-.17,.17-.44,0-.61l-.53-.54c-.07-.07-.04-.19,.06-.22l4.42-1.17s.09,0,.13,.03l1.26,1.27c.17,.17,.44,.17,.61,0l.46-.46c.17-.17,.17-.44,0-.61l-.53-.54c-.07-.07-.04-.19,.06-.22,1.47-.4,2.95-.77,4.41-1.19,.04-.01,.09,0,.13,.03l1.27,1.25c.17,.17,.44,.16,.61,0l.46-.47c.17-.17,.16-.45,0-.61l-.54-.53c-.07-.07-.04-.19,.06-.22l4.4-1.25s.09,0,.13,.03l1.28,1.25c.17,.17,.45,.16,.61,0l.45-.47c.17-.17,.16-.45-.01-.61l-.54-.53c-.07-.07-.04-.19,.05-.22,1.47-.42,2.94-.82,4.39-1.28,.04-.01,.09,0,.13,.03l.39,.37,1.69,5.69-.13,.53Z" />
				<g>
					<path d="M1168.61,2046.12l-4.48,1.27c-.08,.02-.15-.06-.12-.13,1.21-3.25,1.42-6.04,1.43-7.52l4.95-1.4c-.05,1.63-.35,4.51-1.71,7.73-.01,.03-.04,.05-.07,.06Z" />
					<path d="M1155.86,2049.62l-4.5,1.2c-.08,.02-.15-.06-.12-.14,1.26-3.23,1.52-6.01,1.56-7.5l4.98-1.32c-.08,1.62-.43,4.5-1.85,7.7-.01,.03-.04,.05-.07,.06Z" />
					<path d="M1174.97,2044.31l-4.48,1.27c-.08,.02-.15-.06-.12-.13,1.18-3.25,1.37-6.03,1.37-7.5,1.01-.28,2.01-.59,3.01-.89l1.26,4.23c-.23,.93-.55,1.93-.97,2.96-.01,.03-.04,.05-.06,.06Z" />
					<path d="M1149.51,2044.06l1.94-.51c-.06,1.11-.25,2.81-.83,4.79l-1.11-4.28Z" />
					<path d="M1162.25,2047.92l-4.5,1.2c-.08,.02-.15-.06-.12-.14,1.24-3.23,1.48-6,1.5-7.48,1.66-.43,3.31-.92,4.96-1.38-.06,1.63-.38,4.52-1.77,7.74-.01,.03-.04,.05-.07,.06Z" />
				</g>
				<g>
					<path d="M1166.82,2057.08l4.54-1.29s.06,0,.09,.01c2.89,2.02,4.71,4.29,5.64,5.64l-5.11,1.45c-.79-1.25-2.46-3.51-5.19-5.64-.07-.05-.05-.15,.03-.18Z" />
					<path d="M1153.88,2060.55l4.56-1.21s.06,0,.09,.02c2.85,2.06,4.64,4.37,5.54,5.74l-5.13,1.36c-.77-1.27-2.4-3.55-5.09-5.73-.06-.05-.04-.16,.04-.18Z" />
					<path d="M1173.26,2055.26l4.54-1.29s.06,0,.09,.01c.92,.63,1.73,1.28,2.45,1.92l1.26,4.23c-1.04,.31-2.07,.64-3.12,.92-.81-1.25-2.49-3.5-5.25-5.63-.07-.05-.05-.15,.03-.18Z" />
					<path d="M1157.54,2066.83l-2,.53-1.11-4.28c1.48,1.44,2.5,2.81,3.1,3.75Z" />
					<path d="M1160.35,2058.83l4.56-1.21s.06,0,.09,.02c2.86,2.03,4.67,4.31,5.58,5.66-1.71,.48-3.41,.98-5.12,1.42-.78-1.26-2.43-3.54-5.14-5.72-.06-.05-.04-.15,.04-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1204.38,2043.85s-.04,.08-.09,.1c-1.45,.51-2.91,1.02-4.38,1.48-.1,.03-.19-.05-.17-.15l.15-.74c.05-.23-.1-.46-.34-.51l-.65-.14c-.24-.05-.47,.1-.51,.33l-.37,1.75s-.04,.08-.09,.1l-4.39,1.43c-.1,.03-.19-.05-.17-.15l.16-.74c.05-.23-.1-.46-.33-.51l-.64-.14c-.23-.05-.46,.1-.52,.33l-.38,1.75s-.04,.08-.09,.1c-1.46,.49-2.93,.94-4.41,1.38-.1,.03-.19-.06-.17-.15l.17-.74c.05-.23-.09-.47-.33-.52l-.64-.15c-.23-.05-.47,.09-.52,.32l-.4,1.74s-.04,.08-.09,.1l-4.42,1.34c-.1,.03-.19-.06-.17-.16l.18-.74c.06-.23-.09-.47-.32-.52l-.64-.15c-.23-.05-.47,.09-.52,.32l-.29,1.22-1.69-5.69,.9,.86c.17,.16,.45,.16,.61-.02l.45-.48c.16-.17,.15-.45-.02-.61l-.55-.52c-.07-.07-.04-.19,.05-.22l4.37-1.33s.09,0,.13,.03l1.31,1.22c.18,.16,.45,.15,.61-.02l.45-.48c.16-.17,.15-.45-.02-.61l-.55-.52c-.07-.07-.05-.19,.05-.22,1.46-.44,2.92-.89,4.36-1.37,.04-.01,.09,0,.13,.03l1.32,1.2c.18,.16,.45,.15,.61-.03l.44-.49c.16-.18,.15-.45-.03-.61l-.56-.51c-.07-.07-.05-.19,.05-.22l4.34-1.41s.09,0,.13,.03l1.33,1.2c.18,.16,.45,.15,.61-.03l.44-.49c.16-.18,.14-.45-.04-.61l-.56-.51c-.08-.07-.05-.19,.05-.22,1.45-.46,2.89-.96,4.33-1.46,.04-.02,.09,0,.13,.02l.4,.35,1.92,5.62-.1,.53Z" />
				<g>
					<path d="M1193.88,2038.42l-4.42,1.44c-.08,.03-.15-.05-.12-.13,1.08-3.3,1.18-6.08,1.13-7.56l4.88-1.59c.02,1.62-.16,4.51-1.4,7.78-.01,.03-.03,.05-.06,.06Z" />
					<path d="M1181.29,2042.39l-4.45,1.35c-.08,.02-.15-.05-.12-.13,1.15-3.27,1.3-6.06,1.28-7.54l4.92-1.49c-.01,1.63-.26,4.51-1.56,7.76-.01,.03-.04,.05-.06,.06Z" />
					<path d="M1200.15,2036.36c-1.46,.52-2.94,.97-4.41,1.46-.08,.03-.15-.05-.13-.13,1.05-3.29,1.12-6.07,1.06-7.54l2.96-1.03,1.43,4.18c-.2,.94-.47,1.95-.85,3-.01,.03-.03,.05-.06,.06Z" />
					<path d="M1174.75,2037.06l1.91-.58c-.02,1.11-.15,2.81-.66,4.82l-1.26-4.23Z" />
					<path d="M1187.6,2040.46l-4.45,1.36c-.08,.02-.15-.05-.12-.13,1.12-3.27,1.25-6.05,1.22-7.52,1.64-.5,3.26-1.05,4.88-1.57,0,1.63-.2,4.53-1.47,7.8-.01,.03-.03,.05-.06,.06Z" />
				</g>
				<g>
					<path d="M1192.51,2049.45l4.49-1.46s.06,0,.09,.01c2.97,1.9,4.89,4.11,5.87,5.42l-5.07,1.65c-.84-1.22-2.6-3.41-5.41-5.44-.07-.05-.05-.15,.03-.18Z" />
					<path d="M1179.7,2053.39l4.52-1.37s.06,0,.09,.01c2.93,1.96,4.8,4.2,5.75,5.54l-5.09,1.55c-.82-1.24-2.52-3.46-5.29-5.55-.07-.05-.05-.15,.03-.18Z" />
					<path d="M1198.89,2047.37c1.49-.5,3-.95,4.49-1.48,.03-.01,.06,0,.09,0,.95,.59,1.79,1.21,2.53,1.82l1.43,4.18-3.08,1.07c-.86-1.22-2.64-3.4-5.48-5.42-.07-.05-.05-.15,.03-.18Z" />
					<path d="M1183.57,2059.53l-1.98,.6-1.26-4.23c1.53,1.38,2.6,2.72,3.24,3.63Z" />
					<path d="M1186.11,2051.44l4.52-1.38s.06,0,.09,.01c2.94,1.91,4.83,4.12,5.8,5.44-1.69,.54-3.38,1.11-5.07,1.63-.83-1.23-2.56-3.45-5.36-5.53-.07-.05-.05-.15,.03-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1229.51,2034.6s-.04,.09-.08,.1c-1.43,.57-2.85,1.17-4.3,1.7-.1,.04-.19-.04-.18-.14l.12-.74c.04-.24-.12-.46-.36-.5l-.65-.11c-.24-.04-.46,.12-.5,.36l-.29,1.76s-.04,.08-.08,.1l-4.33,1.62c-.1,.04-.19-.04-.18-.14l.13-.75c.04-.24-.12-.46-.36-.5l-.65-.11c-.24-.04-.46,.12-.5,.36l-.3,1.77s-.04,.09-.08,.1c-1.44,.54-2.88,1.1-4.34,1.59-.1,.03-.19-.05-.17-.15l.14-.74c.04-.23-.11-.46-.35-.51l-.65-.12c-.24-.04-.46,.11-.51,.34l-.33,1.75s-.04,.08-.09,.1l-4.36,1.52c-.1,.03-.19-.05-.17-.15l.14-.74c.05-.24-.11-.46-.34-.51l-.65-.12c-.24-.04-.46,.11-.51,.35l-.24,1.23-1.92-5.62,.94,.82c.18,.16,.45,.14,.61-.04l.43-.5c.16-.18,.14-.45-.04-.61l-.57-.5c-.08-.07-.05-.19,.04-.22l4.31-1.5s.09,0,.13,.02l1.36,1.17c.18,.16,.46,.14,.61-.04l.42-.5c.15-.18,.13-.45-.05-.61l-.58-.49c-.08-.07-.05-.19,.04-.22,1.44-.49,2.86-1.04,4.29-1.57,.04-.02,.09,0,.13,.02l1.37,1.14c.18,.15,.46,.13,.61-.06l.42-.51c.15-.18,.12-.46-.06-.61l-.58-.48c-.08-.06-.06-.19,.04-.22l4.27-1.6s.09,0,.13,.02l1.39,1.13c.19,.15,.46,.13,.61-.06l.41-.51c.15-.18,.12-.46-.07-.61l-.59-.48c-.08-.06-.06-.19,.03-.22,1.43-.52,2.83-1.12,4.24-1.68,.04-.02,.09-.01,.13,.02l.42,.33,2.19,5.52-.08,.54Z" />
				<g>
					<path d="M1218.77,2029.68l-4.35,1.63c-.08,.03-.15-.04-.13-.12,.93-3.34,.9-6.13,.78-7.6l4.79-1.79c.1,1.62,.05,4.51-1.03,7.83,0,.03-.03,.05-.06,.06Z" />
					<path d="M1206.38,2034.19l-4.39,1.53c-.08,.03-.15-.05-.13-.13,1.01-3.32,1.04-6.11,.96-7.58l4.84-1.69c.06,1.62-.06,4.51-1.22,7.8-.01,.03-.03,.05-.06,.06Z" />
					<path d="M1224.93,2027.32c-1.43,.59-2.88,1.14-4.33,1.67-.08,.03-.15-.04-.13-.12,.9-3.32,.84-6.1,.7-7.59l2.9-1.16,1.63,4.11c-.15,.95-.37,1.97-.7,3.03,0,.03-.03,.05-.06,.07Z" />
					<path d="M1199.63,2029.12l1.88-.66c.03,1.11-.03,2.82-.45,4.84l-1.43-4.18Z" />
					<path d="M1212.6,2031.99c-1.44,.56-2.91,1.05-4.38,1.56-.08,.03-.15-.05-.13-.13,.98-3.31,.99-6.08,.89-7.56l4.79-1.79c.08,1.63,0,4.53-1.11,7.86,0,.03-.03,.05-.06,.06Z" />
				</g>
				<g>
					<path d="M1217.9,2040.74l4.43-1.66s.06,0,.09,0c3.06,1.77,5.08,3.88,6.12,5.15l-5.01,1.87c-.9-1.18-2.75-3.29-5.66-5.19-.07-.05-.06-.15,.02-.18Z" />
					<path d="M1205.25,2045.24l4.46-1.56s.06,0,.09,0c3.01,1.84,4.98,4,5.99,5.29l-5.04,1.76c-.87-1.2-2.67-3.35-5.53-5.32-.07-.05-.05-.15,.02-.18Z" />
					<path d="M1224.19,2038.39c1.48-.54,2.95-1.11,4.41-1.71,.03-.01,.06,0,.09,0,.97,.54,1.85,1.12,2.61,1.7l1.63,4.11-3.04,1.22c-.91-1.16-2.79-3.25-5.73-5.14-.07-.05-.06-.15,.02-.18Z" />
					<path d="M1209.38,2051.21l-1.96,.68-1.43-4.18c1.59,1.32,2.71,2.61,3.39,3.5Z" />
					<path d="M1211.59,2043.03c1.49-.52,2.98-1.02,4.45-1.59,.03-.01,.06,0,.09,0,3.02,1.78,5.02,3.91,6.04,5.18l-5.01,1.87c-.88-1.19-2.71-3.33-5.6-5.29-.07-.05-.06-.15,.02-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1254.15,2024.06s-.03,.09-.08,.11l-4.2,1.95c-.09,.04-.19-.03-.18-.13l.08-.76c.02-.24-.15-.45-.39-.47l-.66-.07c-.24-.03-.45,.14-.48,.38l-.19,1.77s-.04,.09-.08,.11l-4.25,1.84c-.09,.04-.19-.03-.18-.14l.09-.75c.03-.24-.14-.45-.38-.48l-.65-.08c-.24-.03-.45,.14-.48,.38l-.21,1.78s-.04,.09-.08,.11l-4.25,1.83c-.09,.04-.19-.04-.18-.14l.1-.75c.03-.24-.14-.45-.38-.49l-.66-.09c-.24-.03-.46,.13-.49,.37l-.24,1.77s-.04,.09-.08,.1l-4.29,1.72c-.09,.04-.19-.04-.18-.14l.11-.75c.03-.24-.13-.46-.37-.49l-.65-.09c-.24-.03-.46,.13-.49,.37l-.18,1.24-2.19-5.52,.97,.77c.19,.15,.46,.12,.61-.07l.4-.52c.15-.19,.11-.46-.07-.61l-.59-.47c-.08-.06-.06-.19,.03-.22l4.23-1.7s.09-.01,.13,.02l1.41,1.1c.19,.15,.46,.11,.61-.07l.4-.51c.15-.19,.11-.46-.08-.61l-.6-.46c-.08-.06-.06-.19,.03-.22l4.19-1.8s.09-.01,.13,.02l1.42,1.07c.19,.14,.46,.1,.6-.09l.39-.53c.14-.19,.1-.46-.09-.61l-.6-.45c-.08-.06-.07-.19,.03-.23l4.19-1.81s.09-.01,.13,.02l1.44,1.06c.19,.14,.46,.1,.61-.09l.38-.53c.14-.2,.09-.47-.1-.61l-.61-.44c-.08-.06-.07-.18,.02-.23l4.13-1.92s.09-.01,.13,.01l.44,.31,2.49,5.39-.05,.54Z" />
				<g>
					<path d="M1243.17,2019.73l-4.26,1.84c-.08,.03-.15-.04-.14-.12,.76-3.38,.58-6.16,.38-7.62,1.56-.68,3.13-1.33,4.67-2.04,.2,1.63,.3,4.5-.61,7.87,0,.03-.03,.05-.06,.07Z" />
					<path d="M1231.04,2024.86l-4.31,1.73c-.08,.03-.15-.04-.13-.12,.85-3.36,.74-6.14,.59-7.61l4.74-1.9c.14,1.62,.17,4.5-.83,7.84,0,.03-.03,.05-.06,.06Z" />
					<path d="M1249.17,2017.04c-1.41,.64-2.79,1.33-4.22,1.92-.07,.03-.16-.04-.14-.12,.73-3.35,.48-6.18,.27-7.63l2.82-1.31,1.85,4.01c-.1,.95-.26,1.98-.53,3.06,0,.03-.03,.06-.06,.07Z" />
					<path d="M1224.06,2020.12l1.84-.74c.08,1.11,.11,2.81-.21,4.85l-1.63-4.11Z" />
					<path d="M1237.12,2022.34c-1.42,.62-2.84,1.23-4.28,1.79-.08,.03-.15-.04-.14-.12,.82-3.33,.67-6.14,.49-7.61l4.68-2.02c.16,1.62,.24,4.51-.7,7.89,0,.03-.03,.05-.06,.07Z" />
				</g>
				<g>
					<path d="M1242.87,2030.82l4.35-1.88s.06-.01,.09,0c3.15,1.61,5.3,3.62,6.4,4.81-1.63,.74-3.28,1.43-4.92,2.14-.96-1.13-2.93-3.14-5.93-4.9-.07-.04-.07-.15,.01-.18Z" />
					<path d="M1230.44,2035.94l4.39-1.77s.06,0,.09,0c3.1,1.69,5.18,3.75,6.26,4.99l-4.97,2c-.93-1.16-2.84-3.22-5.79-5.05-.07-.04-.06-.15,.02-.18Z" />
					<path d="M1249.04,2028.15c1.46-.61,2.88-1.31,4.31-1.96,.03-.01,.06-.01,.09,0,1,.49,1.91,1.02,2.71,1.55l1.85,4.01-2.98,1.38c-.98-1.13-2.95-3.05-5.99-4.8-.07-.04-.07-.15,0-.18Z" />
					<path d="M1234.86,2041.71l-1.93,.78-1.63-4.11c1.66,1.24,2.84,2.48,3.56,3.33Z" />
					<path d="M1236.68,2033.43c1.47-.57,2.92-1.2,4.37-1.83,.03-.01,.06-.01,.09,0,3.12,1.63,5.22,3.64,6.31,4.86l-4.92,2.13c-.94-1.14-2.87-3.15-5.86-4.98-.07-.04-.06-.15,.01-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1278.15,2012.08s-.03,.09-.07,.11l-4.08,2.2c-.09,.05-.2-.02-.19-.12l.03-.76c0-.24-.18-.44-.42-.45l-.66-.02c-.24,0-.44,.18-.45,.42l-.08,1.79s-.03,.09-.07,.11l-4.14,2.07c-.09,.05-.2-.02-.19-.12l.04-.76c.01-.24-.17-.44-.41-.46l-.66-.04c-.24-.01-.45,.17-.46,.41l-.11,1.79s-.03,.09-.07,.11l-4.14,2.07c-.09,.05-.2-.02-.19-.13l.05-.76c.02-.24-.16-.45-.4-.46l-.65-.04c-.24-.01-.44,.17-.46,.4l-.14,1.77s-.03,.09-.08,.11l-4.2,1.95c-.09,.04-.2-.03-.19-.13l.07-.75c.02-.24-.16-.45-.4-.47l-.66-.06c-.24-.02-.45,.16-.47,.39l-.11,1.24-2.49-5.39,1.02,.72c.2,.14,.46,.09,.6-.1l.37-.54c.14-.2,.09-.47-.11-.6l-.62-.44c-.08-.06-.07-.18,.02-.23l4.13-1.92s.09-.02,.13,.01l1.48,1.02c.2,.14,.47,.08,.6-.12l.36-.55c.13-.2,.08-.47-.12-.6l-.62-.42c-.08-.06-.07-.18,.02-.23l4.07-2.04s.09-.02,.13,0l1.49,.99c.2,.13,.47,.08,.6-.12l.36-.55c.13-.2,.07-.47-.13-.6l-.63-.42c-.08-.06-.08-.18,.01-.23l4.07-2.04s.09-.02,.13,0l1.5,.96c.2,.13,.47,.07,.59-.14l.35-.56c.13-.2,.06-.47-.14-.6l-.63-.4c-.08-.05-.08-.18,0-.23l4.01-2.16s.09-.02,.13,0l.46,.28,2.82,5.23-.02,.54Z" />
				<g>
					<path d="M1266.93,2008.45l-4.14,2.07c-.07,.04-.16-.03-.14-.11,.56-3.41,.21-6.17-.07-7.62,1.51-.76,3.04-1.5,4.52-2.32,.29,1.6,.61,4.52-.11,7.91,0,.03-.03,.06-.05,.07Z" />
					<path d="M1255.14,2014.26l-4.2,1.95c-.07,.03-.15-.03-.14-.11,.66-3.39,.4-6.17,.16-7.62,1.53-.73,3.09-1.4,4.6-2.18,.24,1.62,.45,4.52-.36,7.89,0,.03-.03,.05-.06,.07Z" />
					<path d="M1272.74,2005.38l-4.07,2.2c-.07,.04-.16-.03-.15-.11,.49-3.44,.09-6.21-.22-7.66l2.73-1.47,2.1,3.89c-.04,.95-.14,1.99-.34,3.08,0,.03-.02,.06-.05,.07Z" />
					<path d="M1247.91,2009.9l1.79-.83c.14,1.1,.26,2.8,.06,4.84l-1.85-4.01Z" />
					<path d="M1261.05,2011.39c-1.38,.68-2.75,1.4-4.15,2.06-.07,.03-.16-.03-.14-.11,.62-3.39,.29-6.2,.03-7.65l4.54-2.28c.26,1.6,.51,4.49-.23,7.91,0,.03-.03,.06-.05,.07Z" />
				</g>
				<g>
					<path d="M1267.29,2019.52l4.24-2.12s.06-.01,.09,0c3.25,1.44,5.49,3.25,6.67,4.38-1.57,.87-3.18,1.66-4.79,2.46-1.03-1.08-3.12-2.96-6.22-4.54-.07-.04-.07-.14,0-.18Z" />
					<path d="M1255.16,2025.35l4.3-2s.06-.01,.09,0c3.2,1.53,5.4,3.45,6.53,4.61-1.6,.82-3.24,1.53-4.86,2.3-.99-1.11-3.02-3.06-6.07-4.73-.07-.04-.07-.15,0-.18Z" />
					<path d="M1273.31,2016.5l4.18-2.25s.06-.02,.09,0c1.03,.43,1.97,.9,2.8,1.38l2.1,3.89-2.9,1.56c-1.04-1.05-3.15-2.89-6.27-4.4-.07-.04-.08-.14,0-.18Z" />
					<path d="M1259.9,2030.87l-1.89,.88-1.85-4.01c1.72,1.15,2.98,2.32,3.75,3.13Z" />
					<path d="M1261.26,2022.51c1.43-.67,2.83-1.4,4.25-2.1,.03-.01,.06-.01,.09,0,3.21,1.44,5.44,3.34,6.6,4.49l-4.81,2.41c-1.01-1.09-3.05-2.97-6.13-4.61-.07-.04-.07-.14,0-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1301.33,1998.53s-.02,.09-.06,.12l-3.93,2.47c-.09,.05-.2,0-.2-.11l-.02-.76c0-.24-.21-.43-.45-.42l-.66,.02c-.24,0-.43,.21-.42,.45l.04,1.8s-.02,.09-.06,.12c-1.31,.83-2.66,1.57-3.99,2.36-.09,.05-.2-.01-.2-.11v-.76c0-.24-.2-.43-.44-.43h-.66c-.24,0-.43,.2-.43,.44v1.79s-.02,.09-.06,.11l-4.01,2.33c-.09,.05-.2-.01-.2-.11v-.76c0-.24-.19-.44-.42-.43h-.66c-.24,0-.43,.2-.44,.44l-.02,1.8s-.03,.09-.07,.11l-4.08,2.2c-.09,.05-.2-.02-.19-.12l.02-.76c0-.24-.18-.44-.42-.44l-.66-.02c-.24,0-.44,.18-.45,.42l-.04,1.25-2.82-5.23,1.06,.66c.2,.13,.47,.06,.59-.14l.34-.56c.12-.2,.06-.47-.15-.59l-.64-.4c-.09-.05-.08-.18,0-.23l4-2.17s.09-.02,.13,0l1.53,.9c.2,.12,.47,.05,.59-.16l.33-.57c.12-.21,.05-.47-.16-.59l-.65-.38c-.09-.05-.09-.18,0-.23l3.93-2.29s.09-.02,.13,0l1.55,.89c.21,.12,.47,.05,.59-.16l.32-.57c.12-.21,.04-.47-.17-.59l-.66-.38c-.09-.05-.09-.18,0-.23,1.3-.77,2.64-1.5,3.92-2.31,.04-.02,.09-.03,.13,0l1.56,.85c.21,.11,.47,.03,.58-.18l.31-.58c.11-.21,.03-.47-.18-.59l-.66-.36c-.09-.05-.09-.17,0-.23l3.85-2.42s.09-.03,.13,0l.48,.25,3.18,5.02,.02,.54Z" />
				<g>
					<path d="M1289.9,1995.69l-4,2.33c-.07,.04-.16-.02-.15-.1,.33-3.43-.2-6.16-.58-7.58,1.47-.83,2.89-1.74,4.32-2.64,.4,1.57,.92,4.45,.46,7.92,0,.03-.02,.06-.05,.07Z" />
					<path d="M1278.53,2002.26l-4.07,2.2c-.07,.04-.16-.02-.15-.1,.45-3.42,0-6.17-.32-7.6l2.23-1.2,2.19-1.27c.33,1.58,.77,4.51,.17,7.92,0,.03-.02,.06-.05,.07Z" />
					<path d="M1295.47,1992.22l-3.91,2.46c-.07,.04-.16-.01-.15-.1,.25-3.46-.34-6.19-.75-7.61l2.62-1.64,2.37,3.73c.03,.95,0,1.99-.13,3.09,0,.03-.02,.06-.05,.07Z" />
					<path d="M1271.04,1998.34l1.74-.94c.21,1.09,.44,2.77,.36,4.82l-2.1-3.89Z" />
					<path d="M1284.22,1998.99l-4,2.33c-.07,.04-.16-.02-.15-.1,.37-3.46-.12-6.2-.48-7.64l4.38-2.55c.37,1.58,.81,4.43,.29,7.89,0,.03-.02,.06-.05,.07Z" />
				</g>
				<g>
					<path d="M1290.98,2006.7l4.1-2.39s.06-.02,.09,0c3.32,1.18,5.69,2.84,6.95,3.89-1.53,.96-3.04,1.93-4.61,2.81-1.1-1-3.31-2.75-6.52-4.12-.08-.03-.08-.14-.01-.18Z" />
					<path d="M1279.24,2013.3l4.18-2.25s.06-.02,.09,0c3.3,1.33,5.59,3.04,6.81,4.15l-2.33,1.35-2.37,1.28c-1.06-1.04-3.21-2.86-6.37-4.34-.08-.04-.08-.14,0-.18Z" />
					<path d="M1296.79,2003.27l4.02-2.53s.06-.02,.09-.01c1.06,.35,2.03,.76,2.9,1.19l2.37,3.73-2.8,1.76c-1.12-.98-3.35-2.67-6.57-3.96-.08-.03-.09-.14-.02-.18Z" />
					<path d="M1284.32,2018.52l-1.84,.99-2.1-3.89c1.8,1.04,3.12,2.13,3.94,2.89Z" />
					<path d="M1285.16,2010.09l4.1-2.39s.06-.02,.09,0c3.3,1.23,5.66,2.97,6.9,4.05l-4.66,2.71c-1.08-1.02-3.25-2.79-6.42-4.18-.08-.03-.08-.14,0-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1323.48,1983.31s-.02,.09-.05,.12l-3.74,2.75c-.08,.06-.2,0-.21-.09l-.08-.76c-.02-.24-.24-.41-.48-.38l-.65,.07c-.24,.03-.41,.24-.39,.48l.18,1.79s-.02,.09-.05,.12l-1.87,1.37-1.92,1.3c-.08,.06-.2,0-.21-.1l-.06-.75c-.02-.24-.23-.41-.47-.4l-.66,.05c-.24,.02-.42,.23-.4,.46l.13,1.78s-.02,.09-.06,.12l-3.84,2.6c-.08,.06-.2,0-.2-.1l-.05-.76c-.02-.24-.22-.42-.46-.4l-.66,.05c-.24,.02-.42,.23-.4,.47l.11,1.8s-.02,.09-.06,.12c-1.27,.89-2.59,1.69-3.9,2.51-.09,.05-.2,0-.2-.1l-.03-.75c0-.24-.21-.42-.45-.41l-.66,.03c-.24,0-.43,.21-.42,.45l.05,1.25-3.18-5.02,1.1,.58c.21,.11,.47,.03,.58-.18l.3-.58c.11-.21,.03-.47-.19-.58l-.67-.35c-.09-.05-.1-.17-.01-.23,1.28-.81,2.58-1.59,3.82-2.47,.04-.03,.09-.03,.13,0l1.59,.79c.21,.11,.47,.02,.57-.2l.29-.59c.1-.22,.01-.47-.2-.58l-.67-.33c-.09-.04-.1-.17-.02-.23l3.76-2.55s.09-.03,.13,0l1.61,.78c.22,.1,.48,.01,.58-.2l.28-.59c.1-.21,0-.47-.21-.57l-.68-.33c-.09-.04-.1-.17-.02-.22l1.88-1.28,1.83-1.34s.09-.03,.13-.01l1.62,.73c.22,.1,.47,0,.57-.22l.26-.6c.1-.22,0-.47-.22-.57l-.69-.31c-.09-.04-.11-.17-.02-.23l3.66-2.69s.09-.03,.13-.01l.5,.22,3.56,4.76,.06,.54Z" />
				<g>
					<path d="M1311.86,1981.34c-1.24,.91-2.54,1.74-3.81,2.62-.07,.05-.16,0-.16-.09,.08-3.44-.65-6.11-1.14-7.5,1.38-.96,2.72-1.98,4.08-2.97,.52,1.54,1.24,4.35,1.06,7.86,0,.03-.02,.06-.04,.08Z" />
					<path d="M1301.02,1988.73l-3.91,2.46c-.07,.04-.16-.01-.15-.09,.21-3.44-.42-6.14-.85-7.55,1.44-.87,2.8-1.87,4.2-2.8,.46,1.56,1.07,4.4,.76,7.91,0,.03-.02,.06-.04,.08Z" />
					<path d="M1317.14,1977.46l-3.72,2.73c-.07,.05-.16,0-.16-.08,0-3.47-.8-6.14-1.32-7.52l2.48-1.82,2.65,3.54c.1,.95,.15,1.98,.11,3.08,0,.03-.02,.06-.04,.08Z" />
					<path d="M1293.27,1985.32l1.67-1.05c.29,1.07,.64,2.73,.7,4.78l-2.37-3.73Z" />
					<path d="M1306.45,1985.04l-3.82,2.59c-.07,.05-.16,0-.15-.09,.12-3.47-.57-6.17-1.03-7.57l4.18-2.83c.48,1.55,1.14,4.35,.87,7.83,0,.03-.02,.06-.04,.08Z" />
				</g>
				<g>
					<path d="M1313.76,1992.24c1.3-.9,2.64-1.76,3.92-2.7,.03-.02,.06-.02,.09-.02,3.39,.9,5.89,2.4,7.22,3.34-1.46,1.06-2.9,2.15-4.38,3.19-1.17-.92-3.52-2.5-6.82-3.64-.08-.03-.09-.13-.02-.18Z" />
					<path d="M1302.5,1999.68l4.02-2.53s.06-.02,.09-.01c3.35,1.02,5.79,2.62,7.08,3.62-1.5,1-2.96,2.06-4.5,3-1.14-.96-3.42-2.63-6.67-3.89-.08-.03-.09-.13-.02-.18Z" />
					<path d="M1319.29,1988.36l3.84-2.81s.06-.02,.09-.02c1.09,.27,2.09,.61,2.99,.97l2.65,3.54-2.67,1.96c-1.19-.89-3.55-2.41-6.86-3.46-.08-.02-.1-.13-.03-.18Z" />
					<path d="M1307.95,2004.53l-1.78,1.12-2.37-3.73c1.87,.92,3.27,1.91,4.15,2.61Z" />
					<path d="M1308.17,1996.03l3.94-2.67s.06-.02,.09-.01c3.39,.99,5.87,2.56,7.19,3.54l-4.48,3.04c-1.15-.94-3.45-2.55-6.72-3.71-.08-.03-.09-.13-.02-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1344.35,1966.36s0,.09-.04,.12l-3.52,3.03c-.08,.07-.2,.03-.21-.07l-.14-.75c-.04-.24-.27-.39-.51-.34l-.65,.12c-.24,.04-.39,.27-.35,.51l.32,1.77s0,.09-.04,.12c-1.18,1-2.32,2.05-3.54,3-.08,.06-.2,.02-.21-.08l-.12-.74c-.04-.24-.26-.39-.5-.36l-.66,.1c-.24,.04-.4,.26-.36,.49l.27,1.76s-.01,.09-.05,.12l-3.64,2.89c-.08,.06-.2,.02-.21-.08l-.11-.75c-.03-.24-.25-.4-.49-.37l-.65,.1c-.24,.04-.4,.26-.37,.49l.25,1.78s-.01,.09-.05,.12c-1.21,.96-2.41,1.94-3.67,2.84-.08,.06-.2,.01-.21-.09l-.09-.75c-.03-.24-.25-.41-.49-.38l-.66,.08c-.24,.03-.41,.24-.38,.48l.14,1.24-3.56-4.76,1.15,.5c.22,.1,.48,0,.57-.22l.26-.6c.09-.22,0-.47-.23-.57l-.7-.3c-.09-.04-.11-.16-.03-.22,1.24-.88,2.41-1.84,3.59-2.78,.04-.03,.08-.04,.13-.02l1.65,.67c.22,.09,.47-.02,.56-.24l.24-.61c.09-.22-.02-.47-.24-.56l-.7-.28c-.09-.04-.11-.16-.03-.22l3.56-2.83s.09-.04,.13-.02l1.67,.65c.22,.09,.48-.02,.56-.24l.23-.61c.08-.22-.03-.47-.25-.55l-.71-.27c-.09-.04-.12-.16-.04-.22,1.19-.93,2.31-1.96,3.46-2.94,.04-.03,.08-.04,.13-.02l1.67,.6c.22,.08,.47-.04,.55-.26l.21-.62c.08-.23-.04-.47-.27-.55l-.71-.25c-.09-.03-.12-.16-.04-.22l3.44-2.96s.08-.04,.13-.02l.51,.18,3.94,4.45,.1,.53Z" />
				<g>
					<path d="M1332.59,1965.32l-1.75,1.5-1.81,1.43c-.06,.05-.16,0-.16-.08-.19-3.42-1.14-6.02-1.75-7.39l3.81-3.28c.64,1.49,1.59,4.23,1.7,7.74,0,.03-.01,.06-.04,.08Z" />
					<path d="M1322.39,1973.54c-1.19,.98-2.45,1.86-3.69,2.78-.07,.05-.16,0-.16-.08-.06-3.43-.89-6.07-1.43-7.45l3.94-3.13c.58,1.52,1.41,4.29,1.38,7.81,0,.03-.01,.06-.04,.08Z" />
					<path d="M1337.55,1961.05l-3.49,3.01c-.06,.05-.16,.01-.16-.07-.29-3.45-1.3-6.04-1.93-7.38l2.33-2.01,2.93,3.31c.18,.93,.31,1.95,.36,3.06,0,.03-.01,.06-.03,.08Z" />
					<path d="M1314.43,1970.76l1.58-1.16c.37,1.04,.84,2.66,1.06,4.7l-2.65-3.54Z" />
					<path d="M1327.52,1969.46l-3.61,2.87c-.06,.05-.16,0-.16-.08-.15-3.46-1.05-6.09-1.62-7.46l3.94-3.13c.61,1.5,1.48,4.24,1.49,7.72,0,.03-.01,.06-.04,.08Z" />
				</g>
				<g>
					<path d="M1335.39,1976.06l1.86-1.48,1.81-1.55s.06-.03,.09-.02c3.46,.62,6.08,1.91,7.49,2.75l-4.12,3.54c-1.23-.8-3.69-2.18-7.09-3.06-.08-.02-.1-.12-.04-.18Z" />
					<path d="M1324.73,1984.37c1.27-.95,2.57-1.85,3.8-2.86,.02-.02,.06-.03,.09-.02,3.42,.76,5.99,2.16,7.36,3.05l-4.25,3.37c-1.2-.86-3.62-2.36-6.97-3.37-.08-.02-.1-.13-.03-.18Z" />
					<path d="M1340.57,1971.72l3.61-3.11s.06-.03,.09-.02c1.11,.19,2.14,.44,3.07,.72l2.93,3.31-2.51,2.17c-1.26-.79-3.75-2.11-7.13-2.89-.08-.02-.11-.12-.04-.18Z" />
					<path d="M1330.54,1988.79l-1.7,1.25-2.65-3.54c1.94,.77,3.42,1.66,4.34,2.29Z" />
					<path d="M1330.1,1980.26l3.73-2.96s.06-.03,.09-.02c3.47,.73,6.07,2.09,7.47,2.96l-4.25,3.38c-1.23-.84-3.65-2.27-7-3.18-.08-.02-.1-.13-.04-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1363.72,1947.69s0,.09-.03,.13l-3.26,3.31c-.07,.07-.19,.04-.22-.06l-.2-.73c-.06-.23-.3-.37-.54-.3l-.64,.18c-.23,.06-.37,.3-.3,.54l.47,1.73s0,.09-.03,.13l-3.26,3.31c-.07,.07-.19,.04-.22-.06l-.19-.74c-.06-.23-.3-.37-.53-.31-.21,.06-.42,.12-.64,.17-.24,.06-.38,.29-.32,.52l.42,1.73s0,.09-.04,.13l-3.39,3.17c-.07,.07-.2,.03-.22-.07l-.17-.74c-.05-.23-.29-.38-.52-.32l-.64,.15c-.23,.05-.38,.29-.33,.52l.39,1.75s0,.09-.04,.13c-1.13,1.05-2.25,2.13-3.4,3.16-.08,.07-.2,.03-.22-.07l-.15-.74c-.05-.23-.28-.38-.52-.34l-.65,.13c-.24,.05-.39,.27-.35,.51l.24,1.22-3.94-4.45,1.19,.4c.23,.08,.48-.04,.55-.27l.21-.61c.08-.22-.05-.47-.28-.54l-.72-.24c-.1-.03-.12-.15-.05-.22,1.12-1.02,2.21-2.06,3.32-3.09,.03-.03,.08-.04,.13-.03l1.7,.53c.23,.07,.47-.06,.54-.29l.19-.63c.07-.23-.06-.47-.29-.54l-.72-.22c-.1-.03-.12-.15-.05-.22l3.32-3.1s.08-.04,.13-.03l1.72,.51c.23,.07,.47-.06,.54-.29,.06-.2,.12-.42,.17-.63,.06-.23-.08-.47-.3-.54l-.72-.2c-.1-.03-.13-.15-.06-.22l3.18-3.24s.08-.05,.12-.03l1.72,.46c.23,.06,.47-.08,.52-.31l.16-.63c.06-.23-.08-.47-.31-.53l-.73-.19c-.1-.03-.13-.15-.06-.22l3.18-3.24s.08-.05,.13-.03l.53,.13,4.31,4.09,.15,.52Z" />
				<g>
					<path d="M1351.9,1947.65c-1.09,1.08-2.12,2.22-3.27,3.25-.06,.06-.16,.02-.17-.06-.46-3.37-1.7-5.95-2.4-7.24l3.52-3.58c.77,1.43,1.95,4.07,2.35,7.55,0,.03,0,.06-.03,.08Z" />
					<path d="M1342.44,1956.69c-1.12,1.05-2.24,2.11-3.42,3.09-.06,.05-.16,.01-.17-.07-.32-3.39-1.42-5.99-2.08-7.32l3.67-3.43c.71,1.46,1.77,4.15,2.02,7.65,0,.03,0,.06-.03,.08Z" />
					<path d="M1356.48,1942.98l-3.23,3.29c-.06,.06-.16,.02-.17-.06-.58-3.4-1.81-5.89-2.55-7.17l2.15-2.19,3.21,3.04c.26,.91,.48,1.92,.62,3.01,0,.03,0,.06-.03,.08Z" />
					<path d="M1334.3,1954.61l1.48-1.28c.46,1.01,1.06,2.58,1.45,4.58l-2.93-3.31Z" />
					<path d="M1347.22,1952.22l-3.37,3.15c-.06,.06-.16,.02-.17-.06-.43-3.43-1.55-5.97-2.24-7.28,1.22-1.15,2.47-2.27,3.65-3.45,.76,1.47,1.85,4.1,2.15,7.56,0,.03,0,.06-.03,.08Z" />
				</g>
				<g>
					<path d="M1355.62,1958.13c1.18-1.06,2.26-2.24,3.38-3.36,.02-.02,.05-.03,.08-.03,3.5,.33,6.23,1.39,7.71,2.1l-3.81,3.88c-1.32-.72-3.82-1.81-7.31-2.42-.08-.01-.11-.11-.05-.17Z" />
					<path d="M1345.69,1967.31c1.22-1.02,2.37-2.11,3.53-3.2,.02-.02,.05-.03,.08-.03,3.48,.48,6.16,1.66,7.6,2.43l-3.97,3.71c-1.27-.76-3.76-1.99-7.2-2.75-.08-.02-.11-.12-.04-.17Z" />
					<path d="M1360.4,1953.34l3.34-3.4s.05-.03,.08-.03c1.12,.09,2.17,.25,3.12,.46l3.21,3.04-2.33,2.37c-1.33-.68-3.92-1.78-7.37-2.27-.08-.01-.12-.11-.06-.17Z" />
					<path d="M1351.86,1971.24l-1.6,1.38-2.93-3.31c2,.61,3.55,1.37,4.53,1.93Z" />
					<path d="M1350.68,1962.75l3.48-3.26s.05-.03,.09-.03c3.52,.43,6.25,1.58,7.69,2.31-1.29,1.28-2.63,2.49-3.95,3.73-1.29-.74-3.84-1.95-7.26-2.58-.08-.02-.11-.12-.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1381.37,1927.37s0,.09-.02,.13l-2.96,3.58c-.06,.08-.19,.06-.23-.04l-.26-.71c-.08-.22-.33-.34-.56-.25l-.62,.23c-.23,.08-.34,.34-.26,.56l.62,1.68s0,.09-.02,.13l-2.96,3.58c-.06,.08-.19,.06-.22-.04l-.25-.72c-.08-.23-.33-.34-.55-.26l-.61,.23c-.22,.08-.34,.33-.26,.56l.57,1.68s0,.09-.03,.13l-3.11,3.45c-.07,.08-.19,.05-.22-.05l-.23-.72c-.07-.23-.32-.35-.55-.28l-.63,.21c-.23,.07-.35,.32-.28,.55l.54,1.71s0,.09-.03,.13l-3.11,3.45c-.07,.08-.19,.05-.22-.05l-.22-.73c-.07-.23-.31-.36-.54-.29l-.62,.2c-.22,.08-.36,.31-.29,.54l.34,1.19-4.31-4.09,1.22,.3c.23,.06,.47-.08,.52-.32l.14-.64c.05-.23-.1-.47-.33-.52l-.73-.17c-.1-.02-.13-.14-.07-.22l3.04-3.37s.08-.05,.12-.04l1.74,.38c.23,.05,.46-.1,.51-.33l.13-.64c.05-.23-.1-.46-.34-.51l-.74-.16c-.1-.02-.14-.14-.07-.21l3.04-3.37s.08-.05,.12-.04l1.76,.36c.23,.04,.45-.11,.49-.35l.11-.65c.04-.24-.12-.46-.35-.51l-.74-.14c-.1-.02-.14-.14-.08-.21l2.89-3.5s.08-.05,.12-.04l1.76,.3c.24,.04,.46-.12,.5-.35l.11-.64c.04-.23-.12-.46-.36-.5l-.75-.13c-.1-.02-.14-.13-.08-.21l2.89-3.5s.08-.05,.12-.05l.54,.08,4.66,3.68,.19,.5Z" />
				<g>
					<path d="M1369.57,1928.37c-.98,1.18-1.94,2.38-2.95,3.54-.05,.06-.16,.03-.17-.05-.8-3.35-2.24-5.79-3.06-7.01l3.19-3.87c.89,1.35,2.3,3.88,3.01,7.3,0,.03,0,.06-.02,.09Z" />
					<path d="M1360.95,1938.2c-1.04,1.13-2.03,2.31-3.11,3.4-.06,.06-.16,.03-.17-.05-.63-3.36-1.97-5.88-2.73-7.13l3.36-3.73c.83,1.39,2.12,3.98,2.68,7.43,0,.03,0,.06-.02,.08Z" />
					<path d="M1373.74,1923.32l-2.93,3.55c-.05,.06-.15,.04-.17-.04-.88-3.34-2.32-5.7-3.17-6.91l1.95-2.36,3.46,2.74c.34,.88,.65,1.86,.88,2.94,0,.03,0,.06-.02,.08Z" />
					<path d="M1352.68,1936.85c.46-.46,.91-.93,1.34-1.42,.55,.97,1.32,2.51,1.87,4.46l-3.21-3.04Z" />
					<path d="M1365.33,1933.35l-3.09,3.42c-.05,.06-.15,.03-.17-.05-.73-3.37-2.07-5.8-2.86-7.05,1.1-1.26,2.28-2.45,3.32-3.77,.88,1.39,2.25,3.96,2.83,7.36,0,.03,0,.06-.02,.08Z" />
				</g>
				<g>
					<path d="M1374.23,1938.49c1.04-1.2,2.03-2.44,3.05-3.66,.02-.02,.05-.04,.08-.04,3.52,.02,6.34,.83,7.87,1.41l-3.46,4.2c-1.37-.59-3.99-1.48-7.47-1.74-.08,0-.12-.1-.07-.17Z" />
					<path d="M1365.14,1948.52c1.12-1.13,2.14-2.35,3.22-3.51,.02-.02,.05-.04,.08-.03,3.52,.17,6.29,1.11,7.8,1.76l-3.64,4.04c-1.35-.66-3.91-1.65-7.39-2.08-.08-.01-.12-.11-.06-.17Z" />
					<path d="M1378.55,1933.29l3.03-3.68s.05-.04,.08-.04c1.13,0,2.19,.06,3.16,.18l3.46,2.74-2.12,2.57c-1.38-.56-4.07-1.43-7.55-1.61-.08,0-.13-.1-.07-.17Z" />
					<path d="M1371.6,1951.87c-.47,.53-.95,1.05-1.45,1.54l-3.21-3.04c2.06,.45,3.64,1.04,4.65,1.5Z" />
					<path d="M1369.7,1943.52l3.19-3.54s.05-.04,.08-.04c3.57,.14,6.34,1,7.85,1.6-1.13,1.42-2.4,2.72-3.59,4.09-1.36-.62-4-1.61-7.47-1.94-.08,0-.12-.11-.06-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1397.29,1905.75s.02,.09-.01,.13c-.87,1.28-1.73,2.56-2.64,3.81-.06,.08-.19,.07-.23-.02l-.32-.69c-.1-.22-.36-.31-.58-.21l-.6,.28c-.22,.1-.31,.36-.21,.58l.74,1.63s.01,.09-.01,.13c-.9,1.26-1.78,2.53-2.7,3.77-.06,.08-.19,.07-.23-.02l-.3-.69c-.1-.22-.35-.32-.57-.22l-.61,.27c-.22,.1-.32,.35-.23,.57l.71,1.64s.01,.09-.02,.13l-2.76,3.72c-.06,.08-.19,.07-.23-.03l-.3-.7c-.09-.22-.35-.32-.57-.23l-.61,.26c-.22,.09-.33,.35-.24,.57l.68,1.65s.01,.09-.02,.13l-2.83,3.67c-.06,.08-.19,.06-.22-.03l-.28-.71c-.09-.22-.34-.33-.56-.24l-.61,.25c-.22,.09-.33,.34-.24,.57l.45,1.16-4.66-3.68,1.24,.19c.24,.03,.45-.13,.48-.37l.09-.65c.03-.24-.13-.46-.37-.49l-.74-.11c-.1-.01-.15-.13-.09-.21l2.78-3.6s.07-.06,.12-.05l1.77,.24c.24,.03,.46-.13,.49-.37l.08-.65c.03-.24-.14-.45-.38-.48l-.75-.09c-.1-.01-.15-.13-.09-.21l2.71-3.66s.07-.06,.12-.05l1.78,.2c.24,.03,.45-.14,.48-.38l.07-.65c.03-.24-.15-.45-.39-.47l-.75-.08c-.1-.01-.15-.13-.09-.21,.9-1.22,1.76-2.47,2.65-3.7,.03-.04,.07-.06,.12-.05l1.78,.17c.24,.02,.45-.15,.47-.39l.06-.65c.02-.24-.15-.45-.39-.47l-.75-.07c-.1,0-.15-.12-.1-.2,.89-1.23,1.74-2.49,2.59-3.74,.03-.04,.07-.06,.12-.06l.54,.04,4.9,3.35,.23,.49Z" />
				<g>
					<path d="M1385.63,1907.64c-.88,1.26-1.79,2.51-2.72,3.74-.05,.07-.15,.05-.18-.03-1.05-3.28-2.63-5.57-3.55-6.74l2.93-4.13c.99,1.29,2.57,3.71,3.52,7.07,0,.03,0,.06-.01,.09Z" />
					<path d="M1377.8,1918.18l-2.83,3.65c-.05,.06-.15,.04-.18-.04-.95-3.32-2.46-5.68-3.33-6.87l3.09-4.02c.96,1.33,2.44,3.79,3.27,7.19,0,.03,0,.06-.02,.09Z" />
					<path d="M1389.41,1902.27c-.85,1.28-1.77,2.52-2.66,3.79-.05,.07-.15,.05-.18-.03-1.12-3.26-2.72-5.53-3.66-6.67l1.74-2.56,3.65,2.49c.41,.87,.79,1.83,1.11,2.9,0,.03,0,.06-.01,.09Z" />
					<path d="M1369.41,1917.56c.42-.5,.81-1.03,1.21-1.55,.63,.91,1.52,2.38,2.25,4.29l-3.46-2.74Z" />
					<path d="M1381.76,1912.94c-.92,1.23-1.82,2.49-2.77,3.7-.05,.06-.15,.04-.18-.04-.98-3.29-2.55-5.64-3.44-6.81l3.02-4.07c.96,1.3,2.5,3.75,3.39,7.13,0,.03,0,.06-.02,.09Z" />
				</g>
				<g>
					<path d="M1391.02,1917.34c.95-1.27,1.88-2.55,2.79-3.84,.02-.03,.05-.04,.08-.04,3.52-.24,6.38,.37,7.95,.84l-3.12,4.4c-1.4-.48-4.11-1.2-7.61-1.19-.08,0-.13-.09-.08-.16Z" />
					<path d="M1382.86,1928.07l2.91-3.75s.05-.04,.08-.04c3.53-.11,6.38,.61,7.92,1.12l-3.29,4.28c-1.39-.53-4.06-1.34-7.55-1.44-.08,0-.13-.1-.07-.16Z" />
					<path d="M1394.96,1911.87c.91-1.3,1.85-2.57,2.73-3.89,.02-.03,.05-.04,.08-.05,1.11-.1,2.16-.11,3.12-.06l3.65,2.49-1.86,2.73c-1.42-.46-4.15-1.14-7.64-1.07-.08,0-.13-.09-.08-.16Z" />
					<path d="M1389.58,1930.84c-.43,.55-.84,1.11-1.29,1.66l-3.46-2.74c2.07,.25,3.7,.71,4.76,1.08Z" />
					<path d="M1386.99,1922.74c.98-1.24,1.9-2.53,2.85-3.8,.02-.03,.05-.04,.08-.04,3.52-.17,6.38,.49,7.94,.99l-3.21,4.34c-1.4-.51-4.07-1.25-7.58-1.32-.08,0-.13-.1-.08-.16Z" />
				</g>
			</g>
			<g>
				<path d="M1411.67,1883.08s.02,.09,0,.13l-2.37,3.98c-.05,.09-.18,.09-.23,0l-.37-.67c-.12-.21-.38-.29-.59-.17l-.57,.32c-.21,.12-.28,.38-.17,.59l.86,1.57s.02,.09,0,.13l-2.45,3.93c-.05,.09-.18,.08-.23,0l-.35-.67c-.11-.21-.37-.29-.59-.18l-.58,.31c-.21,.11-.29,.38-.18,.59l.83,1.59s.02,.09,0,.13c-.82,1.31-1.68,2.59-2.52,3.89-.06,.09-.18,.08-.23-.01l-.34-.68c-.11-.21-.37-.3-.58-.19l-.59,.3c-.21,.11-.3,.37-.19,.58l.8,1.6s.02,.09,0,.13c-.83,1.3-1.71,2.57-2.58,3.85-.06,.08-.18,.08-.23-.02l-.33-.68c-.1-.22-.36-.3-.58-.2l-.59,.29c-.22,.1-.31,.36-.2,.58l.54,1.13-4.9-3.35,1.24,.1c.24,.02,.45-.16,.46-.4l.05-.65c.02-.24-.16-.44-.4-.46l-.75-.06c-.1,0-.16-.12-.1-.2,.85-1.25,1.71-2.5,2.53-3.78,.03-.04,.07-.06,.12-.06l1.78,.11c.24,.01,.44-.17,.45-.41l.03-.65c.01-.24-.17-.44-.41-.45l-.75-.04c-.1,0-.16-.12-.1-.2,.82-1.27,1.67-2.54,2.47-3.82,.02-.04,.07-.06,.12-.06l1.78,.08c.24,0,.44-.18,.45-.42l.02-.65c0-.24-.18-.44-.42-.45l-.75-.03c-.1,0-.16-.12-.11-.2l2.41-3.86s.07-.06,.12-.06l1.78,.05c.24,0,.43-.18,.44-.42v-.66c.01-.24-.18-.44-.42-.44l-.75-.02c-.1,0-.16-.11-.11-.2l2.33-3.91s.07-.06,.11-.06h.54s5.12,3.01,5.12,3.01l.26,.47Z" />
				<g>
					<path d="M1400.17,1885.77l-2.45,3.93c-.04,.07-.15,.06-.18-.02-1.3-3.21-3.03-5.39-4.02-6.49,.89-1.43,1.79-2.86,2.65-4.32,1.07,1.22,2.85,3.54,4.01,6.82,.01,.03,0,.06,0,.09Z" />
					<path d="M1393.1,1896.84l-2.6,3.83c-.05,.07-.15,.05-.18-.03-1.18-3.25-2.83-5.49-3.78-6.61,.96-1.39,1.85-2.82,2.78-4.23,1.03,1.26,2.7,3.62,3.78,6.96,0,.03,0,.06-.01,.09Z" />
					<path d="M1403.57,1880.15l-2.37,3.97c-.04,.07-.15,.06-.18-.02-1.36-3.19-3.13-5.34-4.14-6.42l1.59-2.66,3.81,2.23c.46,.83,.91,1.77,1.3,2.8,.01,.03,0,.06,0,.09Z" />
					<path d="M1384.66,1896.8l1.11-1.63c.69,.87,1.67,2.26,2.54,4.12l-3.65-2.49Z" />
					<path d="M1396.69,1891.34l-2.52,3.88c-.04,.07-.15,.05-.18-.02-1.24-3.23-2.93-5.44-3.9-6.55,.93-1.41,1.82-2.84,2.71-4.28,1.05,1.23,2.78,3.58,3.9,6.89,0,.03,0,.06-.01,.09Z" />
				</g>
				<g>
					<path d="M1406.23,1895.08l2.51-4.03s.04-.04,.08-.05c3.52-.46,6.38-.07,7.98,.29-.91,1.55-1.86,3.07-2.82,4.6-1.44-.39-4.2-.92-7.66-.65-.08,0-.14-.08-.09-.16Z" />
					<path d="M1398.81,1906.34l2.67-3.93s.04-.04,.08-.05c3.49-.37,6.37,.14,7.95,.55-.99,1.5-1.94,3.03-2.96,4.51-1.42-.44-4.17-1.06-7.65-.93-.08,0-.13-.09-.09-.16Z" />
					<path d="M1409.79,1889.35l2.43-4.08s.04-.05,.07-.05c1.11-.17,2.16-.25,3.13-.27l3.81,2.23-1.69,2.83c-1.44-.36-4.21-.84-7.66-.52-.08,0-.14-.08-.1-.15Z" />
					<path d="M1405.72,1908.64l-1.18,1.73-3.65-2.49c2.07,.1,3.75,.46,4.83,.76Z" />
					<path d="M1402.57,1900.74l2.59-3.98s.05-.04,.08-.05c3.51-.42,6.37,.03,7.97,.42-.95,1.53-1.9,3.05-2.89,4.55-1.43-.41-4.18-.99-7.65-.79-.08,0-.14-.09-.09-.16Z" />
				</g>
			</g>
			<g>
				<path d="M1424.45,1859.47s.03,.09,0,.13c-.67,1.39-1.38,2.77-2.09,4.14-.05,.09-.17,.1-.23,.01l-.41-.64c-.13-.2-.4-.26-.6-.13l-.56,.36c-.2,.13-.26,.4-.13,.6l.96,1.51s.03,.09,0,.13c-.71,1.37-1.41,2.75-2.16,4.1-.05,.09-.18,.09-.23,0l-.4-.64c-.13-.2-.39-.27-.6-.14l-.56,.35c-.2,.13-.27,.39-.14,.6l.93,1.53s.03,.09,0,.13l-2.22,4.07c-.05,.09-.18,.09-.23,0l-.38-.65c-.12-.21-.39-.27-.6-.15l-.57,.34c-.21,.12-.28,.39-.16,.59l.9,1.54s.02,.09,0,.13l-2.29,4.03c-.05,.09-.18,.09-.23,0l-.38-.66c-.12-.21-.38-.28-.59-.16l-.57,.33c-.2,.12-.28,.38-.16,.59l.61,1.08-5.12-3h1.25c.24,.02,.44-.17,.44-.41v-.66c0-.24-.2-.43-.44-.43h-.75c-.1,0-.16-.11-.11-.2l2.25-3.96s.07-.07,.11-.07h1.79c.24-.01,.43-.21,.43-.45v-.65c0-.24-.21-.43-.45-.42h-.76c-.1,0-.17-.1-.12-.19l2.18-4s.06-.07,.11-.07l1.79-.04c.24,0,.43-.2,.42-.44l-.02-.65c0-.24-.21-.42-.45-.42l-.76,.02c-.1,0-.17-.1-.12-.19,.74-1.33,1.41-2.68,2.12-4.03,.02-.04,.06-.07,.11-.07l1.78-.08c.24-.01,.42-.21,.41-.45l-.03-.65c-.01-.24-.22-.42-.45-.41l-.75,.03c-.1,0-.17-.1-.12-.19,.7-1.34,1.39-2.7,2.05-4.06,.02-.04,.06-.07,.11-.07l.54-.03,5.32,2.64,.3,.45Z" />
				<g>
					<path d="M1413.17,1862.95c-.7,1.38-1.44,2.72-2.18,4.08-.04,.07-.14,.07-.18,0-1.5-3.11-3.36-5.16-4.44-6.18l2.33-4.5c1.16,1.14,3.06,3.32,4.47,6.52,.01,.03,.01,.06,0,.09Z" />
					<path d="M1406.86,1874.47c-.77,1.34-1.51,2.69-2.3,4.01-.04,.07-.15,.06-.18-.01-1.38-3.15-3.22-5.29-4.24-6.34l2.51-4.4c1.11,1.18,2.93,3.42,4.22,6.66,.01,.03,0,.06,0,.09Z" />
					<path d="M1416.18,1857.11c-.69,1.38-1.41,2.74-2.11,4.11-.04,.07-.14,.07-.18,0-1.56-3.08-3.47-5.1-4.55-6.11,.48-.91,.92-1.85,1.38-2.77l3.96,1.96c.52,.8,1.04,1.71,1.51,2.72,.01,.03,.01,.06,0,.09Z" />
					<path d="M1398.46,1875.02c.34-.56,.67-1.13,.99-1.7,.75,.82,1.83,2.15,2.82,3.94l-3.81-2.23Z" />
					<path d="M1410.07,1868.74c-.73,1.36-1.47,2.71-2.24,4.05-.04,.07-.15,.06-.18-.01-1.43-3.12-3.29-5.22-4.34-6.26l2.42-4.45c1.14,1.16,3,3.37,4.35,6.59,.01,.03,.01,.06,0,.09Z" />
				</g>
				<g>
					<path d="M1419.84,1871.81c.75-1.39,1.52-2.78,2.24-4.19,.01-.03,.04-.05,.07-.05,3.44-.72,6.36-.51,7.98-.27l-2.48,4.79c-1.45-.28-4.26-.63-7.71-.14-.08,.01-.14-.07-.1-.15Z" />
					<path d="M1413.24,1883.56c.82-1.36,1.58-2.75,2.37-4.12,.02-.03,.04-.05,.07-.05,3.48-.6,6.39-.28,8,.02l-2.67,4.69c-1.45-.34-4.2-.76-7.68-.39-.08,0-.14-.08-.1-.15Z" />
					<path d="M1422.99,1865.85c.72-1.41,1.47-2.81,2.17-4.22,.01-.03,.04-.05,.07-.06,1.09-.25,2.12-.4,3.08-.49l3.96,1.96c-.49,.98-.96,1.98-1.47,2.95-1.47-.26-4.27-.55-7.71,0-.08,.01-.14-.07-.11-.15Z" />
					<path d="M1420.29,1885.38c-.34,.61-.69,1.21-1.06,1.81l-3.81-2.23c2.09-.04,3.77,.2,4.87,.42Z" />
					<path d="M1416.59,1877.71c.79-1.37,1.55-2.76,2.3-4.15,.01-.03,.04-.05,.07-.05,3.46-.66,6.38-.4,7.99-.12l-2.57,4.74c-1.45-.31-4.22-.69-7.69-.26-.08,.01-.14-.08-.1-.15Z" />
				</g>
			</g>
			<g>
				<path d="M1435.56,1835.02s.03,.09,.02,.13l-1.79,4.28c-.04,.09-.16,.11-.23,.03l-.46-.61c-.14-.19-.42-.23-.61-.09l-.52,.4c-.19,.14-.23,.42-.08,.61l1.06,1.44s.03,.09,.01,.13l-1.88,4.24c-.04,.09-.17,.11-.23,.02l-.44-.62c-.14-.19-.41-.24-.6-.1l-.53,.39c-.19,.14-.24,.41-.1,.61l1.05,1.46s.03,.09,.01,.13l-1.96,4.2c-.04,.09-.17,.1-.23,.02l-.43-.62c-.14-.2-.41-.24-.6-.11l-.54,.38c-.2,.14-.24,.41-.11,.61l1.02,1.48s.03,.09,.01,.13c-.64,1.41-1.35,2.78-2.03,4.17-.04,.09-.17,.1-.23,.02l-.42-.63c-.13-.2-.4-.25-.6-.12l-.55,.37c-.2,.13-.25,.4-.12,.6l.69,1.04-5.32-2.64,1.24-.08c.24-.01,.42-.22,.4-.46l-.05-.65c-.02-.24-.22-.42-.46-.4l-.75,.05c-.1,0-.17-.1-.13-.19,.66-1.37,1.36-2.71,1.99-4.09,.02-.04,.06-.07,.11-.07l1.77-.14c.24-.02,.41-.23,.39-.47l-.06-.65c-.02-.24-.23-.41-.47-.39l-.75,.06c-.1,0-.17-.09-.13-.19l1.92-4.12s.06-.07,.1-.08l1.77-.17c.24-.02,.41-.24,.38-.47l-.07-.65c-.03-.24-.24-.41-.48-.39l-.75,.07c-.1,0-.17-.09-.13-.18l1.84-4.16s.06-.07,.11-.08l1.78-.2c.24-.03,.4-.24,.37-.48l-.08-.65c-.03-.24-.25-.41-.48-.38l-.75,.09c-.1,.01-.18-.09-.14-.18l1.76-4.2s.06-.07,.1-.08l.54-.07,5.49,2.26,.33,.43Z" />
				<g>
					<path d="M1424.54,1839.28l-1.87,4.23c-.03,.07-.14,.08-.18,0-1.73-3-3.75-4.92-4.89-5.87,.68-1.55,1.39-3.08,2.02-4.64,1.24,1.06,3.31,3.1,4.91,6.19,.01,.03,.02,.06,0,.09Z" />
					<path d="M1419.07,1851.22l-2.04,4.15c-.04,.07-.14,.07-.18,0-1.62-3.05-3.56-5.04-4.66-6.03,.76-1.51,1.46-3.05,2.17-4.57,1.2,1.1,3.19,3.22,4.71,6.37,.01,.03,.01,.06,0,.09Z" />
					<path d="M1427.12,1833.25l-1.79,4.26c-.03,.07-.14,.08-.18,0-1.77-2.96-3.84-4.86-4.99-5.78l1.19-2.85,4.09,1.68c.57,.76,1.15,1.62,1.68,2.6,.01,.03,.02,.06,0,.09Z" />
					<path d="M1410.71,1852.34l.87-1.77c.8,.76,1.96,2.01,3.09,3.73l-3.96-1.96Z" />
					<path d="M1421.86,1845.27l-1.96,4.19c-.03,.07-.14,.07-.18,0-1.68-3.03-3.66-4.98-4.77-5.95,.72-1.53,1.43-3.06,2.1-4.61,1.21,1.08,3.26,3.17,4.81,6.28,.01,.03,.01,.06,0,.09Z" />
				</g>
				<g>
					<path d="M1431.83,1847.66l1.92-4.34s.04-.05,.07-.06c3.42-.95,6.32-.95,7.95-.82-.68,1.67-1.44,3.3-2.16,4.95-1.48-.18-4.28-.32-7.67,.42-.08,.02-.15-.06-.11-.14Z" />
					<path d="M1426.04,1859.84l2.1-4.26s.04-.05,.07-.06c3.42-.84,6.33-.74,7.96-.55-.77,1.63-1.51,3.27-2.32,4.87-1.47-.23-4.27-.48-7.7,.14-.08,.01-.15-.07-.11-.14Z" />
					<path d="M1434.56,1841.5l1.84-4.38s.04-.05,.07-.06c1.07-.32,2.1-.55,3.06-.7l4.08,1.68-1.27,3.04c-1.48-.16-4.27-.24-7.66,.55-.08,.02-.15-.06-.12-.14Z" />
					<path d="M1433.2,1861.16l-.93,1.88-3.96-1.96c2.06-.19,3.78-.07,4.88,.08Z" />
					<path d="M1428.99,1853.78l2.01-4.3s.04-.05,.07-.06c3.43-.89,6.32-.85,7.95-.69-.72,1.65-1.47,3.29-2.24,4.91-1.47-.21-4.28-.4-7.69,.28-.08,.02-.15-.07-.11-.14Z" />
				</g>
			</g>
			<g>
				<path d="M1444.89,1809.83s.04,.08,.03,.13c-.46,1.47-.97,2.94-1.48,4.39-.03,.1-.16,.12-.22,.04l-.49-.57c-.16-.18-.43-.2-.61-.04l-.5,.43c-.18,.16-.2,.43-.04,.61l1.17,1.36s.04,.09,.02,.13c-.51,1.46-1.01,2.92-1.56,4.37-.04,.1-.16,.12-.22,.04l-.48-.58c-.15-.18-.43-.21-.61-.05l-.51,.42c-.19,.15-.21,.43-.06,.61l1.14,1.38s.04,.09,.02,.13c-.54,1.45-1.06,2.9-1.63,4.34-.04,.09-.16,.11-.23,.04l-.47-.59c-.15-.19-.42-.21-.61-.06l-.52,.41c-.19,.15-.22,.42-.07,.61l1.11,1.4s.04,.09,.02,.13l-1.7,4.31c-.04,.09-.16,.11-.22,.03l-.47-.6c-.15-.19-.42-.22-.61-.07l-.52,.41c-.19,.15-.23,.42-.08,.61l.76,.99-5.49-2.26,1.24-.16c.24-.03,.41-.25,.37-.48l-.09-.65c-.04-.24-.25-.4-.49-.37l-.74,.1c-.1,.01-.18-.09-.14-.18l1.67-4.23s.06-.07,.1-.08l1.77-.26c.24-.04,.4-.26,.36-.49l-.1-.64c-.04-.23-.26-.4-.49-.36l-.75,.11c-.1,.02-.18-.08-.14-.18,.56-1.41,1.06-2.84,1.6-4.26,.02-.04,.05-.07,.1-.08l1.76-.29c.24-.04,.39-.26,.35-.5l-.11-.64c-.04-.23-.27-.39-.5-.35l-.75,.13c-.1,.02-.18-.08-.15-.17,.54-1.42,1.03-2.85,1.53-4.28,.02-.04,.05-.08,.1-.08l1.75-.33c.23-.04,.39-.27,.34-.5l-.12-.64c-.05-.23-.27-.39-.51-.34l-.74,.14c-.1,.02-.18-.08-.15-.17,.5-1.43,1-2.86,1.45-4.31,.01-.04,.05-.08,.1-.09l.53-.11,5.64,1.85,.36,.4Z" />
				<g>
					<path d="M1434.22,1814.88c-.49,1.46-1.05,2.9-1.58,4.34-.03,.08-.13,.09-.18,.02-1.93-2.86-4.06-4.63-5.26-5.49l1.66-4.78c1.31,.96,3.5,2.85,5.35,5.82,.02,.03,.02,.06,.01,.09Z" />
					<path d="M1429.59,1827.17c-.57,1.43-1.11,2.87-1.72,4.29-.03,.08-.14,.08-.18,.01-1.8-2.92-3.92-4.78-5.08-5.69l1.86-4.71c1.27,1.01,3.39,2.97,5.12,6,.02,.03,.02,.06,0,.09Z" />
					<path d="M1436.36,1808.68l-1.51,4.37c-.03,.08-.13,.09-.18,.02-1.98-2.83-4.16-4.56-5.37-5.39,.35-.97,.65-1.96,.97-2.93l4.2,1.38c.63,.72,1.27,1.54,1.88,2.47,.02,.03,.02,.06,.01,.09Z" />
					<path d="M1421.35,1828.88c.25-.61,.52-1.21,.75-1.82,.86,.71,2.1,1.87,3.33,3.5l-4.09-1.68Z" />
					<path d="M1431.96,1821.04c-.53,1.45-1.08,2.89-1.65,4.32-.03,.08-.13,.08-.18,.01-1.86-2.89-3.98-4.7-5.17-5.59l1.76-4.74c1.29,.99,3.44,2.91,5.24,5.91,.02,.03,.02,.06,0,.09Z" />
				</g>
				<g>
					<path d="M1442.07,1822.71c.54-1.49,1.12-2.96,1.62-4.46,.01-.03,.03-.05,.06-.06,3.31-1.21,6.23-1.41,7.86-1.4l-1.77,5.1c-1.49-.08-4.31-.02-7.65,.96-.08,.02-.15-.05-.12-.13Z" />
					<path d="M1437.17,1835.28c.63-1.45,1.18-2.94,1.77-4.41,.01-.03,.04-.05,.06-.06,3.36-1.08,6.29-1.18,7.93-1.1l-1.98,5.02c-1.49-.13-4.26-.16-7.66,.69-.08,.02-.15-.06-.12-.14Z" />
					<path d="M1444.35,1816.36l1.55-4.49s.03-.05,.06-.06c1.04-.4,2.04-.7,2.98-.93l4.2,1.38c-.35,1.05-.67,2.1-1.04,3.14-1.49-.05-4.3,.06-7.63,1.1-.08,.02-.15-.05-.12-.13Z" />
					<path d="M1444.41,1836.1c-.25,.65-.53,1.29-.8,1.94l-4.08-1.68c2.06-.33,3.78-.33,4.88-.26Z" />
					<path d="M1439.68,1829.02c.59-1.47,1.16-2.95,1.7-4.44,.01-.03,.03-.05,.06-.06,3.33-1.14,6.26-1.29,7.9-1.25l-1.88,5.07c-1.47-.1-4.28-.09-7.66,.82-.08,.02-.15-.06-.12-.13Z" />
				</g>
			</g>
			<g>
				<path d="M1452.33,1784.01s.05,.08,.04,.13l-1.14,4.5c-.03,.1-.15,.13-.22,.06l-.54-.53c-.17-.17-.44-.17-.61,0l-.46,.47c-.17,.17-.16,.45,0,.62l1.26,1.27s.05,.08,.03,.13l-1.24,4.47c-.03,.1-.15,.13-.22,.06l-.53-.54c-.17-.17-.44-.17-.61,0l-.47,.46c-.17,.17-.17,.44,0,.62l1.25,1.29s.04,.08,.03,.13l-1.33,4.44c-.03,.1-.15,.13-.22,.05l-.52-.55c-.16-.18-.44-.18-.61-.02l-.48,.45c-.17,.16-.18,.44-.02,.62l1.22,1.32s.04,.08,.03,.13c-.44,1.48-.94,2.95-1.41,4.42-.03,.1-.15,.12-.22,.05l-.51-.56c-.16-.18-.43-.19-.61-.03l-.49,.44c-.18,.16-.19,.44-.03,.61l.83,.93-5.64-1.85,1.22-.25c.23-.05,.38-.28,.33-.51l-.14-.64c-.05-.23-.28-.38-.52-.33l-.74,.15c-.1,.02-.18-.07-.15-.17,.46-1.45,.96-2.88,1.38-4.33,.01-.04,.05-.08,.09-.09l1.73-.39c.23-.05,.38-.29,.32-.52l-.15-.64c-.06-.23-.29-.38-.52-.32l-.73,.17c-.1,.02-.19-.07-.16-.16l1.31-4.36s.05-.08,.09-.09l1.72-.43c.23-.06,.37-.29,.31-.52l-.17-.63c-.06-.23-.3-.37-.53-.31l-.73,.18c-.1,.02-.19-.07-.16-.16l1.22-4.38s.05-.08,.09-.09l1.73-.46c.23-.06,.36-.3,.3-.53l-.18-.63c-.06-.23-.3-.36-.53-.3l-.73,.2c-.1,.03-.19-.06-.16-.16l1.12-4.41s.05-.08,.09-.09l.52-.15,5.77,1.42,.39,.38Z" />
				<g>
					<path d="M1442.06,1789.85l-1.23,4.45c-.02,.08-.12,.1-.18,.03-2.15-2.71-4.43-4.32-5.69-5.09,.44-1.62,.92-3.24,1.32-4.87,1.38,.86,3.73,2.58,5.77,5.39,.02,.02,.02,.06,.02,.09Z" />
					<path d="M1438.38,1802.44l-1.42,4.4c-.03,.08-.13,.09-.18,.02-2.04-2.79-4.25-4.48-5.48-5.3,.54-1.6,1-3.22,1.49-4.83,1.34,.91,3.62,2.72,5.58,5.62,.02,.03,.02,.06,.01,.09Z" />
					<path d="M1443.72,1783.51l-1.14,4.47c-.02,.08-.12,.1-.18,.03-2.19-2.67-4.52-4.23-5.79-4.98l.76-2.99,4.29,1.06c.68,.67,1.37,1.43,2.04,2.32,.02,.02,.03,.06,.02,.09Z" />
					<path d="M1430.27,1804.74l.61-1.87c.91,.64,2.23,1.71,3.59,3.25l-4.2-1.38Z" />
					<path d="M1440.28,1796.16l-1.33,4.42c-.02,.08-.13,.09-.18,.03-2.1-2.75-4.34-4.4-5.59-5.19,.5-1.61,.97-3.23,1.4-4.85,1.35,.89,3.69,2.66,5.68,5.51,.02,.03,.02,.06,.01,.09Z" />
				</g>
				<g>
					<path d="M1450.49,1797.08l1.27-4.58s.03-.06,.06-.07c3.24-1.44,6.11-1.87,7.74-1.99-.43,1.75-.93,3.49-1.41,5.23-1.49,.04-4.28,.31-7.53,1.54-.08,.03-.16-.04-.13-.12Z" />
					<path d="M1446.51,1809.97l1.46-4.52s.03-.05,.06-.07c3.26-1.33,6.16-1.65,7.79-1.7-.52,1.73-1.02,3.46-1.6,5.17-1.49-.02-4.3,.14-7.6,1.24-.08,.03-.15-.05-.13-.13Z" />
					<path d="M1452.3,1790.57l1.18-4.61s.03-.06,.06-.07c1.01-.48,2-.85,2.92-1.15l4.29,1.06-.81,3.21c-1.49,.06-4.27,.39-7.5,1.68-.08,.03-.16-.04-.13-.12Z" />
					<path d="M1453.79,1810.26l-.65,2-4.2-1.38c2.02-.48,3.73-.61,4.84-.62Z" />
					<path d="M1448.56,1803.54l1.37-4.55s.03-.05,.06-.07c3.27-1.38,6.13-1.76,7.77-1.84-.47,1.74-.97,3.47-1.5,5.2-1.49,0-4.29,.22-7.57,1.39-.08,.03-.15-.04-.13-.13Z" />
				</g>
			</g>
			<g>
				<path d="M1457.72,1757.68s.05,.08,.05,.12c-.24,1.53-.49,3.06-.78,4.58-.02,.1-.14,.14-.21,.08l-.58-.49c-.18-.15-.46-.13-.61,.05l-.43,.5c-.16,.18-.13,.46,.05,.61l1.36,1.16s.05,.08,.04,.12c-.29,1.52-.54,3.05-.87,4.56-.02,.1-.14,.14-.22,.07l-.57-.5c-.18-.16-.45-.14-.61,.04l-.44,.5c-.16,.18-.14,.45,.03,.61l1.33,1.19s.05,.08,.04,.13l-.95,4.54c-.02,.1-.14,.14-.22,.07l-.55-.51c-.18-.16-.45-.15-.61,.03l-.45,.49c-.16,.18-.15,.45,.02,.61l1.31,1.22s.05,.08,.04,.13l-1.04,4.52c-.02,.1-.14,.14-.22,.07l-.55-.52c-.17-.17-.45-.16-.61,.02l-.45,.48c-.17,.17-.16,.45,0,.61l.9,.87-5.77-1.42,1.2-.34c.23-.07,.36-.3,.3-.53l-.19-.63c-.07-.23-.31-.36-.54-.29l-.72,.21c-.1,.03-.19-.06-.17-.16l1.02-4.43s.04-.08,.09-.09l1.71-.52c.23-.07,.36-.31,.29-.54l-.19-.62c-.07-.23-.31-.35-.54-.28l-.72,.22c-.1,.03-.19-.05-.17-.15l.93-4.45s.04-.08,.09-.1l1.7-.56c.23-.08,.35-.32,.27-.54l-.21-.62c-.08-.22-.32-.35-.55-.27l-.72,.24c-.1,.03-.19-.05-.17-.15,.32-1.48,.56-2.98,.85-4.46,0-.05,.04-.08,.08-.1l1.68-.59c.23-.08,.34-.33,.26-.55l-.22-.61c-.08-.22-.33-.34-.55-.26l-.71,.25c-.09,.03-.19-.05-.17-.14,.29-1.49,.53-2.98,.76-4.48,0-.05,.04-.08,.08-.1l.5-.19,5.86,.95,.42,.34Z" />
				<g>
					<path d="M1447.96,1764.31c-.26,1.52-.58,3.02-.89,4.53-.02,.08-.12,.11-.17,.05-2.34-2.53-4.71-3.95-6.05-4.62l.91-4.95c1.44,.75,3.89,2.27,6.18,4.91,.02,.02,.03,.05,.02,.08Z" />
					<path d="M1445.26,1777.14c-.36,1.5-.67,3.01-1.06,4.49-.02,.08-.12,.1-.18,.04-2.21-2.62-4.59-4.14-5.88-4.86l1.13-4.91c1.41,.81,3.79,2.42,5.96,5.16,.02,.02,.03,.06,.02,.09Z" />
					<path d="M1449.12,1757.87c-.23,1.52-.54,3.03-.81,4.54-.01,.08-.12,.11-.17,.05-2.39-2.48-4.81-3.85-6.13-4.49l.49-3.04,4.36,.71c.74,.61,1.5,1.32,2.24,2.14,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1437.37,1780.05c.16-.63,.33-1.27,.47-1.91,.96,.57,2.37,1.53,3.82,2.96l-4.29-1.06Z" />
					<path d="M1446.67,1770.74c-.31,1.51-.62,3.01-.98,4.51-.02,.08-.12,.1-.17,.04-2.27-2.58-4.65-4.05-5.96-4.74l1.02-4.94c1.42,.78,3.84,2.35,6.07,5.04,.02,.02,.03,.06,.02,.08Z" />
				</g>
				<g>
					<path d="M1456.92,1770.85c.32-1.55,.65-3.11,.92-4.67,0-.03,.03-.06,.05-.07,3.09-1.7,5.94-2.36,7.56-2.6l-.98,5.33c-1.47,.16-4.25,.65-7.42,2.12-.08,.04-.16-.03-.14-.11Z" />
					<path d="M1453.97,1784.03c.41-1.53,.73-3.09,1.09-4.63,0-.03,.03-.06,.06-.07,3.16-1.57,6.05-2.11,7.68-2.29l-1.22,5.28c-1.49,.09-4.24,.48-7.47,1.83-.08,.03-.16-.04-.14-.12Z" />
					<path d="M1458.2,1764.22c.28-1.56,.6-3.12,.83-4.69,0-.03,.02-.06,.05-.07,.97-.56,1.91-1.02,2.8-1.38l4.36,.71-.53,3.27c-1.48,.18-4.25,.73-7.38,2.27-.07,.04-.16-.03-.14-.11Z" />
					<path d="M1461.24,1783.76c-.15,.69-.33,1.37-.5,2.05l-4.29-1.06c1.99-.63,3.68-.89,4.79-.99Z" />
					<path d="M1455.51,1777.46c.37-1.54,.69-3.1,1.01-4.65,0-.03,.03-.06,.05-.07,3.12-1.64,5.99-2.24,7.62-2.44l-1.1,5.31c-1.48,.12-4.24,.56-7.45,1.97-.08,.03-.16-.03-.14-.11Z" />
				</g>
			</g>
			<g>
				<path d="M1460.94,1730.97s.06,.07,.06,.12l-.4,4.63c0,.1-.12,.16-.21,.1l-.62-.44c-.2-.14-.47-.09-.6,.11l-.38,.54c-.14,.2-.09,.47,.11,.61l1.46,1.05s.06,.07,.06,.12l-.51,4.62c-.01,.1-.13,.15-.21,.09l-.61-.45c-.19-.14-.46-.1-.61,.09l-.39,.53c-.14,.19-.1,.47,.09,.61l1.44,1.08s.06,.07,.05,.12c-.16,1.54-.4,3.07-.6,4.61-.01,.1-.13,.15-.21,.09l-.6-.47c-.19-.15-.46-.11-.61,.08l-.4,.52c-.15,.19-.11,.46,.08,.61l1.41,1.11s.06,.08,.05,.12c-.19,1.54-.44,3.06-.69,4.59-.02,.1-.13,.15-.21,.08l-.59-.48c-.19-.15-.46-.12-.61,.07l-.41,.51c-.15,.19-.12,.46,.06,.61l.97,.79-5.86-.95,1.16-.44c.22-.08,.33-.33,.25-.56l-.24-.61c-.09-.22-.34-.33-.56-.25l-.71,.27c-.09,.04-.19-.04-.18-.14,.24-1.49,.49-2.99,.68-4.49,0-.05,.04-.08,.08-.1l1.65-.66c.22-.09,.33-.34,.24-.56l-.25-.6c-.09-.22-.34-.32-.57-.23l-.7,.28c-.09,.04-.19-.04-.18-.14,.19-1.5,.43-3,.59-4.5,0-.05,.03-.09,.08-.1l1.64-.7c.22-.09,.32-.35,.22-.57l-.26-.6c-.1-.22-.35-.32-.57-.22l-.69,.3c-.09,.04-.19-.03-.18-.13l.5-4.51s.03-.09,.08-.11l1.62-.73c.22-.1,.31-.35,.21-.57l-.28-.59c-.1-.22-.36-.31-.58-.21l-.69,.31c-.09,.04-.19-.03-.19-.13l.4-4.52s.03-.09,.08-.11l.49-.23,5.92,.44,.44,.31Z" />
				<g>
					<path d="M1451.77,1738.41l-.51,4.58c0,.08-.11,.11-.17,.06-2.56-2.33-5.06-3.54-6.43-4.09,.2-1.66,.36-3.33,.49-5,1.49,.62,4.11,1.93,6.59,4.37,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1450.15,1751.4l-.72,4.56c-.01,.08-.11,.11-.17,.05-2.45-2.43-4.89-3.75-6.23-4.36,.27-1.66,.46-3.32,.7-4.98,1.47,.69,3.99,2.1,6.4,4.65,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1452.36,1731.89l-.4,4.59c0,.08-.1,.12-.17,.06-2.61-2.27-5.14-3.43-6.53-3.95l.27-3.06,4.4,.33c.78,.54,1.59,1.18,2.39,1.94,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1442.49,1754.93l.31-1.93c.99,.49,2.47,1.33,4.05,2.64l-4.36-.71Z" />
					<path d="M1451.03,1744.91l-.62,4.57c-.01,.08-.11,.11-.17,.06-2.5-2.38-4.98-3.65-6.33-4.23,.24-1.66,.41-3.33,.6-4.99,1.48,.66,4.05,2.02,6.5,4.51,.02,.02,.03,.05,.03,.08Z" />
				</g>
				<g>
					<path d="M1461.24,1744.17l.53-4.73s.02-.06,.05-.08c2.96-1.95,5.7-2.86,7.3-3.24-.15,1.81-.32,3.61-.54,5.41-1.46,.28-4.18,1-7.19,2.74-.07,.04-.16-.02-.15-.1Z" />
					<path d="M1459.35,1757.56l.74-4.7s.02-.06,.05-.07c3-1.83,5.82-2.61,7.43-2.92-.25,1.79-.47,3.59-.76,5.38-1.47,.21-4.23,.82-7.32,2.43-.07,.04-.16-.02-.15-.11Z" />
					<path d="M1461.97,1737.45l.42-4.75s.02-.06,.04-.08c.92-.64,1.84-1.17,2.7-1.62l4.4,.33-.29,3.31c-1.46,.31-4.16,1.1-7.12,2.9-.07,.04-.16-.01-.15-.1Z" />
					<path d="M1466.59,1756.7l-.33,2.08-4.36-.71c1.92-.79,3.59-1.19,4.69-1.38Z" />
					<path d="M1460.37,1750.88l.64-4.72s.02-.06,.05-.08c2.97-1.89,5.76-2.73,7.36-3.08-.21,1.8-.38,3.6-.65,5.39-1.47,.25-4.21,.91-7.26,2.58-.07,.04-.16-.02-.15-.1Z" />
				</g>
			</g>
			<g>
				<path d="M1461.73,1704.05s.07,.07,.07,.11l.06,4.65c0,.1-.11,.17-.2,.11l-.65-.38c-.21-.12-.47-.05-.59,.16l-.34,.57c-.12,.21-.05,.47,.15,.6l1.54,.91s.06,.07,.06,.12l-.06,4.65c0,.1-.11,.16-.2,.11l-.65-.4c-.21-.13-.47-.06-.59,.15l-.34,.56c-.13,.21-.07,.47,.14,.6l1.51,.95s.06,.07,.06,.12l-.18,4.65c0,.1-.12,.16-.2,.11l-.64-.41c-.2-.13-.47-.07-.6,.13l-.35,.56c-.13,.2-.07,.47,.14,.6l1.49,.98s.06,.07,.06,.12l-.29,4.64c0,.1-.12,.16-.2,.1l-.63-.43c-.2-.13-.47-.08-.6,.12l-.36,.55c-.13,.2-.08,.47,.12,.61l1.04,.71-5.92-.44,1.12-.54c.21-.1,.3-.36,.19-.58l-.29-.59c-.11-.21-.37-.3-.58-.2l-.68,.33c-.09,.04-.2-.03-.19-.13l.28-4.53s.03-.09,.07-.11l1.6-.8c.21-.11,.29-.37,.18-.58l-.31-.58c-.11-.21-.37-.29-.59-.18l-.67,.34c-.09,.05-.2-.02-.19-.12l.17-4.53s.03-.09,.07-.11l1.58-.84c.21-.11,.29-.37,.18-.58l-.32-.57c-.12-.21-.38-.28-.59-.17l-.66,.36c-.09,.05-.2-.02-.2-.12l.06-4.54s.03-.09,.07-.11l1.56-.88c.21-.12,.28-.38,.17-.59l-.32-.56c-.12-.21-.39-.28-.59-.16l-.66,.37c-.09,.05-.2-.01-.2-.11l-.06-4.53s.02-.09,.06-.11l.46-.27,5.94-.11,.47,.26Z" />
				<g>
					<path d="M1453.28,1712.3c-.03,1.53,0,3.07-.09,4.6,0,.08-.1,.12-.16,.07-2.71-2.09-5.37-3.07-6.77-3.49l.06-5.01c1.55,.48,4.22,1.54,6.93,3.74,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1452.81,1725.37l-.29,4.6c0,.08-.1,.12-.17,.07-2.67-2.21-5.23-3.31-6.62-3.8,.09-1.67,.24-3.34,.28-5.01,1.54,.55,4.19,1.74,6.76,4.06,.02,.02,.04,.05,.03,.08Z" />
					<path d="M1453.29,1705.77c.02,1.53,.05,3.07,.02,4.6,0,.08-.1,.13-.16,.08-2.76-2.02-5.42-2.94-6.84-3.33l-.04-3.06,4.42-.08c.83,.47,1.7,1.03,2.57,1.71,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1445.53,1729.54l.12-1.95c1.04,.4,2.58,1.11,4.28,2.28l-4.4-.33Z" />
					<path d="M1453.12,1718.83c-.06,1.53-.1,3.07-.19,4.6,0,.08-.1,.12-.17,.07-2.69-2.15-5.3-3.19-6.7-3.65,.05-1.67,.15-3.34,.17-5.01,1.56,.52,4.2,1.64,6.84,3.91,.02,.02,.04,.05,.04,.08Z" />
				</g>
				<g>
					<path d="M1463.25,1717.19c.08-1.59,.06-3.18,.09-4.77,0-.03,.02-.06,.04-.08,2.75-2.22,5.46-3.37,7.01-3.9l-.06,5.45c-1.44,.41-4.03,1.38-6.92,3.38-.07,.05-.16,0-.16-.09Z" />
					<path d="M1462.56,1730.71l.3-4.76s.02-.06,.04-.08c2.88-2.08,5.6-3.11,7.17-3.57-.05,1.82-.2,3.62-.3,5.44-1.45,.34-4.13,1.19-7.05,3.06-.07,.04-.16,0-.16-.09Z" />
					<path d="M1463.36,1710.42c.04-1.59,0-3.18-.02-4.77,0-.03,.01-.06,.04-.08,.86-.72,1.71-1.34,2.53-1.86l4.42-.08,.05,3.33c-1.42,.44-4.01,1.48-6.85,3.54-.07,.05-.16,0-.16-.08Z" />
					<path d="M1469.67,1729.23l-.13,2.11-4.4-.33c1.83-.96,3.46-1.5,4.54-1.78Z" />
					<path d="M1462.98,1723.96c.09-1.59,.13-3.18,.19-4.76,0-.03,.02-.06,.04-.08,2.81-2.15,5.55-3.24,7.09-3.73-.03,1.82-.12,3.63-.18,5.45-1.44,.38-4.08,1.28-6.99,3.22-.07,.05-.16,0-.16-.09Z" />
				</g>
			</g>
			<g>
				<path d="M1459.95,1677.17s.07,.06,.08,.11c.2,1.54,.36,3.08,.5,4.63,0,.1-.09,.18-.19,.13l-.69-.32c-.22-.1-.47,0-.57,.22l-.27,.6c-.1,.22,0,.48,.21,.58l1.63,.75s.07,.06,.08,.11c.15,1.54,.3,3.09,.38,4.64,0,.1-.1,.17-.19,.13l-.68-.33c-.21-.11-.47-.01-.58,.2l-.29,.6c-.11,.22-.02,.48,.2,.58l1.6,.8s.07,.06,.07,.11c.1,1.55,.23,3.09,.27,4.64,0,.1-.1,.17-.19,.12l-.67-.35c-.21-.11-.47-.03-.58,.19l-.31,.59c-.11,.21-.03,.48,.18,.59l1.58,.83s.07,.06,.07,.11c.05,1.55,.15,3.1,.16,4.65,0,.1-.11,.17-.2,.12l-.66-.37c-.21-.12-.47-.04-.59,.17l-.32,.58c-.12,.21-.04,.48,.17,.59l1.09,.61-5.94,.11,1.07-.64c.21-.12,.27-.39,.15-.59l-.34-.56c-.12-.2-.39-.27-.59-.14l-.65,.39c-.09,.05-.2,0-.2-.11-.02-1.51-.11-3.02-.16-4.53,0-.05,.02-.09,.06-.11l1.51-.95c.2-.13,.26-.39,.14-.59l-.35-.55c-.13-.2-.4-.26-.6-.13l-.64,.41c-.09,.05-.2,0-.2-.1-.04-1.51-.17-3.02-.27-4.53,0-.05,.02-.09,.06-.12l1.49-.99c.2-.13,.25-.4,.12-.6l-.37-.54c-.13-.2-.4-.25-.6-.11l-.63,.42c-.08,.06-.2,0-.2-.1-.08-1.51-.23-3.01-.37-4.52,0-.05,.02-.09,.05-.12l1.46-1.02c.2-.14,.24-.41,.1-.6l-.38-.53c-.14-.19-.41-.24-.6-.1l-.62,.44c-.08,.06-.2,0-.21-.09-.13-1.5-.28-3.01-.48-4.5,0-.05,.01-.09,.05-.12l.43-.32,5.9-.7,.5,.22Z" />
				<g>
					<path d="M1452.36,1686.2c.17,1.52,.22,3.06,.34,4.59,0,.08-.09,.13-.16,.09-2.93-1.81-5.6-2.53-7.05-2.82l-.47-4.97c1.59,.33,4.36,1.11,7.29,3.04,.03,.02,.04,.04,.05,.07Z" />
					<path d="M1453.14,1699.23c.08,1.53,.11,3.06,.12,4.6,0,.08-.09,.13-.16,.08-2.82-1.96-5.47-2.81-6.91-3.16l-.2-5c1.57,.41,4.3,1.33,7.11,3.4,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1451.73,1679.71c.18,1.52,.3,3.05,.45,4.57,0,.08-.08,.13-.15,.09-2.98-1.74-5.67-2.39-7.12-2.63-.08-1.01-.24-2.02-.35-3.03l4.39-.52c.88,.38,1.8,.85,2.75,1.44,.03,.02,.04,.04,.05,.07Z" />
					<path d="M1446.26,1704.06l-.03-1.95c1.06,.3,2.66,.86,4.44,1.87l-4.42,.08Z" />
					<path d="M1452.83,1692.71c.12,1.53,.17,3.06,.23,4.59,0,.08-.09,.13-.16,.09-2.88-1.89-5.52-2.67-6.98-2.99l-.33-4.98c1.58,.37,4.33,1.22,7.2,3.22,.03,.02,.04,.05,.04,.08Z" />
				</g>
				<g>
					<path d="M1462.74,1690.11c-.12-1.59-.18-3.18-.35-4.76,0-.03,0-.06,.03-.08,2.51-2.48,5.07-3.89,6.57-4.57l.51,5.45c-1.39,.55-3.93,1.77-6.59,4.04-.06,.05-.16,.01-.17-.07Z" />
					<path d="M1463.32,1703.65c0-1.59-.05-3.18-.13-4.77,0-.03,.01-.06,.04-.08,2.64-2.35,5.28-3.63,6.8-4.23l.22,5.46c-1.4,.48-3.99,1.57-6.77,3.71-.07,.05-.16,0-.16-.08Z" />
					<path d="M1462.2,1683.35c-.16-1.58-.28-3.17-.47-4.75,0-.03,0-.06,.03-.08,.77-.81,1.56-1.51,2.31-2.11l4.39-.52c.13,1.11,.29,2.21,.39,3.32-1.37,.58-3.89,1.87-6.48,4.21-.06,.06-.16,.02-.17-.07Z" />
					<path d="M1470.29,1701.51l.03,2.12-4.42,.08c1.75-1.13,3.33-1.82,4.39-2.2Z" />
					<path d="M1463.11,1696.88c-.06-1.59-.11-3.18-.24-4.77,0-.03,0-.06,.03-.08,2.57-2.41,5.18-3.76,6.69-4.4l.36,5.45c-1.38,.51-3.97,1.67-6.68,3.87-.06,.05-.16,0-.16-.07Z" />
				</g>
			</g>
			<g>
				<path d="M1455.42,1650.58s.08,.05,.09,.1l.97,4.56c.02,.1-.07,.18-.17,.15l-.72-.24c-.23-.08-.47,.05-.55,.28l-.2,.63c-.07,.23,.05,.47,.28,.55l1.7,.58s.08,.05,.09,.1c.31,1.52,.56,3.05,.85,4.58,.02,.1-.08,.18-.17,.15l-.71-.26c-.23-.08-.47,.04-.55,.26l-.22,.62c-.08,.23,.04,.48,.26,.56l1.69,.62s.08,.06,.09,.1c.29,1.53,.48,3.07,.73,4.6,.02,.1-.08,.18-.18,.14l-.7-.28c-.22-.09-.47,.02-.56,.25l-.24,.62c-.09,.22,.02,.48,.25,.57l1.67,.67s.08,.06,.08,.1c.25,1.53,.42,3.08,.61,4.62,.01,.1-.09,.18-.18,.14l-.7-.3c-.22-.09-.47,.01-.57,.23l-.26,.61c-.09,.22,0,.48,.23,.57l1.15,.5-5.9,.7,1-.74c.19-.14,.23-.41,.09-.6l-.4-.52c-.14-.19-.42-.23-.61-.08l-.6,.45c-.08,.06-.2,.01-.21-.09-.19-1.5-.35-3-.59-4.49,0-.05,.01-.09,.05-.12l1.4-1.1c.19-.15,.22-.42,.07-.6l-.41-.51c-.15-.19-.42-.21-.61-.07l-.59,.47c-.08,.06-.2,.02-.21-.08-.24-1.49-.42-2.99-.71-4.47,0-.05,0-.09,.04-.12l1.37-1.13c.18-.15,.21-.42,.05-.61l-.42-.5c-.15-.18-.43-.2-.61-.05l-.58,.48c-.08,.06-.2,.02-.21-.08-.28-1.48-.52-2.97-.82-4.45,0-.05,0-.09,.04-.12l1.33-1.17c.18-.16,.19-.43,.03-.61l-.44-.49c-.16-.18-.43-.19-.61-.03l-.56,.5c-.08,.07-.2,.03-.22-.07l-.94-4.43s0-.09,.04-.12l.39-.36,5.79-1.33,.52,.16Z" />
				<g>
					<path d="M1448.81,1660.37l.82,4.52c.01,.08-.07,.14-.14,.1-3.12-1.49-5.86-1.92-7.33-2.06-.28-1.64-.62-3.26-.97-4.88,1.61,.15,4.49,.63,7.57,2.24,.03,.01,.05,.04,.05,.07Z" />
					<path d="M1450.94,1673.23l.56,4.56c0,.08-.08,.14-.15,.1-3.02-1.66-5.74-2.24-7.2-2.45-.18-1.65-.48-3.29-.71-4.93,1.61,.24,4.42,.88,7.45,2.65,.03,.02,.04,.04,.05,.07Z" />
					<path d="M1447.49,1653.99l.95,4.49c.02,.08-.07,.14-.14,.11-3.16-1.41-5.92-1.76-7.39-1.85-.22-.99-.41-1.98-.64-2.97l4.31-.99c.92,.28,1.87,.65,2.86,1.14,.03,.01,.05,.04,.05,.07Z" />
					<path d="M1444.55,1678.71l-.24-1.92c1.09,.19,2.74,.59,4.62,1.41l-4.39,.52Z" />
					<path d="M1449.96,1666.79l.69,4.54c.01,.08-.07,.14-.15,.1-3.07-1.58-5.8-2.08-7.26-2.25-.23-1.65-.55-3.27-.84-4.9,1.61,.2,4.46,.76,7.51,2.44,.03,.01,.04,.04,.05,.07Z" />
				</g>
				<g>
					<path d="M1459.54,1663.18l-.85-4.71s0-.06,.02-.09c2.26-2.73,4.62-4.41,6.04-5.25,.39,1.79,.76,3.58,1.07,5.38-1.32,.69-3.72,2.18-6.1,4.71-.06,.06-.16,.03-.17-.05Z" />
					<path d="M1461.49,1676.61l-.58-4.74s0-.06,.03-.09c2.36-2.6,4.85-4.15,6.3-4.91,.26,1.81,.58,3.61,.78,5.42-1.36,.62-3.83,1.97-6.36,4.38-.06,.06-.16,.02-.17-.06Z" />
					<path d="M1458.3,1656.5l-.99-4.68s0-.06,.02-.09c.69-.89,1.42-1.67,2.1-2.35l4.31-.99c.26,1.09,.47,2.19,.71,3.28-1.3,.73-3.65,2.28-5.97,4.87-.06,.06-.16,.03-.17-.05Z" />
					<path d="M1468.2,1673.77l.26,2.11-4.39,.52c1.62-1.3,3.12-2.14,4.13-2.63Z" />
					<path d="M1460.6,1669.88l-.72-4.73s0-.06,.03-.09c2.31-2.67,4.74-4.28,6.17-5.08,.32,1.8,.67,3.6,.92,5.41-1.34,.65-3.78,2.08-6.23,4.54-.06,.06-.16,.03-.17-.06Z" />
				</g>
			</g>
			<g>
				<path d="M1447.92,1624.65s.08,.04,.1,.09l1.51,4.41c.03,.1-.05,.19-.15,.17l-.75-.16c-.24-.05-.46,.1-.51,.34l-.13,.64c-.04,.23,.1,.47,.33,.52l1.74,.39s.08,.04,.1,.09l1.37,4.46c.03,.1-.06,.19-.15,.17l-.74-.18c-.23-.06-.47,.09-.52,.32l-.15,.64c-.05,.23,.09,.47,.33,.52l1.73,.44s.08,.05,.09,.09l1.24,4.5c.03,.1-.06,.19-.16,.16l-.73-.2c-.23-.06-.47,.07-.53,.31l-.17,.64c-.06,.23,.08,.47,.31,.53l1.73,.48s.08,.05,.09,.1l1.1,4.53c.02,.1-.07,.19-.16,.16l-.73-.22c-.23-.07-.47,.06-.54,.29l-.18,.63c-.07,.23,.06,.47,.29,.54l1.2,.37-5.79,1.33,.91-.84c.17-.16,.18-.43,.02-.61l-.45-.47c-.17-.17-.44-.18-.61-.02l-.55,.51c-.07,.07-.19,.03-.22-.06l-1.07-4.4s0-.09,.04-.13l1.27-1.24c.17-.17,.17-.44,0-.61l-.47-.46c-.17-.17-.44-.17-.61,0l-.53,.53c-.07,.07-.19,.04-.22-.06l-1.2-4.36s0-.09,.03-.13l1.25-1.28c.16-.17,.15-.44-.02-.61l-.48-.45c-.18-.16-.45-.15-.61,.02l-.52,.54c-.07,.07-.19,.04-.22-.05l-1.33-4.32s0-.09,.03-.13l1.21-1.32c.16-.18,.15-.45-.03-.61l-.5-.43c-.18-.16-.45-.14-.61,.04l-.5,.56c-.07,.07-.19,.05-.22-.04l-1.47-4.28s0-.09,.03-.13l.35-.41,5.6-1.98,.53,.1Z" />
				<g>
					<path d="M1442.47,1635.12l1.34,4.38c.02,.08-.06,.15-.13,.12-3.25-1.14-6.05-1.25-7.52-1.21-.5-1.57-.94-3.17-1.49-4.73,1.64-.04,4.52,.12,7.75,1.37,.03,.01,.05,.03,.06,.06Z" />
					<path d="M1445.99,1647.65l1.08,4.46c.02,.08-.06,.14-.14,.11-3.2-1.32-5.97-1.59-7.44-1.64-.39-1.61-.77-3.22-1.23-4.81,1.62,.06,4.53,.37,7.67,1.81,.03,.01,.05,.04,.06,.07Z" />
					<path d="M1440.44,1628.94c.48,1.45,1.02,2.88,1.46,4.34,.02,.08-.05,.15-.13,.12-3.26-1.05-6.08-1.07-7.55-1l-.98-2.86,4.17-1.47c.93,.18,1.93,.44,2.97,.8,.03,.01,.05,.03,.06,.06Z" />
					<path d="M1440.27,1653.77l-.46-1.88c1.11,.07,2.79,.28,4.76,.89l-4.31,.99Z" />
					<path d="M1444.32,1641.36l1.22,4.42c.02,.08-.06,.15-.13,.12-3.24-1.23-6.02-1.42-7.49-1.43-.45-1.59-.85-3.2-1.36-4.77,1.63,.01,4.53,.24,7.71,1.6,.03,.01,.05,.04,.06,.06Z" />
				</g>
				<g>
					<path d="M1453.47,1636.7l-1.4-4.58s0-.06,.01-.09c1.95-2.98,4.14-4.92,5.43-5.91,.61,1.73,1.1,3.5,1.66,5.25-1.24,.84-3.42,2.58-5.53,5.36-.05,.07-.15,.05-.18-.03Z" />
					<path d="M1456.88,1649.86l-1.13-4.65s0-.06,.02-.09c2.13-2.86,4.38-4.67,5.75-5.58,.51,1.76,.93,3.55,1.36,5.33-1.28,.76-3.58,2.38-5.82,5.04-.05,.06-.16,.04-.18-.04Z" />
					<path d="M1451.48,1630.2c-.47-1.53-1.02-3.03-1.53-4.54-.01-.03,0-.06,0-.09,.59-.96,1.2-1.82,1.81-2.58l4.16-1.47,1.09,3.18c-1.22,.87-3.33,2.67-5.37,5.52-.05,.07-.15,.05-.18-.03Z" />
					<path d="M1463.23,1646.32l.5,2.08-4.31,.99c1.47-1.46,2.86-2.47,3.8-3.06Z" />
					<path d="M1455.27,1643.26l-1.27-4.62s0-.06,.02-.09c2.04-2.92,4.27-4.8,5.59-5.75,.56,1.75,1.01,3.53,1.51,5.29-1.26,.8-3.51,2.48-5.67,5.2-.05,.07-.15,.04-.18-.04Z" />
				</g>
			</g>
			<g>
				<path d="M1437.44,1599.75s.09,.03,.11,.07c.68,1.4,1.41,2.78,2.03,4.2,.04,.09-.03,.2-.13,.19l-.75-.07c-.24-.02-.45,.16-.47,.4l-.06,.66c-.02,.24,.15,.45,.39,.48l1.78,.18s.09,.03,.11,.08c.63,1.42,1.31,2.82,1.91,4.26,.04,.09-.04,.2-.14,.18l-.75-.09c-.24-.03-.45,.14-.48,.38l-.08,.66c-.03,.24,.14,.46,.38,.49l1.77,.23s.09,.04,.1,.08l1.78,4.31c.04,.1-.04,.2-.14,.18l-.74-.12c-.24-.03-.46,.13-.49,.37l-.1,.66c-.04,.24,.12,.46,.36,.5l1.76,.29s.09,.04,.1,.08l1.65,4.36c.04,.1-.04,.19-.14,.17l-.75-.14c-.24-.04-.46,.12-.5,.35l-.11,.65c-.04,.24,.11,.47,.34,.51l1.22,.23-5.6,1.98,.82-.94c.16-.18,.14-.45-.04-.61l-.5-.42c-.19-.15-.46-.13-.61,.06l-.48,.57c-.06,.08-.19,.06-.23-.04l-1.6-4.23s0-.09,.02-.13l1.13-1.39c.15-.19,.12-.46-.06-.61l-.51-.41c-.18-.15-.46-.11-.61,.07l-.48,.59c-.06,.08-.19,.06-.22-.03l-1.73-4.18s-.01-.09,.02-.13l1.09-1.42c.15-.19,.11-.46-.08-.6l-.52-.39c-.19-.14-.46-.1-.6,.09l-.46,.6c-.06,.08-.19,.07-.22-.03-.58-1.39-1.23-2.75-1.85-4.13-.02-.04-.01-.09,.01-.13l1.04-1.45c.14-.19,.09-.46-.1-.6l-.53-.37c-.19-.14-.46-.09-.6,.11l-.44,.62c-.06,.08-.18,.07-.22-.02-.6-1.38-1.31-2.71-1.97-4.07-.02-.04-.02-.09,0-.13l.3-.45,5.32-2.63,.54,.04Z" />
				<g>
					<path d="M1433.28,1610.79l.95,2.08,.88,2.12c.03,.07-.04,.15-.12,.13-3.34-.76-6.11-.54-7.58-.33l-2.04-4.5c1.6-.22,4.47-.41,7.85,.44,.03,0,.05,.03,.07,.06Z" />
					<path d="M1438.23,1622.82c.53,1.43,1.11,2.85,1.58,4.3,.03,.08-.05,.15-.13,.13-3.27-.96-6.11-.89-7.57-.78l-1.75-4.63c1.62-.13,4.48-.14,7.8,.92,.03,0,.05,.03,.06,.06Z" />
					<path d="M1430.54,1604.9c.7,1.36,1.32,2.75,1.95,4.14,.03,.07-.03,.15-.11,.14-3.38-.65-6.11-.37-7.58-.1l-1.34-2.71,3.96-1.96c.95,.06,1.98,.2,3.06,.43,.03,0,.06,.03,.07,.05Z" />
					<path d="M1433.24,1629.54c-.21-.61-.4-1.22-.64-1.82,1.12-.06,2.81-.04,4.81,.34l-4.17,1.47Z" />
					<path d="M1435.85,1616.77c.59,1.41,1.18,2.82,1.71,4.25,.03,.08-.04,.15-.12,.13-3.3-.86-6.11-.72-7.58-.55l-1.9-4.57c1.61-.17,4.48-.27,7.83,.68,.03,0,.05,.03,.07,.06Z" />
				</g>
				<g>
					<path d="M1444.38,1611.08l-.92-2.21-.99-2.18s-.01-.06,0-.09c1.55-3.18,3.51-5.38,4.69-6.52l2.28,5.02c-1.11,.97-3.1,2.97-4.88,5.99-.04,.07-.15,.06-.18-.01Z" />
					<path d="M1449.3,1623.76c-.5-1.52-1.1-3-1.66-4.5-.01-.03,0-.06,0-.09,1.75-3.08,3.84-5.16,5.09-6.23l1.96,5.15c-1.19,.91-3.24,2.76-5.22,5.68-.05,.07-.15,.06-.18-.02Z" />
					<path d="M1441.63,1604.85c-.66-1.46-1.31-2.91-2.04-4.34-.01-.03-.01-.06,0-.09,.46-1.02,.97-1.95,1.48-2.77l3.96-1.96,1.49,3.02c-1.08,1-3.03,3.07-4.71,6.14-.04,.07-.15,.07-.18,0Z" />
					<path d="M1455.22,1619.5c.26,.66,.48,1.35,.71,2.02l-4.16,1.47c1.31-1.63,2.59-2.79,3.45-3.49Z" />
					<path d="M1446.93,1617.38c-.55-1.5-1.17-2.97-1.78-4.45-.01-.03-.01-.06,0-.09,1.65-3.13,3.67-5.27,4.89-6.37l2.12,5.09c-1.15,.94-3.17,2.86-5.05,5.84-.04,.07-.15,.06-.18-.02Z" />
				</g>
			</g>
			<g>
				<path d="M1424.04,1576.29s.09,.02,.12,.06c.88,1.28,1.67,2.63,2.5,3.94,.05,.09,0,.2-.11,.2l-.76,.02c-.24,0-.43,.21-.42,.45l.03,.66c0,.24,.21,.43,.45,.42l1.8-.05s.09,.02,.12,.06c.84,1.31,1.61,2.67,2.39,4.01,.05,.09-.01,.2-.11,.2h-.76c-.24,0-.43,.2-.43,.44v.66c0,.24,.2,.44,.44,.44h1.8s.09,.04,.11,.08l1.17,2.02,1.1,2.06c.05,.09-.02,.2-.12,.2l-.76-.02c-.24,0-.44,.18-.44,.42l-.02,.66c0,.24,.18,.44,.42,.45l1.79,.07s.09,.03,.11,.07c.74,1.37,1.48,2.74,2.15,4.14,.05,.09-.02,.2-.12,.19l-.75-.05c-.24-.01-.44,.17-.46,.41l-.04,.66c-.01,.24,.17,.45,.41,.46l1.25,.08-5.32,2.63,.69-1.03c.13-.2,.08-.47-.12-.6l-.54-.36c-.2-.13-.46-.07-.6,.13l-.42,.63c-.06,.08-.18,.08-.23-.01-.65-1.36-1.37-2.68-2.08-4.01-.02-.04-.02-.09,0-.13l.95-1.51c.13-.2,.06-.47-.14-.59l-.56-.34c-.2-.12-.47-.06-.59,.15l-.4,.64c-.05,.09-.18,.08-.23,0l-1.07-1.99-1.13-1.96s-.02-.09,0-.13l.9-1.53c.12-.21,.05-.47-.16-.59l-.57-.32c-.21-.12-.47-.04-.59,.16l-.38,.65c-.05,.09-.18,.09-.23,0-.76-1.3-1.49-2.62-2.31-3.88-.02-.04-.03-.09,0-.13l.85-1.56c.11-.21,.03-.47-.18-.58l-.58-.3c-.21-.11-.47-.03-.58,.18l-.36,.66c-.05,.09-.17,.09-.23,0-.81-1.27-1.57-2.57-2.42-3.81-.03-.04-.03-.09,0-.13l.24-.48,4.96-3.27,.54-.03Z" />
				<g>
					<path d="M1421.27,1587.75l2.3,3.96c.04,.07-.02,.16-.1,.15-3.43-.33-6.14,.21-7.57,.6-.81-1.44-1.73-2.8-2.59-4.21,1.57-.42,4.4-.96,7.88-.54,.03,0,.06,.02,.07,.05Z" />
					<path d="M1427.62,1599.09c.74,1.33,1.38,2.72,2.07,4.09,.04,.07-.03,.15-.11,.14-3.39-.55-6.13-.17-7.57,.12l-2.33-4.35c1.59-.32,4.44-.69,7.88-.05,.03,0,.06,.02,.07,.05Z" />
					<path d="M1417.82,1582.24l2.43,3.88c.04,.07-.01,.16-.1,.15-3.44-.22-6.14,.41-7.55,.83-.53-.86-1.06-1.71-1.64-2.54l3.69-2.43c.95-.05,2.01-.06,3.09,.05,.03,0,.06,.02,.07,.05Z" />
					<path d="M1423.46,1606.37l-.85-1.72c1.09-.19,2.77-.37,4.81-.24l-3.96,1.96Z" />
					<path d="M1424.53,1593.37c.76,1.32,1.45,2.68,2.18,4.02,.04,.07-.02,.16-.1,.14-3.41-.44-6.14,.02-7.57,.36-.79-1.44-1.65-2.85-2.46-4.28,1.58-.37,4.42-.82,7.88-.29,.03,0,.06,.02,.07,.05Z" />
				</g>
				<g>
					<path d="M1432.29,1586.7l-2.41-4.15s-.02-.06-.01-.09c1.14-3.33,2.8-5.75,3.83-7.02,.96,1.57,1.99,3.1,2.89,4.7-1,1.11-2.74,3.34-4.12,6.54-.03,.08-.14,.08-.18,0Z" />
					<path d="M1438.7,1598.71c-.72-1.43-1.39-2.88-2.16-4.28-.01-.03-.02-.06,0-.09,1.35-3.26,3.16-5.57,4.27-6.78l2.61,4.87c-1.07,1.04-2.95,3.17-4.53,6.29-.04,.07-.14,.07-.18,0Z" />
					<path d="M1428.82,1580.85l-2.54-4.07s-.02-.06-.01-.09c.35-1.08,.71-2.05,1.12-2.93l3.69-2.43c.64,.93,1.24,1.88,1.83,2.83-.97,1.14-2.63,3.42-3.91,6.66-.03,.08-.14,.09-.18,.01Z" />
					<path d="M1444.08,1593.77l.95,1.92-3.96,1.96c1.1-1.77,2.22-3.08,3.01-3.88Z" />
					<path d="M1435.59,1592.66c-.77-1.4-1.49-2.83-2.29-4.21-.02-.03-.02-.06,0-.09,1.24-3.3,2.98-5.66,4.05-6.91,.92,1.6,1.86,3.17,2.75,4.79-1.04,1.08-2.84,3.25-4.32,6.42-.04,.08-.14,.08-.18,0Z" />
				</g>
			</g>
			<g>
				<path d="M1407.86,1554.66s.09,0,.12,.05l2.99,3.59c.07,.08,.02,.2-.08,.21l-.75,.12c-.24,.04-.4,.26-.36,.5l.11,.65c.04,.24,.27,.4,.5,.36l1.76-.25s.09,.01,.12,.05l2.86,3.69c.06,.08,.02,.2-.09,.21l-.76,.09c-.24,.03-.41,.25-.37,.49l.09,.65c.03,.24,.25,.4,.49,.38l1.78-.21s.09,.02,.12,.05l2.74,3.78c.06,.08,0,.2-.09,.21l-.76,.07c-.24,.02-.41,.24-.39,.47l.07,.66c.02,.24,.24,.41,.48,.39l1.8-.16s.09,.02,.12,.06l2.62,3.87c.06,.09,0,.2-.1,.2l-.76,.05c-.24,.02-.42,.22-.4,.46l.05,.66c.02,.24,.23,.42,.47,.41l1.25-.07-4.96,3.27,.56-1.11c.11-.21,.02-.47-.2-.57l-.59-.28c-.21-.1-.47-.01-.58,.2l-.34,.67c-.04,.09-.17,.1-.23,.02l-2.53-3.74s-.03-.09-.01-.13l.75-1.61c.1-.22,0-.47-.21-.57l-.6-.27c-.22-.1-.47,0-.57,.22l-.31,.68c-.04,.09-.17,.1-.23,.02l-2.65-3.66s-.03-.09-.01-.13l.71-1.63c.09-.22-.01-.47-.23-.56l-.61-.25c-.22-.09-.47,.02-.57,.24l-.29,.69c-.04,.09-.16,.11-.23,.03l-2.77-3.57s-.04-.09-.02-.13l.67-1.66c.09-.23-.02-.47-.25-.55l-.62-.23c-.22-.08-.47,.03-.56,.25l-.27,.7c-.04,.09-.16,.11-.22,.04l-2.89-3.47s-.04-.08-.02-.13l.19-.51,4.53-3.84,.53-.09Z" />
				<g>
					<path d="M1406.49,1566.38l2.81,3.62c.05,.06,0,.16-.08,.16-3.46,.1-6.1,.98-7.47,1.54-1.03-1.29-1.99-2.63-3.06-3.88,1.52-.62,4.27-1.5,7.73-1.47,.03,0,.06,.01,.08,.04Z" />
					<path d="M1414.21,1576.84l2.56,3.8c.05,.07,0,.16-.09,.15-3.45-.12-6.13,.6-7.53,1.07l-1.38-2.05-1.45-2c1.53-.51,4.37-1.26,7.82-1.02,.03,0,.06,.02,.08,.04Z" />
					<path d="M1402.4,1561.33c.97,1.18,1.97,2.33,2.91,3.53,.05,.06,0,.16-.08,.16-3.42,.18-6.07,1.17-7.41,1.76l-1.93-2.32,3.37-2.86c.93-.16,1.96-.28,3.06-.31,.03,0,.06,.01,.08,.04Z" />
					<path d="M1410.97,1584.57l-1.07-1.59c1.06-.32,2.71-.71,4.76-.84l-3.69,2.43Z" />
					<path d="M1410.43,1571.55l2.68,3.71c.05,.07,0,.16-.09,.16-3.46,0-6.12,.79-7.5,1.31-.96-1.34-1.92-2.68-2.95-3.97,1.52-.56,4.33-1.39,7.78-1.25,.03,0,.06,.02,.08,.04Z" />
				</g>
				<g>
					<path d="M1417.34,1563.96l-2.94-3.79s-.03-.06-.02-.09c.77-3.48,2.12-6.08,2.97-7.46,1.19,1.4,2.28,2.89,3.42,4.33-.86,1.22-2.3,3.64-3.25,6.97-.02,.08-.13,.1-.18,.03Z" />
					<path d="M1425.16,1575.11l-2.68-3.98s-.02-.06-.01-.09c.98-3.43,2.44-5.91,3.4-7.25l1.62,2.24,1.55,2.29c-.93,1.17-2.52,3.49-3.69,6.77-.03,.08-.13,.09-.18,.02Z" />
					<path d="M1413.17,1558.59c-.98-1.26-2.03-2.47-3.05-3.7-.02-.02-.03-.06-.02-.09,.21-1.11,.48-2.13,.77-3.05l3.37-2.86,2.16,2.59c-.83,1.25-2.16,3.68-3.05,7.07-.02,.08-.12,.1-.18,.04Z" />
					<path d="M1429.87,1569.56l1.2,1.78-3.69,2.43c.86-1.89,1.81-3.32,2.49-4.21Z" />
					<path d="M1421.34,1569.47l-2.81-3.88s-.02-.06-.02-.09c.89-3.46,2.27-5.99,3.19-7.36,1.15,1.44,2.22,2.94,3.29,4.43-.89,1.19-2.41,3.57-3.47,6.88-.03,.08-.13,.09-.18,.03Z" />
				</g>
			</g>
			<g>
				<path d="M1389.23,1535.12s.09,0,.13,.03c1.16,1.03,2.26,2.14,3.37,3.22,.07,.07,.04,.19-.06,.22l-.73,.2c-.23,.06-.36,.3-.3,.54l.18,.64c.07,.23,.31,.37,.54,.3l1.73-.47s.09,0,.13,.03c1.11,1.08,2.23,2.17,3.29,3.31,.07,.07,.04,.2-.06,.22l-.73,.18c-.23,.06-.37,.29-.31,.53l.16,.64c.06,.23,.29,.38,.53,.32l1.74-.42s.09,0,.13,.04c1.06,1.13,2.17,2.23,3.2,3.4,.07,.08,.03,.2-.07,.22l-.74,.16c-.23,.05-.38,.28-.33,.52l.14,.65c.05,.24,.28,.39,.51,.34l1.75-.36s.09,0,.13,.04l3.1,3.48c.07,.08,.02,.2-.07,.22l-.75,.14c-.24,.05-.39,.27-.34,.51l.12,.65c.04,.24,.27,.4,.5,.36l1.23-.22-4.53,3.84,.43-1.17c.08-.23-.03-.47-.25-.55l-.61-.22c-.23-.07-.47,.05-.55,.27l-.25,.71c-.04,.1-.16,.12-.22,.04l-3.01-3.37s-.04-.08-.03-.13l.56-1.7c.07-.23-.05-.47-.27-.54l-.62-.2c-.23-.07-.47,.05-.54,.28l-.23,.72c-.03,.1-.15,.12-.22,.05-1-1.13-2.07-2.19-3.1-3.29-.03-.03-.04-.08-.03-.13l.51-1.71c.07-.23-.06-.47-.29-.53l-.62-.18c-.23-.07-.47,.07-.53,.3l-.21,.73c-.03,.1-.15,.13-.22,.06-1.03-1.1-2.11-2.16-3.19-3.21-.03-.03-.05-.08-.03-.12l.46-1.72c.06-.23-.08-.47-.31-.52l-.63-.16c-.23-.06-.46,.08-.52,.32l-.19,.73c-.03,.1-.15,.13-.22,.06-1.08-1.05-2.14-2.12-3.27-3.13-.03-.03-.05-.08-.04-.12l.12-.52,4.05-4.35,.52-.16Z" />
				<g>
					<path d="M1389.25,1546.91c1.11,1.05,2.13,2.19,3.19,3.29,.06,.06,.02,.16-.06,.17-3.4,.49-5.9,1.66-7.19,2.38l-3.54-3.47c1.42-.78,4.04-1.97,7.52-2.39,.03,0,.06,0,.08,.03Z" />
					<path d="M1398.16,1556.4c1.01,1.14,2.05,2.26,3.01,3.45,.05,.06,0,.16-.07,.16-3.4,.26-6.02,1.34-7.34,1.97l-3.29-3.69c1.46-.69,4.14-1.73,7.62-1.92,.03,0,.06,.01,.08,.03Z" />
					<path d="M1384.6,1542.36l3.28,3.21c.06,.06,.02,.16-.06,.17-3.39,.6-5.87,1.84-7.13,2.59-.71-.72-1.46-1.39-2.21-2.07l3.01-3.23c.91-.28,1.93-.52,3.03-.69,.03,0,.06,0,.08,.02Z" />
					<path d="M1395.89,1564.46c-.41-.49-.81-.99-1.24-1.47,1.02-.46,2.6-1.03,4.61-1.39l-3.37,2.86Z" />
					<path d="M1393.77,1551.59l1.58,1.66,1.52,1.71c.05,.06,.01,.16-.07,.17-3.4,.38-5.94,1.49-7.27,2.17l-3.42-3.58c1.44-.74,4.09-1.85,7.57-2.16,.03,0,.06,0,.08,.03Z" />
				</g>
				<g>
					<path d="M1399.71,1543.25c-1.11-1.14-2.18-2.34-3.34-3.44-.02-.02-.03-.05-.03-.08,.32-3.51,1.36-6.25,2.06-7.74l3.93,3.86c-.71,1.32-1.87,3.9-2.45,7.35-.01,.08-.11,.11-.17,.05Z" />
					<path d="M1408.83,1553.34c-1.01-1.24-2.09-2.42-3.15-3.61-.02-.02-.03-.06-.03-.09,.54-3.5,1.74-6.18,2.53-7.63l3.67,4.11c-.79,1.27-2.04,3.74-2.85,7.17-.02,.08-.12,.11-.18,.04Z" />
					<path d="M1394.94,1538.4l-3.43-3.35s-.04-.05-.03-.08c.07-1.11,.21-2.15,.39-3.1l3.01-3.23c.83,.76,1.66,1.51,2.45,2.3-.67,1.33-1.75,3.95-2.22,7.4-.01,.08-.11,.12-.17,.06Z" />
					<path d="M1412.86,1547.25c.48,.53,.92,1.09,1.38,1.64l-3.37,2.86c.64-1.99,1.43-3.54,1.98-4.5Z" />
					<path d="M1404.35,1548.23l-1.6-1.79-1.65-1.74s-.03-.05-.03-.09c.43-3.51,1.55-6.22,2.3-7.69l3.8,3.99c-.73,1.28-1.96,3.82-2.65,7.26-.02,.08-.12,.11-.17,.05Z" />
				</g>
			</g>
			<g>
				<path d="M1368.5,1517.85s.09-.01,.13,.02l3.72,2.8c.08,.06,.06,.19-.03,.23l-.7,.28c-.22,.09-.33,.34-.23,.56l.25,.62c.09,.22,.34,.34,.56,.25l1.66-.65s.09,0,.13,.02l3.64,2.91c.08,.06,.06,.19-.04,.22l-.71,.27c-.23,.08-.34,.34-.25,.56l.24,.61c.09,.22,.34,.33,.57,.25l1.68-.61s.09,0,.13,.02l3.54,3.03c.08,.07,.05,.19-.04,.22l-.72,.25c-.23,.08-.35,.33-.27,.55l.22,.62c.08,.23,.33,.35,.56,.27l1.71-.58s.09,0,.13,.03c1.17,1.03,2.3,2.09,3.45,3.14,.08,.07,.05,.19-.05,.22l-.72,.22c-.23,.07-.35,.32-.28,.54l.2,.63c.07,.23,.32,.36,.55,.29l1.2-.37-4.05,4.35,.28-1.21c.05-.23-.09-.46-.32-.51l-.64-.14c-.23-.05-.46,.1-.52,.33l-.17,.73c-.02,.1-.14,.14-.22,.07-1.12-1.01-2.21-2.05-3.35-3.05-.04-.03-.05-.08-.04-.12l.35-1.74c.05-.23-.11-.46-.34-.5l-.64-.12c-.23-.04-.46,.11-.51,.35l-.15,.74c-.02,.1-.14,.14-.21,.08l-3.44-2.95s-.05-.08-.04-.12l.31-1.76c.04-.23-.12-.45-.36-.49l-.65-.1c-.24-.04-.46,.13-.5,.36l-.12,.74c-.02,.1-.13,.14-.21,.08l-3.53-2.83s-.05-.08-.05-.12l.27-1.77c.04-.24-.13-.46-.36-.49l-.64-.09c-.24-.03-.46,.14-.49,.37l-.11,.75c-.01,.1-.13,.15-.21,.09l-3.62-2.72s-.06-.07-.05-.12l.07-.53,3.55-4.76,.5-.21Z" />
				<g>
					<path d="M1369.84,1529.57l3.58,2.88c.06,.05,.04,.15-.04,.17-3.34,.88-5.72,2.36-6.92,3.21l-3.89-3.11c1.33-.94,3.79-2.4,7.19-3.18,.03,0,.06,0,.08,.02Z" />
					<path d="M1379.8,1537.96l3.38,3.1c.06,.05,.03,.15-.05,.17-3.38,.7-5.82,2.02-7.07,2.81-1.2-1.13-2.46-2.22-3.72-3.29,1.37-.85,3.96-2.23,7.38-2.81,.03,0,.06,0,.08,.02Z" />
					<path d="M1364.69,1525.57c1.22,.92,2.46,1.82,3.64,2.8,.06,.05,.04,.15-.04,.18-3.28,.94-5.64,2.49-6.82,3.38l-2.44-1.82,2.64-3.54c.88-.37,1.85-.72,2.92-1,.03,0,.06,0,.09,.02Z" />
					<path d="M1378.48,1546.26l-1.42-1.3c.96-.56,2.47-1.33,4.43-1.93l-3.01,3.23Z" />
					<path d="M1374.88,1533.7l3.48,2.99c.06,.05,.03,.15-.05,.17-3.37,.8-5.77,2.19-7,3.02-1.26-1.07-2.49-2.18-3.81-3.19,1.36-.9,3.89-2.34,7.28-3,.03,0,.06,0,.08,.02Z" />
				</g>
				<g>
					<path d="M1379.84,1524.71l-3.73-3s-.04-.05-.04-.08c-.06-3.54,.69-6.4,1.21-7.94l4.29,3.42c-.56,1.38-1.4,4.05-1.57,7.53,0,.08-.1,.13-.17,.07Z" />
					<path d="M1390.03,1533.7l-3.53-3.23s-.04-.05-.03-.08c.14-3.55,.99-6.31,1.62-7.83,1.4,1.18,2.78,2.38,4.12,3.64-.63,1.35-1.63,3.99-2,7.45,0,.08-.11,.12-.17,.06Z" />
					<path d="M1374.55,1520.46c-1.23-1.01-2.52-1.95-3.79-2.91-.02-.02-.04-.05-.04-.08-.05-1.12-.02-2.17,.06-3.14l2.64-3.54,2.68,2.01c-.52,1.4-1.27,4.07-1.38,7.59,0,.08-.1,.13-.16,.08Z" />
					<path d="M1393.31,1527.2l1.58,1.44-3.01,3.23c.4-2.04,.99-3.65,1.43-4.68Z" />
					<path d="M1385,1529.13l-3.63-3.11s-.04-.05-.04-.08c.06-3.57,.84-6.36,1.41-7.89,1.44,1.12,2.81,2.35,4.21,3.53-.59,1.37-1.52,4.03-1.78,7.49,0,.08-.1,.12-.17,.07Z" />
				</g>
			</g>
			<g>
				<path d="M1346.11,1502.85s.09-.02,.13,0l3.97,2.42c.09,.05,.08,.18,0,.23l-.67,.36c-.21,.11-.29,.38-.18,.59l.31,.58c.11,.21,.38,.29,.59,.18l1.59-.83s.09-.02,.13,0l3.9,2.55c.09,.06,.08,.18-.01,.23l-.68,.34c-.21,.11-.3,.37-.19,.58l.3,.59c.11,.21,.37,.3,.59,.19l1.61-.79s.09-.02,.13,0c1.31,.83,2.56,1.75,3.84,2.63,.08,.06,.07,.18-.02,.23l-.69,.32c-.22,.1-.31,.36-.21,.58l.28,.6c.1,.22,.36,.31,.58,.21l1.63-.75s.09-.01,.13,.01c1.27,.89,2.55,1.76,3.78,2.71,.08,.06,.07,.19-.02,.23l-.69,.3c-.22,.09-.32,.35-.22,.57l.26,.61c.1,.22,.35,.32,.57,.23l1.15-.49-3.55,4.76,.15-1.24c.03-.24-.14-.45-.38-.48l-.65-.07c-.24-.03-.45,.15-.48,.38l-.09,.75c-.01,.1-.13,.15-.21,.09-1.2-.92-2.45-1.77-3.68-2.64-.04-.03-.06-.07-.05-.12l.17-1.77c.02-.24-.15-.45-.39-.47l-.65-.05c-.24-.02-.44,.16-.47,.4l-.07,.75c0,.1-.12,.15-.2,.1-1.24-.86-2.46-1.76-3.74-2.56-.04-.03-.06-.07-.06-.12l.12-1.77c.02-.24-.17-.44-.4-.45l-.65-.03c-.24-.01-.44,.17-.46,.41l-.05,.75c0,.1-.12,.16-.2,.1l-3.79-2.48s-.06-.07-.06-.12l.07-1.78c0-.24-.18-.43-.42-.44l-.66-.02c-.24,0-.44,.18-.45,.42l-.03,.75c0,.1-.11,.16-.2,.11l-3.87-2.36s-.06-.07-.06-.11v-.54s3.06-5.1,3.06-5.1l.47-.26Z" />
				<g>
					<path d="M1348.64,1514.38l3.85,2.52c.07,.04,.05,.15-.02,.18-3.23,1.23-5.44,2.92-6.55,3.9-1.39-.91-2.78-1.83-4.22-2.68,1.22-1.06,3.57-2.83,6.85-3.92,.03,0,.06,0,.09,.01Z" />
					<path d="M1359.44,1521.7c1.27,.86,2.48,1.8,3.7,2.72,.06,.05,.04,.15-.03,.18-3.28,1.05-5.55,2.61-6.71,3.53l-4.09-2.86c1.28-1,3.68-2.6,7.04-3.58,.03,0,.06,0,.09,.01Z" />
					<path d="M1343.09,1510.93l3.93,2.4c.07,.04,.06,.15-.02,.18-3.21,1.32-5.37,3.07-6.46,4.08l-2.61-1.59,2.27-3.79c.83-.46,1.77-.89,2.81-1.28,.03-.01,.06,0,.09,0Z" />
					<path d="M1359.04,1530.09l-1.55-1.16c.89-.66,2.3-1.58,4.19-2.38l-2.64,3.54Z" />
					<path d="M1354.1,1517.96l3.76,2.64c.07,.05,.05,.15-.03,.18-3.25,1.14-5.5,2.77-6.63,3.72-1.35-.98-2.77-1.84-4.15-2.77,1.25-1.03,3.62-2.7,6.96-3.77,.03,0,.06,0,.09,.01Z" />
				</g>
				<g>
					<path d="M1358.07,1508.53l-4-2.61s-.04-.05-.05-.08c-.4-3.55,0-6.39,.38-7.99,1.56,.93,3.08,1.93,4.61,2.93-.41,1.43-.98,4.19-.79,7.66,0,.08-.09,.14-.16,.09Z" />
					<path d="M1369.15,1516.34c-1.28-.95-2.54-1.93-3.85-2.83-.03-.02-.04-.05-.04-.08-.26-3.51,.33-6.38,.78-7.96l4.48,3.14c-.48,1.4-1.2,4.15-1.2,7.64,0,.08-.09,.13-.16,.08Z" />
					<path d="M1352.39,1504.82l-4.07-2.49s-.04-.04-.05-.07c-.16-1.11-.23-2.17-.24-3.14l2.27-3.79,2.85,1.74c-.37,1.44-.88,4.2-.59,7.66,0,.08-.08,.14-.16,.09Z" />
					<path d="M1371.71,1509.51l1.71,1.28-2.64,3.54c.18-2.08,.59-3.75,.93-4.82Z" />
					<path d="M1363.66,1512.37l-3.92-2.74s-.04-.05-.04-.08c-.36-3.49,.15-6.37,.56-7.96,1.52,1.01,3.07,1.96,4.55,3.03-.45,1.42-1.09,4.17-1,7.66,0,.08-.09,.13-.16,.08Z" />
				</g>
			</g>
			<g>
				<path d="M1322.33,1490.21s.09-.03,.13,0l4.19,2.01c.09,.04,.1,.17,.02,.23l-.64,.42c-.2,.13-.25,.4-.12,.6l.37,.54c.13,.2,.41,.25,.61,.12l1.51-.99s.09-.03,.13,0c.7,.34,1.41,.66,2.09,1.02l2.04,1.12c.09,.05,.09,.18,0,.23l-.64,.4c-.2,.13-.26,.39-.14,.6l.35,.57c.13,.21,.39,.27,.6,.15l1.52-.93s.09-.03,.13,0l4.07,2.24c.09,.05,.09,.18,0,.23l-.65,.39c-.21,.12-.27,.39-.15,.59l.34,.56c.12,.21,.39,.27,.6,.15l1.55-.91s.09-.02,.13,0l4.07,2.25c.09,.05,.09,.18,0,.23l-.66,.37c-.21,.12-.28,.38-.16,.59l.32,.58c.12,.21,.38,.29,.59,.17l1.09-.6-3.05,5.1,.03-1.25c0-.24-.18-.44-.42-.44h-.65c-.24-.01-.44,.19-.44,.42v.76c-.01,.1-.12,.16-.21,.11l-3.97-2.19s-.07-.06-.07-.11v-1.77c-.02-.24-.21-.43-.45-.42h-.66c-.24,.02-.43,.22-.43,.46v.75c0,.1-.1,.17-.19,.12l-3.97-2.19s-.07-.06-.07-.11l-.05-1.79c0-.24-.2-.43-.44-.42l-.65,.02c-.24,0-.42,.21-.41,.45l.02,.76c0,.1-.1,.17-.19,.12l-1.99-1.09c-.67-.36-1.36-.66-2.04-1-.04-.02-.07-.06-.07-.11l-.1-1.77c-.01-.24-.22-.41-.46-.4l-.66,.05c-.24,.02-.42,.23-.4,.46l.05,.75c0,.1-.1,.17-.19,.13l-4.09-1.96s-.07-.06-.07-.11l-.04-.54,2.55-5.37,.45-.3Z" />
				<g>
					<path d="M1326,1501.36c1.37,.69,2.69,1.47,4.04,2.2,.07,.04,.07,.14,0,.18-3.08,1.5-5.1,3.38-6.1,4.45-1.46-.8-3-1.46-4.5-2.2,1.12-1.18,3.27-3.13,6.47-4.62,.03-.01,.06-.01,.09,0Z" />
					<path d="M1337.45,1507.64c1.34,.74,2.7,1.46,3.99,2.29,.07,.04,.06,.15-.02,.18-3.14,1.34-5.29,3.19-6.35,4.22l-4.38-2.41c1.18-1.12,3.41-2.96,6.67-4.28,.03-.01,.06,0,.09,0Z" />
					<path d="M1320.1,1498.53l4.15,1.99c.07,.04,.07,.14,0,.18-3.07,1.63-5.06,3.59-6.05,4.69l-2.76-1.32,1.89-3.99c.79-.54,1.68-1.06,2.68-1.55,.03-.01,.06-.01,.09,0Z" />
					<path d="M1337.93,1515.99l-1.66-1.01c.83-.75,2.14-1.8,3.93-2.78l-2.27,3.79Z" />
					<path d="M1331.73,1504.49l4.03,2.22c.07,.04,.06,.14-.01,.18-3.14,1.47-5.21,3.32-6.25,4.37l-4.38-2.42c1.14-1.14,3.32-3.01,6.52-4.36,.03-.01,.06-.01,.09,0Z" />
				</g>
				<g>
					<path d="M1334.78,1494.68c-1.4-.76-2.77-1.56-4.19-2.28-.03-.01-.05-.04-.06-.07-.79-3.4-.63-6.31-.42-7.93,1.63,.81,3.3,1.53,4.89,2.4-.27,1.47-.59,4.29-.08,7.77,.01,.08-.07,.14-.15,.1Z" />
					<path d="M1346.61,1501.29c-1.34-.85-2.75-1.6-4.14-2.37-.03-.02-.05-.04-.05-.07-.61-3.45-.32-6.36-.02-7.97l4.78,2.64c-.34,1.45-.75,4.17-.41,7.68,0,.08-.08,.14-.15,.1Z" />
					<path d="M1328.78,1491.54l-4.3-2.06s-.05-.04-.06-.07c-.27-1.09-.44-2.13-.55-3.09l1.89-3.99,3,1.44c-.23,1.47-.47,4.26,.15,7.66,.01,.08-.07,.14-.15,.11Z" />
					<path d="M1348.47,1494.24l1.82,1.1-2.27,3.79c-.03-2.08,.23-3.8,.45-4.89Z" />
					<path d="M1340.71,1497.95l-4.18-2.3s-.05-.04-.05-.07c-.67-3.48-.43-6.42-.17-8.05l4.78,2.63c-.3,1.46-.68,4.25-.23,7.69,.01,.08-.08,.14-.15,.1Z" />
				</g>
			</g>
			<g>
				<path d="M1297.53,1479.75s.09-.04,.13-.02c1.45,.53,2.94,.99,4.37,1.59,.09,.04,.11,.17,.04,.23l-.59,.47c-.19,.15-.21,.42-.07,.61l.41,.53c.15,.19,.42,.23,.61,.08l1.4-1.1s.09-.04,.13-.02l4.29,1.78c.09,.04,.11,.16,.03,.23l-.6,.46c-.19,.15-.23,.42-.08,.61l.4,.52c.15,.19,.42,.23,.61,.08l1.43-1.08s.09-.03,.13-.02l4.29,1.78c.09,.04,.11,.16,.03,.22l-.62,.45c-.19,.14-.23,.41-.09,.6l.4,.52c.14,.19,.42,.23,.61,.08l1.46-1.05s.09-.03,.13-.01l4.19,2.01c.09,.04,.1,.17,.02,.23l-.62,.43c-.2,.14-.24,.41-.11,.61l.38,.55c.14,.2,.41,.25,.6,.11l1.03-.7-2.55,5.37-.09-1.24c-.02-.24-.23-.42-.46-.4l-.65,.05c-.24,.02-.41,.23-.39,.47l.06,.76c0,.1-.09,.17-.18,.13l-4.09-1.96s-.07-.06-.07-.11l-.18-1.77c-.03-.23-.24-.4-.48-.37l-.65,.08c-.24,.03-.41,.25-.38,.48l.08,.74c.01,.1-.09,.18-.18,.14l-4.19-1.74s-.07-.06-.08-.1l-.22-1.77c-.03-.24-.25-.4-.48-.37l-.65,.09c-.24,.03-.4,.25-.37,.49l.1,.75c.01,.1-.09,.18-.18,.14l-4.19-1.74s-.07-.06-.08-.1l-.25-1.78c-.03-.24-.25-.41-.49-.37l-.64,.09c-.23,.03-.39,.25-.36,.49l.11,.75c.01,.1-.08,.18-.17,.14-1.4-.58-2.85-1.03-4.27-1.55-.04-.02-.08-.05-.08-.1l-.09-.53,2.05-5.58,.42-.35Z" />
				<g>
					<path d="M1302.17,1490.61l4.26,1.76c.07,.03,.08,.14,.01,.18-2.94,1.81-4.79,3.89-5.69,5.05l-4.63-1.92c1-1.26,2.95-3.36,5.97-5.07,.03-.01,.06-.02,.09,0Z" />
					<path d="M1314.21,1495.71l4.15,1.99c.07,.04,.07,.14,0,.18-3.01,1.66-4.95,3.64-5.9,4.76-1.5-.72-3-1.47-4.56-2.07,1.06-1.22,3.15-3.34,6.22-4.86,.03-.01,.06-.01,.09,0Z" />
					<path d="M1296.08,1488.22l2.17,.77,2.13,.88c.08,.03,.08,.14,.01,.18-1.44,.92-2.61,1.9-3.52,2.8-.92,.91-1.6,1.76-2.04,2.35l-2.9-1.02,1.52-4.15c.74-.61,1.58-1.23,2.54-1.81,.03-.02,.06-.02,.09,0Z" />
					<path d="M1315.45,1504.07l-1.76-.84c.74-.82,1.96-2,3.65-3.15l-1.89,3.99Z" />
					<path d="M1308.21,1493.11l4.26,1.76c.08,.03,.08,.14,0,.18-3,1.79-4.9,3.84-5.84,4.99l-4.63-1.92c1.04-1.25,3.04-3.33,6.12-5.01,.03-.01,.06-.02,.09,0Z" />
				</g>
				<g>
					<path d="M1310.31,1483.1l-4.41-1.82s-.05-.04-.06-.07c-1.07-3.39-1.16-6.34-1.09-7.99l5.03,2.08c-.13,1.48-.18,4.3,.66,7.68,.02,.08-.06,.15-.14,.12Z" />
					<path d="M1322.67,1488.61l-4.3-2.06s-.05-.04-.06-.07c-.89-3.49-.9-6.33-.75-7.96,1.69,.66,3.32,1.47,4.96,2.25-.2,1.48-.38,4.3,.3,7.73,.02,.08-.07,.15-.14,.11Z" />
					<path d="M1304.06,1480.51l-2.2-.91-2.25-.8s-.05-.03-.06-.06c-.37-1.05-.64-2.06-.83-3l1.52-4.15,3.13,1.11c-.05,.74-.06,1.8,.04,3.11,.1,1.32,.33,2.88,.78,4.58,.02,.08-.06,.15-.13,.12Z" />
					<path d="M1323.86,1481.41l1.91,.92-1.89,3.99c-.23-2.07-.14-3.79-.02-4.91Z" />
					<path d="M1316.56,1485.69l-4.4-1.83s-.05-.04-.06-.07c-1.01-3.37-1.05-6.3-.94-7.93l5.03,2.08c-.16,1.47-.27,4.27,.52,7.62,.02,.08-.06,.15-.14,.11Z" />
				</g>
			</g>
			<g>
				<path d="M1271.91,1471.52s.08-.04,.13-.03l4.45,1.32c.1,.03,.13,.15,.06,.22l-.55,.52c-.17,.16-.18,.44-.01,.61l.46,.48c.17,.17,.44,.18,.61,.02l1.3-1.23s.08-.04,.13-.03l4.45,1.32c.1,.03,.13,.15,.05,.22l-.56,.52c-.18,.16-.19,.44-.02,.61l.45,.48c.16,.17,.44,.18,.62,.02l1.33-1.22s.08-.04,.13-.03c1.5,.4,2.94,.99,4.4,1.48,.1,.03,.12,.16,.05,.22l-.57,.49c-.18,.16-.2,.43-.04,.61l.43,.5c.16,.18,.43,.2,.61,.05l1.35-1.17s.08-.04,.13-.02l4.38,1.55c.1,.03,.12,.16,.04,.22l-.58,.49c-.18,.15-.21,.43-.05,.61l.43,.5c.16,.18,.43,.2,.62,.05l.96-.81-2.05,5.58-.21-1.22c-.04-.23-.27-.39-.5-.34l-.64,.12c-.23,.04-.39,.27-.35,.5l.13,.74c.02,.1-.08,.18-.17,.15l-4.28-1.51s-.08-.05-.08-.1l-.33-1.76c-.05-.24-.27-.39-.5-.35l-.64,.12c-.23,.04-.38,.27-.34,.51l.14,.75c.02,.1-.07,.18-.17,.15-1.43-.49-2.84-1.06-4.3-1.45-.04-.01-.08-.05-.09-.09l-.38-1.73c-.05-.23-.28-.37-.52-.32l-.64,.15c-.23,.06-.38,.29-.33,.52l.16,.73c.02,.1-.07,.18-.17,.16l-4.35-1.29s-.08-.05-.09-.09l-.41-1.74c-.06-.23-.29-.38-.52-.32l-.63,.16c-.23,.06-.37,.29-.31,.52l.18,.74c.02,.1-.07,.19-.16,.16l-4.35-1.29s-.08-.05-.09-.1l-.13-.53,1.56-5.73,.38-.37Z" />
				<g>
					<path d="M1277.47,1481.99l4.42,1.31c.08,.02,.09,.13,.03,.18-2.78,2.1-4.44,4.34-5.24,5.59l-4.82-1.43c.89-1.35,2.66-3.63,5.53-5.63,.03-.02,.06-.02,.09-.01Z" />
					<path d="M1289.91,1486.04l4.35,1.53c.08,.03,.09,.13,.02,.18-2.86,1.96-4.62,4.12-5.47,5.33l-4.73-1.67c.95-1.31,2.81-3.5,5.75-5.36,.03-.02,.06-.02,.09,0Z" />
					<path d="M1271.19,1480.13l4.42,1.31c.08,.02,.1,.13,.03,.18-2.72,2.11-4.33,4.37-5.1,5.63l-2.95-.87,1.16-4.26c.68-.66,1.46-1.32,2.35-1.96,.02-.02,.06-.02,.09-.01Z" />
					<path d="M1291.94,1494.18l-1.84-.65c.67-.89,1.78-2.18,3.36-3.5l-1.52,4.15Z" />
					<path d="M1283.75,1483.86l4.35,1.54c.08,.03,.09,.13,.02,.18-2.8,1.98-4.5,4.16-5.33,5.38-1.56-.6-3.2-1-4.8-1.5,.93-1.34,2.75-3.61,5.67-5.58,.03-.02,.06-.02,.09-.01Z" />
				</g>
				<g>
					<path d="M1284.94,1473.72l-4.57-1.35s-.05-.03-.07-.06c-1.37-3.25-1.73-6.16-1.8-7.8l5.21,1.54c0,1.48,.2,4.28,1.35,7.54,.03,.08-.05,.15-.13,.13Z" />
					<path d="M1297.73,1478.13l-4.49-1.59s-.05-.03-.06-.06c-1.22-3.32-1.45-6.26-1.45-7.9l5.13,1.81c-.07,1.49,.01,4.29,1.01,7.62,.02,.08-.05,.15-.13,.12Z" />
					<path d="M1278.46,1471.8l-4.57-1.35s-.05-.03-.07-.06c-.45-1.04-.8-2.04-1.07-2.98l1.16-4.26,3.18,.94c.03,1.49,.28,4.3,1.49,7.58,.03,.08-.04,.16-.12,.13Z" />
					<path d="M1298.24,1470.88l1.99,.7-1.52,4.15c-.42-2.02-.49-3.74-.47-4.85Z" />
					<path d="M1291.35,1475.88l-4.49-1.59s-.05-.03-.06-.06c-1.31-3.24-1.61-6.13-1.65-7.76,1.73,.53,3.49,.98,5.19,1.62-.04,1.49,.1,4.32,1.15,7.66,.03,.08-.05,.15-.13,.13Z" />
				</g>
			</g>
			<g>
				<path d="M1245.68,1465.6s.08-.05,.12-.04l4.56,.87c.1,.02,.14,.14,.07,.21l-.5,.57c-.16,.18-.14,.45,.04,.61l.5,.43c.18,.16,.46,.14,.62-.04l1.2-1.35s.08-.05,.13-.04c1.52,.29,3.05,.56,4.54,.95,.1,.02,.14,.15,.07,.22l-.51,.55c-.16,.17-.15,.45,.03,.62l.48,.46c.18,.17,.45,.16,.61-.01l1.22-1.3s.08-.05,.13-.04l4.51,1.09c.1,.02,.14,.15,.06,.22l-.53,.55c-.17,.17-.16,.45,.02,.61l.48,.45c.17,.17,.45,.16,.62-.01l1.25-1.29s.08-.05,.13-.04l4.51,1.09c.1,.02,.13,.14,.06,.22l-.54,.54c-.17,.17-.17,.45,0,.61l.47,.45c.17,.16,.45,.16,.62,0l.88-.86-1.56,5.73-.31-1.22c-.06-.24-.3-.37-.53-.3l-.63,.18c-.23,.07-.37,.31-.3,.54l.2,.72c.03,.1-.06,.19-.16,.16l-4.42-1.07s-.08-.05-.09-.09l-.49-1.71c-.07-.23-.31-.36-.53-.29l-.63,.19c-.23,.07-.36,.31-.29,.54l.21,.72c.03,.1-.06,.19-.16,.16l-4.42-1.07s-.08-.05-.09-.09l-.52-1.72c-.07-.23-.31-.36-.54-.29l-.62,.19c-.23,.07-.35,.31-.28,.54l.22,.73c.03,.1-.06,.19-.15,.17-1.46-.39-2.96-.64-4.45-.93-.05,0-.08-.04-.1-.09l-.56-1.68c-.08-.23-.32-.34-.55-.27l-.62,.22c-.23,.08-.35,.33-.27,.55l.24,.71c.03,.1-.05,.19-.15,.17l-4.46-.85s-.08-.04-.1-.09l-.18-.51,1.08-5.84,.35-.41Z" />
				<g>
					<path d="M1252.12,1475.45c1.52,.25,3,.67,4.5,1.02,.08,.02,.1,.12,.04,.18-2.58,2.26-4.04,4.59-4.73,5.9l-4.95-.94c.78-1.43,2.35-3.87,5.06-6.13,.02-.02,.06-.03,.09-.02Z" />
					<path d="M1264.87,1478.47c1.49,.38,3,.69,4.47,1.12,.08,.02,.1,.13,.03,.18-2.67,2.15-4.25,4.53-5,5.79l-4.89-1.18c.84-1.39,2.51-3.76,5.3-5.89,.02-.02,.06-.03,.09-.02Z" />
					<path d="M1245.68,1474.22l4.53,.87c.08,.02,.11,.12,.04,.17-2.54,2.37-3.96,4.76-4.63,6.09l-3.02-.58,.8-4.34c.62-.72,1.35-1.46,2.19-2.18,.02-.02,.05-.03,.08-.02Z" />
					<path d="M1267.6,1486.37c-.62-.2-1.26-.35-1.89-.5,.59-.93,1.59-2.37,3.05-3.76l-1.16,4.26Z" />
					<path d="M1258.5,1476.92l4.48,1.09c.08,.02,.1,.12,.04,.17-2.63,2.24-4.15,4.57-4.87,5.86l-4.89-1.19c.81-1.4,2.42-3.77,5.15-5.92,.02-.02,.06-.03,.08-.02Z" />
				</g>
				<g>
					<path d="M1258.84,1466.66c-1.55-.36-3.08-.79-4.64-1.05-.03,0-.06-.03-.07-.05-1.64-3.09-2.24-5.94-2.45-7.56l5.33,1.02c.12,1.48,.55,4.29,1.95,7.51,.03,.08-.03,.16-.11,.14Z" />
					<path d="M1271.97,1469.88c-1.52-.44-3.08-.77-4.62-1.16-.03,0-.06-.03-.07-.06-1.51-3.17-1.99-6.06-2.13-7.69l5.27,1.28c.06,1.5,.4,4.22,1.66,7.49,.03,.08-.04,.16-.12,.13Z" />
					<path d="M1252.24,1465.24l-4.67-.89s-.06-.03-.07-.05c-.54-.98-.97-1.94-1.32-2.85l.8-4.34,3.26,.62c.15,1.48,.64,4.24,2.12,7.37,.04,.07-.03,.16-.11,.14Z" />
					<path d="M1271.87,1462.61c.68,.16,1.37,.33,2.04,.54l-1.16,4.26c-.58-2.03-.81-3.68-.88-4.8Z" />
					<path d="M1265.41,1468.25l-4.63-1.12s-.06-.03-.07-.06c-1.56-3.18-2.1-6.08-2.27-7.72l5.28,1.28c.09,1.49,.47,4.28,1.81,7.48,.03,.08-.04,.16-.12,.14Z" />
				</g>
			</g>
			<g>
				<path d="M1219.08,1461.76s.07-.06,.12-.05l4.62,.44c.1,.01,.15,.13,.09,.21l-.45,.61c-.14,.19-.1,.46,.09,.6l.53,.41c.19,.15,.46,.11,.61-.07l1.08-1.41s.07-.06,.12-.05l4.6,.65c.1,.01,.15,.13,.09,.21l-.47,.6c-.15,.19-.11,.46,.08,.61l.52,.41c.19,.15,.46,.11,.61-.07l1.11-1.41s.08-.06,.12-.05l4.6,.65c.1,.01,.15,.13,.08,.21l-.48,.59c-.15,.19-.12,.46,.07,.61l.51,.4c.19,.15,.46,.11,.61-.07l1.14-1.4s.08-.06,.12-.05c1.53,.25,3.04,.57,4.56,.85,.1,.02,.14,.14,.08,.22l-.49,.57c-.15,.18-.13,.46,.05,.61l.5,.43c.18,.16,.46,.14,.61-.04l.81-.95-1.08,5.84-.41-1.18c-.08-.23-.33-.35-.55-.27l-.61,.22c-.22,.08-.34,.33-.26,.55l.25,.72c.03,.1-.05,.19-.15,.17-1.49-.28-2.97-.59-4.47-.83-.05,0-.08-.04-.1-.08l-.63-1.65c-.08-.22-.33-.33-.56-.24l-.61,.25c-.22,.09-.33,.34-.25,.56l.27,.7c.04,.09-.04,.19-.14,.18l-4.5-.64s-.08-.04-.1-.08l-.66-1.66c-.09-.22-.34-.33-.56-.24l-.6,.25c-.22,.09-.33,.34-.24,.56l.28,.7c.04,.09-.04,.19-.14,.18l-4.5-.64s-.08-.04-.1-.08l-.69-1.66c-.09-.22-.35-.33-.56-.24l-.6,.25c-.22,.1-.32,.36-.23,.57l.3,.69c.04,.09-.04,.19-.13,.18l-4.53-.43s-.09-.03-.1-.08l-.22-.49,.61-5.91,.32-.44Z" />
				<g>
					<path d="M1226.28,1471.11l4.57,.65c.08,.01,.11,.11,.06,.17-2.39,2.49-3.66,4.96-4.25,6.31l-4.99-.7c.67-1.5,2.03-4,4.53-6.41,.02-.02,.05-.03,.08-.03Z" />
					<path d="M1239.25,1472.99l4.53,.87c.08,.02,.11,.12,.05,.17-2.48,2.38-3.85,4.78-4.49,6.11-.83-.15-1.65-.33-2.48-.46l-2.5-.35c.72-1.44,2.21-4.02,4.8-6.31,.02-.02,.05-.03,.08-.02Z" />
					<path d="M1219.77,1470.33c1.53,.15,3.07,.26,4.59,.51,.08,.01,.11,.11,.06,.17-2.33,2.47-3.56,5.04-4.12,6.41l-3.07-.29,.45-4.39c.57-.77,1.23-1.58,2.01-2.37,.02-.02,.05-.03,.08-.03Z" />
					<path d="M1242.61,1480.77l-1.92-.37c.51-.98,1.38-2.43,2.73-3.98l-.8,4.34Z" />
					<path d="M1232.77,1472.03l4.57,.65c.08,.01,.11,.11,.05,.17-2.45,2.48-3.77,4.94-4.39,6.29l-4.99-.71c.69-1.47,2.11-3.98,4.67-6.38,.02-.02,.05-.03,.08-.03Z" />
				</g>
				<g>
					<path d="M1232.27,1461.8l-4.71-.67s-.06-.02-.08-.05c-1.88-3.01-2.7-5.86-3.03-7.45,1.79,.23,3.58,.5,5.37,.74,.24,1.47,.9,4.21,2.56,7.27,.04,.07-.02,.16-.1,.15Z" />
					<path d="M1245.6,1463.97l-4.67-.89s-.06-.03-.07-.05c-1.75-3.1-2.48-5.85-2.75-7.48l2.68,.38c.89,.15,1.78,.33,2.67,.5,.18,1.48,.72,4.26,2.26,7.4,.04,.07-.03,.16-.11,.14Z" />
					<path d="M1225.59,1460.85c-1.57-.25-3.15-.37-4.73-.52-.03,0-.06-.02-.08-.05-.62-.93-1.12-1.84-1.54-2.71l.45-4.39,3.3,.31c.27,1.47,.99,4.11,2.7,7.21,.04,.07-.02,.16-.1,.15Z" />
					<path d="M1244.9,1456.7l2.07,.39-.8,4.34c-.75-1.94-1.11-3.63-1.27-4.74Z" />
					<path d="M1238.96,1462.74l-4.71-.67s-.06-.02-.07-.05c-1.82-3.01-2.59-5.84-2.9-7.45l5.37,.76c.21,1.47,.81,4.2,2.42,7.26,.04,.07-.02,.16-.11,.15Z" />
				</g>
			</g>
			<g>
				<path d="M1192.26,1459.97s.07-.06,.12-.06l4.63,.22c.1,0,.16,.12,.11,.2l-.41,.64c-.13,.2-.07,.47,.14,.6l.56,.36c.2,.13,.47,.07,.6-.13l.97-1.5s.07-.06,.12-.06l4.64,.22c.1,0,.16,.12,.1,.2l-.42,.64c-.13,.2-.08,.47,.13,.6l.55,.35c.2,.13,.47,.07,.6-.13l1-1.5s.07-.06,.12-.06c.77,.04,1.55,.06,2.32,.12l2.31,.22c.1,0,.16,.12,.1,.21l-.43,.62c-.14,.2-.08,.47,.11,.61l.54,.38c.2,.14,.47,.09,.6-.1l1.02-1.46s.07-.06,.12-.05l4.62,.43c.1,0,.15,.12,.1,.21l-.44,.62c-.14,.2-.09,.47,.1,.6l.54,.38c.2,.14,.47,.09,.61-.1l.73-1.02-.61,5.91-.51-1.13c-.1-.22-.35-.31-.57-.21l-.59,.27c-.22,.1-.31,.36-.21,.57l.31,.69c.04,.09-.03,.19-.13,.19l-4.53-.42s-.09-.03-.11-.08l-.75-1.63c-.1-.22-.36-.31-.57-.21l-.59,.27c-.21,.1-.3,.36-.2,.58l.32,.69c.04,.09-.03,.19-.13,.19l-2.26-.21c-.75-.07-1.51-.08-2.27-.12-.05,0-.09-.03-.11-.07l-.79-1.59c-.11-.21-.37-.3-.58-.19l-.58,.3c-.21,.11-.3,.37-.19,.58l.34,.67c.04,.09-.02,.2-.12,.19l-4.54-.21s-.09-.03-.11-.07l-.82-1.59c-.11-.21-.37-.3-.58-.19l-.58,.3c-.21,.11-.29,.37-.18,.58l.35,.67c.05,.09-.02,.2-.12,.19l-4.54-.21s-.09-.03-.11-.07l-.26-.48,.15-5.94,.28-.45Z" />
				<g>
					<path d="M1200.17,1468.82l4.61,.22c.08,0,.12,.1,.07,.16-2.19,2.7-3.27,5.28-3.75,6.68l-5.04-.24c.55-1.53,1.72-4.16,4.02-6.79,.02-.02,.05-.04,.08-.03Z" />
					<path d="M1213.25,1469.72l4.6,.43c.08,0,.12,.11,.06,.17-2.29,2.6-3.47,5.12-4,6.5l-5.02-.47c.6-1.5,1.87-4.08,4.28-6.6,.02-.02,.05-.03,.08-.03Z" />
					<path d="M1193.62,1468.51l4.61,.22c.08,0,.12,.1,.07,.16-2.13,2.7-3.16,5.28-3.61,6.68l-3.08-.15,.11-4.42c.5-.8,1.1-1.63,1.82-2.47,.02-.02,.05-.04,.08-.03Z" />
					<path d="M1217.23,1477.13l-1.95-.18c.43-1.02,1.19-2.55,2.4-4.21l-.45,4.39Z" />
					<path d="M1206.72,1469.12c1.54,.1,3.07,.28,4.6,.41,.08,0,.12,.11,.07,.17-2.24,2.6-3.36,5.13-3.87,6.51-1.67-.16-3.36-.18-5.04-.27,.58-1.53,1.8-4.16,4.16-6.78,.02-.02,.05-.04,.08-.03Z" />
				</g>
				<g>
					<path d="M1205.42,1459l-4.75-.22s-.06-.02-.08-.04c-2.11-2.83-3.15-5.57-3.61-7.14l5.41,.25c.36,1.44,1.22,4.11,3.12,7,.04,.07,0,.16-.09,.16Z" />
					<path d="M1218.87,1460.14l-4.74-.44s-.06-.02-.08-.05c-1.99-2.93-2.93-5.71-3.33-7.31l5.39,.5c.3,1.46,1.06,4.16,2.84,7.14,.04,.07-.01,.16-.1,.15Z" />
					<path d="M1198.67,1458.68l-4.75-.22s-.06-.02-.08-.04c-.69-.9-1.26-1.79-1.75-2.63l.11-4.42,3.31,.15c.38,1.44,1.3,4.11,3.25,7,.05,.07,0,.16-.09,.16Z" />
					<path d="M1217.59,1452.98l2.1,.2-.45,4.39c-.9-1.86-1.39-3.5-1.65-4.59Z" />
					<path d="M1212.15,1459.51c-1.58-.14-3.16-.32-4.74-.42-.03,0-.06-.02-.08-.04-2.06-2.83-3.04-5.57-3.47-7.14,1.8,.1,3.61,.13,5.41,.3,.33,1.46,1.14,4.17,2.98,7.15,.04,.07-.01,.16-.09,.15Z" />
				</g>
			</g>
			<g>
				<path d="M1165.39,1460.4s.06-.07,.11-.07l4.64-.21c.1,0,.17,.1,.12,.19l-.35,.67c-.11,.21-.03,.47,.18,.58l.58,.3c.21,.11,.48,.03,.59-.19l.85-1.59s.07-.07,.11-.07c1.55-.05,3.09-.19,4.64-.16,.1,0,.17,.11,.12,.2l-.37,.65c-.12,.21-.04,.47,.17,.59l.58,.33c.21,.12,.48,.05,.59-.16l.88-1.55s.07-.07,.11-.07h4.64c.1,0,.17,.11,.11,.2l-.38,.65c-.12,.21-.05,.47,.16,.59l.57,.33c.21,.12,.47,.05,.59-.16l.91-1.55s.07-.07,.11-.07h4.64c.1,0,.16,.11,.11,.2l-.39,.65c-.12,.21-.06,.47,.15,.59l.56,.33c.21,.12,.47,.05,.6-.16l.65-1.07-.15,5.94-.59-1.09c-.11-.21-.38-.28-.58-.16l-.57,.33c-.21,.12-.28,.38-.17,.59l.36,.66c.05,.09-.02,.2-.12,.2h-4.55s-.09-.03-.11-.07l-.88-1.55c-.12-.21-.38-.28-.59-.16l-.57,.33c-.21,.12-.28,.38-.16,.59l.37,.66c.05,.09-.01,.2-.11,.2h-4.55s-.09-.03-.11-.07l-.91-1.55c-.12-.21-.39-.28-.59-.16l-.56,.33c-.2,.12-.27,.38-.15,.59l.39,.66c.05,.09,0,.2-.11,.2-1.52-.03-3.03,.11-4.54,.16-.05,0-.09-.02-.11-.06l-.94-1.51c-.13-.2-.39-.26-.59-.13l-.55,.35c-.2,.13-.26,.4-.13,.6l.4,.64c.05,.09,0,.2-.1,.2l-4.54,.2s-.09-.02-.12-.06l-.29-.45-.31-5.93,.25-.48Z" />
				<g>
					<path d="M1173.96,1468.49c.77-.03,1.54-.08,2.31-.09h2.31c.08,0,.13,.1,.08,.16-1.97,2.79-2.85,5.42-3.22,6.87l-5.04,.23c.43-1.57,1.39-4.31,3.49-7.13,.02-.02,.05-.04,.08-.04Z" />
					<path d="M1187.07,1468.41h4.62c.08,.01,.12,.11,.07,.17-2.08,2.78-3.06,5.42-3.48,6.83h-5.05c.49-1.55,1.55-4.24,3.76-6.97,.02-.02,.05-.04,.08-.04Z" />
					<path d="M1167.41,1468.79l4.61-.21c.08,0,.13,.09,.08,.16-1.92,2.89-2.74,5.55-3.08,6.99l-3.08,.14-.23-4.41c.44-.84,.97-1.73,1.62-2.63,.02-.02,.05-.04,.08-.04Z" />
					<path d="M1191.61,1475.43c-.65-.02-1.31,0-1.96,0,.35-1.05,.99-2.66,2.07-4.41l-.11,4.42Z" />
					<path d="M1180.51,1468.41h4.62c.08,0,.13,.1,.08,.17-2.03,2.8-2.95,5.42-3.35,6.84h-5.05c.46-1.56,1.47-4.24,3.62-6.97,.02-.02,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M1178.43,1458.35h-2.38c-.79,0-1.58,.06-2.38,.09-.03,0-.06-.01-.08-.03-2.32-2.63-3.57-5.26-4.15-6.79l5.41-.24c.47,1.39,1.54,4.01,3.65,6.81,.05,.07,0,.16-.08,.16Z" />
					<path d="M1191.93,1458.36h-4.76s-.06-.02-.08-.05c-2.22-2.73-3.36-5.42-3.88-6.97h5.41c.41,1.43,1.38,4.04,3.39,6.86,.05,.07,0,.16-.09,.16Z" />
					<path d="M1171.68,1458.53l-4.75,.21s-.06-.01-.08-.03c-.75-.83-1.4-1.67-1.95-2.47l-.23-4.41,3.31-.15c.49,1.4,1.61,3.98,3.78,6.68,.05,.06,0,.16-.08,.16Z" />
					<path d="M1190.1,1451.35c.7,0,1.4,0,2.1,.01l-.11,4.42c-1.04-1.79-1.66-3.36-2-4.43Z" />
					<path d="M1185.18,1458.35h-4.76s-.06-.02-.08-.04c-2.27-2.73-3.47-5.42-4.02-6.97h5.41c.44,1.43,1.46,4.05,3.52,6.85,.05,.07,0,.16-.08,.16Z" />
				</g>
			</g>
			<g>
				<path d="M1138.63,1462.84s.06-.07,.1-.08l4.6-.63c.1-.01,.18,.09,.14,.18l-.3,.7c-.1,.22,0,.48,.23,.57l.6,.25c.22,.09,.48,0,.57-.22l.72-1.62s.06-.07,.11-.08l4.62-.42c.1,0,.18,.09,.13,.19l-.32,.69c-.1,.22,0,.47,.22,.57l.6,.28c.22,.1,.48,0,.58-.21l.75-1.62s.06-.07,.11-.08l4.62-.42c.1,0,.17,.09,.13,.19l-.33,.69c-.1,.22-.01,.48,.21,.58l.59,.28c.22,.1,.48,0,.58-.21l.78-1.63s.06-.07,.11-.08c1.54-.17,3.09-.17,4.63-.26,.1,0,.17,.1,.13,.19l-.34,.67c-.11,.21-.02,.47,.19,.58l.59,.3c.21,.11,.48,.03,.58-.19l.57-1.11,.31,5.93-.68-1.05c-.13-.2-.4-.26-.6-.13l-.55,.35c-.2,.13-.25,.4-.12,.6l.41,.64c.05,.09,0,.2-.1,.2-1.51,.09-3.03,.09-4.54,.25-.05,0-.09-.02-.12-.06l-.99-1.46c-.13-.2-.4-.24-.6-.1l-.54,.38c-.2,.14-.25,.41-.11,.6l.42,.62c.06,.08,0,.2-.1,.21l-4.53,.41s-.09-.02-.12-.06l-1.02-1.46c-.14-.2-.41-.24-.6-.1l-.53,.38c-.19,.14-.24,.41-.1,.6l.44,.62c.06,.08,0,.2-.09,.21l-4.53,.41s-.09-.02-.12-.06l-1.05-1.46c-.14-.19-.41-.25-.6-.09l-.52,.4c-.19,.15-.23,.42-.09,.61l.45,.6c.06,.08,.01,.2-.09,.21l-4.5,.62s-.09-.01-.12-.05l-.33-.43-.77-5.89,.21-.5Z" />
				<g>
					<path d="M1147.8,1470.25l4.6-.42c.08,0,.14,.08,.09,.15-1.75,2.97-2.42,5.66-2.68,7.12-1.67,.17-3.36,.26-5.02,.5,.3-1.62,1.05-4.41,2.93-7.31,.02-.03,.04-.04,.07-.05Z" />
					<path d="M1160.86,1469.08l4.61-.21c.08,0,.13,.09,.09,.16-1.86,2.89-2.64,5.55-2.95,6.98-1.68,.04-3.36,.24-5.03,.38,.37-1.58,1.22-4.38,3.21-7.27,.02-.02,.05-.04,.08-.04Z" />
					<path d="M1141.29,1471.02c1.53-.19,3.04-.46,4.58-.59,.08,0,.14,.08,.1,.15-1.7,2.97-2.3,5.77-2.53,7.21l-3.05,.42-.57-4.38c.37-.88,.83-1.82,1.41-2.77,.02-.03,.04-.04,.07-.05Z" />
					<path d="M1165.94,1475.87l-1.96,.09c.27-1.07,.78-2.69,1.73-4.5l.23,4.41Z" />
					<path d="M1154.33,1469.66l4.6-.42c.08,0,.13,.08,.09,.15-1.8,2.98-2.52,5.68-2.81,7.13l-5.03,.46c.33-1.59,1.14-4.36,3.07-7.28,.02-.03,.04-.04,.08-.05Z" />
				</g>
				<g>
					<path d="M1151.47,1459.82l-4.74,.43s-.06,0-.08-.03c-2.52-2.52-3.97-5.07-4.67-6.52,1.79-.26,3.59-.36,5.39-.54,.57,1.38,1.84,3.89,4.17,6.49,.06,.06,.02,.16-.07,.17Z" />
					<path d="M1164.94,1458.83l-4.75,.21s-.06-.01-.08-.03c-2.42-2.55-3.77-5.1-4.41-6.61,1.8-.15,3.59-.36,5.4-.41,.52,1.4,1.69,3.97,3.92,6.67,.05,.06,.01,.16-.07,.16Z" />
					<path d="M1144.75,1460.43c-1.58,.14-3.14,.41-4.72,.61-.03,0-.06,0-.08-.03-.81-.76-1.52-1.53-2.13-2.28l-.57-4.38,3.28-.45c.6,1.37,1.91,3.78,4.29,6.36,.06,.06,.02,.16-.06,.17Z" />
					<path d="M1162.56,1451.93l2.1-.1,.23,4.41c-1.18-1.72-1.92-3.27-2.34-4.32Z" />
					<path d="M1158.2,1459.21l-4.74,.43s-.06,0-.08-.03c-2.47-2.51-3.87-5.09-4.54-6.58l5.39-.49c.55,1.38,1.77,3.9,4.04,6.51,.05,.06,.01,.16-.07,.17Z" />
				</g>
			</g>
			<g>
				<path d="M1112.13,1467.3s.05-.08,.1-.09c1.51-.34,3.04-.58,4.56-.87,.1-.02,.18,.08,.15,.17l-.25,.71c-.08,.23,.04,.47,.27,.55l.63,.22c.23,.08,.48-.04,.55-.26l.59-1.68s.05-.08,.1-.09l4.56-.85c.1-.02,.18,.08,.15,.17l-.26,.71c-.08,.23,.03,.47,.26,.55l.62,.22c.23,.08,.47-.04,.56-.26l.62-1.69s.06-.08,.1-.09c1.52-.28,3.04-.59,4.58-.77,.1-.01,.18,.09,.14,.18l-.28,.7c-.09,.22,.02,.47,.25,.56l.62,.25c.22,.09,.48-.02,.56-.24l.66-1.65s.06-.07,.1-.08l4.6-.63c.1-.01,.18,.09,.14,.18l-.29,.7c-.09,.22,.02,.47,.24,.56l.61,.25c.22,.09,.48-.02,.57-.24l.48-1.16,.77,5.89-.75-.99c-.15-.19-.42-.22-.6-.08l-.52,.4c-.19,.15-.22,.42-.08,.61l.46,.6c.06,.08,.01,.2-.08,.21l-4.5,.62s-.09-.01-.12-.05l-1.11-1.41c-.15-.19-.42-.22-.61-.08l-.51,.4c-.19,.15-.21,.42-.06,.61l.47,.6c.06,.08,.02,.2-.08,.21-1.51,.18-2.99,.48-4.48,.75-.05,0-.09,0-.12-.04l-1.13-1.36c-.15-.18-.42-.2-.61-.05l-.5,.43c-.18,.16-.2,.43-.05,.61l.48,.58c.06,.08,.02,.2-.08,.21l-4.47,.83s-.09,0-.12-.04l-1.16-1.36c-.16-.18-.43-.2-.61-.05l-.49,.43c-.18,.15-.2,.43-.04,.61l.49,.57c.07,.08,.03,.2-.07,.21-1.49,.29-2.99,.52-4.46,.85-.04,.01-.09,0-.12-.04l-.36-.4-1.24-5.81,.17-.52Z" />
				<g>
					<path d="M1121.86,1474.07l4.54-.84c.08-.01,.14,.07,.1,.14-1.51,3.13-1.96,5.88-2.1,7.35l-4.96,.92c.18-1.61,.7-4.44,2.34-7.52,.01-.03,.04-.05,.07-.05Z" />
					<path d="M1134.79,1471.91l4.57-.63c.08-.01,.14,.08,.1,.15-1.63,3.05-2.19,5.78-2.39,7.24l-5,.69c.24-1.6,.88-4.4,2.65-7.4,.02-.03,.04-.04,.07-.05Z" />
					<path d="M1115.42,1475.26l4.54-.84c.08-.01,.14,.07,.11,.14-1.45,3.12-1.85,5.86-1.97,7.32-1.01,.2-2.03,.35-3.02,.6l-.92-4.32c.3-.92,.68-1.9,1.19-2.86,.01-.03,.04-.05,.07-.05Z" />
					<path d="M1140.38,1478.22l-1.94,.27c.19-1.09,.57-2.75,1.37-4.65l.57,4.38Z" />
					<path d="M1128.31,1472.87c1.51-.31,3.04-.47,4.56-.69,.08-.01,.14,.08,.1,.15-1.58,3.04-2.09,5.75-2.26,7.22l-4.96,.92c.21-1.62,.79-4.46,2.48-7.55,.02-.03,.04-.05,.07-.05Z" />
				</g>
				<g>
					<path d="M1124.7,1463.31l-4.68,.87s-.06,0-.09-.02c-2.71-2.27-4.35-4.7-5.17-6.13l5.33-.99c.68,1.32,2.14,3.72,4.66,6.1,.06,.06,.03,.16-.05,.17Z" />
					<path d="M1138.06,1461.31l-4.71,.65s-.06,0-.09-.03c-2.62-2.39-4.17-4.89-4.92-6.35l5.36-.74c.63,1.35,1.99,3.81,4.42,6.3,.06,.06,.02,.16-.06,.17Z" />
					<path d="M1118.06,1464.54l-4.68,.87s-.06,0-.09-.02c-.88-.73-1.64-1.43-2.3-2.12l-.92-4.32c1.07-.27,2.16-.44,3.25-.65,.71,1.32,2.22,3.7,4.79,6.07,.06,.06,.03,.16-.05,.17Z" />
					<path d="M1135.16,1454.64l2.09-.29,.57,4.38c-1.31-1.61-2.16-3.09-2.66-4.09Z" />
					<path d="M1131.37,1462.23c-1.57,.23-3.15,.39-4.7,.72-.03,0-.06,0-.09-.02-2.65-2.28-4.25-4.72-5.03-6.15l5.33-.98c.65,1.34,2.07,3.79,4.55,6.28,.06,.06,.03,.16-.06,.17Z" />
				</g>
			</g>
			<g>
				<path d="M1086.09,1473.96s.05-.08,.09-.09l4.46-1.27c.1-.03,.19,.06,.16,.16l-.19,.73c-.06,.23,.08,.47,.31,.53l.64,.16c.23,.06,.47-.08,.53-.32l.45-1.74s.05-.08,.09-.09l4.46-1.26c.1-.03,.19,.06,.16,.16l-.2,.74c-.06,.23,.07,.47,.3,.53l.63,.16c.23,.07,.47-.06,.54-.29l.49-1.71s.05-.08,.1-.09l4.52-1.06c.1-.02,.19,.07,.16,.17l-.22,.72c-.07,.23,.06,.47,.29,.54l.63,.19c.23,.07,.47-.06,.54-.29l.52-1.71s.05-.08,.1-.09l4.52-1.06c.1-.02,.18,.07,.15,.17l-.23,.73c-.07,.23,.05,.47,.28,.54l.63,.19c.23,.07,.47-.06,.55-.29l.39-1.2,1.24,5.81-.83-.92c-.16-.18-.43-.18-.61-.02l-.48,.45c-.17,.16-.19,.44-.03,.61l.5,.55c.07,.07,.03,.2-.07,.22l-4.42,1.04s-.09,0-.12-.04l-1.22-1.31c-.16-.17-.44-.18-.61-.02l-.47,.45c-.17,.16-.18,.44-.02,.61l.52,.55c.07,.07,.03,.19-.06,.22l-4.42,1.04s-.09,0-.13-.04l-1.25-1.3c-.17-.17-.44-.18-.61-.02l-.46,.47c-.17,.17-.17,.45,0,.62l.53,.53c.07,.07,.04,.19-.06,.22l-4.38,1.24s-.09,0-.13-.03l-1.26-1.25c-.17-.17-.44-.16-.61,0l-.46,.47c-.17,.17-.16,.45,0,.61l.54,.53c.07,.07,.04,.19-.06,.22l-4.38,1.24s-.09,0-.13-.03l-.39-.38-1.68-5.7,.13-.52Z" />
				<g>
					<path d="M1096.31,1479.86c1.49-.4,2.95-.89,4.46-1.21,.08-.02,.15,.06,.12,.14-1.27,3.15-1.48,6.02-1.51,7.49l-4.87,1.38c.05-1.63,.35-4.51,1.74-7.74,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1109.03,1476.72l4.49-1.05c.08-.02,.14,.06,.11,.14-1.38,3.2-1.72,5.97-1.8,7.45l-4.91,1.15c.11-1.62,.52-4.48,2.03-7.63,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1090,1481.65l4.45-1.26c.08-.02,.15,.06,.12,.13-1.2,3.25-1.38,6.03-1.38,7.51l-2.97,.84-1.25-4.24c.23-.92,.55-1.91,.97-2.93,.01-.03,.04-.05,.06-.06Z" />
					<path d="M1115.08,1482.49l-1.91,.45c.1-1.11,.34-2.8,.99-4.77l.92,4.32Z" />
					<path d="M1102.65,1478.21l4.49-1.05c.08-.02,.15,.06,.11,.14-1.32,3.19-1.61,5.95-1.66,7.42-1.63,.4-3.28,.73-4.9,1.18,.07-1.65,.43-4.49,1.89-7.63,.01-.03,.04-.05,.07-.05Z" />
				</g>
				<g>
					<path d="M1098.27,1468.91c-1.55,.33-3.06,.84-4.59,1.25-.03,0-.06,0-.09-.01-2.87-2.02-4.69-4.3-5.61-5.65l5.2-1.47c.79,1.28,2.41,3.46,5.13,5.72,.06,.05,.04,.16-.04,.17Z" />
					<path d="M1111.43,1465.82l-4.63,1.09s-.06,0-.09-.02c-2.79-2.15-4.54-4.5-5.41-5.89l5.28-1.24c.73,1.29,2.29,3.62,4.89,5.88,.06,.05,.04,.16-.05,.18Z" />
					<path d="M1091.77,1470.7l-4.57,1.3s-.06,0-.09-.01c-.93-.64-1.75-1.3-2.46-1.95l-1.25-4.24,3.18-.9c.81,1.25,2.49,3.5,5.23,5.63,.07,.05,.05,.15-.04,.18Z" />
					<path d="M1108.02,1459.43l2.05-.48,.92,4.32c-1.43-1.48-2.4-2.89-2.97-3.84Z" />
					<path d="M1104.85,1467.36l-4.63,1.09s-.06,0-.09-.02c-2.85-2.14-4.65-4.48-5.53-5.82,1.74-.48,3.51-.85,5.27-1.28,.76,1.28,2.37,3.6,5.03,5.85,.06,.05,.04,.16-.04,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1060.62,1482.42s.04-.09,.08-.1l4.34-1.62c.1-.04,.19,.05,.17,.15l-.14,.75c-.04,.24,.11,.46,.35,.5l.65,.11c.23,.04,.46-.12,.5-.36l.33-1.75s.04-.08,.09-.1l4.4-1.45c.1-.03,.19,.05,.17,.15l-.15,.74c-.05,.23,.1,.46,.34,.51l.65,.13c.24,.05,.47-.1,.51-.34l.36-1.75s.04-.08,.09-.1l4.4-1.45c.1-.03,.19,.05,.17,.15l-.16,.74c-.05,.24,.1,.47,.33,.51l.64,.13c.23,.05,.46-.1,.52-.34l.39-1.76s.04-.08,.09-.1l2.2-.72,2.23-.63c.1-.03,.19,.06,.17,.16l-.18,.73c-.06,.23,.09,.47,.32,.52l.64,.16c.23,.06,.47-.08,.53-.31l.3-1.21,1.68,5.7-.9-.87c-.17-.17-.45-.16-.61,0l-.45,.47c-.16,.17-.15,.44,.02,.61l.55,.53c.07,.07,.04,.19-.05,.22l-2.19,.62-2.16,.71s-.09,0-.13-.03l-1.31-1.2c-.18-.16-.45-.15-.61,.03l-.44,.49c-.16,.18-.15,.45,.03,.61l.56,.51c.07,.07,.05,.19-.05,.22l-4.32,1.43s-.09,0-.13-.03l-1.33-1.19c-.18-.16-.45-.14-.61,.03l-.43,.49c-.16,.18-.14,.45,.04,.61l.57,.5c.08,.07,.05,.19-.04,.22l-4.32,1.43s-.09,0-.13-.03l-1.35-1.17c-.18-.15-.45-.13-.6,.06l-.42,.51c-.15,.18-.13,.46,.05,.61l.57,.49c.08,.06,.05,.19-.04,.22l-4.27,1.59s-.09,0-.13-.02l-.41-.35-2.05-5.57,.09-.53Z" />
				<g>
					<path d="M1071.21,1487.59l4.4-1.45c.08-.03,.15,.05,.13,.13-1.03,3.29-1.08,6.07-1,7.54-1.6,.54-3.22,1.03-4.8,1.65-.06-1.63,.03-4.57,1.22-7.81,.01-.03,.03-.05,.06-.06Z" />
					<path d="M1083.69,1483.47c1.47-.47,2.96-.86,4.44-1.29,.08-.02,.15,.06,.12,.13-1.15,3.24-1.29,6-1.26,7.47-1.62,.48-3.21,1.05-4.82,1.56,0-1.63,.19-4.53,1.46-7.82,.01-.03,.03-.05,.06-.06Z" />
					<path d="M1065.03,1489.81l4.34-1.61c.08-.03,.15,.05,.13,.13-.97,3.33-.96,6.14-.86,7.62l-2.91,1.09-1.53-4.14c.17-.94,.42-1.95,.77-3.01,0-.03,.03-.05,.06-.06Z" />
					<path d="M1090.21,1488.88l-1.89,.54c.02-1.1,.14-2.79,.64-4.77l1.25,4.24Z" />
					<path d="M1077.45,1485.53l4.4-1.45c.08-.03,.15,.05,.12,.13-1.08,3.3-1.16,6.09-1.11,7.58l-4.82,1.59c-.03-1.62,.13-4.51,1.35-7.78,.01-.03,.03-.05,.06-.06Z" />
				</g>
				<g>
					<path d="M1072.43,1476.6l-4.51,1.49s-.06,0-.09-.01c-3.03-1.9-4.98-4-5.99-5.29,1.67-.65,3.39-1.18,5.09-1.75,.87,1.21,2.67,3.38,5.52,5.38,.07,.05,.05,.15-.03,.18Z" />
					<path d="M1085.28,1472.54c-1.52,.44-3.06,.84-4.56,1.32-.03,0-.06,0-.09-.01-2.93-1.9-4.83-4.11-5.8-5.42,1.71-.55,3.4-1.15,5.13-1.66,.83,1.24,2.56,3.48,5.35,5.59,.07,.05,.05,.15-.03,.18Z" />
					<path d="M1066.03,1478.71l-4.44,1.65s-.06,0-.09,0c-.96-.57-1.82-1.16-2.58-1.76l-1.53-4.14,3.08-1.15c.89,1.19,2.71,3.3,5.58,5.23,.07,.05,.06,.15-.02,.18Z" />
					<path d="M1081.38,1466.36l2.02-.57,1.25,4.24c-1.55-1.4-2.62-2.75-3.27-3.66Z" />
					<path d="M1078.83,1474.49l-4.51,1.49s-.06,0-.09-.01c-2.98-1.89-4.92-4.08-5.91-5.39l5.12-1.69c.85,1.22,2.61,3.4,5.42,5.42,.07,.05,.05,.15-.03,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1035.73,1492.35s.04-.09,.08-.11l4.27-1.78c.09-.04,.19,.04,.18,.14l-.1,.75c-.03,.24,.14,.45,.38,.48l.66,.08c.24,.03,.46-.14,.49-.37l.23-1.77s.04-.09,.08-.1l4.27-1.77c.09-.04,.19,.04,.18,.14l-.1,.75c-.03,.24,.13,.46,.37,.49l.65,.09c.24,.03,.46-.14,.49-.37l.25-1.78s.04-.09,.08-.1c1.43-.58,2.84-1.2,4.29-1.72,.1-.04,.19,.04,.18,.14l-.12,.74c-.04,.24,.13,.46,.36,.5l.65,.11c.24,.04,.46-.12,.5-.36l.28-1.76s.04-.08,.08-.1l4.34-1.62c.1-.04,.19,.04,.18,.14l-.13,.75c-.04,.24,.12,.46,.36,.5l.65,.11c.24,.04,.46-.12,.5-.36l.21-1.23,2.05,5.57-.96-.8c-.18-.15-.46-.13-.61,.06l-.42,.51c-.15,.18-.12,.46,.06,.61l.58,.48c.08,.06,.06,.19-.04,.22l-4.27,1.59s-.09,0-.13-.02l-1.39-1.13c-.19-.15-.46-.13-.61,.06l-.41,.5c-.15,.18-.12,.46,.07,.61l.59,.48c.08,.06,.06,.19-.03,.22-1.43,.51-2.82,1.12-4.23,1.7-.04,.02-.09,.01-.13-.02l-1.4-1.1c-.19-.15-.46-.11-.6,.08l-.4,.52c-.15,.19-.11,.46,.08,.61l.59,.46c.08,.06,.06,.19-.03,.23l-4.21,1.74s-.09,.01-.13-.02l-1.42-1.09c-.19-.15-.46-.11-.61,.08l-.4,.52c-.14,.19-.11,.46,.08,.61l.6,.46c.08,.06,.06,.19-.03,.22l-4.21,1.75s-.09,.01-.13-.01l-.43-.32-2.36-5.45,.06-.54Z" />
				<g>
					<path d="M1046.64,1496.99l4.29-1.77c.08-.03,.15,.04,.13,.12-.82,3.37-.71,6.16-.54,7.63l-4.72,1.95c-.15-1.62-.2-4.51,.78-7.86,0-.03,.03-.05,.06-.07Z" />
					<path d="M1058.86,1492.11l4.34-1.62c.08-.03,.15,.04,.13,.12-.92,3.33-.88,6.12-.76,7.59l-4.77,1.78c-.11-1.62-.08-4.5,1-7.81,0-.03,.03-.05,.06-.06Z" />
					<path d="M1040.55,1499.5l4.29-1.77c.08-.03,.15,.04,.14,.12-.79,3.36-.64,6.13-.46,7.6-.96,.41-1.93,.77-2.88,1.21l-1.76-4.05c.12-.96,.31-1.98,.61-3.04,0-.03,.03-.05,.06-.07Z" />
					<path d="M1065.72,1497.03l-1.85,.69c-.06-1.11-.04-2.81,.33-4.84l1.53,4.14Z" />
					<path d="M1052.72,1494.47c1.42-.61,2.88-1.13,4.32-1.68,.08-.03,.15,.04,.13,.12-.88,3.32-.81,6.08-.66,7.57l-4.72,1.95c-.13-1.62-.15-4.53,.87-7.9,0-.03,.03-.05,.06-.07Z" />
				</g>
				<g>
					<path d="M1047.17,1485.88l-4.38,1.81s-.06,.01-.09,0c-3.11-1.67-5.2-3.71-6.28-4.94l4.95-2.05c.94,1.15,2.85,3.2,5.81,5,.07,.04,.06,.15-.01,.18Z" />
					<path d="M1059.72,1481.06l-4.44,1.66s-.06,0-.09,0c-3.07-1.77-5.11-3.87-6.16-5.14l5.03-1.88c.9,1.18,2.77,3.28,5.68,5.19,.07,.05,.06,.15-.02,.18Z" />
					<path d="M1040.95,1488.45l-4.38,1.81s-.06,.01-.09,0c-1-.53-1.89-1.08-2.67-1.63l-1.76-4.05c.99-.46,2.01-.85,3.01-1.27,.95,1.14,2.9,3.18,5.9,4.97,.07,.04,.06,.15-.01,.18Z" />
					<path d="M1055.44,1475.18l1.96-.73,1.53,4.14c-1.63-1.28-2.78-2.55-3.49-3.41Z" />
					<path d="M1053.42,1483.41c-1.48,.55-2.97,1.09-4.42,1.71-.03,.01-.06,.01-.09,0-3.07-1.68-5.13-3.74-6.2-4.98l4.95-2.05c.91,1.15,2.82,3.26,5.77,5.14,.07,.05,.06,.15-.02,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1011.44,1503.6s.03-.09,.07-.11l4.16-2.01c.09-.04,.2,.03,.19,.13l-.06,.76c-.02,.24,.16,.45,.4,.46l.66,.05c.24,.02,.45-.16,.47-.4l.14-1.79s.03-.09,.07-.11l4.16-2c.09-.04,.2,.03,.19,.13l-.07,.75c-.02,.24,.16,.45,.4,.47l.66,.06c.24,.02,.45-.15,.47-.39l.17-1.77s.03-.09,.08-.11l4.22-1.9c.09-.04,.2,.03,.19,.13l-.08,.75c-.02,.24,.15,.45,.39,.47l.66,.07c.24,.02,.45-.15,.48-.39l.18-1.78s.03-.09,.08-.11l4.22-1.9c.09-.04,.19,.03,.18,.13l-.08,.76c-.03,.24,.15,.45,.38,.48l.65,.07c.24,.02,.45-.15,.48-.39l.14-1.25,2.36,5.45-1-.74c-.19-.14-.46-.1-.6,.1l-.39,.53c-.14,.19-.1,.47,.09,.61l.6,.45c.08,.06,.07,.19-.02,.23l-4.16,1.87s-.09,.01-.13-.01l-1.44-1.05c-.19-.14-.46-.1-.6,.1l-.38,.53c-.14,.19-.09,.46,.1,.6l.61,.44c.08,.06,.07,.18-.02,.23l-4.16,1.87s-.09,.01-.13-.01l-1.46-1.04c-.2-.14-.47-.1-.6,.1l-.38,.53c-.14,.19-.09,.46,.11,.6l.62,.44c.08,.06,.07,.18-.02,.22l-4.12,1.98s-.09,.02-.13-.01l-1.47-1.01c-.2-.14-.46-.08-.6,.11l-.37,.54c-.13,.2-.08,.47,.11,.6l.62,.43c.08,.06,.07,.18-.02,.23l-4.12,1.98s-.09,.02-.13,0l-.45-.3-2.6-5.34,.04-.54Z" />
				<g>
					<path d="M1022.52,1507.66c1.4-.68,2.79-1.35,4.21-1.97,.07-.03,.16,.03,.14,.11-.68,3.36-.42,6.16-.18,7.63l-4.62,2.23c-.22-1.61-.41-4.51,.4-7.93,0-.03,.03-.06,.05-.07Z" />
					<path d="M1034.52,1502.18l4.24-1.91c.07-.03,.15,.03,.14,.12-.73,3.4-.54,6.19-.34,7.66l-4.67,2.1c-.19-1.62-.31-4.51,.58-7.9,0-.03,.03-.05,.06-.07Z" />
					<path d="M1016.58,1510.52l4.19-2.02c.07-.04,.16,.03,.14,.11-.63,3.41-.35,6.19-.1,7.65l-2.83,1.36-1.94-3.97c.08-.95,.23-1.98,.48-3.06,0-.03,.03-.06,.05-.07Z" />
					<path d="M1041.64,1506.66l-1.81,.82c-.12-1.11-.2-2.82,.06-4.87l1.76,4.05Z" />
					<path d="M1028.51,1504.88l4.24-1.91c.07-.03,.16,.03,.14,.11-.7,3.39-.48,6.16-.27,7.63l-4.67,2.1c-.21-1.61-.35-4.49,.51-7.87,0-.03,.03-.05,.06-.07Z" />
				</g>
				<g>
					<path d="M1022.51,1496.55c-1.45,.63-2.86,1.32-4.28,2.01-.03,.01-.06,.01-.09,0-3.17-1.5-5.35-3.44-6.48-4.61l4.8-2.31c.99,1.1,2.99,3.03,6.05,4.74,.07,.04,.07,.15,0,.18Z" />
					<path d="M1034.75,1491.05l-4.31,1.94s-.06,.01-.09,0c-3.14-1.58-5.28-3.56-6.39-4.77l4.87-2.19c.96,1.13,2.93,3.12,5.93,4.84,.07,.04,.07,.15,0,.18Z" />
					<path d="M1016.45,1499.42l-4.25,2.05s-.06,.01-.09,0c-1.01-.47-1.93-.99-2.74-1.51l-1.94-3.97,2.93-1.41c1,1.1,3.03,3.03,6.09,4.66,.07,.04,.07,.14,0,.18Z" />
					<path d="M1030.16,1485.43l1.89-.85,1.76,4.05c-1.69-1.18-2.91-2.37-3.65-3.2Z" />
					<path d="M1028.63,1493.8l-4.31,1.94s-.06,.01-.09,0c-3.17-1.56-5.34-3.54-6.46-4.73l4.87-2.19c.98,1.12,2.97,3.1,6,4.81,.07,.04,.07,.15,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M987.62,1515.73s.03-.09,.07-.11l4.07-2.17c.09-.05,.2,.02,.19,.12l-.03,.76c0,.24,.18,.44,.41,.45l.66,.02c.24,0,.44-.18,.45-.41l.08-1.78s.03-.09,.07-.11l4.11-2.1c.09-.05,.2,.02,.19,.12l-.04,.76c-.01,.24,.17,.44,.41,.45l.66,.03c.24,.01,.44-.17,.46-.41l.09-1.79s.03-.09,.07-.11l4.11-2.1c.09-.05,.2,.02,.19,.12l-.04,.76c-.01,.24,.17,.44,.41,.46l.66,.03c.24,.01,.44-.17,.46-.41l.11-1.79s.03-.09,.07-.11c1.36-.71,2.76-1.36,4.14-2.03,.09-.04,.2,.03,.19,.13l-.05,.75c-.02,.24,.16,.45,.4,.46l.66,.05c.24,.02,.45-.16,.46-.4l.09-1.24,2.6,5.34-1.03-.7c-.2-.13-.47-.08-.6,.11l-.37,.54c-.13,.2-.08,.47,.12,.6l.63,.42c.08,.06,.08,.18-.02,.23-1.37,.67-2.75,1.31-4.1,2.01-.04,.02-.09,.02-.13,0l-1.49-.98c-.2-.13-.47-.07-.6,.13l-.36,.55c-.13,.2-.07,.47,.13,.6l.63,.41c.08,.06,.08,.18-.01,.23l-4.07,2.08s-.09,.02-.13,0l-1.5-.97c-.2-.13-.47-.07-.6,.13l-.36,.55c-.13,.2-.07,.47,.13,.6l.63,.41c.08,.05,.08,.18-.01,.23l-4.07,2.08s-.09,.02-.13,0l-1.51-.97c-.2-.13-.47-.07-.6,.13l-.35,.56c-.13,.2-.07,.47,.14,.6l.64,.4c.08,.05,.08,.18,0,.23l-4.04,2.15s-.09,.02-.13,0l-.46-.29-2.78-5.25,.02-.54Z" />
				<g>
					<path d="M998.85,1519.4l4.15-2.12c.07-.04,.16,.03,.14,.11-.54,3.42-.19,6.18,.1,7.63-1.53,.79-3.06,1.54-4.58,2.36-.28-1.62-.56-4.5,.14-7.91,0-.03,.03-.06,.05-.07Z" />
					<path d="M1010.63,1513.39l4.19-2.02c.07-.04,.16,.03,.14,.11-.6,3.4-.3,6.16-.04,7.62-1.55,.72-3.06,1.54-4.6,2.31-.25-1.61-.49-4.51,.25-7.95,0-.03,.03-.06,.05-.07Z" />
					<path d="M992.99,1522.47c1.37-.73,2.74-1.48,4.12-2.18,.07-.04,.16,.03,.15,.11-.52,3.41-.13,6.21,.16,7.65l-2.79,1.49-2.07-3.9c.05-.96,.16-2,.37-3.09,0-.03,.03-.06,.05-.07Z" />
					<path d="M1017.98,1517.62l-1.8,.87c-.16-1.1-.31-2.79-.14-4.84l1.94,3.97Z" />
					<path d="M1004.74,1516.39l4.15-2.12c.07-.04,.16,.03,.14,.11-.56,3.43-.23,6.2,.05,7.66l-4.59,2.34c-.27-1.61-.52-4.49,.2-7.92,0-.03,.03-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M998.42,1508.32l-4.2,2.14s-.06,.01-.09,0c-3.24-1.41-5.48-3.28-6.65-4.41,1.55-.84,3.15-1.62,4.72-2.43,1.03,1.07,3.11,2.95,6.22,4.51,.07,.04,.08,.14,0,.18Z" />
					<path d="M1010.41,1502.33l-4.25,2.05s-.06,.01-.09,0c-3.2-1.43-5.4-3.32-6.56-4.48,1.58-.79,3.14-1.63,4.75-2.39,1.01,1.09,3.07,3.01,6.15,4.63,.07,.04,.07,.14,0,.18Z" />
					<path d="M992.46,1511.37c-1.4,.71-2.78,1.47-4.17,2.2-.03,.01-.06,.02-.09,0-1.03-.43-1.95-.92-2.78-1.41l-2.07-3.9,2.85-1.52c1.04,1.07,3.12,2.9,6.24,4.45,.07,.04,.08,.14,0,.18Z" />
					<path d="M1005.56,1496.89l1.87-.9,1.94,3.97c-1.75-1.12-3.02-2.27-3.8-3.07Z" />
					<path d="M1004.39,1505.28l-4.2,2.14s-.06,.01-.09,0c-3.22-1.42-5.44-3.3-6.61-4.45l4.73-2.41c1.02,1.08,3.08,2.97,6.17,4.54,.07,.04,.07,.14,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M964.18,1528.5s.03-.09,.07-.11l4.03-2.23c.09-.05,.2,.02,.2,.12v.76c-.02,.24,.17,.44,.41,.44h.66c.24,.02,.44-.17,.44-.41l.03-1.79s.03-.09,.07-.11l4.03-2.23c.09-.05,.2,.02,.19,.12l-.02,.76c0,.24,.18,.44,.42,.44h.66c.24,.02,.44-.17,.44-.41l.04-1.79s.03-.09,.07-.11c1.34-.75,2.69-1.48,4.05-2.2,.09-.05,.2,.02,.19,.12l-.02,.75c0,.24,.18,.44,.42,.45l.66,.02c.24,0,.44-.18,.45-.42l.06-1.78s.03-.09,.07-.11l4.07-2.17c.09-.05,.2,.02,.19,.12l-.03,.76c0,.24,.18,.44,.42,.45l.66,.02c.24,0,.44-.18,.45-.42l.04-1.25,2.78,5.25-1.06-.66c-.2-.13-.47-.07-.6,.14l-.35,.56c-.13,.2-.06,.47,.14,.6l.64,.4c.09,.05,.08,.18,0,.23l-4.04,2.15s-.09,.02-.13,0l-1.52-.94c-.2-.13-.47-.06-.6,.14l-.34,.56c-.13,.2-.06,.47,.14,.6l.64,.4c.09,.05,.08,.18,0,.23-1.35,.71-2.69,1.44-4.02,2.19-.04,.02-.09,.02-.13,0l-1.52-.93c-.2-.12-.47-.06-.59,.15l-.34,.56c-.12,.2-.06,.47,.15,.6l.65,.39c.09,.05,.08,.18,0,.23l-4.01,2.21s-.09,.02-.13,0l-1.53-.92c-.2-.12-.47-.06-.59,.15l-.34,.56c-.12,.2-.06,.47,.15,.59l.65,.39c.09,.05,.08,.18,0,.23l-4.01,2.21s-.09,.02-.13,0l-.46-.28-2.9-5.18v-.54Z" />
				<g>
					<path d="M975.51,1531.92l4.09-2.26c.07-.04,.16,.02,.15,.1-.44,3.44-.02,6.21,.3,7.65l-4.54,2.51c-.32-1.6-.66-4.48-.05-7.93,0-.03,.02-.06,.05-.07Z" />
					<path d="M987.15,1525.58l4.11-2.2c.07-.04,.16,.02,.15,.1-.48,3.43-.1,6.2,.21,7.65l-4.56,2.43c-.3-1.6-.61-4.48,.04-7.92,0-.03,.02-.06,.05-.07Z" />
					<path d="M969.71,1535.12l4.09-2.26c.07-.04,.16,.02,.15,.1-.43,3.43,0,6.19,.33,7.64l-2.77,1.53-2.16-3.85c.03-.96,.12-1.99,.31-3.09,0-.03,.02-.06,.05-.07Z" />
					<path d="M994.63,1529.53l-1.77,.95c-.2-1.09-.4-2.79-.3-4.85l2.07,3.9Z" />
					<path d="M981.31,1528.71c1.36-.76,2.74-1.47,4.11-2.21,.07-.04,.16,.02,.15,.1-.47,3.42-.06,6.18,.25,7.62l-4.54,2.5c-.31-1.6-.64-4.49-.02-7.95,0-.03,.02-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M974.77,1520.83l-4.12,2.27s-.06,.02-.09,0c-3.26-1.32-5.54-3.13-6.74-4.24l4.62-2.55c1.05,1.04,3.18,2.87,6.32,4.33,.08,.03,.08,.14,0,.18Z" />
					<path d="M986.55,1514.5l-4.16,2.22s-.06,.02-.09,0c-3.25-1.36-5.52-3.2-6.71-4.32l4.67-2.49c1.04,1.06,3.15,2.9,6.27,4.41,.07,.04,.08,.14,0,.18Z" />
					<path d="M968.92,1524.06l-4.12,2.27s-.06,.02-.09,0c-1.04-.42-1.98-.88-2.82-1.36l-2.16-3.85,2.82-1.56c1.06,1.04,3.2,2.86,6.35,4.31,.08,.03,.08,.14,0,.18Z" />
					<path d="M981.54,1509.23l1.82-.97,2.07,3.9c-1.78-1.06-3.08-2.16-3.88-2.93Z" />
					<path d="M980.65,1517.65c-1.38,.74-2.78,1.47-4.15,2.23-.03,.01-.06,.02-.09,0-3.25-1.32-5.52-3.14-6.71-4.25l4.62-2.55c1.05,1.05,3.17,2.89,6.32,4.39,.07,.04,.08,.14,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M941.01,1541.68s.03-.09,.07-.11l3.99-2.29c.09-.05,.2,.01,.2,.11v.76c0,.24,.19,.43,.43,.43h.66c.24,0,.43-.19,.43-.43v-1.79s.03-.09,.07-.11l3.99-2.28c.09-.05,.2,.01,.2,.11v.76c0,.24,.19,.43,.43,.44h.66c.24,0,.44-.19,.44-.42v-1.79s.04-.09,.08-.11l4-2.27c.09-.05,.2,.01,.2,.12v.76c0,.24,.18,.44,.42,.44h.66c.24,0,.43-.18,.44-.42l.02-1.79s.03-.09,.07-.11l4-2.27c.09-.05,.2,.01,.19,.12v.76c-.01,.24,.18,.44,.42,.44h.66c.24,0,.44-.18,.44-.42l.02-1.25,2.9,5.18-1.07-.64c-.21-.12-.47-.05-.59,.15l-.33,.57c-.12,.21-.05,.47,.15,.59l.65,.39c.09,.05,.09,.18,0,.23l-3.99,2.26s-.09,.02-.13,0l-1.54-.91c-.21-.12-.47-.05-.59,.15l-.33,.57c-.12,.21-.05,.47,.15,.59l.65,.38c.09,.05,.09,.18,0,.23l-3.99,2.26s-.09,.02-.13,0l-1.54-.91c-.21-.12-.47-.05-.59,.15l-.33,.57c-.12,.21-.05,.47,.16,.59l.65,.38c.09,.05,.09,.18,0,.23l-3.98,2.28s-.09,.02-.13,0l-1.54-.9c-.21-.12-.47-.05-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l.65,.38c.09,.05,.09,.18,0,.23l-3.98,2.29s-.09,.02-.13,0l-.47-.27-2.96-5.15v-.54Z" />
				<g>
					<path d="M952.38,1544.92l4.06-2.31c.07-.04,.16,.02,.15,.1-.39,3.44,.07,6.19,.41,7.64l-4.52,2.6c-.34-1.6-.72-4.48-.16-7.95,0-.03,.02-.06,.05-.07Z" />
					<path d="M963.92,1538.37l4.07-2.3c.07-.04,.16,.02,.15,.1-.41,3.45,.04,6.21,.37,7.65l-4.52,2.56c-.33-1.6-.7-4.48-.11-7.94,0-.03,.02-.06,.05-.07Z" />
					<path d="M946.61,1548.23l4.06-2.33c.07-.04,.16,.02,.15,.1-.38,3.45,.08,6.21,.43,7.65l-2.76,1.59-2.2-3.83c.02-.96,.1-2,.28-3.1,0-.03,.02-.06,.05-.07Z" />
					<path d="M971.5,1542.13l-1.76,.99c-.22-1.09-.46-2.78-.4-4.85l2.16,3.85Z" />
					<path d="M958.15,1541.64l4.07-2.3c.07-.04,.16,.02,.15,.1-.4,3.44,.05,6.2,.39,7.64l-4.52,2.56c-.33-1.59-.71-4.47-.14-7.93,0-.03,.02-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M951.47,1533.86l-4.08,2.32s-.06,.02-.09,0c-3.27-1.27-5.57-3.05-6.78-4.14l4.55-2.61c1.07,1.02,3.22,2.82,6.39,4.24,.08,.03,.08,.14,0,.18Z" />
					<path d="M963.08,1527.29l-4.09,2.31s-.06,.02-.09,0c-3.27-1.28-5.56-3.08-6.77-4.18l4.58-2.59c1.06,1.03,3.2,2.84,6.35,4.27,.08,.03,.08,.14,0,.18Z" />
					<path d="M945.68,1537.17l-4.07,2.34s-.06,.02-.09,0c-1.04-.4-1.98-.85-2.82-1.32l-2.2-3.83,2.78-1.6c1.07,1.03,3.22,2.81,6.39,4.22,.08,.03,.08,.14,.01,.18Z" />
					<path d="M957.96,1522.14l1.78-1.01,2.16,3.85c-1.8-1.02-3.12-2.09-3.94-2.85Z" />
					<path d="M957.27,1530.58l-4.09,2.31s-.06,.02-.09,0c-3.28-1.28-5.58-3.07-6.79-4.17l4.58-2.59c1.07,1.03,3.21,2.83,6.38,4.26,.08,.03,.08,.14,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M917.95,1554.98s.02-.09,.07-.11l3.98-2.29c.09-.05,.2,.01,.2,.11v.76c0,.24,.19,.43,.43,.43h.66c.24,0,.43-.19,.43-.43v-1.79s.02-.09,.06-.11l3.98-2.3c.09-.05,.2,.01,.2,.11v.76c0,.24,.19,.43,.43,.43h.66c.24,0,.43-.19,.43-.43v-1.79s.02-.09,.06-.11l3.98-2.3c.09-.05,.2,.01,.2,.11v.76c0,.24,.19,.43,.43,.43h.66c.24,0,.43-.19,.43-.43v-1.79s.02-.09,.06-.11l3.99-2.29c.09-.05,.2,.01,.2,.11v.76c0,.24,.19,.43,.43,.43h.66c.24,0,.43-.19,.43-.43v-1.25s2.96,5.15,2.96,5.15l-1.08-.63c-.21-.12-.47-.05-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l.65,.38c.09,.05,.09,.18,0,.23l-3.98,2.29s-.09,.02-.13,0l-1.55-.89c-.21-.12-.47-.05-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l.65,.38c.09,.05,.09,.18,0,.23l-3.98,2.3s-.09,.02-.13,0l-1.55-.89c-.21-.12-.47-.05-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l.65,.38c.09,.05,.09,.18,0,.23l-3.98,2.3s-.09,.02-.13,0l-1.55-.89c-.21-.12-.47-.05-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l.65,.38c.09,.05,.09,.18,0,.23l-3.98,2.29s-.09,.02-.13,0l-.47-.27-2.96-5.15v-.54Z" />
				<g>
					<path d="M929.33,1558.19l4.06-2.34c.07-.04,.16,.02,.15,.1-.37,3.45,.1,6.21,.45,7.65l-4.53,2.62c-.34-1.6-.73-4.48-.18-7.94,0-.03,.02-.06,.05-.07Z" />
					<path d="M940.85,1551.54l4.06-2.33c.07-.04,.16,.02,.15,.1-.38,3.44,.09,6.2,.44,7.64l-4.52,2.61c-.34-1.59-.73-4.48-.17-7.94,0-.03,.02-.06,.05-.07Z" />
					<path d="M923.56,1561.51l4.06-2.34c.07-.04,.16,.02,.15,.1-.37,3.45,.09,6.21,.44,7.65l-2.78,1.6-2.2-3.83c.02-.96,.1-2,.28-3.1,0-.03,.02-.06,.05-.07Z" />
					<path d="M948.49,1555.23l-1.76,1.01c-.23-1.09-.48-2.78-.44-4.84l2.2,3.83Z" />
					<path d="M935.09,1554.86l4.06-2.34c.07-.04,.16,.02,.15,.1-.37,3.45,.1,6.21,.44,7.65l-4.53,2.62c-.34-1.6-.73-4.48-.17-7.95,0-.03,.02-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M928.36,1547.13l-4.06,2.34s-.06,.02-.09,0c-3.28-1.26-5.58-3.03-6.79-4.12l4.53-2.62c1.07,1.02,3.23,2.81,6.4,4.21,.08,.03,.08,.14,.01,.18Z" />
					<path d="M939.9,1540.49l-4.07,2.34s-.06,.02-.09,0c-3.28-1.26-5.58-3.03-6.79-4.13l4.54-2.62c1.07,1.02,3.23,2.81,6.4,4.22,.08,.03,.08,.14,.01,.18Z" />
					<path d="M922.6,1550.46l-4.06,2.34s-.06,.02-.09,0c-1.04-.4-1.98-.85-2.82-1.32l-2.2-3.83,2.76-1.59c1.07,1.02,3.23,2.81,6.4,4.21,.08,.03,.08,.14,.01,.18Z" />
					<path d="M934.72,1535.38l1.77-1.02,2.2,3.83c-1.81-1-3.14-2.07-3.97-2.81Z" />
					<path d="M934.12,1543.81l-4.06,2.34s-.06,.02-.09,0c-3.28-1.26-5.58-3.03-6.79-4.12l4.53-2.62c1.07,1.02,3.23,2.81,6.4,4.21,.08,.03,.08,.14,.01,.18Z" />
				</g>
			</g>
			<g>
				<path d="M894.87,1568.16s.03-.09,.07-.11l3.99-2.27c.09-.05,.2,.01,.2,.12v.76c0,.24,.19,.44,.42,.44h.66c.24,0,.43-.19,.44-.42v-1.79s.04-.09,.08-.11l3.99-2.27c.09-.05,.2,.01,.2,.11v.76c0,.24,.19,.43,.43,.44h.66c.24,0,.43-.19,.44-.42v-1.78s.03-.09,.07-.11l3.98-2.28c.09-.05,.2,.01,.2,.11v.76c0,.24,.19,.43,.43,.43h.66c.24,0,.43-.19,.43-.43v-1.79s.03-.09,.07-.11l3.98-2.29c.09-.05,.2,.01,.2,.11v.76c0,.24,.19,.43,.43,.43h.66c.24,0,.43-.19,.43-.43v-1.25s2.97,5.15,2.97,5.15l-1.08-.63c-.21-.12-.47-.05-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l.65,.38c.09,.05,.09,.18,0,.23l-3.98,2.29s-.09,.02-.13,0l-1.55-.9c-.21-.12-.47-.05-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l.65,.38c.09,.05,.09,.18,0,.23l-3.99,2.29s-.09,.02-.13,0l-1.55-.9c-.21-.12-.47-.05-.59,.15l-.33,.57c-.12,.21-.05,.47,.16,.59l.65,.38c.09,.05,.09,.18,0,.23l-4,2.27s-.09,.02-.13,0l-1.54-.9c-.21-.12-.47-.05-.59,.15l-.33,.57c-.12,.21-.05,.47,.15,.59l.65,.38c.09,.05,.09,.18,0,.23l-4,2.27s-.09,.02-.13,0l-.46-.27-2.92-5.17v-.54Z" />
				<g>
					<path d="M906.22,1571.45l4.08-2.32c.07-.04,.16,.02,.15,.1-.39,3.44,.05,6.21,.39,7.65l-4.57,2.6c-.32-1.6-.68-4.49-.1-7.96,0-.03,.02-.06,.05-.07Z" />
					<path d="M917.79,1564.84l4.07-2.34c.07-.04,.16,.02,.15,.1-.38,3.45,.08,6.21,.43,7.65l-4.55,2.62c-.33-1.6-.71-4.48-.15-7.95,0-.03,.02-.06,.05-.07Z" />
					<path d="M900.42,1574.75l4.08-2.32c.07-.04,.16,.02,.15,.1-.4,3.45,.04,6.21,.37,7.66l-2.8,1.59-2.17-3.85c.03-.96,.12-2.01,.31-3.11,0-.03,.02-.06,.05-.07Z" />
					<path d="M925.44,1568.52l-1.77,1.02c-.23-1.09-.48-2.78-.44-4.84l2.2,3.83Z" />
					<path d="M912.01,1568.16l4.07-2.34c.07-.04,.16,.02,.15,.1-.38,3.45,.08,6.21,.42,7.66l-4.57,2.6c-.33-1.6-.69-4.48-.12-7.94,0-.03,.02-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M905.31,1560.4l-4.07,2.31s-.06,.02-.09,0c-3.27-1.28-5.55-3.08-6.75-4.18l4.52-2.57c1.07,1.03,3.2,2.83,6.37,4.25,.08,.03,.08,.14,.01,.18Z" />
					<path d="M916.83,1553.78l-4.06,2.34s-.06,.02-.09,0c-3.27-1.26-5.57-3.05-6.77-4.14l4.52-2.6c1.07,1.03,3.22,2.81,6.39,4.22,.08,.03,.08,.14,.01,.18Z" />
					<path d="M899.54,1563.68l-4.07,2.31s-.06,.02-.09,0c-1.03-.41-1.97-.87-2.8-1.34l-2.17-3.85,2.76-1.57c1.06,1.03,3.19,2.84,6.35,4.26,.08,.03,.08,.14,0,.18Z" />
					<path d="M911.67,1548.67l1.76-1.01,2.2,3.83c-1.8-1-3.14-2.07-3.96-2.82Z" />
					<path d="M911.07,1557.1l-4.06,2.34s-.06,.02-.09,0c-3.27-1.28-5.56-3.07-6.77-4.17l4.52-2.58c1.07,1.03,3.21,2.82,6.38,4.23,.08,.03,.08,.14,.01,.18Z" />
				</g>
			</g>
			<g>
				<path d="M871.61,1580.96s.03-.09,.07-.11l4.03-2.17c.09-.05,.2,.02,.19,.12l-.02,.75c0,.24,.18,.44,.42,.45l.66,.02c.24,0,.44-.18,.44-.42l.04-1.78s.03-.09,.07-.11c1.35-.72,2.69-1.45,4.02-2.2,.09-.05,.2,.02,.19,.12v.76c-.02,.24,.17,.44,.41,.44h.65c.24,.02,.44-.17,.44-.41l.03-1.79s.03-.09,.07-.11l4.01-2.23c.09-.05,.2,.02,.19,.12v.76c-.01,.24,.18,.44,.41,.44h.66c.24,.02,.44-.17,.44-.41l.03-1.79s.03-.09,.07-.11l4-2.23c.09-.05,.2,.02,.2,.12v.75c-.01,.24,.18,.44,.42,.44h.66c.24,.02,.43-.18,.44-.42v-1.25s2.93,5.18,2.93,5.18l-1.07-.63c-.21-.12-.47-.05-.59,.15l-.34,.56c-.12,.21-.05,.47,.15,.59l.65,.39c.09,.05,.09,.18,0,.23l-4.03,2.24s-.09,.02-.13,0l-1.54-.92c-.21-.12-.47-.06-.59,.15l-.34,.56c-.12,.21-.06,.47,.15,.59l.65,.39c.09,.05,.08,.18,0,.23l-4.03,2.24s-.09,.02-.13,0l-1.53-.92c-.2-.12-.47-.06-.59,.15l-.34,.56c-.12,.21-.06,.47,.15,.6l.65,.39c.09,.05,.08,.18,0,.23-1.34,.75-2.69,1.49-4.04,2.21-.04,.02-.09,.02-.13,0l-1.53-.94c-.2-.13-.47-.06-.6,.14l-.35,.56c-.13,.2-.06,.47,.14,.6l.65,.4c.09,.05,.08,.18,0,.23l-4.06,2.18s-.09,.02-.13,0l-.46-.28-2.8-5.24,.02-.54Z" />
				<g>
					<path d="M882.91,1584.49c1.38-.74,2.75-1.52,4.12-2.27,.07-.04,.16,.02,.15,.1-.44,3.45-.04,6.23,.27,7.68l-4.66,2.51c-.29-1.61-.59-4.5,.06-7.95,0-.03,.03-.06,.05-.07Z" />
					<path d="M894.6,1578.01l4.11-2.29c.07-.04,.16,.02,.15,.1-.41,3.45,0,6.21,.34,7.65l-4.61,2.56c-.31-1.6-.64-4.5-.03-7.95,0-.03,.02-.06,.05-.07Z" />
					<path d="M877.03,1587.66l4.15-2.23c.07-.04,.16,.02,.15,.1-.47,3.43-.1,6.21,.21,7.66l-2.85,1.53-2.09-3.89c.05-.96,.17-2,.38-3.1,0-.03,.03-.06,.05-.07Z" />
					<path d="M902.23,1581.78l-1.79,1c-.22-1.09-.44-2.78-.38-4.85l2.17,3.85Z" />
					<path d="M888.76,1581.26l4.11-2.28c.07-.04,.16,.02,.15,.1-.43,3.44-.02,6.21,.3,7.66l-4.61,2.56c-.3-1.61-.62-4.5,0-7.97,0-.03,.02-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M882.18,1573.41c-1.36,.75-2.72,1.52-4.09,2.26-.03,.01-.06,.02-.09,0-3.24-1.35-5.49-3.2-6.67-4.33l4.55-2.45c1.05,1.05,3.15,2.88,6.28,4.34,.07,.03,.08,.14,0,.18Z" />
					<path d="M893.77,1566.96l-4.08,2.28s-.06,.02-.09,0c-3.26-1.31-5.52-3.13-6.71-4.24l4.53-2.52c1.06,1.04,3.19,2.87,6.34,4.3,.08,.03,.08,.14,0,.18Z" />
					<path d="M876.37,1576.59l-4.11,2.21s-.06,.02-.09,0c-1.03-.43-1.95-.91-2.77-1.41l-2.09-3.89,2.78-1.5c1.04,1.06,3.13,2.91,6.26,4.4,.07,.04,.08,.14,0,.18Z" />
					<path d="M888.65,1561.8l1.76-.99,2.17,3.85c-1.79-1.01-3.11-2.1-3.93-2.86Z" />
					<path d="M887.97,1570.19l-4.08,2.27s-.06,.02-.09,0c-3.25-1.32-5.5-3.14-6.68-4.26l4.53-2.52c1.05,1.04,3.17,2.87,6.31,4.32,.07,.03,.08,.14,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M848.09,1593.24s.03-.09,.07-.11c1.37-.68,2.73-1.37,4.08-2.08,.09-.05,.19,.02,.19,.12l-.04,.76c-.01,.24,.17,.44,.41,.45l.65,.03c.24,.01,.44-.17,.45-.41l.08-1.79s.03-.09,.07-.11l4.07-2.11c.09-.05,.2,.02,.19,.12l-.03,.75c-.01,.24,.17,.44,.41,.45l.66,.03c.24,.01,.44-.17,.45-.41l.08-1.78s.03-.09,.07-.11l4.07-2.11c.09-.05,.2,.02,.19,.12l-.03,.75c0,.24,.18,.44,.42,.45l.66,.03c.24,.01,.44-.17,.45-.41l.07-1.78s.03-.09,.07-.11l4.03-2.17c.09-.05,.2,.02,.19,.12l-.02,.76c0,.24,.18,.44,.42,.45l.65,.02c.24,0,.44-.18,.44-.42l.04-1.25,2.8,5.24-1.06-.66c-.2-.13-.47-.06-.6,.14l-.35,.56c-.13,.2-.06,.47,.14,.6l.64,.4c.09,.05,.08,.18,0,.23l-4.06,2.18s-.09,.02-.13,0l-1.51-.95c-.2-.13-.47-.07-.6,.13l-.35,.55c-.13,.2-.07,.47,.13,.6l.64,.41c.09,.05,.08,.18,0,.23l-4.09,2.12s-.09,.02-.13,0l-1.51-.96c-.2-.13-.47-.07-.6,.13l-.35,.55c-.13,.2-.07,.47,.13,.6l.64,.41c.09,.05,.08,.18-.01,.23l-4.09,2.12s-.09,.02-.13,0l-1.5-.96c-.2-.13-.47-.07-.6,.13l-.36,.55c-.13,.2-.07,.47,.13,.6l.63,.41c.08,.05,.08,.18-.01,.23-1.36,.71-2.73,1.4-4.1,2.09-.04,.02-.09,.02-.13,0l-.45-.3-2.69-5.3,.03-.54Z" />
				<g>
					<path d="M859.29,1597.01l4.18-2.17c.07-.04,.16,.02,.15,.11-.53,3.43-.2,6.21,.08,7.67l-4.69,2.43c-.26-1.61-.49-4.52,.23-7.97,0-.03,.03-.06,.05-.07Z" />
					<path d="M871.14,1590.83l4.15-2.23c.07-.04,.16,.02,.15,.1-.49,3.44-.13,6.22,.17,7.68-1.55,.84-3.11,1.68-4.67,2.48-.27-1.61-.52-4.49,.15-7.96,0-.03,.03-.06,.05-.07Z" />
					<path d="M853.36,1600.07c1.41-.7,2.79-1.44,4.18-2.15,.07-.04,.16,.02,.15,.11-.54,3.44-.22,6.22,.05,7.69l-2.88,1.45-2-3.94c.07-.96,.21-1.99,.45-3.08,0-.03,.03-.06,.05-.07Z" />
					<path d="M878.68,1594.72l-1.81,.97c-.19-1.1-.38-2.8-.27-4.87l2.09,3.89Z" />
					<path d="M865.22,1593.93l4.18-2.17c.07-.04,.16,.02,.15,.11-.52,3.42-.17,6.2,.11,7.65l-4.69,2.43c-.26-1.61-.51-4.51,.2-7.95,0-.03,.03-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M858.82,1585.92l-4.14,2.15s-.06,.02-.09,0c-3.21-1.41-5.4-3.3-6.56-4.45l4.6-2.39c1.02,1.07,3.08,2.96,6.18,4.5,.07,.04,.08,.14,0,.18Z" />
					<path d="M870.54,1579.73l-4.11,2.21s-.06,.02-.09,0c-3.21-1.36-5.44-3.25-6.6-4.39,1.54-.79,3.05-1.61,4.57-2.42,1.03,1.06,3.11,2.92,6.22,4.42,.07,.04,.08,.14,0,.18Z" />
					<path d="M852.94,1588.97c-1.38,.71-2.76,1.45-4.15,2.14-.03,.01-.06,.01-.09,0-1.02-.46-1.93-.96-2.74-1.48l-2-3.94,2.83-1.42c1.02,1.08,3.06,2.97,6.15,4.52,.07,.04,.08,.14,0,.18Z" />
					<path d="M865.55,1574.46l1.77-.95,2.09,3.89c-1.77-1.06-3.06-2.17-3.86-2.94Z" />
					<path d="M864.7,1582.86l-4.14,2.15s-.06,.02-.09,0c-3.22-1.4-5.43-3.28-6.59-4.43l4.6-2.39c1.03,1.07,3.1,2.95,6.22,4.49,.07,.04,.08,.14,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M824.29,1604.99s.03-.09,.07-.11l4.12-2c.09-.04,.19,.03,.19,.13l-.05,.75c-.02,.24,.16,.45,.4,.46l.65,.05c.24,.02,.45-.16,.46-.4l.12-1.78s.03-.09,.07-.11l4.12-2c.09-.04,.2,.03,.19,.13l-.05,.75c-.02,.24,.17,.45,.4,.46l.66,.05c.24,.02,.45-.16,.46-.4l.11-1.79s.03-.09,.07-.11l4.09-2.06c.09-.05,.19,.02,.19,.13l-.04,.76c-.01,.24,.17,.44,.41,.46l.65,.04c.24,.01,.44-.17,.46-.41l.1-1.78s.03-.09,.07-.11l4.09-2.06c.09-.05,.2,.02,.19,.12l-.04,.75c-.01,.24,.17,.44,.41,.46l.66,.04c.24,.01,.44-.17,.46-.41l.07-1.24,2.69,5.3-1.05-.68c-.2-.13-.47-.08-.6,.12l-.36,.55c-.13,.2-.08,.47,.13,.6l.63,.41c.08,.06,.08,.18-.01,.23l-4.12,2.07s-.09,.02-.13,0l-1.49-.98c-.2-.13-.47-.08-.6,.12l-.36,.55c-.13,.2-.08,.47,.12,.6l.63,.42c.08,.06,.08,.18-.01,.23l-4.12,2.07s-.09,.02-.13,0l-1.49-.99c-.2-.13-.47-.08-.6,.12l-.37,.54c-.13,.2-.08,.47,.12,.6l.63,.42c.08,.06,.08,.18-.02,.23l-4.15,2.01s-.09,.02-.13,0l-1.48-1c-.2-.13-.47-.08-.6,.12l-.37,.54c-.13,.2-.08,.47,.12,.6l.63,.42c.08,.06,.07,.18-.02,.23l-4.15,2.01s-.09,.02-.13,0l-.45-.3-2.57-5.35,.04-.54Z" />
				<g>
					<path d="M835.42,1609.01l4.23-2.06c.07-.04,.16,.03,.14,.11-.6,3.42-.34,6.19-.09,7.66l-4.75,2.31c-.22-1.62-.39-4.52,.41-7.94,0-.03,.03-.06,.06-.07Z" />
					<path d="M847.39,1603.07l4.21-2.11c.07-.04,.16,.03,.15,.11-.57,3.42-.27,6.2,0,7.66l-4.72,2.37c-.24-1.62-.44-4.52,.32-7.96,0-.03,.03-.06,.05-.07Z" />
					<path d="M829.4,1611.93l4.24-2.05c.07-.04,.16,.03,.14,.11-.62,3.41-.37,6.2-.13,7.67l-2.91,1.41-1.91-3.98c.09-.96,.25-1.99,.51-3.08,0-.03,.03-.06,.06-.07Z" />
					<path d="M854.85,1607.16l-1.83,.92c-.17-1.1-.32-2.8-.16-4.86l2,3.94Z" />
					<path d="M841.42,1606.07l4.21-2.11c.07-.04,.16,.03,.14,.11-.58,3.43-.29,6.22-.04,7.68-1.58,.78-3.14,1.59-4.73,2.35-.23-1.61-.41-4.51,.36-7.96,0-.03,.03-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M835.19,1597.94l-4.2,2.04s-.06,.01-.09,0c-3.18-1.49-5.34-3.43-6.46-4.61l4.66-2.26c1,1.09,3.02,3.04,6.09,4.65,.07,.04,.07,.14,0,.18Z" />
					<path d="M847.04,1591.99l-4.17,2.1s-.06,.01-.09,0c-3.19-1.45-5.37-3.36-6.51-4.53l4.63-2.32c1.01,1.08,3.05,2.99,6.14,4.58,.07,.04,.08,.14,0,.18Z" />
					<path d="M829.24,1600.83l-4.2,2.04s-.06,.01-.09,0c-1-.48-1.91-1-2.71-1.52l-1.91-3.98,2.85-1.38c.99,1.1,3,3.04,6.06,4.67,.07,.04,.07,.14,0,.18Z" />
					<path d="M842.16,1586.6l1.8-.9,2,3.94c-1.75-1.1-3.02-2.24-3.8-3.03Z" />
					<path d="M841.12,1594.96l-4.17,2.1s-.06,.01-.09,0c-3.18-1.45-5.35-3.39-6.49-4.56,1.56-.74,3.09-1.54,4.64-2.31,1,1.09,3.03,3,6.11,4.59,.07,.04,.07,.14,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M800.24,1616.2s.03-.09,.08-.11l4.18-1.87c.09-.04,.19,.03,.19,.13l-.07,.75c-.02,.24,.15,.45,.39,.47l.65,.07c.24,.02,.45-.15,.47-.39l.16-1.78s.03-.09,.08-.11l4.15-1.94c.09-.04,.19,.03,.19,.13l-.07,.75c-.02,.24,.15,.45,.39,.47l.65,.06c.24,.02,.45-.16,.47-.39l.15-1.78s.03-.09,.07-.11l4.15-1.94c.09-.04,.19,.03,.19,.13l-.06,.75c-.02,.24,.16,.45,.4,.47l.65,.06c.24,.02,.45-.16,.47-.39l.15-1.78s.03-.09,.07-.11c1.39-.64,2.76-1.3,4.13-1.97,.09-.04,.19,.03,.19,.13l-.06,.76c-.02,.24,.16,.45,.4,.47l.65,.05c.24,.02,.45-.16,.46-.4l.09-1.25,2.57,5.35-1.03-.7c-.2-.13-.47-.08-.6,.12l-.37,.54c-.14,.2-.08,.47,.11,.6l.62,.43c.08,.06,.07,.18-.02,.23-1.38,.67-2.76,1.34-4.16,1.98-.04,.02-.09,.02-.13-.01l-1.47-1.02c-.2-.14-.47-.09-.6,.11l-.38,.54c-.14,.2-.09,.47,.11,.6l.62,.43c.08,.06,.07,.18-.02,.23l-4.18,1.95s-.09,.02-.13-.01l-1.46-1.03c-.2-.14-.47-.09-.6,.11l-.38,.54c-.14,.2-.09,.47,.1,.6l.62,.43c.08,.06,.07,.18-.02,.23l-4.18,1.95s-.09,.02-.13-.01l-1.46-1.03c-.19-.14-.46-.09-.6,.11l-.38,.53c-.14,.19-.09,.46,.1,.6l.62,.44c.08,.06,.07,.18-.02,.23l-4.21,1.88s-.09,.01-.13-.01l-.44-.32-2.44-5.41,.05-.54Z" />
				<g>
					<path d="M811.27,1620.48l4.27-1.99c.08-.04,.16,.03,.14,.11-.68,3.41-.47,6.2-.26,7.67-1.6,.74-3.19,1.5-4.8,2.22-.18-1.61-.28-4.53,.59-7.95,0-.03,.03-.06,.06-.07Z" />
					<path d="M823.39,1614.83l4.24-2.05c.07-.04,.16,.03,.14,.11-.64,3.42-.39,6.22-.16,7.69l-4.79,2.25c-.2-1.62-.33-4.52,.51-7.93,0-.03,.03-.06,.06-.07Z" />
					<path d="M805.19,1623.26c1.43-.65,2.87-1.27,4.29-1.94,.08-.04,.16,.03,.14,.11-.69,3.42-.52,6.18-.31,7.66l-2.95,1.32-1.82-4.03c.12-.95,.3-1.98,.59-3.06,0-.03,.03-.05,.06-.07Z" />
					<path d="M830.75,1619.06l-1.85,.9c-.14-1.11-.26-2.82-.06-4.88l1.91,3.98Z" />
					<path d="M817.33,1617.66l4.27-1.99c.08-.04,.16,.03,.14,.11-.67,3.4-.44,6.19-.22,7.66l-4.79,2.24c-.19-1.62-.31-4.53,.55-7.94,0-.03,.03-.06,.06-.07Z" />
				</g>
				<g>
					<path d="M811.29,1609.38l-4.23,1.97s-.06,.01-.09,0c-3.13-1.55-5.24-3.53-6.34-4.74,1.57-.7,3.13-1.45,4.69-2.18,.97,1.12,2.94,3.09,5.97,4.77,.07,.04,.07,.14,0,.18Z" />
					<path d="M823.27,1603.72l-4.2,2.03s-.06,.01-.09,0c-3.16-1.53-5.3-3.5-6.41-4.7l4.68-2.2c.98,1.1,2.98,3.05,6.02,4.69,.07,.04,.07,.14,0,.18Z" />
					<path d="M805.29,1612.18c-1.41,.67-2.83,1.28-4.25,1.92-.03,.01-.06,.01-.09,0-1-.5-1.88-1.05-2.67-1.6l-1.82-4.03,2.88-1.29c.96,1.12,2.94,3.14,5.95,4.82,.07,.04,.07,.15,0,.18Z" />
					<path d="M818.52,1598.24l1.81-.88,1.91,3.98c-1.72-1.13-2.96-2.3-3.72-3.1Z" />
					<path d="M817.3,1606.58l-4.23,1.97s-.06,.01-.09,0c-3.15-1.54-5.27-3.52-6.38-4.71l4.69-2.19c.98,1.11,2.97,3.08,6.01,4.75,.07,.04,.07,.14,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M775.93,1626.81s.04-.09,.08-.1l4.21-1.8c.09-.04,.19,.04,.18,.14l-.09,.75c-.03,.24,.14,.45,.38,.48l.65,.08c.24,.03,.45-.14,.48-.38l.21-1.77s.04-.09,.08-.1l4.21-1.8c.09-.04,.19,.04,.18,.14l-.09,.75c-.03,.24,.14,.45,.38,.48l.65,.08c.24,.03,.45-.14,.48-.38l.2-1.77s.03-.09,.08-.1c1.41-.59,2.79-1.23,4.19-1.85,.09-.04,.19,.03,.18,.13l-.08,.75c-.03,.24,.15,.45,.38,.48l.65,.07c.24,.03,.45-.15,.47-.39l.19-1.78s.03-.09,.08-.11l4.18-1.87c.09-.04,.19,.03,.18,.13l-.08,.75c-.02,.24,.15,.45,.39,.48l.65,.07c.24,.02,.45-.15,.47-.39l.12-1.24,2.44,5.41-1.01-.73c-.19-.14-.47-.1-.61,.1l-.39,.53c-.14,.19-.1,.46,.1,.61l.61,.44c.08,.06,.07,.18-.02,.23l-4.21,1.88s-.09,.01-.13-.01l-1.44-1.05c-.19-.14-.46-.1-.6,.1l-.39,.53c-.14,.19-.1,.47,.09,.61l.61,.44c.08,.06,.07,.19-.02,.23-1.4,.62-2.8,1.27-4.22,1.86-.04,.02-.09,.01-.13-.02l-1.44-1.07c-.19-.14-.46-.1-.61,.09l-.39,.53c-.14,.19-.1,.46,.09,.61l.61,.45c.08,.06,.07,.19-.03,.23l-4.24,1.81s-.09,.01-.13-.02l-1.43-1.07c-.19-.14-.46-.1-.61,.09l-.39,.53c-.14,.19-.11,.46,.09,.61l.6,.45c.08,.06,.07,.19-.03,.23l-4.24,1.81s-.09,.01-.13-.02l-.43-.32-2.3-5.48,.07-.54Z" />
				<g>
					<path d="M786.84,1631.35l4.33-1.85c.08-.03,.16,.04,.14,.12-.77,3.38-.64,6.17-.46,7.64l-4.87,2.08c-.14-1.63-.16-4.54,.8-7.92,0-.03,.03-.05,.06-.07Z" />
					<path d="M799.09,1625.99l4.3-1.92c.08-.03,.16,.03,.14,.11-.73,3.39-.55,6.19-.36,7.66l-4.83,2.16c-.16-1.63-.23-4.54,.69-7.94,0-.03,.03-.05,.06-.07Z" />
					<path d="M780.69,1633.99l4.33-1.85c.08-.03,.16,.04,.14,.12-.79,3.38-.67,6.19-.5,7.66l-2.97,1.27-1.71-4.07c.14-.96,.35-1.98,.66-3.06,0-.03,.03-.05,.06-.07Z" />
					<path d="M806.36,1630.41l-1.88,.84c-.12-1.11-.19-2.82,.06-4.87l1.82,4.03Z" />
					<path d="M792.99,1628.72l4.3-1.92c.08-.03,.16,.03,.14,.11-.74,3.4-.59,6.2-.4,7.68-1.6,.73-3.24,1.4-4.86,2.1-.15-1.62-.19-4.53,.76-7.9,0-.03,.03-.05,.06-.07Z" />
				</g>
				<g>
					<path d="M787.15,1620.28l-4.29,1.84s-.06,.01-.09,0c-3.1-1.64-5.16-3.68-6.22-4.92l4.75-2.03c.94,1.14,2.87,3.17,5.86,4.94,.07,.04,.07,.15,0,.18Z" />
					<path d="M799.25,1614.9l-4.26,1.91s-.06,.01-.09,0c-3.12-1.59-5.2-3.61-6.28-4.82l4.72-2.11c.96,1.13,2.91,3.13,5.92,4.85,.07,.04,.07,.15,0,.18Z" />
					<path d="M781.07,1622.88l-4.29,1.84s-.06,.01-.09,0c-.98-.52-1.85-1.09-2.62-1.65l-1.71-4.07,2.9-1.24c.94,1.15,2.84,3.18,5.81,4.96,.07,.04,.07,.15,0,.18Z" />
					<path d="M794.63,1609.29l1.83-.82,1.82,4.03c-1.69-1.18-2.91-2.38-3.65-3.21Z" />
					<path d="M793.21,1617.6l-4.26,1.91s-.06,.01-.09,0c-3.12-1.63-5.19-3.67-6.27-4.9,1.58-.69,3.17-1.34,4.74-2.05,.95,1.13,2.88,3.14,5.88,4.87,.07,.04,.07,.15,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M751.34,1636.73s.04-.09,.08-.1l4.27-1.65c.09-.04,.19,.04,.18,.14l-.11,.75c-.04,.24,.13,.46,.36,.49l.65,.1c.24,.04,.46-.12,.49-.36l.26-1.76s.04-.08,.08-.1c1.42-.55,2.83-1.14,4.24-1.72,.09-.04,.19,.04,.18,.14l-.1,.75c-.03,.24,.13,.46,.37,.49l.65,.09c.24,.03,.45-.13,.49-.37l.24-1.77s.04-.09,.08-.1l4.24-1.73c.09-.04,.19,.04,.18,.14l-.1,.75c-.03,.24,.13,.46,.37,.49l.65,.09c.24,.03,.45-.13,.49-.37l.23-1.77s.04-.09,.08-.1l4.24-1.73c.09-.04,.19,.04,.18,.14l-.09,.75c-.03,.24,.14,.45,.37,.49l.65,.08c.24,.03,.45-.14,.48-.38l.15-1.24,2.3,5.48-.99-.75c-.19-.14-.46-.11-.61,.09l-.4,.53c-.15,.19-.11,.46,.08,.61l.6,.46c.08,.06,.06,.19-.03,.23l-4.27,1.74s-.09,.01-.13-.02l-1.42-1.09c-.19-.15-.46-.11-.61,.08l-.4,.52c-.15,.19-.11,.46,.08,.61l.6,.46c.08,.06,.06,.19-.03,.23l-4.27,1.74s-.09,.01-.13-.02l-1.41-1.1c-.19-.15-.46-.11-.61,.08l-.4,.52c-.15,.19-.11,.46,.07,.61l.6,.46c.08,.06,.06,.19-.03,.23-1.42,.57-2.84,1.17-4.27,1.73-.04,.02-.09,0-.13-.02l-1.4-1.12c-.19-.15-.46-.12-.61,.07l-.41,.51c-.15,.19-.12,.46,.07,.61l.59,.47c.08,.06,.06,.19-.03,.22l-4.3,1.66s-.09,0-.13-.02l-.42-.34-2.15-5.54,.08-.53Z" />
				<g>
					<path d="M762.14,1641.62l4.36-1.78c.08-.03,.16,.04,.14,.12-.86,3.37-.79,6.18-.66,7.66-1.63,.68-3.28,1.31-4.93,1.95-.09-1.63-.03-4.52,1.03-7.88,0-.03,.03-.05,.06-.07Z" />
					<path d="M774.52,1636.57c1.45-.59,2.91-1.18,4.35-1.81,.08-.03,.16,.04,.14,.12-.8,3.4-.71,6.18-.56,7.65l-4.9,2c-.12-1.63-.1-4.54,.91-7.9,0-.03,.03-.05,.06-.07Z" />
					<path d="M755.9,1644.05l4.39-1.7c.08-.03,.16,.04,.13,.12-.89,3.35-.85,6.14-.73,7.62l-3.02,1.17-1.6-4.12c.17-.95,.41-1.97,.75-3.03,0-.03,.03-.05,.06-.06Z" />
					<path d="M781.68,1641.18l-1.89,.81c-.08-1.11-.12-2.83,.18-4.88l1.71,4.07Z" />
					<path d="M768.33,1639.1l4.36-1.78c.08-.03,.16,.04,.14,.12-.84,3.37-.76,6.17-.61,7.64l-4.9,2c-.11-1.63-.07-4.55,.96-7.92,0-.03,.03-.05,.06-.07Z" />
				</g>
				<g>
					<path d="M762.73,1630.52l-4.32,1.76s-.06,0-.09,0c-3.05-1.72-5.06-3.84-6.09-5.1,1.6-.62,3.21-1.23,4.8-1.9,.91,1.16,2.77,3.24,5.71,5.06,.07,.04,.06,.15-.01,.18Z" />
					<path d="M774.98,1625.49c-1.43,.62-2.87,1.2-4.31,1.79-.03,.01-.06,0-.09,0-3.08-1.69-5.11-3.76-6.16-5.01l4.78-1.95c.93,1.15,2.83,3.23,5.78,5,.07,.04,.06,.15-.01,.18Z" />
					<path d="M756.59,1633l-4.35,1.68s-.06,0-.09,0c-.97-.55-1.82-1.15-2.58-1.74l-1.6-4.12,2.94-1.14c.9,1.18,2.76,3.27,5.68,5.14,.07,.04,.06,.15-.02,.18Z" />
					<path d="M770.51,1619.78l1.85-.79,1.71,4.07c-1.66-1.22-2.84-2.43-3.56-3.28Z" />
					<path d="M768.86,1628.02l-4.32,1.76s-.06,0-.09,0c-3.06-1.7-5.08-3.78-6.12-5.03l4.78-1.95c.92,1.16,2.8,3.23,5.75,5.05,.07,.04,.06,.15-.01,.18Z" />
				</g>
			</g>
			<g>
				<path d="M726.5,1646s.04-.08,.08-.1l4.3-1.57c.09-.03,.19,.05,.17,.15l-.13,.75c-.04,.24,.11,.46,.35,.5l.64,.11c.23,.04,.46-.12,.5-.35l.31-1.76s.04-.08,.08-.1l4.3-1.57c.09-.03,.19,.05,.17,.15l-.13,.74c-.04,.24,.12,.46,.35,.5l.65,.11c.24,.04,.46-.12,.5-.35l.3-1.76s.04-.08,.08-.1c1.43-.53,2.87-1.03,4.29-1.58,.09-.04,.19,.04,.17,.14l-.12,.75c-.04,.24,.12,.46,.36,.5l.65,.1c.23,.04,.46-.12,.49-.36l.28-1.77s.04-.09,.08-.1l4.27-1.65c.09-.04,.19,.04,.18,.14l-.12,.75c-.04,.24,.12,.46,.36,.5l.65,.1c.24,.04,.46-.12,.49-.36l.19-1.23,2.15,5.54-.97-.78c-.19-.15-.46-.12-.61,.07l-.41,.51c-.15,.19-.12,.46,.06,.61l.59,.47c.08,.06,.06,.19-.04,.22l-4.3,1.66s-.09,0-.13-.02l-1.38-1.12c-.19-.15-.46-.12-.61,.07l-.42,.51c-.15,.19-.12,.46,.06,.61l.58,.48c.08,.06,.06,.19-.04,.23-1.43,.55-2.88,1.06-4.33,1.6-.04,.02-.09,0-.13-.02l-1.38-1.14c-.18-.15-.46-.13-.61,.05l-.42,.5c-.15,.18-.13,.46,.06,.61l.58,.48c.08,.06,.06,.19-.04,.22l-4.33,1.58s-.09,0-.13-.02l-1.37-1.15c-.18-.15-.46-.13-.61,.05l-.42,.5c-.15,.18-.13,.46,.05,.61l.58,.49c.08,.06,.05,.19-.04,.22l-4.33,1.58s-.09,0-.13-.02l-.41-.35-1.98-5.6,.1-.53Z" />
				<g>
					<path d="M737.12,1651.14l4.43-1.62c.08-.03,.15,.04,.13,.12-.96,3.33-.98,6.13-.89,7.61l-4.98,1.82c-.05-1.63,.1-4.55,1.25-7.88,.01-.03,.03-.05,.06-.06Z" />
					<path d="M749.66,1646.46l4.4-1.7c.08-.03,.15,.04,.13,.12-.91,3.35-.89,6.16-.77,7.64l-4.94,1.91c-.07-1.64,.02-4.56,1.12-7.91,0-.03,.03-.05,.06-.06Z" />
					<path d="M730.84,1653.43l4.43-1.62c.08-.03,.15,.04,.13,.12-.98,3.34-1.02,6.15-.94,7.63-1.02,.37-2.02,.75-3.05,1.1l-1.47-4.16c.2-.93,.47-1.95,.84-3.01,.01-.03,.03-.05,.06-.06Z" />
					<path d="M756.69,1651.26l-1.92,.74c-.06-1.11-.04-2.83,.32-4.86l1.6,4.12Z" />
					<path d="M743.41,1648.84c1.48-.53,2.95-1.08,4.41-1.66,.08-.03,.15,.04,.13,.12-.92,3.37-.93,6.17-.83,7.64l-4.98,1.82c-.06-1.63,.07-4.54,1.2-7.86,0-.03,.03-.05,.06-.06Z" />
				</g>
				<g>
					<path d="M738.05,1640.09l-4.38,1.6s-.06,0-.09,0c-3-1.81-4.94-3.96-5.93-5.25l4.85-1.77c.88,1.19,2.68,3.33,5.57,5.25,.07,.05,.06,.15-.02,.18Z" />
					<path d="M750.42,1635.38l-4.35,1.68s-.06,0-.09,0c-3.02-1.75-4.99-3.88-6-5.15l4.81-1.86c.89,1.18,2.73,3.28,5.64,5.16,.07,.04,.06,.15-.02,.18Z" />
					<path d="M731.84,1642.36l-4.38,1.6s-.06,0-.09,0c-.95-.58-1.78-1.18-2.52-1.8l-1.47-4.16c.99-.34,1.98-.72,2.96-1.07,.87,1.2,2.65,3.34,5.52,5.27,.07,.05,.06,.15-.02,.18Z" />
					<path d="M746.11,1629.54l1.87-.72,1.6,4.12c-1.62-1.27-2.77-2.53-3.47-3.39Z" />
					<path d="M744.25,1637.77c-1.45,.57-2.9,1.12-4.36,1.64-.03,.01-.06,0-.09,0-3.02-1.8-4.97-3.95-5.98-5.23l4.85-1.77c.89,1.2,2.71,3.32,5.6,5.19,.07,.04,.06,.15-.02,.18Z" />
				</g>
			</g>
			<g>
				<path d="M701.37,1654.42s.04-.08,.09-.1l4.35-1.4c.1-.03,.19,.05,.17,.15l-.16,.74c-.05,.23,.1,.46,.33,.51l.64,.14c.23,.05,.46-.1,.51-.33l.37-1.74s.04-.08,.09-.1c1.45-.47,2.91-.92,4.35-1.42,.1-.03,.19,.05,.17,.15l-.15,.74c-.05,.24,.1,.46,.34,.51l.64,.13c.23,.05,.46-.11,.51-.34l.35-1.76s.04-.08,.09-.1l4.33-1.49c.1-.03,.19,.05,.17,.15l-.14,.74c-.05,.23,.11,.46,.34,.51l.64,.13c.23,.05,.46-.11,.51-.34l.34-1.75s.04-.08,.09-.1l4.33-1.49c.09-.03,.19,.05,.17,.15l-.14,.74c-.04,.23,.11,.46,.35,.51l.65,.13c.24,.05,.46-.11,.51-.34l.23-1.22,1.98,5.6-.95-.82c-.18-.16-.46-.14-.61,.04l-.43,.5c-.16,.18-.14,.45,.05,.61l.58,.49c.08,.07,.05,.19-.04,.22l-4.36,1.5s-.09,0-.13-.02l-1.35-1.17c-.18-.16-.46-.14-.61,.04l-.43,.5c-.16,.18-.14,.45,.04,.61l.57,.5c.08,.07,.05,.19-.04,.22l-4.36,1.5s-.09,0-.13-.03l-1.34-1.18c-.18-.16-.45-.14-.61,.04l-.43,.5c-.16,.18-.14,.46,.04,.61l.57,.5c.08,.07,.05,.19-.04,.22-1.45,.51-2.92,.96-4.38,1.44-.04,.01-.09,0-.13-.03l-1.33-1.2c-.18-.16-.45-.15-.61,.03l-.44,.49c-.16,.18-.15,.45,.03,.61l.56,.51c.08,.07,.05,.19-.05,.22l-4.39,1.41s-.09,0-.13-.03l-.4-.36-1.8-5.66,.11-.53Z" />
				<g>
					<path d="M711.85,1659.95c1.5-.47,2.98-.99,4.47-1.5,.08-.03,.15,.05,.13,.13-1.06,3.32-1.16,6.14-1.12,7.61l-5.05,1.62c0-1.63,.24-4.53,1.51-7.8,.01-.03,.03-.05,.06-.06Z" />
					<path d="M724.52,1655.63l4.46-1.53c.08-.03,.15,.05,.13,.13-1.01,3.31-1.08,6.11-1.01,7.59l-5.02,1.72c-.02-1.63,.17-4.54,1.38-7.84,.01-.03,.03-.05,.06-.06Z" />
					<path d="M705.47,1662l4.49-1.44c.08-.03,.15,.05,.13,.13-1.09,3.29-1.22,6.09-1.19,7.57l-3.09,.99-1.34-4.21c.23-.94,.53-1.94,.94-2.98,.01-.03,.04-.05,.06-.06Z" />
					<path d="M731.41,1660.67l-1.95,.67c-.02-1.11,.05-2.82,.48-4.83l1.47,4.16Z" />
					<path d="M718.19,1657.81l4.46-1.53c.08-.03,.15,.05,.13,.13-1.03,3.32-1.12,6.12-1.06,7.6l-5.02,1.72c0-1.64,.2-4.55,1.43-7.86,.01-.03,.03-.05,.06-.06Z" />
				</g>
				<g>
					<path d="M713.11,1648.92c-1.47,.5-2.93,1.02-4.42,1.48-.03,0-.06,0-.09-.01-2.95-1.92-4.81-4.14-5.77-5.47l4.91-1.57c.84,1.23,2.57,3.4,5.39,5.39,.07,.05,.05,.15-.02,.18Z" />
					<path d="M725.62,1644.62l-4.41,1.51s-.06,0-.09-.01c-2.97-1.86-4.88-4.05-5.85-5.36l4.88-1.68c.86,1.21,2.63,3.37,5.49,5.35,.07,.05,.06,.15-.02,.18Z" />
					<path d="M706.83,1651l-4.44,1.42s-.06,0-.09-.01c-.93-.61-1.75-1.25-2.46-1.89l-1.34-4.21,3-.96c.83,1.23,2.55,3.43,5.35,5.47,.07,.05,.05,.15-.03,.18Z" />
					<path d="M721.48,1638.63l1.9-.65,1.47,4.16c-1.59-1.33-2.7-2.62-3.37-3.51Z" />
					<path d="M719.37,1646.77l-4.41,1.51s-.06,0-.09-.01c-2.95-1.87-4.84-4.07-5.8-5.38l4.88-1.68c.85,1.21,2.6,3.38,5.44,5.37,.07,.05,.06,.15-.02,.18Z" />
				</g>
			</g>
			<g>
				<path d="M676,1662.07s.05-.08,.09-.09c1.47-.4,2.94-.8,4.4-1.25,.1-.03,.19,.06,.16,.16l-.18,.74c-.06,.23,.08,.47,.32,.52l.64,.15c.23,.06,.46-.09,.52-.32l.42-1.74s.04-.08,.09-.1l4.38-1.3c.1-.03,.19,.06,.16,.16l-.17,.74c-.05,.23,.09,.47,.32,.52l.64,.15c.23,.06,.47-.09,.52-.32l.41-1.74s.04-.08,.09-.1l4.38-1.3c.1-.03,.19,.06,.17,.16l-.17,.73c-.05,.23,.09,.47,.33,.52l.64,.15c.23,.06,.47-.09,.52-.32l.39-1.74s.04-.08,.09-.1l4.35-1.4c.1-.03,.19,.05,.17,.15l-.16,.74c-.05,.23,.1,.47,.33,.52l.64,.14c.23,.05,.46-.1,.51-.33l.26-1.22,1.8,5.66-.92-.84c-.18-.16-.45-.15-.61,.03l-.44,.49c-.16,.18-.15,.45,.03,.61l.56,.51c.07,.07,.05,.19-.05,.22l-4.39,1.41s-.09,0-.13-.03l-1.31-1.22c-.18-.16-.45-.16-.62,.02l-.45,.48c-.16,.17-.15,.45,.02,.61l.56,.52c.07,.07,.05,.19-.05,.22l-4.42,1.32s-.09,0-.13-.03l-1.3-1.23c-.17-.16-.45-.16-.61,.02l-.45,.48c-.16,.17-.16,.45,.02,.61l.55,.52c.07,.07,.04,.19-.05,.22l-4.42,1.32s-.09,0-.13-.03l-1.29-1.23c-.17-.16-.45-.16-.61,.02l-.46,.48c-.17,.17-.16,.45,.01,.61l.54,.52c.07,.07,.04,.19-.05,.22-1.47,.45-2.95,.86-4.44,1.26-.04,.01-.09,0-.13-.03l-.39-.38-1.61-5.72,.13-.52Z" />
				<g>
					<path d="M686.27,1667.9l4.52-1.35c.08-.02,.15,.05,.12,.13-1.17,3.27-1.37,6.07-1.38,7.55l-5.09,1.52c.06-1.64,.39-4.54,1.76-7.79,.01-.03,.04-.05,.07-.06Z" />
					<path d="M699.1,1664.04l4.49-1.44c.08-.03,.15,.05,.13,.13-1.11,3.3-1.27,6.1-1.24,7.59-1.68,.54-3.37,1.09-5.07,1.58,.03-1.64,.33-4.5,1.63-7.8,.01-.03,.04-.05,.06-.06Z" />
					<path d="M679.85,1669.79c1.52-.4,3.02-.88,4.53-1.32,.08-.02,.15,.05,.12,.13-1.19,3.28-1.42,6.08-1.43,7.57l-3.13,.86-1.2-4.25c.26-.92,.6-1.91,1.04-2.93,.01-.03,.04-.05,.07-.06Z" />
					<path d="M705.81,1669.24l-1.97,.63c.01-1.12,.14-2.83,.62-4.84l1.34,4.21Z" />
					<path d="M692.69,1665.99l4.52-1.35c.08-.02,.15,.05,.12,.13-1.15,3.26-1.33,6.05-1.32,7.54l-5.09,1.52c.05-1.63,.36-4.53,1.7-7.78,.01-.03,.04-.05,.07-.06Z" />
				</g>
				<g>
					<path d="M687.9,1656.92l-4.46,1.33s-.06,0-.09-.01c-2.87-1.99-4.66-4.26-5.57-5.61l4.94-1.47c.8,1.25,2.46,3.49,5.21,5.59,.07,.05,.05,.15-.03,.18Z" />
					<path d="M700.54,1653.02l-4.44,1.42s-.06,0-.09-.01c-2.9-1.92-4.74-4.21-5.67-5.54,1.65-.48,3.28-1.01,4.92-1.53,.82,1.23,2.52,3.44,5.3,5.49,.07,.05,.05,.15-.03,.18Z" />
					<path d="M681.57,1658.81c-1.49,.43-2.97,.91-4.47,1.31-.03,0-.06,0-.09-.02-.91-.65-1.71-1.32-2.4-1.99l-1.2-4.25,3.03-.83c.79,1.25,2.43,3.5,5.16,5.61,.06,.05,.05,.15-.03,.18Z" />
					<path d="M696.6,1646.92l1.91-.61,1.34,4.21c-1.54-1.36-2.61-2.69-3.25-3.6Z" />
					<path d="M694.24,1655.04l-4.46,1.33s-.06,0-.09-.01c-2.9-1.98-4.71-4.25-5.62-5.6l4.94-1.47c.81,1.24,2.49,3.48,5.27,5.57,.07,.05,.05,.15-.03,.18Z" />
				</g>
			</g>
			<g>
				<path d="M650.38,1668.79s.05-.08,.09-.09l4.44-1.11c.1-.02,.18,.07,.16,.16l-.21,.73c-.07,.23,.07,.47,.3,.54l.63,.18c.23,.07,.47-.07,.53-.3l.48-1.72s.05-.08,.09-.09l4.44-1.11c.1-.02,.19,.07,.16,.16l-.2,.72c-.06,.23,.07,.47,.3,.53l.63,.18c.23,.07,.47-.07,.53-.3l.47-1.73s.05-.08,.09-.09l4.41-1.21c.1-.03,.19,.06,.16,.16l-.19,.73c-.06,.23,.08,.47,.31,.53l.63,.17c.23,.06,.47-.08,.53-.31l.45-1.73s.05-.08,.09-.09l4.41-1.21c.1-.03,.19,.06,.16,.16l-.19,.73c-.06,.23,.08,.47,.31,.53l.64,.17c.23,.06,.47-.08,.53-.31l.31-1.2,1.61,5.72-.9-.88c-.17-.17-.45-.17-.61,0l-.46,.47c-.17,.17-.17,.44,0,.61l.54,.53c.07,.07,.04,.19-.06,.22l-4.45,1.22s-.09,0-.13-.03l-1.27-1.26c-.17-.17-.44-.17-.61,0l-.46,.47c-.17,.17-.17,.45,0,.61l.54,.53c.07,.07,.04,.19-.06,.22l-4.45,1.22s-.09,0-.13-.03l-1.26-1.26c-.17-.17-.44-.18-.62,0l-.47,.46c-.17,.17-.17,.44,0,.61l.53,.54c.07,.07,.04,.19-.06,.22l-4.48,1.12s-.09,0-.13-.04l-1.25-1.28c-.17-.17-.44-.18-.61-.01l-.47,.46c-.17,.17-.18,.44-.01,.61l.53,.54c.07,.07,.04,.19-.06,.22l-4.48,1.12s-.09,0-.13-.04l-.37-.39-1.4-5.77,.15-.52Z" />
				<g>
					<path d="M660.44,1675.01l4.58-1.15c.08-.02,.15,.06,.12,.13-1.28,3.23-1.59,5.99-1.65,7.48l-5.16,1.29c.12-1.63,.56-4.51,2.04-7.69,.01-.03,.04-.05,.07-.06Z" />
					<path d="M673.39,1671.56l4.55-1.25c.08-.02,.15,.06,.12,.13-1.23,3.24-1.48,6.03-1.51,7.51l-5.12,1.4c.09-1.63,.47-4.53,1.9-7.74,.01-.03,.04-.05,.07-.06Z" />
					<path d="M653.94,1676.63l4.58-1.14c.08-.02,.15,.06,.12,.14-1.31,3.21-1.64,6-1.71,7.48l-3.15,.79-1.04-4.29c.29-.92,.66-1.9,1.14-2.91,.01-.03,.04-.05,.07-.06Z" />
					<path d="M679.93,1677.03l-1.99,.55c.05-1.11,.24-2.81,.8-4.8l1.2,4.25Z" />
					<path d="M666.93,1673.33l4.55-1.25c.08-.02,.15,.06,.12,.13-1.25,3.25-1.53,6.04-1.57,7.53-1.71,.46-3.41,.96-5.13,1.37,.11-1.62,.52-4.49,1.97-7.73,.01-.03,.04-.05,.07-.06Z" />
				</g>
				<g>
					<path d="M662.46,1664.12l-4.51,1.14s-.06,0-.09-.02c-2.81-2.11-4.51-4.46-5.37-5.84l4.99-1.24c.75,1.27,2.34,3.6,5.02,5.79,.06,.05,.04,.16-.04,.18Z" />
					<path d="M675.21,1660.63l-4.49,1.23s-.06,0-.09-.02c-2.84-2.05-4.59-4.36-5.47-5.73l4.96-1.36c.78,1.26,2.4,3.54,5.12,5.7,.06,.05,.05,.15-.03,.18Z" />
					<path d="M656.05,1665.73l-4.52,1.13s-.06,0-.09-.02c-.88-.67-1.65-1.37-2.32-2.05l-1.04-4.29,3.05-.76c.74,1.28,2.3,3.59,4.96,5.82,.06,.05,.04,.16-.04,.18Z" />
					<path d="M671.48,1654.39l1.93-.53,1.2,4.25c-1.5-1.42-2.52-2.79-3.13-3.72Z" />
					<path d="M668.84,1662.38l-4.49,1.23s-.06,0-.09-.02c-2.81-2.04-4.55-4.39-5.42-5.77,1.67-.4,3.31-.89,4.97-1.33,.76,1.27,2.37,3.55,5.06,5.71,.06,.05,.04,.15-.03,.18Z" />
				</g>
			</g>
			<g>
				<path d="M624.53,1674.57s.05-.08,.1-.09l4.48-.89c.1-.02,.18,.08,.15,.17l-.23,.71c-.07,.23,.05,.47,.28,.55l.62,.21c.23,.07,.47-.06,.54-.28l.54-1.71s.05-.08,.1-.09l4.46-1c.1-.02,.18,.07,.15,.17l-.23,.72c-.07,.23,.05,.47,.28,.54l.62,.2c.23,.07,.47-.06,.54-.28l.53-1.71s.05-.08,.1-.09l4.46-1c.1-.02,.18,.07,.15,.17l-.22,.72c-.07,.23,.06,.47,.29,.54l.63,.2c.23,.07,.47-.06,.54-.28l.52-1.7s.05-.08,.1-.09c1.49-.32,2.97-.69,4.45-1.06,.1-.02,.18,.07,.16,.16l-.21,.73c-.07,.23,.06,.47,.29,.54l.63,.18c.23,.07,.47-.07,.54-.3l.35-1.2,1.4,5.77-.86-.9c-.17-.17-.44-.18-.61-.01l-.48,.46c-.17,.17-.18,.44-.02,.61l.52,.55c.07,.07,.03,.19-.06,.22-1.49,.38-2.98,.75-4.49,1.07-.05,.01-.09,0-.13-.04l-1.23-1.31c-.16-.18-.44-.19-.61-.02l-.48,.45c-.18,.16-.19,.44-.02,.61l.52,.56c.07,.07,.03,.19-.07,.22l-4.5,1.01s-.09,0-.13-.04l-1.21-1.31c-.16-.18-.44-.19-.61-.02l-.49,.45c-.18,.16-.19,.44-.03,.61l.51,.56c.07,.07,.03,.19-.07,.22l-4.5,1.01s-.09,0-.12-.04l-1.2-1.32c-.16-.18-.43-.19-.61-.03l-.49,.43c-.18,.16-.19,.43-.03,.61l.51,.57c.07,.08,.03,.19-.07,.21l-4.53,.9s-.09,0-.12-.04l-.36-.41-1.19-5.82,.17-.51Z" />
				<g>
					<path d="M634.36,1681.17l4.61-1.03c.08-.02,.15,.06,.11,.14-1.4,3.18-1.81,5.96-1.92,7.45-1.73,.38-3.46,.8-5.2,1.15,.19-1.61,.73-4.49,2.32-7.64,.01-.03,.04-.05,.07-.05Z" />
					<path d="M647.44,1678.24c1.53-.36,3.05-.75,4.58-1.13,.08-.02,.15,.06,.12,.14-1.34,3.22-1.69,6.01-1.77,7.5-1.72,.43-3.46,.79-5.19,1.18,.15-1.62,.64-4.49,2.19-7.63,.01-.03,.04-.05,.07-.05Z" />
					<path d="M627.8,1682.56c1.54-.32,3.09-.59,4.62-.95,.08-.02,.15,.06,.11,.14-1.42,3.2-1.87,5.92-1.99,7.41l-3.19,.64-.88-4.33c.33-.9,.74-1.86,1.26-2.85,.01-.03,.04-.05,.07-.05Z" />
					<path d="M653.77,1683.89l-2.01,.5c.09-1.11,.34-2.81,.96-4.79l1.04,4.29Z" />
					<path d="M640.9,1679.71l4.61-1.03c.08-.02,.15,.06,.12,.14-1.38,3.18-1.76,5.95-1.85,7.43l-5.19,1.17c.17-1.63,.68-4.5,2.25-7.65,.01-.03,.04-.05,.07-.05Z" />
				</g>
				<g>
					<path d="M636.77,1670.32l-4.54,1.02s-.06,0-.09-.02c-2.72-2.18-4.33-4.57-5.14-6.01,1.68-.34,3.35-.74,5.02-1.11,.71,1.3,2.2,3.65,4.79,5.94,.06,.05,.04,.16-.04,.17Z" />
					<path d="M649.64,1667.33c-1.51,.37-3.01,.76-4.52,1.11-.03,0-.06,0-.09-.02-2.77-2.17-4.43-4.56-5.27-5.96,1.67-.38,3.35-.73,5.01-1.14,.73,1.28,2.27,3.6,4.9,5.83,.06,.05,.04,.16-.04,.18Z" />
					<path d="M630.33,1671.77c-1.51,.36-3.04,.62-4.56,.93-.03,0-.06,0-.09-.02-.86-.71-1.6-1.44-2.25-2.15l-.88-4.33,3.08-.61c.69,1.3,2.17,3.72,4.74,6.01,.06,.05,.04,.16-.04,.17Z" />
					<path d="M646.14,1660.99l1.94-.48,1.04,4.29c-1.44-1.46-2.41-2.86-2.98-3.81Z" />
					<path d="M643.22,1668.88l-4.54,1.02s-.06,0-.09-.02c-2.74-2.18-4.38-4.57-5.2-5.97l5.02-1.13c.72,1.3,2.24,3.64,4.85,5.93,.06,.05,.04,.16-.04,.17Z" />
				</g>
			</g>
			<g>
				<path d="M598.51,1679.34s.06-.08,.1-.08l4.51-.72c.1-.02,.18,.08,.14,.18l-.26,.71c-.08,.22,.03,.47,.25,.56l.61,.24c.22,.08,.47-.03,.55-.26l.62-1.68s.05-.08,.1-.08l4.49-.8c.1-.02,.18,.08,.15,.17l-.25,.71c-.08,.22,.04,.47,.26,.56l.62,.22c.22,.08,.47-.03,.55-.26l.6-1.68s.05-.08,.1-.08c1.5-.25,2.99-.57,4.49-.86,.1-.02,.18,.08,.15,.17l-.25,.72c-.08,.23,.04,.47,.27,.55l.62,.21c.23,.08,.47-.04,.55-.27l.58-1.69s.05-.08,.1-.09l4.48-.89c.1-.02,.18,.08,.15,.17l-.24,.72c-.08,.23,.05,.47,.27,.55l.62,.21c.23,.08,.47-.04,.55-.27l.39-1.18,1.19,5.82-.83-.94c-.16-.18-.43-.2-.61-.04l-.49,.43c-.18,.16-.2,.43-.04,.61l.5,.57c.07,.08,.03,.2-.07,.22l-4.53,.9s-.09,0-.12-.04l-1.17-1.35c-.16-.18-.43-.2-.61-.04l-.5,.43c-.18,.16-.2,.43-.04,.61l.49,.57c.07,.08,.02,.2-.07,.22-1.51,.29-3.01,.62-4.54,.87-.05,0-.09,0-.12-.04l-1.16-1.37c-.16-.18-.43-.21-.61-.05l-.5,.42c-.18,.15-.21,.43-.05,.61l.49,.58c.07,.08,.02,.2-.08,.21l-4.55,.81s-.09,0-.12-.04l-1.14-1.37c-.15-.18-.43-.21-.61-.05l-.51,.41c-.19,.15-.21,.42-.06,.61l.48,.59c.06,.08,.02,.2-.08,.21l-4.56,.73s-.09-.01-.12-.05l-.34-.42-.93-5.87,.19-.5Z" />
				<g>
					<path d="M608.04,1686.34l4.66-.82c.08-.01,.15,.07,.11,.14-1.53,3.11-2.06,5.87-2.23,7.35-1.76,.3-3.51,.64-5.27,.92,.25-1.6,.92-4.45,2.66-7.53,.02-.03,.04-.05,.07-.05Z" />
					<path d="M621.23,1683.86l4.63-.92c.08-.02,.15,.07,.11,.14-1.46,3.15-1.93,5.91-2.07,7.39l-5.22,1.04c.21-1.62,.81-4.48,2.47-7.6,.01-.03,.04-.05,.07-.05Z" />
					<path d="M601.42,1687.45c1.56-.26,3.12-.48,4.67-.76,.08-.01,.14,.07,.11,.14-1.56,3.12-2.13,5.84-2.32,7.32l-3.23,.51-.69-4.36c.37-.89,.82-1.84,1.38-2.8,.02-.03,.04-.05,.07-.05Z" />
					<path d="M627.36,1689.79l-2.03,.4c.13-1.11,.45-2.79,1.15-4.73l.88,4.33Z" />
					<path d="M614.65,1685.17l4.63-.92c.08-.02,.15,.07,.11,.14-1.49,3.15-1.98,5.92-2.13,7.4-1.73,.36-3.5,.64-5.25,.96,.23-1.62,.87-4.46,2.57-7.53,.01-.03,.04-.05,.07-.05Z" />
				</g>
				<g>
					<path d="M610.9,1675.63l-4.57,.81s-.06,0-.08-.02c-2.62-2.3-4.13-4.77-4.87-6.23,1.68-.27,3.36-.59,5.04-.88,.65,1.33,2.04,3.75,4.54,6.15,.06,.06,.03,.16-.05,.17Z" />
					<path d="M623.85,1673.09l-4.56,.91s-.06,0-.08-.02c-2.68-2.25-4.25-4.68-5.02-6.1l5.04-1c.68,1.32,2.13,3.7,4.68,6.05,.06,.06,.03,.16-.05,.17Z" />
					<path d="M604.4,1676.77c-1.52,.28-3.06,.5-4.58,.75-.03,0-.06,0-.08-.03-.82-.75-1.54-1.51-2.15-2.24l-.69-4.36,3.09-.49c.63,1.33,2,3.8,4.47,6.2,.06,.06,.03,.16-.05,.17Z" />
					<path d="M620.6,1666.59l1.96-.39,.88,4.33c-1.39-1.53-2.3-2.97-2.84-3.94Z" />
					<path d="M617.38,1674.38l-4.56,.91s-.06,0-.08-.02c-2.65-2.3-4.19-4.76-4.95-6.2,1.68-.31,3.36-.57,5.04-.92,.67,1.32,2.09,3.71,4.61,6.06,.06,.06,.03,.16-.05,.17Z" />
				</g>
			</g>
			<g>
				<path d="M572.29,1682.84s.06-.07,.11-.08c1.51-.16,3.03-.31,4.53-.51,.1-.01,.17,.09,.14,.18l-.3,.7c-.09,.22,0,.48,.23,.57l.6,.26c.22,.09,.47,0,.57-.23l.7-1.65s.06-.07,.1-.08l4.53-.56c.1-.01,.18,.09,.14,.18l-.29,.7c-.09,.22,.01,.47,.23,.57l.6,.26c.22,.09,.47,0,.56-.23l.68-1.66s.06-.07,.1-.08l4.52-.64c.1-.01,.18,.09,.14,.18l-.28,.7c-.09,.22,.02,.47,.24,.56l.61,.25c.22,.09,.47-.02,.56-.24l.66-1.66s.06-.07,.1-.08c1.51-.2,3.01-.43,4.51-.68,.1-.02,.18,.08,.14,.18l-.27,.71c-.09,.22,.02,.47,.25,.56l.61,.24c.22,.09,.47-.03,.56-.25l.44-1.17,.93,5.87-.79-.97c-.15-.19-.42-.21-.61-.06l-.51,.41c-.19,.15-.22,.42-.07,.61l.48,.59c.06,.08,.02,.2-.08,.21-1.52,.25-3.04,.48-4.57,.69-.05,0-.09-.01-.12-.05l-1.11-1.41c-.15-.19-.42-.22-.61-.07l-.52,.4c-.19,.15-.22,.42-.07,.61l.47,.6c.06,.08,.02,.2-.09,.21l-4.58,.65s-.09-.01-.12-.05l-1.09-1.41c-.15-.19-.42-.23-.61-.08l-.52,.4c-.19,.14-.23,.42-.08,.61l.46,.6c.06,.08,.01,.2-.09,.21l-4.59,.56s-.09-.01-.12-.05l-1.08-1.43c-.14-.19-.42-.23-.61-.09l-.53,.4c-.19,.14-.23,.42-.09,.61l.45,.6c.06,.08,.01,.2-.09,.21-1.53,.2-3.06,.35-4.6,.51-.05,0-.09-.02-.12-.05l-.32-.44-.63-5.91,.22-.49Z" />
				<g>
					<path d="M581.48,1690.29l4.69-.58c.08-.01,.14,.08,.1,.15-1.68,3.04-2.34,5.76-2.59,7.22l-5.31,.65c.33-1.6,1.14-4.41,3.02-7.39,.02-.03,.04-.04,.08-.05Z" />
					<path d="M594.79,1688.5l4.67-.73c.08-.01,.14,.07,.1,.15-1.6,3.09-2.19,5.84-2.4,7.31l-5.29,.76c.29-1.61,1.03-4.43,2.84-7.44,.02-.03,.04-.04,.07-.05Z" />
					<path d="M574.81,1691.09c1.57-.15,3.13-.37,4.7-.55,.08-.01,.14,.08,.1,.15-1.72,3.03-2.41,5.76-2.67,7.22l-3.25,.34-.47-4.39c.41-.87,.91-1.79,1.52-2.72,.02-.03,.04-.04,.08-.05Z" />
					<path d="M600.65,1694.67l-2.05,.33c.19-1.1,.57-2.77,1.36-4.69l.69,4.36Z" />
					<path d="M588.14,1689.43l4.68-.66c.08-.01,.14,.08,.1,.15-1.64,3.06-2.27,5.8-2.5,7.26-1.77,.24-3.53,.52-5.3,.72,.31-1.6,1.09-4.4,2.93-7.42,.02-.03,.04-.04,.07-.05Z" />
				</g>
				<g>
					<path d="M584.85,1679.74l-4.61,.57s-.06,0-.08-.03c-2.51-2.44-3.89-4.98-4.56-6.46l5.07-.62c.58,1.36,1.86,3.86,4.23,6.37,.06,.06,.02,.16-.06,.17Z" />
					<path d="M597.9,1677.83l-4.59,.72s-.06,0-.08-.03c-2.57-2.39-4.02-4.9-4.72-6.37l5.06-.73c.62,1.34,1.95,3.79,4.39,6.23,.06,.06,.03,.16-.05,.17Z" />
					<path d="M578.31,1680.55c-1.54,.18-3.07,.4-4.61,.54-.03,0-.06,0-.08-.03-.79-.79-1.46-1.58-2.03-2.35l-.47-4.39,3.11-.32c.57,1.36,1.81,3.85,4.15,6.39,.06,.06,.02,.16-.06,.17Z" />
					<path d="M594.94,1671.21l1.96-.31,.69,4.36c-1.31-1.58-2.16-3.06-2.65-4.05Z" />
					<path d="M591.38,1678.82l-4.6,.65s-.06,0-.08-.03c-2.54-2.39-3.95-4.93-4.64-6.41,1.69-.19,3.38-.46,5.07-.69,.6,1.35,1.9,3.82,4.31,6.3,.06,.06,.03,.16-.06,.17Z" />
				</g>
			</g>
			<g>
				<path d="M545.95,1685.02s.06-.07,.11-.07l4.55-.3c.1,0,.17,.1,.12,.19l-.33,.68c-.11,.21-.02,.47,.2,.58l.59,.29c.21,.11,.47,.02,.58-.2l.78-1.6s.06-.07,.11-.07c1.52-.11,3.04-.18,4.55-.33,.1,0,.17,.1,.13,.19l-.32,.69c-.1,.22-.01,.48,.2,.58l.59,.28c.22,.1,.47,0,.57-.21l.76-1.62s.06-.07,.11-.07l4.55-.39c.1,0,.17,.09,.13,.19l-.31,.68c-.1,.22,0,.47,.21,.58l.59,.28c.22,.1,.47,0,.57-.21l.74-1.62s.06-.07,.11-.08l4.54-.47c.1-.01,.17,.09,.13,.18l-.31,.69c-.1,.22,0,.47,.22,.57l.6,.27c.22,.1,.47,0,.57-.22l.5-1.14,.63,5.91-.74-1.01c-.14-.19-.41-.24-.61-.1l-.53,.39c-.19,.14-.24,.41-.1,.61l.45,.61c.06,.08,0,.2-.09,.21l-4.6,.48s-.09-.02-.12-.05l-1.04-1.46c-.14-.2-.41-.24-.61-.11l-.54,.38c-.2,.14-.24,.41-.1,.6l.44,.62c.06,.08,0,.2-.1,.21l-4.61,.39s-.09-.02-.12-.06l-1.02-1.47c-.14-.2-.41-.24-.6-.11l-.54,.38c-.2,.14-.25,.41-.11,.6l.43,.62c.06,.08,0,.2-.1,.21-1.53,.15-3.08,.22-4.61,.33-.05,0-.09-.02-.12-.06l-1-1.48c-.13-.2-.4-.25-.6-.12l-.55,.37c-.2,.13-.25,.4-.12,.6l.42,.63c.06,.08,0,.2-.1,.2l-4.61,.3s-.09-.02-.12-.06l-.3-.45-.33-5.93,.24-.48Z" />
				<g>
					<path d="M554.74,1692.93c1.58-.09,3.15-.23,4.72-.37,.08,0,.14,.08,.09,.15-1.83,2.97-2.63,5.67-2.95,7.11l-5.34,.36c.41-1.58,1.37-4.34,3.4-7.21,.02-.03,.05-.04,.08-.04Z" />
					<path d="M568.13,1691.78l4.71-.49c.08,0,.14,.08,.1,.15-1.76,3-2.48,5.71-2.77,7.17-1.77,.19-3.55,.37-5.33,.51,.37-1.6,1.26-4.34,3.21-7.3,.02-.03,.05-.04,.08-.05Z" />
					<path d="M548.04,1693.38l4.72-.32c.08,0,.14,.09,.09,.16-1.87,2.93-2.7,5.62-3.04,7.06-1.09,.07-2.18,.16-3.27,.2l-.25-4.41c.46-.84,1-1.74,1.66-2.65,.02-.03,.05-.04,.08-.04Z" />
					<path d="M573.68,1698.25l-2.07,.22c.24-1.09,.71-2.74,1.6-4.61l.47,4.39Z" />
					<path d="M561.44,1692.39l4.72-.4c.08,0,.14,.08,.1,.15-1.8,2.96-2.56,5.66-2.86,7.12l-5.33,.46c.39-1.59,1.31-4.37,3.3-7.28,.02-.03,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M558.64,1682.53c-1.54,.14-3.08,.27-4.63,.36-.03,0-.06-.01-.08-.03-2.38-2.57-3.63-5.19-4.23-6.7l5.1-.34c.52,1.4,1.66,3.93,3.9,6.55,.05,.06,.02,.16-.07,.17Z" />
					<path d="M571.76,1681.29l-4.62,.48s-.06,0-.08-.03c-2.44-2.47-3.77-5.09-4.4-6.59,1.7-.13,3.39-.31,5.09-.49,.55,1.37,1.76,3.88,4.07,6.46,.06,.06,.02,.16-.06,.17Z" />
					<path d="M552.07,1683.03l-4.63,.31s-.06-.01-.08-.03c-.74-.81-1.37-1.64-1.91-2.44l-.25-4.41c1.04-.04,2.08-.13,3.11-.19,.5,1.39,1.61,3.95,3.82,6.6,.05,.06,.01,.16-.07,.17Z" />
					<path d="M569.13,1674.52l1.98-.21,.47,4.39c-1.23-1.65-2.01-3.17-2.45-4.19Z" />
					<path d="M565.2,1681.97l-4.62,.4s-.06,0-.08-.03c-2.41-2.53-3.7-5.12-4.31-6.62l5.09-.44c.53,1.38,1.71,3.91,3.99,6.53,.05,.06,.02,.16-.06,.17Z" />
				</g>
			</g>
			<g>
				<path d="M519.53,1685.79s.07-.07,.11-.07l4.56-.04c.1,0,.16,.11,.11,.2l-.37,.66c-.12,.21-.04,.47,.17,.59l.57,.32c.21,.12,.47,.04,.59-.16l.86-1.56s.06-.07,.11-.07c1.52,0,3.04-.07,4.56-.11,.1,0,.17,.1,.12,.19l-.36,.67c-.11,.21-.04,.47,.17,.59l.57,.31c.21,.11,.47,.04,.58-.18l.84-1.57s.06-.07,.11-.07l4.56-.14c.1,0,.17,.1,.12,.19l-.35,.67c-.11,.21-.03,.47,.18,.58l.58,.3c.21,.11,.47,.03,.58-.19l.82-1.59s.06-.07,.11-.07l4.56-.22c.1,0,.17,.1,.12,.19l-.34,.67c-.11,.21-.02,.47,.19,.58l.58,.3c.21,.11,.47,.03,.58-.19l.56-1.11,.33,5.93-.69-1.05c-.13-.2-.4-.26-.6-.13l-.55,.36c-.2,.13-.26,.4-.13,.6l.41,.64c.06,.08,0,.2-.1,.2l-4.62,.22s-.09-.02-.12-.06l-.96-1.5c-.13-.2-.4-.26-.6-.13l-.56,.36c-.2,.13-.26,.4-.14,.6l.41,.64c.05,.08,0,.2-.11,.2l-4.62,.14s-.09-.02-.12-.06l-.94-1.52c-.13-.2-.39-.27-.6-.14l-.56,.34c-.2,.13-.27,.39-.14,.6l.4,.64c.05,.09,0,.2-.11,.2-1.54,.03-3.08,.11-4.62,.11-.05,0-.09-.02-.12-.06l-.92-1.54c-.12-.21-.39-.28-.6-.15l-.57,.33c-.21,.12-.27,.39-.15,.59l.39,.65c.05,.09-.01,.2-.11,.2l-4.63,.04s-.09-.02-.11-.06l-.27-.47v-5.94s.26-.47,.26-.47Z" />
				<g>
					<path d="M527.89,1694.18l4.73-.13c.08,0,.13,.09,.09,.16-1.99,2.86-2.92,5.51-3.32,6.94-1.78,.07-3.57,.06-5.36,.08,.5-1.56,1.6-4.25,3.78-7.01,.02-.02,.05-.04,.08-.04Z" />
					<path d="M541.32,1693.72l4.73-.23c.08,0,.14,.09,.09,.16-1.91,2.89-2.78,5.56-3.13,7l-5.35,.25c.46-1.57,1.48-4.32,3.59-7.15,.02-.02,.05-.04,.08-.04Z" />
					<path d="M521.17,1694.24l4.74-.04c.08,0,.13,.09,.08,.16-2.03,2.82-3,5.45-3.42,6.87l-3.28,.03v-4.42c.49-.83,1.09-1.69,1.79-2.57,.02-.02,.05-.04,.08-.04Z" />
					<path d="M546.54,1700.48l-2.08,.1c.3-1.07,.85-2.69,1.83-4.51l.25,4.41Z" />
					<path d="M534.61,1693.99c1.58-.04,3.16-.09,4.73-.18,.08,0,.13,.09,.09,.16-1.95,2.9-2.85,5.55-3.23,6.98l-5.36,.15c.48-1.57,1.54-4.29,3.68-7.07,.02-.02,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M532.33,1683.99l-4.64,.13s-.06-.01-.08-.04c-2.24-2.69-3.35-5.38-3.86-6.93,1.7-.02,3.4-.01,5.1-.08,.44,1.41,1.45,4.01,3.55,6.75,.05,.07,0,.16-.07,.16Z" />
					<path d="M545.49,1683.45l-4.63,.22s-.06-.01-.08-.03c-2.31-2.62-3.49-5.26-4.05-6.78l5.1-.24c.48,1.4,1.55,3.98,3.73,6.67,.05,.06,.01,.16-.07,.16Z" />
					<path d="M525.75,1684.15l-4.64,.04s-.06-.01-.08-.04c-.7-.86-1.28-1.72-1.77-2.54v-4.42s3.11-.03,3.11-.03c.42,1.42,1.39,4.03,3.46,6.82,.05,.07,0,.16-.08,.16Z" />
					<path d="M543.22,1676.54l1.98-.09,.25,4.41c-1.15-1.72-1.84-3.27-2.23-4.32Z" />
					<path d="M538.91,1683.76c-1.54,.08-3.09,.13-4.64,.17-.03,0-.06-.01-.08-.04-2.28-2.66-3.42-5.32-3.95-6.86l5.1-.14c.46,1.41,1.5,4.01,3.64,6.7,.05,.06,0,.16-.07,.16Z" />
				</g>
			</g>
			<g>
				<path d="M493.13,1685.1s.07-.06,.12-.06l4.55,.24c.1,0,.16,.12,.1,.2l-.4,.63c-.13,.2-.07,.47,.13,.6l.55,.36c.2,.13,.47,.07,.6-.13l.95-1.52s.07-.06,.12-.06l4.56,.15c.1,0,.16,.11,.11,.2l-.4,.64c-.12,.2-.06,.47,.14,.6l.56,.35c.2,.13,.47,.06,.59-.14l.93-1.52s.07-.06,.11-.06c1.52,.06,3.04,.07,4.56,.09,.1,0,.16,.11,.11,.2l-.39,.65c-.12,.21-.06,.47,.15,.59l.56,.34c.2,.12,.47,.05,.59-.15l.91-1.54s.07-.06,.11-.06l4.56,.05c.1,0,.16,.11,.11,.2l-.38,.65c-.12,.21-.05,.47,.16,.59l.57,.33c.21,.12,.47,.04,.59-.16l.62-1.09v5.94s-.62-1.08-.62-1.08c-.12-.21-.39-.28-.59-.15l-.57,.33c-.21,.12-.28,.38-.16,.59l.38,.66c.05,.09-.01,.2-.11,.2l-4.63-.05s-.09-.03-.11-.07l-.88-1.56c-.12-.21-.38-.28-.59-.17l-.58,.32c-.21,.12-.29,.38-.17,.59l.37,.66c.05,.09-.02,.2-.12,.2-1.54-.02-3.08-.03-4.63-.09-.05,0-.09-.03-.11-.07l-.86-1.58c-.11-.21-.38-.29-.59-.18l-.58,.31c-.21,.11-.29,.38-.18,.59l.36,.67c.05,.09-.02,.2-.12,.19l-4.63-.15s-.09-.03-.11-.07l-.83-1.58c-.11-.21-.37-.29-.59-.18l-.58,.3c-.21,.11-.3,.37-.19,.58l.35,.67c.05,.09-.02,.2-.12,.19l-4.62-.25s-.09-.03-.11-.07l-.25-.48,.33-5.93,.29-.45Z" />
				<g>
					<path d="M501,1693.94l4.74,.15c.08,0,.13,.1,.08,.16-2.14,2.73-3.23,5.32-3.7,6.73-1.79-.07-3.58-.09-5.36-.2,.59-1.51,1.84-4.15,4.17-6.81,.02-.02,.05-.04,.08-.04Z" />
					<path d="M514.44,1694.24c1.58,.01,3.16,.05,4.74,.02,.08,0,.13,.09,.08,.16-2.06,2.83-3.08,5.41-3.51,6.84l-5.36-.06c.54-1.54,1.72-4.22,3.98-6.92,.02-.02,.05-.04,.08-.04Z" />
					<path d="M494.28,1693.64c1.58,.08,3.15,.19,4.73,.23,.08,0,.13,.1,.07,.16-2.18,2.73-3.31,5.27-3.8,6.67l-3.28-.17,.24-4.41c.55-.79,1.19-1.62,1.94-2.45,.02-.02,.05-.04,.08-.03Z" />
					<path d="M519.29,1701.27c-.69,0-1.39,.02-2.08,0,.36-1.05,1-2.65,2.08-4.43v4.42Z" />
					<path d="M507.72,1694.15l4.74,.06c.08,0,.13,.1,.08,.16-2.1,2.78-3.15,5.39-3.61,6.8-1.79,0-3.58-.1-5.36-.15,.56-1.53,1.78-4.19,4.07-6.84,.02-.02,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M506,1684.03l-4.63-.15s-.06-.02-.08-.04c-2.09-2.79-3.04-5.52-3.46-7.1,1.69,.1,3.39,.12,5.09,.19,.36,1.43,1.22,4.09,3.17,6.95,.05,.07,0,.16-.08,.16Z" />
					<path d="M519.16,1684.2c-1.55,.03-3.09-.01-4.64-.02-.03,0-.06-.01-.08-.04-2.16-2.75-3.2-5.45-3.67-7.01l5.1,.06c.4,1.42,1.33,4.08,3.36,6.85,.05,.07,0,.16-.08,.16Z" />
					<path d="M499.42,1683.82c-1.54-.05-3.09-.15-4.63-.23-.03,0-.06-.02-.08-.04-.65-.9-1.18-1.79-1.62-2.64l.24-4.41,3.11,.17c.34,1.43,1.16,4.13,3.06,7,.05,.07,0,.16-.09,.16Z" />
					<path d="M517.27,1677.2c.66,.01,1.32,0,1.98,0v4.42c-1.04-1.76-1.65-3.34-1.98-4.41Z" />
					<path d="M512.58,1684.16l-4.64-.06s-.06-.02-.08-.04c-2.12-2.8-3.12-5.52-3.56-7.09,1.7,.04,3.39,.13,5.09,.14,.38,1.43,1.28,4.06,3.27,6.88,.05,.07,0,.16-.08,.16Z" />
				</g>
			</g>
			<g>
				<path d="M466.82,1682.87s.07-.06,.12-.05c1.51,.18,3.01,.38,4.53,.51,.1,0,.15,.12,.09,.21l-.44,.62c-.14,.19-.1,.47,.1,.6l.53,.38c.19,.14,.46,.09,.6-.1l1.04-1.45s.07-.06,.12-.05l4.54,.44c.1,0,.15,.12,.09,.21l-.43,.62c-.14,.2-.09,.47,.1,.6l.53,.38c.19,.14,.46,.09,.6-.1l1.02-1.47s.07-.06,.12-.06l4.54,.34c.1,0,.15,.12,.1,.2l-.42,.63c-.13,.2-.08,.47,.11,.6l.54,.37c.2,.13,.47,.08,.6-.11l.99-1.48s.07-.06,.12-.06c1.51,.12,3.03,.22,4.55,.29,.1,0,.16,.12,.1,.2l-.41,.63c-.13,.2-.08,.47,.12,.6l.55,.36c.2,.13,.47,.07,.6-.13l.68-1.05-.33,5.93-.57-1.11c-.11-.21-.37-.3-.58-.19l-.59,.3c-.21,.11-.3,.37-.19,.58l.34,.67c.05,.09-.02,.2-.13,.19-1.54-.07-3.08-.17-4.62-.3-.05,0-.09-.03-.11-.07l-.79-1.61c-.1-.22-.36-.31-.58-.2l-.59,.29c-.22,.1-.31,.36-.2,.58l.33,.68c.04,.09-.03,.2-.13,.19l-4.62-.35s-.09-.03-.11-.07l-.76-1.62c-.1-.22-.36-.31-.58-.22l-.6,.27c-.22,.1-.31,.36-.21,.57l.32,.69c.04,.09-.03,.19-.13,.18l-4.61-.45s-.09-.03-.11-.08l-.74-1.63c-.1-.22-.36-.31-.57-.22l-.6,.27c-.22,.1-.32,.36-.22,.57l.31,.69c.04,.09-.03,.19-.13,.18-1.54-.13-3.07-.34-4.6-.52-.05,0-.09-.04-.11-.08l-.22-.5,.69-5.9,.32-.43Z" />
				<g>
					<path d="M474.15,1692.16l4.72,.45c.08,0,.12,.1,.07,.17-2.3,2.6-3.54,5.11-4.1,6.49l-5.35-.52c.68-1.49,2.09-4.06,4.58-6.56,.02-.02,.05-.03,.08-.03Z" />
					<path d="M487.56,1693.26c1.58,.12,3.15,.18,4.73,.27,.08,0,.12,.1,.07,.17-2.22,2.69-3.38,5.25-3.9,6.65l-5.36-.39c.63-1.51,1.96-4.11,4.37-6.66,.02-.02,.05-.03,.08-.03Z" />
					<path d="M467.45,1691.47c1.57,.2,3.14,.34,4.72,.49,.08,0,.12,.11,.06,.17-2.34,2.58-3.62,5.1-4.2,6.46l-3.26-.39,.51-4.39c.6-.76,1.29-1.54,2.09-2.32,.02-.02,.05-.03,.08-.03Z" />
					<path d="M492.01,1700.53l-2.08-.11c.42-1.04,1.15-2.59,2.33-4.3l-.24,4.41Z" />
					<path d="M480.85,1692.76l4.73,.35c.08,0,.12,.1,.07,.17-2.26,2.64-3.46,5.18-4,6.56-1.79-.14-3.57-.25-5.35-.44,.65-1.5,2.02-4.05,4.47-6.61,.02-.02,.05-.03,.08-.03Z" />
				</g>
				<g>
					<path d="M479.72,1682.59l-4.61-.44s-.06-.02-.07-.05c-1.91-2.93-2.7-5.7-3.03-7.29l5.06,.49c.27,1.45,.97,4.16,2.75,7.14,.04,.07-.01,.16-.09,.15Z" />
					<path d="M492.85,1683.49c-1.54-.09-3.09-.15-4.63-.26-.03,0-.06-.02-.08-.04-2-2.88-2.87-5.65-3.25-7.23l5.08,.37c.32,1.44,1.1,4.11,2.96,7.01,.04,.07,0,.16-.09,.16Z" />
					<path d="M473.18,1681.96c-1.54-.15-3.08-.28-4.61-.48-.03,0-.06-.02-.07-.05-.59-.94-1.07-1.87-1.46-2.74l.51-4.39,3.08,.37c.25,1.46,.91,4.15,2.64,7.14,.04,.07-.01,.16-.09,.15Z" />
					<path d="M491.36,1676.4l1.98,.11-.24,4.41c-.95-1.82-1.46-3.44-1.73-4.52Z" />
					<path d="M486.28,1683.08l-4.62-.35s-.06-.02-.08-.04c-1.96-2.87-2.79-5.67-3.14-7.26,1.69,.18,3.38,.29,5.07,.42,.3,1.45,1.04,4.13,2.86,7.08,.04,.07,0,.16-.09,.15Z" />
				</g>
			</g>
			<g>
				<path d="M440.71,1679.02s.08-.05,.12-.04c1.49,.29,2.99,.51,4.49,.77,.1,.02,.14,.13,.08,.21l-.48,.58c-.15,.18-.13,.46,.06,.61l.5,.42c.18,.15,.46,.13,.61-.06l1.13-1.38s.08-.05,.12-.05c1.5,.24,2.99,.52,4.49,.73,.1,.01,.14,.13,.08,.21l-.47,.59c-.15,.19-.12,.46,.07,.61l.51,.4c.19,.15,.46,.11,.61-.07l1.11-1.41s.08-.06,.12-.05l4.51,.64c.1,.01,.15,.13,.08,.21l-.46,.6c-.15,.19-.11,.46,.08,.61l.52,.41c.19,.15,.46,.11,.6-.07l1.08-1.41s.07-.06,.12-.05l4.52,.54c.1,.01,.15,.13,.09,.21l-.45,.61c-.14,.19-.1,.46,.09,.61l.52,.39c.19,.14,.46,.1,.6-.09l.74-1-.69,5.9-.5-1.15c-.1-.22-.35-.32-.57-.23l-.61,.26c-.22,.09-.32,.35-.23,.57l.3,.7c.04,.09-.04,.19-.14,.18l-4.6-.55s-.09-.04-.1-.08l-.69-1.66c-.09-.22-.34-.33-.57-.24l-.61,.24c-.22,.09-.33,.34-.24,.56l.29,.7c.04,.09-.04,.19-.14,.18l-4.59-.65s-.09-.04-.1-.08l-.66-1.66c-.09-.22-.34-.33-.56-.24l-.61,.24c-.22,.09-.33,.34-.25,.56l.28,.7c.04,.09-.04,.19-.14,.18-1.53-.22-3.05-.5-4.57-.74-.05,0-.09-.04-.1-.08l-.63-1.68c-.08-.22-.33-.34-.56-.25l-.62,.23c-.23,.08-.34,.33-.26,.56l.27,.71c.04,.09-.05,.19-.15,.18-1.52-.26-3.05-.49-4.57-.78-.05,0-.08-.04-.1-.09l-.18-.51,1.07-5.84,.35-.41Z" />
				<g>
					<path d="M447.44,1688.75c1.56,.27,3.12,.51,4.69,.73,.08,.01,.12,.11,.06,.17-2.46,2.48-3.86,4.92-4.51,6.25l-5.31-.89c.77-1.44,2.35-3.91,4.99-6.23,.02-.02,.05-.03,.09-.02Z" />
					<path d="M460.77,1690.67l4.71,.56c.08,0,.12,.11,.06,.17-2.38,2.54-3.7,5.02-4.3,6.38-1.78-.2-3.56-.43-5.33-.7,.73-1.48,2.21-3.94,4.78-6.39,.02-.02,.05-.03,.08-.03Z" />
					<path d="M440.79,1687.64l4.68,.78c.08,.01,.11,.11,.05,.17-2.5,2.42-3.94,4.84-4.61,6.17-1.08-.18-2.17-.36-3.24-.57l.79-4.35c.64-.72,1.38-1.44,2.24-2.18,.02-.02,.06-.03,.09-.02Z" />
					<path d="M464.77,1698.21l-2.08-.25c.48-1.01,1.31-2.51,2.59-4.14l-.51,4.39Z" />
					<path d="M454.09,1689.75l4.7,.67c.08,.01,.12,.11,.06,.17-2.42,2.48-3.78,4.94-4.4,6.29l-5.33-.76c.75-1.46,2.28-3.96,4.89-6.34,.02-.02,.05-.03,.08-.03Z" />
				</g>
				<g>
					<path d="M453.61,1679.52c-1.53-.21-3.06-.44-4.58-.71-.03,0-.06-.02-.07-.05-1.72-3.05-2.33-5.88-2.55-7.49l5.01,.84c.18,1.48,.7,4.22,2.29,7.26,.04,.07-.02,.16-.1,.15Z" />
					<path d="M466.64,1681.25l-4.6-.55s-.06-.02-.07-.05c-1.82-2.96-2.52-5.81-2.79-7.41,1.67,.25,3.35,.47,5.04,.66,.23,1.46,.84,4.17,2.52,7.2,.04,.07-.02,.16-.1,.15Z" />
					<path d="M447.11,1678.5l-4.57-.76s-.06-.02-.07-.05c-.53-.96-.95-1.93-1.28-2.83l.79-4.35c1.01,.2,2.03,.37,3.05,.54,.16,1.47,.64,4.21,2.17,7.3,.04,.07-.02,.16-.1,.15Z" />
					<path d="M465.58,1674.07l1.96,.23-.51,4.39c-.83-1.88-1.25-3.53-1.45-4.62Z" />
					<path d="M460.11,1680.45l-4.58-.65s-.06-.02-.07-.05c-1.77-3.01-2.43-5.82-2.68-7.42l5.03,.72c.2,1.47,.77,4.2,2.41,7.26,.04,.07-.02,.16-.1,.15Z" />
				</g>
			</g>
			<g>
				<path d="M414.94,1673.42s.08-.05,.12-.04l4.42,1.08c.1,.02,.13,.14,.06,.22l-.52,.55c-.16,.17-.16,.45,.01,.61l.47,.45c.17,.17,.45,.16,.61-.02l1.22-1.3s.08-.05,.12-.04c1.47,.37,2.96,.69,4.44,1.01,.1,.02,.14,.14,.07,.22l-.51,.56c-.16,.18-.15,.45,.02,.61l.48,.44c.18,.16,.45,.15,.61-.03l1.2-1.32s.08-.05,.12-.04l4.45,.97c.1,.02,.14,.14,.07,.22l-.5,.56c-.16,.18-.14,.45,.04,.61l.49,.43c.18,.16,.45,.14,.61-.04l1.18-1.35s.08-.05,.12-.04l4.47,.86c.1,.02,.14,.14,.07,.21l-.49,.57c-.15,.18-.13,.46,.05,.61l.5,.43c.18,.16,.45,.14,.61-.05l.8-.95-1.07,5.84-.42-1.18c-.08-.23-.33-.35-.55-.27l-.62,.22c-.23,.08-.35,.33-.27,.55l.25,.72c.03,.1-.05,.19-.15,.17l-4.55-.87s-.08-.04-.1-.09l-.58-1.69c-.08-.23-.32-.35-.55-.27l-.63,.21c-.23,.07-.35,.32-.27,.55l.24,.72c.03,.09-.05,.19-.15,.17l-4.53-.98s-.08-.04-.1-.09l-.55-1.7c-.07-.23-.32-.35-.55-.28l-.63,.2c-.23,.07-.36,.32-.28,.54l.23,.72c.03,.1-.06,.19-.15,.17-1.51-.33-3.02-.65-4.52-1.03-.05-.01-.08-.05-.1-.09l-.52-1.72c-.07-.23-.31-.36-.54-.29l-.63,.18c-.23,.07-.36,.31-.29,.54l.22,.73c.03,.1-.06,.19-.16,.16l-4.5-1.1s-.08-.05-.1-.09l-.15-.52,1.47-5.76,.38-.39Z" />
				<g>
					<path d="M420.98,1683.6l4.64,1.01c.08,.02,.11,.12,.04,.17-2.62,2.3-4.19,4.64-4.92,5.94-1.76-.37-3.5-.84-5.25-1.25,.87-1.38,2.61-3.73,5.4-5.86,.02-.02,.06-.03,.09-.02Z" />
					<path d="M434.17,1686.38l4.66,.89c.08,.02,.11,.12,.05,.17-2.54,2.36-4.02,4.75-4.71,6.07l-5.29-1.03c.82-1.4,2.48-3.84,5.2-6.09,.02-.02,.06-.03,.09-.02Z" />
					<path d="M414.43,1682.01l4.62,1.12c.08,.02,.1,.12,.04,.18-2.66,2.23-4.26,4.54-5.02,5.82l-3.21-.78,1.09-4.28c.69-.68,1.49-1.37,2.39-2.04,.02-.02,.06-.03,.09-.02Z" />
					<path d="M437.67,1694.19l-2.06-.39c.54-.97,1.47-2.42,2.85-3.95l-.79,4.35Z" />
					<path d="M427.57,1685.04c1.55,.33,3.09,.69,4.65,.97,.08,.02,.11,.12,.05,.17-2.59,2.37-4.1,4.69-4.81,6l-5.27-1.14c.85-1.4,2.54-3.79,5.3-5.98,.02-.02,.06-.03,.09-.02Z" />
				</g>
				<g>
					<path d="M427.76,1674.79l-4.52-.99s-.05-.03-.07-.06c-1.5-3.17-1.91-6.04-2.02-7.66,1.64,.39,3.27,.82,4.93,1.17,.08,1.47,.42,4.23,1.8,7.39,.03,.08-.03,.16-.11,.14Z" />
					<path d="M440.64,1677.38l-4.54-.87s-.06-.03-.07-.05c-1.62-3.09-2.14-5.91-2.3-7.54l4.98,.96c.13,1.47,.56,4.23,2.04,7.36,.04,.07-.03,.16-.11,.14Z" />
					<path d="M421.36,1673.34l-4.49-1.09s-.05-.03-.07-.06c-.46-1-.81-1.98-1.08-2.89l1.09-4.28,3.01,.73c.05,1.48,.34,4.25,1.66,7.45,.03,.08-.03,.16-.11,.14Z" />
					<path d="M440.06,1670.14l1.94,.37-.79,4.35c-.71-1.94-1.01-3.61-1.14-4.72Z" />
					<path d="M434.19,1676.15c-1.52-.27-3.02-.62-4.53-.95-.03,0-.06-.03-.07-.05-1.56-3.13-2.03-5.98-2.17-7.6l4.95,1.08c.11,1.47,.48,4.27,1.92,7.38,.03,.07-.03,.16-.11,.14Z" />
				</g>
			</g>
			<g>
				<path d="M389.66,1665.99s.08-.04,.13-.03l4.32,1.42c.09,.03,.12,.15,.05,.22l-.56,.51c-.18,.16-.19,.43-.03,.61l.44,.49c.16,.18,.43,.19,.61,.03l1.31-1.2s.08-.04,.13-.03l4.36,1.3c.1,.03,.12,.15,.05,.22l-.55,.52c-.17,.16-.18,.44-.02,.61l.45,.48c.16,.17,.44,.18,.61,.02l1.29-1.23s.08-.04,.13-.03c1.45,.44,2.9,.87,4.37,1.26,.1,.03,.13,.15,.06,.22l-.54,.53c-.17,.17-.17,.44,0,.61l.46,.46c.17,.17,.44,.17,.61,0l1.27-1.26s.08-.05,.13-.03l4.39,1.19c.1,.03,.13,.15,.06,.22l-.53,.54c-.17,.17-.17,.44,0,.61l.46,.47c.17,.17,.44,.17,.61,0l.87-.9-1.47,5.76-.34-1.2c-.07-.23-.3-.37-.54-.31l-.63,.17c-.23,.06-.37,.3-.3,.53l.2,.73c.03,.1-.06,.19-.16,.16l-4.48-1.21s-.08-.05-.09-.09l-.46-1.73c-.06-.23-.3-.37-.53-.31l-.64,.17c-.23,.06-.37,.3-.31,.53l.19,.73c.03,.1-.07,.19-.16,.16-1.5-.39-2.98-.83-4.46-1.28-.05-.01-.08-.05-.09-.1l-.42-1.74c-.06-.23-.29-.38-.52-.32l-.64,.15c-.23,.05-.38,.29-.32,.52l.18,.74c.02,.1-.07,.18-.17,.16l-4.44-1.33s-.08-.05-.09-.1l-.39-1.76c-.05-.24-.28-.39-.52-.34l-.64,.13c-.23,.05-.38,.28-.33,.51l.16,.74c.02,.1-.07,.18-.17,.15l-4.41-1.45s-.08-.05-.09-.1l-.11-.53,1.89-5.63,.4-.36Z" />
				<g>
					<path d="M394.94,1676.58l4.55,1.36c.08,.02,.1,.13,.03,.18-2.78,2.09-4.51,4.31-5.33,5.55-1.73-.51-3.45-1.03-5.16-1.61,.97-1.33,2.86-3.49,5.82-5.47,.03-.02,.06-.02,.09-.01Z" />
					<path d="M407.9,1680.31c1.53,.41,3.05,.84,4.59,1.23,.08,.02,.1,.12,.04,.18-2.7,2.21-4.34,4.45-5.12,5.72l-5.21-1.41c.92-1.36,2.75-3.65,5.62-5.7,.03-.02,.06-.02,.09-.02Z" />
					<path d="M388.51,1674.52l4.52,1.48c.08,.03,.1,.13,.03,.18-2.81,2.02-4.58,4.2-5.43,5.42l-3.14-1.03,1.4-4.19c.74-.62,1.58-1.25,2.53-1.85,.03-.02,.06-.02,.09-.01Z" />
					<path d="M410.86,1688.35c-.68-.16-1.36-.35-2.03-.53,.61-.94,1.63-2.28,3.12-3.75l-1.09,4.28Z" />
					<path d="M401.4,1678.52c1.51,.47,3.05,.85,4.58,1.27,.08,.02,.1,.13,.04,.18-2.74,2.16-4.43,4.42-5.24,5.67l-5.18-1.54c.94-1.34,2.81-3.59,5.72-5.56,.03-.02,.06-.02,.09-.01Z" />
				</g>
				<g>
					<path d="M402.33,1668.3l-4.43-1.32s-.05-.03-.06-.06c-1.29-3.21-1.47-6.15-1.46-7.77,1.59,.54,3.21,1.03,4.82,1.5-.03,1.48,.11,4.26,1.25,7.52,.03,.08-.04,.16-.12,.13Z" />
					<path d="M414.98,1671.79c-1.5-.37-2.98-.8-4.47-1.19-.03,0-.05-.03-.07-.06-1.4-3.2-1.7-6.07-1.76-7.69l4.88,1.32c.03,1.47,.26,4.28,1.52,7.49,.03,.08-.04,.16-.12,.14Z" />
					<path d="M396.05,1666.41l-4.39-1.44s-.05-.03-.06-.06c-.39-1.04-.66-2.04-.86-2.97l1.4-4.19,2.93,.96c-.06,1.48,.03,4.27,1.1,7.57,.03,.08-.05,.15-.12,.13Z" />
					<path d="M414.9,1664.53c.63,.17,1.26,.35,1.9,.5l-1.09,4.28c-.58-1.95-.76-3.67-.81-4.78Z" />
					<path d="M408.64,1670.09c-1.48-.42-2.98-.78-4.45-1.24-.03,0-.05-.03-.06-.06-1.33-3.25-1.58-6.13-1.61-7.75l4.84,1.45c0,1.47,.19,4.24,1.4,7.47,.03,.08-.04,.16-.12,.13Z" />
				</g>
			</g>
			<g>
				<path d="M365.02,1656.66s.09-.03,.13-.02l4.18,1.77c.09,.04,.11,.16,.03,.22l-.59,.46c-.19,.15-.22,.42-.08,.61l.4,.51c.15,.19,.42,.22,.61,.07l1.4-1.11s.09-.04,.13-.02l4.23,1.66c.09,.04,.11,.16,.03,.22l-.58,.47c-.19,.15-.21,.42-.07,.61l.41,.51c.15,.19,.42,.22,.6,.07l1.37-1.12s.08-.04,.13-.02c1.41,.55,2.85,1.04,4.27,1.56,.09,.03,.12,.16,.04,.22l-.58,.49c-.18,.15-.21,.43-.05,.61l.42,.5c.15,.18,.43,.2,.61,.05l1.35-1.16s.08-.04,.13-.02c1.43,.5,2.84,1.05,4.29,1.51,.09,.03,.12,.15,.04,.22l-.57,.5c-.18,.16-.2,.43-.04,.61l.43,.49c.16,.18,.43,.19,.61,.03l.93-.83-1.89,5.63-.25-1.22c-.05-.23-.28-.38-.51-.34l-.65,.13c-.24,.05-.39,.28-.34,.51l.15,.74c.02,.1-.08,.18-.17,.15-1.47-.47-2.92-1.03-4.38-1.54-.04-.02-.08-.05-.09-.1l-.33-1.76c-.04-.24-.27-.39-.51-.35l-.65,.12c-.24,.04-.4,.27-.35,.5l.14,.74c.02,.1-.08,.18-.18,.15-1.45-.53-2.92-1.03-4.36-1.59-.04-.02-.08-.06-.08-.1l-.29-1.78c-.04-.24-.26-.4-.5-.36l-.65,.1c-.24,.04-.4,.26-.36,.49l.12,.75c.02,.1-.08,.18-.18,.14l-4.32-1.69s-.08-.06-.08-.1l-.26-1.76c-.03-.24-.26-.4-.49-.36l-.66,.09c-.24,.03-.4,.25-.37,.49l.11,.75c.01,.1-.09,.18-.18,.14l-4.27-1.81s-.07-.06-.08-.11l-.07-.54,2.32-5.47,.43-.33Z" />
				<g>
					<path d="M369.47,1667.63l4.43,1.73c.08,.03,.09,.14,.02,.18-2.93,1.86-4.82,3.94-5.74,5.1l-5.04-1.98c1.07-1.23,3.15-3.31,6.24-5.03,.03-.02,.06-.02,.09,0Z" />
					<path d="M382.12,1672.36c1.49,.55,2.99,1.05,4.5,1.54,.08,.03,.09,.13,.03,.18-2.86,2.01-4.68,4.17-5.55,5.36l-5.1-1.83c1.02-1.28,3.01-3.42,6.03-5.24,.03-.02,.06-.02,.09,0Z" />
					<path d="M363.21,1665.09c1.46,.62,2.91,1.26,4.4,1.82,.08,.03,.09,.14,.02,.18-2.98,1.86-4.9,3.85-5.85,5.01l-3.05-1.29,1.73-4.07c.79-.56,1.68-1.12,2.67-1.64,.03-.01,.06-.02,.09,0Z" />
					<path d="M384.48,1680.57l-2-.66c.68-.89,1.81-2.19,3.4-3.53l-1.4,4.19Z" />
					<path d="M375.76,1670.08l4.48,1.61c.08,.03,.09,.13,.02,.18-2.9,1.93-4.75,4.05-5.65,5.24-1.71-.59-3.38-1.26-5.07-1.92,1.05-1.27,3.07-3.33,6.13-5.11,.03-.02,.06-.02,.09,0Z" />
				</g>
				<g>
					<path d="M377.46,1659.96l-4.3-1.68s-.05-.03-.06-.06c-1.02-3.33-1.01-6.2-.87-7.83l4.69,1.84c-.14,1.47-.22,4.26,.66,7.61,.02,.08-.06,.15-.13,.12Z" />
					<path d="M389.82,1664.36c-1.47-.47-2.93-.96-4.37-1.5-.03-.01-.05-.03-.06-.06-1.15-3.31-1.23-6.2-1.16-7.82l4.75,1.71c-.09,1.49-.05,4.26,.97,7.54,.02,.08-.05,.15-.13,.13Z" />
					<path d="M371.36,1657.58c-1.44-.54-2.85-1.16-4.27-1.76-.03-.01-.05-.04-.06-.07-.3-1.07-.5-2.09-.62-3.03l1.73-4.07,2.83,1.2c-.17,1.46-.32,4.29,.52,7.6,.02,.08-.06,.15-.13,.12Z" />
					<path d="M390.27,1657.14l1.87,.61-1.4,4.19c-.43-2-.49-3.7-.46-4.8Z" />
					<path d="M383.62,1662.21l-4.35-1.56s-.05-.03-.06-.06c-1.09-3.31-1.1-6.24-1-7.86,1.56,.62,3.13,1.24,4.72,1.79-.11,1.47-.13,4.25,.82,7.57,.02,.08-.05,.15-.13,.12Z" />
				</g>
			</g>
			<g>
				<path d="M341.23,1645.4s.09-.03,.13,0c1.33,.72,2.67,1.43,4.03,2.09,.09,.04,.1,.17,.01,.23l-.63,.42c-.2,.13-.26,.4-.13,.6l.36,.54c.13,.2,.4,.25,.6,.12l1.49-.99s.09-.03,.13,0l4.07,2.02c.09,.04,.1,.17,.02,.23l-.62,.42c-.2,.13-.25,.4-.12,.6l.36,.55c.13,.2,.4,.25,.6,.11l1.47-1.03s.09-.03,.13-.01l4.13,1.9c.09,.04,.1,.17,.02,.23l-.61,.44c-.19,.14-.24,.41-.1,.6l.38,.54c.14,.19,.41,.24,.6,.1l1.44-1.04s.09-.03,.13-.01c1.37,.65,2.77,1.23,4.16,1.82,.09,.04,.11,.16,.03,.22l-.6,.46c-.19,.14-.23,.41-.09,.61l.39,.52c.14,.19,.41,.23,.6,.08l.99-.75-2.32,5.47-.15-1.24c-.03-.24-.25-.41-.48-.38l-.66,.08c-.24,.03-.41,.24-.38,.48l.09,.75c.01,.1-.09,.18-.18,.14-1.42-.61-2.85-1.2-4.25-1.86-.04-.02-.07-.06-.08-.11l-.18-1.79c-.02-.24-.24-.41-.48-.39l-.66,.06c-.24,.02-.41,.23-.39,.47l.07,.76c.01,.1-.09,.17-.19,.13l-4.22-1.94s-.07-.06-.08-.11l-.15-1.77c-.02-.23-.23-.42-.47-.4l-.65,.04c-.24,.02-.42,.22-.4,.46l.06,.76c0,.1-.1,.17-.19,.13l-4.16-2.06s-.07-.06-.07-.11l-.11-1.78c-.01-.24-.22-.42-.46-.41l-.66,.04c-.24,.01-.43,.22-.41,.46l.05,.75c0,.1-.1,.17-.19,.12-1.4-.67-2.76-1.4-4.12-2.13-.04-.02-.07-.07-.07-.11l-.02-.54,2.77-5.26,.45-.29Z" />
				<g>
					<path d="M344.76,1656.7c1.43,.7,2.84,1.43,4.27,2.1,.08,.04,.08,.14,0,.18-3.08,1.65-5.12,3.53-6.14,4.62l-4.86-2.41c1.17-1.15,3.42-3.04,6.63-4.49,.03-.01,.06-.01,.09,0Z" />
					<path d="M356.99,1662.45l4.38,1.87c.08,.03,.08,.14,.01,.18-3.01,1.76-4.99,3.77-5.96,4.91-1.67-.69-3.29-1.49-4.94-2.23,1.12-1.19,3.28-3.16,6.42-4.72,.03-.01,.06-.01,.09,0Z" />
					<path d="M338.72,1653.66c1.39,.77,2.83,1.43,4.25,2.15,.08,.04,.08,.14,0,.18-3.12,1.58-5.22,3.46-6.26,4.54l-2.93-1.56,2.06-3.91c.83-.49,1.76-.97,2.79-1.4,.03-.01,.06-.01,.09,0Z" />
					<path d="M358.72,1670.8l-1.94-.82c.75-.83,1.97-2.04,3.67-3.24l-1.73,4.07Z" />
					<path d="M350.85,1659.63l4.33,1.99c.08,.04,.08,.14,0,.18-3.04,1.69-5.06,3.65-6.05,4.76-1.64-.77-3.3-1.48-4.91-2.3,1.14-1.16,3.33-3.08,6.54-4.62,.03-.01,.06-.01,.09,0Z" />
				</g>
				<g>
					<path d="M353.35,1649.71c-1.4-.65-2.76-1.36-4.14-2.03-.03-.01-.05-.04-.05-.07-.74-3.41-.48-6.28-.21-7.88l4.51,2.23c-.26,1.45-.58,4.25,.04,7.63,.02,.08-.07,.15-.14,.11Z" />
					<path d="M365.31,1655.06l-4.25-1.81s-.05-.04-.06-.07c-.87-3.4-.72-6.29-.52-7.91,1.53,.69,3.04,1.43,4.59,2.07-.2,1.46-.39,4.23,.37,7.59,.02,.08-.06,.15-.14,.12Z" />
					<path d="M347.48,1646.82c-1.37-.7-2.77-1.33-4.12-2.08-.03-.01-.05-.04-.05-.07-.21-1.09-.32-2.13-.36-3.08l2.06-3.91,2.71,1.44c-.29,1.44-.65,4.19-.1,7.59,.01,.08-.07,.15-.14,.11Z" />
					<path d="M366.34,1647.88l1.8,.77-1.73,4.07c-.26-2.03-.19-3.73-.08-4.83Z" />
					<path d="M359.3,1652.44l-4.19-1.93s-.05-.04-.06-.07c-.83-3.37-.62-6.26-.38-7.88,1.49,.77,3.04,1.42,4.56,2.13-.23,1.46-.48,4.23,.2,7.62,.02,.08-.06,.15-.14,.11Z" />
				</g>
			</g>
			<g>
				<path d="M318.49,1632.19s.09-.02,.13,0c1.26,.83,2.57,1.6,3.85,2.4,.08,.05,.08,.18,0,.23l-.66,.36c-.21,.11-.29,.38-.18,.59l.31,.58c.11,.21,.37,.29,.58,.17l1.56-.86s.09-.02,.13,0c1.3,.78,2.56,1.62,3.88,2.35,.09,.05,.08,.17,0,.23l-.66,.38c-.21,.12-.28,.38-.17,.59l.32,.56c.12,.21,.38,.28,.59,.16l1.55-.9s.09-.02,.13,0l3.94,2.26c.09,.05,.09,.18,0,.23l-.65,.38c-.2,.12-.27,.39-.15,.59l.33,.57c.12,.21,.38,.28,.59,.15l1.52-.92s.09-.03,.13,0l4.01,2.14c.09,.05,.09,.17,0,.23l-.64,.4c-.2,.13-.27,.39-.14,.6l.34,.56c.13,.2,.39,.26,.59,.14l1.05-.66-2.77,5.26-.05-1.25c0-.24-.21-.43-.45-.42l-.66,.02c-.24,0-.43,.21-.42,.45l.03,.76c0,.1-.1,.17-.19,.12l-4.1-2.19s-.07-.06-.07-.11l-.03-1.8c0-.24-.2-.44-.44-.43h-.66c-.24,0-.43,.2-.43,.44v.76c.01,.1-.1,.17-.19,.11l-4.03-2.31s-.07-.07-.07-.11v-1.79c0-.24-.19-.43-.44-.43h-.66c-.24,0-.44,.19-.44,.43v.75c0,.1-.11,.16-.2,.11-1.35-.75-2.65-1.61-3.97-2.41-.04-.02-.06-.07-.06-.12l.04-1.79c0-.24-.18-.44-.42-.45l-.66-.02c-.24,0-.44,.18-.45,.42l-.02,.76c0,.1-.11,.16-.2,.11-1.31-.82-2.65-1.6-3.94-2.46-.04-.03-.06-.07-.06-.12l.03-.55,3.21-4.99,.47-.24Z" />
				<g>
					<path d="M321.04,1643.74c1.34,.85,2.72,1.64,4.1,2.43,.07,.04,.07,.15-.01,.18-3.22,1.38-5.44,3.13-6.53,4.12l-4.63-2.85c1.26-1.04,3.66-2.73,6.98-3.88,.03-.01,.06,0,.09,0Z" />
					<path d="M332.76,1650.48l4.2,2.24c.07,.04,.07,.15,0,.18-3.14,1.5-5.28,3.33-6.34,4.37-1.6-.85-3.2-1.7-4.76-2.62,1.22-1.1,3.51-2.83,6.81-4.18,.03-.01,.06-.01,.09,0Z" />
					<path d="M315.28,1640.19l4.06,2.5c.07,.04,.06,.15-.01,.18-3.23,1.3-5.49,2.99-6.62,3.96-.94-.59-1.89-1.14-2.81-1.78l2.39-3.71c.87-.41,1.83-.79,2.91-1.16,.03,0,.06,0,.09,.01Z" />
					<path d="M333.79,1658.97l-1.86-.99c.81-.77,2.14-1.86,3.92-2.91l-2.06,3.91Z" />
					<path d="M326.87,1647.16l4.13,2.37c.07,.04,.07,.15,0,.18-3.16,1.41-5.34,3.19-6.42,4.21l-4.71-2.71c1.24-1.07,3.61-2.82,6.92-4.06,.03-.01,.06,0,.09,0Z" />
				</g>
				<g>
					<path d="M330.2,1637.48c-1.34-.76-2.67-1.53-3.97-2.35-.03-.02-.04-.04-.05-.07-.44-3.47,.07-6.32,.48-7.89l4.28,2.64c-.39,1.44-.93,4.16-.59,7.58,0,.08-.08,.14-.15,.1Z" />
					<path d="M341.65,1643.82l-4.07-2.17s-.05-.04-.05-.07c-.62-3.4-.2-6.32,.13-7.9,1.44,.85,2.92,1.64,4.4,2.42-.32,1.44-.74,4.19-.27,7.61,.01,.08-.07,.14-.15,.11Z" />
					<path d="M324.59,1634.11l-3.92-2.42s-.04-.04-.05-.07c-.13-1.09-.14-2.13-.09-3.1l2.39-3.71c.83,.58,1.72,1.1,2.59,1.64-.41,1.42-1.01,4.13-.76,7.57,0,.08-.08,.14-.15,.1Z" />
					<path d="M343.28,1636.75l1.73,.92-2.06,3.91c-.09-2.05,.13-3.74,.33-4.83Z" />
					<path d="M335.88,1640.74l-4-2.3s-.04-.04-.05-.07c-.53-3.45-.08-6.29,.29-7.87l4.36,2.5c-.35,1.44-.84,4.18-.46,7.63,0,.08-.08,.14-.15,.1Z" />
				</g>
			</g>
			<g>
				<path d="M297.19,1616.85s.09,0,.13,.02l1.75,1.44,1.81,1.36c.08,.06,.06,.18-.03,.22l-.7,.29c-.22,.09-.33,.34-.24,.56l.25,.6c.09,.22,.35,.32,.57,.23l1.65-.7s.09-.01,.13,.01l3.63,2.71c.08,.06,.07,.19-.03,.23l-.69,.31c-.22,.1-.32,.35-.22,.57l.26,.6c.09,.22,.35,.32,.56,.22l1.61-.73s.09-.01,.13,.01c1.19,.93,2.48,1.72,3.72,2.58,.08,.06,.07,.18-.02,.22l-.68,.33c-.22,.1-.31,.36-.21,.57l.28,.59c.1,.21,.36,.3,.58,.2l1.61-.79s.09-.02,.13,0l3.76,2.53c.08,.06,.07,.18-.01,.23l-.67,.34c-.21,.11-.3,.37-.19,.58l.29,.59c.11,.22,.36,.3,.57,.2l1.1-.56-3.21,4.99,.06-1.26c.01-.24-.17-.45-.41-.47l-.65-.04c-.24-.02-.44,.17-.46,.41l-.04,.76c0,.1-.12,.16-.2,.1l-3.86-2.6s-.06-.07-.06-.12l.12-1.78c.02-.24-.16-.45-.4-.46l-.66-.05c-.24-.02-.45,.16-.47,.4l-.06,.75c0,.1-.12,.15-.21,.1-1.28-.88-2.6-1.7-3.82-2.66-.04-.03-.06-.07-.05-.12l.18-1.8c.02-.24-.15-.46-.38-.49l-.65-.08c-.24-.03-.45,.14-.48,.38l-.08,.76c-.01,.1-.13,.15-.21,.09l-3.73-2.79s-.06-.07-.05-.12l.22-1.77c.03-.24-.14-.45-.38-.48l-.66-.08c-.24-.03-.46,.14-.49,.37l-.09,.75c-.01,.1-.13,.15-.21,.09l-1.86-1.39-1.8-1.48s-.05-.08-.05-.12l.08-.54,3.72-4.63,.5-.19Z" />
				<g>
					<path d="M298.57,1628.58l3.83,2.86c.07,.05,.05,.16-.03,.18-3.31,1.02-5.69,2.52-6.89,3.4l-4.39-3.28c1.37-.91,3.94-2.34,7.39-3.18,.03,0,.06,0,.09,.02Z" />
					<path d="M309.63,1636.43l3.96,2.67c.07,.05,.06,.15-.02,.18-3.25,1.19-5.54,2.8-6.69,3.75l-4.54-3.06c1.32-.98,3.81-2.55,7.2-3.55,.03,0,.06,0,.09,.01Z" />
					<path d="M293.15,1624.49c1.23,1.01,2.54,1.92,3.81,2.89,.07,.05,.05,.15-.03,.18-3.37,.98-5.8,2.43-7.03,3.29-.9-.66-1.73-1.41-2.6-2.11l2.77-3.44c.91-.32,1.91-.6,3-.83,.03,0,.06,0,.09,.02Z" />
					<path d="M309.89,1645.05l-1.77-1.19c.88-.68,2.29-1.65,4.16-2.53l-2.39,3.71Z" />
					<path d="M304.01,1632.64l3.96,2.67c.07,.05,.06,.15-.02,.18-3.31,1.15-5.66,2.72-6.84,3.64-1.54-.99-2.96-2.14-4.43-3.22,1.34-.94,3.85-2.41,7.24-3.29,.03,0,.06,0,.09,.01Z" />
				</g>
				<g>
					<path d="M308.28,1623.29l-3.68-2.75s-.04-.05-.04-.08c-.1-3.48,.7-6.24,1.27-7.75l3.99,2.98c-.53,1.38-1.35,4.04-1.38,7.52,0,.08-.09,.13-.16,.08Z" />
					<path d="M319.05,1630.65l-3.81-2.57s-.04-.04-.04-.08c-.28-3.47,.38-6.27,.86-7.82l4.13,2.78c-.45,1.41-1.14,4.11-.99,7.59,0,.08-.09,.14-.15,.09Z" />
					<path d="M303.05,1619.38c-1.22-.93-2.47-1.81-3.66-2.78-.02-.02-.04-.05-.04-.08,0-1.12,.1-2.16,.25-3.1l2.77-3.44c.79,.64,1.54,1.32,2.36,1.92-.56,1.36-1.43,3.98-1.52,7.4,0,.08-.1,.13-.16,.08Z" />
					<path d="M321.31,1623.73l1.61,1.08-2.39,3.71c.1-2.07,.48-3.73,.78-4.8Z" />
					<path d="M313.64,1627l-3.81-2.57s-.04-.04-.04-.07c-.16-3.51,.59-6.32,1.12-7.86,1.34,.98,2.63,2.03,4.03,2.93-.48,1.39-1.22,4.05-1.14,7.48,0,.08-.09,.13-.16,.09Z" />
				</g>
			</g>
			<g>
				<path d="M277.65,1599.35s.09,0,.12,.03c1.07,1.07,2.11,2.16,3.2,3.2,.07,.07,.04,.19-.05,.22l-.73,.22c-.23,.07-.36,.31-.29,.53l.19,.62c.07,.23,.31,.35,.54,.28l1.72-.53s.09,0,.13,.03l3.34,3.06c.07,.07,.05,.19-.05,.22l-.72,.23c-.23,.07-.35,.32-.28,.54l.2,.62c.07,.23,.31,.35,.54,.28l1.69-.56s.09,0,.13,.03c1.12,1.01,2.2,2.07,3.39,3,.08,.06,.05,.19-.04,.22l-.71,.26c-.23,.08-.35,.33-.27,.55l.22,.61c.08,.22,.33,.34,.56,.25l1.68-.62s.09,0,.13,.02l3.49,2.89c.08,.06,.06,.19-.04,.22l-.7,.27c-.22,.09-.34,.34-.25,.56l.23,.61c.08,.22,.33,.34,.55,.25l1.15-.45-3.72,4.63,.19-1.24c.04-.24-.12-.46-.36-.5l-.65-.11c-.24-.04-.46,.12-.5,.36l-.12,.75c-.02,.1-.13,.14-.21,.08l-3.59-2.97s-.05-.08-.05-.12l.31-1.76c.04-.23-.12-.46-.36-.5l-.66-.12c-.24-.04-.47,.11-.51,.35l-.13,.74c-.02,.1-.14,.14-.22,.08-1.22-.96-2.33-2.05-3.48-3.09-.04-.03-.05-.08-.04-.13l.37-1.76c.05-.24-.1-.47-.33-.52l-.64-.14c-.23-.05-.47,.1-.52,.33l-.16,.74c-.02,.1-.14,.14-.22,.07l-3.44-3.14s-.05-.08-.04-.13l.4-1.73c.05-.23-.09-.46-.33-.52l-.65-.15c-.24-.05-.47,.09-.53,.32l-.17,.73c-.02,.1-.15,.13-.22,.06-1.12-1.08-2.19-2.2-3.29-3.29-.03-.03-.05-.08-.03-.13l.14-.53,4.19-4.21,.52-.14Z" />
				<g>
					<path d="M277.74,1611.19l3.53,3.23c.06,.06,.03,.16-.05,.18-3.41,.68-5.94,1.92-7.23,2.67l-4.04-3.7c1.46-.76,4.18-1.92,7.7-2.39,.03,0,.06,0,.09,.03Z" />
					<path d="M287.92,1620.16l3.68,3.05c.06,.05,.04,.16-.04,.18-3.36,.85-5.82,2.22-7.06,3.04l-4.22-3.5c1.42-.84,4.07-2.14,7.55-2.79,.03,0,.06,0,.09,.02Z" />
					<path d="M272.82,1606.51c1.12,1.14,2.24,2.27,3.43,3.33,.06,.06,.03,.16-.05,.17-3.48,.64-6.01,1.78-7.31,2.49l-2.36-2.38,3.12-3.13c.94-.22,1.97-.4,3.08-.51,.03,0,.06,0,.08,.03Z" />
					<path d="M287.3,1628.74l-1.64-1.36c.95-.59,2.46-1.4,4.41-2.08l-2.77,3.44Z" />
					<path d="M282.75,1615.77c1.16,1.1,2.4,2.09,3.63,3.11,.06,.05,.04,.16-.04,.17-3.42,.8-5.94,2.14-7.19,2.91l-4.05-3.7c1.43-.79,4.1-2,7.57-2.52,.03,0,.06,0,.09,.03Z" />
				</g>
				<g>
					<path d="M287.98,1606.93l-3.39-3.1s-.03-.05-.03-.08c.26-3.46,1.34-6.11,2.06-7.55l3.68,3.36c-.67,1.32-1.76,3.87-2.15,7.31,0,.08-.11,.12-.17,.07Z" />
					<path d="M297.91,1615.38l-3.54-2.93s-.04-.05-.04-.08c.08-3.47,1.02-6.18,1.67-7.66l3.84,3.17c-.6,1.35-1.56,3.96-1.77,7.42,0,.08-.1,.13-.16,.08Z" />
					<path d="M283.17,1602.53c-1.15-1.01-2.23-2.1-3.3-3.19-.02-.02-.03-.05-.03-.08,.12-1.11,.32-2.13,.57-3.05l3.12-3.13,2.14,2.16c-.71,1.3-1.9,3.86-2.33,7.23-.01,.08-.11,.12-.17,.06Z" />
					<path d="M300.88,1608.74l1.49,1.24-2.77,3.44c.31-2.04,.86-3.65,1.28-4.68Z" />
					<path d="M292.89,1611.22c-1.18-.98-2.38-1.93-3.49-2.99-.02-.02-.03-.05-.03-.08,.21-3.51,1.24-6.21,1.93-7.68l3.67,3.36c-.65,1.35-1.64,3.9-1.92,7.32,0,.08-.1,.12-.17,.07Z" />
				</g>
			</g>
			<g>
				<path d="M260.06,1579.89s.09,0,.12,.05l2.86,3.51c.06,.08,.02,.2-.08,.21l-.74,.13c-.23,.04-.39,.27-.35,.5l.11,.65c.04,.24,.26,.39,.49,.35l1.74-.32s.09,0,.12,.04l3.03,3.37c.07,.07,.03,.19-.07,.21l-.74,.16c-.23,.05-.39,.28-.34,.51l.13,.64c.05,.23,.28,.38,.51,.33l1.74-.38s.09,0,.12,.04l3.03,3.37c.07,.07,.03,.2-.07,.22l-.73,.17c-.23,.05-.38,.29-.33,.52l.14,.64c.05,.24,.28,.38,.52,.32l1.74-.44s.09,0,.13,.03l3.19,3.21c.07,.07,.04,.19-.06,.22l-.73,.2c-.23,.06-.37,.3-.31,.53l.16,.63c.06,.23,.3,.37,.53,.3l1.2-.33-4.19,4.21,.32-1.21c.06-.23-.08-.47-.31-.53l-.64-.18c-.23-.06-.47,.07-.53,.3l-.2,.73c-.03,.1-.15,.13-.22,.06l-3.28-3.31s-.05-.08-.03-.13l.49-1.71c.06-.23-.07-.47-.29-.54l-.62-.2c-.23-.07-.47,.05-.54,.29l-.22,.73c-.03,.1-.15,.13-.22,.05l-3.12-3.46s-.04-.08-.03-.13l.55-1.71c.07-.23-.05-.47-.28-.55l-.63-.21c-.23-.08-.48,.05-.55,.27l-.23,.72c-.03,.1-.16,.12-.22,.05l-3.11-3.46s-.04-.09-.02-.13l.61-1.7c.08-.23-.03-.48-.26-.56l-.61-.23c-.22-.08-.47,.03-.55,.26l-.26,.72c-.03,.1-.16,.12-.22,.04l-2.95-3.61s-.04-.09-.02-.13l.19-.51,4.62-3.74,.53-.09Z" />
				<g>
					<path d="M258.86,1591.7l3.2,3.55c.06,.06,.02,.16-.06,.17-3.48,.32-6.13,1.29-7.49,1.9l-1.83-2.04-1.74-2.12c.76-.29,1.84-.65,3.16-.94,1.32-.28,2.89-.5,4.69-.57,.03,0,.06,.01,.08,.04Z" />
					<path d="M268.04,1601.7l3.37,3.39c.06,.06,.03,.16-.06,.17-3.45,.5-6.04,1.61-7.37,2.29-1.27-1.32-2.62-2.56-3.82-3.94,.74-.33,1.8-.74,3.11-1.1,1.33-.38,2.91-.7,4.68-.85,.03,0,.06,0,.08,.03Z" />
					<path d="M254.52,1586.48l3.02,3.7c.05,.07,.01,.16-.07,.17-3.45,.2-6.11,1.09-7.48,1.66l-2.12-2.6,3.43-2.78c.96-.12,2.01-.2,3.14-.19,.03,0,.06,.01,.08,.04Z" />
					<path d="M266.54,1610.13l-1.5-1.51c1.01-.49,2.6-1.14,4.62-1.62l-3.12,3.13Z" />
					<path d="M263.4,1596.74c1.08,1.17,2.11,2.39,3.23,3.53,.06,.06,.02,.16-.06,.17-3.49,.43-6.05,1.41-7.4,2.07l-3.67-4.08c1.51-.64,4.3-1.56,7.82-1.73,.03,0,.06,.01,.08,.03Z" />
				</g>
				<g>
					<path d="M269.53,1588.52l-3.07-3.41s-.03-.05-.02-.08c.29-1.69,.81-3.19,1.36-4.43,.55-1.24,1.11-2.22,1.56-2.91l1.58,1.92,1.67,1.85c-.8,1.23-2.15,3.65-2.89,7.01-.02,.08-.12,.11-.17,.05Z" />
					<path d="M278.51,1597.97l-3.23-3.26s-.03-.05-.03-.08c.22-1.72,.63-3.22,1.09-4.47,.48-1.27,1-2.28,1.4-2.99,1.09,1.26,2.32,2.38,3.47,3.57-.74,1.28-1.96,3.77-2.53,7.17-.01,.08-.11,.12-.17,.06Z" />
					<path d="M265.19,1583.66l-2.9-3.56s-.03-.05-.02-.08c.23-1.08,.54-2.07,.88-2.96l3.43-2.78,1.92,2.36c-.84,1.22-2.27,3.61-3.14,6.98-.02,.08-.12,.11-.17,.04Z" />
					<path d="M282.16,1591.7l1.36,1.37-3.12,3.13c.52-1.99,1.23-3.53,1.75-4.5Z" />
					<path d="M273.92,1593.34c-1.08-1.09-2.06-2.26-3.1-3.39-.02-.02-.03-.05-.02-.08,.56-3.45,1.87-6.02,2.71-7.41l3.33,3.7c-.76,1.25-2.11,3.76-2.74,7.12-.02,.08-.12,.11-.17,.06Z" />
				</g>
			</g>
			<g>
				<path d="M244.62,1558.68s.09,.02,.12,.06l2.52,3.76c.06,.08,0,.2-.1,.2l-.75,.06c-.24,.02-.42,.22-.4,.46l.04,.65c.02,.24,.22,.42,.46,.4l1.77-.14s.09,.02,.12,.06c.83,1.26,1.67,2.52,2.59,3.72,.06,.08,0,.19-.09,.2l-.75,.08c-.24,.03-.41,.24-.39,.47l.07,.64c.02,.24,.24,.41,.48,.38l1.78-.2s.09,.01,.12,.05l2.69,3.64c.06,.08,0,.2-.09,.21l-.74,.09c-.24,.03-.41,.25-.38,.48l.07,.65c.03,.24,.24,.41,.48,.38l1.75-.23s.09,.01,.12,.05c.87,1.24,1.87,2.37,2.81,3.55,.06,.08,.02,.19-.08,.21l-.75,.12c-.24,.04-.4,.26-.36,.49l.1,.64c.04,.23,.26,.39,.5,.36l1.23-.2-4.62,3.74,.44-1.17c.09-.22-.03-.47-.25-.56l-.62-.24c-.23-.09-.48,.02-.56,.25l-.27,.7c-.04,.09-.16,.11-.23,.03-.97-1.21-2-2.38-2.89-3.65-.03-.04-.03-.09-.02-.13l.69-1.67c.09-.22,0-.48-.23-.57l-.6-.26c-.22-.1-.47,0-.57,.23l-.3,.7c-.04,.09-.16,.11-.23,.03l-2.77-3.74s-.03-.09-.02-.13l.72-1.63c.1-.22,0-.47-.23-.57l-.61-.27c-.22-.1-.48,0-.58,.22l-.31,.69c-.04,.09-.17,.1-.23,.02-.95-1.23-1.8-2.53-2.66-3.82-.03-.04-.03-.09-.01-.13l.78-1.62c.1-.22,.02-.48-.2-.58l-.59-.29c-.22-.11-.48-.02-.58,.2l-.33,.68c-.04,.09-.17,.1-.23,.01l-2.59-3.87s-.03-.09,0-.13l.24-.48,4.99-3.23,.54-.03Z" />
				<g>
					<path d="M242.2,1570.28c.86,1.35,1.86,2.59,2.79,3.88,.05,.07,0,.16-.08,.16-3.51-.04-6.26,.65-7.68,1.12l-3.05-4.55c1.58-.43,4.45-.97,7.95-.65,.03,0,.06,.02,.08,.05Z" />
					<path d="M250.22,1581.23l3.02,3.7c.05,.07,0,.16-.07,.16-3.5,.14-6.2,.97-7.6,1.51-1.19-1.39-2.21-2.91-3.32-4.36,1.55-.51,4.39-1.2,7.89-1.06,.03,0,.06,.02,.08,.04Z" />
					<path d="M238.42,1564.64l2.66,3.97c.05,.07,0,.16-.09,.16-3.47-.15-6.22,.45-7.65,.87l-1.86-2.79,3.71-2.4c.97-.02,2.03,.01,3.15,.13,.03,0,.06,.02,.08,.05Z" />
					<path d="M247.87,1589.42l-1.35-1.65c1.06-.38,2.71-.86,4.78-1.13l-3.43,2.78Z" />
					<path d="M246.18,1575.77l2.84,3.84c.05,.07,0,.16-.08,.16-3.47,.02-6.18,.77-7.58,1.27l-3.26-4.41c1.58-.48,4.45-1.11,7.99-.91,.03,0,.06,.02,.08,.04Z" />
				</g>
				<g>
					<path d="M253.14,1568.26c-.89-1.24-1.86-2.43-2.68-3.73-.02-.03-.02-.06-.01-.09,1.04-3.36,2.68-5.74,3.7-7l2.77,4.14c-.93,1.14-2.52,3.39-3.59,6.65-.03,.08-.13,.1-.18,.03Z" />
					<path d="M261.07,1578.61l-2.91-3.56s-.03-.05-.02-.08c.86-3.41,2.39-5.88,3.34-7.2,1,1.32,1.93,2.7,3.01,3.96-.86,1.19-2.34,3.53-3.25,6.84-.02,.08-.12,.1-.17,.04Z" />
					<path d="M249.38,1562.93l-2.55-3.82s-.02-.06-.01-.09c.34-1.05,.74-1.99,1.17-2.84l3.71-2.4,1.69,2.53c-.96,1.12-2.63,3.34-3.83,6.59-.03,.08-.13,.09-.18,.03Z" />
					<path d="M265.35,1572.78l1.22,1.5-3.43,2.78c.72-1.91,1.59-3.36,2.21-4.28Z" />
					<path d="M257.01,1573.5l-2.73-3.69s-.02-.06-.02-.09c.91-3.36,2.47-5.77,3.45-7.05l2.96,4c-.9,1.17-2.46,3.48-3.49,6.79-.02,.08-.13,.1-.17,.03Z" />
				</g>
			</g>
			<g>
				<path d="M231.49,1535.96s.09,.03,.11,.07c.68,1.35,1.43,2.66,2.14,3.99,.05,.09-.02,.19-.12,.19l-.76-.02c-.24,0-.44,.18-.45,.42l-.02,.65c0,.24,.18,.44,.42,.44l1.78,.04s.09,.03,.11,.07l2.15,3.98c.05,.09-.02,.2-.12,.2h-.75c-.24,0-.43,.18-.44,.42l-.02,.66c0,.24,.18,.44,.42,.44h1.8s.09,0,.11,.05l2.34,3.88c.05,.09-.01,.19-.11,.2l-.75,.02c-.24,0-.43,.2-.43,.44v.65c.01,.24,.21,.43,.45,.42l1.78-.05s.09,.02,.11,.06c.79,1.29,1.54,2.6,2.35,3.87,.06,.08,0,.19-.1,.2l-.76,.04c-.24,.01-.43,.21-.41,.45l.04,.65c.01,.24,.22,.42,.46,.4l1.25-.07-4.99,3.23,.56-1.11c.11-.21,.02-.47-.2-.58l-.6-.3c-.22-.11-.48-.03-.59,.18l-.34,.67c-.05,.09-.17,.1-.23,.01-.84-1.31-1.61-2.65-2.42-3.98-.02-.04-.03-.09,0-.13l.86-1.58c.12-.21,.04-.48-.17-.59l-.58-.32c-.21-.12-.47-.04-.59,.17l-.36,.66c-.05,.09-.18,.09-.23,0l-2.4-3.99s-.03-.09,0-.13l.88-1.54c.12-.21,.06-.48-.15-.6l-.56-.34c-.2-.12-.47-.06-.59,.15l-.39,.66c-.05,.09-.18,.09-.23,0l-2.22-4.1s-.02-.09,0-.13l.93-1.53c.13-.2,.06-.47-.14-.6l-.56-.35c-.21-.13-.47-.07-.6,.14l-.4,.64c-.05,.09-.18,.08-.23,0-.73-1.37-1.49-2.72-2.2-4.1-.02-.04-.02-.09,0-.13l.3-.46,5.3-2.69,.53,.03Z" />
				<g>
					<path d="M227.92,1547.21c.77,1.4,1.49,2.82,2.3,4.19,.04,.07-.01,.16-.1,.15-3.47-.41-6.21-.05-7.68,.26l-2.61-4.82c1.62-.26,4.55-.51,8.01,.16,.03,0,.06,.03,.07,.05Z" />
					<path d="M234.76,1558.93l1.23,2.05c.42,.68,.88,1.33,1.31,2,.05,.07,0,.16-.09,.16-3.53-.2-6.27,.31-7.69,.69l-2.83-4.69c1.6-.35,4.51-.74,7.99-.24,.03,0,.06,.02,.07,.05Z" />
					<path d="M224.69,1541.24l2.27,4.2c.04,.07-.02,.16-.1,.15-3.46-.51-6.27-.19-7.73,.09-.52-.99-1.09-1.95-1.56-2.96l3.94-2c.95,.09,1.99,.24,3.11,.47,.03,0,.06,.03,.07,.05Z" />
					<path d="M231.48,1566.86l-1.18-1.77c1.09-.27,2.8-.58,4.89-.63l-3.71,2.4Z" />
					<path d="M231.26,1553.12l2.47,4.09c.04,.07-.01,.16-.1,.15-3.47-.33-6.25,.13-7.7,.48-.92-1.58-1.93-3.1-2.77-4.73,.8-.15,1.92-.3,3.28-.34,1.37-.05,2.99,0,4.75,.28,.03,0,.06,.02,.07,.05Z" />
				</g>
				<g>
					<path d="M238.94,1546.39c-.77-1.32-1.47-2.69-2.21-4.03-.01-.03-.02-.06,0-.09,1.36-3.22,3.23-5.41,4.37-6.56l2.37,4.38c-1.04,1.04-2.93,3.15-4.35,6.28-.03,.07-.14,.08-.18,.01Z" />
					<path d="M245.75,1557.51c-.42-.64-.86-1.26-1.26-1.92l-1.19-1.97s-.02-.06,0-.09c1.2-3.29,2.96-5.58,4.04-6.79l2.57,4.27c-1,1.1-2.76,3.3-3.98,6.47-.03,.08-.13,.09-.18,.02Z" />
					<path d="M235.82,1540.67l-2.19-4.04s-.02-.06,0-.09c.43-1,.94-1.9,1.47-2.71l3.94-2c.42,.93,.95,1.8,1.42,2.7-1.07,1.01-2.95,3.04-4.46,6.13-.04,.07-.14,.08-.18,0Z" />
					<path d="M250.61,1552.18l1.08,1.61-3.71,2.4c.91-1.82,1.92-3.17,2.63-4.01Z" />
					<path d="M242.31,1551.98l-2.37-3.93s-.02-.06,0-.09c.62-1.62,1.37-2.98,2.12-4.09,.77-1.12,1.51-1.98,2.07-2.57,.75,1.48,1.68,2.86,2.52,4.3-1.02,1.07-2.8,3.2-4.15,6.37-.03,.08-.14,.09-.18,.02Z" />
				</g>
			</g>
			<g>
				<path d="M220.85,1511.96s.08,.04,.1,.08c.55,1.41,1.01,2.85,1.64,4.22,.04,.09-.04,.19-.14,.18l-.75-.1c-.24-.03-.46,.13-.49,.37l-.08,.64c-.03,.23,.14,.45,.38,.48l1.78,.22s.09,.04,.1,.08l1.78,4.17c.04,.09-.04,.19-.14,.18l-.75-.08c-.24-.03-.45,.14-.48,.38l-.08,.65c-.03,.24,.14,.45,.38,.48l1.76,.19s.09,.03,.1,.08c.57,1.4,1.23,2.76,1.89,4.12,.04,.09-.03,.19-.13,.18l-.76-.06c-.24-.02-.45,.16-.47,.39l-.05,.65c-.02,.24,.16,.44,.4,.46l1.78,.13s.09,.03,.11,.07l1.97,4.08c.04,.09-.03,.19-.13,.19l-.75-.04c-.24-.01-.44,.17-.46,.41l-.05,.66c-.02,.24,.16,.44,.4,.46l1.23,.07-5.3,2.69,.69-1.05c.13-.2,.08-.47-.12-.61l-.54-.37c-.2-.13-.47-.08-.6,.12l-.42,.64c-.06,.09-.18,.08-.23-.02l-2.02-4.19s-.02-.09,0-.13l1.01-1.48c.14-.2,.08-.47-.12-.6l-.55-.38c-.2-.14-.47-.09-.61,.11l-.43,.62c-.06,.08-.18,.07-.23-.02-.68-1.39-1.36-2.79-1.94-4.23-.02-.04-.01-.09,.01-.13l1.06-1.46c.14-.19,.1-.47-.09-.61l-.53-.39c-.19-.14-.46-.1-.61,.09l-.45,.61c-.06,.08-.19,.07-.23-.03l-1.83-4.28s-.01-.09,.02-.13l1.08-1.42c.14-.19,.11-.46-.09-.61l-.53-.4c-.19-.15-.47-.11-.61,.08l-.46,.6c-.06,.08-.19,.06-.23-.03-.64-1.41-1.12-2.89-1.68-4.34-.02-.04,0-.09,.02-.13l.34-.42,5.54-2.13,.53,.08Z" />
				<g>
					<path d="M216.1,1522.8l1.88,4.39c.03,.08-.03,.16-.11,.14-3.38-.78-6.19-.69-7.67-.53l-2.15-5.03c1.64-.1,4.59-.04,7.99,.97,.03,0,.05,.03,.07,.06Z" />
					<path d="M221.7,1535.15l2.08,4.3c.04,.08-.03,.16-.11,.15-3.41-.61-6.2-.38-7.67-.14l-2.38-4.93c1.63-.18,4.57-.28,8.01,.57,.03,0,.06,.03,.07,.06Z" />
					<path d="M213.52,1516.53c.53,1.5,1.16,2.97,1.79,4.43,.03,.08-.04,.16-.12,.14-3.41-.85-6.25-.81-7.71-.7l-1.18-3.13,4.12-1.58c.95,.18,1.96,.44,3.02,.79,.03,0,.05,.03,.06,.06Z" />
					<path d="M217.58,1542.72l-.93-1.92c1.1-.15,2.81-.27,4.87-.08l-3.94,2Z" />
					<path d="M218.77,1529.04c.65,1.45,1.37,2.87,2.06,4.31,.04,.08-.03,.16-.11,.14-3.44-.68-6.26-.5-7.75-.3-.79-1.64-1.46-3.34-2.19-5.01,1.63-.13,4.55-.15,7.92,.8,.03,0,.05,.03,.07,.06Z" />
				</g>
				<g>
					<path d="M227.18,1523.11l-1.81-4.23s0-.06,0-.09c1.66-3.05,3.74-5.03,4.99-6.06l1.96,4.59c-1.15,.93-3.2,2.81-4.97,5.79-.04,.07-.15,.07-.18,0Z" />
					<path d="M232.75,1534.91l-2-4.14s-.01-.06,0-.09c1.51-3.14,3.49-5.23,4.69-6.31l2.17,4.49c-1.1,.99-3.06,2.98-4.68,6.05-.04,.07-.14,.08-.18,0Z" />
					<path d="M224.61,1517.12c-.61-1.41-1.21-2.81-1.73-4.26-.01-.03,0-.06,0-.09,.56-.96,1.17-1.81,1.77-2.56l4.12-1.58,1.07,2.86c-1.2,.91-3.26,2.73-5.06,5.64-.04,.07-.15,.06-.18-.01Z" />
					<path d="M238.2,1530.08l.84,1.75-3.94,2c1.13-1.73,2.29-2.98,3.1-3.75Z" />
					<path d="M229.91,1529.03c-.66-1.38-1.35-2.75-1.98-4.15-.01-.03-.01-.06,0-.09,1.63-3.12,3.69-5.16,4.92-6.22,.67,1.52,1.27,3.08,1.99,4.57-1.12,.95-3.1,2.88-4.76,5.89-.04,.07-.14,.07-.18,0Z" />
				</g>
			</g>
			<g>
				<path d="M212.68,1487.01s.08,.05,.09,.09l1.2,4.37c.03,.1-.06,.19-.16,.16l-.73-.18c-.23-.06-.47,.09-.53,.32l-.17,.64c-.06,.23,.08,.47,.31,.52l1.72,.41s.08,.04,.1,.09l1.4,4.31c.03,.09-.05,.19-.15,.17l-.74-.16c-.23-.05-.46,.1-.52,.33l-.14,.64c-.05,.23,.1,.46,.33,.51l1.74,.36s.08,.04,.1,.09l1.4,4.31c.03,.09-.05,.19-.15,.17l-.73-.14c-.23-.04-.46,.11-.51,.34l-.13,.64c-.04,.23,.12,.46,.36,.5l1.77,.31s.08,.04,.1,.08l1.59,4.24c.04,.09-.05,.19-.14,.17l-.74-.12c-.24-.04-.46,.12-.5,.36l-.11,.64c-.04,.24,.12,.46,.35,.49l1.23,.19-5.54,2.13,.79-.98c.15-.19,.12-.46-.06-.61l-.51-.42c-.19-.15-.46-.13-.61,.06l-.48,.59c-.06,.08-.19,.06-.22-.04l-1.63-4.36s0-.09,.02-.13l1.14-1.36c.15-.18,.13-.45-.06-.61l-.49-.43c-.18-.16-.45-.14-.61,.04l-.5,.58c-.07,.08-.19,.05-.22-.04l-1.44-4.43s0-.09,.03-.13l1.19-1.34c.16-.18,.15-.45-.03-.62l-.49-.44c-.18-.16-.45-.15-.61,.03l-.51,.56c-.07,.08-.19,.05-.22-.05l-1.44-4.43s0-.09,.03-.13l1.24-1.31c.17-.18,.16-.45,0-.62l-.47-.46c-.17-.17-.44-.16-.61,.01l-.53,.55c-.07,.07-.19,.04-.22-.06l-1.24-4.49s0-.09,.03-.13l.38-.39,5.73-1.56,.52,.13Z" />
				<g>
					<path d="M206.82,1497.33l1.47,4.54c.03,.08-.05,.15-.13,.13-3.31-1.12-6.12-1.3-7.61-1.29l-.84-2.6c-.28-.87-.5-1.75-.75-2.63,1.65,.07,4.46,.45,7.8,1.79,.03,.01,.05,.04,.06,.07Z" />
					<path d="M211.14,1510.18l1.68,4.47c.03,.08-.04,.16-.12,.13-3.35-.95-6.17-1-7.65-.91-.63-1.71-1.31-3.41-1.89-5.13,1.61,0,4.57,.2,7.92,1.38,.03,.01,.05,.03,.06,.06Z" />
					<path d="M204.94,1490.81l1.27,4.6c.02,.08-.06,.15-.13,.12-3.24-1.21-6.03-1.47-7.52-1.5l-.89-3.22,4.26-1.16c.93,.28,1.92,.63,2.95,1.09,.03,.01,.05,.04,.06,.07Z" />
					<path d="M206.31,1517.26l-.75-1.99c1.12-.04,2.83,.02,4.87,.41l-4.12,1.58Z" />
					<path d="M208.91,1503.78c.51,1.51,.94,3.05,1.53,4.53,.03,.08-.04,.15-.12,.13-3.39-1.01-6.11-1.16-7.62-1.11l-1.69-5.2c1.64,.03,4.56,.31,7.84,1.59,.03,.01,.05,.04,.06,.07Z" />
				</g>
				<g>
					<path d="M217.85,1498.75l-1.42-4.37s0-.06,.01-.09c1.91-2.85,4.29-4.65,5.63-5.54,.23,.8,.42,1.61,.68,2.4l.77,2.37c-1.23,.81-3.45,2.47-5.49,5.25-.05,.07-.15,.05-.18-.02Z" />
					<path d="M222.21,1511.05l-1.61-4.3s0-.06,0-.09c1.8-2.96,3.96-4.83,5.3-5.8,.53,1.58,1.15,3.12,1.73,4.68-1.19,.87-3.33,2.64-5.24,5.52-.05,.07-.15,.06-.18-.02Z" />
					<path d="M215.85,1492.54l-1.22-4.43s0-.06,.02-.09c.65-.9,1.33-1.68,2-2.36l4.26-1.16,.81,2.94c-1.26,.78-3.54,2.39-5.69,5.13-.05,.07-.15,.05-.18-.03Z" />
					<path d="M228.1,1506.81l.68,1.82-4.12,1.58c1.29-1.6,2.57-2.72,3.44-3.4Z" />
					<path d="M219.92,1504.94c-.57-1.42-.98-2.9-1.47-4.36,0-.03,0-.06,.01-.09,1.92-2.93,4.15-4.75,5.48-5.68l1.54,4.75c-1.2,.83-3.48,2.59-5.39,5.39-.05,.07-.15,.06-.18-.02Z" />
				</g>
			</g>
			<g>
				<path d="M207.01,1461.37s.08,.05,.08,.1l.81,4.46c.02,.1-.08,.18-.17,.15l-.71-.25c-.23-.08-.47,.04-.55,.26l-.22,.62c-.08,.22,.04,.47,.26,.55l1.68,.58s.08,.05,.08,.1c.27,1.49,.53,2.98,.89,4.44,.02,.1-.07,.18-.17,.15l-.72-.23c-.23-.07-.47,.05-.54,.28l-.2,.62c-.07,.23,.06,.47,.29,.54l1.71,.53s.08,.05,.09,.1l1.01,4.42c.02,.1-.07,.18-.17,.15l-.72-.21c-.23-.07-.47,.06-.54,.29l-.19,.63c-.07,.23,.06,.47,.29,.54l1.7,.49s.08,.05,.09,.09c.31,1.48,.76,2.92,1.15,4.38,.03,.1-.06,.18-.16,.16l-.73-.19c-.23-.06-.47,.07-.53,.3l-.17,.63c-.06,.23,.08,.46,.31,.53l1.21,.31-5.73,1.56,.88-.89c.17-.17,.17-.45,0-.62l-.47-.47c-.17-.17-.45-.17-.61,0l-.53,.54c-.07,.07-.19,.04-.22-.06-.4-1.5-.86-2.98-1.18-4.5-.01-.05,0-.09,.04-.13l1.3-1.25c.17-.17,.18-.44,.02-.62l-.45-.48c-.16-.18-.44-.18-.61-.02l-.55,.52c-.07,.07-.19,.03-.22-.07l-1.04-4.54s0-.09,.04-.13l1.31-1.21c.18-.16,.19-.44,.02-.61l-.45-.49c-.16-.18-.44-.19-.62-.03l-.56,.51c-.07,.07-.19,.03-.22-.07-.37-1.51-.64-3.04-.91-4.56,0-.05,0-.09,.04-.13l1.36-1.18c.18-.16,.2-.43,.05-.62l-.43-.5c-.16-.18-.43-.21-.61-.05l-.58,.5c-.08,.07-.2,.02-.21-.08l-.84-4.58s0-.09,.04-.12l.41-.35,5.86-.98,.51,.18Z" />
				<g>
					<path d="M200.17,1471.05c.25,1.57,.67,3.11,1.01,4.67,.02,.08-.06,.15-.14,.11-3.2-1.44-6-1.9-7.48-2.04l-.98-5.38c1.62,.23,4.47,.88,7.55,2.56,.03,.01,.05,.04,.05,.07Z" />
					<path d="M203.14,1484.28l1.27,4.6c.02,.08-.06,.15-.13,.12-3.26-1.28-6.07-1.6-7.56-1.67-.52-1.75-.86-3.54-1.29-5.31,1.63,.15,4.5,.66,7.65,2.19,.03,.01,.05,.04,.06,.07Z" />
					<path d="M198.95,1464.38l.86,4.7c.02,.08-.07,.15-.14,.11-3.12-1.52-5.89-2.06-7.37-2.24l-.6-3.29,4.36-.73c.9,.36,1.86,.81,2.84,1.37,.03,.02,.05,.04,.05,.07Z" />
					<path d="M197.67,1490.82l-.56-2.05c1.12,.08,2.83,.3,4.83,.89l-4.26,1.16Z" />
					<path d="M201.62,1477.67l1.06,4.66c.02,.08-.06,.15-.14,.12-3.19-1.37-5.97-1.77-7.45-1.87l-1.22-5.33c1.63,.19,4.52,.76,7.69,2.36,.03,.01,.05,.04,.06,.07Z" />
				</g>
				<g>
					<path d="M211,1473.57c-.33-1.5-.73-2.98-.97-4.49,0-.03,0-.06,.02-.08,2.29-2.67,4.74-4.2,6.18-4.96l.9,4.92c-1.3,.68-3.66,2.11-5.95,4.66-.05,.06-.16,.04-.17-.04Z" />
					<path d="M214.11,1486.25l-1.22-4.43s0-.06,.02-.08c2.15-2.78,4.54-4.43,5.94-5.26,.39,1.62,.69,3.26,1.17,4.85-1.27,.74-3.56,2.29-5.73,4.96-.05,.06-.15,.04-.18-.03Z" />
					<path d="M209.68,1467.18l-.83-4.52s0-.06,.02-.08c.73-.83,1.48-1.54,2.2-2.15l4.36-.73,.55,3c-1.33,.65-3.74,2.02-6.13,4.53-.06,.06-.16,.03-.17-.05Z" />
					<path d="M220.38,1482.63l.52,1.87-4.26,1.16c1.43-1.46,2.81-2.44,3.75-3.03Z" />
					<path d="M212.45,1479.93l-1.02-4.48s0-.06,.02-.08c2.18-2.71,4.57-4.3,5.98-5.09l1.11,4.87c-1.3,.71-3.64,2.21-5.92,4.83-.05,.06-.16,.04-.17-.04Z" />
				</g>
			</g>
			<g>
				<path d="M203.89,1435.29s.07,.06,.07,.11c.09,1.51,.27,3.01,.4,4.52,0,.1-.09,.17-.19,.13l-.69-.32c-.22-.1-.48,0-.58,.21l-.27,.59c-.1,.22,0,.47,.21,.57l1.62,.74s.07,.06,.08,.11l.42,4.51c0,.1-.09,.17-.19,.13l-.69-.3c-.22-.1-.47,0-.57,.22l-.27,.6c-.1,.22,0,.47,.21,.57l1.65,.7s.07,.06,.08,.1l.62,4.49c.01,.1-.09,.18-.18,.14l-.7-.28c-.22-.09-.48,.02-.57,.23l-.25,.6c-.09,.22,.02,.47,.24,.56l1.65,.66s.07,.06,.08,.1c.21,1.5,.4,3,.63,4.49,.02,.1-.08,.18-.17,.14l-.71-.26c-.22-.08-.48,.03-.56,.25l-.22,.61c-.08,.22,.04,.47,.26,.55l1.18,.43-5.86,.98,.95-.8c.18-.15,.21-.43,.05-.61l-.43-.51c-.16-.19-.43-.22-.61-.06l-.58,.48c-.08,.06-.2,.02-.21-.08-.24-1.53-.43-3.07-.65-4.61,0-.05,.01-.09,.05-.12l1.41-1.11c.19-.15,.22-.42,.08-.61l-.4-.52c-.15-.19-.42-.23-.61-.08l-.6,.47c-.08,.06-.2,.01-.21-.09l-.63-4.61s.01-.09,.05-.12l1.42-1.07c.19-.14,.24-.42,.1-.61l-.38-.53c-.14-.19-.41-.24-.6-.09l-.62,.45c-.08,.06-.2,0-.21-.09l-.43-4.63s.02-.09,.05-.12l1.46-1.04c.2-.14,.24-.41,.1-.61l-.38-.54c-.14-.2-.41-.25-.6-.11l-.62,.44c-.08,.06-.2,0-.21-.1-.13-1.54-.31-3.09-.41-4.63,0-.05,.02-.09,.06-.12l.45-.3,5.93-.4,.48,.23Z" />
				<g>
					<path d="M196.2,1444.24c.16,1.58,.28,3.17,.46,4.75,.01,.08-.08,.14-.15,.1-3.02-1.75-5.69-2.49-7.16-2.77l-.51-5.44c1.59,.39,4.38,1.31,7.3,3.29,.03,.02,.04,.05,.05,.08Z" />
					<path d="M197.86,1457.69c.21,1.58,.42,3.16,.73,4.72,.02,.08-.07,.14-.14,.11-3.16-1.59-5.86-2.2-7.33-2.41l-.75-5.41c1.61,.31,4.43,1.1,7.44,2.93,.03,.02,.04,.04,.05,.07Z" />
					<path d="M195.57,1437.5l.44,4.75c0,.08-.08,.14-.15,.1-2.98-1.82-5.69-2.62-7.15-2.94-.09-1.11-.23-2.21-.27-3.33l4.41-.3c.84,.45,1.73,1,2.67,1.64,.03,.02,.04,.05,.05,.08Z" />
					<path d="M191.7,1463.67l-.38-2.09c1.1,.19,2.79,.58,4.73,1.36l-4.36,.73Z" />
					<path d="M196.93,1450.98l.65,4.73c.01,.08-.08,.14-.15,.1-3.06-1.67-5.8-2.34-7.27-2.59-.23-1.81-.54-3.6-.67-5.42,1.59,.35,4.35,1.21,7.39,3.1,.03,.02,.04,.04,.05,.08Z" />
				</g>
				<g>
					<path d="M206.65,1447.83c-.18-1.52-.29-3.05-.44-4.58,0-.03,0-.06,.03-.08,2.51-2.43,5.09-3.71,6.59-4.32l.46,4.98c-1.37,.55-3.92,1.75-6.47,4.07-.06,.06-.16,.02-.17-.06Z" />
					<path d="M208.51,1460.75c-.3-1.5-.5-3.02-.71-4.54,0-.03,0-.06,.03-.08,2.4-2.55,4.92-3.96,6.39-4.64l.68,4.95c-1.35,.61-3.84,1.94-6.22,4.37-.06,.06-.16,.03-.17-.05Z" />
					<path d="M206.03,1441.33l-.43-4.58s0-.06,.03-.08c.79-.75,1.62-1.38,2.42-1.92l4.41-.3c.03,1.02,.16,2.03,.25,3.04-1.38,.51-3.91,1.65-6.51,3.9-.06,.05-.16,.02-.17-.06Z" />
					<path d="M215.09,1457.78l.35,1.91-4.36,.73c1.56-1.31,3.02-2.15,4.01-2.64Z" />
					<path d="M207.54,1454.3l-.63-4.56s0-.06,.03-.08c2.39-2.48,4.98-3.83,6.48-4.48,.12,1.66,.41,3.31,.62,4.96-1.36,.58-3.83,1.84-6.33,4.22-.06,.06-.16,.03-.17-.06Z" />
				</g>
			</g>
			<g>
				<path d="M203.41,1409.03s.06,.07,.06,.11c-.04,1.51-.16,3.02-.12,4.53,0,.1-.11,.16-.2,.11l-.66-.38c-.21-.12-.47-.05-.59,.15l-.33,.56c-.12,.2-.05,.47,.16,.59l1.55,.89s.07,.07,.07,.11l.03,4.53c0,.1-.11,.16-.2,.11l-.66-.37c-.21-.12-.47-.04-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l1.55,.86s.07,.06,.07,.11c-.02,1.51,.05,3.02,.14,4.53,0,.1-.1,.17-.19,.12l-.67-.35c-.21-.11-.47-.03-.58,.18l-.3,.58c-.11,.21-.03,.47,.19,.58l1.59,.82s.07,.06,.07,.11l.22,4.53c0,.1-.1,.17-.19,.12l-.67-.33c-.21-.11-.47-.02-.58,.19l-.3,.58c-.11,.21-.03,.47,.19,.58l1.11,.54-5.93,.4,1.05-.7c.2-.13,.26-.4,.13-.6l-.36-.55c-.13-.2-.4-.26-.6-.12l-.64,.42c-.08,.06-.2,0-.2-.1l-.23-4.65s.02-.09,.06-.12l1.5-.97c.2-.13,.26-.4,.13-.6l-.36-.56c-.13-.2-.4-.27-.6-.14l-.64,.41c-.08,.05-.2,0-.2-.11-.09-1.55-.16-3.1-.14-4.65,0-.05,.02-.09,.06-.12l1.54-.93c.21-.12,.28-.39,.15-.6l-.33-.57c-.12-.21-.39-.28-.59-.15l-.65,.39c-.09,.05-.2-.01-.2-.11l-.03-4.65s.02-.09,.06-.11l1.54-.89c.21-.12,.28-.38,.16-.59l-.33-.58c-.12-.21-.39-.29-.59-.17l-.65,.37c-.09,.05-.2-.01-.2-.12-.04-1.55,.08-3.1,.12-4.65,0-.05,.03-.09,.07-.11l.48-.26,5.94,.18,.46,.28Z" />
				<g>
					<path d="M194.84,1417.19l.03,4.77c0,.08-.09,.13-.16,.08-2.81-2.03-5.44-3.03-6.87-3.46l-.03-5.46c1.55,.54,4.25,1.73,6.99,3.98,.02,.02,.04,.05,.04,.08Z" />
					<path d="M195.19,1430.73l.24,4.77c0,.08-.09,.14-.16,.09-2.9-1.89-5.57-2.77-7.01-3.12l-.27-5.45c1.58,.47,4.32,1.52,7.16,3.64,.02,.02,.04,.05,.04,.08Z" />
					<path d="M194.88,1410.41c-.09,1.59-.07,3.18-.06,4.77,0,.08-.09,.13-.16,.08-2.81-2.1-5.45-3.17-6.84-3.62l.12-3.33,4.42,.13c.8,.53,1.64,1.16,2.48,1.89,.02,.02,.04,.05,.04,.08Z" />
					<path d="M188.44,1436.07l-.11-2.12c1.07,.29,2.69,.85,4.51,1.82l-4.41,.3Z" />
					<path d="M194.88,1423.96c.03,1.59,.14,3.18,.21,4.77,0,.08-.09,.14-.16,.09-2.9-1.96-5.57-2.9-7.01-3.29-.08-1.82-.04-3.64-.07-5.46,1.55,.51,4.25,1.63,6.99,3.81,.02,.02,.04,.05,.04,.08Z" />
				</g>
				<g>
					<path d="M204.92,1421.77l-.03-4.6s.01-.06,.04-.08c2.71-2.17,5.4-3.2,6.94-3.66l.03,5c-1.42,.41-4.04,1.36-6.83,3.42-.07,.05-.16,0-.16-.08Z" />
					<path d="M205.46,1434.82l-.23-4.6s.01-.06,.03-.08c2.62-2.3,5.25-3.46,6.78-4l.25,4.99c-1.4,.48-3.97,1.55-6.67,3.74-.06,.05-.16,.01-.16-.07Z" />
					<path d="M204.89,1415.24c-.02-1.53-.03-3.07,.05-4.6,0-.03,.02-.06,.04-.08,.89-.67,1.77-1.22,2.62-1.68l4.42,.13-.12,3.05c-1.46,.38-4.06,1.26-6.85,3.25-.07,.05-.16,0-.16-.08Z" />
					<path d="M212.36,1432.5l.09,1.94-4.41,.3c1.71-1.15,3.27-1.85,4.31-2.24Z" />
					<path d="M205.14,1428.3c-.07-1.53-.17-3.06-.21-4.6,0-.03,.01-.06,.04-.08,2.71-2.24,5.4-3.33,6.95-3.83,.03,1.67-.02,3.33,.06,5-1.4,.44-3.98,1.45-6.67,3.58-.06,.05-.16,0-.16-.07Z" />
				</g>
			</g>
			<g>
				<path d="M205.44,1382.84s.06,.07,.05,.12l-.57,4.5c-.01,.1-.13,.15-.21,.09l-.6-.44c-.19-.14-.46-.1-.61,.09l-.4,.52c-.14,.19-.11,.46,.08,.6l1.45,1.04s.06,.07,.06,.12l-.37,4.52c0,.1-.12,.15-.21,.1l-.62-.43c-.2-.14-.47-.09-.6,.11l-.37,.54c-.14,.2-.09,.46,.11,.6l1.47,1s.06,.07,.06,.12l-.37,4.52c0,.1-.12,.15-.21,.1l-.62-.41c-.2-.13-.47-.08-.6,.12-.13,.18-.25,.36-.36,.54-.13,.2-.07,.47,.14,.6l1.51,.97s.06,.07,.06,.12l-.17,4.53c0,.1-.12,.16-.2,.1l-.64-.4c-.2-.13-.47-.07-.6,.14l-.35,.55c-.13,.2-.07,.47,.14,.59l1.06,.65-5.94-.18,1.1-.6c.21-.11,.29-.38,.18-.59l-.31-.58c-.11-.21-.37-.29-.59-.18l-.67,.36c-.09,.05-.2-.02-.19-.12l.18-4.65s.03-.09,.07-.11l1.58-.82c.21-.11,.29-.37,.18-.59-.1-.2-.2-.39-.29-.59-.1-.22-.36-.3-.58-.2l-.69,.34c-.09,.05-.2-.02-.19-.13l.38-4.64s.03-.09,.07-.11l1.62-.78c.22-.1,.31-.36,.21-.58l-.28-.6c-.1-.22-.36-.31-.58-.21l-.68,.33c-.09,.04-.19-.03-.19-.13l.38-4.64s.03-.09,.07-.11l1.63-.74c.22-.1,.32-.35,.23-.57l-.26-.6c-.09-.22-.35-.32-.57-.22l-.7,.31c-.09,.04-.19-.03-.18-.14l.58-4.62s.04-.09,.08-.11l.5-.21,5.89,.75,.43,.32Z" />
				<g>
					<path d="M196.09,1390.12l-.39,4.76c0,.08-.1,.12-.17,.07-2.62-2.3-5.15-3.55-6.53-4.11,.15-1.81,.27-3.63,.53-5.43,1.49,.69,3.97,2.12,6.53,4.63,.02,.02,.03,.05,.03,.08Z" />
					<path d="M195.14,1403.64l-.18,4.77c0,.08-.1,.13-.16,.08-2.72-2.17-5.3-3.29-6.71-3.79,.08-1.82,.12-3.64,.23-5.45,1.48,.62,4.15,1.93,6.78,4.31,.02,.02,.04,.05,.03,.08Z" />
					<path d="M196.84,1383.39l-.59,4.74c0,.08-.1,.12-.17,.06-2.54-2.36-5.01-3.67-6.37-4.26l.42-3.31,4.38,.56c.75,.61,1.53,1.31,2.3,2.13,.02,.02,.03,.05,.03,.09Z" />
					<path d="M187.95,1408.3l.08-2.12c1.04,.4,2.61,1.11,4.34,2.25l-4.42-.13Z" />
					<path d="M195.54,1396.88c-.12,1.59-.3,3.17-.32,4.76,0,.08-.1,.13-.16,.07-2.74-2.24-5.21-3.42-6.61-3.95l.44-5.44c1.5,.66,4.09,2.03,6.63,4.47,.02,.02,.03,.05,.03,.08Z" />
				</g>
				<g>
					<path d="M205.73,1395.67l.37-4.59s.02-.06,.04-.07c2.86-1.9,5.72-2.66,7.31-2.97-.24,1.65-.34,3.32-.48,4.98-1.45,.27-4.14,.96-7.09,2.74-.07,.04-.16-.01-.15-.09Z" />
					<path d="M205.01,1408.71l.17-4.6s.02-.06,.04-.08c2.81-2.04,5.51-2.93,7.12-3.32-.11,1.66-.14,3.33-.21,5-1.44,.34-4.09,1.16-6.97,3.08-.07,.05-.16,0-.16-.08Z" />
					<path d="M206.26,1389.16l.57-4.57s.02-.06,.05-.07c.95-.58,1.87-1.04,2.75-1.42l4.38,.56-.38,3.03c-1.46,.24-4.19,.86-7.21,2.56-.07,.04-.16-.01-.15-.09Z" />
					<path d="M212.09,1407.07l-.07,1.94-4.42-.13c1.81-.98,3.42-1.52,4.49-1.81Z" />
					<path d="M205.26,1402.18c.03-1.53,.2-3.06,.31-4.59,0-.03,.02-.06,.04-.08,2.9-1.97,5.66-2.79,7.25-3.14l-.41,4.98c-1.43,.31-4.2,1.06-7.04,2.91-.07,.04-.16,0-.16-.09Z" />
				</g>
			</g>
			<g>
				<path d="M209.98,1356.96s.05,.08,.04,.12l-.96,4.43c-.02,.1-.14,.14-.22,.07l-.56-.5c-.18-.16-.45-.14-.61,.03l-.44,.49c-.16,.18-.15,.45,.03,.61l1.33,1.17s.05,.08,.04,.12c-.15,.74-.33,1.48-.47,2.22l-.38,2.24c-.02,.1-.13,.14-.21,.08l-.58-.49c-.18-.15-.46-.13-.61,.05l-.42,.5c-.15,.18-.13,.45,.06,.61l1.38,1.14s.05,.08,.05,.12l-.76,4.47c-.02,.1-.14,.14-.21,.08l-.58-.47c-.19-.15-.46-.12-.61,.06l-.42,.51c-.15,.18-.13,.45,.06,.6l1.38,1.11s.05,.07,.05,.12c-.25,1.49-.39,3-.6,4.49-.01,.1-.13,.15-.21,.09l-.6-.46c-.19-.15-.46-.11-.61,.08l-.4,.52c-.14,.19-.11,.46,.08,.6l1,.75-5.89-.75,1.15-.49c.22-.09,.32-.35,.23-.57l-.25-.61c-.09-.22-.35-.33-.57-.24l-.7,.29c-.09,.04-.19-.04-.18-.14,.21-1.54,.35-3.08,.61-4.61,0-.05,.04-.09,.08-.1l1.68-.66c.22-.09,.34-.34,.26-.56l-.23-.62c-.08-.22-.33-.34-.56-.25l-.71,.27c-.09,.04-.19-.04-.18-.14l.78-4.59s.04-.09,.08-.1l1.68-.62c.22-.08,.34-.33,.26-.56l-.23-.62c-.08-.23-.33-.35-.56-.26l-.71,.26c-.09,.03-.19-.05-.17-.15l.39-2.29c.14-.76,.32-1.52,.48-2.28,.01-.05,.04-.08,.09-.1l1.71-.57c.23-.08,.35-.32,.28-.55l-.2-.63c-.07-.23-.32-.35-.55-.28l-.72,.24c-.1,.03-.19-.05-.17-.15l.98-4.55s.04-.08,.09-.1l.51-.16,5.79,1.32,.4,.36Z" />
				<g>
					<path d="M199.95,1363.31c-.31,1.56-.55,3.13-.82,4.7-.01,.08-.11,.11-.17,.05-2.41-2.54-4.81-4.03-6.14-4.73,.31-1.79,.74-3.56,1.11-5.34,1.41,.83,3.8,2.51,6,5.23,.02,.02,.03,.06,.02,.09Z" />
					<path d="M197.69,1376.67l-.6,4.74c-.01,.08-.11,.12-.17,.06-2.52-2.42-4.99-3.8-6.34-4.42,.2-1.81,.51-3.6,.82-5.4,1.46,.76,3.88,2.32,6.26,4.94,.02,.02,.03,.05,.03,.09Z" />
					<path d="M201.38,1356.69l-1.01,4.67c-.02,.08-.12,.11-.17,.05-2.31-2.59-4.66-4.14-5.95-4.86l.7-3.26,4.31,.98c.7,.68,1.41,1.46,2.1,2.34,.02,.02,.03,.06,.02,.09Z" />
					<path d="M190.13,1380.62l.26-2.11c1,.5,2.5,1.35,4.12,2.66l-4.38-.56Z" />
					<path d="M198.79,1369.98l-.8,4.71c-.01,.08-.12,.12-.17,.06-2.42-2.48-4.84-3.91-6.17-4.57l.92-5.38c1.44,.8,3.89,2.42,6.2,5.1,.02,.02,.03,.06,.02,.09Z" />
				</g>
				<g>
					<path d="M209.03,1369.76c.27-1.51,.49-3.03,.79-4.53,0-.03,.03-.06,.05-.07,3.14-1.6,5.99-2.08,7.61-2.24-.34,1.63-.74,3.25-1.02,4.9-1.46,.13-4.2,.56-7.29,2.05-.07,.04-.16-.03-.15-.11Z" />
					<path d="M207.07,1382.68l.57-4.57s.02-.06,.05-.07c3.01-1.75,5.88-2.37,7.48-2.6-.28,1.64-.58,3.28-.75,4.94-1.46,.2-4.17,.76-7.2,2.4-.07,.04-.16-.02-.15-.1Z" />
					<path d="M210.23,1363.34l.97-4.5s.03-.05,.05-.07c.99-.49,1.95-.86,2.86-1.15l4.31,.98-.64,2.99c-1.48,.09-4.24,.45-7.4,1.86-.08,.03-.16-.03-.14-.11Z" />
					<path d="M214.25,1381.73l-.24,1.93-4.38-.56c1.88-.8,3.53-1.19,4.62-1.37Z" />
					<path d="M207.93,1376.2l.77-4.54s.02-.06,.05-.07c3.05-1.68,5.88-2.24,7.48-2.43l-.84,4.93c-1.47,.16-4.22,.65-7.32,2.21-.07,.04-.16-.02-.15-.1Z" />
				</g>
			</g>
			<g>
				<path d="M216.97,1331.64s.04,.08,.03,.13l-1.34,4.33c-.03,.1-.15,.12-.22,.05l-.52-.55c-.16-.17-.44-.19-.61-.03l-.48,.44c-.18,.16-.19,.43-.02,.61l1.22,1.3s.04,.08,.03,.13l-1.33,4.33c-.03,.1-.15,.13-.22,.05l-.53-.54c-.16-.17-.44-.18-.61-.01l-.46,.46c-.17,.17-.17,.44,0,.61l1.27,1.27s.05,.08,.03,.13l-1.15,4.39c-.03,.1-.15,.13-.22,.06l-.54-.53c-.17-.17-.44-.17-.61,0l-.46,.46c-.17,.17-.17,.44,0,.61l1.28,1.24s.05,.08,.04,.12c-.37,1.47-.8,2.92-1.09,4.4-.02,.1-.14,.13-.21,.06l-.56-.51c-.18-.16-.45-.16-.61,.02l-.44,.48c-.16,.17-.15,.45,.03,.61l.92,.84-5.79-1.32,1.19-.37c.23-.07,.35-.32,.28-.55l-.2-.63c-.07-.23-.32-.36-.54-.29l-.72,.22c-.1,.03-.19-.06-.17-.16,.3-1.52,.74-3.01,1.12-4.51,.01-.05,.05-.08,.09-.1l1.73-.49c.23-.07,.37-.31,.3-.54l-.17-.64c-.06-.23-.3-.37-.53-.3l-.73,.2c-.1,.03-.19-.06-.16-.16l1.18-4.5s.05-.08,.09-.09l1.72-.46c.23-.06,.37-.3,.3-.53l-.17-.64c-.06-.24-.29-.37-.53-.32l-.73,.19c-.1,.03-.19-.07-.16-.16l1.37-4.45s.05-.08,.1-.09l1.75-.41c.23-.05,.38-.29,.33-.52l-.14-.64c-.05-.23-.28-.38-.52-.33l-.74,.17c-.1,.02-.18-.07-.15-.17l1.38-4.44s.05-.08,.1-.09l.52-.11,5.64,1.87,.37,.4Z" />
				<g>
					<path d="M206.44,1337.01c-.47,1.52-.96,3.03-1.34,4.58-.02,.08-.12,.1-.18,.04-2.19-2.77-4.37-4.47-5.61-5.29l1.62-5.21c1.32,.96,3.55,2.87,5.5,5.8,.02,.03,.02,.06,.01,.09Z" />
					<path d="M202.88,1350.08c-.43,1.53-.74,3.09-1.08,4.65-.02,.08-.12,.11-.17,.04-2.28-2.66-4.64-4.27-5.9-5.02l1.38-5.28c1.37,.9,3.68,2.69,5.76,5.52,.02,.02,.02,.06,.02,.09Z" />
					<path d="M208.45,1330.54l-1.41,4.56c-.02,.08-.13,.1-.18,.03-2.06-2.81-4.26-4.58-5.49-5.42l.99-3.19,4.19,1.39c.63,.74,1.27,1.59,1.88,2.54,.02,.03,.02,.06,.01,.09Z" />
					<path d="M194.95,1353.28l.45-2.07c.95,.59,2.36,1.59,3.86,3.05l-4.31-.98Z" />
					<path d="M204.6,1343.53l-1.21,4.62c-.02,.08-.12,.1-.18,.04-2.19-2.7-4.46-4.36-5.73-5.15l1.39-5.28c1.35,.93,3.65,2.79,5.71,5.68,.02,.03,.02,.06,.02,.09Z" />
				</g>
				<g>
					<path d="M214.8,1344.29c.36-1.49,.83-2.95,1.29-4.42,0-.03,.03-.05,.06-.06,3.26-1.29,6.14-1.5,7.76-1.5l-1.48,4.78c-1.48-.01-4.31,.13-7.49,1.32-.08,.03-.16-.04-.14-.12Z" />
					<path d="M211.61,1356.96c.32-1.5,.62-3,1.04-4.48,0-.03,.03-.05,.06-.07,3.2-1.45,6.07-1.79,7.69-1.87l-1.27,4.84c-1.5,.05-4.22,.35-7.38,1.69-.08,.03-.16-.03-.14-.11Z" />
					<path d="M216.66,1338.03l1.36-4.4s.03-.05,.06-.06c1.03-.39,2.02-.67,2.94-.87l4.19,1.39-.9,2.92c-1.47-.05-4.25,.04-7.52,1.14-.08,.03-.15-.04-.13-.12Z" />
					<path d="M218.83,1356.7l-.41,1.9-4.31-.98c1.94-.62,3.62-.84,4.72-.92Z" />
					<path d="M213.14,1350.61l1.17-4.45s.03-.05,.06-.07c3.18-1.38,6.03-1.67,7.65-1.71l-1.27,4.84c-1.48,.02-4.25,.25-7.47,1.5-.08,.03-.16-.04-.14-.12Z" />
				</g>
			</g>
			<g>
				<path d="M226.4,1307.12s.03,.09,.01,.13c-.64,1.37-1.17,2.79-1.76,4.18-.04,.09-.16,.11-.22,.03l-.46-.6c-.15-.19-.42-.23-.61-.08l-.52,.4c-.19,.14-.22,.42-.08,.6l1.09,1.41s.04,.09,.02,.13l-1.72,4.19c-.04,.09-.16,.11-.23,.03l-.47-.59c-.15-.19-.42-.22-.61-.07l-.52,.4c-.19,.15-.22,.42-.08,.6l1.11,1.38s.04,.09,.02,.13l-1.53,4.27c-.03,.09-.16,.11-.22,.04l-.49-.58c-.15-.18-.43-.21-.61-.06l-.5,.42c-.18,.15-.2,.42-.05,.61l1.16,1.36s.04,.08,.02,.13l-1.53,4.27c-.03,.09-.16,.12-.22,.04l-.49-.56c-.16-.18-.43-.2-.61-.04l-.5,.43c-.18,.16-.21,.43-.04,.61l.84,.93-5.64-1.87,1.21-.26c.23-.05,.39-.28,.34-.51l-.12-.64c-.04-.23-.27-.39-.51-.34l-.75,.15c-.1,.02-.18-.07-.15-.17l1.57-4.38s.05-.08,.1-.09l1.76-.33c.24-.04,.39-.27,.35-.51l-.12-.65c-.04-.24-.27-.4-.5-.35l-.74,.13c-.1,.02-.18-.08-.15-.18l1.57-4.38s.05-.08,.1-.08l1.79-.28c.24-.04,.41-.26,.37-.49l-.09-.65c-.03-.24-.25-.4-.49-.36l-.75,.11c-.1,.02-.18-.08-.14-.18l1.77-4.3s.06-.07,.1-.08l1.77-.24c.24-.03,.4-.25,.37-.49l-.08-.66c-.03-.24-.25-.41-.48-.38l-.75,.1c-.1,.01-.18-.09-.14-.18,.6-1.43,1.15-2.88,1.8-4.29,.02-.04,.06-.07,.11-.08l.54-.06,5.43,2.4,.32,.43Z" />
				<g>
					<path d="M215.42,1311.45l-1.81,4.42c-.03,.08-.14,.09-.18,.02-1.82-2.94-3.87-4.85-5.02-5.78l2.07-5.05c1.23,1.09,3.27,3.2,4.94,6.31,.01,.03,.02,.06,0,.09Z" />
					<path d="M210.63,1324.12c-.52,1.5-1.11,2.98-1.58,4.5-.02,.08-.13,.1-.18,.03-2.02-2.87-4.11-4.66-5.32-5.54l1.85-5.14c1.28,1.03,3.41,3.04,5.23,6.06,.02,.03,.02,.06,0,.09Z" />
					<path d="M218,1305.19l-1.81,4.41c-.03,.08-.14,.09-.18,.01-1.79-3-3.82-4.97-4.96-5.94,.42-1.03,.83-2.06,1.32-3.06l4.04,1.79c.54,.8,1.06,1.68,1.59,2.69,.01,.03,.02,.06,0,.09Z" />
					<path d="M202.36,1326.52c.2-.68,.44-1.34,.68-2.01,.89,.68,2.14,1.79,3.51,3.4l-4.19-1.39Z" />
					<path d="M212.92,1317.75l-1.62,4.49c-.03,.08-.13,.09-.18,.02-1.93-2.91-4.04-4.78-5.23-5.69l.92-2.57c.32-.85,.68-1.69,1.02-2.53,1.27,1.06,3.25,3.09,5.07,6.18,.02,.03,.02,.06,0,.09Z" />
				</g>
				<g>
					<path d="M222.99,1319.49l1.75-4.26s.04-.05,.06-.06c3.35-.98,6.23-.91,7.84-.76l-1.9,4.63c-1.47-.16-4.26-.27-7.64,.58-.08,.02-.15-.06-.12-.13Z" />
					<path d="M218.59,1331.79c.46-1.46,1.02-2.89,1.53-4.34,.01-.03,.03-.05,.06-.06,3.31-1.14,6.19-1.21,7.81-1.13l-1.69,4.71c-1.47-.08-4.31-.08-7.58,.95-.08,.02-.15-.05-.13-.13Z" />
					<path d="M225.48,1313.45l1.75-4.26s.04-.05,.06-.06c1.04-.3,2.1-.46,3.05-.57l4.04,1.79c-.45,.92-.82,1.86-1.21,2.81-1.46-.19-4.22-.36-7.57,.42-.08,.02-.15-.06-.12-.13Z" />
					<path d="M225.84,1332.25c-.22,.61-.45,1.22-.63,1.84l-4.19-1.39c1.97-.44,3.72-.48,4.82-.45Z" />
					<path d="M220.77,1325.63l1.56-4.33s.03-.05,.06-.06c3.26-1.09,6.24-1.05,7.84-.94-.31,.77-.65,1.54-.94,2.32l-.84,2.35c-1.47-.12-4.24-.16-7.55,.78-.08,.02-.15-.05-.12-.13Z" />
				</g>
			</g>
			<g>
				<path d="M238.18,1283.66s.02,.09,0,.13l-2.2,3.96c-.05,.09-.17,.09-.23,0l-.39-.64c-.12-.2-.39-.27-.6-.15l-.56,.34c-.2,.12-.27,.39-.15,.59l.94,1.51s.03,.09,0,.13l-2.07,4.03c-.05,.09-.17,.09-.23,.01l-.41-.63c-.13-.2-.4-.26-.6-.13l-.55,.35c-.2,.13-.26,.4-.13,.59l.98,1.48s.03,.09,0,.13c-.71,1.33-1.33,2.71-1.97,4.08-.04,.09-.17,.1-.22,.02l-.43-.62c-.14-.2-.41-.25-.6-.11l-.53,.37c-.19,.14-.24,.4-.1,.6l1.03,1.46s.03,.09,.01,.13l-1.91,4.11c-.04,.09-.17,.1-.23,.02l-.44-.61c-.14-.19-.41-.24-.61-.1l-.54,.38c-.2,.14-.24,.41-.1,.6l.73,1-5.43-2.4,1.25-.13c.24-.02,.42-.24,.4-.48l-.06-.65c-.02-.24-.23-.41-.47-.39l-.76,.08c-.1,.01-.17-.09-.13-.18l1.96-4.22s.06-.07,.11-.08l1.78-.16c.24-.02,.41-.23,.39-.47l-.06-.66c-.02-.24-.23-.42-.47-.4l-.75,.06c-.1,0-.17-.1-.13-.19,.65-1.41,1.29-2.82,2.02-4.19,.02-.04,.06-.07,.11-.07l1.79-.11c.24-.01,.42-.22,.41-.46l-.03-.66c-.01-.24-.21-.43-.45-.41l-.76,.04c-.1,0-.17-.1-.12-.19l2.13-4.14s.07-.07,.11-.07l1.8-.06c.24,0,.43-.21,.43-.45v-.66c-.01-.24-.21-.43-.45-.42l-.76,.02c-.1,0-.17-.11-.12-.19l2.26-4.07s.07-.07,.11-.07h.54s5.16,2.94,5.16,2.94l.28,.46Z" />
				<g>
					<path d="M226.78,1286.85l-2.18,4.26c-.04,.08-.14,.07-.18,0-1.55-3.12-3.41-5.24-4.47-6.28l1.25-2.44,1.33-2.4c1.12,1.21,2.87,3.49,4.26,6.77,.01,.03,.01,.06,0,.09Z" />
					<path d="M220.82,1299.03l-2.01,4.33c-.04,.08-.14,.08-.18,0-1.68-3.02-3.64-5.04-4.75-6.02l2.3-4.95c1.18,1.15,3.12,3.36,4.64,6.55,.01,.03,.01,.06,0,.09Z" />
					<path d="M230.04,1280.89l-2.32,4.18c-.04,.07-.15,.07-.18,0-1.45-3.15-3.25-5.32-4.28-6.39l1.63-2.93,3.83,2.19c.47,.86,.92,1.81,1.33,2.86,.01,.03,0,.06,0,.09Z" />
					<path d="M212.36,1300.62l.89-1.93c.81,.76,1.99,2,3.15,3.71l-4.04-1.79Z" />
					<path d="M223.68,1292.89l-2.02,4.33c-.04,.08-.14,.08-.18,0-1.65-3.09-3.58-5.16-4.68-6.17,.75-1.66,1.64-3.26,2.46-4.9,1.14,1.18,3.01,3.43,4.42,6.65,.01,.03,.01,.06,0,.09Z" />
				</g>
				<g>
					<path d="M233.58,1295.62l2.09-4.09s.04-.05,.07-.05c3.39-.66,6.31-.26,7.89,.05l-1.21,2.17-1.13,2.21c-1.44-.3-4.19-.69-7.61-.16-.08,.01-.15-.07-.11-.14Z" />
					<path d="M228.01,1307.43l1.94-4.17s.04-.05,.07-.05c3.39-.82,6.26-.62,7.86-.38l-2.11,4.54c-1.46-.23-4.24-.48-7.65,.21-.08,.02-.15-.06-.11-.14Z" />
					<path d="M236.58,1289.83l2.23-4.01s.04-.04,.07-.05c1.09-.18,2.12-.25,3.06-.26l3.83,2.19-1.47,2.66c-1.44-.34-4.18-.8-7.62-.38-.08,.01-.14-.07-.1-.14Z" />
					<path d="M235.2,1308.59l-.82,1.76-4.04-1.79c2.05-.22,3.76-.11,4.86,.02Z" />
					<path d="M230.76,1301.51l1.94-4.17s.04-.05,.07-.05c3.43-.72,6.31-.43,7.91-.15-.74,1.49-1.55,2.93-2.24,4.45-1.45-.26-4.2-.56-7.57,.06-.08,.01-.15-.07-.11-.14Z" />
				</g>
			</g>
			<g>
				<path d="M252.41,1261.63s.01,.09-.01,.13c-.92,1.2-1.76,2.45-2.62,3.69-.06,.08-.18,.07-.23-.02l-.32-.68c-.1-.22-.36-.31-.57-.21l-.59,.28c-.21,.1-.31,.36-.2,.57l.77,1.6s.02,.09,0,.13c-.86,1.24-1.73,2.47-2.53,3.75-.05,.08-.18,.08-.23-.01l-.35-.67c-.11-.21-.37-.3-.58-.19l-.58,.29c-.21,.11-.29,.37-.19,.58l.82,1.58s.02,.09,0,.13c-.81,1.27-1.65,2.53-2.44,3.81-.05,.09-.18,.08-.23,0l-.37-.66c-.12-.21-.38-.29-.58-.18l-.57,.31c-.21,.11-.28,.38-.17,.59l.87,1.56s.02,.09,0,.13l-2.33,3.88c-.05,.09-.18,.08-.23,0l-.37-.65c-.12-.21-.38-.28-.59-.16l-.57,.32c-.21,.12-.28,.38-.16,.59l.64,1.08-5.16-2.95h1.24c.24-.02,.43-.21,.44-.45v-.66c.01-.24-.18-.43-.42-.44h-.76c-.1,0-.16-.11-.11-.2l2.39-4s.07-.07,.12-.06l1.79,.03c.24,0,.44-.19,.44-.43v-.66c.02-.24-.17-.44-.41-.45l-.75-.02c-.1,0-.16-.11-.11-.2,.81-1.32,1.67-2.62,2.51-3.93,.03-.04,.07-.06,.12-.06l1.79,.08c.24,.01,.44-.17,.46-.41l.04-.66c.01-.24-.17-.45-.41-.46l-.75-.04c-.1,0-.16-.12-.1-.2,.82-1.32,1.72-2.59,2.6-3.86,.03-.04,.07-.06,.12-.06l1.79,.14c.24,.02,.45-.16,.47-.4l.06-.66c.02-.24-.16-.45-.39-.47l-.75-.06c-.1,0-.15-.12-.1-.21,.89-1.28,1.75-2.57,2.7-3.8,.03-.04,.07-.06,.12-.05l.54,.06,4.8,3.5,.22,.49Z" />
				<g>
					<path d="M240.73,1263.55c-.93,1.3-1.76,2.66-2.63,4-.05,.07-.15,.06-.18-.02-1.22-3.28-2.85-5.6-3.79-6.75l3.12-4.52c.97,1.32,2.51,3.82,3.49,7.2,0,.03,0,.06-.01,.09Z" />
					<path d="M233.45,1275.02c-.81,1.37-1.66,2.73-2.44,4.12-.04,.07-.15,.07-.18,0-1.4-3.21-3.1-5.4-4.11-6.51l2.82-4.71c1.05,1.27,2.74,3.68,3.92,7.02,.01,.03,0,.06,0,.09Z" />
					<path d="M244.6,1257.97l-2.73,3.93c-.05,.07-.15,.05-.18-.02-1.12-3.31-2.68-5.66-3.59-6.85,.62-.93,1.3-1.82,1.97-2.72l3.57,2.6c.36,.9,.7,1.89,.98,2.97,0,.03,0,.06-.02,.09Z" />
					<path d="M224.88,1275.74c.34-.63,.71-1.24,1.07-1.85,.74,.85,1.75,2.19,2.76,4.04l-3.83-2.19Z" />
					<path d="M237.01,1269.23c-.87,1.34-1.73,2.68-2.53,4.06-.04,.07-.15,.06-.18-.01-1.33-3.26-2.99-5.51-3.95-6.63l2.97-4.62c1.01,1.3,2.63,3.75,3.7,7.12,0,.03,0,.06-.01,.09Z" />
				</g>
				<g>
					<path d="M246.54,1273.02c.84-1.28,1.63-2.59,2.52-3.83,.02-.02,.05-.04,.08-.04,3.49-.25,6.29,.42,7.84,.92l-2.83,4.09c-1.4-.46-4.08-1.13-7.51-.97-.08,0-.14-.09-.09-.15Z" />
					<path d="M239.74,1284.13c.75-1.34,1.56-2.63,2.34-3.95,.02-.03,.04-.04,.07-.05,3.45-.46,6.28,.06,7.84,.46l-2.55,4.27c-1.42-.38-4.19-.94-7.6-.58-.08,0-.14-.08-.1-.15Z" />
					<path d="M250.15,1267.6l2.61-3.77s.05-.04,.08-.04c1.11-.05,2.15,0,3.1,.1l3.57,2.6c-.6,.81-1.22,1.62-1.78,2.46-1.39-.49-4.05-1.25-7.5-1.19-.08,0-.13-.09-.09-.16Z" />
					<path d="M246.74,1286.02c-.33,.55-.67,1.1-.97,1.67l-3.83-2.19c2.03-.03,3.73,.27,4.81,.52Z" />
					<path d="M243.06,1278.53c.77-1.32,1.59-2.61,2.43-3.89,.02-.03,.04-.04,.07-.05,3.47-.35,6.29,.24,7.84,.69l-2.69,4.18c-1.42-.42-4.15-1.05-7.56-.78-.08,0-.14-.08-.1-.15Z" />
				</g>
			</g>
			<g>
				<path d="M268.98,1241.31s0,.09-.03,.13l-3.03,3.36c-.07,.07-.19,.05-.22-.05l-.24-.71c-.08-.22-.32-.35-.55-.27l-.62,.2c-.22,.08-.34,.33-.26,.55l.6,1.69s0,.09-.02,.13l-2.92,3.46c-.06,.08-.19,.05-.22-.04l-.26-.7c-.08-.22-.33-.34-.56-.26l-.62,.22c-.23,.08-.34,.33-.26,.55l.64,1.66s.01,.09-.02,.13l-2.81,3.55c-.06,.08-.19,.06-.22-.03l-.28-.7c-.09-.22-.34-.33-.56-.24l-.61,.24c-.22,.09-.33,.34-.24,.56l.68,1.64s.01,.09-.02,.13c-.92,1.19-1.8,2.42-2.71,3.63-.06,.08-.18,.06-.22-.03l-.3-.69c-.1-.22-.35-.32-.57-.23l-.6,.26c-.22,.09-.32,.35-.22,.57l.51,1.13-4.8-3.5,1.25,.13c.24,.03,.46-.15,.48-.38l.08-.66c.03-.24-.14-.45-.38-.48l-.75-.08c-.1-.01-.15-.13-.09-.21,.93-1.24,1.84-2.51,2.79-3.73,.03-.04,.08-.06,.12-.05l1.79,.24c.24,.03,.46-.13,.5-.37l.1-.65c.04-.24-.13-.46-.37-.49l-.75-.1c-.1-.01-.15-.13-.08-.21l2.89-3.65s.08-.06,.12-.05l1.77,.28c.24,.04,.46-.12,.51-.35l.12-.65c.04-.24-.11-.46-.35-.5l-.75-.13c-.1-.02-.14-.14-.08-.21l3.01-3.56s.08-.05,.12-.05l1.75,.32c.23,.04,.46-.11,.5-.35l.13-.64c.05-.23-.1-.46-.34-.51l-.75-.15c-.1-.02-.14-.14-.07-.22l3.12-3.46s.08-.05,.13-.04l.53,.11,4.39,4,.17,.51Z" />
				<g>
					<path d="M257.16,1241.93l-3.09,3.66c-.05,.07-.15,.04-.18-.04-.83-3.38-2.17-5.84-2.98-7.09,1.19-1.39,2.35-2.81,3.56-4.17,.8,1.41,2.08,4.07,2.7,7.56,0,.03,0,.06-.02,.09Z" />
					<path d="M248.64,1252.51l-2.85,3.84c-.05,.07-.15,.05-.18-.03-1.02-3.33-2.51-5.73-3.38-6.93,1.07-1.48,2.2-2.92,3.35-4.35,.9,1.38,2.27,3.92,3.08,7.38,0,.03,0,.06-.02,.09Z" />
					<path d="M261.63,1236.82c-1.05,1.2-2.16,2.35-3.17,3.58-.05,.06-.16,.04-.17-.04-.78-3.44-2.01-5.9-2.8-7.18l2.24-2.49,3.26,2.98c.27,.93,.5,1.96,.66,3.07,0,.03,0,.06-.03,.09Z" />
					<path d="M240.07,1252.31l1.27-1.72c.64,.92,1.52,2.39,2.3,4.32l-3.57-2.6Z" />
					<path d="M252.83,1247.16l-2.97,3.75c-.05,.07-.16,.05-.18-.03-.92-3.35-2.34-5.78-3.18-7.01,1.14-1.43,2.26-2.88,3.46-4.27,.86,1.4,2.15,3.98,2.89,7.47,0,.03,0,.06-.02,.09Z" />
				</g>
				<g>
					<path d="M261.86,1251.96l2.96-3.51s.05-.04,.08-.03c3.47,.11,6.17,1.08,7.67,1.76-1.1,1.24-2.15,2.52-3.23,3.78-1.34-.61-3.96-1.6-7.41-1.83-.08,0-.13-.1-.08-.16Z" />
					<path d="M253.92,1262.28l2.73-3.69s.05-.04,.08-.04c3.47-.08,6.27,.78,7.78,1.36-1.04,1.29-2.06,2.59-3.03,3.94-1.38-.53-4.02-1.37-7.48-1.41-.08,0-.13-.09-.08-.16Z" />
					<path d="M266.07,1246.98c.97-1.18,2.03-2.29,3.05-3.43,.02-.02,.05-.03,.08-.03,1.1,.07,2.13,.22,3.06,.42l3.26,2.98-2.03,2.26c-1.32-.64-3.94-1.73-7.34-2.02-.08,0-.12-.1-.07-.17Z" />
					<path d="M260.67,1264.93l-1.15,1.55-3.57-2.6c2.04,.21,3.67,.68,4.72,1.05Z" />
					<path d="M257.82,1257.06l2.85-3.6s.05-.04,.08-.04c3.45,0,6.23,.94,7.73,1.56-1.09,1.25-2.1,2.57-3.13,3.86-1.36-.57-3.99-1.48-7.45-1.62-.08,0-.13-.1-.08-.16Z" />
				</g>
			</g>
			<g>
				<path d="M287.65,1222.87s0,.09-.04,.12c-1.15,.98-2.23,2.04-3.35,3.05-.07,.07-.19,.03-.22-.07l-.17-.73c-.05-.23-.28-.38-.52-.33l-.64,.14c-.23,.05-.38,.28-.32,.51l.41,1.73s0,.09-.04,.12c-1.12,1.01-2.2,2.07-3.27,3.13-.07,.07-.19,.03-.22-.06l-.19-.73c-.06-.23-.29-.37-.52-.31l-.63,.16c-.23,.06-.37,.29-.31,.52l.45,1.72s0,.09-.03,.13c-1.08,1.06-2.17,2.1-3.2,3.21-.07,.07-.19,.04-.22-.06l-.21-.73c-.07-.23-.3-.37-.53-.3l-.63,.18c-.23,.07-.36,.3-.29,.53l.5,1.71s0,.09-.03,.13c-1.04,1.1-2.1,2.17-3.12,3.28-.07,.07-.19,.05-.22-.05l-.23-.72c-.07-.23-.31-.36-.54-.29l-.62,.2c-.23,.07-.35,.32-.28,.54l.38,1.19-4.39-4,1.22,.26c.23,.05,.46-.1,.52-.34l.14-.65c.05-.24-.09-.47-.33-.52l-.74-.16c-.1-.02-.14-.14-.07-.22,1.05-1.15,2.14-2.25,3.21-3.38,.03-.03,.08-.05,.13-.04l1.74,.42c.23,.06,.47-.09,.53-.32l.16-.64c.06-.23-.08-.47-.31-.53l-.73-.18c-.1-.02-.13-.15-.06-.22,1.06-1.13,2.18-2.21,3.29-3.3,.03-.03,.08-.05,.13-.03l1.73,.47c.23,.06,.47-.07,.54-.3l.18-.64c.07-.23-.07-.47-.3-.54l-.73-.2c-.1-.03-.13-.15-.06-.22,1.1-1.09,2.21-2.18,3.37-3.22,.03-.03,.08-.04,.13-.03l1.72,.52c.23,.07,.47-.06,.55-.29l.2-.63c.07-.23-.05-.47-.28-.54l-.72-.22c-.1-.03-.12-.15-.05-.22,1.15-1.04,2.26-2.13,3.44-3.14,.04-.03,.09-.04,.13-.03l.52,.17,3.94,4.44,.11,.52Z" />
				<g>
					<path d="M275.82,1222.22c-1.16,1.09-2.28,2.23-3.42,3.34-.06,.06-.16,.03-.17-.06-.47-3.46-1.56-6.07-2.23-7.4,1.31-1.28,2.67-2.49,4-3.74,.66,1.5,1.64,4.26,1.85,7.77,0,.03-.01,.06-.03,.08Z" />
					<path d="M266.23,1231.83c-1.11,1.14-2.2,2.3-3.26,3.5-.06,.06-.16,.03-.17-.05-.68-3.45-1.89-5.98-2.62-7.27l3.8-3.96c.74,1.47,1.86,4.18,2.28,7.69,0,.03,0,.06-.03,.09Z" />
					<path d="M280.8,1217.62l-3.51,3.24c-.06,.06-.16,.02-.17-.06-.38-3.46-1.39-6.1-2.03-7.44,.82-.76,1.64-1.52,2.49-2.24l2.93,3.3c.17,.95,.27,1.98,.33,3.11,0,.03-.01,.06-.03,.08Z" />
					<path d="M257.73,1230.68l1.43-1.58c.53,.99,1.26,2.55,1.83,4.56l-3.26-2.98Z" />
					<path d="M270.96,1226.96c-1.15,1.1-2.23,2.27-3.34,3.42-.06,.06-.16,.03-.17-.05-.57-3.45-1.73-6.04-2.43-7.35l3.91-3.84c.7,1.48,1.75,4.22,2.07,7.73,0,.03,0,.06-.03,.08Z" />
				</g>
				<g>
					<path d="M279.45,1232.73c1.1-1.07,2.18-2.15,3.29-3.21,.02-.02,.05-.03,.08-.03,3.47,.51,6.06,1.77,7.47,2.59-1.21,1.14-2.46,2.24-3.65,3.4-1.27-.75-3.74-1.99-7.14-2.58-.08-.01-.11-.11-.06-.17Z" />
					<path d="M270.4,1242.11c1.01-1.15,2.06-2.26,3.13-3.36,.02-.02,.05-.03,.08-.03,3.48,.32,6.13,1.43,7.57,2.17l-3.44,3.59c-1.32-.69-3.87-1.82-7.27-2.21-.08,0-.12-.11-.07-.17Z" />
					<path d="M284.16,1228.21l3.38-3.12s.05-.03,.08-.02c1.08,.17,2.1,.45,3.01,.75l2.93,3.3c-.77,.66-1.52,1.35-2.26,2.04-1.25-.78-3.7-2.09-7.08-2.78-.08-.02-.11-.12-.05-.17Z" />
					<path d="M276.81,1245.47l-1.29,1.44-3.26-2.98c2,.43,3.56,1.06,4.56,1.54Z" />
					<path d="M274.86,1237.36c1.07-1.1,2.1-2.23,3.21-3.28,.02-.02,.05-.03,.08-.03,3.47,.41,6.1,1.61,7.52,2.38l-3.56,3.49c-1.29-.72-3.79-1.89-7.2-2.39-.08-.01-.12-.11-.06-.17Z" />
				</g>
			</g>
			<g>
				<path d="M308.13,1206.43s-.01,.09-.05,.12l-3.66,2.68c-.08,.06-.2,0-.21-.09l-.09-.74c-.03-.24-.24-.41-.48-.38l-.65,.08c-.23,.03-.4,.25-.37,.49l.24,1.77s-.01,.09-.05,.12l-3.58,2.78c-.08,.06-.2,.01-.21-.09l-.11-.74c-.03-.24-.25-.4-.49-.37l-.65,.09c-.24,.03-.4,.25-.37,.48l.28,1.77s0,.09-.05,.12l-3.5,2.88c-.08,.06-.2,.02-.21-.08l-.13-.74c-.04-.23-.26-.39-.5-.36l-.65,.11c-.24,.04-.39,.26-.35,.49l.31,1.75s0,.09-.04,.12l-3.42,2.98c-.08,.07-.19,.02-.21-.07l-.15-.74c-.05-.23-.27-.39-.51-.34l-.64,.12c-.23,.05-.39,.27-.34,.5l.25,1.21-3.94-4.44,1.19,.4c.23,.08,.48-.05,.55-.27l.22-.62c.08-.23-.04-.47-.27-.55l-.72-.24c-.1-.03-.12-.16-.04-.22l3.51-3.06s.09-.04,.13-.02l1.69,.61c.23,.08,.48-.03,.56-.26l.23-.62c.08-.22-.03-.47-.25-.56l-.71-.26c-.1-.03-.12-.16-.04-.22l3.6-2.95s.09-.04,.13-.02l1.67,.64c.22,.09,.48-.02,.57-.24l.25-.61c.09-.22-.02-.47-.24-.56l-.71-.28c-.09-.04-.11-.16-.03-.22l3.68-2.85s.09-.04,.13-.02l1.65,.68c.22,.09,.47-.01,.57-.24l.26-.61c.1-.22,0-.47-.23-.57l-.7-.3c-.09-.04-.11-.16-.03-.23l3.75-2.75s.09-.03,.13-.01l.49,.22,3.49,4.81,.06,.54Z" />
				<g>
					<path d="M296.43,1204.61c-1.25,.98-2.52,1.94-3.76,2.93-.07,.05-.16,0-.16-.07-.12-3.49-.92-6.16-1.45-7.55,1.44-1.11,2.87-2.24,4.32-3.33,.5,1.54,1.21,4.41,1.1,7.95,0,.03-.02,.06-.04,.08Z" />
					<path d="M285.91,1213.15l-3.6,3.14c-.06,.06-.16,.02-.17-.07-.28-3.47-1.23-6.12-1.83-7.48,1.37-1.21,2.75-2.39,4.17-3.53,.6,1.54,1.39,4.3,1.47,7.86,0,.03-.01,.06-.04,.08Z" />
					<path d="M301.84,1200.54c-1.28,.95-2.58,1.86-3.83,2.85-.07,.05-.16,0-.16-.08-.06-3.52-.78-6.19-1.28-7.6l2.69-1.97,2.59,3.58c.07,.97,.09,2.02,.03,3.14,0,.03-.02,.06-.04,.08Z" />
					<path d="M277.58,1211.12l1.6-1.4c.43,1.03,.98,2.66,1.33,4.7l-2.93-3.3Z" />
					<path d="M291.12,1208.82l-3.69,3.03c-.06,.05-.16,.01-.17-.07-.19-3.47-1.07-6.14-1.63-7.51,1.42-1.15,2.8-2.34,4.25-3.43,.54,1.54,1.29,4.35,1.28,7.91,0,.03-.01,.06-.04,.08Z" />
				</g>
				<g>
					<path d="M298.95,1215.4c1.2-.96,2.42-1.88,3.63-2.83,.02-.02,.06-.02,.09-.02,3.39,.83,5.82,2.32,7.15,3.29-1.33,1-2.64,2.04-3.96,3.05-1.19-.87-3.55-2.39-6.87-3.32-.08-.02-.1-.12-.04-.18Z" />
					<path d="M288.98,1223.82l3.47-3.02s.05-.03,.08-.02c3.4,.64,5.97,2.08,7.32,2.95-1.3,1.04-2.56,2.13-3.81,3.23-1.23-.81-3.65-2.19-7.02-2.97-.08-.02-.11-.12-.05-.17Z" />
					<path d="M304.11,1211.39c1.2-.96,2.46-1.84,3.69-2.75,.02-.02,.06-.02,.09-.02,1.07,.29,2.04,.65,2.91,1.03l2.59,3.58-2.47,1.81c-1.16-.9-3.51-2.49-6.78-3.47-.08-.02-.1-.13-.04-.18Z" />
					<path d="M295.02,1227.85l-1.46,1.27-2.93-3.3c1.95,.65,3.45,1.45,4.4,2.03Z" />
					<path d="M293.91,1219.54l3.55-2.92s.06-.03,.08-.02c3.39,.73,5.89,2.2,7.24,3.12-1.33,1-2.59,2.09-3.89,3.14-1.21-.85-3.59-2.28-6.95-3.15-.08-.02-.11-.12-.04-.17Z" />
				</g>
			</g>
			<g>
				<path d="M330.09,1191.95s-.02,.09-.06,.11c-1.31,.76-2.58,1.58-3.87,2.37-.08,.05-.2,0-.2-.11l-.03-.75c0-.24-.21-.43-.44-.42l-.65,.02c-.24,0-.42,.21-.42,.44l.07,1.78s-.02,.09-.06,.11c-1.3,.78-2.56,1.62-3.82,2.45-.08,.05-.2,0-.2-.1l-.04-.75c-.01-.24-.22-.42-.45-.41l-.65,.03c-.24,.01-.42,.22-.41,.45l.11,1.78s-.02,.09-.06,.12c-1.27,.83-2.54,1.65-3.77,2.52-.08,.06-.19,0-.2-.1l-.06-.75c-.02-.24-.23-.42-.46-.4l-.65,.05c-.24,.02-.41,.23-.39,.47l.15,1.78s-.02,.09-.06,.12c-1.24,.87-2.5,1.7-3.72,2.6-.08,.06-.19,0-.21-.09l-.08-.75c-.02-.24-.24-.41-.47-.39l-.65,.07c-.24,.02-.41,.24-.38,.48l.13,1.24-3.49-4.81,1.14,.5c.22,.1,.47,0,.57-.22l.27-.61c.1-.22,0-.48-.21-.58l-.69-.31c-.09-.04-.1-.17-.02-.23,1.25-.92,2.54-1.77,3.81-2.66,.04-.03,.09-.03,.13-.01l1.62,.76c.22,.1,.48,0,.58-.21l.29-.6c.1-.22,.01-.48-.2-.58l-.68-.32c-.09-.04-.1-.17-.02-.23,1.27-.89,2.57-1.74,3.86-2.58,.04-.03,.09-.03,.13,0l1.6,.8c.21,.11,.48,.02,.58-.19l.3-.59c.11-.21,.03-.48-.19-.58l-.68-.34c-.09-.05-.1-.17-.01-.23,1.3-.85,2.59-1.7,3.91-2.5,.04-.02,.09-.03,.13,0l1.59,.84c.21,.11,.48,.03,.59-.18l.31-.58c.11-.21,.04-.47-.18-.59l-.67-.36c-.09-.05-.09-.17,0-.23,1.32-.81,2.63-1.64,3.96-2.43,.04-.02,.09-.02,.13,0l.47,.26,3.04,5.1v.54Z" />
				<g>
					<path d="M318.6,1189.07l-3.99,2.6c-.07,.05-.16,0-.16-.09,.21-3.48-.36-6.24-.76-7.68,1.51-1,3.08-1.92,4.61-2.88,.37,1.6,.8,4.49,.35,7.97,0,.03-.02,.06-.05,.07Z" />
					<path d="M307.34,1196.59c-1.32,.89-2.61,1.82-3.89,2.77-.07,.05-.16,0-.16-.08,.04-3.5-.65-6.24-1.11-7.64l4.48-3.1c.44,1.58,1.01,4.46,.72,7.97,0,.03-.02,.06-.04,.08Z" />
					<path d="M324.35,1185.52l-4.05,2.5c-.07,.04-.16-.01-.15-.09,.29-3.47-.22-6.24-.59-7.68,.94-.58,1.88-1.17,2.84-1.72l2.26,3.79c-.02,.97-.1,2-.25,3.12,0,.03-.02,.06-.05,.08Z" />
					<path d="M299.27,1193.74l1.71-1.25c.33,1.07,.72,2.75,.88,4.83l-2.59-3.58Z" />
					<path d="M312.93,1192.77c-1.34,.85-2.63,1.79-3.94,2.68-.07,.05-.16,0-.16-.09,.12-3.49-.5-6.24-.94-7.67l4.55-2.98c.4,1.59,.9,4.47,.53,7.98,0,.03-.02,.06-.05,.08Z" />
				</g>
				<g>
					<path d="M320.12,1200.08l3.86-2.51s.06-.02,.09,0c3.31,1.16,5.63,2.89,6.85,3.96-1.42,.89-2.87,1.74-4.26,2.66-1.1-.98-3.3-2.68-6.52-3.92-.08-.03-.09-.13-.02-.18Z" />
					<path d="M309.36,1207.5c1.23-.92,2.48-1.81,3.75-2.67,.02-.02,.06-.02,.09-.01,3.35,1,5.73,2.62,7,3.63l-4.12,2.85c-1.16-.94-3.43-2.54-6.69-3.62-.08-.03-.1-.13-.03-.18Z" />
					<path d="M325.63,1196.55l3.92-2.42s.06-.02,.09,0c1.03,.37,1.98,.83,2.81,1.29l2.26,3.79c-.89,.51-1.76,1.05-2.63,1.59-1.08-1-3.24-2.75-6.44-4.07-.08-.03-.09-.14-.02-.18Z" />
					<path d="M314.96,1212.08l-1.57,1.15-2.59-3.58c1.87,.82,3.28,1.76,4.16,2.43Z" />
					<path d="M314.7,1203.73c1.27-.86,2.51-1.77,3.81-2.59,.03-.02,.06-.02,.09-.01,3.33,1.08,5.68,2.76,6.93,3.8l-4.2,2.75c-1.12-.95-3.35-2.6-6.6-3.76-.08-.03-.09-.13-.03-.18Z" />
				</g>
			</g>
			<g>
				<path d="M353.24,1179.41s-.03,.09-.07,.11l-4.07,2.02c-.09,.05-.19-.02-.19-.12l.03-.76c.01-.24-.17-.44-.41-.45l-.65-.03c-.24-.01-.44,.17-.45,.41l-.08,1.79s-.03,.09-.07,.11l-4.03,2.1c-.09,.05-.2-.02-.19-.12l.02-.75c0-.24-.18-.44-.42-.45l-.66-.03c-.24-.01-.44,.18-.44,.42l-.04,1.79s-.03,.09-.07,.11l-3.98,2.2c-.09,.05-.2-.02-.19-.12v-.75c0-.24-.18-.44-.42-.44h-.66c-.24-.02-.43,.17-.44,.41v1.78s-.03,.09-.07,.11l-3.92,2.29c-.09,.05-.2-.01-.2-.11v-.75c-.01-.24-.21-.43-.44-.43h-.65c-.24,0-.43,.2-.43,.44l.02,1.24-3.04-5.1,1.09,.61c.21,.12,.48,.04,.59-.16l.33-.57c.12-.21,.05-.47-.16-.59l-.66-.37c-.09-.05-.09-.18,0-.23l4.01-2.34s.09-.02,.13,0l1.55,.91c.21,.12,.47,.06,.6-.15l.34-.56c.12-.21,.06-.47-.15-.59l-.65-.39c-.09-.05-.08-.18,0-.23l4.06-2.24s.09-.02,.13,0l1.52,.93c.2,.12,.47,.06,.6-.14l.35-.55c.13-.2,.07-.47-.14-.6l-.65-.4c-.09-.05-.08-.18,0-.23l4.11-2.15s.09-.02,.13,0l1.51,.96c.2,.13,.47,.07,.6-.13l.36-.56c.13-.2,.07-.47-.13-.6l-.63-.41c-.08-.05-.08-.18,.01-.23l4.16-2.06s.09-.02,.13,0l.45,.3,2.62,5.33-.03,.54Z" />
				<g>
					<path d="M342.03,1175.6c-1.4,.74-2.82,1.45-4.21,2.22-.07,.04-.16-.02-.15-.1,.49-3.46,.17-6.22-.11-7.69l4.8-2.51c.24,1.62,.44,4.55-.28,8.01,0,.03-.03,.06-.05,.07Z" />
					<path d="M330.18,1182.09l-4.11,2.4c-.07,.04-.16-.01-.15-.1,.36-3.46-.08-6.23-.42-7.68,1.56-.92,3.12-1.83,4.71-2.69,.31,1.62,.6,4.48,.02,7.99,0,.03-.02,.06-.05,.07Z" />
					<path d="M348.04,1172.53c-1.42,.7-2.84,1.4-4.24,2.15-.07,.04-.16-.02-.15-.11,.55-3.46,.29-6.24,.03-7.7l2.96-1.47,1.95,3.96c-.09,.96-.25,2-.5,3.09,0,.03-.03,.06-.06,.07Z" />
					<path d="M322.4,1178.53l1.82-1.07c.23,1.09,.47,2.79,.44,4.86l-2.26-3.79Z" />
					<path d="M336.08,1178.79l-4.17,2.3c-.07,.04-.16-.02-.15-.1,.44-3.44,.05-6.22-.26-7.68,1.59-.86,3.15-1.77,4.76-2.6,.26,1.6,.52,4.52-.13,8,0,.03-.02,.06-.05,.07Z" />
				</g>
				<g>
					<path d="M342.61,1186.67c1.34-.75,2.72-1.43,4.08-2.15,.03-.01,.06-.01,.09,0,3.19,1.41,5.36,3.31,6.49,4.47l-4.47,2.34c-1.02-1.06-3.09-2.97-6.18-4.47-.07-.04-.08-.14,0-.18Z" />
					<path d="M331.22,1193.14l3.98-2.33s.06-.02,.09,0c3.23,1.25,5.5,3.12,6.67,4.23-1.48,.8-2.92,1.65-4.37,2.5-1.06-1.03-3.19-2.82-6.36-4.21-.08-.03-.08-.14-.01-.18Z" />
					<path d="M348.41,1183.62c1.36-.73,2.74-1.4,4.12-2.08,.03-.01,.06-.01,.09,0,1.01,.47,1.91,.99,2.7,1.51l1.95,3.96-2.77,1.37c-1-1.09-3.03-3.03-6.09-4.58-.07-.04-.08-.14,0-.18Z" />
					<path d="M336.41,1198.22l-1.69,.99-2.26-3.79c1.8,1,3.13,2.06,3.95,2.81Z" />
					<path d="M336.87,1189.84l4.04-2.23s.06-.02,.09,0c3.21,1.33,5.42,3.2,6.58,4.35-1.5,.77-2.95,1.61-4.42,2.42-1.04-1.05-3.13-2.89-6.28-4.35-.07-.03-.08-.14-.01-.18Z" />
				</g>
			</g>
			<g>
				<path d="M377.29,1168.62s-.04,.09-.08,.1l-4.2,1.75c-.09,.04-.19-.04-.18-.14l.09-.75c.03-.24-.14-.45-.37-.49l-.65-.08c-.24-.03-.45,.14-.48,.37l-.21,1.77s-.04,.09-.08,.1c-1.4,.58-2.78,1.22-4.17,1.82-.09,.04-.19-.03-.18-.13l.08-.75c.03-.24-.15-.45-.38-.48l-.65-.07c-.24-.03-.45,.15-.47,.38l-.18,1.77s-.03,.09-.08,.11c-1.39,.6-2.77,1.24-4.14,1.89-.09,.04-.19-.03-.18-.13l.06-.75c.02-.24-.15-.45-.39-.47l-.65-.06c-.24-.02-.45,.15-.47,.39l-.15,1.78s-.03,.09-.07,.11c-1.37,.65-2.76,1.27-4.11,1.95-.09,.04-.19-.03-.19-.13l.05-.76c.02-.24-.16-.45-.4-.46l-.65-.05c-.24-.02-.44,.16-.46,.4l-.08,1.24-2.62-5.33,1.04,.69c.2,.13,.47,.08,.6-.12l.37-.55c.13-.2,.08-.47-.12-.6l-.63-.42c-.08-.06-.07-.18,.02-.23,1.38-.7,2.79-1.33,4.19-1.99,.04-.02,.09-.02,.13,.01l1.47,1.02c.2,.14,.47,.09,.61-.11l.38-.54c.14-.2,.09-.47-.1-.6l-.62-.43c-.08-.06-.07-.18,.02-.23,1.4-.66,2.8-1.31,4.22-1.92,.04-.02,.09-.01,.13,.01l1.45,1.05c.19,.14,.47,.1,.61-.1l.39-.53c.14-.19,.1-.47-.09-.61l-.61-.45c-.08-.06-.07-.19,.03-.23,1.42-.62,2.82-1.27,4.25-1.86,.04-.02,.09-.01,.13,.02l1.44,1.08c.19,.14,.46,.11,.61-.08l.4-.52c.15-.19,.11-.46-.08-.61l-.6-.46c-.08-.06-.06-.19,.03-.23l4.28-1.79s.09-.01,.13,.02l.43,.33,2.23,5.5-.07,.53Z" />
				<g>
					<path d="M366.4,1164.01l-4.35,1.92c-.08,.03-.16-.03-.14-.11,.76-3.4,.63-6.21,.46-7.68,1.64-.74,3.3-1.42,4.96-2.12,.12,1.63,.11,4.54-.87,7.93,0,.03-.03,.06-.06,.07Z" />
					<path d="M354.11,1169.57c-1.44,.66-2.86,1.37-4.28,2.07-.08,.04-.16-.03-.14-.11,.63-3.43,.39-6.25,.17-7.71l4.9-2.29c.18,1.63,.27,4.55-.59,7.97,0,.03-.03,.06-.06,.07Z" />
					<path d="M372.61,1161.4l-4.38,1.83c-.08,.03-.16-.04-.14-.12,.82-3.38,.74-6.18,.59-7.66l3.04-1.27,1.66,4.09c-.16,.95-.39,1.98-.71,3.06,0,.03-.03,.05-.06,.07Z" />
					<path d="M346.65,1165.4l1.89-.93c.14,1.11,.26,2.83,.06,4.9l-1.95-3.96Z" />
					<path d="M360.23,1166.73l-4.31,2c-.08,.04-.16-.03-.14-.11,.69-3.41,.52-6.23,.31-7.7,1.63-.76,3.29-1.46,4.93-2.2,.15,1.63,.19,4.55-.74,7.94,0,.03-.03,.05-.06,.07Z" />
				</g>
				<g>
					<path d="M366.15,1175.11l4.23-1.87s.06-.01,.09,0c3.08,1.65,5.12,3.73,6.17,4.96-1.56,.65-3.12,1.29-4.66,1.99-.94-1.14-2.85-3.16-5.83-4.91-.07-.04-.07-.15,0-.18Z" />
					<path d="M354.27,1180.67c1.38-.68,2.76-1.38,4.16-2.01,.03-.01,.06-.01,.09,0,3.14,1.54,5.24,3.53,6.33,4.73l-4.58,2.14c-.99-1.12-2.96-3.06-6-4.68-.07-.04-.07-.14,0-.18Z" />
					<path d="M372.17,1172.48l4.27-1.78s.06,0,.09,0c.97,.54,1.83,1.11,2.59,1.69l1.66,4.09-2.86,1.19c-.92-1.16-2.79-3.22-5.75-5.02-.07-.04-.07-.15,0-.18Z" />
					<path d="M359.03,1186.14l-1.76,.87-1.95-3.96c1.71,1.13,2.95,2.29,3.71,3.09Z" />
					<path d="M360.18,1177.84l4.19-1.95s.06-.01,.09,0c3.12,1.6,5.18,3.63,6.25,4.86-1.54,.69-3.09,1.35-4.62,2.06-.96-1.12-2.9-3.11-5.91-4.79-.07-.04-.07-.14,0-.18Z" />
				</g>
			</g>
			<g>
				<path d="M402.06,1159.5s-.04,.08-.09,.1c-1.44,.48-2.89,.95-4.31,1.46-.09,.03-.19-.05-.17-.15l.14-.74c.04-.24-.11-.46-.34-.51l-.64-.12c-.23-.04-.46,.11-.5,.35l-.33,1.76s-.04,.08-.08,.1l-4.29,1.52c-.09,.03-.19-.05-.17-.15l.13-.75c.04-.24-.12-.46-.35-.5l-.64-.11c-.23-.04-.46,.12-.5,.35l-.3,1.76s-.04,.08-.08,.1l-4.27,1.59c-.09,.03-.19-.05-.18-.14l.12-.74c.04-.24-.12-.46-.36-.5l-.65-.11c-.24-.04-.46,.13-.49,.36l-.27,1.77s-.04,.09-.08,.1l-4.24,1.67c-.09,.04-.19-.04-.18-.14l.11-.75c.03-.24-.13-.46-.37-.49l-.65-.1c-.24-.04-.46,.13-.49,.36l-.17,1.23-2.23-5.5,.99,.77c.19,.15,.46,.12,.61-.07l.41-.51c.15-.19,.12-.46-.07-.61l-.6-.47c-.08-.06-.06-.19,.03-.22l4.31-1.7s.09-.01,.13,.02l1.39,1.11c.19,.15,.46,.12,.61-.07l.42-.51c.15-.18,.12-.46-.06-.61l-.59-.48c-.08-.06-.06-.19,.04-.22l4.34-1.62s.09,0,.13,.02l1.38,1.14c.18,.15,.46,.13,.61-.06l.42-.51c.15-.19,.13-.46-.05-.61l-.58-.48c-.08-.06-.06-.19,.04-.22l4.36-1.55s.09,0,.13,.02l1.36,1.16c.18,.16,.46,.13,.61-.05l.43-.5c.16-.18,.14-.46-.04-.61l-.57-.49c-.08-.07-.05-.19,.04-.22,1.45-.52,2.92-1,4.38-1.49,.04-.01,.09,0,.13,.03l.41,.36,1.88,5.63-.11,.53Z" />
				<g>
					<path d="M391.48,1154.18c-1.49,.52-2.98,1.05-4.46,1.61-.08,.03-.15-.04-.13-.12,.97-3.36,1.03-6.16,.96-7.64l5.07-1.78c.02,1.64-.18,4.56-1.37,7.87-.01,.03-.03,.05-.06,.06Z" />
					<path d="M378.87,1158.89l-4.41,1.74c-.08,.03-.16-.04-.14-.12,.88-3.36,.85-6.16,.72-7.64,1.67-.65,3.33-1.34,5.02-1.96,.06,1.62-.04,4.55-1.13,7.91,0,.03-.03,.05-.06,.07Z" />
					<path d="M397.84,1151.97c-1.5,.48-2.99,1.03-4.48,1.55-.08,.03-.15-.05-.13-.13,1.03-3.33,1.12-6.14,1.07-7.63l3.12-1.03,1.4,4.19c-.22,.94-.51,1.94-.91,2.99-.01,.03-.03,.05-.06,.06Z" />
					<path d="M371.73,1154.18l1.95-.77c.06,1.11,.06,2.82-.29,4.86l-1.66-4.09Z" />
					<path d="M385.16,1156.49c-1.48,.56-2.97,1.09-4.44,1.67-.08,.03-.16-.04-.13-.12,.92-3.37,.94-6.15,.84-7.64l5.04-1.88c.04,1.64-.1,4.56-1.25,7.9-.01,.03-.03,.05-.06,.06Z" />
				</g>
				<g>
					<path d="M390.49,1165.24c1.44-.55,2.9-1.07,4.36-1.57,.03-.01,.06,0,.09,0,2.97,1.85,4.87,4.03,5.84,5.33l-4.8,1.69c-.87-1.21-2.64-3.36-5.5-5.28-.07-.05-.06-.15,.02-.18Z" />
					<path d="M378.23,1169.96l4.31-1.7s.06,0,.09,0c3.02,1.74,4.98,3.86,5.99,5.15-1.59,.59-3.15,1.23-4.73,1.85-.9-1.17-2.74-3.27-5.67-5.12-.07-.04-.06-.15,.01-.18Z" />
					<path d="M396.68,1163.02c1.46-.51,2.91-1.04,4.38-1.51,.03,0,.06,0,.09,.01,.94,.6,1.76,1.24,2.48,1.86l1.4,4.19-2.95,.98c-.84-1.21-2.58-3.38-5.42-5.35-.07-.05-.06-.15,.02-.18Z" />
					<path d="M382.6,1175.76l-1.84,.72-1.66-4.09c1.64,1.26,2.79,2.51,3.5,3.37Z" />
					<path d="M384.34,1167.55c1.43-.57,2.89-1.09,4.33-1.63,.03-.01,.06,0,.09,0,3,1.79,4.92,3.95,5.91,5.23l-4.76,1.77c-.88-1.18-2.7-3.33-5.59-5.2-.07-.04-.06-.15,.02-.18Z" />
				</g>
			</g>
			<g>
				<path d="M427.36,1151.88s-.05,.08-.09,.09l-4.41,1.19c-.1,.03-.19-.06-.16-.16l.18-.73c.06-.23-.08-.47-.31-.53l-.64-.17c-.23-.06-.47,.07-.53,.31l-.43,1.74s-.05,.08-.09,.09l-4.37,1.29c-.1,.03-.19-.06-.16-.16l.17-.73c.06-.23-.09-.47-.32-.52l-.64-.16c-.23-.06-.47,.09-.52,.32l-.41,1.73s-.04,.08-.09,.09l-4.35,1.36c-.1,.03-.19-.06-.17-.15l.16-.74c.05-.23-.09-.47-.33-.52l-.64-.14c-.23-.05-.46,.09-.51,.33l-.38,1.74s-.04,.08-.09,.1c-1.45,.44-2.89,.93-4.33,1.41-.09,.03-.19-.05-.17-.15l.15-.74c.05-.23-.1-.46-.33-.51l-.64-.13c-.23-.05-.46,.1-.51,.34l-.25,1.22-1.88-5.63,.94,.83c.18,.16,.45,.14,.61-.04l.44-.49c.16-.18,.15-.45-.03-.61l-.57-.5c-.08-.07-.05-.19,.05-.22,1.47-.48,2.93-.98,4.4-1.43,.04-.01,.09,0,.13,.03l1.33,1.21c.18,.16,.45,.15,.61-.03l.45-.48c.16-.18,.15-.45-.02-.61l-.56-.51c-.07-.07-.05-.19,.05-.22l4.42-1.38s.09,0,.13,.03l1.31,1.23c.17,.16,.45,.16,.62-.02l.45-.48c.17-.17,.16-.45-.02-.61l-.55-.52c-.07-.07-.04-.19,.05-.22l4.44-1.31s.09,0,.13,.03l1.29,1.23c.17,.17,.45,.17,.62,0l.46-.46c.17-.17,.16-.44,0-.61l-.55-.53c-.07-.07-.04-.19,.06-.22l4.46-1.2s.09,0,.13,.03l.39,.38,1.57,5.73-.13,.52Z" />
				<g>
					<path d="M417.09,1145.93l-4.54,1.34c-.08,.02-.15-.05-.12-.13,1.18-3.26,1.39-6.06,1.4-7.55,1.71-.49,3.43-1.04,5.14-1.48-.07,1.62-.43,4.5-1.81,7.76-.01,.03-.04,.05-.07,.06Z" />
					<path d="M404.23,1149.86l-4.5,1.49c-.08,.03-.15-.05-.13-.13,1.08-3.31,1.22-6.12,1.18-7.6,1.7-.58,3.41-1.08,5.12-1.62-.03,1.63-.31,4.54-1.61,7.8-.01,.03-.04,.05-.07,.06Z" />
					<path d="M423.56,1144.13c-1.52,.42-3.04,.81-4.56,1.24-.08,.02-.15-.05-.12-.13,1.22-3.26,1.47-6.02,1.5-7.51l3.15-.85,1.17,4.26c-.27,.92-.61,1.91-1.06,2.93-.01,.03-.04,.05-.07,.06Z" />
					<path d="M397.42,1144.73l1.98-.66c0,1.12-.12,2.83-.58,4.85l-1.4-4.19Z" />
					<path d="M410.65,1147.85l-4.52,1.41c-.08,.02-.15-.05-.13-.13,1.13-3.29,1.31-6.09,1.29-7.58,1.7-.54,3.42-1.06,5.13-1.56-.05,1.64-.38,4.51-1.71,7.79-.01,.03-.04,.05-.07,.06Z" />
				</g>
				<g>
					<path d="M415.45,1156.9l4.45-1.31s.06,0,.09,.01c2.85,1.99,4.63,4.3,5.54,5.67-1.66,.43-3.27,.94-4.91,1.41-.79-1.25-2.44-3.5-5.19-5.61-.07-.05-.05-.15,.03-.18Z" />
					<path d="M402.91,1160.89l4.4-1.46s.06,0,.09,.01c2.92,1.94,4.75,4.19,5.69,5.52-1.62,.52-3.25,.99-4.86,1.54-.83-1.22-2.54-3.42-5.34-5.44-.07-.05-.05-.15,.02-.18Z" />
					<path d="M421.77,1155.04c1.49-.43,2.99-.81,4.48-1.22,.03,0,.06,0,.09,.02,.9,.65,1.7,1.33,2.39,2l1.17,4.26-3.03,.81c-.78-1.26-2.42-3.55-5.14-5.69-.06-.05-.05-.15,.03-.18Z" />
					<path d="M406.91,1166.94l-1.88,.62-1.4-4.19c1.55,1.35,2.63,2.67,3.28,3.57Z" />
					<path d="M409.17,1158.85l4.43-1.38s.06,0,.09,.01c2.88,1.95,4.69,4.25,5.61,5.59-1.63,.47-3.26,.96-4.88,1.48-.81-1.24-2.49-3.46-5.27-5.53-.07-.05-.05-.15,.03-.18Z" />
				</g>
			</g>
			<g>
				<path d="M453.02,1145.37s-.05,.08-.09,.09l-4.45,1.07c-.1,.02-.18-.07-.16-.16l.21-.72c.07-.23-.06-.47-.29-.54l-.63-.19c-.23-.07-.47,.06-.54,.29l-.5,1.71s-.05,.08-.09,.09l-4.45,1.06c-.1,.02-.19-.07-.16-.16l.21-.72c.07-.23-.07-.47-.3-.54l-.63-.19c-.23-.07-.47,.06-.54,.29l-.49,1.71s-.05,.08-.09,.09l-4.41,1.18c-.1,.03-.19-.06-.16-.16l.2-.73c.06-.23-.07-.47-.3-.53l-.63-.17c-.23-.06-.47,.08-.53,.31l-.46,1.73s-.05,.08-.09,.09l-4.41,1.19c-.1,.03-.19-.06-.16-.16l.19-.73c.06-.23-.08-.47-.31-.53l-.63-.17c-.23-.06-.47,.08-.53,.31l-.31,1.2-1.57-5.73,.89,.88c.17,.17,.45,.17,.61,0l.46-.47c.17-.17,.17-.44,0-.61l-.54-.53c-.07-.07-.04-.19,.06-.22l4.46-1.2s.09,0,.13,.03l1.26,1.26c.17,.17,.44,.17,.61,0l.47-.47c.17-.17,.17-.44,0-.61l-.53-.54c-.07-.07-.04-.19,.06-.22l4.46-1.19s.09,0,.13,.04l1.25,1.29c.17,.17,.44,.18,.62,.02l.47-.45c.17-.16,.18-.44,.01-.61l-.53-.55c-.07-.07-.04-.19,.06-.22l4.49-1.08s.09,0,.13,.04l1.24,1.3c.17,.17,.44,.18,.61,.02l.48-.45c.17-.16,.18-.44,.02-.61l-.52-.55c-.07-.07-.03-.19,.06-.22l4.49-1.08s.09,0,.13,.04l.37,.39,1.35,5.78-.16,.52Z" />
				<g>
					<path d="M443.02,1139.06l-4.59,1.1c-.08,.02-.15-.06-.12-.14,1.32-3.2,1.65-5.97,1.72-7.45l5.17-1.24c-.14,1.63-.6,4.5-2.12,7.67-.01,.03-.04,.05-.07,.06Z" />
					<path d="M430.03,1142.39l-4.56,1.23c-.08,.02-.15-.06-.12-.13,1.25-3.24,1.53-6.03,1.57-7.51l5.15-1.38c-.11,1.63-.52,4.53-1.97,7.74-.01,.03-.04,.05-.07,.06Z" />
					<path d="M449.54,1137.5l-4.59,1.1c-.08,.02-.15-.06-.12-.14,1.34-3.2,1.7-5.98,1.79-7.47l3.16-.76,1,4.3c-.3,.92-.68,1.9-1.17,2.9-.01,.03-.04,.05-.07,.06Z" />
					<path d="M423.52,1136.89l2-.54c-.06,1.11-.26,2.81-.83,4.8l-1.17-4.26Z" />
					<path d="M436.51,1140.65l-4.56,1.23c-.08,.02-.15-.06-.12-.13,1.28-3.24,1.58-6.04,1.64-7.53,1.71-.48,3.44-.9,5.16-1.31-.12,1.64-.57,4.47-2.05,7.68-.01,.03-.04,.05-.07,.06Z" />
				</g>
				<g>
					<path d="M440.89,1149.91l4.53-1.08s.06,0,.09,.02c2.78,2.14,4.47,4.5,5.31,5.89l-5,1.2c-.74-1.28-2.3-3.61-4.96-5.85-.06-.05-.04-.16,.04-.17Z" />
					<path d="M428.14,1153.32l4.49-1.21s.06,0,.09,.02c2.82,2.07,4.54,4.39,5.41,5.76l-4.95,1.33c-.77-1.27-2.37-3.55-5.07-5.72-.06-.05-.04-.15,.03-.18Z" />
					<path d="M447.32,1148.37l4.53-1.08s.06,0,.09,.02c.88,.68,1.64,1.38,2.3,2.07l1,4.3-3.06,.73c-.73-1.29-2.27-3.62-4.9-5.86-.06-.05-.04-.16,.04-.18Z" />
					<path d="M431.83,1159.58l-1.93,.52-1.17-4.26c1.48,1.43,2.49,2.81,3.1,3.74Z" />
					<path d="M434.51,1151.6l4.49-1.21s.06,0,.09,.02c2.79,2.08,4.51,4.47,5.37,5.85-1.67,.39-3.33,.8-4.98,1.26-.75-1.27-2.33-3.56-5-5.74-.06-.05-.04-.16,.04-.18Z" />
				</g>
			</g>
			<g>
				<path d="M478.93,1139.76s-.05,.08-.1,.09c-1.5,.3-3,.56-4.49,.9-.1,.02-.18-.07-.15-.17l.24-.72c.07-.23-.05-.47-.27-.55l-.62-.2c-.23-.07-.47,.05-.54,.28l-.56,1.7s-.05,.08-.1,.09l-4.47,.96c-.1,.02-.18-.07-.15-.17l.23-.72c.07-.23-.05-.47-.28-.55l-.62-.2c-.23-.07-.47,.05-.54,.28l-.54,1.7s-.05,.08-.1,.09l-4.47,.96c-.1,.02-.18-.07-.15-.17l.23-.72c.07-.23-.05-.47-.28-.54l-.63-.2c-.23-.07-.47,.05-.54,.28l-.53,1.7s-.05,.08-.1,.09c-1.49,.31-2.98,.66-4.46,1.02-.1,.02-.18-.07-.15-.17l.22-.73c.07-.23-.06-.47-.29-.54l-.63-.19c-.23-.07-.47,.06-.54,.29l-.36,1.2-1.35-5.79,.85,.91c.16,.17,.44,.18,.61,.02l.48-.45c.18-.17,.19-.44,.02-.61l-.52-.55c-.07-.07-.03-.19,.07-.22,1.5-.36,2.99-.72,4.5-1.03,.05,0,.09,0,.13,.04l1.21,1.32c.16,.18,.44,.19,.61,.03l.49-.44c.18-.16,.19-.43,.03-.61l-.51-.56c-.07-.07-.03-.19,.07-.22l4.51-.97s.09,0,.13,.04l1.2,1.33c.16,.18,.43,.19,.61,.03l.49-.44c.18-.16,.19-.43,.03-.61l-.51-.56c-.07-.07-.03-.2,.07-.22l4.51-.97s.09,0,.12,.04l1.19,1.33c.16,.18,.43,.19,.61,.03l.49-.44c.18-.16,.19-.44,.04-.61l-.5-.56c-.07-.08-.03-.2,.07-.22,1.5-.34,3.01-.6,4.52-.9,.05,0,.09,0,.12,.04l.36,.41,1.17,5.82-.17,.51Z" />
				<g>
					<path d="M469.14,1133.13l-4.61,.99c-.08,.02-.15-.06-.11-.14,1.42-3.17,1.85-5.94,1.96-7.42l5.19-1.11c-.19,1.63-.74,4.5-2.36,7.63-.01,.03-.04,.05-.07,.05Z" />
					<path d="M456.06,1135.95l-4.59,1.09c-.08,.02-.15-.06-.11-.14,1.37-3.21,1.75-5.99,1.85-7.48,1.72-.41,3.46-.75,5.18-1.14-.16,1.62-.68,4.48-2.25,7.61-.01,.03-.04,.05-.07,.05Z" />
					<path d="M475.69,1131.76c-1.54,.29-3.08,.62-4.61,.95-.08,.02-.15-.07-.11-.14,1.44-3.17,1.89-5.95,2.02-7.42l3.17-.62,.87,4.33c-.33,.9-.74,1.86-1.26,2.84-.01,.03-.04,.05-.07,.05Z" />
					<path d="M449.78,1130.23l2.01-.48c-.1,1.11-.36,2.81-1.01,4.78l-1-4.3Z" />
					<path d="M462.6,1134.54l-4.61,.99c-.08,.02-.15-.06-.11-.14,1.4-3.16,1.8-5.93,1.91-7.41l5.19-1.11c-.18,1.62-.71,4.49-2.3,7.62-.01,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M466.65,1143.96l4.56-.98s.06,0,.09,.02c2.71,2.21,4.32,4.61,5.11,6.03l-5.04,1.08c-.7-1.3-2.18-3.67-4.76-5.98-.06-.06-.04-.16,.04-.17Z" />
					<path d="M453.74,1146.83l4.53-1.07s.06,0,.09,.02c2.75,2.2,4.4,4.6,5.22,6.01-1.68,.37-3.37,.7-5.04,1.1-.72-1.29-2.23-3.63-4.84-5.88-.06-.05-.04-.16,.04-.18Z" />
					<path d="M473.12,1142.57c1.52-.33,3.04-.66,4.57-.94,.03,0,.06,0,.09,.02,.86,.72,1.6,1.45,2.25,2.16l.87,4.33-3.1,.6c-.69-1.32-2.15-3.69-4.71-6-.06-.06-.04-.16,.05-.17Z" />
					<path d="M457.18,1153.21l-1.94,.47-1-4.3c1.43,1.48,2.38,2.88,2.95,3.84Z" />
					<path d="M460.18,1145.35l4.56-.98s.06,0,.09,.02c2.73,2.2,4.36,4.6,5.17,6.02l-5.04,1.08c-.71-1.3-2.21-3.66-4.81-5.97-.06-.05-.04-.16,.04-.17Z" />
				</g>
			</g>
			<g>
				<path d="M505.01,1134.85s-.05,.08-.1,.08l-4.51,.81c-.1,.02-.18-.08-.15-.17l.25-.71c.08-.23-.04-.47-.26-.55l-.62-.22c-.22-.08-.47,.04-.55,.26l-.6,1.68s-.05,.08-.1,.08l-4.51,.81c-.1,.02-.18-.08-.15-.17l.25-.71c.08-.22-.04-.47-.26-.55l-.62-.22c-.22-.08-.47,.04-.55,.27l-.59,1.69s-.05,.08-.1,.09l-4.49,.88c-.1,.02-.18-.08-.15-.17l.25-.72c.08-.23-.04-.47-.27-.55l-.62-.21c-.23-.08-.47,.04-.55,.27l-.58,1.69s-.05,.08-.1,.09l-4.49,.88c-.1,.02-.18-.08-.15-.17l.24-.71c.08-.23-.04-.47-.27-.55l-.62-.21c-.23-.08-.47,.04-.55,.27l-.4,1.18-1.17-5.82,.83,.94c.16,.18,.43,.2,.61,.04l.49-.43c.18-.16,.2-.43,.04-.61l-.5-.57c-.07-.08-.03-.2,.07-.21l4.52-.88s.09,0,.12,.04l1.17,1.35c.16,.18,.43,.2,.61,.04l.5-.43c.18-.16,.2-.43,.04-.61l-.5-.57c-.07-.08-.02-.2,.07-.21l4.52-.88s.09,0,.12,.04l1.16,1.35c.16,.18,.43,.2,.61,.04l.5-.42c.18-.15,.2-.43,.05-.61l-.49-.58c-.07-.08-.02-.2,.08-.21l4.53-.81s.09,0,.12,.04l1.16,1.37c.15,.18,.43,.21,.61,.05l.5-.42c.18-.15,.21-.43,.05-.61l-.49-.58c-.07-.08-.02-.2,.08-.21l4.53-.81s.09,0,.12,.04l.35,.41,1.04,5.85-.18,.51Z" />
				<g>
					<path d="M495.38,1127.98c-1.54,.28-3.09,.54-4.63,.85-.08,.02-.15-.07-.11-.14,1.5-3.15,1.99-5.88,2.15-7.36l5.19-.93c-.22,1.62-.84,4.46-2.53,7.53-.01,.03-.04,.05-.07,.05Z" />
					<path d="M482.25,1130.49l-4.62,.9c-.08,.02-.15-.07-.11-.14,1.47-3.14,1.93-5.9,2.07-7.38l5.19-1.01c-.21,1.62-.8,4.48-2.45,7.58-.01,.03-.04,.05-.07,.05Z" />
					<path d="M501.95,1126.8l-4.63,.83c-.08,.01-.14-.07-.11-.14,1.52-3.12,2.02-5.87,2.18-7.35l3.17-.57,.77,4.35c-.35,.9-.78,1.85-1.32,2.83-.02,.03-.04,.05-.07,.05Z" />
					<path d="M476.15,1124.54l2.02-.39c-.14,1.1-.45,2.78-1.15,4.72l-.87-4.33Z" />
					<path d="M488.81,1129.21l-4.62,.9c-.08,.02-.15-.07-.11-.14,1.48-3.14,1.96-5.91,2.11-7.38l5.19-1c-.22,1.61-.82,4.47-2.5,7.57-.01,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M492.61,1138.71c1.52-.31,3.06-.56,4.59-.84,.03,0,.06,0,.08,.02,2.66,2.29,4.22,4.75,4.99,6.18l-5.11,.92c-.66-1.32-2.1-3.75-4.61-6.11-.06-.06-.03-.16,.05-.17Z" />
					<path d="M479.61,1141.25l4.58-.89s.06,0,.09,.02c2.68,2.26,4.27,4.69,5.05,6.12l-5.08,.99c-.68-1.32-2.13-3.71-4.68-6.06-.06-.06-.03-.16,.05-.17Z" />
					<path d="M499.13,1137.53l4.6-.83s.06,0,.08,.02c.84,.73,1.57,1.47,2.2,2.2l.77,4.35-3.12,.56c-.66-1.33-2.08-3.74-4.58-6.13-.06-.06-.03-.16,.05-.17Z" />
					<path d="M482.86,1147.75l-1.97,.38-.87-4.33c1.39,1.53,2.3,2.97,2.84,3.95Z" />
					<path d="M486.11,1139.98l4.58-.89s.06,0,.08,.02c2.67,2.26,4.23,4.69,5.01,6.13l-5.08,.98c-.67-1.32-2.11-3.71-4.64-6.07-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M531.2,1130.37s-.06,.08-.1,.08l-4.52,.75c-.1,.02-.18-.08-.14-.18l.26-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.08-.47,.03-.56,.25l-.62,1.68s-.06,.08-.1,.08l-4.52,.76c-.1,.02-.18-.08-.14-.18l.26-.71c.08-.22-.03-.47-.25-.56l-.62-.23c-.22-.08-.47,.03-.56,.25l-.62,1.67s-.05,.08-.1,.08l-4.52,.76c-.1,.02-.18-.08-.15-.17l.26-.71c.08-.22-.03-.47-.26-.56l-.62-.23c-.22-.08-.47,.03-.56,.25l-.62,1.67s-.05,.08-.1,.08l-4.51,.81c-.1,.02-.18-.08-.15-.17l.26-.71c.08-.23-.03-.47-.26-.56l-.62-.22c-.22-.08-.47,.04-.55,.26l-.42,1.17-1.04-5.85,.8,.95c.15,.18,.43,.21,.61,.05l.5-.42c.18-.15,.21-.43,.05-.61l-.49-.58c-.06-.08-.02-.2,.08-.21l4.53-.81s.09,0,.12,.04l1.15,1.38c.15,.18,.43,.21,.61,.06l.5-.42c.18-.15,.21-.43,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.54-.76s.09,0,.12,.05l1.14,1.38c.15,.18,.43,.21,.61,.06l.51-.42c.18-.15,.21-.43,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.54-.76s.09,0,.12,.05l1.14,1.38c.15,.18,.43,.21,.61,.06l.51-.42c.18-.15,.21-.43,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.54-.76s.09,.01,.12,.05l.34,.42,.96,5.86-.19,.5Z" />
				<g>
					<path d="M521.68,1123.39l-4.63,.78c-.08,.01-.14-.07-.11-.15,1.55-3.1,2.08-5.85,2.26-7.32l5.19-.87c-.25,1.61-.9,4.45-2.64,7.51-.02,.03-.04,.05-.07,.05Z" />
					<path d="M508.52,1125.62l-4.63,.83c-.08,.01-.14-.07-.11-.14,1.53-3.12,2.04-5.88,2.21-7.35,1.73-.31,3.46-.62,5.19-.91-.24,1.62-.88,4.43-2.59,7.52-.01,.03-.04,.05-.07,.05Z" />
					<path d="M528.25,1122.28l-4.63,.78c-.08,.01-.14-.07-.11-.15,1.56-3.1,2.1-5.85,2.28-7.33l3.17-.52,.72,4.36c-.36,.89-.8,1.84-1.35,2.8-.02,.03-.04,.05-.07,.05Z" />
					<path d="M502.57,1119.57l2.02-.36c-.16,1.1-.5,2.78-1.25,4.71l-.77-4.35Z" />
					<path d="M515.1,1124.49l-4.63,.78c-.08,.01-.14-.07-.11-.15,1.54-3.1,2.07-5.85,2.24-7.32l5.19-.87c-.24,1.61-.89,4.45-2.61,7.51-.02,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M518.73,1134.08l4.61-.78s.06,0,.08,.02c2.63,2.32,4.15,4.79,4.9,6.24l-5.13,.86c-.65-1.33-2.04-3.76-4.52-6.18-.06-.06-.03-.16,.05-.17Z" />
					<path d="M505.66,1136.35l4.6-.83s.06,0,.08,.02c2.63,2.29,4.18,4.78,4.94,6.22-1.71,.28-3.42,.59-5.12,.89-.65-1.33-2.06-3.74-4.55-6.14-.06-.06-.03-.16,.05-.17Z" />
					<path d="M525.27,1132.98l4.61-.78s.06,0,.08,.02c.83,.74,1.55,1.5,2.18,2.23l.72,4.36-3.14,.52c-.64-1.33-2.02-3.76-4.5-6.18-.06-.06-.03-.16,.05-.17Z" />
					<path d="M508.77,1142.91l-1.98,.36-.77-4.35c1.35,1.55,2.24,3.01,2.76,3.99Z" />
					<path d="M512.18,1135.19l4.61-.78s.06,0,.08,.02c2.63,2.32,4.17,4.79,4.92,6.24l-5.13,.86c-.65-1.33-2.05-3.76-4.54-6.18-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M557.47,1126.14s-.06,.08-.1,.08l-4.54,.73c-.1,.02-.18-.08-.14-.18l.27-.71c.09-.22-.03-.47-.25-.56l-.61-.23c-.22-.09-.47,.03-.56,.25l-.64,1.67s-.06,.08-.1,.08l-4.53,.73c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.09-.47,.03-.56,.25l-.63,1.67s-.06,.08-.1,.08l-4.53,.73c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.09-.47,.03-.56,.25l-.63,1.67s-.06,.08-.1,.08l-4.53,.73c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.09-.47,.03-.56,.25l-.44,1.17-.96-5.86,.79,.97c.15,.19,.42,.21,.61,.06l.51-.42c.19-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.54-.74s.09,.01,.12,.05l1.13,1.39c.15,.19,.42,.21,.61,.06l.51-.42c.19-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.54-.74s.09,.01,.12,.05l1.13,1.39c.15,.19,.42,.21,.61,.06l.51-.42c.19-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.54-.73s.09,.01,.12,.05l1.13,1.39c.15,.19,.42,.21,.61,.06l.51-.41c.19-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.54-.73s.09,.01,.12,.05l.34,.42,.94,5.86-.19,.5Z" />
				<g>
					<path d="M547.98,1119.08l-4.63,.75c-.08,.01-.14-.07-.1-.15,1.58-3.09,2.12-5.84,2.31-7.31l5.16-.83c-.25,1.61-.92,4.44-2.67,7.48-.02,.03-.04,.05-.07,.05Z" />
					<path d="M534.83,1121.21l-4.63,.75c-.08,.01-.14-.07-.11-.15,1.57-3.09,2.11-5.84,2.29-7.31l5.18-.84c-.25,1.61-.91,4.44-2.66,7.49-.02,.03-.04,.05-.07,.05Z" />
					<path d="M554.55,1118.03l-4.63,.74c-.08,.01-.14-.07-.1-.15,1.58-3.09,2.12-5.83,2.31-7.3l3.16-.51,.7,4.36c-.36,.89-.8,1.83-1.36,2.8-.02,.03-.04,.05-.07,.05Z" />
					<path d="M528.96,1115.06l2.01-.33c-.17,1.1-.53,2.77-1.3,4.69l-.72-4.36Z" />
					<path d="M541.4,1120.14l-4.63,.75c-.08,.01-.14-.07-.1-.15,1.57-3.09,2.12-5.84,2.3-7.31l5.18-.84c-.25,1.61-.92,4.45-2.67,7.49-.02,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M544.95,1129.76l4.62-.75s.06,0,.08,.03c2.62,2.34,4.13,4.82,4.88,6.28l-5.17,.83c-.64-1.34-2.01-3.77-4.47-6.21-.06-.06-.03-.16,.05-.17Z" />
					<path d="M531.83,1131.89l4.62-.75s.06,0,.08,.02c2.62,2.34,4.14,4.82,4.88,6.27l-5.15,.83c-.64-1.34-2.02-3.77-4.49-6.21-.06-.06-.03-.16,.05-.17Z" />
					<path d="M551.51,1128.7l4.63-.74s.06,0,.08,.03c.83,.74,1.55,1.5,2.17,2.24l.7,4.36-3.16,.51c-.64-1.34-2.02-3.77-4.48-6.22-.06-.06-.03-.16,.05-.17Z" />
					<path d="M534.86,1138.49l-2,.32-.72-4.36c1.34,1.58,2.21,3.04,2.72,4.03Z" />
					<path d="M538.39,1130.82l4.62-.75s.06,0,.08,.02c2.61,2.34,4.13,4.82,4.87,6.27l-5.15,.83c-.64-1.34-2.02-3.77-4.48-6.21-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M583.75,1121.89s-.06,.08-.1,.08l-4.54,.74c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.08-.47,.03-.56,.25l-.63,1.67s-.06,.08-.1,.08l-4.54,.74c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.61-.23c-.22-.08-.47,.03-.56,.25l-.63,1.67s-.06,.08-.1,.08l-4.54,.74c-.1,.02-.18-.08-.14-.18l.27-.71c.09-.22-.03-.47-.25-.56l-.61-.23c-.22-.09-.47,.03-.56,.25l-.64,1.67s-.06,.08-.1,.08l-4.54,.73c-.1,.02-.18-.08-.14-.18l.27-.71c.09-.22-.03-.47-.25-.56l-.61-.23c-.22-.09-.47,.03-.56,.25l-.44,1.17-.94-5.86,.79,.97c.15,.19,.42,.21,.61,.06l.51-.41c.19-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.54-.73s.09,.01,.12,.05l1.13,1.39c.15,.19,.42,.21,.61,.06l.51-.41c.19-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.53-.74s.09,.01,.12,.05l1.13,1.39c.15,.19,.42,.21,.61,.06l.51-.42c.19-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.53-.74s.09,.01,.12,.05l1.13,1.38c.15,.19,.42,.21,.61,.06l.51-.42c.19-.15,.21-.42,.06-.61l-.48-.59c-.06-.08-.02-.2,.08-.21l4.53-.74s.09,.01,.12,.05l.34,.42,.97,5.86-.19,.5Z" />
				<g>
					<path d="M574.24,1114.85l-4.62,.75c-.08,.01-.14-.07-.1-.15,1.57-3.09,2.11-5.84,2.29-7.31l5.15-.84c-.25,1.61-.9,4.44-2.64,7.49-.02,.03-.04,.05-.07,.05Z" />
					<path d="M561.12,1116.98l-4.63,.74c-.08,.01-.14-.07-.1-.15,1.58-3.09,2.12-5.83,2.31-7.3l5.16-.83c-.25,1.61-.92,4.44-2.67,7.48-.02,.03-.04,.05-.07,.05Z" />
					<path d="M580.8,1113.79l-4.62,.75c-.08,.01-.14-.07-.1-.15,1.57-3.09,2.1-5.83,2.28-7.3l3.15-.51,.72,4.36c-.35,.89-.79,1.84-1.35,2.8-.02,.03-.04,.05-.07,.05Z" />
					<path d="M555.28,1110.82l2.01-.32c-.17,1.1-.54,2.77-1.31,4.68l-.7-4.36Z" />
					<path d="M567.68,1115.92l-4.62,.75c-.08,.01-.14-.07-.1-.15,1.58-3.09,2.12-5.83,2.3-7.3l5.15-.84c-.25,1.61-.91,4.44-2.65,7.49-.02,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M571.23,1125.53l4.63-.75s.06,0,.08,.02c2.62,2.34,4.15,4.81,4.9,6.26l-5.18,.84c-.64-1.34-2.03-3.77-4.5-6.2-.06-.06-.03-.16,.05-.17Z" />
					<path d="M558.08,1127.65l4.63-.74s.06,0,.08,.03c2.62,2.34,4.13,4.82,4.88,6.28l-5.17,.83c-.64-1.34-2.02-3.77-4.48-6.22-.06-.06-.03-.16,.05-.17Z" />
					<path d="M577.8,1124.47l4.63-.75s.06,0,.08,.02c.83,.74,1.56,1.5,2.18,2.23l.72,4.36-3.16,.51c-.64-1.34-2.03-3.77-4.51-6.2-.06-.06-.03-.16,.05-.17Z" />
					<path d="M561.1,1134.26l-2.01,.32-.7-4.36c1.33,1.58,2.2,3.05,2.71,4.04Z" />
					<path d="M564.65,1126.6l4.63-.75s.06,0,.08,.02c2.62,2.34,4.14,4.81,4.89,6.26l-5.18,.84c-.64-1.34-2.02-3.77-4.48-6.21-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M610.03,1117.36s-.05,.08-.1,.08l-4.53,.82c-.1,.02-.18-.08-.15-.17l.26-.71c.08-.23-.03-.47-.26-.55l-.62-.22c-.23-.08-.47,.04-.56,.26l-.61,1.68s-.05,.08-.1,.08l-4.54,.78c-.1,.02-.18-.08-.15-.17l.26-.71c.08-.22-.03-.47-.26-.56l-.62-.23c-.22-.08-.47,.03-.56,.26l-.62,1.68s-.05,.08-.1,.08l-4.54,.77c-.1,.02-.18-.08-.15-.18l.26-.71c.08-.22-.03-.47-.25-.56l-.62-.23c-.22-.08-.47,.03-.56,.26l-.62,1.68s-.06,.08-.1,.08l-4.54,.77c-.1,.02-.18-.08-.14-.18l.27-.71c.08-.22-.03-.47-.25-.56l-.62-.23c-.22-.08-.47,.03-.56,.26l-.44,1.17-.97-5.86,.79,.96c.15,.18,.42,.21,.61,.06l.51-.42c.18-.15,.21-.43,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.52-.77s.09,0,.12,.05l1.14,1.38c.15,.18,.42,.21,.61,.06l.51-.42c.18-.15,.21-.43,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.52-.76s.09,0,.12,.05l1.14,1.38c.15,.18,.43,.21,.61,.06l.5-.42c.18-.15,.21-.43,.06-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.52-.77s.09,0,.12,.04l1.14,1.37c.15,.18,.43,.21,.61,.05l.5-.42c.18-.15,.21-.43,.05-.61l-.48-.58c-.06-.08-.02-.2,.08-.21l4.51-.81s.09,0,.12,.04l.35,.41,1.05,5.85-.18,.51Z" />
				<g>
					<path d="M600.44,1110.48l-4.61,.78c-.08,.01-.14-.07-.11-.15,1.54-3.1,2.05-5.85,2.21-7.32l5.11-.9c-.22,1.61-.83,4.47-2.54,7.53-.01,.03-.04,.05-.07,.05Z" />
					<path d="M587.35,1112.7l-4.61,.78c-.08,.01-.14-.07-.11-.15,1.56-3.1,2.08-5.85,2.26-7.32l5.13-.87c-.24,1.61-.87,4.45-2.6,7.51-.02,.03-.04,.05-.07,.05Z" />
					<path d="M606.97,1109.31l-4.6,.83c-.08,.01-.14-.07-.11-.14,1.52-3.12,2.01-5.87,2.17-7.35l3.12-.56,.78,4.35c-.34,.9-.76,1.85-1.3,2.83-.01,.03-.04,.05-.07,.05Z" />
					<path d="M581.5,1106.58l2-.34c-.16,1.1-.52,2.77-1.28,4.69l-.72-4.36Z" />
					<path d="M593.9,1111.59l-4.61,.78c-.08,.01-.14-.07-.11-.15,1.55-3.1,2.06-5.85,2.23-7.32l5.13-.87c-.23,1.61-.86,4.45-2.57,7.5-.01,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M597.53,1121.17l4.63-.78s.06,0,.09,.02c2.65,2.31,4.21,4.75,4.98,6.19l-5.19,.91c-.66-1.33-2.07-3.75-4.56-6.17-.06-.06-.03-.16,.05-.17Z" />
					<path d="M584.38,1123.4l4.63-.78s.06,0,.08,.02c2.64,2.32,4.18,4.79,4.94,6.23l-5.19,.88c-.65-1.33-2.04-3.75-4.52-6.18-.06-.06-.03-.16,.05-.17Z" />
					<path d="M604.11,1120.05l4.63-.84s.06,0,.09,.02c.85,.73,1.58,1.47,2.22,2.19l.78,4.35-3.17,.57c-.66-1.33-2.09-3.73-4.59-6.13-.06-.06-.03-.16,.05-.17Z" />
					<path d="M587.44,1129.99l-2.02,.34-.72-4.36c1.34,1.57,2.22,3.03,2.74,4.02Z" />
					<path d="M590.96,1122.29l4.63-.78s.06,0,.09,.02c2.64,2.32,4.2,4.78,4.96,6.23l-5.19,.88c-.65-1.33-2.06-3.75-4.54-6.17-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M636.26,1112.38s-.05,.08-.1,.09l-4.52,.89c-.1,.02-.18-.08-.15-.17l.24-.72c.08-.23-.04-.47-.27-.55l-.62-.21c-.23-.08-.47,.04-.55,.27l-.58,1.69s-.05,.08-.1,.09l-4.52,.89c-.1,.02-.18-.08-.15-.17l.25-.72c.08-.23-.04-.47-.27-.55l-.62-.21c-.23-.08-.47,.04-.55,.27l-.59,1.69s-.05,.08-.1,.09c-1.51,.3-3.02,.59-4.53,.85-.1,.02-.18-.08-.15-.17l.25-.71c.08-.23-.04-.47-.26-.55l-.62-.22c-.23-.08-.47,.04-.55,.26l-.6,1.68s-.05,.08-.1,.08l-4.53,.82c-.1,.02-.18-.08-.15-.17l.26-.71c.08-.23-.04-.47-.26-.55l-.62-.22c-.23-.08-.47,.04-.55,.26l-.42,1.17-1.05-5.85,.8,.95c.15,.18,.43,.21,.61,.05l.5-.42c.18-.15,.2-.43,.05-.61l-.49-.58c-.07-.08-.02-.2,.08-.21l4.51-.81s.09,0,.12,.04l1.16,1.36c.16,.18,.43,.21,.61,.05l.5-.42c.18-.15,.2-.43,.05-.61l-.49-.58c-.07-.08-.02-.2,.08-.21,1.5-.26,3-.55,4.5-.85,.05,0,.09,0,.12,.04l1.16,1.35c.16,.18,.43,.2,.61,.04l.5-.43c.18-.16,.2-.43,.04-.61l-.49-.57c-.07-.08-.02-.2,.07-.21l4.49-.88s.09,0,.12,.04l1.17,1.35c.16,.18,.43,.2,.61,.04l.49-.43c.18-.16,.2-.43,.04-.61l-.5-.57c-.07-.08-.03-.2,.07-.21l4.49-.88s.09,0,.12,.04l.36,.41,1.18,5.82-.17,.51Z" />
				<g>
					<path d="M626.51,1105.67l-4.58,.9c-.08,.02-.14-.07-.11-.14,1.47-3.14,1.92-5.9,2.06-7.38l5.07-1c-.19,1.62-.74,4.47-2.37,7.57-.01,.03-.04,.05-.07,.05Z" />
					<path d="M613.49,1108.13l-4.6,.83c-.08,.01-.14-.07-.11-.14,1.51-3.12,1.99-5.87,2.14-7.34l5.1-.92c-.21,1.61-.8,4.45-2.47,7.53-.01,.03-.04,.05-.07,.05Z" />
					<path d="M633.01,1104.39l-4.58,.9c-.08,.02-.14-.07-.11-.14,1.46-3.14,1.89-5.9,2.01-7.37l3.1-.61,.88,4.33c-.32,.9-.72,1.86-1.24,2.84-.01,.03-.04,.05-.07,.05Z" />
					<path d="M607.56,1102.08l1.98-.36c-.15,1.1-.47,2.78-1.2,4.71l-.78-4.35Z" />
					<path d="M620.02,1106.94c-1.52,.3-3.06,.56-4.59,.84-.08,.01-.14-.07-.11-.14,1.5-3.11,1.96-5.86,2.11-7.34l5.08-.99c-.2,1.62-.76,4.47-2.42,7.57-.01,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M623.82,1116.45l4.62-.91s.06,0,.09,.02c2.7,2.25,4.32,4.67,5.13,6.1l-5.19,1.02c-.69-1.32-2.15-3.7-4.69-6.06-.06-.06-.03-.16,.05-.17Z" />
					<path d="M610.68,1118.86l4.63-.84s.06,0,.09,.02c2.68,2.29,4.27,4.73,5.05,6.17l-5.19,.94c-.67-1.32-2.11-3.73-4.62-6.12-.06-.06-.03-.16,.05-.17Z" />
					<path d="M630.38,1115.16l4.62-.91s.06,0,.09,.02c.86,.71,1.62,1.44,2.27,2.15l.88,4.33-3.17,.62c-.69-1.31-2.17-3.7-4.73-6.05-.06-.06-.03-.16,.05-.17Z" />
					<path d="M613.84,1125.41l-2.02,.36-.78-4.35c1.37,1.55,2.27,3,2.8,3.98Z" />
					<path d="M617.25,1117.68c1.54-.28,3.09-.54,4.63-.85,.03,0,.06,0,.09,.02,2.69,2.25,4.29,4.68,5.08,6.1l-5.19,1.01c-.68-1.32-2.13-3.73-4.65-6.12-.06-.06-.03-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M662.36,1106.65s-.05,.08-.1,.09l-4.49,1.08c-.1,.02-.18-.07-.15-.16l.22-.73c.07-.23-.06-.47-.29-.54l-.63-.19c-.23-.07-.47,.07-.54,.29l-.53,1.7s-.05,.08-.1,.09l-4.51,.98c-.1,.02-.18-.07-.15-.17l.23-.72c.07-.23-.05-.47-.28-.54l-.63-.2c-.23-.07-.47,.05-.55,.28l-.54,1.7s-.05,.08-.1,.09l-4.51,.98c-.1,.02-.18-.07-.15-.17l.23-.72c.07-.23-.05-.47-.28-.55l-.63-.2c-.23-.07-.47,.05-.55,.28l-.56,1.7s-.05,.08-.1,.09c-1.5,.32-3,.66-4.51,.96-.1,.02-.18-.08-.15-.17l.24-.71c.08-.23-.05-.47-.27-.55l-.62-.21c-.23-.08-.47,.04-.55,.27l-.4,1.18-1.18-5.82,.83,.94c.16,.18,.43,.2,.61,.04l.49-.43c.18-.16,.19-.43,.04-.61l-.5-.57c-.07-.08-.03-.2,.07-.21,1.5-.3,2.98-.64,4.47-.96,.05,0,.09,0,.12,.04l1.19,1.33c.16,.18,.43,.19,.61,.03l.49-.44c.18-.16,.19-.43,.03-.61l-.5-.56c-.07-.07-.03-.2,.07-.22l4.47-.97s.09,0,.12,.04l1.2,1.32c.16,.18,.43,.19,.61,.03l.48-.44c.18-.16,.19-.43,.03-.61l-.51-.56c-.07-.07-.03-.19,.07-.22l4.47-.97s.09,0,.12,.04l1.21,1.32c.16,.18,.44,.18,.61,.02l.48-.45c.17-.17,.18-.44,.02-.61l-.51-.55c-.07-.07-.03-.19,.07-.22l4.44-1.07s.09,0,.12,.04l.37,.39,1.37,5.78-.16,.52Z" />
				<g>
					<path d="M652.43,1100.27l-4.55,.99c-.08,.02-.15-.06-.11-.14,1.39-3.17,1.75-5.93,1.85-7.4,1.68-.38,3.37-.7,5.03-1.12-.13,1.64-.59,4.51-2.14,7.62-.01,.03-.04,.05-.07,.05Z" />
					<path d="M639.5,1103.07c-1.52,.34-3.04,.66-4.56,.94-.08,.02-.15-.07-.11-.14,1.44-3.13,1.85-5.9,1.96-7.38l5.04-1.09c-.16,1.62-.67,4.48-2.26,7.62-.01,.03-.04,.05-.07,.05Z" />
					<path d="M658.87,1098.78c-1.51,.36-3.01,.74-4.53,1.08-.08,.02-.15-.06-.11-.14,1.36-3.17,1.69-5.98,1.77-7.45l3.05-.74,1.02,4.3c-.29,.92-.66,1.89-1.14,2.9-.01,.03-.04,.05-.07,.06Z" />
					<path d="M633.44,1097.17l1.97-.39c-.12,1.1-.41,2.78-1.09,4.72l-.88-4.33Z" />
					<path d="M645.96,1101.67l-4.55,.99c-.08,.02-.15-.07-.11-.14,1.41-3.17,1.8-5.94,1.9-7.41l5.04-1.09c-.15,1.62-.64,4.48-2.21,7.61-.01,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M650.03,1111.08l4.61-1s.06,0,.09,.02c2.77,2.2,4.47,4.56,5.31,5.95-1.72,.43-3.45,.77-5.18,1.15-.72-1.3-2.26-3.65-4.87-5.95-.06-.05-.04-.16,.04-.17Z" />
					<path d="M636.94,1113.87c1.54-.29,3.08-.61,4.61-.95,.03,0,.06,0,.09,.02,2.73,2.2,4.39,4.59,5.21,6l-5.18,1.12c-.7-1.3-2.2-3.66-4.77-6.02-.06-.06-.03-.16,.05-.17Z" />
					<path d="M656.57,1109.67c1.54-.34,3.06-.73,4.59-1.09,.03,0,.06,0,.09,.02,.88,.68,1.66,1.38,2.34,2.06l1.02,4.3-3.16,.76c-.74-1.29-2.29-3.59-4.92-5.87-.06-.05-.04-.16,.04-.17Z" />
					<path d="M640.25,1120.36l-2.02,.4-.88-4.33c1.4,1.53,2.34,2.96,2.9,3.93Z" />
					<path d="M643.49,1112.5l4.61-1s.06,0,.09,.02c2.75,2.19,4.43,4.58,5.26,5.99l-5.18,1.12c-.71-1.3-2.23-3.66-4.82-5.96-.06-.05-.04-.16,.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M688.27,1099.99s-.05,.08-.09,.09l-4.46,1.21c-.1,.03-.19-.06-.16-.16l.19-.73c.06-.23-.08-.47-.31-.53l-.64-.17c-.23-.06-.47,.08-.53,.31l-.46,1.73s-.05,.08-.09,.09l-4.46,1.21c-.1,.03-.19-.06-.16-.16l.2-.73c.06-.23-.07-.47-.3-.53l-.63-.17c-.23-.06-.47,.08-.53,.31l-.48,1.73s-.05,.08-.09,.09l-4.48,1.09c-.1,.02-.19-.07-.16-.16l.21-.72c.07-.23-.07-.47-.3-.54l-.63-.19c-.23-.07-.47,.06-.54,.29l-.5,1.71s-.05,.08-.1,.09l-4.49,1.08c-.1,.02-.19-.07-.16-.16l.22-.73c.07-.23-.06-.47-.29-.54l-.63-.19c-.23-.07-.47,.06-.54,.29l-.36,1.2-1.37-5.78,.85,.91c.16,.17,.44,.18,.61,.02l.47-.45c.17-.16,.18-.44,.02-.61l-.52-.55c-.07-.07-.03-.19,.06-.22l4.44-1.07s.09,0,.13,.04l1.24,1.29c.17,.17,.44,.18,.61,.02l.47-.45c.17-.16,.18-.44,.01-.61l-.52-.55c-.07-.07-.04-.19,.06-.22l4.44-1.08s.09,0,.12,.03l1.24,1.27c.17,.17,.44,.17,.61,0l.46-.47c.17-.17,.17-.44,0-.61l-.53-.54c-.07-.07-.04-.19,.06-.22l4.41-1.2s.09,0,.13,.03l1.26,1.26c.17,.17,.44,.17,.61,0l.46-.47c.17-.17,.17-.44,0-.61l-.53-.53c-.07-.07-.04-.19,.06-.22l4.41-1.2s.09,0,.13,.03l.38,.38,1.6,5.72-.14,.52Z" />
				<g>
					<path d="M678.09,1093.98l-4.49,1.22c-.08,.02-.15-.06-.12-.14,1.26-3.24,1.51-6.03,1.55-7.51l4.95-1.34c-.07,1.63-.41,4.51-1.83,7.72-.01,.03-.04,.05-.07,.06Z" />
					<path d="M665.29,1097.23l-4.52,1.09c-.08,.02-.15-.06-.11-.14,1.33-3.2,1.64-5.97,1.71-7.45l5-1.21c-.11,1.62-.51,4.49-2,7.65-.01,.03-.04,.05-.07,.06Z" />
					<path d="M684.46,1092.25l-4.49,1.22c-.08,.02-.15-.06-.12-.13,1.23-3.24,1.46-6.01,1.48-7.49l3.02-.82,1.19,4.25c-.25,.92-.58,1.9-1.02,2.92-.01,.03-.04,.05-.07,.06Z" />
					<path d="M659.05,1091.53l1.94-.47c-.08,1.11-.31,2.8-.92,4.77l-1.02-4.3Z" />
					<path d="M671.71,1095.68l-4.52,1.09c-.08,.02-.15-.06-.12-.14,1.3-3.19,1.59-5.96,1.64-7.44,1.67-.39,3.33-.82,4.97-1.28-.09,1.62-.45,4.55-1.91,7.71-.01,.03-.04,.05-.07,.06Z" />
				</g>
				<g>
					<path d="M676.11,1104.94l4.56-1.24s.06,0,.09,.02c2.85,2.05,4.64,4.35,5.55,5.71l-5.15,1.4c-.77-1.26-2.4-3.54-5.09-5.71-.06-.05-.04-.16,.04-.18Z" />
					<path d="M663.09,1108.11l4.59-1.11s.06,0,.09,.02c2.81,2.13,4.56,4.47,5.43,5.86l-5.17,1.25c-.75-1.28-2.33-3.6-4.98-5.84-.06-.05-.04-.16,.04-.18Z" />
					<path d="M682.58,1103.18l4.56-1.24s.06,0,.09,.02c.91,.65,1.72,1.32,2.43,1.98l1.19,4.25-3.15,.85c-.79-1.26-2.44-3.53-5.16-5.69-.06-.05-.04-.15,.04-.18Z" />
					<path d="M666.61,1114.46l-2.01,.49-1.02-4.3c1.45,1.47,2.44,2.87,3.03,3.81Z" />
					<path d="M669.6,1106.54l4.59-1.11s.06,0,.09,.02c2.83,2.12,4.59,4.38,5.48,5.76-1.71,.48-3.43,.93-5.16,1.33-.76-1.28-2.36-3.59-5.04-5.82-.06-.05-.04-.16,.04-.18Z" />
				</g>
			</g>
			<g>
				<path d="M713.88,1092.19s-.04,.08-.09,.1c-1.46,.48-2.9,1.02-4.38,1.45-.1,.03-.19-.06-.17-.15l.16-.73c.05-.23-.1-.46-.33-.52l-.65-.15c-.24-.05-.47,.09-.52,.32l-.39,1.74s-.04,.08-.09,.1l-4.42,1.35c-.1,.03-.19-.06-.17-.15l.17-.74c.05-.23-.09-.47-.33-.52l-.64-.15c-.23-.05-.47,.09-.52,.33l-.4,1.74s-.04,.08-.09,.1l-4.42,1.35c-.1,.03-.19-.06-.17-.16l.18-.74c.06-.23-.09-.47-.32-.52l-.64-.15c-.23-.05-.47,.09-.52,.33l-.42,1.75s-.05,.08-.09,.1c-1.47,.47-2.96,.87-4.44,1.27-.1,.03-.19-.06-.16-.16l.19-.73c.06-.23-.08-.47-.31-.53l-.64-.17c-.23-.06-.47,.08-.53,.31l-.31,1.2-1.6-5.72,.89,.88c.17,.17,.45,.17,.61,0l.46-.46c.17-.17,.16-.44,0-.61l-.54-.53c-.07-.07-.04-.19,.06-.22,1.47-.39,2.94-.79,4.39-1.25,.04-.01,.09,0,.13,.03l1.29,1.23c.17,.16,.44,.15,.61-.02l.45-.48c.16-.18,.16-.45-.02-.61l-.55-.52c-.07-.07-.04-.19,.05-.22l4.36-1.34s.09,0,.13,.03l1.3,1.22c.17,.16,.45,.15,.61-.02l.44-.48c.16-.17,.15-.45-.02-.61l-.55-.52c-.07-.07-.05-.19,.05-.22l4.36-1.34s.09,0,.13,.03l1.32,1.21c.18,.16,.45,.15,.61-.02l.44-.48c.16-.17,.15-.45-.03-.61l-.56-.51c-.07-.07-.05-.19,.05-.22,1.46-.42,2.89-.96,4.34-1.43,.04-.01,.09,0,.13,.03l.4,.36,1.87,5.64-.11,.53Z" />
				<g>
					<path d="M703.43,1086.65l-4.44,1.36c-.08,.02-.15-.05-.12-.13,1.12-3.27,1.24-6.05,1.21-7.53l4.89-1.5c0,1.62-.2,4.5-1.47,7.74-.01,.03-.04,.05-.06,.06Z" />
					<path d="M690.82,1090.51c-1.49,.43-2.99,.82-4.48,1.23-.08,.02-.15-.06-.12-.13,1.2-3.23,1.4-6,1.4-7.47,1.65-.44,3.27-.98,4.9-1.47-.03,1.63-.3,4.53-1.64,7.79-.01,.03-.04,.05-.06,.06Z" />
					<path d="M709.72,1084.67c-1.47,.52-2.95,.96-4.43,1.41-.08,.02-.15-.05-.12-.13,1.08-3.26,1.18-6.02,1.13-7.52l2.98-1.01,1.39,4.19c-.21,.94-.49,1.95-.88,2.99-.01,.03-.03,.05-.06,.06Z" />
					<path d="M684.35,1085.02l1.92-.52c-.04,1.11-.2,2.8-.73,4.78l-1.19-4.25Z" />
					<path d="M697.13,1088.58l-4.44,1.36c-.08,.02-.15-.05-.12-.13,1.15-3.28,1.31-6.07,1.29-7.55l4.89-1.5c-.01,1.63-.25,4.51-1.56,7.76-.01,.03-.04,.05-.06,.06Z" />
				</g>
				<g>
					<path d="M701.93,1097.63l4.52-1.39s.06,0,.09,.01c2.96,1.95,4.86,4.17,5.83,5.5l-5.11,1.57c-.83-1.23-2.57-3.44-5.36-5.52-.07-.05-.05-.15,.03-.18Z" />
					<path d="M689.06,1101.42c1.52-.42,3.04-.81,4.56-1.25,.03,0,.06,0,.09,.01,2.89,1.97,4.74,4.21,5.67,5.55-1.71,.51-3.4,1.07-5.12,1.54-.8-1.26-2.48-3.52-5.23-5.67-.07-.05-.05-.15,.03-.18Z" />
					<path d="M708.35,1095.66c1.51-.46,3.02-.91,4.5-1.44,.03,0,.06,0,.09,.01,.94,.6,1.77,1.22,2.5,1.84l1.39,4.19-3.08,1.04c-.84-1.21-2.61-3.42-5.44-5.48-.07-.05-.05-.15,.03-.18Z" />
					<path d="M692.85,1107.65l-2,.54-1.19-4.25c1.52,1.42,2.57,2.78,3.19,3.71Z" />
					<path d="M695.51,1099.59l4.52-1.39s.06,0,.09,.01c2.92,1.96,4.8,4.19,5.75,5.53l-5.11,1.57c-.82-1.24-2.52-3.46-5.28-5.54-.07-.05-.05-.15,.03-.18Z" />
				</g>
			</g>
			<g>
				<path d="M739.1,1083.34s-.04,.08-.08,.1l-4.33,1.59c-.1,.03-.19-.05-.17-.15l.13-.75c.04-.24-.12-.46-.35-.5l-.65-.11c-.24-.04-.46,.12-.5,.35l-.32,1.77s-.04,.08-.08,.1l-4.33,1.58c-.1,.03-.19-.05-.17-.15l.14-.74c.04-.24-.11-.46-.35-.51l-.65-.13c-.24-.05-.46,.11-.51,.34l-.34,1.75s-.04,.08-.09,.1l-4.37,1.48c-.1,.03-.19-.05-.17-.15l.15-.74c.05-.23-.11-.46-.34-.51l-.65-.13c-.24-.05-.46,.11-.51,.34l-.35,1.75s-.04,.08-.09,.1l-4.37,1.48c-.1,.03-.19-.05-.17-.15l.15-.74c.05-.24-.1-.46-.34-.51l-.64-.13c-.23-.05-.46,.11-.51,.34l-.25,1.23-1.87-5.64,.93,.83c.18,.16,.45,.14,.61-.04l.43-.49c.16-.18,.14-.45-.04-.61l-.56-.5c-.08-.07-.05-.19,.04-.22l4.33-1.47s.09,0,.13,.03l1.34,1.18c.18,.16,.45,.14,.61-.04l.43-.49c.16-.18,.14-.45-.04-.61l-.57-.5c-.08-.07-.05-.19,.04-.22l4.33-1.47s.09,0,.13,.03l1.35,1.17c.18,.16,.46,.14,.61-.04l.43-.49c.16-.18,.13-.45-.05-.61l-.57-.5c-.08-.07-.05-.19,.04-.22l4.29-1.57s.09,0,.13,.02l1.36,1.15c.18,.15,.45,.13,.61-.05l.42-.5c.15-.18,.13-.46-.05-.61l-.58-.49c-.08-.06-.06-.19,.04-.22l4.29-1.58s.09,0,.13,.02l.41,.35,2.06,5.57-.09,.53Z" />
				<g>
					<path d="M728.47,1078.23c-1.46,.54-2.91,1.08-4.39,1.56-.08,.03-.15-.05-.13-.13,1-3.28,1.02-6.1,.93-7.58l4.83-1.78c.06,1.63-.04,4.53-1.18,7.86-.01,.03-.03,.05-.06,.06Z" />
					<path d="M715.98,1082.54l-4.41,1.5c-.08,.03-.15-.05-.13-.13,1.04-3.31,1.12-6.11,1.05-7.59l4.87-1.65c.03,1.63-.12,4.52-1.33,7.81-.01,.03-.03,.05-.06,.06Z" />
					<path d="M734.68,1075.95l-4.37,1.61c-.08,.03-.15-.04-.13-.12,.96-3.33,.96-6.13,.85-7.61l2.95-1.09,1.53,4.14c-.17,.94-.42,1.95-.78,3,0,.03-.03,.05-.06,.06Z" />
					<path d="M709.27,1077.42l1.9-.64c.01,1.11-.07,2.82-.51,4.84l-1.39-4.19Z" />
					<path d="M722.24,1080.42l-4.41,1.5c-.08,.03-.15-.05-.13-.13,1.02-3.3,1.07-6.09,1-7.57l4.87-1.66c.05,1.63-.09,4.51-1.27,7.79-.01,.03-.03,.05-.06,.06Z" />
				</g>
				<g>
					<path d="M727.42,1089.28c1.49-.49,2.97-1.04,4.44-1.58,.03-.01,.06,0,.09,0,3.01,1.8,4.98,3.94,5.99,5.22l-4.99,1.83c-.88-1.2-2.68-3.31-5.56-5.3-.07-.05-.06-.15,.02-.18Z" />
					<path d="M714.73,1093.59l4.47-1.52s.06,0,.09,.01c2.98,1.87,4.92,4.05,5.91,5.35l-5.03,1.71c-.85-1.21-2.63-3.38-5.46-5.38-.07-.05-.05-.15,.03-.18Z" />
					<path d="M733.72,1087.01l4.43-1.63s.06,0,.09,0c.96,.57,1.82,1.17,2.57,1.77l1.53,4.14-3.05,1.12c-.89-1.19-2.71-3.31-5.6-5.24-.07-.05-.06-.15,.02-.18Z" />
					<path d="M718.79,1099.61l-1.96,.66-1.39-4.19c1.57,1.33,2.68,2.64,3.34,3.53Z" />
					<path d="M721.07,1091.43l4.47-1.52s.06,0,.09,.01c3,1.86,4.96,4.04,5.96,5.33l-5.03,1.71c-.87-1.21-2.66-3.37-5.51-5.36-.07-.05-.05-.15,.02-.18Z" />
				</g>
			</g>
			<g>
				<path d="M764.03,1073.68s-.04,.09-.08,.1l-4.29,1.7c-.09,.04-.19-.04-.18-.14l.11-.75c.03-.24-.13-.46-.37-.49l-.65-.1c-.24-.03-.46,.13-.49,.37l-.26,1.77s-.04,.09-.08,.1l-4.29,1.7c-.09,.04-.19-.04-.18-.14l.11-.75c.04-.24-.13-.46-.36-.49l-.65-.1c-.24-.04-.46,.13-.49,.37l-.27,1.77s-.04,.09-.08,.1l-4.29,1.7c-.09,.04-.19-.04-.18-.14l.12-.75c.04-.24-.12-.46-.36-.49l-.65-.1c-.23-.03-.46,.12-.5,.36l-.29,1.76s-.04,.08-.08,.1l-4.33,1.59c-.1,.03-.19-.05-.18-.15l.13-.74c.04-.24-.12-.46-.36-.5l-.65-.11c-.24-.04-.46,.12-.5,.35l-.21,1.23-2.06-5.57,.96,.8c.18,.15,.46,.13,.61-.05l.42-.5c.15-.18,.13-.46-.06-.61l-.58-.48c-.08-.06-.06-.19,.04-.22l4.29-1.58s.09,0,.13,.02l1.39,1.14c.19,.15,.46,.13,.61-.06l.41-.52c.15-.19,.12-.46-.06-.61l-.58-.47c-.08-.06-.06-.19,.04-.23l4.25-1.68s.09,0,.13,.02l1.39,1.11c.19,.15,.46,.12,.61-.07l.41-.51c.15-.19,.12-.46-.07-.61l-.59-.47c-.08-.06-.06-.19,.03-.22l4.25-1.68s.09,0,.13,.02l1.4,1.11c.19,.15,.46,.12,.61-.07l.4-.51c.15-.19,.11-.46-.07-.61l-.59-.47c-.08-.06-.06-.19,.03-.22l4.25-1.68s.09,0,.13,.02l.43,.33,2.25,5.5-.07,.53Z" />
				<g>
					<path d="M753.2,1068.86l-4.33,1.71c-.08,.03-.15-.04-.13-.12,.87-3.36,.8-6.15,.66-7.62l4.79-1.9c.12,1.62,.11,4.52-.92,7.86,0,.03-.03,.05-.06,.06Z" />
					<path d="M740.88,1073.67l-4.37,1.61c-.08,.03-.15-.04-.13-.12,.93-3.33,.91-6.11,.8-7.59,1.61-.6,3.23-1.16,4.82-1.8,.1,1.64,.04,4.54-1.06,7.84,0,.03-.03,.05-.06,.06Z" />
					<path d="M759.34,1066.43l-4.33,1.71c-.08,.03-.15-.04-.13-.12,.85-3.35,.75-6.13,.6-7.6l2.93-1.16,1.68,4.09c-.14,.94-.36,1.95-.68,3.01,0,.03-.03,.05-.06,.06Z" />
					<path d="M733.99,1068.74l1.88-.69c.05,1.11,.03,2.81-.35,4.83l-1.53-4.14Z" />
					<path d="M747.05,1071.3c-1.45,.56-2.88,1.16-4.34,1.69-.08,.03-.15-.04-.13-.12,.91-3.32,.85-6.15,.72-7.62l4.79-1.9c.11,1.63,.08,4.53-.98,7.88,0,.03-.03,.05-.06,.06Z" />
				</g>
				<g>
					<path d="M752.53,1079.95l4.39-1.74s.06,0,.09,0c3.08,1.72,5.13,3.8,6.18,5.05l-4.94,1.96c-.92-1.17-2.8-3.24-5.73-5.09-.07-.04-.06-.15,.02-.18Z" />
					<path d="M740,1084.7l4.43-1.63s.06,0,.09,0c3.05,1.79,5.07,3.9,6.09,5.16-1.64,.66-3.32,1.24-4.98,1.86-.9-1.19-2.75-3.3-5.65-5.21-.07-.05-.06-.15,.02-.18Z" />
					<path d="M758.75,1077.48l4.39-1.74s.06,0,.09,0c.98,.54,1.86,1.12,2.64,1.7l1.68,4.09-3.02,1.2c-.93-1.16-2.83-3.23-5.79-5.07-.07-.04-.06-.15,.02-.18Z" />
					<path d="M744.28,1090.6l-1.94,.71-1.53-4.14c1.62,1.29,2.77,2.56,3.47,3.43Z" />
					<path d="M746.29,1082.39c1.48-.54,2.93-1.15,4.4-1.72,.03-.01,.06,0,.09,0,3.05,1.72,5.08,3.81,6.13,5.07l-4.94,1.96c-.91-1.18-2.76-3.23-5.69-5.14-.07-.05-.06-.15,.02-.18Z" />
				</g>
			</g>
			<g>
				<path d="M788.57,1063.07s-.03,.09-.08,.11l-4.2,1.91c-.09,.04-.19-.03-.18-.13l.08-.76c.03-.24-.15-.45-.38-.48l-.65-.06c-.24-.02-.45,.15-.48,.39l-.19,1.79s-.03,.09-.08,.11l-4.24,1.82c-.09,.04-.19-.04-.18-.14l.09-.75c.03-.24-.14-.45-.38-.48l-.66-.08c-.24-.03-.45,.14-.48,.38l-.21,1.77s-.04,.09-.08,.1l-4.25,1.8c-.09,.04-.19-.04-.18-.14l.09-.75c.03-.24-.14-.45-.38-.48l-.65-.08c-.24-.03-.45,.14-.48,.38l-.22,1.78s-.04,.09-.08,.11l-4.25,1.8c-.09,.04-.19-.04-.18-.14l.1-.75c.03-.24-.14-.46-.37-.49l-.65-.08c-.24-.03-.45,.14-.49,.38l-.17,1.24-2.25-5.5,.98,.76c.19,.15,.46,.11,.6-.08l.4-.53c.14-.19,.11-.46-.08-.61l-.6-.46c-.08-.06-.06-.19,.03-.23l4.21-1.79s.09-.01,.13,.02l1.42,1.08c.19,.14,.46,.11,.6-.08l.39-.52c.14-.19,.1-.46-.09-.61l-.6-.46c-.08-.06-.07-.19,.03-.23l4.21-1.79s.09-.01,.13,.02l1.43,1.07c.19,.14,.46,.11,.61-.09l.39-.52c.14-.19,.1-.46-.09-.61l-.61-.45c-.08-.06-.07-.19,.03-.22l4.2-1.8s.09-.01,.13,.01l1.44,1.05c.19,.14,.46,.1,.6-.1l.38-.54c.14-.19,.1-.47-.1-.61l-.61-.44c-.08-.06-.07-.19,.02-.23l4.16-1.89s.09-.01,.13,.01l.44,.32,2.44,5.41-.05,.54Z" />
				<g>
					<path d="M777.61,1058.72l-4.29,1.82c-.08,.03-.16-.04-.14-.12,.76-3.37,.6-6.14,.41-7.61,1.59-.66,3.15-1.36,4.71-2.08,.17,1.61,.29,4.55-.64,7.92,0,.03-.03,.05-.06,.07Z" />
					<path d="M765.45,1063.89l-4.29,1.82c-.08,.03-.15-.04-.14-.12,.81-3.38,.68-6.18,.52-7.66l4.74-2.01c.15,1.62,.19,4.53-.78,7.9,0,.03-.03,.05-.06,.07Z" />
					<path d="M783.64,1056.01l-4.24,1.93c-.08,.03-.15-.03-.14-.11,.72-3.4,.52-6.19,.32-7.66l2.86-1.3,1.82,4.03c-.11,.95-.29,1.98-.57,3.06,0,.03-.03,.05-.06,.07Z" />
					<path d="M758.41,1059.27l1.84-.78c.09,1.11,.13,2.82-.17,4.87l-1.68-4.09Z" />
					<path d="M771.53,1061.3l-4.29,1.82c-.08,.03-.15-.04-.14-.12,.79-3.38,.64-6.16,.46-7.63l4.74-2.01c.16,1.62,.23,4.51-.72,7.88,0,.03-.03,.05-.06,.07Z" />
				</g>
				<g>
					<path d="M777.31,1069.78l4.34-1.85s.06-.01,.09,0c3.14,1.62,5.24,3.58,6.34,4.8-1.61,.74-3.22,1.47-4.86,2.14-.96-1.14-2.92-3.16-5.92-4.92-.07-.04-.07-.15,.01-.18Z" />
					<path d="M764.98,1075.01l4.34-1.84s.06-.01,.09,0c3.1,1.65,5.18,3.68,6.26,4.91l-4.89,2.08c-.94-1.15-2.85-3.18-5.81-4.97-.07-.04-.06-.15,.01-.18Z" />
					<path d="M783.46,1067.14l4.29-1.95s.06-.01,.09,0c1,.5,1.89,1.04,2.69,1.58l1.82,4.03-2.96,1.34c-.97-1.12-2.93-3.11-5.94-4.82-.07-.04-.07-.15,0-.18Z" />
					<path d="M769.45,1080.73l-1.9,.81-1.68-4.09c1.66,1.22,2.85,2.44,3.58,3.28Z" />
					<path d="M771.14,1072.4l4.34-1.84s.06-.01,.09,0c3.12,1.64,5.22,3.67,6.31,4.89l-4.89,2.08c-.95-1.14-2.88-3.17-5.87-4.94-.07-.04-.06-.15,.01-.18Z" />
				</g>
			</g>
			<g>
				<path d="M812.75,1051.68s-.03,.09-.07,.11l-4.15,2.01c-.09,.04-.2-.03-.19-.13l.06-.75c.02-.24-.16-.45-.4-.46l-.66-.05c-.24-.02-.45,.16-.46,.4l-.13,1.78s-.03,.09-.07,.11l-4.15,2.01c-.09,.04-.2-.03-.19-.13l.06-.76c.02-.24-.16-.45-.4-.47l-.65-.05c-.24-.02-.45,.16-.47,.4l-.15,1.79s-.03,.09-.07,.11c-1.38,.69-2.79,1.3-4.19,1.94-.09,.04-.2-.03-.19-.13l.07-.75c.02-.24-.15-.45-.39-.47l-.66-.06c-.24-.02-.45,.15-.47,.39l-.17,1.78s-.03,.09-.08,.11l-4.2,1.91c-.09,.04-.19-.03-.19-.13l.07-.75c.02-.24-.15-.45-.39-.47l-.65-.06c-.24-.02-.45,.15-.47,.39l-.12,1.24-2.44-5.41,1.01,.73c.19,.14,.46,.09,.6-.1l.38-.53c.14-.19,.09-.46-.1-.6l-.61-.44c-.08-.06-.07-.18,.02-.23l4.16-1.89s.09-.01,.13,.01l1.46,1.04c.2,.14,.47,.09,.6-.1l.38-.53c.14-.19,.09-.46-.11-.6l-.62-.44c-.08-.06-.07-.18,.02-.23,1.38-.64,2.78-1.24,4.15-1.92,.04-.02,.09-.02,.13,.01l1.46,1.01c.2,.14,.46,.08,.6-.11l.37-.54c.13-.2,.08-.47-.11-.6l-.62-.43c-.08-.06-.07-.18,.02-.23l4.12-1.99s.09-.02,.13,0l1.47,1.01c.2,.13,.47,.08,.6-.11l.37-.54c.13-.2,.08-.47-.12-.6l-.62-.43c-.08-.06-.07-.18,.02-.23l4.12-1.99s.09-.02,.13,0l.45,.3,2.63,5.33-.04,.54Z" />
				<g>
					<path d="M801.63,1047.66l-4.19,2.03c-.07,.04-.16-.03-.14-.11,.63-3.42,.37-6.2,.12-7.67l4.63-2.24c.23,1.61,.43,4.51-.37,7.92,0,.03-.03,.06-.05,.07Z" />
					<path d="M789.66,1053.27l-4.24,1.93c-.08,.03-.16-.03-.14-.11,.7-3.39,.48-6.17,.27-7.64l4.69-2.13c.2,1.61,.34,4.5-.52,7.89,0,.03-.03,.05-.06,.07Z" />
					<path d="M807.58,1044.78l-4.19,2.03c-.07,.04-.16-.03-.14-.11,.61-3.41,.32-6.18,.07-7.64l2.83-1.37,1.95,3.96c-.08,.95-.22,1.98-.47,3.06,0,.03-.03,.06-.05,.07Z" />
					<path d="M782.45,1048.85l1.82-.83c.13,1.1,.23,2.81,0,4.85l-1.82-4.03Z" />
					<path d="M795.68,1050.54l-4.24,1.93c-.07,.03-.16-.03-.14-.11,.68-3.38,.44-6.15,.21-7.61,1.57-.69,3.1-1.48,4.65-2.21,.22,1.62,.39,4.52-.43,7.95,0,.03-.03,.05-.06,.07Z" />
				</g>
				<g>
					<path d="M801.73,1058.78l4.25-2.06s.06-.01,.09,0c3.18,1.49,5.37,3.42,6.51,4.59l-4.78,2.31c-.99-1.1-3.01-3.03-6.06-4.67-.07-.04-.07-.14,0-.18Z" />
					<path d="M789.56,1064.37l4.3-1.95s.06-.01,.09,0c3.16,1.56,5.32,3.53,6.44,4.73l-4.84,2.2c-.98-1.12-2.97-3.1-5.99-4.8-.07-.04-.07-.15,0-.18Z" />
					<path d="M807.76,1055.87l4.25-2.06s.06-.01,.09,0c1.02,.47,1.93,.98,2.75,1.5l1.95,3.96-2.92,1.42c-1.01-1.09-3.05-3.02-6.12-4.64-.07-.04-.07-.14,0-.18Z" />
					<path d="M794.23,1069.94l-1.88,.85-1.82-4.03c1.71,1.17,2.94,2.35,3.7,3.17Z" />
					<path d="M795.66,1061.6l4.3-1.95s.06-.01,.09,0c3.16,1.5,5.33,3.44,6.45,4.62-1.6,.76-3.18,1.57-4.8,2.28-.99-1.11-3-3.08-6.05-4.77-.07-.04-.07-.15,0-.18Z" />
				</g>
			</g>
			<g>
				<path d="M836.53,1039.46s-.03,.09-.07,.11c-1.35,.73-2.69,1.49-4.06,2.18-.09,.05-.2-.02-.19-.12l.03-.75c0-.24-.18-.44-.42-.45l-.66-.03c-.24-.01-.44,.17-.45,.41l-.08,1.78s-.03,.09-.07,.11l-4.1,2.11c-.09,.05-.2-.02-.19-.12l.04-.76c.01-.24-.17-.44-.41-.45l-.66-.03c-.24-.01-.44,.17-.45,.41l-.09,1.79s-.03,.09-.07,.11l-4.1,2.11c-.09,.05-.2-.02-.19-.12l.04-.76c.01-.24-.17-.44-.41-.46l-.66-.03c-.24-.01-.44,.17-.46,.41l-.1,1.79s-.03,.09-.07,.11c-1.36,.72-2.74,1.4-4.13,2.06-.09,.04-.2-.03-.19-.13l.05-.75c.02-.24-.17-.44-.41-.46l-.66-.05c-.24-.02-.45,.16-.46,.4l-.08,1.24-2.63-5.33,1.04,.7c.2,.13,.47,.08,.6-.11l.36-.54c.13-.2,.08-.47-.12-.6l-.63-.42c-.08-.06-.08-.18,.01-.23,1.38-.65,2.74-1.33,4.09-2.04,.04-.02,.09-.02,.13,0l1.49,.98c.2,.13,.47,.07,.6-.13l.36-.55c.13-.2,.07-.47-.13-.6l-.63-.41c-.08-.05-.08-.18,.01-.23l4.06-2.09s.09-.02,.13,0l1.5,.97c.2,.13,.47,.07,.6-.13l.35-.55c.13-.2,.07-.47-.13-.6l-.64-.41c-.08-.05-.08-.18,0-.23l4.07-2.09s.09-.02,.13,0l1.51,.96c.2,.13,.47,.07,.6-.13l.35-.55c.13-.2,.07-.47-.14-.6l-.64-.41c-.09-.05-.08-.18,0-.23,1.36-.68,2.69-1.44,4.03-2.16,.04-.02,.09-.02,.13,0l.46,.28,2.81,5.23-.02,.54Z" />
				<g>
					<path d="M825.28,1035.83l-4.14,2.13c-.07,.04-.16-.03-.14-.11,.52-3.42,.17-6.18-.12-7.64l4.58-2.36c.28,1.6,.57,4.47-.12,7.9,0,.03-.03,.06-.05,.07Z" />
					<path d="M813.52,1041.88c-1.38,.71-2.79,1.36-4.18,2.05-.07,.04-.16-.03-.14-.11,.59-3.4,.28-6.16,.02-7.61l4.58-2.35c.26,1.61,.51,4.51-.22,7.95,0,.03-.03,.06-.05,.07Z" />
					<path d="M831.13,1032.76c-1.35,.76-2.74,1.46-4.12,2.17-.07,.04-.16-.02-.15-.11,.5-3.41,.13-6.15-.18-7.62l2.76-1.51,2.09,3.89c-.04,.96-.15,2-.35,3.1,0,.03-.02,.06-.05,.07Z" />
					<path d="M806.15,1037.69l1.8-.87c.17,1.1,.32,2.79,.15,4.83l-1.95-3.96Z" />
					<path d="M819.4,1038.85l-4.14,2.13c-.07,.04-.16-.03-.14-.11,.55-3.43,.21-6.21-.07-7.66l4.58-2.36c.27,1.6,.54,4.49-.17,7.92,0,.03-.03,.06-.05,.07Z" />
				</g>
				<g>
					<path d="M825.74,1046.9l4.19-2.16s.06-.02,.09,0c3.24,1.4,5.5,3.26,6.68,4.4l-4.72,2.43c-1.03-1.07-3.12-2.94-6.24-4.49-.07-.04-.08-.14,0-.18Z" />
					<path d="M813.79,1052.95c1.41-.69,2.84-1.35,4.24-2.07,.03-.01,.06-.02,.09,0,3.2,1.42,5.41,3.3,6.57,4.45l-4.73,2.42c-1.02-1.09-3.08-3-6.17-4.62-.07-.04-.07-.14,0-.18Z" />
					<path d="M831.69,1043.83c1.4-.72,2.8-1.43,4.17-2.2,.03-.01,.06-.02,.09,0,1.03,.43,1.96,.9,2.78,1.39l2.09,3.89-2.85,1.56c-1.03-1.04-3.15-2.92-6.28-4.45-.07-.04-.08-.14,0-.18Z" />
					<path d="M818.66,1058.37l-1.86,.9-1.95-3.96c1.75,1.12,3.03,2.27,3.81,3.06Z" />
					<path d="M819.79,1049.97l4.19-2.16s.06-.02,.09,0c3.22,1.41,5.46,3.28,6.62,4.43l-4.72,2.43c-1.02-1.07-3.09-2.96-6.18-4.52-.07-.04-.07-.14,0-.18Z" />
				</g>
			</g>
			<g>
				<path d="M859.86,1026.41s-.02,.09-.07,.11l-3.99,2.31c-.09,.05-.2-.01-.2-.11v-.76c0-.24-.19-.43-.43-.43h-.66c-.24,0-.43,.19-.43,.43v1.79s-.03,.09-.07,.11l-4,2.3c-.09,.05-.2-.02-.2-.12v-.75c.02-.24-.18-.44-.42-.44h-.66c-.24-.02-.44,.17-.44,.41l-.03,1.78s-.03,.09-.07,.11l-4.05,2.21c-.09,.05-.2-.02-.19-.12l.02-.76c0-.24-.19-.44-.43-.44h-.66c-.24-.02-.44,.17-.44,.41l-.04,1.79s-.03,.09-.07,.11l-4.05,2.21c-.09,.05-.2-.02-.19-.12l.02-.76c0-.24-.18-.44-.42-.44l-.66-.02c-.24,0-.44,.18-.45,.42l-.04,1.25-2.81-5.23,1.06,.65c.2,.13,.47,.06,.59-.14l.34-.56c.12-.2,.06-.47-.14-.6l-.64-.4c-.09-.05-.08-.18,0-.23l4.01-2.19s.09-.02,.13,0l1.52,.93c.2,.12,.47,.06,.59-.14l.34-.56c.12-.2,.06-.47-.15-.59l-.65-.39c-.09-.05-.08-.18,0-.23l4.01-2.19s.09-.02,.13,0l1.54,.92c.21,.12,.47,.06,.6-.14l.34-.56c.12-.2,.06-.47-.15-.59l-.65-.39c-.09-.05-.08-.18,0-.23l3.96-2.28s.09-.02,.13,0l1.54,.9c.21,.12,.47,.05,.59-.16l.33-.57c.12-.21,.05-.47-.16-.59l-.65-.38c-.09-.05-.09-.18,0-.23l3.96-2.29s.09-.02,.13,0l.47,.27,2.99,5.14v.54Z" />
				<g>
					<path d="M848.51,1023.2c-1.34,.78-2.69,1.56-4.06,2.29-.07,.04-.16-.02-.15-.1,.42-3.41-.04-6.18-.38-7.63l4.46-2.58c.34,1.59,.74,4.47,.18,7.95,0,.03-.02,.06-.05,.07Z" />
					<path d="M836.94,1029.59l-4.09,2.23c-.07,.04-.16-.02-.15-.1,.46-3.44,.05-6.21-.27-7.65l4.52-2.47c.31,1.6,.65,4.47,.03,7.92,0,.03-.02,.06-.05,.07Z" />
					<path d="M854.23,1019.89l-4.03,2.33c-.07,.04-.16-.02-.15-.1,.37-3.45-.11-6.2-.46-7.64l2.72-1.58,2.22,3.82c-.01,.96-.08,2-.25,3.09,0,.03-.02,.06-.05,.07Z" />
					<path d="M829.45,1025.7l1.76-.96c.21,1.09,.42,2.79,.33,4.85l-2.09-3.89Z" />
					<path d="M842.74,1026.42l-4.09,2.23c-.07,.04-.16-.02-.15-.1,.44-3.43,0-6.18-.32-7.63l4.52-2.47c.32,1.59,.69,4.46,.08,7.89,0,.03-.02,.06-.05,.07Z" />
				</g>
				<g>
					<path d="M849.36,1034.27c1.39-.74,2.75-1.53,4.11-2.32,.03-.02,.06-.02,.09,0,3.27,1.26,5.58,3.03,6.79,4.12l-4.6,2.66c-1.07-1.02-3.21-2.81-6.39-4.27-.08-.03-.08-.14,0-.18Z" />
					<path d="M837.6,1040.69l4.14-2.26s.06-.02,.09,0c3.26,1.33,5.54,3.15,6.74,4.26l-4.66,2.55c-1.05-1.05-3.17-2.88-6.3-4.36-.07-.04-.08-.14,0-.18Z" />
					<path d="M855.18,1030.96l4.08-2.36s.06-.02,.09,0c1.04,.4,1.99,.84,2.84,1.3l2.22,3.82-2.81,1.63c-1.08-1.02-3.24-2.8-6.41-4.2-.08-.03-.08-.14,0-.18Z" />
					<path d="M842.64,1045.92l-1.81,.99-2.09-3.89c1.78,1.04,3.09,2.14,3.9,2.9Z" />
					<path d="M843.48,1037.48l4.14-2.26s.06-.02,.09,0c3.28,1.32,5.58,3.12,6.79,4.23l-4.66,2.55c-1.06-1.04-3.2-2.86-6.35-4.33-.08-.03-.08-.14,0-.18Z" />
				</g>
			</g>
			<g>
				<path d="M882.76,1012.64s-.02,.09-.06,.12l-3.94,2.41c-.09,.05-.2,0-.2-.11l-.02-.75c0-.24-.21-.43-.45-.42l-.66,.02c-.24,0-.43,.21-.42,.44l.05,1.79s-.02,.09-.06,.12l-3.94,2.41c-.09,.05-.2,0-.2-.11l-.02-.76c0-.24-.2-.43-.44-.42l-.66,.02c-.24,0-.43,.21-.42,.45l.04,1.79s-.02,.09-.06,.12l-3.94,2.41c-.09,.05-.2,0-.2-.11v-.76c-.02-.24-.21-.43-.45-.43h-.65c-.24,.02-.43,.21-.43,.45v1.78s-.01,.09-.05,.11l-3.99,2.31c-.09,.05-.2-.01-.2-.11v-.76c0-.24-.2-.43-.44-.43h-.66c-.24,0-.43,.2-.43,.44v1.25s-2.98-5.14-2.98-5.14l1.08,.62c.21,.12,.47,.05,.59-.16l.33-.57c.12-.21,.05-.47-.16-.59l-.66-.38c-.09-.05-.09-.18,0-.23l3.96-2.29s.09-.02,.13,0l1.56,.89c.21,.12,.47,.05,.59-.16l.32-.58c.12-.21,.04-.48-.17-.59l-.66-.37c-.09-.05-.09-.18,0-.23l3.9-2.39s.09-.03,.13,0l1.56,.86c.21,.12,.47,.04,.59-.17l.31-.58c.11-.21,.04-.47-.17-.59l-.66-.36c-.09-.05-.09-.17,0-.23l3.9-2.39s.09-.03,.13,0l1.57,.85c.21,.11,.47,.04,.59-.17l.31-.58c.11-.21,.03-.47-.18-.59l-.67-.36c-.09-.05-.09-.17,0-.23l3.9-2.39s.09-.03,.13,0l.48,.26,3.16,5.03,.02,.54Z" />
				<g>
					<path d="M871.27,1009.74l-3.97,2.43c-.07,.04-.16-.01-.15-.1,.28-3.46-.26-6.19-.65-7.62l4.39-2.69c.39,1.58,.88,4.43,.43,7.9,0,.03-.02,.06-.05,.07Z" />
					<path d="M859.95,1016.58l-4.03,2.33c-.07,.04-.16-.02-.15-.1,.35-3.44-.15-6.18-.51-7.61,1.48-.87,2.98-1.7,4.45-2.59,.38,1.6,.81,4.45,.29,7.9,0,.03-.02,.06-.05,.07Z" />
					<path d="M876.91,1006.29l-3.97,2.43c-.07,.04-.16-.01-.15-.1,.26-3.44-.3-6.17-.7-7.59l2.69-1.64,2.35,3.74c.02,.95-.02,1.99-.16,3.09,0,.03-.02,.06-.05,.07Z" />
					<path d="M852.31,1012.9l1.73-1c.24,1.08,.51,2.76,.49,4.82l-2.22-3.82Z" />
					<path d="M865.63,1013.19c-1.33,.8-2.63,1.64-3.99,2.41-.07,.04-.16-.02-.15-.1,.34-3.42-.22-6.2-.59-7.63l4.39-2.69c.38,1.58,.85,4.45,.38,7.93,0,.03-.02,.06-.05,.07Z" />
				</g>
				<g>
					<path d="M872.5,1020.78l4.02-2.46s.06-.02,.09,0c3.32,1.16,5.7,2.86,6.95,3.92l-4.53,2.77c-1.1-.99-3.31-2.72-6.52-4.04-.08-.03-.09-.14-.01-.18Z" />
					<path d="M860.98,1027.6l4.08-2.36s.06-.02,.09,0c3.31,1.24,5.66,2.99,6.89,4.04-1.51,.92-3.06,1.78-4.59,2.68-1.09-1.01-3.27-2.78-6.46-4.17-.08-.03-.08-.14-.01-.18Z" />
					<path d="M878.21,1017.28l4.02-2.46s.06-.02,.09,0c1.06,.37,2.03,.79,2.89,1.22l2.35,3.74-2.77,1.69c-1.11-.99-3.34-2.7-6.57-4.01-.08-.03-.09-.14-.02-.18Z" />
					<path d="M866.2,1032.68l-1.79,1.03-2.22-3.82c1.82,.99,3.17,2.05,4.01,2.78Z" />
					<path d="M866.77,1024.25c1.37-.77,2.69-1.63,4.04-2.44,.03-.02,.06-.02,.09,0,3.3,1.18,5.66,2.89,6.9,3.95l-4.53,2.77c-1.1-1.01-3.27-2.71-6.48-4.09-.08-.03-.08-.14-.01-.18Z" />
				</g>
			</g>
			<g>
				<path d="M905.13,998s-.02,.09-.06,.12l-3.81,2.6c-.08,.06-.2,0-.2-.1l-.05-.76c-.02-.24-.22-.42-.46-.4l-.65,.05c-.24,.02-.42,.23-.4,.47l.12,1.79s-.02,.09-.06,.12l-3.87,2.51c-.09,.05-.2,0-.2-.1l-.04-.75c-.01-.24-.22-.42-.46-.41l-.66,.03c-.24,.01-.42,.22-.41,.45l.09,1.78s-.02,.09-.06,.12l-3.88,2.5c-.08,.05-.2,0-.2-.1l-.04-.76c-.01-.24-.21-.42-.45-.41l-.66,.03c-.24,.01-.42,.22-.41,.46l.08,1.79s-.02,.09-.06,.12l-3.88,2.5c-.08,.05-.2,0-.2-.1l-.03-.76c-.01-.24-.21-.43-.45-.41l-.65,.03c-.24,.01-.42,.22-.41,.46l.05,1.25-3.16-5.03,1.1,.58c.21,.11,.47,.03,.58-.19l.3-.59c.11-.21,.03-.48-.18-.59l-.67-.35c-.09-.05-.09-.17,0-.23l3.84-2.48s.09-.03,.13,0l1.58,.82c.21,.11,.47,.03,.58-.19l.3-.58c.11-.21,.02-.47-.19-.58l-.67-.35c-.09-.05-.1-.17-.01-.23l3.84-2.48s.09-.03,.13,0l1.59,.81c.21,.11,.47,.02,.58-.19l.3-.58c.11-.21,.02-.47-.19-.58l-.68-.34c-.09-.05-.1-.17-.01-.23l3.84-2.48s.09-.03,.13,0l1.59,.79c.21,.11,.47,.02,.58-.2l.29-.59c.1-.22,.02-.48-.2-.58l-.67-.33c-.09-.04-.1-.17-.02-.23l3.78-2.57s.09-.03,.13,0l.48,.24,3.33,4.92,.04,.54Z" />
				<g>
					<path d="M893.6,995.58l-3.91,2.53c-.07,.04-.16,0-.16-.09,.18-3.45-.46-6.16-.9-7.57l2.16-1.4,2.13-1.45c.44,1.56,1.06,4.44,.72,7.91,0,.03-.02,.06-.05,.07Z" />
					<path d="M882.49,1002.75l-3.91,2.52c-.07,.04-.16-.01-.15-.09,.23-3.46-.38-6.2-.79-7.63l4.33-2.79c.42,1.57,.96,4.43,.58,7.92,0,.03-.02,.06-.05,.08Z" />
					<path d="M899.08,991.88l-3.85,2.62c-.07,.05-.16,0-.15-.09,.13-3.48-.54-6.19-.99-7.61l2.6-1.77,2.48,3.66c.06,.96,.06,2-.04,3.11,0,.03-.02,.06-.04,.08Z" />
					<path d="M874.77,999.39l1.69-1.08c.28,1.08,.62,2.76,.66,4.82l-2.35-3.74Z" />
					<path d="M888.04,999.16l-3.91,2.53c-.07,.04-.16,0-.15-.09,.2-3.46-.42-6.18-.85-7.6l4.33-2.79c.43,1.57,.99,4.41,.63,7.89,0,.03-.02,.06-.05,.08Z" />
				</g>
				<g>
					<path d="M895.18,1006.53l3.96-2.56s.06-.02,.09-.01c3.38,1.07,5.77,2.64,7.07,3.65l-2.2,1.5-2.23,1.44c-1.14-.96-3.41-2.61-6.67-3.84-.08-.03-.09-.13-.02-.18Z" />
					<path d="M883.93,1013.79l3.97-2.55s.06-.02,.09-.01c3.33,1.09,5.73,2.75,7,3.77l-4.46,2.88c-1.12-.97-3.35-2.64-6.58-3.91-.08-.03-.09-.14-.02-.18Z" />
					<path d="M900.8,1002.88l3.9-2.66s.06-.02,.09-.01c1.07,.32,2.04,.7,2.92,1.1l2.48,3.66-2.68,1.83c-1.14-.94-3.42-2.56-6.67-3.74-.08-.03-.09-.13-.02-.18Z" />
					<path d="M889.31,1018.66l-1.74,1.11-2.35-3.74c1.85,.93,3.23,1.92,4.09,2.63Z" />
					<path d="M889.56,1010.16l3.96-2.56s.06-.02,.09-.01c3.35,1.08,5.77,2.72,7.05,3.74l-4.46,2.88c-1.13-.96-3.38-2.63-6.62-3.87-.08-.03-.09-.13-.02-.18Z" />
				</g>
			</g>
			<g>
				<path d="M927.02,982.65s-.02,.09-.05,.12l-3.75,2.69c-.08,.06-.2,0-.21-.09l-.07-.75c-.02-.24-.24-.41-.48-.39l-.65,.07c-.24,.02-.41,.24-.39,.47l.17,1.78s-.02,.09-.05,.12l-3.75,2.69c-.08,.06-.2,0-.21-.09l-.07-.76c-.02-.24-.23-.41-.47-.39l-.65,.06c-.24,.02-.41,.24-.39,.48l.16,1.79s-.02,.09-.06,.12c-1.24,.91-2.53,1.74-3.8,2.62-.08,.06-.2,0-.21-.1l-.06-.75c-.02-.24-.23-.42-.47-.4l-.66,.05c-.24,.02-.42,.23-.4,.46l.14,1.78s-.02,.09-.06,.12l-3.81,2.6c-.08,.06-.2,0-.2-.1l-.06-.76c-.02-.24-.23-.42-.46-.4l-.66,.05c-.24,.02-.42,.23-.4,.47l.09,1.25-3.33-4.92,1.12,.54c.21,.1,.47,.01,.58-.2l.28-.59c.1-.22,.01-.47-.2-.58l-.68-.33c-.09-.04-.1-.17-.02-.23l3.78-2.57s.09-.03,.13,0l1.61,.77c.22,.1,.48,.01,.58-.2l.28-.59c.1-.21,.01-.47-.21-.58l-.68-.33c-.09-.04-.1-.17-.02-.23,1.25-.87,2.53-1.69,3.76-2.6,.04-.03,.09-.03,.13-.01l1.61,.75c.22,.1,.47,0,.57-.21l.27-.6c.1-.22,0-.48-.21-.58l-.68-.32c-.09-.04-.1-.17-.02-.23l3.71-2.67s.09-.03,.13-.01l1.62,.74c.22,.1,.47,0,.57-.22l.27-.6c.1-.22,0-.47-.22-.57l-.69-.31c-.09-.04-.1-.17-.02-.23l3.71-2.67s.09-.03,.13-.01l.49,.22,3.5,4.8,.06,.54Z" />
				<g>
					<path d="M915.38,980.59l-3.78,2.72c-.07,.05-.16,0-.16-.09,.04-3.48-.7-6.17-1.19-7.57l4.18-3c.5,1.55,1.19,4.37,.99,7.87,0,.03-.02,.06-.04,.08Z" />
					<path d="M904.54,988.16l-3.85,2.62c-.07,.05-.16,0-.16-.09,.11-3.46-.58-6.17-1.04-7.57l4.26-2.9c.47,1.55,1.11,4.38,.83,7.86,0,.03-.02,.06-.04,.08Z" />
					<path d="M920.75,976.73l-3.78,2.72c-.07,.05-.16,0-.16-.08,.02-3.46-.74-6.15-1.24-7.54l2.56-1.84,2.6,3.57c.09,.95,.12,1.99,.06,3.1,0,.03-.02,.06-.04,.08Z" />
					<path d="M896.68,985.04l1.65-1.13c.32,1.07,.7,2.73,.82,4.79l-2.48-3.66Z" />
					<path d="M910,984.44l-3.85,2.62c-.07,.05-.16,0-.16-.09,.09-3.45-.62-6.14-1.09-7.54,1.43-.95,2.81-1.97,4.21-2.97,.49,1.55,1.16,4.39,.93,7.9,0,.03-.02,.06-.04,.08Z" />
				</g>
				<g>
					<path d="M917.38,991.53l3.83-2.75s.06-.02,.09-.02c3.39,.92,5.87,2.45,7.19,3.41l-4.32,3.1c-1.17-.91-3.49-2.48-6.77-3.56-.08-.03-.09-.13-.03-.18Z" />
					<path d="M906.33,999.11l3.9-2.66s.06-.02,.09-.01c3.38,1,5.84,2.57,7.15,3.56l-4.39,2.99c-1.15-.94-3.45-2.54-6.72-3.7-.08-.03-.09-.13-.02-.18Z" />
					<path d="M922.82,987.63l3.83-2.75s.06-.02,.09-.02c1.08,.29,2.07,.64,2.96,1.01l2.6,3.57-2.64,1.89c-1.18-.91-3.52-2.46-6.82-3.53-.08-.03-.1-.13-.03-.18Z" />
					<path d="M911.88,1003.8l-1.71,1.16-2.48-3.66c1.88,.86,3.3,1.82,4.18,2.49Z" />
					<path d="M911.87,995.34l3.9-2.66s.06-.02,.09-.01c3.38,.95,5.84,2.48,7.15,3.45-1.44,1.03-2.86,2.08-4.34,3.06-1.16-.93-3.48-2.52-6.77-3.67-.08-.03-.09-.13-.02-.18Z" />
				</g>
			</g>
			<g>
				<path d="M948.36,966.55s-.01,.09-.05,.12c-1.21,.96-2.4,1.93-3.63,2.84-.08,.06-.2,.01-.21-.09l-.1-.75c-.03-.24-.25-.4-.49-.37l-.66,.08c-.24,.03-.41,.25-.38,.48l.23,1.77s-.01,.09-.05,.12l-3.68,2.78c-.08,.06-.2,.01-.21-.09l-.09-.75c-.03-.24-.25-.41-.48-.38l-.65,.08c-.24,.03-.41,.25-.38,.48l.22,1.78s-.01,.09-.05,.12l-3.68,2.78c-.08,.06-.2,.01-.21-.09l-.09-.75c-.03-.24-.24-.41-.48-.38l-.65,.08c-.24,.03-.41,.25-.38,.48l.21,1.78s-.01,.09-.05,.12c-1.22,.94-2.46,1.84-3.72,2.73-.08,.06-.2,0-.21-.09l-.08-.75c-.02-.24-.24-.41-.48-.38l-.66,.07c-.24,.02-.41,.24-.39,.47l.13,1.24-3.5-4.8,1.14,.51c.22,.1,.48,0,.57-.22l.27-.6c.1-.22,0-.47-.22-.57l-.69-.31c-.09-.04-.11-.17-.02-.22,1.24-.88,2.48-1.78,3.68-2.71,.04-.03,.09-.03,.13-.02l1.63,.71c.22,.09,.47,0,.57-.23l.26-.61c.09-.22,0-.48-.23-.57l-.69-.3c-.09-.04-.11-.17-.03-.23l3.65-2.76s.09-.03,.13-.02l1.64,.7c.22,.09,.47,0,.57-.23l.25-.6c.09-.22-.01-.47-.23-.57l-.7-.29c-.09-.04-.11-.16-.03-.22l3.65-2.76s.09-.03,.13-.02l1.65,.69c.22,.09,.48-.01,.57-.23l.25-.6c.09-.22-.01-.47-.24-.56l-.7-.29c-.09-.04-.11-.16-.03-.22,1.23-.91,2.41-1.87,3.6-2.82,.04-.03,.09-.04,.13-.02l.5,.2,3.66,4.68,.08,.54Z" />
				<g>
					<path d="M936.65,964.89l-3.71,2.81c-.07,.05-.16,0-.16-.08-.07-3.46-.89-6.12-1.43-7.5l4.11-3.11c.55,1.53,1.33,4.31,1.24,7.8,0,.03-.02,.06-.04,.08Z" />
					<path d="M926.11,972.86c-1.24,.93-2.52,1.82-3.77,2.73-.07,.05-.16,0-.16-.08,0-3.45-.77-6.12-1.28-7.51,1.39-1.01,2.74-2.06,4.12-3.09,.53,1.54,1.27,4.36,1.14,7.87,0,.03-.02,.06-.04,.08Z" />
					<path d="M941.9,960.87c-1.2,.98-2.46,1.9-3.69,2.84-.07,.05-.16,0-.16-.08-.09-3.45-.93-6.09-1.48-7.47l2.46-1.96,2.72,3.48c.12,.95,.2,2,.18,3.11,0,.03-.01,.06-.04,.08Z" />
					<path d="M918.13,969.99l1.63-1.17c.35,1.05,.79,2.69,.98,4.74l-2.6-3.57Z" />
					<path d="M931.38,968.88l-3.71,2.81c-.07,.05-.16,0-.16-.08-.05-3.47-.86-6.15-1.38-7.54l4.11-3.11c.54,1.53,1.3,4.33,1.19,7.84,0,.03-.02,.06-.04,.08Z" />
				</g>
				<g>
					<path d="M939,975.72l3.76-2.85s.06-.03,.09-.02c3.43,.82,5.97,2.27,7.33,3.19l-4.24,3.21c-1.2-.88-3.58-2.36-6.91-3.35-.08-.02-.1-.13-.03-.18Z" />
					<path d="M928.26,983.72c1.27-.92,2.56-1.82,3.82-2.76,.02-.02,.06-.03,.09-.02,3.4,.85,5.9,2.33,7.23,3.26-1.42,1.06-2.81,2.15-4.25,3.19-1.19-.9-3.54-2.43-6.87-3.5-.08-.03-.1-.13-.03-.18Z" />
					<path d="M944.34,971.68c1.25-.95,2.52-1.88,3.74-2.88,.02-.02,.06-.03,.09-.02,1.08,.24,2.08,.55,2.98,.89l2.72,3.48-2.54,2.02c-1.21-.86-3.61-2.34-6.96-3.31-.08-.02-.1-.13-.03-.18Z" />
					<path d="M933.98,988.24l-1.68,1.21-2.6-3.57c1.92,.8,3.37,1.71,4.28,2.36Z" />
					<path d="M933.66,979.76l3.76-2.85s.06-.03,.09-.02c3.41,.84,5.94,2.3,7.28,3.23l-4.24,3.21c-1.19-.88-3.55-2.39-6.86-3.39-.08-.02-.1-.13-.03-.18Z" />
				</g>
			</g>
			<g>
				<path d="M969.1,949.68s0,.09-.04,.12l-3.54,2.96c-.08,.07-.2,.02-.21-.08l-.13-.75c-.04-.24-.26-.39-.5-.35l-.65,.11c-.24,.04-.39,.27-.35,.5l.3,1.77s0,.09-.05,.12c-1.18,.98-2.35,1.99-3.55,2.95-.08,.06-.2,.02-.21-.08l-.12-.74c-.04-.24-.26-.4-.5-.36l-.65,.1c-.24,.04-.4,.26-.36,.49l.28,1.76s-.01,.09-.05,.12l-3.61,2.87c-.08,.06-.2,.02-.21-.08l-.11-.75c-.04-.24-.26-.4-.49-.36l-.65,.1c-.24,.04-.4,.26-.36,.49l.27,1.77s-.01,.09-.05,.12l-3.61,2.87c-.08,.06-.2,.02-.21-.08l-.11-.75c-.03-.24-.25-.4-.49-.37l-.65,.1c-.24,.04-.4,.26-.37,.49l.18,1.24-3.66-4.68,1.15,.46c.22,.09,.47-.02,.56-.24l.24-.61c.09-.22-.02-.47-.24-.56l-.7-.28c-.09-.04-.11-.16-.03-.22l3.58-2.85s.09-.04,.13-.02l1.66,.66c.22,.09,.47-.02,.56-.24l.24-.61c.09-.22-.02-.47-.25-.56l-.7-.28c-.09-.04-.11-.16-.03-.22l3.58-2.85s.09-.04,.13-.02l1.67,.65c.22,.09,.48-.02,.56-.24l.24-.61c.09-.22-.03-.47-.25-.56l-.71-.27c-.09-.04-.11-.16-.04-.22,1.19-.95,2.34-1.95,3.51-2.92,.04-.03,.08-.04,.13-.02l1.67,.62c.22,.08,.47-.03,.55-.26l.22-.62c.08-.22-.03-.47-.26-.56l-.71-.26c-.09-.04-.12-.16-.04-.22l3.5-2.94s.08-.04,.13-.02l.51,.19,3.83,4.54,.09,.53Z" />
				<g>
					<path d="M957.37,948.47c-1.18,1.01-2.38,1.99-3.6,2.95-.06,.05-.16,0-.16-.08-.17-3.43-1.1-6.07-1.69-7.44l3.94-3.31c.61,1.51,1.5,4.28,1.55,7.8,0,.03-.01,.06-.04,.08Z" />
					<path d="M947.07,956.75l-3.64,2.9c-.06,.05-.16,0-.16-.08-.14-3.47-1.02-6.12-1.58-7.5l4.03-3.21c.58,1.52,1.41,4.3,1.39,7.8,0,.03-.01,.06-.04,.08Z" />
					<path d="M962.44,944.22l-3.57,2.99c-.06,.05-.16,0-.16-.07-.23-3.46-1.17-6.09-1.77-7.45l2.41-2.02,2.84,3.38c.15,.94,.26,1.98,.28,3.09,0,.03-.01,.06-.04,.08Z" />
					<path d="M939.03,954.2l1.57-1.25c.39,1.04,.89,2.68,1.16,4.72l-2.72-3.48Z" />
					<path d="M952.24,952.64l-3.64,2.9c-.06,.05-.16,0-.16-.08-.16-3.45-1.05-6.09-1.62-7.46l4.03-3.21c.59,1.51,1.44,4.27,1.44,7.76,0,.03-.01,.06-.04,.08Z" />
				</g>
				<g>
					<path d="M960.11,959.23c1.24-.97,2.45-1.97,3.65-2.99,.02-.02,.06-.03,.09-.02,3.44,.68,6.02,2.02,7.4,2.89l-4.07,3.41c-1.22-.82-3.65-2.22-7.03-3.12-.08-.02-.1-.12-.04-.18Z" />
					<path d="M949.63,967.57l3.69-2.94s.06-.03,.09-.02c3.44,.75,6,2.15,7.37,3.04l-4.16,3.31c-1.21-.85-3.61-2.29-6.95-3.21-.08-.02-.1-.13-.04-.18Z" />
					<path d="M965.28,954.97l3.62-3.03s.06-.03,.09-.02c1.1,.21,2.11,.49,3.02,.8l2.84,3.38-2.49,2.09c-1.24-.82-3.67-2.2-7.04-3.03-.08-.02-.1-.12-.04-.18Z" />
					<path d="M955.49,971.86l-1.62,1.29-2.72-3.48c1.93,.72,3.41,1.58,4.34,2.19Z" />
					<path d="M954.87,963.4l3.69-2.94s.06-.03,.09-.02c3.46,.73,6.03,2.12,7.42,3l-4.16,3.31c-1.22-.84-3.64-2.27-7-3.18-.08-.02-.1-.12-.04-.18Z" />
				</g>
			</g>
			<g>
				<path d="M989.26,932.1s0,.09-.04,.13c-1.12,1.05-2.26,2.09-3.42,3.1-.08,.07-.2,.03-.22-.07l-.15-.74c-.05-.23-.28-.38-.51-.34l-.65,.13c-.24,.05-.39,.28-.34,.51l.36,1.75s0,.09-.04,.12l-3.46,3.05c-.08,.07-.2,.03-.22-.07l-.15-.74c-.05-.23-.28-.39-.51-.34l-.64,.13c-.23,.05-.39,.28-.34,.51l.35,1.76s0,.09-.04,.12l-3.46,3.05c-.08,.07-.19,.03-.21-.07l-.14-.75c-.05-.24-.27-.39-.51-.34l-.64,.13c-.24,.04-.39,.27-.35,.5l.32,1.75s0,.09-.04,.12l-3.54,2.96c-.08,.07-.2,.02-.21-.08l-.13-.74c-.04-.24-.27-.39-.5-.35l-.65,.12c-.24,.04-.39,.27-.35,.5l.22,1.23-3.83-4.54,1.17,.43c.23,.08,.47-.03,.55-.26l.22-.62c.08-.22-.04-.47-.26-.55l-.71-.26c-.1-.03-.12-.16-.04-.22l3.5-2.94s.08-.04,.13-.02l1.69,.6c.23,.08,.48-.04,.56-.26l.21-.62c.08-.23-.04-.47-.27-.55l-.71-.25c-.09-.03-.12-.16-.04-.22l3.43-3.02s.08-.04,.13-.03l1.69,.58c.23,.08,.47-.04,.55-.27l.21-.62c.08-.23-.05-.47-.27-.55l-.71-.24c-.1-.03-.12-.16-.05-.22l3.43-3.02s.08-.04,.13-.03l1.7,.57c.23,.08,.47-.05,.55-.27l.21-.62c.08-.23-.05-.47-.28-.54l-.72-.24c-.1-.03-.12-.15-.05-.22,1.15-1,2.27-2.03,3.39-3.07,.03-.03,.08-.04,.13-.03l.51,.16,4,4.39,.12,.53Z" />
				<g>
					<path d="M977.46,931.3l-3.49,3.08c-.06,.05-.16,.01-.17-.07-.32-3.45-1.34-6.05-1.98-7.38l3.85-3.39c.67,1.48,1.65,4.2,1.81,7.69,0,.03-.01,.06-.03,.08Z" />
					<path d="M967.5,939.98l-3.57,2.99c-.06,.05-.16,.01-.17-.07-.25-3.45-1.21-6.06-1.82-7.41,1.31-1.11,2.64-2.19,3.93-3.32,.65,1.51,1.56,4.24,1.65,7.73,0,.03-.01,.06-.04,.08Z" />
					<path d="M982.4,926.92c-1.13,1.06-2.32,2.06-3.47,3.09-.06,.05-.16,.01-.17-.07-.34-3.43-1.38-6.01-2.03-7.34l2.31-2.13,2.98,3.26c.2,.94,.35,1.97,.42,3.09,0,.03,0,.06-.03,.08Z" />
					<path d="M959.35,937.67l1.53-1.29c.42,1.03,.98,2.63,1.31,4.67l-2.84-3.38Z" />
					<path d="M972.51,935.67c-1.17,1.02-2.31,2.07-3.51,3.05-.06,.05-.16,.01-.17-.07-.25-3.42-1.29-6.07-1.9-7.41l3.85-3.39c.65,1.49,1.62,4.23,1.76,7.74,0,.03-.01,.06-.03,.08Z" />
				</g>
				<g>
					<path d="M980.6,941.95l3.54-3.12s.06-.03,.09-.02c3.48,.57,6.12,1.82,7.55,2.64l-4,3.52c-1.26-.78-3.74-2.09-7.13-2.84-.08-.02-.11-.12-.05-.17Z" />
					<path d="M970.41,950.66l3.62-3.03s.06-.03,.09-.02c3.47,.64,6.1,1.97,7.49,2.79-1.34,1.17-2.71,2.28-4.06,3.43-1.25-.81-3.7-2.17-7.08-2.99-.08-.02-.11-.12-.04-.17Z" />
					<path d="M985.63,937.52c1.17-1.05,2.38-2.06,3.53-3.14,.02-.02,.05-.03,.08-.03,1.1,.16,2.12,.39,3.04,.66l2.98,3.26-2.39,2.21c-1.27-.77-3.77-2.07-7.19-2.79-.08-.02-.11-.12-.05-.17Z" />
					<path d="M976.43,954.76l-1.58,1.33-2.84-3.38c1.96,.66,3.48,1.47,4.43,2.05Z" />
					<path d="M975.54,946.36c1.22-1,2.38-2.07,3.56-3.1,.02-.02,.06-.03,.09-.02,3.46,.58,6.08,1.86,7.49,2.68l-4,3.52c-1.26-.81-3.69-2.1-7.1-2.91-.08-.02-.11-.12-.04-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1008.66,913.68s0,.09-.04,.13l-3.31,3.22c-.07,.07-.19,.04-.22-.06l-.19-.73c-.06-.23-.3-.37-.53-.31l-.64,.16c-.23,.06-.37,.3-.31,.53l.44,1.74s0,.09-.04,.13l-3.31,3.22c-.07,.07-.19,.04-.22-.06l-.18-.74c-.06-.23-.29-.38-.52-.32l-.64,.16c-.23,.06-.37,.29-.32,.53l.42,1.75s0,.09-.04,.13l-3.39,3.14c-.07,.07-.2,.03-.22-.07l-.17-.74c-.05-.23-.29-.38-.52-.32l-.64,.15c-.23,.05-.38,.29-.33,.52l.4,1.74s0,.09-.04,.13l-3.39,3.14c-.07,.07-.19,.03-.22-.07l-.16-.74c-.05-.23-.28-.38-.52-.33l-.64,.15c-.23,.05-.38,.29-.33,.52l.27,1.22-4-4.39,1.18,.38c.23,.07,.47-.05,.54-.28l.19-.63c.07-.23-.06-.47-.28-.54l-.72-.23c-.1-.03-.12-.15-.05-.22l3.35-3.1s.08-.04,.13-.03l1.71,.53c.23,.07,.47-.06,.54-.28l.19-.63c.07-.23-.06-.47-.29-.54l-.72-.22c-.1-.03-.13-.15-.05-.22l3.35-3.11s.08-.04,.13-.03l1.7,.5c.23,.07,.47-.07,.53-.3l.18-.63c.07-.23-.07-.47-.3-.54l-.72-.21c-.1-.03-.13-.15-.05-.22l3.27-3.18s.08-.04,.13-.03l1.72,.49c.23,.07,.47-.07,.53-.3l.18-.63c.06-.23-.07-.47-.3-.53l-.73-.21c-.1-.03-.13-.15-.06-.22l3.27-3.18s.08-.04,.13-.03l.52,.15,4.18,4.22,.13,.52Z" />
				<g>
					<path d="M996.84,913.37l-3.33,3.24c-.06,.06-.16,.02-.17-.06-.47-3.44-1.61-6-2.3-7.31l3.68-3.58c.73,1.45,1.83,4.14,2.15,7.63,0,.03,0,.06-.03,.08Z" />
					<path d="M987.24,922.43l-3.41,3.16c-.06,.06-.16,.02-.17-.06-.4-3.45-1.47-6.02-2.14-7.35l3.77-3.49c.7,1.47,1.74,4.17,1.98,7.66,0,.03,0,.06-.03,.08Z" />
					<path d="M1001.57,908.77l-3.33,3.24c-.06,.06-.16,.02-.17-.06-.5-3.42-1.65-5.96-2.35-7.26l2.25-2.19,3.11,3.14c.23,.93,.42,1.95,.52,3.05,0,.03,0,.06-.03,.08Z" />
					<path d="M979.03,920.48l1.46-1.36c.47,1.01,1.09,2.6,1.51,4.62l-2.98-3.26Z" />
					<path d="M992.08,917.95l-3.41,3.16c-.06,.06-.16,.02-.17-.06-.42-3.43-1.52-5.99-2.19-7.3l1.88-1.74,1.84-1.79c.71,1.46,1.82,4.19,2.08,7.66,0,.03,0,.06-.03,.08Z" />
				</g>
				<g>
					<path d="M1000.44,923.9l3.39-3.29s.05-.03,.08-.03c3.49,.41,6.18,1.55,7.63,2.3l-3.82,3.72c-1.29-.73-3.82-1.93-7.23-2.52-.08-.01-.11-.11-.05-.17Z" />
					<path d="M990.61,933.03l3.47-3.21s.05-.03,.08-.03c3.49,.49,6.15,1.69,7.59,2.47l-3.91,3.62c-1.28-.76-3.78-2.01-7.18-2.68-.08-.02-.11-.12-.05-.17Z" />
					<path d="M1005.24,919.23l3.39-3.29s.05-.03,.08-.03c1.11,.12,2.15,.32,3.09,.56l3.11,3.14-2.33,2.27c-1.3-.72-3.85-1.9-7.28-2.47-.08-.01-.11-.11-.05-.17Z" />
					<path d="M996.77,936.86l-1.52,1.41-2.98-3.26c1.98,.58,3.52,1.31,4.5,1.86Z" />
					<path d="M995.53,928.47l3.47-3.21s.05-.03,.09-.03c3.51,.47,6.15,1.6,7.6,2.37l-1.91,1.86-1.96,1.81c-1.29-.75-3.81-1.98-7.23-2.63-.08-.02-.11-.12-.05-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1027.26,894.44s0,.09-.03,.13l-3.15,3.38c-.07,.07-.19,.05-.22-.05l-.22-.73c-.07-.23-.31-.36-.54-.29l-.63,.19c-.23,.07-.36,.31-.29,.54l.51,1.72s0,.09-.03,.13c-1.04,1.14-2.11,2.24-3.2,3.33-.07,.07-.19,.04-.22-.05l-.21-.73c-.07-.23-.31-.36-.54-.3l-.64,.18c-.23,.07-.37,.3-.3,.53l.49,1.72s0,.09-.03,.13l-3.23,3.3c-.07,.07-.19,.04-.22-.06l-.2-.73c-.06-.23-.3-.37-.53-.3l-.63,.18c-.23,.06-.37,.3-.3,.54l.47,1.73s0,.09-.03,.13l-3.23,3.3c-.07,.07-.2,.04-.22-.06l-.19-.73c-.06-.23-.3-.37-.53-.31l-.64,.16c-.23,.06-.37,.3-.31,.53l.31,1.2-4.18-4.22,1.21,.33c.23,.06,.47-.07,.53-.3l.17-.63c.06-.23-.08-.47-.31-.53l-.73-.2c-.1-.03-.13-.15-.06-.22l3.2-3.26s.08-.05,.13-.03l1.72,.45c.23,.06,.47-.08,.53-.31l.16-.64c.06-.23-.08-.47-.31-.53l-.73-.19c-.1-.03-.13-.15-.06-.22l3.2-3.26s.08-.05,.13-.04l1.73,.44c.23,.06,.47-.08,.53-.31l.16-.63c.06-.23-.08-.46-.32-.52l-.73-.19c-.1-.02-.13-.15-.06-.22,1.07-1.08,2.13-2.17,3.16-3.3,.03-.03,.08-.05,.12-.04l1.73,.41c.23,.06,.46-.09,.52-.32l.15-.64c.05-.23-.09-.47-.32-.52l-.73-.17c-.1-.02-.13-.14-.07-.22l3.11-3.34s.08-.05,.12-.04l.53,.12,4.35,4.04,.16,.52Z" />
				<g>
					<path d="M1015.48,894.67l-3.24,3.33c-.06,.06-.16,.03-.17-.06-.59-3.4-1.82-5.89-2.56-7.17,1.2-1.22,2.34-2.5,3.51-3.74,.79,1.43,2.01,4.06,2.49,7.55,0,.03,0,.06-.03,.08Z" />
					<path d="M1006.25,904.11c-1.08,1.11-2.16,2.22-3.28,3.29-.06,.06-.16,.02-.17-.06-.51-3.39-1.71-5.94-2.43-7.24l3.59-3.67c.76,1.44,1.92,4.1,2.32,7.59,0,.03,0,.06-.03,.08Z" />
					<path d="M1019.98,889.84l-3.17,3.4c-.06,.06-.16,.03-.17-.05-.65-3.41-1.91-5.9-2.67-7.18l2.14-2.29,3.24,3c.27,.92,.5,1.93,.66,3.03,0,.03,0,.06-.03,.08Z" />
					<path d="M997.97,902.5l1.43-1.39c.51,.99,1.19,2.54,1.68,4.53l-3.11-3.14Z" />
					<path d="M1010.87,899.4l-3.25,3.32c-.06,.06-.16,.03-.17-.06-.57-3.42-1.78-5.93-2.51-7.22l3.59-3.67c.77,1.43,1.95,4.07,2.37,7.54,0,.03,0,.06-.03,.08Z" />
				</g>
				<g>
					<path d="M1019.46,905l3.3-3.38s.05-.03,.08-.03c3.49,.25,6.22,1.27,7.7,1.96-1.22,1.29-2.41,2.62-3.65,3.89-1.33-.68-3.91-1.78-7.37-2.26-.08-.01-.12-.11-.06-.17Z" />
					<path d="M1010.05,914.55c1.14-1.09,2.23-2.22,3.34-3.34,.02-.02,.05-.03,.08-.03,3.49,.33,6.2,1.41,7.67,2.13l-3.73,3.81c-1.31-.7-3.84-1.83-7.3-2.39-.08-.01-.11-.11-.06-.17Z" />
					<path d="M1024.11,900.16l3.22-3.46s.05-.03,.08-.03c1.11,.07,2.15,.22,3.1,.42l3.24,3-2.22,2.38c-1.33-.66-3.92-1.73-7.36-2.15-.08,0-.12-.11-.06-.17Z" />
					<path d="M1016.39,918.15l-1.48,1.45-3.11-3.14c2.02,.5,3.6,1.19,4.59,1.69Z" />
					<path d="M1014.77,909.79l3.3-3.38s.05-.03,.08-.03c3.51,.31,6.24,1.37,7.72,2.08l-3.73,3.81c-1.31-.69-3.88-1.81-7.32-2.31-.08-.01-.12-.11-.06-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1045.06,874.46s0,.09-.02,.13c-1,1.17-1.96,2.37-2.99,3.52-.07,.08-.19,.05-.22-.04l-.24-.71c-.08-.23-.33-.35-.55-.27l-.63,.21c-.23,.08-.35,.32-.27,.55l.57,1.69s0,.09-.03,.13l-3.06,3.46c-.07,.08-.19,.05-.22-.04l-.24-.72c-.08-.23-.32-.35-.55-.27l-.62,.21c-.23,.08-.35,.32-.27,.55l.56,1.7s0,.09-.03,.13l-3.06,3.46c-.07,.08-.19,.05-.22-.05l-.23-.72c-.07-.23-.32-.35-.54-.28l-.62,.21c-.23,.08-.35,.32-.28,.55l.54,1.7s0,.09-.03,.13l-3.15,3.38c-.07,.07-.19,.05-.22-.05l-.22-.72c-.07-.23-.31-.36-.54-.29l-.63,.19c-.23,.07-.36,.31-.29,.54l.37,1.19-4.35-4.04,1.22,.28c.23,.05,.47-.09,.52-.32l.14-.64c.05-.23-.09-.46-.33-.52l-.74-.17c-.1-.02-.14-.14-.07-.22l3.11-3.34s.08-.05,.13-.04l1.74,.38c.23,.05,.46-.1,.51-.34l.13-.64c.05-.23-.1-.47-.33-.52l-.74-.16c-.1-.02-.14-.14-.07-.22l3.03-3.42s.08-.05,.12-.04l1.75,.36c.23,.05,.46-.1,.51-.34l.13-.64c.05-.23-.1-.46-.34-.51l-.74-.15c-.1-.02-.14-.14-.07-.21l3.03-3.42s.08-.05,.12-.04l1.76,.35c.24,.05,.46-.1,.51-.34l.13-.64c.05-.23-.11-.46-.34-.51l-.74-.15c-.1-.02-.14-.14-.07-.21,1.02-1.13,1.97-2.32,2.96-3.48,.03-.04,.08-.05,.12-.04l.53,.1,4.52,3.85,.18,.51Z" />
				<g>
					<path d="M1033.26,875.16l-3.08,3.48c-.05,.06-.16,.03-.17-.05-.75-3.38-2.08-5.83-2.88-7.07l3.4-3.84c.85,1.39,2.16,3.96,2.76,7.39,0,.03,0,.06-.02,.08Z" />
					<path d="M1024.48,885.01l-3.17,3.4c-.06,.06-.16,.03-.17-.05-.67-3.39-1.95-5.86-2.72-7.12,1.16-1.25,2.34-2.49,3.46-3.79,.82,1.41,2.12,4.04,2.62,7.47,0,.03,0,.06-.03,.08Z" />
					<path d="M1037.6,870.18c-.99,1.2-2.01,2.36-3.04,3.52-.05,.06-.16,.03-.17-.05-.76-3.35-2.12-5.78-2.94-7.03l2.02-2.4,3.36,2.86c.31,.91,.59,1.91,.8,3.01,0,.03,0,.06-.02,.09Z" />
					<path d="M1016.1,883.72l1.36-1.46c.55,.96,1.3,2.49,1.88,4.46l-3.24-3Z" />
					<path d="M1028.89,880.1l-3.08,3.48c-.06,.06-.16,.03-.17-.05-.72-3.4-2.04-5.87-2.83-7.12l3.4-3.84c.83,1.4,2.13,3.99,2.71,7.45,0,.03,0,.06-.02,.08Z" />
				</g>
				<g>
					<path d="M1037.7,885.32l3.13-3.54s.05-.04,.08-.04c3.53,.13,6.31,1.04,7.82,1.67l-3.53,3.99c-1.35-.62-3.98-1.61-7.44-1.93-.08,0-.12-.11-.07-.17Z" />
					<path d="M1028.68,895.25l3.22-3.46s.05-.03,.08-.03c3.54,.22,6.27,1.16,7.76,1.82-1.16,1.34-2.39,2.63-3.6,3.93-1.34-.65-3.95-1.7-7.4-2.09-.08,0-.12-.11-.06-.17Z" />
					<path d="M1042.14,880.3c1.05-1.17,2.09-2.36,3.09-3.57,.02-.02,.05-.04,.08-.04,1.11,.02,2.15,.13,3.1,.28l3.36,2.86-2.1,2.49c-1.35-.6-3.99-1.56-7.47-1.86-.08,0-.12-.1-.07-.17Z" />
					<path d="M1035.16,898.57l-1.41,1.52-3.24-3c2.03,.41,3.63,1.03,4.65,1.49Z" />
					<path d="M1033.25,890.34l3.13-3.54s.05-.04,.08-.03c3.51,.15,6.27,1.09,7.78,1.73l-3.53,3.99c-1.34-.63-3.95-1.64-7.39-1.98-.08,0-.12-.11-.06-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1062,853.74s0,.09-.02,.13l-2.88,3.61c-.06,.08-.19,.06-.23-.03l-.28-.7c-.09-.22-.34-.33-.56-.24l-.61,.24c-.22,.09-.33,.34-.25,.56l.65,1.67s0,.09-.02,.13l-2.88,3.61c-.06,.08-.19,.06-.22-.03l-.27-.71c-.09-.22-.34-.34-.56-.25l-.61,.24c-.22,.09-.33,.34-.25,.56l.64,1.68s0,.09-.02,.13c-.95,1.22-1.97,2.37-2.95,3.55-.07,.08-.19,.06-.23-.04l-.26-.71c-.08-.22-.33-.34-.56-.26l-.62,.23c-.23,.08-.34,.33-.26,.56l.61,1.68s0,.09-.02,.13l-2.97,3.54c-.07,.08-.19,.06-.22-.04l-.26-.71c-.08-.23-.33-.34-.55-.26l-.62,.22c-.23,.08-.34,.33-.26,.56l.42,1.18-4.52-3.85,1.22,.23c.23,.04,.46-.11,.5-.35l.11-.65c.04-.24-.12-.46-.35-.5l-.74-.14c-.1-.02-.14-.14-.08-.21l2.94-3.5s.08-.05,.12-.04l1.76,.31c.24,.04,.46-.12,.5-.35l.11-.64c.04-.23-.12-.46-.35-.5l-.75-.13c-.1-.02-.14-.14-.08-.21,.97-1.17,1.98-2.31,2.92-3.51,.03-.04,.08-.05,.12-.05l1.75,.28c.24,.04,.46-.12,.49-.36l.1-.65c.04-.24-.13-.46-.36-.5l-.74-.12c-.1-.02-.15-.13-.08-.21l2.85-3.57s.08-.05,.12-.05l1.77,.27c.24,.04,.46-.13,.49-.36l.1-.65c.03-.24-.13-.46-.37-.49l-.75-.11c-.1-.02-.15-.13-.08-.21l2.85-3.57s.08-.05,.12-.05l.54,.08,4.68,3.65,.2,.5Z" />
				<g>
					<path d="M1050.22,854.93l-2.9,3.63c-.05,.06-.15,.04-.17-.04-.91-3.35-2.35-5.75-3.2-6.96l3.2-4.01c.91,1.35,2.34,3.87,3.1,7.29,0,.03,0,.06-.02,.09Z" />
					<path d="M1041.85,865.13l-2.99,3.56c-.05,.06-.15,.04-.17-.04-.83-3.37-2.22-5.79-3.04-7.02l3.3-3.93c.88,1.37,2.25,3.91,2.93,7.34,0,.03,0,.06-.02,.09Z" />
					<path d="M1054.33,849.77l-2.9,3.63c-.05,.06-.15,.04-.18-.04-.92-3.33-2.39-5.7-3.25-6.9l1.96-2.45,3.48,2.72c.34,.89,.66,1.87,.91,2.95,0,.03,0,.06-.02,.09Z" />
					<path d="M1033.46,864.22l1.28-1.53c.59,.94,1.41,2.44,2.08,4.39l-3.36-2.86Z" />
					<path d="M1046.09,860.08l-2.99,3.56c-.05,.06-.15,.04-.18-.04-.85-3.34-2.26-5.74-3.09-6.96,1.12-1.3,2.16-2.65,3.23-3.98,.89,1.36,2.32,3.91,3.05,7.34,0,.03,0,.06-.02,.08Z" />
				</g>
				<g>
					<path d="M1055.11,864.91l2.95-3.69s.05-.04,.08-.04c3.52-.04,6.33,.75,7.87,1.31l-3.33,4.17c-1.37-.56-4.03-1.43-7.49-1.59-.08,0-.13-.1-.07-.16Z" />
					<path d="M1046.51,875.21l3.04-3.62s.05-.04,.08-.04c3.52,.05,6.32,.9,7.85,1.49l-3.43,4.08c-1.36-.59-4.01-1.52-7.47-1.76-.08,0-.12-.1-.07-.17Z" />
					<path d="M1059.3,859.67l2.95-3.69s.05-.04,.08-.04c1.12-.02,2.17,.05,3.14,.16l3.48,2.72-2.03,2.55c-1.38-.55-4.06-1.4-7.54-1.53-.08,0-.13-.1-.08-.16Z" />
					<path d="M1053.11,878.24l-1.33,1.59-3.36-2.86c2.04,.32,3.66,.86,4.7,1.28Z" />
					<path d="M1050.83,870.08l3.04-3.62s.05-.04,.08-.04c3.51,0,6.3,.8,7.82,1.38-1.11,1.39-2.2,2.79-3.36,4.14-1.37-.58-4.03-1.49-7.51-1.7-.08,0-.12-.1-.07-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1077.96,832.26s.01,.09-.01,.13l-2.69,3.76c-.06,.08-.18,.07-.23-.02l-.31-.69c-.1-.22-.35-.32-.57-.22l-.6,.27c-.22,.1-.31,.36-.22,.58l.73,1.64s.01,.09-.01,.13c-.89,1.25-1.79,2.51-2.73,3.73-.06,.08-.19,.07-.23-.03l-.3-.69c-.09-.22-.35-.32-.57-.23l-.61,.26c-.22,.09-.32,.35-.23,.57l.7,1.64s.01,.09-.02,.13l-2.79,3.69c-.06,.08-.19,.07-.23-.03l-.29-.7c-.09-.22-.35-.32-.57-.23l-.61,.26c-.22,.09-.32,.35-.23,.57l.69,1.65s.01,.09-.02,.13l-2.79,3.68c-.06,.08-.19,.06-.22-.03l-.29-.7c-.09-.22-.34-.33-.56-.24l-.6,.25c-.22,.09-.33,.34-.24,.56l.46,1.15-4.68-3.65,1.24,.18c.24,.03,.46-.13,.49-.37l.08-.65c.03-.24-.14-.46-.37-.49l-.74-.1c-.1-.01-.15-.13-.09-.21l2.75-3.64s.07-.06,.12-.05l1.77,.23c.24,.03,.45-.14,.48-.37l.08-.65c.03-.24-.14-.45-.38-.48l-.75-.1c-.1-.01-.15-.13-.09-.21l2.75-3.64s.07-.06,.12-.05l1.78,.22c.24,.03,.45-.14,.48-.38l.08-.65c.03-.24-.14-.45-.38-.48l-.75-.09c-.1-.01-.15-.13-.09-.21,.93-1.2,1.81-2.45,2.69-3.69,.03-.04,.07-.06,.12-.05l1.77,.19c.24,.02,.45-.15,.47-.39l.06-.65c.02-.24-.15-.45-.39-.48l-.75-.08c-.1-.01-.15-.13-.09-.21l2.66-3.71s.07-.06,.12-.05l.54,.05,4.84,3.44,.22,.49Z" />
				<g>
					<path d="M1066.3,834.01c-.89,1.27-1.85,2.49-2.78,3.73-.05,.07-.15,.05-.18-.03-1.02-3.29-2.56-5.6-3.46-6.77l2.98-4.17c.97,1.31,2.52,3.77,3.44,7.16,0,.03,0,.06-.02,.09Z" />
					<path d="M1058.37,844.55c-.94,1.23-1.85,2.49-2.82,3.69-.05,.06-.15,.04-.18-.04-.95-3.31-2.48-5.69-3.35-6.88l3.09-4.09c.94,1.33,2.43,3.82,3.27,7.23,0,.03,0,.06-.02,.09Z" />
					<path d="M1070.14,828.64l-2.7,3.78c-.05,.07-.15,.05-.18-.03-1.08-3.3-2.66-5.6-3.58-6.77l1.82-2.55,3.6,2.56c.39,.87,.75,1.85,1.05,2.92,0,.03,0,.06-.01,.09Z" />
					<path d="M1049.96,844.01c.42-.52,.83-1.04,1.22-1.57,.63,.92,1.53,2.39,2.26,4.29l-3.48-2.72Z" />
					<path d="M1062.35,839.29l-2.8,3.71c-.05,.07-.15,.04-.18-.03-1-3.31-2.52-5.65-3.41-6.84l3.09-4.09c.95,1.32,2.46,3.78,3.32,7.16,0,.03,0,.06-.02,.09Z" />
				</g>
				<g>
					<path d="M1071.6,843.74c.94-1.26,1.92-2.5,2.82-3.79,.02-.03,.05-.04,.08-.04,3.5-.2,6.33,.46,7.89,.95l-3.11,4.34c-1.4-.5-4.11-1.27-7.6-1.29-.08,0-.13-.09-.08-.16Z" />
					<path d="M1063.48,854.43c.99-1.23,1.91-2.51,2.87-3.76,.02-.02,.05-.04,.08-.04,3.51-.12,6.33,.61,7.88,1.13l-3.22,4.26c-1.39-.54-4.04-1.32-7.53-1.43-.08,0-.13-.1-.08-.16Z" />
					<path d="M1075.57,838.34l2.75-3.84s.05-.04,.08-.04c1.12-.07,2.17-.06,3.13,.01l3.6,2.56-1.9,2.65c-1.4-.48-4.11-1.21-7.58-1.17-.08,0-.13-.09-.08-.16Z" />
					<path d="M1070.22,857.18c-.41,.55-.84,1.1-1.27,1.64l-3.48-2.72c2.07,.25,3.71,.71,4.76,1.08Z" />
					<path d="M1067.55,849.09l2.85-3.77s.05-.04,.08-.04c3.53-.14,6.37,.56,7.93,1.07l-3.22,4.26c-1.39-.52-4.09-1.3-7.56-1.35-.08,0-.13-.1-.08-.16Z" />
				</g>
			</g>
			<g>
				<path d="M1092.98,810.1s.02,.09,0,.13l-2.48,3.9c-.06,.08-.18,.08-.23-.01l-.34-.68c-.11-.22-.37-.3-.58-.19l-.59,.29c-.22,.11-.31,.37-.2,.58l.79,1.6s.02,.09,0,.13l-2.59,3.83c-.06,.08-.18,.08-.23-.02l-.33-.68c-.1-.22-.36-.3-.58-.2l-.59,.29c-.22,.11-.31,.36-.2,.58l.78,1.61s.02,.09,0,.13l-2.59,3.83c-.06,.08-.18,.07-.23-.02l-.33-.69c-.1-.22-.36-.31-.58-.2l-.59,.29c-.21,.1-.3,.36-.2,.58l.77,1.63s.02,.09,0,.13l-2.68,3.76c-.06,.08-.19,.07-.23-.02l-.31-.69c-.1-.22-.36-.31-.58-.21l-.6,.27c-.22,.1-.31,.36-.22,.57l.52,1.13-4.84-3.44,1.24,.12c.24,.02,.45-.15,.47-.39l.06-.65c.02-.24-.15-.45-.39-.47l-.75-.07c-.1,0-.15-.12-.09-.21l2.65-3.72s.07-.06,.12-.06l1.77,.15c.24,.02,.44-.16,.46-.4l.05-.66c.02-.24-.16-.45-.4-.47l-.75-.06c-.1,0-.15-.12-.1-.21l2.56-3.78s.07-.06,.12-.06l1.78,.13c.24,.02,.44-.16,.46-.4l.04-.65c.02-.24-.16-.44-.4-.46l-.75-.05c-.1,0-.16-.12-.1-.2l2.56-3.78s.07-.06,.12-.06l1.79,.12c.24,.02,.45-.16,.46-.4l.04-.65c.01-.24-.17-.44-.41-.46l-.76-.05c-.1,0-.16-.12-.1-.2l2.45-3.85s.07-.06,.12-.06l.54,.03,4.99,3.21,.25,.48Z" />
				<g>
					<path d="M1081.39,812.36l-2.6,3.85c-.05,.07-.15,.05-.18-.02-1.18-3.25-2.83-5.5-3.78-6.63l2.86-4.25c1.03,1.27,2.67,3.63,3.71,6.96,0,.03,0,.06-.01,.09Z" />
					<path d="M1073.98,823.27l-2.7,3.78c-.05,.07-.15,.05-.18-.03-1.1-3.27-2.7-5.55-3.62-6.7l1.49-2.08,1.43-2.12c.99,1.28,2.63,3.73,3.59,7.07,0,.03,0,.06-.01,.09Z" />
					<path d="M1085.02,806.85c-.83,1.31-1.65,2.62-2.53,3.89-.05,.07-.15,.05-.18-.02-1.19-3.21-2.9-5.46-3.87-6.58l1.68-2.64,3.71,2.39c.43,.86,.84,1.82,1.19,2.87,0,.03,0,.06-.01,.09Z" />
					<path d="M1065.51,823.08l1.16-1.62c.67,.88,1.61,2.3,2.44,4.18l-3.6-2.56Z" />
					<path d="M1077.7,817.83l-2.6,3.85c-.05,.07-.15,.05-.18-.03-1.17-3.28-2.8-5.55-3.74-6.69l2.87-4.24c1.01,1.27,2.64,3.67,3.66,7.02,0,.03,0,.06-.01,.09Z" />
				</g>
				<g>
					<path d="M1087.12,821.85l2.65-3.92s.05-.04,.08-.05c3.52-.34,6.41,.2,7.98,.62l-2.98,4.43c-1.42-.44-4.16-1.07-7.63-.93-.08,0-.13-.09-.09-.16Z" />
					<path d="M1079.48,832.88l2.75-3.84s.05-.04,.08-.04c3.53-.25,6.34,.31,7.92,.77l-1.49,2.21-1.55,2.17c-1.41-.47-4.14-1.17-7.62-1.11-.08,0-.13-.09-.08-.16Z" />
					<path d="M1090.88,816.28c.9-1.3,1.73-2.63,2.58-3.96,.02-.03,.04-.04,.08-.05,1.11-.13,2.15-.16,3.11-.14l3.71,2.39-1.75,2.75c-1.42-.42-4.13-1-7.64-.84-.08,0-.14-.09-.09-.16Z" />
					<path d="M1086.34,835.33l-1.21,1.69-3.6-2.56c2.07,.15,3.74,.55,4.81,.87Z" />
					<path d="M1083.36,827.41l2.65-3.92s.05-.04,.08-.05c3.5-.31,6.37,.25,7.94,.69l-2.99,4.42c-1.41-.45-4.13-1.11-7.59-.99-.08,0-.13-.09-.09-.16Z" />
				</g>
			</g>
			<g>
				<path d="M1106.99,787.28s.02,.09,0,.13l-2.37,3.97c-.05,.09-.18,.09-.23,0l-.37-.66c-.12-.21-.38-.28-.59-.16l-.58,.32c-.21,.12-.28,.38-.17,.59l.87,1.56s.02,.09,0,.13l-2.37,3.97c-.05,.09-.18,.08-.23,0l-.37-.67c-.12-.21-.38-.29-.59-.17l-.57,.32c-.21,.12-.28,.38-.17,.59l.86,1.58s.02,.09,0,.13c-.78,1.33-1.6,2.63-2.43,3.93-.05,.09-.18,.08-.23,0l-.35-.67c-.11-.21-.38-.29-.59-.18l-.58,.31c-.21,.11-.3,.37-.18,.58l.83,1.58s.02,.09,0,.13l-2.48,3.9c-.05,.09-.18,.08-.23,0l-.35-.67c-.11-.21-.37-.3-.58-.18l-.58,.3c-.21,.11-.29,.37-.18,.59l.57,1.11-4.99-3.21,1.24,.06c.24,.01,.44-.17,.45-.41l.03-.66c0-.24-.18-.44-.41-.45l-.75-.04c-.1,0-.16-.12-.1-.2l2.45-3.85s.07-.06,.12-.06l1.79,.07c.24,0,.44-.18,.45-.41l.03-.65c0-.24-.18-.44-.42-.45l-.76-.03c-.1,0-.16-.11-.11-.2,.82-1.28,1.64-2.57,2.4-3.88,.02-.04,.07-.06,.11-.06l1.78,.04c.24,0,.43-.18,.44-.42v-.66c.01-.24-.18-.44-.42-.44l-.75-.02c-.1,0-.16-.11-.11-.2l2.34-3.92s.07-.06,.11-.06l1.79,.03c.24,0,.44-.19,.44-.43v-.65c.01-.24-.18-.43-.42-.44h-.76c-.1-.01-.16-.12-.11-.21l2.34-3.92s.07-.06,.11-.06h.54s5.14,2.98,5.14,2.98l.27,.47Z" />
				<g>
					<path d="M1095.47,790.05l-2.38,3.99c-.04,.07-.15,.06-.18-.02-1.35-3.2-3.11-5.37-4.12-6.46l2.63-4.39c1.08,1.21,2.84,3.51,4.06,6.79,.01,.03,0,.06,0,.09Z" />
					<path d="M1088.56,801.28l-2.49,3.92c-.04,.07-.15,.06-.18-.02-1.27-3.23-2.97-5.44-3.95-6.54l2.75-4.32c1.05,1.24,2.75,3.57,3.88,6.88,.01,.03,0,.06,0,.09Z" />
					<path d="M1098.85,784.39l-2.38,3.99c-.04,.07-.15,.06-.18-.01-1.37-3.17-3.14-5.31-4.16-6.39l1.6-2.68,3.82,2.21c.46,.83,.91,1.76,1.3,2.8,.01,.03,0,.06,0,.09Z" />
					<path d="M1080.12,801.5l1.07-1.68c.71,.85,1.72,2.23,2.65,4.07l-3.71-2.39Z" />
					<path d="M1092.08,795.71c-.81,1.32-1.66,2.62-2.48,3.93-.04,.07-.15,.06-.18-.02-1.28-3.2-3-5.38-3.99-6.48,.91-1.44,1.76-2.92,2.64-4.38,1.07,1.23,2.82,3.55,4.02,6.86,.01,.03,0,.06,0,.09Z" />
				</g>
				<g>
					<path d="M1101.67,799.28l2.43-4.06s.04-.04,.07-.05c3.48-.51,6.38-.12,7.98,.23l-2.74,4.59c-1.44-.37-4.19-.87-7.64-.55-.08,0-.14-.08-.1-.15Z" />
					<path d="M1094.52,810.65l2.54-3.99s.04-.04,.08-.05c3.5-.43,6.39,.04,7.98,.43l-2.87,4.51c-1.43-.4-4.18-.97-7.64-.74-.08,0-.14-.09-.09-.16Z" />
					<path d="M1105.11,793.52l2.43-4.06s.04-.05,.07-.05c1.11-.17,2.16-.25,3.13-.27l3.82,2.21-1.67,2.8c-1.45-.35-4.22-.83-7.68-.48-.08,0-.14-.08-.1-.15Z" />
					<path d="M1101.47,812.77l-1.12,1.75-3.71-2.39c2.07,.05,3.75,.36,4.83,.64Z" />
					<path d="M1098.12,804.98c.84-1.33,1.7-2.65,2.53-4,.02-.03,.04-.04,.07-.05,3.47-.49,6.35-.06,7.94,.3-.92,1.52-1.81,3.07-2.76,4.57-1.44-.39-4.2-.93-7.68-.68-.08,0-.14-.08-.09-.15Z" />
				</g>
			</g>
			<g>
				<path d="M1119.8,763.76s.03,.09,0,.13l-2.14,4.1c-.05,.09-.17,.1-.23,0l-.4-.64c-.13-.2-.4-.26-.6-.13l-.55,.35c-.2,.13-.26,.4-.13,.6l.95,1.52s.03,.09,0,.13c-.72,1.36-1.4,2.74-2.16,4.09-.05,.09-.18,.09-.23,0l-.39-.64c-.12-.2-.39-.27-.6-.14l-.57,.34c-.21,.12-.27,.39-.15,.59l.92,1.53s.02,.09,0,.13l-2.26,4.03c-.05,.09-.18,.09-.23,0l-.39-.65c-.12-.21-.39-.27-.59-.15l-.57,.34c-.21,.12-.27,.39-.15,.6l.91,1.54s.02,.09,0,.13l-2.26,4.03c-.05,.09-.18,.09-.23,0l-.38-.66c-.12-.21-.39-.28-.59-.16l-.56,.33c-.21,.12-.27,.39-.15,.6l.63,1.09-5.14-2.98h1.24c.24,0,.43-.19,.43-.43v-.66c-.01-.24-.21-.43-.44-.43h-.75c-.1,0-.16-.11-.12-.19l2.23-3.98s.07-.07,.11-.07l1.78-.02c.24,0,.43-.2,.43-.44v-.65c-.01-.24-.21-.43-.45-.43h-.76c-.1,0-.17-.1-.12-.19l2.23-3.98s.07-.07,.11-.07l1.79-.03c.24,0,.43-.2,.43-.44v-.65c-.02-.24-.22-.42-.46-.42l-.76,.02c-.1,0-.17-.1-.12-.19,.75-1.33,1.42-2.69,2.13-4.03,.02-.04,.06-.07,.11-.07l1.78-.06c.24,0,.42-.21,.41-.45l-.03-.66c-.01-.24-.21-.42-.45-.42l-.75,.03c-.1,0-.17-.1-.12-.19l2.11-4.05s.06-.07,.11-.07l.54-.02,5.28,2.72,.29,.45Z" />
				<g>
					<path d="M1108.5,767.13l-1.08,2.06-1.13,2.03c-.04,.07-.15,.07-.18,0-1.46-3.11-3.32-5.19-4.39-6.23l2.36-4.53c1.14,1.16,3.02,3.37,4.42,6.6,.01,.03,.01,.06,0,.09Z" />
					<path d="M1102.11,778.67l-2.27,4.05c-.04,.07-.15,.06-.18-.01-1.43-3.17-3.25-5.3-4.28-6.36l2.5-4.46c1.11,1.19,2.93,3.44,4.24,6.7,.01,.03,0,.06,0,.09Z" />
					<path d="M1111.54,761.29l-2.15,4.12c-.04,.07-.14,.07-.18,0-1.54-3.11-3.42-5.17-4.49-6.19l1.45-2.77,3.93,2.02c.51,.81,1,1.72,1.45,2.74,.01,.03,.01,.06,0,.09Z" />
					<path d="M1093.73,779.3l.97-1.73c.75,.82,1.84,2.15,2.85,3.95l-3.82-2.21Z" />
					<path d="M1105.33,772.91l-2.27,4.05c-.04,.07-.14,.07-.18,0-1.45-3.14-3.28-5.24-4.32-6.29l2.5-4.46c1.12,1.17,2.96,3.4,4.28,6.62,.01,.03,0,.06,0,.09Z" />
				</g>
				<g>
					<path d="M1115.11,776.04l1.15-2.06,1.1-2.1s.04-.05,.07-.05c3.44-.69,6.34-.44,7.95-.18l-2.48,4.74c-1.44-.29-4.23-.66-7.7-.2-.08,.01-.14-.07-.1-.15Z" />
					<path d="M1108.55,787.76l2.31-4.13s.04-.05,.07-.05c3.46-.6,6.36-.28,7.97,.03l-2.61,4.67c-1.44-.33-4.21-.76-7.64-.36-.08,0-.14-.08-.1-.15Z" />
					<path d="M1118.28,770.11l2.19-4.19s.04-.05,.07-.05c1.1-.23,2.14-.36,3.1-.43l3.93,2.02-1.51,2.9c-1.46-.28-4.24-.61-7.67-.09-.08,.01-.14-.07-.1-.15Z" />
					<path d="M1115.58,789.54l-1.02,1.81-3.82-2.21c2.06-.05,3.75,.18,4.84,.4Z" />
					<path d="M1111.83,781.9l2.31-4.13s.04-.05,.07-.05c3.48-.63,6.39-.34,8.01-.05l-2.61,4.67c-1.45-.32-4.23-.72-7.68-.29-.08,.01-.14-.08-.1-.15Z" />
				</g>
			</g>
			<g>
				<path d="M1131.46,739.65s.03,.09,.01,.13l-1.89,4.22c-.04,.09-.17,.1-.23,.02l-.44-.62c-.14-.2-.41-.24-.6-.1l-.53,.38c-.19,.14-.24,.41-.1,.61l1.01,1.46s.03,.09,0,.13l-2.02,4.16c-.04,.09-.17,.1-.23,.02l-.42-.62c-.13-.2-.4-.25-.6-.11l-.55,.37c-.2,.14-.25,.4-.12,.6l1,1.48s.03,.09,0,.13l-2.02,4.16c-.04,.09-.17,.1-.23,.02l-.42-.63c-.13-.2-.4-.25-.6-.12l-.54,.37c-.2,.13-.25,.4-.12,.6l.99,1.5s.03,.09,0,.13c-.65,1.4-1.4,2.75-2.1,4.12-.05,.09-.17,.1-.23,.01l-.41-.63c-.13-.2-.4-.26-.6-.13l-.56,.36c-.2,.13-.26,.4-.13,.6l.67,1.05-5.28-2.72,1.25-.06c.24-.01,.42-.21,.41-.45l-.03-.65c-.01-.24-.21-.42-.45-.41l-.76,.04c-.1,0-.17-.1-.12-.19,.69-1.35,1.43-2.68,2.08-4.06,.02-.04,.06-.07,.11-.07l1.77-.11c.24-.01,.42-.22,.4-.46l-.05-.66c-.02-.24-.22-.42-.46-.4l-.75,.05c-.1,0-.17-.1-.13-.19l1.99-4.11s.06-.07,.11-.07l1.78-.13c.24-.02,.42-.22,.4-.46l-.05-.65c-.02-.24-.23-.42-.47-.4l-.75,.06c-.1,0-.17-.1-.13-.19l1.99-4.11s.06-.07,.11-.07l1.79-.14c.24-.02,.41-.23,.39-.47l-.07-.66c-.02-.24-.24-.41-.47-.39l-.75,.07c-.1,0-.17-.09-.13-.19l1.86-4.16s.06-.07,.11-.08l.54-.05,5.41,2.45,.32,.44Z" />
				<g>
					<path d="M1120.34,743.58l-2.02,4.18c-.04,.07-.14,.07-.18,0-1.64-3.04-3.59-5.02-4.7-6,.73-1.54,1.52-3.05,2.19-4.61,1.21,1.1,3.21,3.22,4.71,6.35,.01,.03,.01,.06,0,.09Z" />
					<path d="M1114.59,755.44l-2.15,4.12c-.04,.07-.14,.07-.18,0-1.55-3.08-3.45-5.11-4.53-6.11,.81-1.5,1.51-3.05,2.27-4.58,1.18,1.13,3.12,3.3,4.6,6.49,.01,.03,.01,.06,0,.09Z" />
					<path d="M1123.1,737.6c-.64,1.41-1.25,2.83-1.91,4.23-.04,.07-.14,.08-.18,0-1.67-3.02-3.7-4.99-4.81-5.95l1.27-2.85,4.03,1.82c.55,.78,1.1,1.67,1.6,2.66,.01,.03,.02,.06,0,.09Z" />
					<path d="M1106.17,756.44l.92-1.76c.79,.78,1.92,2.04,3.01,3.78l-3.93-2.02Z" />
					<path d="M1117.47,749.51l-2.02,4.18c-.04,.07-.14,.07-.18,0-1.62-3.07-3.56-5.08-4.66-6.08l2.23-4.6c1.18,1.11,3.14,3.25,4.64,6.41,.01,.03,.01,.06,0,.09Z" />
				</g>
				<g>
					<path d="M1127.38,752.13l2.06-4.26s.04-.05,.07-.06c3.45-.84,6.36-.74,7.97-.56-.71,1.64-1.53,3.22-2.29,4.83-1.47-.23-4.27-.45-7.7,.19-.08,.02-.15-.07-.11-.14Z" />
					<path d="M1121.39,764.16l2.19-4.19s.04-.05,.07-.05c3.41-.78,6.31-.61,7.93-.38-.79,1.6-1.53,3.23-2.37,4.79-1.47-.26-4.27-.57-7.71-.02-.08,.01-.14-.07-.11-.15Z" />
					<path d="M1130.31,746.09c.68-1.43,1.3-2.88,1.95-4.31,.01-.03,.04-.05,.07-.06,1.08-.29,2.11-.48,3.06-.6l4.03,1.82-1.34,2.99c-1.48-.21-4.24-.38-7.66,.3-.08,.02-.15-.07-.11-.14Z" />
					<path d="M1128.53,765.61l-.96,1.84-3.93-2.02c2.07-.15,3.78,0,4.89,.18Z" />
					<path d="M1124.45,758.18l2.06-4.26s.04-.05,.07-.06c3.42-.81,6.34-.67,7.97-.46l-2.33,4.82c-1.46-.24-4.25-.5-7.66,.11-.08,.01-.15-.07-.11-.14Z" />
				</g>
			</g>
			<g>
				<path d="M1141.9,714.97s.04,.09,.02,.13c-.53,1.45-1.16,2.86-1.73,4.29-.04,.09-.16,.11-.23,.03l-.46-.6c-.15-.19-.42-.22-.61-.07l-.52,.41c-.19,.15-.23,.42-.08,.61l1.09,1.41s.04,.09,.02,.13l-1.76,4.28c-.04,.09-.16,.11-.23,.03l-.46-.6c-.15-.19-.42-.23-.61-.08l-.52,.4c-.19,.15-.23,.42-.08,.61l1.09,1.43s.03,.09,.02,.13c-.6,1.42-1.15,2.86-1.8,4.26-.04,.09-.17,.11-.23,.03l-.45-.61c-.14-.19-.41-.23-.61-.09l-.53,.39c-.19,.14-.24,.41-.1,.6l1.05,1.44s.03,.09,.01,.13l-1.89,4.22c-.04,.09-.17,.11-.23,.02l-.44-.61c-.14-.19-.41-.24-.61-.1l-.53,.39c-.19,.14-.24,.41-.1,.61l.73,1.02-5.41-2.45,1.24-.12c.24-.02,.41-.24,.39-.47l-.07-.65c-.02-.24-.24-.41-.48-.39l-.75,.08c-.1,.01-.17-.09-.13-.18l1.86-4.16s.06-.07,.11-.08l1.78-.19c.24-.03,.41-.24,.39-.47l-.07-.65c-.03-.24-.24-.41-.48-.38l-.75,.08c-.1,.01-.18-.09-.13-.18,.64-1.38,1.18-2.8,1.77-4.2,.02-.04,.06-.07,.1-.08l1.76-.22c.24-.03,.4-.25,.37-.48l-.09-.65c-.03-.24-.25-.4-.49-.37l-.75,.1c-.1,.01-.18-.09-.14-.18l1.73-4.22s.06-.07,.1-.08l1.77-.24c.24-.03,.4-.25,.37-.49l-.09-.65c-.03-.24-.25-.4-.49-.37l-.75,.1c-.1,.01-.18-.08-.14-.18,.57-1.41,1.18-2.8,1.71-4.23,.02-.04,.05-.07,.1-.08l.53-.08,5.54,2.15,.34,.42Z" />
				<g>
					<path d="M1130.94,719.46l-1.76,4.29c-.03,.08-.14,.08-.18,.01-1.81-2.96-3.88-4.84-5.04-5.76l1.94-4.72c1.25,1.03,3.34,3.03,5.03,6.09,.01,.03,.02,.06,0,.09Z" />
					<path d="M1125.79,731.59l-1.9,4.24c-.03,.07-.14,.08-.18,0-1.72-3-3.73-4.93-4.87-5.88l2.09-4.66c1.22,1.06,3.25,3.12,4.85,6.21,.01,.03,.02,.06,0,.09Z" />
					<path d="M1133.44,713.37l-1.76,4.29c-.03,.08-.14,.08-.18,.01-1.83-2.92-3.9-4.77-5.07-5.67,.4-.96,.8-1.92,1.15-2.9l4.12,1.6c.59,.75,1.21,1.62,1.74,2.58,.02,.03,.02,.06,0,.09Z" />
					<path d="M1117.47,733.03l.81-1.81c.83,.74,2.03,1.95,3.21,3.63l-4.03-1.82Z" />
					<path d="M1128.44,725.55c-.57,1.44-1.21,2.85-1.85,4.26-.03,.07-.14,.08-.18,0-1.74-2.97-3.75-4.86-4.92-5.8l1.94-4.72c1.25,1.05,3.32,3.08,5,6.17,.02,.03,.02,.06,0,.09Z" />
				</g>
				<g>
					<path d="M1138.45,727.65l1.8-4.38s.04-.05,.07-.06c3.37-1.03,6.3-1.08,7.94-.98l-2.03,4.96c-1.48-.15-4.28-.22-7.65,.6-.08,.02-.15-.06-.12-.14Z" />
					<path d="M1133.07,739.96l1.93-4.32s.04-.05,.07-.06c3.41-.94,6.34-.91,7.97-.76l-2.19,4.89c-1.47-.19-4.28-.34-7.68,.39-.08,.02-.15-.06-.11-.14Z" />
					<path d="M1141,721.43l1.8-4.38s.04-.05,.07-.06c1.08-.34,2.07-.59,3.02-.76l4.12,1.6c-.37,1.03-.79,2.04-1.2,3.04-1.48-.13-4.3-.17-7.68,.69-.08,.02-.15-.06-.12-.14Z" />
					<path d="M1140.26,741.04l-.85,1.9-4.03-1.82c2.05-.26,3.77-.19,4.88-.08Z" />
					<path d="M1135.81,733.83c.64-1.44,1.3-2.87,1.88-4.34,.01-.03,.04-.05,.07-.06,3.36-1,6.27-1.01,7.9-.9l-2.04,4.96c-1.46-.16-4.29-.29-7.7,.48-.08,.02-.15-.06-.11-.14Z" />
				</g>
			</g>
			<g>
				<path d="M1150.91,689.73s.04,.08,.03,.13l-1.48,4.38c-.03,.1-.16,.12-.22,.05l-.5-.57c-.16-.18-.43-.2-.61-.04l-.49,.44c-.18,.16-.2,.43-.04,.61l1.18,1.35s.04,.08,.03,.13l-1.48,4.38c-.03,.1-.16,.12-.22,.04l-.5-.58c-.16-.18-.43-.2-.61-.05l-.49,.43c-.18,.16-.21,.43-.05,.61l1.14,1.36s.04,.09,.02,.13l-1.62,4.33c-.04,.1-.16,.12-.22,.04l-.48-.58c-.15-.18-.43-.21-.61-.06l-.51,.42c-.18,.15-.21,.43-.06,.61l1.14,1.38s.04,.09,.02,.13l-1.62,4.33c-.04,.1-.16,.12-.22,.04l-.48-.59c-.15-.19-.42-.22-.61-.06l-.51,.42c-.18,.15-.21,.43-.06,.61l.79,.98-5.54-2.15,1.22-.19c.24-.04,.39-.26,.35-.49l-.11-.65c-.04-.24-.26-.4-.5-.36l-.74,.12c-.1,.02-.18-.08-.14-.18l1.6-4.27s.05-.07,.1-.08l1.76-.29c.24-.04,.4-.26,.35-.5l-.11-.64c-.04-.23-.26-.39-.5-.35l-.75,.12c-.1,.02-.18-.08-.15-.17l1.6-4.27s.05-.08,.1-.08l1.77-.31c.23-.04,.4-.26,.35-.5l-.13-.65c-.05-.24-.27-.39-.51-.35l-.74,.14c-.1,.02-.18-.08-.15-.17l1.46-4.32s.05-.08,.1-.09l1.75-.34c.23-.05,.39-.27,.34-.51l-.13-.64c-.05-.23-.28-.39-.51-.34l-.74,.15c-.1,.02-.18-.07-.15-.17l1.46-4.32s.05-.08,.1-.09l.53-.11,5.65,1.84,.36,.4Z" />
				<g>
					<path d="M1140.26,694.83c-.51,1.46-.96,2.94-1.51,4.38-.03,.08-.13,.09-.18,.02-1.93-2.84-4.15-4.62-5.34-5.47l1.63-4.83c1.31,.96,3.52,2.84,5.4,5.81,.02,.03,.02,.06,.01,.09Z" />
					<path d="M1135.76,707.21l-1.62,4.35c-.03,.08-.13,.09-.18,.02-1.9-2.91-4.02-4.73-5.21-5.62l1.79-4.78c1.28,1,3.43,2.94,5.21,5.95,.02,.03,.02,.06,0,.09Z" />
					<path d="M1142.36,688.59l-1.48,4.39c-.03,.08-.13,.09-.18,.02-2-2.82-4.19-4.55-5.41-5.39l1-2.95,4.2,1.37c.63,.72,1.26,1.54,1.86,2.47,.02,.03,.02,.06,.01,.09Z" />
					<path d="M1127.57,709.1l.69-1.86c.87,.69,2.14,1.84,3.42,3.46l-4.12-1.6Z" />
					<path d="M1138.07,701.04l-1.62,4.34c-.03,.08-.13,.09-.18,.02-1.91-2.87-4.05-4.66-5.24-5.53,.58-1.6,1.22-3.17,1.76-4.79,1.32,.99,3.48,2.9,5.28,5.87,.02,.03,.02,.06,0,.09Z" />
				</g>
				<g>
					<path d="M1148.22,702.6c.56-1.47,1.03-2.99,1.54-4.48,.01-.03,.03-.05,.06-.06,3.29-1.22,6.2-1.44,7.84-1.43l-1.71,5.08c-1.49-.07-4.24,.03-7.6,1.02-.08,.02-.15-.05-.12-.13Z" />
					<path d="M1143.51,715.2l1.66-4.44s.03-.05,.06-.06c3.33-1.13,6.25-1.26,7.89-1.21l-1.88,5.02c-1.48-.11-4.28-.11-7.61,.82-.08,.02-.15-.06-.12-.13Z" />
					<path d="M1150.4,696.24l1.51-4.49s.03-.05,.06-.06c1.05-.4,2.06-.7,3-.92l4.2,1.37-1.05,3.11c-1.49-.05-4.29,.07-7.6,1.12-.08,.03-.15-.05-.13-.13Z" />
					<path d="M1150.74,715.88l-.73,1.95-4.12-1.6c2.03-.37,3.73-.4,4.85-.35Z" />
					<path d="M1145.87,708.9l1.66-4.44s.04-.05,.06-.06c3.35-1.16,6.28-1.33,7.89-1.31-.56,1.7-1.23,3.35-1.85,5.03-1.49-.09-4.3-.05-7.65,.91-.08,.02-.15-.05-.12-.13Z" />
				</g>
			</g>
			<g>
				<path d="M1158.5,664.01s.05,.08,.03,.13l-1.18,4.48c-.03,.1-.15,.13-.22,.06l-.53-.54c-.17-.17-.44-.17-.61,0l-.46,.46c-.17,.17-.17,.44,0,.62l1.26,1.28s.05,.08,.03,.13c-.4,1.49-.88,2.96-1.31,4.44-.03,.1-.15,.13-.22,.05l-.52-.55c-.16-.17-.44-.18-.61-.01l-.48,.45c-.17,.17-.18,.44-.02,.61l1.23,1.3s.04,.08,.03,.13l-1.33,4.43c-.03,.1-.15,.13-.22,.05l-.52-.56c-.16-.18-.44-.19-.61-.02l-.48,.45c-.17,.16-.18,.44-.02,.62l1.22,1.32s.04,.08,.03,.13c-.45,1.48-.87,2.96-1.39,4.42-.03,.1-.16,.12-.22,.05l-.5-.56c-.16-.18-.43-.19-.61-.03l-.5,.44c-.18,.16-.2,.43-.04,.61l.83,.93-5.65-1.84,1.23-.25c.24-.05,.39-.28,.34-.51l-.13-.64c-.05-.23-.28-.38-.51-.33l-.74,.15c-.1,.02-.18-.07-.15-.17,.51-1.43,.93-2.89,1.36-4.35,.01-.04,.05-.08,.09-.09l1.73-.39c.23-.05,.38-.28,.32-.52l-.15-.64c-.06-.23-.29-.38-.52-.32l-.73,.17c-.1,.02-.18-.07-.16-.17l1.31-4.37s.05-.08,.1-.09l1.74-.41c.23-.06,.38-.29,.32-.52l-.15-.63c-.06-.23-.29-.37-.52-.32l-.74,.18c-.1,.02-.19-.07-.16-.16,.43-1.46,.89-2.9,1.29-4.37,.01-.04,.05-.08,.09-.09l1.72-.44c.23-.06,.37-.3,.3-.53l-.17-.64c-.06-.23-.3-.37-.53-.31l-.73,.19c-.1,.03-.19-.06-.16-.16l1.16-4.41s.05-.08,.09-.09l.52-.14,5.74,1.51,.38,.38Z" />
				<g>
					<path d="M1148.21,669.73l-1.33,4.44c-.02,.08-.13,.1-.18,.03-2.1-2.73-4.35-4.36-5.6-5.14,.51-1.62,.94-3.26,1.37-4.9,1.36,.88,3.72,2.65,5.73,5.49,.02,.02,.02,.06,.01,.09Z" />
					<path d="M1144.42,682.34c-.42,1.49-.95,2.94-1.44,4.41-.03,.08-.13,.09-.18,.03-2.02-2.79-4.21-4.48-5.44-5.3l1.46-4.88c1.34,.92,3.6,2.74,5.58,5.66,.02,.03,.02,.06,.01,.09Z" />
					<path d="M1149.91,663.38l-1.18,4.48c-.02,.08-.12,.1-.18,.03-2.18-2.71-4.47-4.31-5.74-5.08l.79-3.01,4.27,1.13c.67,.68,1.36,1.46,2.02,2.36,.02,.02,.02,.06,.02,.09Z" />
					<path d="M1136.29,684.67l.63-1.88c.9,.64,2.22,1.71,3.57,3.25l-4.2-1.37Z" />
					<path d="M1146.31,676.04l-1.33,4.44c-.02,.08-.13,.09-.18,.03-2.09-2.77-4.33-4.44-5.57-5.24l1.47-4.88c1.35,.9,3.62,2.69,5.6,5.56,.02,.03,.02,.06,.01,.09Z" />
				</g>
				<g>
					<path d="M1156.52,677.02l1.36-4.54s.03-.05,.06-.07c3.25-1.4,6.09-1.8,7.73-1.88-.45,1.73-.91,3.46-1.44,5.17-1.49,.02-4.3,.25-7.58,1.44-.08,.03-.15-.04-.13-.12Z" />
					<path d="M1152.54,689.87c.49-1.5,1.04-2.98,1.47-4.5,0-.03,.03-.05,.06-.07,3.24-1.32,6.14-1.62,7.77-1.66l-1.54,5.14c-1.49-.02-4.31,.12-7.63,1.22-.08,.03-.15-.05-.13-.13Z" />
					<path d="M1158.42,670.57l1.21-4.58s.03-.06,.06-.07c1.02-.46,2-.82,2.93-1.1l4.27,1.13-.84,3.17c-1.48,.04-4.27,.32-7.49,1.57-.08,.03-.16-.04-.13-.12Z" />
					<path d="M1159.84,690.16l-.67,1.98-4.2-1.37c2.03-.48,3.74-.6,4.86-.61Z" />
					<path d="M1154.58,683.46l1.36-4.54s.03-.05,.06-.07c3.26-1.36,6.16-1.69,7.8-1.76l-1.54,5.14c-1.48,0-4.28,.19-7.55,1.35-.08,.03-.15-.05-.13-.13Z" />
				</g>
			</g>
			<g>
				<path d="M1164.6,637.9s.05,.08,.04,.13c-.28,1.52-.57,3.03-.93,4.54-.02,.1-.14,.14-.22,.07l-.56-.51c-.18-.16-.45-.15-.61,.03l-.45,.49c-.16,.18-.15,.45,.02,.61l1.31,1.2s.05,.08,.04,.13l-1.02,4.52c-.02,.1-.14,.14-.22,.07l-.55-.52c-.18-.16-.45-.15-.61,.02l-.45,.48c-.16,.18-.15,.45,.02,.61l1.31,1.23s.05,.08,.04,.13l-1.02,4.52c-.02,.1-.14,.14-.22,.06l-.55-.53c-.18-.17-.45-.16-.61,.02l-.45,.48c-.17,.17-.17,.45,0,.61l1.27,1.24s.05,.08,.03,.13l-1.18,4.48c-.03,.1-.15,.13-.22,.06l-.54-.53c-.17-.17-.44-.17-.61,0l-.46,.47c-.17,.17-.17,.45,0,.61l.89,.88-5.74-1.51,1.2-.32c.23-.06,.37-.3,.3-.53l-.17-.63c-.06-.23-.3-.36-.53-.3l-.73,.2c-.1,.03-.19-.06-.16-.16l1.16-4.41s.05-.08,.09-.09l1.73-.49c.23-.07,.37-.3,.3-.53l-.19-.63c-.07-.23-.31-.36-.54-.29l-.72,.21c-.1,.03-.19-.06-.17-.16l1.01-4.44s.04-.08,.09-.09l1.7-.52c.23-.07,.36-.31,.28-.54l-.2-.63c-.07-.23-.31-.36-.54-.29l-.72,.22c-.1,.03-.19-.06-.17-.15l1.01-4.44s.04-.08,.09-.1l1.71-.54c.23-.07,.36-.32,.29-.54l-.2-.62c-.07-.23-.32-.35-.54-.28l-.72,.23c-.1,.03-.19-.05-.17-.15,.36-1.48,.64-2.97,.91-4.46,0-.05,.04-.08,.08-.1l.51-.17,5.82,1.17,.41,.36Z" />
				<g>
					<path d="M1154.6,644.21l-1.02,4.52c-.02,.08-.12,.1-.17,.04-2.28-2.6-4.63-4.1-5.93-4.81l1.12-4.96c1.41,.8,3.8,2.41,5.98,5.13,.02,.02,.03,.06,.02,.09Z" />
					<path d="M1151.59,657.02l-1.18,4.48c-.02,.08-.12,.1-.18,.04-2.19-2.67-4.49-4.23-5.77-4.98,.42-1.64,.89-3.28,1.27-4.93,1.41,.85,3.75,2.53,5.83,5.31,.02,.02,.02,.06,.02,.09Z" />
					<path d="M1156.01,637.78c-.26,1.52-.66,3.02-.98,4.53-.02,.08-.12,.1-.17,.04-2.29-2.56-4.65-4.02-5.95-4.71l.58-3.05,4.33,.87c.72,.64,1.45,1.38,2.17,2.24,.02,.02,.03,.06,.02,.09Z" />
					<path d="M1143.6,659.81l.5-1.91c.94,.59,2.32,1.58,3.77,3.04l-4.27-1.13Z" />
					<path d="M1153.14,650.62c-.36,1.5-.64,3.02-1.06,4.51-.02,.08-.12,.1-.18,.04-2.19-2.62-4.59-4.17-5.86-4.9l1.12-4.96c1.4,.82,3.79,2.47,5.96,5.23,.02,.02,.03,.06,.02,.09Z" />
				</g>
				<g>
					<path d="M1163.37,651l1.05-4.62s.03-.06,.06-.07c3.16-1.59,6.04-2.14,7.67-2.33l-1.19,5.24c-1.48,.11-4.26,.51-7.45,1.9-.08,.03-.16-.03-.14-.12Z" />
					<path d="M1160.13,664.06l1.21-4.58s.03-.06,.06-.07c3.21-1.5,6.12-1.95,7.73-2.1-.41,1.74-.9,3.47-1.34,5.2-1.49,.06-4.28,.38-7.52,1.67-.08,.03-.16-.04-.14-.12Z" />
					<path d="M1164.86,644.44c.33-1.54,.74-3.07,1-4.63,0-.03,.03-.06,.05-.07,.98-.52,1.94-.94,2.84-1.27l4.33,.87-.62,3.23c-1.49,.13-4.28,.57-7.47,2-.08,.03-.16-.03-.14-.11Z" />
					<path d="M1167.41,663.92l-.53,2.02-4.27-1.13c1.99-.6,3.69-.82,4.8-.89Z" />
					<path d="M1161.84,657.55c.43-1.52,.72-3.08,1.09-4.61,0-.03,.03-.06,.06-.07,3.15-1.55,6.02-2.06,7.65-2.22l-1.19,5.24c-1.5,.08-4.21,.46-7.47,1.78-.08,.03-.16-.04-.14-.12Z" />
				</g>
			</g>
			<g>
				<path d="M1169.09,611.46s.06,.08,.05,.12l-.69,4.58c-.02,.1-.13,.15-.21,.08l-.59-.47c-.19-.15-.46-.12-.61,.07l-.41,.52c-.15,.19-.12,.46,.07,.61l1.4,1.12s.05,.08,.05,.12l-.69,4.58c-.02,.1-.13,.15-.21,.08l-.59-.48c-.19-.15-.46-.12-.61,.06l-.41,.51c-.15,.19-.12,.46,.07,.61l1.39,1.15s.05,.08,.05,.12c-.26,1.52-.57,3.03-.85,4.55-.02,.1-.14,.14-.21,.08l-.57-.49c-.18-.15-.46-.13-.61,.05l-.43,.5c-.16,.18-.14,.46,.05,.61l1.36,1.16s.05,.08,.04,.12l-.86,4.55c-.02,.1-.14,.14-.21,.07l-.57-.5c-.18-.16-.45-.14-.61,.04l-.43,.5c-.16,.18-.14,.46,.04,.61l.94,.83-5.82-1.17,1.17-.4c.23-.08,.34-.32,.27-.55l-.22-.62c-.08-.23-.33-.35-.55-.27l-.71,.24c-.1,.03-.19-.05-.17-.15l.85-4.48s.04-.08,.09-.1l1.69-.59c.23-.08,.35-.33,.27-.55l-.22-.61c-.08-.22-.33-.34-.55-.26l-.71,.25c-.1,.03-.19-.05-.17-.15,.28-1.49,.58-2.98,.83-4.48,0-.05,.04-.08,.08-.1l1.66-.62c.22-.08,.33-.33,.25-.56l-.24-.61c-.09-.22-.34-.34-.56-.25l-.7,.26c-.09,.04-.19-.04-.18-.14l.68-4.5s.04-.08,.08-.1l1.66-.64c.22-.09,.33-.34,.25-.56l-.24-.61c-.09-.22-.34-.33-.56-.24l-.7,.27c-.09,.04-.19-.04-.18-.14l.68-4.5s.04-.08,.08-.1l.5-.2,5.89,.8,.42,.33Z" />
				<g>
					<path d="M1159.48,618.37l-.69,4.58c-.01,.08-.11,.11-.17,.05-2.46-2.46-4.91-3.81-6.25-4.44l.76-5.02c1.46,.71,3.96,2.17,6.33,4.75,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1157.23,631.32l-.86,4.55c-.02,.08-.12,.11-.17,.05-2.37-2.54-4.77-3.96-6.09-4.63l.95-5c1.44,.76,3.88,2.29,6.16,4.94,.02,.02,.03,.05,.02,.08Z" />
					<path d="M1160.46,611.87l-.69,4.58c-.01,.08-.11,.11-.17,.05-2.46-2.42-4.92-3.73-6.27-4.33l.47-3.07,4.38,.6c.74,.59,1.51,1.28,2.26,2.09,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1149.48,634.59l.37-1.94c.98,.53,2.42,1.44,3.96,2.81l-4.33-.87Z" />
					<path d="M1158.45,624.86l-.86,4.55c-.02,.08-.12,.11-.17,.05-2.38-2.49-4.78-3.88-6.11-4.53l.47-2.5,.38-2.51c1.44,.73,3.99,2.24,6.27,4.85,.02,.02,.03,.05,.02,.08Z" />
				</g>
				<g>
					<path d="M1168.7,624.62l.71-4.69s.02-.06,.05-.07c3.03-1.79,5.86-2.52,7.47-2.8l-.81,5.32c-1.47,.2-4.21,.77-7.28,2.36-.07,.04-.16-.02-.15-.11Z" />
					<path d="M1166.23,637.85l.88-4.66s.03-.06,.05-.07c3.1-1.69,5.95-2.33,7.58-2.57l-1,5.28c-1.48,.15-4.24,.64-7.37,2.13-.07,.04-.16-.03-.14-.11Z" />
					<path d="M1169.71,617.96l.71-4.69s.02-.06,.05-.07c.96-.58,1.91-1.06,2.8-1.44l4.38,.6-.49,3.25c-1.47,.22-4.22,.84-7.3,2.46-.07,.04-.16-.02-.15-.1Z" />
					<path d="M1173.47,637.27l-.39,2.05-4.33-.87c1.94-.72,3.62-1.04,4.72-1.19Z" />
					<path d="M1167.48,631.24l.88-4.66s.03-.06,.05-.07c3.1-1.73,5.87-2.43,7.49-2.69l-.4,2.66-.49,2.64c-1.48,.17-4.25,.7-7.39,2.23-.07,.04-.16-.03-.14-.11Z" />
				</g>
			</g>
			<g>
				<path d="M1171.84,584.77s.06,.07,.06,.12l-.35,4.62c0,.1-.12,.16-.2,.1l-.62-.43c-.2-.14-.47-.09-.6,.11l-.37,.54c-.13,.2-.08,.47,.11,.61l1.47,1.03s.06,.07,.06,.12l-.18,2.31-.26,2.3c-.01,.1-.13,.15-.21,.09l-.61-.44c-.19-.14-.46-.1-.61,.1l-.39,.53c-.14,.19-.1,.47,.09,.61l1.44,1.05s.06,.07,.05,.12l-.52,4.6c-.01,.1-.13,.15-.21,.09l-.61-.45c-.19-.14-.46-.1-.61,.09l-.39,.53c-.14,.19-.1,.46,.09,.61l1.44,1.08s.06,.07,.05,.12l-.52,4.6c-.01,.1-.13,.15-.21,.09l-.61-.46c-.19-.15-.46-.11-.6,.08l-.4,.52c-.15,.19-.12,.46,.07,.61l.98,.77-5.89-.8,1.16-.47c.22-.09,.33-.34,.25-.56l-.25-.6c-.1-.22-.35-.33-.57-.23l-.69,.29c-.09,.04-.19-.04-.18-.14l.52-4.53s.03-.09,.08-.1l1.64-.7c.22-.09,.32-.35,.22-.57l-.26-.6c-.1-.22-.35-.32-.57-.22l-.69,.3c-.09,.04-.19-.04-.18-.13l.52-4.53s.03-.09,.08-.1l1.64-.72c.22-.1,.32-.35,.22-.57l-.26-.6c-.1-.22-.35-.31-.57-.22l-.69,.31c-.09,.04-.19-.03-.18-.13l.25-2.26,.17-2.27s.03-.09,.07-.11l1.61-.75c.22-.1,.31-.36,.2-.57l-.28-.59c-.1-.22-.36-.31-.58-.21l-.68,.32c-.09,.04-.19-.03-.19-.13l.34-4.54s.03-.09,.07-.11l.49-.23,5.92,.43,.45,.3Z" />
				<g>
					<path d="M1162.79,592.28c-.11,1.54-.33,3.07-.49,4.6,0,.08-.11,.12-.17,.06-2.56-2.3-5.06-3.49-6.43-4.03,.16-1.69,.26-3.37,.4-5.06,1.51,.62,4.11,1.91,6.66,4.34,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1161.33,605.35c-.19,1.53-.32,3.07-.58,4.59-.01,.08-.11,.11-.17,.06-2.45-2.37-5-3.65-6.35-4.24l.57-5.05c1.49,.67,4.04,2.04,6.5,4.55,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1163.28,585.73l-.35,4.62c0,.08-.1,.12-.17,.06-2.64-2.26-5.18-3.41-6.57-3.93l.23-3.09,4.41,.32c.78,.54,1.6,1.19,2.41,1.94,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1153.8,609.09c.09-.65,.22-1.3,.28-1.96,1.02,.47,2.5,1.28,4.1,2.55l-4.38-.6Z" />
					<path d="M1162.08,598.82l-.52,4.6c0,.08-.11,.12-.17,.06-2.55-2.34-5.05-3.57-6.42-4.13l.58-5.05c1.49,.64,4.04,1.97,6.51,4.44,.02,.02,.03,.05,.03,.08Z" />
				</g>
				<g>
					<path d="M1172.3,597.93c.16-1.57,.39-3.14,.5-4.72,0-.03,.02-.06,.04-.08,2.89-1.98,5.65-2.89,7.24-3.28-.14,1.79-.26,3.58-.42,5.37-1.46,.29-4.19,1.04-7.21,2.8-.07,.04-.16-.01-.15-.1Z" />
					<path d="M1170.72,611.3c.26-1.56,.4-3.14,.59-4.71,0-.03,.02-.06,.05-.08,2.96-1.89,5.76-2.71,7.36-3.04l-.61,5.35c-1.48,.24-4.13,.91-7.24,2.58-.07,.04-.16-.02-.15-.1Z" />
					<path d="M1172.95,591.23l.36-4.73s.02-.06,.04-.08c.92-.64,1.82-1.18,2.68-1.62l4.41,.32-.25,3.28c-1.45,.32-4.14,1.11-7.09,2.93-.07,.04-.16-.01-.15-.09Z" />
					<path d="M1177.94,610.28c-.07,.69-.2,1.38-.29,2.07l-4.38-.6c1.92-.83,3.59-1.26,4.67-1.47Z" />
					<path d="M1171.54,604.62l.54-4.71s.02-.06,.05-.08c2.96-1.93,5.77-2.79,7.37-3.15l-.61,5.35c-1.46,.27-4.18,.97-7.2,2.7-.07,.04-.16-.02-.15-.1Z" />
				</g>
			</g>
			<g>
				<path d="M1172.92,557.96s.07,.07,.07,.11c0,1.54,.02,3.09,0,4.63,0,.1-.12,.16-.2,.11l-.64-.39c-.2-.12-.47-.06-.6,.15l-.35,.56c-.13,.21-.07,.47,.14,.6l1.51,.93s.06,.07,.06,.12l-.17,4.63c0,.1-.12,.16-.2,.11l-.64-.4c-.2-.13-.47-.06-.6,.14l-.35,.56c-.13,.2-.07,.47,.14,.6l1.51,.96s.06,.07,.06,.12l-.17,4.63c0,.1-.12,.16-.2,.1l-.64-.41c-.2-.13-.47-.07-.6,.13l-.35,.55c-.13,.2-.07,.47,.14,.6l1.51,.98s.06,.07,.06,.12l-.35,4.62c0,.1-.12,.16-.2,.1l-.62-.42c-.2-.13-.47-.08-.6,.12l-.37,.55c-.14,.2-.08,.47,.11,.6l1.03,.7-5.92-.43,1.12-.54c.22-.1,.31-.36,.2-.58l-.29-.59c-.1-.21-.36-.3-.58-.2l-.68,.33c-.09,.04-.2-.03-.19-.13l.34-4.54s.03-.09,.07-.11l1.58-.8c.21-.11,.29-.37,.18-.58l-.31-.58c-.11-.21-.37-.3-.59-.19l-.67,.34c-.09,.05-.2-.02-.19-.12l.17-4.55s.03-.09,.07-.11l1.58-.83c.21-.11,.29-.37,.18-.58l-.31-.58c-.11-.21-.37-.29-.59-.18l-.67,.35c-.09,.05-.2-.02-.19-.12l.17-4.55s.03-.09,.07-.11l1.58-.85c.21-.11,.29-.38,.18-.59l-.31-.57c-.11-.21-.37-.28-.59-.17l-.67,.36c-.09,.05-.2-.02-.19-.12,.02-1.52,0-3.04,0-4.55,0-.05,.02-.09,.07-.11l.47-.26,5.94,.04,.47,.27Z" />
				<g>
					<path d="M1164.32,566.05l-.17,4.63c0,.08-.1,.12-.16,.07-2.72-2.13-5.31-3.15-6.72-3.6l.19-5.07c1.53,.52,4.18,1.64,6.83,3.89,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1163.78,579.18l-.35,4.62c0,.08-.1,.12-.17,.07-2.64-2.21-5.19-3.32-6.58-3.82l.19-2.53,.1-2.54c1.51,.57,4.23,1.78,6.77,4.12,.02,.02,.03,.05,.03,.08Z" />
					<path d="M1164.46,559.48c0,1.54,.02,3.09-.06,4.63,0,.08-.1,.13-.16,.08-2.71-2.08-5.35-3.06-6.77-3.49v-3.1s4.41,.03,4.41,.03c.82,.49,1.69,1.08,2.55,1.78,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1156.43,583.39l.15-1.97c1.03,.4,2.57,1.12,4.26,2.28l-4.41-.32Z" />
					<path d="M1164.08,572.61l-.17,4.63c0,.08-.1,.12-.16,.07-2.72-2.17-5.31-3.24-6.71-3.71l.19-5.07c1.53,.54,4.18,1.71,6.82,4.01,.02,.02,.04,.05,.04,.08Z" />
				</g>
				<g>
					<path d="M1174.2,571.06l.18-4.74s.02-.06,.04-.08c2.81-2.16,5.54-3.25,7.11-3.74l-.2,5.38c-1.44,.39-4.1,1.31-6.97,3.27-.07,.05-.16,0-.16-.09Z" />
					<path d="M1173.46,584.51l.36-4.73s.02-.06,.04-.08c2.91-2.07,5.56-3.07,7.16-3.51l-.11,2.69-.2,2.68c-1.45,.34-4.14,1.17-7.09,3.04-.07,.04-.16,0-.15-.09Z" />
					<path d="M1174.45,564.33c.08-1.58,.08-3.16,.07-4.74,0-.03,.01-.06,.04-.08,.86-.7,1.73-1.29,2.55-1.79l4.42,.03v3.29c-1.42,.41-4.02,1.38-6.91,3.38-.07,.05-.16,0-.16-.08Z" />
					<path d="M1180.6,583.03l-.16,2.09-4.41-.32c1.85-.95,3.48-1.49,4.56-1.77Z" />
					<path d="M1173.95,577.79l.18-4.74s.02-.06,.04-.08c2.81-2.12,5.54-3.16,7.11-3.63l-.2,5.38c-1.44,.36-4.09,1.24-6.97,3.16-.07,.05-.16,0-.16-.09Z" />
				</g>
			</g>
			<g>
				<path d="M1172.25,531.13s.07,.06,.07,.11l.19,4.63c0,.1-.1,.17-.19,.12l-.67-.35c-.21-.11-.47-.03-.58,.19l-.3,.59c-.11,.21-.03,.48,.18,.59l1.58,.83s.07,.06,.07,.11l.19,4.63c0,.1-.1,.17-.19,.12l-.67-.36c-.21-.11-.47-.03-.59,.18l-.3,.58c-.11,.21-.03,.47,.18,.59l1.58,.86s.07,.07,.07,.11c.09,1.54,.08,3.09,.08,4.63,0,.1-.11,.17-.2,.12l-.66-.37c-.21-.12-.47-.04-.59,.17l-.33,.58c-.12,.21-.05,.47,.16,.59l1.55,.88s.07,.07,.07,.11v4.63c.01,.1-.1,.17-.19,.11l-.66-.38c-.21-.12-.47-.05-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l1.08,.63-5.94-.04,1.08-.61c.21-.12,.28-.38,.16-.59l-.33-.57c-.12-.21-.39-.28-.59-.16l-.65,.37c-.09,.05-.2-.01-.2-.11v-4.55s.02-.09,.06-.11l1.55-.9c.21-.12,.28-.39,.16-.59l-.33-.56c-.12-.2-.39-.27-.59-.15l-.65,.38c-.09,.05-.2,0-.2-.11,0-1.52,.01-3.04-.08-4.55,0-.05,.02-.09,.06-.11l1.51-.93c.2-.12,.26-.39,.13-.59l-.35-.56c-.13-.2-.4-.26-.6-.14l-.64,.4c-.09,.05-.2,0-.2-.11l-.19-4.55s.02-.09,.06-.12l1.51-.95c.2-.13,.26-.4,.13-.6l-.35-.55c-.13-.2-.4-.26-.6-.13l-.64,.41c-.08,.05-.2,0-.2-.1l-.19-4.55s.02-.09,.06-.12l.46-.3,5.93-.36,.48,.24Z" />
				<g>
					<path d="M1164.14,539.77l.19,4.62c0,.08-.09,.13-.16,.08-2.88-1.94-5.54-2.79-6.98-3.14l-.21-5.07c1.57,.41,4.3,1.35,7.12,3.43,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1164.44,552.91v4.63c0,.08-.08,.13-.15,.08-2.8-2.04-5.43-2.97-6.85-3.38v-5.07c1.54,.47,4.23,1.5,6.96,3.66,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1163.87,533.21l.19,4.62c0,.08-.09,.13-.16,.09-2.88-1.89-5.54-2.7-6.98-3.03l-.13-3.1,4.41-.27c.84,.44,1.73,.96,2.62,1.61,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1157.45,557.59v-1.97c1.06,.33,2.65,.94,4.42,2l-4.42-.03Z" />
					<path d="M1164.42,546.34l.02,4.63c0,.08-.09,.13-.16,.08-2.8-1.99-5.43-2.88-6.85-3.26,.01-1.69-.12-3.38-.17-5.07,1.57,.44,4.3,1.43,7.12,3.54,.02,.02,.04,.05,.04,.08Z" />
				</g>
				<g>
					<path d="M1174.39,544.12l-.2-4.74s.01-.06,.03-.08c2.63-2.35,5.27-3.61,6.8-4.21l.23,5.38c-1.4,.48-3.98,1.58-6.7,3.72-.06,.05-.16,0-.16-.08Z" />
					<path d="M1174.51,557.59v-4.75s0-.06,.03-.08c2.72-2.26,5.41-3.44,6.96-3.98v5.39c-1.41,.43-4.03,1.44-6.83,3.5-.07,.05-.16,0-.16-.08Z" />
					<path d="M1174.11,537.39l-.2-4.74s.01-.06,.03-.08c.83-.76,1.67-1.41,2.47-1.96l4.41-.27,.14,3.29c-1.4,.5-3.98,1.64-6.69,3.84-.06,.05-.16,0-.16-.07Z" />
					<path d="M1181.51,555.64v2.09s-4.41-.03-4.41-.03c1.77-1.07,3.36-1.72,4.41-2.07Z" />
					<path d="M1174.5,550.86l-.02-4.75s.01-.06,.04-.08c2.63-2.3,5.27-3.52,6.8-4.09,.06,1.8,.19,3.59,.18,5.38-1.42,.46-4.04,1.51-6.84,3.61-.07,.05-.16,0-.16-.08Z" />
				</g>
			</g>
			<g>
				<path d="M1169.68,504.41s.07,.06,.08,.11l.57,4.6c.01,.1-.09,.18-.18,.14l-.7-.3c-.22-.1-.48,0-.57,.23l-.26,.6c-.09,.22,0,.48,.23,.57l1.65,.72s.07,.06,.08,.11c.18,1.53,.41,3.06,.51,4.61,0,.1-.1,.18-.19,.13l-.68-.31c-.22-.1-.47,0-.58,.22l-.28,.6c-.1,.22,0,.48,.21,.58l1.61,.75s.07,.06,.07,.11l.38,4.62c0,.1-.1,.17-.19,.13l-.68-.33c-.22-.1-.47,0-.58,.21l-.28,.6c-.1,.22,0,.48,.21,.58l1.62,.78s.07,.06,.07,.11l.38,4.62c0,.1-.1,.17-.19,.13l-.69-.34c-.22-.11-.48-.02-.58,.2l-.28,.59c-.1,.21,0,.48,.21,.58l1.11,.56-5.93,.36,1.05-.68c.2-.13,.25-.4,.11-.6l-.37-.54c-.14-.2-.41-.25-.6-.12l-.62,.42c-.08,.06-.2,0-.21-.1l-.37-4.54s.02-.09,.06-.12l1.47-1c.2-.13,.25-.4,.11-.6l-.37-.54c-.14-.2-.41-.24-.6-.11l-.62,.43c-.08,.06-.2,0-.21-.1l-.37-4.54s.02-.09,.06-.12l1.47-1.03c.2-.14,.25-.41,.11-.6l-.37-.53c-.14-.19-.41-.24-.6-.1l-.62,.44c-.08,.06-.2,0-.21-.09-.1-1.52-.32-3.02-.5-4.52,0-.05,.01-.09,.05-.12l1.43-1.05c.19-.14,.23-.41,.09-.6l-.4-.52c-.14-.19-.42-.23-.61-.09l-.6,.45c-.08,.06-.2,.01-.21-.09l-.56-4.52s.01-.09,.05-.12l.43-.33,5.89-.76,.5,.21Z" />
				<g>
					<path d="M1162.28,513.57c.22,1.53,.32,3.07,.44,4.61,0,.08-.08,.14-.15,.09-2.96-1.75-5.62-2.42-7.1-2.68l-.62-5.03c1.6,.31,4.4,1.06,7.38,2.94,.03,.02,.04,.04,.05,.07Z" />
					<path d="M1163.42,526.66c.12,1.54,.27,3.07,.37,4.61,0,.08-.09,.13-.16,.09-2.93-1.84-5.65-2.6-7.09-2.91l-.42-5.05c1.59,.36,4.35,1.21,7.25,3.18,.03,.02,.04,.05,.04,.08Z" />
					<path d="M1161.48,507.05l.57,4.59c.01,.08-.08,.14-.15,.1-3.03-1.69-5.75-2.32-7.21-2.55l-.38-3.08,4.38-.56c.88,.38,1.8,.84,2.75,1.43,.03,.02,.04,.04,.05,.07Z" />
					<path d="M1156.8,531.79c-.02-.66-.1-1.31-.14-1.97,1.08,.26,2.74,.76,4.55,1.7l-4.41,.27Z" />
					<path d="M1162.88,520.11l.38,4.61c0,.08-.08,.13-.15,.09-2.96-1.79-5.65-2.51-7.1-2.79l-.41-5.05c1.58,.33,4.35,1.14,7.24,3.07,.03,.02,.04,.04,.04,.08Z" />
				</g>
				<g>
					<path d="M1172.74,517.22c-.13-1.58-.24-3.16-.46-4.72,0-.03,0-.06,.03-.08,2.44-2.51,4.97-3.95,6.45-4.65l.66,5.35c-1.35,.57-3.9,1.84-6.51,4.17-.06,.06-.16,.02-.17-.07Z" />
					<path d="M1173.83,530.66c-.1-1.58-.25-3.15-.37-4.73,0-.03,0-.06,.03-.08,2.54-2.43,5.12-3.79,6.63-4.43l.44,5.37c-1.39,.53-3.9,1.71-6.56,3.95-.06,.05-.16,.01-.16-.07Z" />
					<path d="M1172.04,510.52l-.58-4.71s0-.06,.03-.08c.77-.81,1.55-1.52,2.31-2.12l4.38-.56,.4,3.27c-1.36,.6-3.84,1.91-6.37,4.27-.06,.06-.16,.02-.17-.06Z" />
					<path d="M1180.67,528.24c.05,.7,.13,1.39,.15,2.09l-4.41,.27c1.71-1.19,3.22-1.93,4.26-2.36Z" />
					<path d="M1173.29,523.94l-.39-4.73s0-.06,.03-.08c2.53-2.48,5.12-3.88,6.62-4.55l.44,5.37c-1.38,.55-3.91,1.78-6.53,4.06-.06,.05-.16,.01-.17-.07Z" />
				</g>
			</g>
			<g>
				<path d="M1165.06,477.86s.08,.05,.09,.1c.34,1.51,.73,3.02,1.03,4.54,.02,.1-.07,.19-.17,.15l-.71-.24c-.23-.08-.47,.05-.55,.28l-.21,.63c-.08,.23,.04,.48,.27,.55l1.69,.57s.08,.05,.09,.1l.9,4.57c.02,.1-.08,.18-.17,.15l-.72-.26c-.23-.08-.47,.04-.55,.26l-.21,.62c-.07,.23,.04,.48,.26,.56l1.67,.62s.08,.06,.08,.1l.75,4.6c.02,.1-.08,.18-.18,.14l-.71-.28c-.22-.09-.47,.02-.56,.25l-.23,.62c-.08,.22,.03,.48,.25,.56l1.68,.67s.08,.06,.08,.1c.22,1.53,.39,3.07,.58,4.6,.01,.1-.09,.18-.18,.14l-.7-.29c-.22-.09-.47,.01-.57,.24l-.26,.61c-.09,.22,0,.48,.23,.57l1.15,.48-5.89,.76,1-.75c.19-.14,.23-.42,.09-.6l-.4-.52c-.14-.19-.42-.22-.61-.08l-.6,.46c-.08,.06-.2,.01-.21-.09-.19-1.5-.36-3.02-.57-4.51,0-.05,.01-.09,.05-.12l1.39-1.1c.19-.15,.21-.42,.06-.6l-.42-.51c-.15-.18-.42-.21-.61-.06l-.59,.47c-.08,.06-.2,.02-.21-.08l-.72-4.47s.01-.09,.05-.12l1.38-1.14c.19-.15,.21-.43,.05-.61l-.43-.49c-.16-.18-.43-.2-.61-.05l-.57,.48c-.08,.06-.2,.02-.22-.07l-.88-4.44s0-.09,.04-.12l1.35-1.18c.18-.16,.2-.43,.04-.61l-.43-.49c-.16-.18-.43-.19-.61-.03l-.57,.5c-.08,.07-.19,.03-.21-.07-.29-1.48-.67-2.94-1-4.42-.01-.04,0-.09,.04-.12l.4-.37,5.79-1.35,.52,.16Z" />
				<g>
					<path d="M1158.5,487.68c.28,1.5,.62,3,.86,4.51,.01,.08-.07,.14-.15,.11-3.09-1.49-5.89-1.89-7.35-2.02l-.96-4.88c1.61,.15,4.44,.62,7.54,2.22,.03,.01,.05,.04,.05,.07Z" />
					<path d="M1160.68,500.53l.56,4.59c0,.08-.08,.14-.15,.1-3.02-1.65-5.74-2.22-7.2-2.43l-.31-2.51-.4-2.45c1.59,.24,4.47,.84,7.45,2.63,.03,.02,.04,.04,.05,.07Z" />
					<path d="M1157.17,481.3l.52,2.24,.44,2.25c.02,.08-.07,.14-.14,.11-3.12-1.4-5.88-1.74-7.37-1.83l-.69-2.96,4.3-1c.91,.28,1.88,.65,2.88,1.13,.03,.01,.05,.04,.06,.07Z" />
					<path d="M1154.3,506.11l-.24-1.96c1.09,.19,2.74,.58,4.62,1.39l-4.38,.56Z" />
					<path d="M1159.67,494.08l.73,4.53c.01,.08-.07,.14-.15,.1-3.09-1.57-5.83-2.06-7.29-2.22-.28-1.63-.49-3.27-.83-4.9,1.63,.19,4.46,.73,7.49,2.42,.03,.01,.05,.04,.05,.07Z" />
				</g>
				<g>
					<path d="M1169.27,490.44c-.25-1.58-.6-3.13-.9-4.7,0-.03,0-.06,.02-.09,2.24-2.74,4.64-4.44,6.06-5.28l1.06,5.39c-1.33,.7-3.66,2.18-6.07,4.73-.06,.06-.16,.03-.17-.05Z" />
					<path d="M1171.22,503.84l-.58-4.71s0-.06,.03-.09c2.42-2.6,4.84-4.16,6.3-4.92l.44,2.71,.34,2.68c-1.36,.62-3.83,1.97-6.35,4.39-.06,.06-.16,.02-.17-.06Z" />
					<path d="M1167.98,483.77l-.46-2.35-.54-2.33s0-.06,.02-.09c.68-.89,1.39-1.67,2.07-2.35l4.3-1,.76,3.26c-1.28,.73-3.62,2.29-5.97,4.9-.06,.06-.16,.03-.17-.05Z" />
					<path d="M1177.92,500.96l.26,2.08-4.38,.56c1.62-1.3,3.12-2.15,4.12-2.64Z" />
					<path d="M1170.36,497.15l-.76-4.73s0-.06,.02-.09c2.34-2.68,4.78-4.31,6.19-5.11,.37,1.79,.61,3.61,.92,5.42-1.33,.66-3.76,2.09-6.2,4.56-.06,.06-.16,.03-.17-.06Z" />
				</g>
			</g>
			<g>
				<path d="M1157.67,451.96s.08,.04,.1,.09l1.46,4.41c.03,.1-.05,.19-.15,.17l-.74-.16c-.24-.05-.46,.1-.51,.33l-.13,.64c-.05,.23,.1,.47,.34,.52l1.75,.4s.08,.05,.1,.09l1.33,4.46c.03,.1-.06,.19-.16,.17l-.74-.18c-.23-.06-.47,.09-.52,.32l-.15,.64c-.06,.23,.09,.47,.32,.53l1.75,.44s.08,.05,.1,.09c.45,1.48,.81,2.99,1.22,4.49,.03,.1-.06,.19-.16,.16l-.73-.2c-.23-.06-.47,.08-.53,.31l-.17,.64c-.06,.23,.07,.47,.31,.54l1.73,.48s.08,.05,.09,.09l.6,2.25,.53,2.27c.02,.1-.07,.19-.17,.16l-.72-.22c-.23-.07-.47,.06-.54,.29l-.19,.63c-.07,.23,.06,.47,.29,.54l1.19,.37-5.79,1.35,.91-.85c.17-.16,.18-.44,.02-.61l-.45-.47c-.16-.17-.44-.18-.61-.01l-.55,.52c-.07,.07-.19,.03-.22-.06l-.51-2.21-.58-2.19s0-.09,.03-.12l1.27-1.25c.17-.17,.17-.44,0-.61l-.46-.46c-.17-.17-.44-.17-.61,0l-.54,.53c-.07,.07-.19,.04-.22-.06-.4-1.46-.75-2.93-1.19-4.37-.01-.04,0-.09,.03-.13l1.23-1.28c.17-.17,.16-.44-.02-.61l-.48-.45c-.17-.16-.45-.16-.61,.02l-.52,.54c-.07,.07-.19,.04-.22-.05l-1.3-4.35s0-.09,.03-.13l1.21-1.31c.16-.18,.15-.45-.03-.61l-.49-.44c-.18-.16-.45-.14-.61,.03l-.51,.56c-.07,.07-.19,.05-.22-.05l-1.42-4.31s0-.09,.03-.13l.36-.4,5.62-1.91,.53,.11Z" />
				<g>
					<path d="M1152.14,462.38l1.32,4.41c.02,.08-.05,.15-.13,.12-3.27-1.15-6.04-1.28-7.52-1.26-.48-1.6-.94-3.2-1.48-4.78,1.62-.01,4.55,.16,7.76,1.45,.03,.01,.05,.03,.06,.06Z" />
					<path d="M1155.67,474.95c.4,1.48,.71,2.98,1.06,4.47,.02,.08-.06,.14-.14,.11-3.18-1.32-5.94-1.59-7.41-1.64-.4-1.61-.85-3.22-1.27-4.82,1.62,.06,4.49,.39,7.69,1.81,.03,.01,.05,.04,.06,.07Z" />
					<path d="M1150.14,456.16l1.44,4.37c.02,.08-.05,.15-.13,.12-3.29-1.07-6.08-1.14-7.56-1.08l-.96-2.91,4.18-1.42c.93,.19,1.93,.47,2.97,.85,.03,.01,.05,.03,.06,.06Z" />
					<path d="M1149.93,481.11l-.44-1.89c1.1,.07,2.78,.28,4.74,.88l-4.3,1Z" />
					<path d="M1153.99,468.65l1.18,4.44c.02,.08-.06,.15-.14,.12-3.23-1.23-5.99-1.43-7.47-1.44-.41-1.61-.9-3.21-1.38-4.8,1.62,.03,4.52,.27,7.74,1.62,.03,.01,.05,.04,.06,.07Z" />
				</g>
				<g>
					<path d="M1163.12,464.01l-1.37-4.57s0-.06,.01-.09c1.98-2.96,4.15-4.87,5.47-5.84,.59,1.72,1.09,3.47,1.61,5.21-1.24,.83-3.46,2.56-5.55,5.33-.05,.07-.15,.05-.18-.03Z" />
					<path d="M1166.53,477.14c-.37-1.55-.7-3.11-1.11-4.65,0-.03,0-.06,.02-.09,2.06-2.85,4.37-4.66,5.73-5.57,.46,1.76,.95,3.52,1.39,5.29-1.28,.77-3.6,2.39-5.85,5.06-.05,.06-.16,.04-.18-.04Z" />
					<path d="M1161.18,457.52l-1.49-4.53s0-.06,.01-.09c.6-.95,1.23-1.8,1.85-2.55l4.18-1.42,1.04,3.16c-1.22,.85-3.39,2.63-5.41,5.45-.05,.07-.15,.05-.18-.03Z" />
					<path d="M1172.89,473.57l.48,2.08-4.3,1c1.47-1.47,2.87-2.48,3.82-3.08Z" />
					<path d="M1164.9,470.55l-1.23-4.62s0-.06,.02-.09c2.01-2.9,4.24-4.76,5.58-5.71,.52,1.74,1.06,3.48,1.5,5.25-1.26,.8-3.53,2.48-5.7,5.2-.05,.06-.16,.04-.18-.04Z" />
				</g>
			</g>
			<g>
				<path d="M1148.02,426.87s.09,.04,.11,.08c.62,1.42,1.19,2.85,1.79,4.28,.04,.09-.04,.19-.14,.18l-.75-.1c-.24-.03-.46,.14-.49,.37l-.08,.65c-.03,.24,.14,.46,.37,.49l1.78,.24s.09,.04,.1,.08c.6,1.42,1.17,2.86,1.72,4.31,.04,.09-.04,.19-.14,.18l-.75-.12c-.24-.04-.46,.13-.49,.37l-.1,.65c-.04,.24,.13,.46,.36,.5l1.77,.28s.09,.04,.1,.08c.55,1.45,1.14,2.87,1.65,4.34,.03,.1-.05,.19-.15,.18l-.74-.13c-.24-.04-.46,.12-.5,.36l-.11,.65c-.04,.24,.11,.46,.35,.51l1.76,.32s.08,.04,.1,.09l1.57,4.37c.03,.1-.05,.19-.15,.17l-.75-.14c-.24-.05-.46,.11-.51,.34l-.13,.65c-.05,.24,.1,.47,.34,.51l1.22,.25-5.62,1.91,.83-.94c.16-.18,.14-.45-.03-.61l-.49-.43c-.18-.15-.46-.13-.61,.05l-.49,.57c-.07,.08-.19,.05-.22-.04l-1.54-4.27s0-.09,.02-.13l1.16-1.36c.15-.18,.13-.46-.05-.61l-.5-.42c-.18-.15-.45-.13-.61,.06l-.49,.58c-.06,.08-.19,.06-.22-.04-.5-1.43-1.08-2.83-1.62-4.25-.02-.04,0-.09,.02-.13l1.12-1.39c.15-.19,.12-.46-.06-.61l-.51-.41c-.19-.15-.46-.12-.61,.07l-.47,.59c-.06,.08-.19,.06-.22-.03-.54-1.41-1.09-2.83-1.69-4.22-.02-.04-.01-.09,.02-.13l1.09-1.41c.15-.19,.11-.46-.08-.6l-.52-.4c-.19-.14-.46-.11-.61,.08l-.46,.6c-.06,.08-.19,.06-.23-.03-.59-1.4-1.15-2.81-1.76-4.2-.02-.04-.01-.09,.02-.13l.32-.43,5.46-2.35,.54,.06Z" />
				<g>
					<path d="M1143.37,437.72c.58,1.43,1.12,2.87,1.68,4.3,.03,.08-.04,.15-.12,.13-3.34-.87-6.11-.77-7.58-.62-.61-1.57-1.28-3.12-1.92-4.68,1.62-.14,4.51-.19,7.88,.81,.03,0,.05,.03,.07,.06Z" />
					<path d="M1148.01,449.97c.51,1.45,1.06,2.89,1.52,4.35,.03,.08-.05,.15-.13,.13-3.27-1.01-6.1-1.01-7.56-.92l-1.7-4.73c1.62-.08,4.5-.02,7.8,1.11,.03,0,.05,.03,.06,.06Z" />
					<path d="M1140.86,431.66l1.77,4.27c.03,.08-.04,.15-.12,.13-3.36-.8-6.14-.65-7.61-.48-.4-.95-.78-1.91-1.21-2.85l4.06-1.75c.95,.12,1.98,.31,3.04,.61,.03,0,.05,.03,.07,.06Z" />
					<path d="M1142.93,456.67c-.21-.62-.39-1.24-.62-1.85,1.12-.04,2.81,.01,4.81,.43l-4.18,1.42Z" />
					<path d="M1145.75,443.83c.57,1.43,1.09,2.88,1.6,4.33,.03,.08-.05,.15-.12,.13-3.31-.93-6.08-.89-7.56-.77l-1.82-4.7c1.62-.11,4.51-.1,7.84,.95,.03,0,.05,.03,.06,.06Z" />
				</g>
				<g>
					<path d="M1154.43,438.39c-.58-1.48-1.14-2.96-1.73-4.43-.01-.03-.01-.06,0-.09,1.67-3.09,3.72-5.18,4.95-6.25,.68,1.67,1.39,3.32,2.05,5-1.17,.93-3.24,2.84-5.1,5.79-.04,.07-.15,.06-.18-.02Z" />
					<path d="M1159.06,451.09c-.48-1.52-1.05-3-1.58-4.5-.01-.03,0-.06,0-.09,1.81-3.03,3.94-5.05,5.22-6.08l1.84,5.11c-1.21,.88-3.3,2.69-5.31,5.58-.05,.07-.15,.05-.18-.03Z" />
					<path d="M1151.94,432.12l-1.82-4.39s-.01-.06,0-.09c.53-1,1.07-1.88,1.63-2.67l4.06-1.75c.45,1,.86,2.02,1.28,3.04-1.15,.95-3.18,2.89-4.98,5.87-.04,.07-.15,.06-.18-.01Z" />
					<path d="M1165.05,446.93c.25,.66,.45,1.34,.68,2.01l-4.18,1.42c1.33-1.61,2.63-2.74,3.51-3.42Z" />
					<path d="M1156.81,444.71c-.54-1.5-1.07-2.99-1.66-4.46-.01-.03,0-.06,0-.09,1.74-3.06,3.83-5.12,5.08-6.17l1.96,5.05c-1.17,.9-3.29,2.78-5.21,5.69-.05,.07-.15,.06-.18-.02Z" />
				</g>
			</g>
			<g>
				<path d="M1136.68,402.57s.09,.03,.11,.07c.68,1.38,1.39,2.75,2.05,4.14,.04,.09-.03,.2-.13,.19l-.75-.05c-.24-.02-.45,.16-.46,.4l-.05,.66c-.02,.24,.16,.45,.4,.47l1.78,.13s.09,.03,.11,.07l2.01,4.17c.04,.09-.03,.2-.13,.19l-.76-.06c-.24-.02-.45,.16-.47,.4l-.05,.66c-.02,.24,.15,.45,.39,.47l1.78,.16s.09,.03,.11,.08l1.94,4.2c.04,.09-.03,.2-.13,.19l-.76-.07c-.24-.02-.45,.15-.47,.39l-.06,.65c-.02,.24,.15,.45,.39,.47l1.78,.18s.09,.03,.11,.08l1.86,4.24c.04,.09-.03,.19-.13,.18l-.75-.09c-.24-.03-.45,.14-.48,.38l-.07,.65c-.03,.24,.15,.45,.38,.48l1.24,.15-5.46,2.35,.74-1c.14-.19,.1-.46-.09-.6l-.53-.39c-.19-.14-.46-.1-.61,.09l-.45,.6c-.06,.08-.19,.07-.23-.02l-1.83-4.17s-.01-.09,.01-.13l1.05-1.45c.14-.19,.09-.46-.1-.6l-.54-.38c-.2-.14-.47-.09-.6,.1l-.44,.61c-.06,.08-.18,.07-.23-.02l-1.91-4.14s-.01-.09,.01-.13l1.03-1.46c.14-.2,.09-.47-.1-.6l-.54-.37c-.2-.13-.47-.08-.6,.11l-.43,.62c-.06,.08-.18,.07-.23-.02l-1.98-4.11s-.02-.09,0-.13l1.01-1.48c.13-.2,.08-.47-.11-.6l-.54-.37c-.2-.13-.47-.08-.6,.12l-.43,.63c-.06,.08-.18,.08-.23-.02-.65-1.37-1.35-2.73-2.03-4.09-.02-.04-.02-.09,0-.13l.3-.45,5.31-2.66,.54,.03Z" />
				<g>
					<path d="M1132.7,413.7c.67,1.4,1.36,2.78,1.99,4.19,.03,.07-.03,.16-.11,.14-3.37-.65-6.18-.37-7.63-.13l-2.22-4.6c1.61-.24,4.49-.45,7.9,.34,.03,0,.06,.03,.07,.05Z" />
					<path d="M1138.23,425.64l1.86,4.24c.03,.07-.04,.15-.12,.14-3.38-.74-6.16-.55-7.63-.35-.68-1.55-1.35-3.1-2.07-4.64,1.61-.2,4.53-.34,7.89,.55,.03,0,.05,.03,.07,.06Z" />
					<path d="M1129.81,407.77c.7,1.38,1.37,2.78,2.04,4.17,.04,.07-.03,.16-.11,.14-3.4-.6-6.16-.3-7.62-.03l-1.4-2.8,3.95-1.97c.95,.07,1.99,.21,3.07,.44,.03,0,.06,.03,.07,.05Z" />
					<path d="M1133.7,432.73l-.79-1.81c1.1-.12,2.81-.2,4.85,.06l-4.06,1.75Z" />
					<path d="M1135.51,419.65l1.94,4.21c.03,.07-.03,.16-.11,.14-3.39-.69-6.18-.45-7.64-.23l-2.14-4.62c1.62-.22,4.49-.39,7.89,.45,.03,0,.06,.03,.07,.06Z" />
				</g>
				<g>
					<path d="M1143.8,413.62c-.65-1.44-1.35-2.85-2.03-4.28-.01-.03-.01-.06,0-.09,1.49-3.19,3.41-5.39,4.58-6.54l2.33,4.82c-1.1,1-3.02,3.02-4.69,6.09-.04,.07-.15,.07-.18,0Z" />
					<path d="M1149.33,425.9l-1.91-4.35s-.01-.06,0-.09c1.6-3.16,3.56-5.3,4.75-6.41,.76,1.62,1.47,3.27,2.2,4.91-1.13,.97-3.13,2.94-4.86,5.95-.04,.07-.15,.07-.18-.01Z" />
					<path d="M1140.91,407.55c-.68-1.42-1.37-2.84-2.08-4.25-.01-.03-.01-.06,0-.09,.46-1.02,.96-1.94,1.48-2.75l3.95-1.97,1.46,2.92c-1.08,1.01-3,3.07-4.62,6.15-.04,.07-.14,.07-.18,0Z" />
					<path d="M1154.96,421.3l.84,1.92-4.06,1.75c1.19-1.69,2.39-2.92,3.22-3.66Z" />
					<path d="M1146.62,419.73l-1.98-4.3s-.01-.06,0-.09c1.53-3.17,3.5-5.36,4.67-6.49l2.26,4.87c-1.11,.98-3.07,2.98-4.77,6.02-.04,.07-.15,.07-.18,0Z" />
				</g>
			</g>
			<g>
				<path d="M1124.28,378.89s.09,.03,.11,.07l2.19,4.05c.05,.09-.02,.2-.12,.19l-.76-.02c-.24,0-.44,.18-.45,.42l-.02,.66c0,.24,.18,.44,.42,.45l1.79,.06s.09,.03,.11,.07l2.16,4.08c.05,.09-.02,.2-.12,.19l-.76-.03c-.24,0-.44,.18-.45,.42l-.02,.66c0,.24,.18,.44,.42,.45l1.79,.07s.09,.03,.11,.07l2.13,4.1c.05,.09-.02,.2-.12,.19l-.76-.04c-.24-.01-.44,.17-.45,.41l-.03,.66c-.01,.24,.17,.44,.41,.45l1.79,.09s.09,.03,.11,.07c.71,1.36,1.41,2.74,2.09,4.12,.05,.09-.02,.2-.12,.19l-.75-.04c-.24-.01-.44,.17-.46,.41l-.04,.66c-.01,.24,.17,.45,.41,.46l1.25,.08-5.31,2.66,.69-1.04c.13-.2,.08-.47-.12-.6l-.55-.36c-.2-.13-.47-.07-.6,.13l-.42,.63c-.06,.08-.18,.08-.23-.01-.68-1.36-1.36-2.72-2.07-4.07-.02-.04-.02-.09,0-.13l.97-1.5c.13-.2,.07-.47-.13-.6l-.55-.35c-.2-.13-.47-.07-.6,.13l-.41,.63c-.05,.08-.18,.08-.23-.01l-2.1-4.06s-.02-.09,0-.13l.95-1.51c.13-.2,.07-.47-.14-.6l-.56-.35c-.2-.13-.47-.07-.6,.14l-.4,.64c-.05,.09-.18,.08-.23,0l-2.14-4.04s-.02-.09,0-.13l.94-1.52c.13-.2,.06-.47-.14-.59l-.56-.34c-.2-.13-.47-.06-.6,.14l-.4,.64c-.05,.09-.18,.08-.23,0l-2.18-4.03s-.02-.09,0-.13l.28-.46,5.22-2.84h.54Z" />
				<g>
					<path d="M1120.74,390.16l2.18,4.12c.04,.07-.02,.16-.1,.15-3.43-.49-6.2-.11-7.65,.19l-2.43-4.55c1.6-.3,4.5-.61,7.93,.05,.03,0,.06,.03,.07,.05Z" />
					<path d="M1126.86,401.88l2.09,4.15c.04,.07-.03,.16-.11,.14-3.41-.56-6.18-.23-7.63,.05l-2.35-4.57c1.6-.27,4.49-.54,7.93,.17,.03,0,.06,.03,.07,.05Z" />
					<path d="M1117.6,384.33l2.22,4.1c.04,.07-.02,.16-.1,.15-3.43-.47-6.2-.07-7.65,.24l-1.5-2.78,3.88-2.11c.96,.04,1.99,.14,3.09,.35,.03,0,.06,.02,.07,.05Z" />
					<path d="M1122.72,409.25l-.89-1.78c1.1-.18,2.79-.34,4.84-.19l-3.95,1.97Z" />
					<path d="M1123.83,396.01l2.13,4.13c.04,.07-.02,.16-.11,.14-3.42-.52-6.19-.16-7.64,.13l-2.39-4.56c1.6-.28,4.5-.58,7.93,.1,.03,0,.06,.03,.07,.05Z" />
				</g>
				<g>
					<path d="M1131.82,389.6l-2.21-4.17s-.02-.06,0-.09c1.37-3.25,3.2-5.51,4.32-6.69l2.5,4.68c-1.06,1.04-2.91,3.14-4.43,6.26-.04,.07-.14,.08-.18,0Z" />
					<path d="M1137.94,401.53l-2.12-4.22s-.02-.06,0-.09c1.41-3.22,3.28-5.45,4.42-6.62l2.43,4.74c-1.08,1.02-2.97,3.1-4.55,6.2-.04,.07-.14,.07-.18,0Z" />
					<path d="M1128.69,383.68l-2.24-4.14s-.02-.06,0-.09c.43-1.03,.9-1.97,1.39-2.8l3.88-2.11,1.54,2.84c-1.05,1.05-2.89,3.16-4.38,6.29-.04,.07-.14,.08-.18,0Z" />
					<path d="M1143.32,396.63l.93,1.86-3.95,1.97c1.1-1.76,2.23-3.04,3.02-3.83Z" />
					<path d="M1134.91,395.55l-2.17-4.2s-.02-.06,0-.09c1.39-3.24,3.23-5.48,4.37-6.66l2.47,4.7c-1.07,1.03-2.94,3.12-4.48,6.23-.04,.07-.14,.08-.18,0Z" />
				</g>
			</g>
			<g>
				<path d="M1111.37,355.57s.09,.03,.11,.07l2.24,4.01c.05,.09-.02,.2-.12,.2h-.76c-.24-.01-.44,.18-.44,.42v.66c-.01,.24,.18,.44,.42,.44l1.79,.03s.09,.03,.11,.07l2.24,4.02c.05,.09-.02,.2-.12,.19h-.76c-.24-.02-.44,.17-.44,.41v.66c-.01,.24,.18,.44,.42,.44l1.79,.03s.09,.03,.11,.07l2.23,4.03c.05,.09-.02,.2-.12,.19l-.76-.02c-.24,0-.44,.19-.44,.43v.66c-.02,.24,.17,.44,.41,.44l1.79,.04s.09,.03,.11,.07l2.22,4.04c.05,.09-.02,.2-.12,.19l-.76-.02c-.24,0-.44,.18-.44,.42l-.02,.66c0,.24,.18,.44,.42,.45l1.25,.03-5.22,2.84,.65-1.06c.13-.2,.06-.47-.14-.6l-.56-.34c-.2-.12-.47-.06-.6,.15l-.39,.64c-.05,.09-.18,.08-.23,0l-2.21-4.02s-.02-.09,0-.13l.93-1.53c.12-.2,.06-.47-.15-.59l-.56-.34c-.2-.12-.47-.06-.59,.15l-.39,.65c-.05,.09-.18,.08-.23,0l-2.22-4.01s-.02-.09,0-.13l.92-1.53c.12-.21,.06-.47-.15-.59l-.56-.34c-.21-.12-.47-.06-.59,.15l-.39,.65c-.05,.09-.18,.08-.23,0l-2.23-4.01s-.02-.09,0-.13l.92-1.53c.12-.21,.06-.47-.15-.59l-.56-.34c-.21-.12-.47-.05-.59,.15l-.39,.65c-.05,.09-.18,.08-.23,0l-2.24-4.01s-.02-.09,0-.13l.28-.46,5.18-2.9h.54Z" />
				<g>
					<path d="M1108,366.9l2.27,4.09c.04,.07-.02,.16-.1,.15-3.44-.43-6.2,0-7.65,.33l-2.54-4.55c1.6-.32,4.49-.67,7.94-.07,.03,0,.06,.02,.07,.05Z" />
					<path d="M1114.43,378.51l2.24,4.1c.04,.07-.02,.16-.1,.15-3.42-.45-6.2-.04-7.64,.28l-2.5-4.55c1.6-.31,4.48-.65,7.93-.03,.03,0,.06,.02,.07,.05Z" />
					<path d="M1104.76,361.1l2.28,4.09c.04,.07-.02,.16-.1,.15-3.44-.42-6.21,.01-7.65,.34l-1.56-2.79,3.85-2.16c.96,.03,2,.12,3.1,.31,.03,0,.06,.02,.07,.05Z" />
					<path d="M1110.56,386.04l-.96-1.77c1.1-.21,2.78-.43,4.84-.34l-3.88,2.11Z" />
					<path d="M1111.23,372.7l2.26,4.09c.04,.07-.02,.16-.1,.15-3.44-.44-6.19-.01-7.64,.31l-2.53-4.55c1.6-.32,4.48-.66,7.94-.05,.03,0,.06,.02,.07,.05Z" />
				</g>
				<g>
					<path d="M1119.07,366.11l-2.28-4.11s-.02-.06,0-.09c1.3-3.26,3.11-5.54,4.22-6.74l2.56,4.58c-1.04,1.06-2.85,3.19-4.31,6.34-.03,.08-.14,.08-.18,0Z" />
					<path d="M1125.5,377.8l-2.26-4.13s-.02-.06,0-.09c1.32-3.26,3.14-5.53,4.25-6.73l2.54,4.62c-1.05,1.05-2.87,3.17-4.35,6.32-.04,.07-.14,.08-.18,0Z" />
					<path d="M1115.83,360.28l-2.29-4.1s-.02-.06,0-.09c.41-1.04,.88-1.97,1.35-2.81l3.85-2.16,1.56,2.79c-1.04,1.06-2.85,3.19-4.3,6.34-.03,.08-.14,.08-.18,0Z" />
					<path d="M1130.73,372.74l.98,1.81-3.88,2.11c1.04-1.79,2.14-3.11,2.9-3.92Z" />
					<path d="M1122.3,371.94l-2.27-4.12s-.02-.06,0-.09c1.31-3.26,3.12-5.54,4.23-6.73l2.55,4.6c-1.04,1.05-2.86,3.19-4.33,6.34-.04,.08-.14,.08-.18,0Z" />
				</g>
			</g>
			<g>
				<path d="M1098.47,332.33s.09,.03,.11,.07l2.2,4.02c.05,.09-.02,.2-.12,.19h-.76c-.24-.02-.44,.17-.44,.41v.66c-.02,.24,.17,.44,.41,.44l1.79,.03s.09,.03,.11,.07l2.22,4.01c.05,.09-.02,.2-.12,.19h-.76c-.24-.02-.44,.17-.44,.41v.66c-.01,.24,.18,.44,.42,.44l1.79,.03s.09,.03,.11,.07l2.23,4.01c.05,.09-.02,.2-.12,.19h-.76c-.24-.01-.44,.18-.44,.42v.66c-.01,.24,.18,.44,.42,.44l1.79,.02s.09,.03,.11,.07l2.24,4.01c.05,.09-.02,.2-.12,.2h-.76c-.24-.01-.44,.18-.44,.42v.66c-.01,.24,.18,.44,.42,.44l1.25,.02-5.18,2.9,.64-1.07c.12-.21,.06-.47-.15-.59l-.56-.34c-.21-.12-.47-.05-.59,.15l-.39,.65c-.05,.09-.18,.09-.23,0l-2.24-4.01s-.02-.09,0-.13l.92-1.54c.12-.21,.06-.47-.15-.59l-.56-.34c-.21-.12-.47-.06-.59,.15l-.39,.65c-.05,.09-.18,.08-.23,0l-2.24-4.02s-.02-.09,0-.13l.92-1.53c.12-.21,.06-.47-.15-.59l-.56-.34c-.21-.12-.47-.06-.59,.15l-.39,.65c-.05,.09-.18,.08-.23,0l-2.23-4.03s-.02-.09,0-.13l.92-1.53c.12-.2,.06-.47-.15-.59l-.56-.34c-.21-.12-.47-.06-.6,.15l-.39,.65c-.05,.09-.18,.08-.23,0l-2.21-4.04s-.02-.09,0-.13l.28-.46,5.21-2.84h.54Z" />
				<g>
					<path d="M1095.03,343.64l2.28,4.11c.04,.07-.02,.16-.1,.15-3.45-.43-6.21-.02-7.66,.31l-2.55-4.61c1.6-.3,4.5-.63,7.96,0,.03,0,.06,.02,.07,.05Z" />
					<path d="M1101.51,355.29l2.29,4.09c.04,.07-.02,.16-.1,.15-3.44-.42-6.21,.01-7.65,.34l-2.56-4.57c1.6-.31,4.49-.65,7.95-.06,.03,0,.06,.02,.07,.05Z" />
					<path d="M1091.81,337.79l2.27,4.12c.04,.07-.02,.16-.1,.15-3.44-.44-6.22-.04-7.67,.28l-1.55-2.83,3.88-2.12c.96,.04,2,.15,3.1,.35,.03,0,.06,.02,.07,.05Z" />
					<path d="M1097.73,362.89l-.99-1.78c1.09-.22,2.78-.45,4.85-.38l-3.85,2.16Z" />
					<path d="M1098.26,349.47l2.29,4.1c.04,.07-.02,.16-.1,.15-3.44-.42-6.21,0-7.65,.33l-2.56-4.59c1.6-.31,4.49-.65,7.95-.04,.03,0,.06,.02,.07,.05Z" />
				</g>
				<g>
					<path d="M1106.1,342.86l-2.27-4.09s-.02-.06,0-.09c1.32-3.25,3.15-5.51,4.26-6.69l2.51,4.55c-1.04,1.05-2.87,3.17-4.32,6.32-.03,.07-.14,.08-.18,0Z" />
					<path d="M1112.58,354.47l-2.29-4.09s-.02-.06,0-.09c1.3-3.26,3.11-5.53,4.22-6.73l2.55,4.56c-1.04,1.06-2.85,3.19-4.3,6.34-.03,.08-.14,.08-.18,0Z" />
					<path d="M1102.88,337.06l-2.25-4.09s-.02-.06,0-.09c.42-1.03,.9-1.96,1.39-2.78l3.88-2.12,1.52,2.78c-1.05,1.05-2.88,3.16-4.34,6.29-.03,.07-.14,.08-.18,0Z" />
					<path d="M1117.75,349.36l.99,1.77-3.85,2.16c1.02-1.79,2.11-3.11,2.86-3.93Z" />
					<path d="M1109.33,348.66l-2.28-4.09s-.02-.06,0-.09c1.31-3.25,3.12-5.52,4.24-6.71l2.54,4.55c-1.04,1.06-2.86,3.18-4.31,6.33-.03,.08-.14,.08-.18,0Z" />
				</g>
			</g>
			<g>
				<path d="M1086.1,308.89s.09,.03,.11,.07l2.07,4.07c.05,.09-.02,.2-.12,.19l-.75-.04c-.24-.01-.44,.17-.46,.41l-.04,.66c-.01,.24,.17,.44,.41,.45l1.79,.08s.09,.03,.11,.07l2.12,4.05c.05,.09-.02,.2-.12,.19l-.75-.03c-.24,0-.44,.18-.45,.42l-.03,.66c-.01,.24,.18,.44,.41,.45l1.78,.07s.09,.03,.11,.07l2.16,4.04c.05,.09-.02,.2-.12,.19l-.76-.02c-.24,0-.44,.18-.45,.42l-.02,.66c0,.24,.18,.44,.42,.45l1.78,.05s.09,.03,.11,.07l2.18,4.03c.05,.09-.02,.2-.12,.19l-.76-.02c-.24,0-.44,.18-.44,.42l-.02,.66c0,.24,.18,.44,.42,.44l1.25,.03-5.21,2.84,.65-1.07c.12-.2,.06-.47-.14-.6l-.56-.34c-.2-.12-.47-.06-.6,.14l-.39,.65c-.05,.09-.18,.08-.23,0l-2.19-4.05s-.02-.09,0-.13l.94-1.52c.13-.2,.06-.47-.14-.6l-.56-.35c-.2-.13-.47-.06-.6,.14l-.4,.64c-.05,.09-.18,.08-.23,0l-2.17-4.07s-.02-.09,0-.13l.95-1.52c.13-.2,.07-.47-.13-.6l-.56-.35c-.2-.13-.47-.07-.6,.13l-.4,.64c-.05,.09-.18,.08-.23,0l-2.14-4.09s-.02-.09,0-.13l.96-1.51c.13-.2,.07-.47-.13-.6l-.55-.36c-.2-.13-.47-.07-.6,.13l-.41,.64c-.05,.09-.18,.08-.23-.01l-2.09-4.12s-.02-.09,0-.13l.29-.45,5.3-2.67,.54,.03Z" />
				<g>
					<path d="M1082.34,320.1l2.19,4.18c.04,.07-.02,.16-.1,.15-3.43-.52-6.21-.18-7.67,.1-.82-1.57-1.65-3.13-2.45-4.72,1.61-.25,4.51-.47,7.97,.24,.03,0,.06,.03,.07,.05Z" />
					<path d="M1088.61,331.93l2.25,4.13c.04,.07-.02,.16-.1,.15-3.44-.46-6.22-.08-7.67,.23l-2.51-4.66c1.61-.28,4.51-.56,7.96,.1,.03,0,.06,.03,.07,.05Z" />
					<path d="M1079.27,314.14l2.15,4.21c.04,.07-.02,.16-.11,.15-3.43-.55-6.21-.24-7.67,.03l-1.48-2.9,3.94-1.99c.96,.08,2,.22,3.09,.46,.03,0,.06,.03,.07,.05Z" />
					<path d="M1084.76,339.5l-.98-1.8c1.1-.2,2.79-.41,4.86-.31l-3.88,2.12Z" />
					<path d="M1085.45,326.03l2.22,4.15c.04,.07-.02,.16-.1,.15-3.44-.49-6.22-.12-7.67,.17l-2.48-4.69c1.61-.27,4.51-.52,7.96,.16,.03,0,.06,.03,.07,.05Z" />
				</g>
				<g>
					<path d="M1093.42,319.58l-2.16-4.13s-.02-.06,0-.09c1.4-3.2,3.3-5.4,4.46-6.55,.77,1.53,1.58,3.04,2.37,4.56-1.07,1.02-2.95,3.09-4.48,6.19-.04,.07-.14,.08-.18,0Z" />
					<path d="M1099.69,331.24l-2.23-4.1s-.02-.06,0-.09c1.36-3.23,3.22-5.47,4.35-6.64l2.45,4.55c-1.05,1.04-2.9,3.14-4.38,6.27-.04,.07-.14,.08-.18,0Z" />
					<path d="M1090.35,313.73l-2.11-4.14s-.01-.06,0-.09c.46-1.01,.96-1.92,1.47-2.73l3.94-1.99,1.42,2.8c-1.08,1.01-2.99,3.06-4.55,6.15-.04,.07-.14,.08-.18,0Z" />
					<path d="M1104.92,326.21l.96,1.77-3.88,2.12c1.05-1.78,2.15-3.08,2.91-3.88Z" />
					<path d="M1096.54,325.42l-2.2-4.11s-.02-.06,0-.09c1.37-3.22,3.26-5.44,4.4-6.6l2.41,4.56c-1.06,1.03-2.92,3.11-4.43,6.23-.04,.07-.14,.08-.18,0Z" />
				</g>
			</g>
			<g>
				<path d="M1074.79,285.01s.09,.03,.1,.08l1.87,4.16c.04,.09-.03,.19-.13,.18l-.75-.07c-.24-.02-.45,.15-.48,.39l-.07,.65c-.02,.24,.15,.45,.39,.47l1.77,.17s.09,.03,.11,.08c.61,1.39,1.27,2.76,1.92,4.13,.04,.09-.03,.19-.13,.19l-.75-.06c-.24-.02-.45,.16-.47,.39l-.06,.65c-.02,.24,.16,.45,.4,.47l1.78,.14s.09,.03,.11,.07c.65,1.37,1.29,2.75,1.97,4.11,.04,.09-.03,.19-.13,.19l-.76-.05c-.24-.02-.45,.16-.46,.4l-.05,.65c-.02,.24,.16,.44,.4,.46l1.78,.12s.09,.03,.11,.07l2.02,4.09c.05,.09-.02,.19-.12,.19l-.75-.04c-.24-.01-.44,.17-.46,.41l-.04,.65c-.01,.24,.17,.44,.41,.45l1.25,.07-5.3,2.67,.68-1.04c.13-.2,.07-.47-.13-.6l-.55-.36c-.2-.13-.47-.08-.6,.12l-.41,.63c-.06,.08-.18,.08-.23-.01l-2.04-4.15s-.02-.09,0-.13l1-1.49c.13-.2,.08-.47-.12-.6l-.55-.37c-.2-.13-.47-.08-.6,.12l-.42,.63c-.06,.08-.18,.07-.23-.02-.69-1.38-1.34-2.78-2-4.17-.02-.04-.02-.09,.01-.13l1.02-1.47c.14-.2,.09-.47-.11-.6l-.54-.38c-.2-.14-.47-.09-.6,.11l-.43,.62c-.06,.08-.18,.07-.23-.02-.66-1.4-1.33-2.78-1.95-4.2-.02-.04-.01-.09,.01-.13l1.04-1.46c.14-.2,.09-.47-.1-.61l-.53-.39c-.19-.14-.47-.1-.61,.1l-.44,.62c-.06,.08-.18,.07-.23-.02l-1.9-4.22s-.01-.09,.02-.13l.32-.44,5.44-2.38,.53,.06Z" />
				<g>
					<path d="M1070.46,296.02l2.02,4.28c.04,.08-.03,.16-.11,.14-3.41-.67-6.22-.47-7.69-.26-.77-1.61-1.48-3.26-2.23-4.89,1.63-.17,4.55-.24,7.94,.66,.03,0,.05,.03,.07,.06Z" />
					<path d="M1076.27,308.14c.7,1.42,1.39,2.83,2.11,4.23,.04,.07-.02,.16-.11,.14-3.44-.57-6.22-.31-7.68-.05l-2.36-4.8c1.62-.22,4.54-.38,7.97,.42,.03,0,.06,.03,.07,.06Z" />
					<path d="M1067.69,289.88l1.95,4.32c.03,.08-.03,.16-.11,.14-3.4-.73-6.21-.57-7.68-.38-.45-1-.91-1.99-1.34-3l4.05-1.77c.95,.14,1.98,.33,3.07,.63,.03,0,.06,.03,.07,.06Z" />
					<path d="M1072.17,315.61l-.94-1.85c1.1-.16,2.81-.3,4.88-.14l-3.94,1.99Z" />
					<path d="M1073.33,302.1c.67,1.43,1.37,2.84,2.07,4.26,.04,.07-.03,.16-.11,.14-3.42-.62-6.23-.38-7.69-.14l-2.29-4.85c1.62-.19,4.54-.31,7.96,.54,.03,0,.06,.03,.07,.06Z" />
				</g>
				<g>
					<path d="M1081.57,296l-1.98-4.2s-.01-.06,0-.09c1.58-3.13,3.59-5.22,4.8-6.3,.71,1.54,1.37,3.1,2.11,4.63-1.12,.97-3.09,2.93-4.75,5.96-.04,.07-.14,.07-.18,0Z" />
					<path d="M1087.36,307.84c-.71-1.38-1.39-2.77-2.07-4.16-.01-.03-.01-.06,0-.09,1.48-3.17,3.42-5.32,4.6-6.44l2.26,4.59c-1.09,1-3.03,3.03-4.6,6.09-.04,.07-.14,.07-.18,0Z" />
					<path d="M1078.79,290.03l-1.9-4.22s-.01-.06,0-.09c.51-.98,1.06-1.86,1.62-2.64l4.05-1.77c.4,.95,.85,1.89,1.27,2.84-1.13,.95-3.14,2.88-4.85,5.89-.04,.07-.15,.07-.18,0Z" />
					<path d="M1092.76,303l.9,1.78-3.94,1.99c1.1-1.73,2.24-2.99,3.04-3.77Z" />
					<path d="M1084.43,301.94c-.68-1.39-1.38-2.77-2.03-4.18-.01-.03-.01-.06,0-.09,1.53-3.15,3.5-5.27,4.69-6.37l2.18,4.61c-1.11,.99-3.05,2.98-4.67,6.03-.04,.07-.14,.07-.18,0Z" />
				</g>
			</g>
			<g>
				<path d="M1065.09,260.5s.08,.04,.1,.08c.5,1.43,1.01,2.86,1.55,4.28,.04,.09-.05,.19-.15,.17l-.75-.12c-.24-.04-.46,.12-.5,.35l-.11,.64c-.04,.23,.12,.46,.36,.5l1.76,.29s.08,.04,.1,.08l1.61,4.25c.04,.09-.04,.19-.14,.18l-.75-.11c-.24-.03-.46,.13-.49,.36l-.09,.64c-.03,.23,.13,.45,.37,.49l1.77,.25s.09,.04,.1,.08l1.7,4.22c.04,.09-.04,.19-.14,.18l-.75-.1c-.24-.03-.46,.13-.49,.37l-.09,.65c-.03,.24,.13,.46,.37,.48l1.78,.22s.09,.04,.1,.08l1.79,4.19c.04,.09-.04,.19-.14,.18l-.75-.08c-.24-.03-.45,.14-.48,.38l-.08,.65c-.03,.24,.14,.45,.38,.48l1.23,.14-5.44,2.38,.74-1.01c.14-.19,.1-.47-.09-.61l-.53-.39c-.19-.14-.46-.1-.61,.09l-.45,.61c-.06,.08-.19,.07-.23-.03l-1.82-4.26s-.01-.09,.02-.13l1.08-1.42c.14-.19,.11-.46-.08-.61l-.52-.4c-.19-.15-.46-.11-.61,.08l-.46,.6c-.06,.08-.19,.06-.23-.03l-1.73-4.3s-.01-.09,.02-.13l1.1-1.4c.15-.19,.11-.46-.07-.61l-.52-.41c-.19-.15-.46-.12-.61,.07l-.47,.59c-.06,.08-.19,.06-.23-.03l-1.64-4.34s0-.09,.02-.13l1.13-1.38c.15-.18,.12-.46-.06-.61l-.51-.42c-.19-.15-.46-.13-.61,.05l-.48,.58c-.06,.08-.19,.06-.22-.04-.56-1.45-1.07-2.9-1.58-4.36-.02-.04,0-.09,.02-.13l.35-.41,5.6-1.97,.53,.1Z" />
				<g>
					<path d="M1059.99,271.18c.55,1.49,1.11,2.97,1.72,4.43,.03,.08-.04,.16-.12,.13-3.39-.89-6.19-.9-7.66-.79l-1.9-5.07c1.64-.05,4.57,.1,7.9,1.23,.03,0,.05,.03,.06,.06Z" />
					<path d="M1065.02,283.7l1.87,4.37c.03,.08-.04,.16-.12,.14-3.38-.78-6.19-.68-7.66-.51-.7-1.66-1.44-3.29-2.09-4.97,1.62-.1,4.54-.07,7.94,.92,.03,0,.05,.03,.07,.06Z" />
					<path d="M1057.64,264.85c.51,1.5,1.1,2.97,1.65,4.46,.03,.08-.04,.16-.12,.13-3.35-.97-6.17-1.02-7.66-.94l-1.1-3.11,4.17-1.47c.94,.2,1.96,.48,3.01,.86,.03,.01,.05,.03,.06,.06Z" />
					<path d="M1060.51,290.96l-.82-1.93c1.11-.09,2.82-.13,4.87,.16l-4.05,1.77Z" />
					<path d="M1062.45,277.46c.6,1.47,1.17,2.95,1.79,4.4,.03,.08-.04,.16-.12,.14-3.39-.83-6.17-.79-7.66-.65l-2.02-5.02c1.64-.08,4.57,0,7.94,1.07,.03,0,.05,.03,.07,.06Z" />
				</g>
				<g>
					<path d="M1071.06,271.91c-.59-1.42-1.13-2.86-1.67-4.31-.01-.03,0-.06,0-.09,1.79-3.01,3.94-4.93,5.22-5.92l1.77,4.72c-1.19,.89-3.31,2.71-5.15,5.61-.04,.07-.15,.06-.18-.01Z" />
					<path d="M1076.1,284.03l-1.82-4.25s-.01-.06,0-.09c1.64-3.07,3.73-5.08,4.99-6.13,.61,1.57,1.31,3.11,1.97,4.67-1.15,.93-3.19,2.83-4.96,5.81-.04,.07-.15,.07-.18,0Z" />
					<path d="M1068.71,265.79c-.53-1.44-1.1-2.87-1.6-4.33-.01-.03,0-.06,0-.09,.58-.94,1.2-1.78,1.82-2.51l4.17-1.47,1.03,2.92c-1.2,.86-3.33,2.63-5.25,5.5-.05,.07-.15,.06-.18-.02Z" />
					<path d="M1081.79,279.5l.77,1.81-4.05,1.77c1.21-1.67,2.43-2.86,3.27-3.58Z" />
					<path d="M1073.52,277.99c-.6-1.42-1.16-2.86-1.74-4.28-.01-.03,0-.06,0-.09,1.72-3.04,3.82-5.01,5.09-6.02l1.89,4.69c-1.16,.91-3.26,2.78-5.06,5.71-.04,.07-.15,.06-.18-.01Z" />
				</g>
			</g>
			<g>
				<path d="M1057.21,235.35s.08,.05,.09,.09l1.23,4.38c.03,.1-.06,.19-.16,.16l-.73-.18c-.23-.06-.47,.08-.53,.31l-.16,.63c-.06,.23,.08,.47,.31,.52l1.73,.42s.08,.04,.1,.09l1.31,4.35c.03,.1-.06,.19-.16,.17l-.73-.17c-.23-.05-.47,.09-.52,.32l-.15,.64c-.05,.23,.09,.46,.32,.52l1.75,.38s.08,.04,.1,.09l1.38,4.33c.03,.1-.05,.19-.15,.17l-.74-.15c-.23-.05-.46,.1-.52,.33l-.13,.64c-.05,.23,.1,.46,.34,.51l1.75,.35s.08,.04,.1,.09c.49,1.44,.95,2.88,1.46,4.31,.03,.09-.05,.19-.15,.17l-.74-.14c-.24-.04-.46,.11-.51,.34l-.12,.64c-.04,.23,.11,.46,.35,.5l1.23,.23-5.6,1.97,.81-.95c.16-.18,.13-.46-.05-.61l-.5-.43c-.18-.16-.46-.14-.61,.04l-.49,.57c-.07,.08-.19,.05-.22-.04-.52-1.46-.99-2.93-1.49-4.39-.02-.04,0-.09,.03-.13l1.18-1.34c.16-.18,.14-.45-.04-.61l-.49-.44c-.18-.16-.45-.15-.61,.03l-.51,.57c-.07,.08-.19,.05-.22-.05l-1.41-4.42s0-.09,.03-.13l1.2-1.32c.16-.18,.15-.45-.02-.62l-.48-.45c-.17-.16-.45-.16-.61,.02l-.52,.56c-.07,.07-.19,.05-.22-.05l-1.34-4.44s0-.09,.03-.13l1.24-1.3c.17-.17,.16-.45,0-.62l-.47-.46c-.17-.17-.45-.16-.61,0l-.53,.55c-.07,.07-.19,.04-.22-.06l-1.26-4.47s0-.09,.03-.13l.38-.39,5.73-1.56,.52,.14Z" />
				<g>
					<path d="M1051.34,245.62l1.38,4.55c.03,.08-.05,.15-.13,.13-3.29-1.15-6.08-1.36-7.57-1.36-.52-1.72-1.04-3.45-1.53-5.19,1.64,.07,4.51,.44,7.78,1.81,.03,.01,.05,.04,.06,.07Z" />
					<path d="M1055.43,258.48c.5,1.5,1.02,3,1.55,4.49,.03,.08-.05,.15-.12,.13-3.33-1.03-6.15-1.13-7.62-1.08l-1.71-5.12c1.63,.02,4.56,.26,7.85,1.51,.03,.01,.05,.03,.06,.06Z" />
					<path d="M1049.47,239.14l1.3,4.57c.02,.08-.05,.15-.13,.12-3.25-1.21-6.05-1.47-7.54-1.5-.3-1.06-.61-2.12-.89-3.19l4.26-1.16c.92,.27,1.91,.63,2.94,1.08,.03,.01,.05,.04,.06,.07Z" />
					<path d="M1050.4,265.39l-.7-1.98c1.12-.01,2.83,.07,4.86,.51l-4.17,1.47Z" />
					<path d="M1053.33,252.07c.48,1.51,.95,3.02,1.47,4.52,.03,.08-.05,.15-.13,.13-3.33-1.08-6.11-1.24-7.6-1.22-.54-1.72-1.11-3.42-1.62-5.15,1.63,.05,4.53,.35,7.82,1.66,.03,.01,.05,.04,.06,.07Z" />
				</g>
				<g>
					<path d="M1062.32,247.17l-1.34-4.42s0-.06,.01-.09c1.97-2.86,4.3-4.64,5.65-5.53,.45,1.62,.94,3.23,1.42,4.85-1.24,.8-3.5,2.46-5.56,5.22-.05,.07-.15,.05-.18-.03Z" />
					<path d="M1066.47,259.63c-.52-1.45-1.03-2.91-1.51-4.37,0-.03,0-.06,.01-.09,1.89-2.94,4.1-4.79,5.43-5.74l1.61,4.8c-1.22,.85-3.39,2.58-5.36,5.42-.05,.07-.15,.06-.18-.02Z" />
					<path d="M1060.42,240.89l-1.26-4.44s0-.06,.02-.09c.64-.9,1.33-1.68,2-2.37l4.26-1.16c.25,1,.55,1.98,.83,2.97-1.26,.77-3.53,2.39-5.67,5.11-.05,.06-.15,.05-.18-.03Z" />
					<path d="M1072.45,255.54l.66,1.86-4.17,1.47c1.32-1.57,2.62-2.66,3.51-3.32Z" />
					<path d="M1064.34,253.42c-.5-1.46-.96-2.93-1.43-4.4,0-.03,0-.06,.01-.09,1.93-2.9,4.19-4.71,5.54-5.64,.47,1.62,1.01,3.22,1.52,4.82-1.23,.82-3.46,2.53-5.46,5.32-.05,.07-.15,.05-.18-.02Z" />
				</g>
			</g>
			<g>
				<path d="M1051.34,209.69s.08,.05,.08,.1c.29,1.49,.54,2.98,.86,4.46,.02,.1-.07,.18-.17,.15l-.72-.24c-.23-.08-.47,.05-.55,.27l-.21,.62c-.08,.23,.05,.47,.27,.54l1.7,.55s.08,.05,.09,.1c.31,1.48,.61,2.97,.96,4.44,.02,.1-.07,.18-.17,.15l-.72-.22c-.23-.07-.47,.06-.54,.28l-.2,.62c-.07,.23,.06,.47,.29,.54l1.71,.52s.08,.05,.09,.09c.33,1.48,.68,2.95,1.05,4.42,.02,.1-.07,.18-.16,.16l-.73-.21c-.23-.07-.47,.07-.54,.29l-.18,.63c-.07,.23,.07,.47,.29,.53l1.71,.48s.08,.05,.09,.09c.35,1.47,.76,2.93,1.14,4.4,.03,.1-.06,.18-.16,.16l-.73-.19c-.23-.06-.47,.07-.53,.3l-.17,.63c-.06,.23,.07,.47,.3,.53l1.2,.32-5.73,1.56,.88-.89c.17-.17,.17-.45,0-.62l-.46-.47c-.17-.17-.44-.17-.61,0l-.54,.54c-.07,.07-.19,.04-.22-.06-.39-1.5-.81-2.99-1.17-4.49-.01-.05,0-.09,.04-.13l1.29-1.25c.17-.17,.18-.44,.01-.62l-.46-.48c-.17-.17-.44-.18-.61-.01l-.54,.53c-.07,.07-.19,.03-.22-.06-.38-1.5-.74-3-1.07-4.52-.01-.05,0-.09,.04-.13l1.31-1.22c.18-.16,.19-.44,.02-.61l-.45-.49c-.16-.18-.44-.19-.61-.03l-.55,.51c-.07,.07-.19,.03-.22-.07-.35-1.51-.66-3.02-.98-4.54,0-.05,0-.09,.04-.13l1.33-1.19c.18-.16,.19-.43,.03-.61l-.44-.5c-.16-.18-.43-.2-.61-.04l-.56,.5c-.08,.07-.2,.03-.22-.07-.32-1.51-.58-3.04-.88-4.56,0-.05,0-.09,.04-.12l.41-.35,5.84-1.07,.51,.18Z" />
				<g>
					<path d="M1044.64,219.46c.31,1.56,.68,3.1,1.03,4.65,.02,.08-.06,.15-.14,.12-3.19-1.4-5.98-1.83-7.46-1.95l-1.11-5.32c1.63,.21,4.5,.8,7.62,2.44,.03,.01,.05,.04,.05,.07Z" />
					<path d="M1047.73,232.61l1.22,4.6c.02,.08-.06,.15-.13,.12-3.24-1.28-6.03-1.59-7.52-1.65-.48-1.74-.88-3.5-1.32-5.26,1.63,.14,4.52,.61,7.7,2.12,.03,.01,.05,.04,.06,.07Z" />
					<path d="M1043.31,212.83c.28,1.56,.6,3.12,.93,4.67,.02,.08-.07,.15-.14,.11-3.17-1.47-5.94-1.96-7.41-2.12l-.61-3.27,4.34-.8c.9,.35,1.86,.78,2.84,1.32,.03,.02,.05,.04,.05,.07Z" />
					<path d="M1042.21,239.14l-.54-2.04c1.11,.07,2.82,.29,4.8,.88l-4.26,1.16Z" />
					<path d="M1046.12,226.05l1.13,4.62c.02,.08-.06,.15-.14,.12-3.21-1.34-6.01-1.71-7.49-1.8l-1.22-5.29c1.63,.17,4.51,.71,7.66,2.27,.03,.01,.05,.04,.06,.07Z" />
				</g>
				<g>
					<path d="M1055.48,221.86c-.33-1.5-.7-3-1-4.5,0-.03,0-.06,.02-.08,2.23-2.7,4.65-4.27,6.07-5.05l1.02,4.92c-1.3,.7-3.66,2.17-5.94,4.75-.05,.06-.16,.04-.17-.04Z" />
					<path d="M1058.65,234.58l-1.18-4.46s0-.06,.02-.09c2.12-2.79,4.48-4.46,5.87-5.3,.41,1.63,.79,3.27,1.23,4.89-1.27,.75-3.57,2.32-5.76,5-.05,.06-.15,.04-.18-.03Z" />
					<path d="M1054.09,215.47c-.32-1.5-.63-3.01-.9-4.52,0-.03,0-.06,.02-.08,.72-.84,1.46-1.56,2.18-2.19l4.34-.8,.57,3.02c-1.33,.67-3.72,2.09-6.05,4.62-.06,.06-.16,.03-.17-.04Z" />
					<path d="M1064.94,230.94l.5,1.9-4.26,1.16c1.44-1.47,2.82-2.46,3.76-3.05Z" />
					<path d="M1057,228.24l-1.09-4.48s0-.06,.02-.09c2.17-2.74,4.56-4.37,5.97-5.18l1.13,4.91c-1.29,.72-3.61,2.24-5.85,4.88-.05,.06-.16,.04-.18-.04Z" />
				</g>
			</g>
			<g>
				<path d="M1047.78,183.66s.07,.06,.07,.11l.44,4.51c0,.1-.09,.17-.18,.13l-.69-.3c-.22-.1-.47,0-.57,.22l-.27,.6c-.1,.22,0,.47,.22,.57l1.63,.71s.07,.06,.08,.1l.55,4.5c.01,.1-.09,.17-.18,.14l-.7-.29c-.22-.09-.47,.01-.57,.23l-.26,.6c-.09,.22,0,.47,.23,.56l1.66,.67s.07,.06,.08,.1l.65,4.49c.01,.1-.08,.18-.18,.14l-.7-.27c-.22-.09-.47,.02-.56,.25l-.24,.61c-.08,.22,.03,.47,.25,.56l1.67,.63s.08,.05,.08,.1l.75,4.47c.02,.1-.08,.18-.17,.14l-.71-.25c-.22-.08-.47,.03-.55,.26l-.22,.61c-.08,.22,.04,.47,.26,.55l1.18,.41-5.84,1.07,.95-.81c.18-.16,.2-.43,.05-.61l-.43-.5c-.16-.18-.43-.21-.61-.05l-.57,.49c-.08,.07-.2,.02-.21-.08l-.77-4.58s0-.09,.05-.12l1.38-1.13c.19-.15,.21-.42,.06-.61l-.41-.52c-.15-.19-.42-.22-.61-.07l-.59,.48c-.08,.06-.2,.02-.21-.08l-.67-4.6s.01-.09,.05-.12l1.41-1.1c.19-.15,.23-.42,.08-.61l-.4-.53c-.14-.19-.42-.23-.61-.08l-.6,.46c-.08,.06-.2,.01-.21-.09l-.57-4.62s.01-.09,.05-.12l1.45-1.07c.19-.14,.24-.41,.1-.61l-.38-.54c-.14-.2-.41-.24-.6-.1l-.61,.45c-.08,.06-.2,0-.21-.09l-.45-4.63s.02-.09,.06-.12l.45-.31,5.92-.5,.49,.23Z" />
				<g>
					<path d="M1040.2,192.75l.59,4.74c.01,.08-.07,.14-.15,.1-3.05-1.69-5.76-2.38-7.23-2.64-.23-1.81-.45-3.62-.61-5.44,1.61,.36,4.36,1.24,7.35,3.16,.03,.02,.04,.05,.05,.08Z" />
					<path d="M1042.12,206.16c.26,1.57,.52,3.14,.82,4.7,.02,.08-.07,.15-.14,.11-3.15-1.54-5.89-2.1-7.36-2.28-.29-1.79-.61-3.58-.88-5.38,1.6,.29,4.45,1.01,7.51,2.78,.03,.02,.05,.04,.05,.07Z" />
					<path d="M1039.49,186.01l.47,4.76c0,.08-.08,.14-.15,.1-2.99-1.77-5.7-2.53-7.16-2.83-.1-1.11-.24-2.22-.31-3.33l4.4-.37c.86,.44,1.76,.97,2.7,1.6,.03,.02,.04,.05,.05,.08Z" />
					<path d="M1036.07,212.23l-.39-2.08c1.11,.17,2.79,.53,4.73,1.28l-4.34,.8Z" />
					<path d="M1041.08,199.47c.24,1.57,.43,3.15,.71,4.72,.01,.08-.07,.14-.15,.11-3.11-1.61-5.82-2.24-7.3-2.46-.25-1.8-.54-3.6-.75-5.41,1.6,.33,4.4,1.13,7.44,2.97,.03,.02,.04,.04,.05,.07Z" />
				</g>
				<g>
					<path d="M1050.76,196.14l-.57-4.56s0-.06,.03-.08c2.43-2.47,5.04-3.8,6.52-4.43,.15,1.65,.35,3.31,.56,4.96-1.36,.57-3.86,1.81-6.37,4.18-.06,.06-.16,.02-.17-.06Z" />
					<path d="M1052.83,209.04c-.3-1.51-.54-3.02-.79-4.54,0-.03,0-.06,.02-.08,2.33-2.59,4.81-4.04,6.29-4.76,.24,1.65,.54,3.3,.81,4.94-1.33,.64-3.78,2-6.15,4.48-.06,.06-.16,.03-.17-.05Z" />
					<path d="M1049.96,189.66l-.46-4.57s0-.06,.03-.08c.79-.76,1.6-1.41,2.39-1.96l4.4-.37c.06,1.01,.19,2.02,.28,3.03-1.38,.54-3.89,1.71-6.48,4.01-.06,.05-.16,.02-.17-.06Z" />
					<path d="M1059.38,205.96l.36,1.92-4.34,.8c1.55-1.34,3-2.21,3.99-2.72Z" />
					<path d="M1051.72,202.6c-.27-1.51-.45-3.03-.68-4.55,0-.03,0-.06,.03-.08,2.38-2.53,4.92-3.93,6.4-4.6,.18,1.65,.46,3.3,.69,4.95-1.34,.6-3.84,1.91-6.26,4.34-.06,.06-.16,.03-.17-.05Z" />
				</g>
			</g>
			<g>
				<path d="M1047.06,157.48s.06,.07,.06,.11c-.03,1.51-.12,3.01-.1,4.51,0,.1-.11,.16-.2,.11l-.65-.38c-.21-.12-.47-.05-.59,.15l-.33,.56c-.12,.2-.05,.47,.16,.59l1.55,.89s.07,.07,.07,.11c0,1.51-.01,3.01,.04,4.52,0,.1-.1,.16-.19,.12l-.66-.36c-.21-.11-.47-.04-.59,.17l-.31,.57c-.11,.21-.04,.47,.17,.58l1.57,.84s.07,.06,.07,.11c.02,1.51,.1,3.01,.18,4.52,0,.1-.1,.17-.19,.12l-.67-.34c-.21-.11-.47-.03-.58,.19l-.3,.58c-.11,.21-.02,.47,.19,.58l1.59,.8s.07,.06,.07,.11c.06,1.51,.21,3.01,.31,4.52,0,.1-.1,.17-.19,.13l-.68-.32c-.22-.1-.47-.01-.58,.2l-.28,.59c-.1,.21-.01,.47,.2,.57l1.12,.53-5.92,.5,1.03-.72c.2-.14,.25-.41,.11-.61l-.37-.55c-.14-.2-.4-.25-.6-.11l-.62,.43c-.08,.06-.2,0-.21-.1-.11-1.55-.26-3.09-.32-4.65,0-.05,.02-.09,.06-.12l1.5-.99c.2-.13,.26-.4,.13-.6l-.36-.56c-.13-.2-.4-.26-.6-.13l-.63,.41c-.08,.06-.2,0-.2-.11-.08-1.55-.16-3.1-.19-4.66,0-.05,.02-.09,.06-.12l1.52-.94c.2-.13,.27-.39,.14-.6l-.34-.57c-.12-.21-.39-.27-.6-.15l-.65,.4c-.09,.05-.2,0-.2-.11-.06-1.55-.05-3.11-.04-4.67,0-.05,.02-.09,.07-.12l1.55-.9c.21-.12,.28-.39,.16-.6l-.33-.58c-.12-.21-.38-.29-.59-.17l-.66,.38c-.09,.05-.2-.01-.2-.12-.02-1.56,.07-3.11,.11-4.67,0-.05,.03-.09,.07-.11l.48-.26,5.94,.2,.46,.28Z" />
				<g>
					<path d="M1038.48,165.65c-.02,1.6,.06,3.19,.09,4.79,0,.08-.09,.13-.16,.09-2.85-2.02-5.5-3.01-6.93-3.43v-5.52c1.56,.55,4.24,1.75,6.97,3.99,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1038.96,179.24l.36,4.77c0,.08-.08,.14-.15,.1-2.95-1.85-5.64-2.69-7.09-3.02-.16-1.82-.22-3.66-.32-5.49,1.58,.45,4.32,1.48,7.16,3.56,.03,.02,.04,.05,.04,.08Z" />
					<path d="M1038.55,158.84c-.06,1.6-.07,3.2-.06,4.8,0,.08-.09,.13-.16,.08-2.8-2.1-5.42-3.18-6.82-3.65l.1-3.38,4.41,.15c.81,.54,1.65,1.18,2.49,1.92,.02,.02,.04,.05,.04,.08Z" />
					<path d="M1032.34,184.7l-.16-2.13c1.08,.28,2.72,.81,4.56,1.76l-4.4,.37Z" />
					<path d="M1038.62,172.45l.23,4.78c0,.08-.09,.14-.16,.09-2.9-1.93-5.57-2.84-7.02-3.22-.09-1.83-.11-3.67-.16-5.51,1.56,.5,4.28,1.61,7.06,3.78,.02,.02,.04,.05,.04,.08Z" />
				</g>
				<g>
					<path d="M1048.62,170.17c-.02-1.53-.11-3.05-.08-4.58,0-.03,.01-.06,.04-.08,2.73-2.17,5.42-3.19,6.97-3.65v4.94c-1.42,.41-4.02,1.37-6.77,3.44-.07,.05-.16,0-.16-.07Z" />
					<path d="M1049.34,183.17l-.35-4.58s0-.06,.03-.08c2.6-2.33,5.24-3.51,6.77-4.07,.09,1.65,.14,3.3,.29,4.96-1.39,.5-3.94,1.6-6.57,3.83-.06,.05-.16,.02-.17-.06Z" />
					<path d="M1048.54,163.66c0-1.53,0-3.05,.06-4.57,0-.03,.02-.06,.04-.08,.89-.66,1.76-1.21,2.6-1.65l4.41,.15-.09,3.01c-1.45,.37-4.06,1.24-6.87,3.23-.07,.05-.16,0-.16-.08Z" />
					<path d="M1056.18,180.75l.15,1.93-4.4,.37c1.69-1.18,3.22-1.89,4.26-2.3Z" />
					<path d="M1048.89,176.67l-.22-4.58s.01-.06,.04-.08c2.67-2.26,5.33-3.36,6.87-3.86,.05,1.65,.06,3.3,.15,4.95-1.4,.46-3.97,1.49-6.67,3.64-.06,.05-.16,.01-.16-.07Z" />
				</g>
			</g>
			<g>
				<path d="M1049.72,131.52s.05,.08,.04,.12c-.28,1.47-.48,2.96-.72,4.44-.02,.1-.13,.14-.21,.08l-.59-.46c-.19-.15-.46-.12-.61,.07l-.41,.51c-.15,.19-.12,.45,.07,.6l1.4,1.09s.05,.07,.05,.12l-.54,4.47c-.01,.1-.13,.15-.21,.09l-.61-.44c-.19-.14-.46-.1-.61,.09l-.4,.52c-.14,.19-.1,.46,.09,.6l1.46,1.04s.06,.07,.06,.12l-.4,4.49c0,.1-.12,.15-.21,.1l-.62-.42c-.2-.13-.47-.08-.6,.11l-.38,.54c-.13,.2-.08,.46,.12,.6l1.49,.99s.06,.07,.06,.12l-.26,4.5c0,.1-.11,.16-.2,.1l-.64-.4c-.2-.13-.47-.07-.6,.13l-.35,.55c-.13,.2-.06,.47,.14,.59l1.06,.65-5.94-.2,1.1-.59c.21-.11,.29-.38,.18-.59l-.31-.59c-.11-.21-.38-.3-.59-.19l-.67,.35c-.09,.05-.2-.02-.19-.12l.26-4.67s.03-.09,.07-.11l1.6-.8c.21-.11,.3-.37,.19-.58l-.28-.6c-.1-.22-.36-.31-.58-.21l-.69,.33c-.09,.04-.19-.03-.19-.13l.42-4.66s.03-.09,.08-.11l1.63-.74c.22-.1,.32-.36,.23-.58l-.26-.61c-.09-.22-.35-.32-.57-.23l-.7,.31c-.09,.04-.19-.03-.18-.14l.57-4.65s.04-.09,.08-.11l1.67-.68c.22-.09,.33-.34,.25-.57l-.24-.62c-.09-.22-.34-.34-.56-.25l-.71,.28c-.09,.04-.19-.04-.18-.14,.25-1.54,.45-3.09,.75-4.63,0-.05,.04-.09,.09-.1l.51-.19,5.85,1.05,.41,.34Z" />
				<g>
					<path d="M1040.1,138.42l-.57,4.78c0,.08-.11,.12-.17,.07-2.56-2.38-5.02-3.73-6.38-4.34l.34-2.77,.42-2.76c1.47,.77,3.88,2.33,6.33,4.94,.02,.02,.03,.05,.03,.09Z" />
					<path d="M1038.83,152.02c-.1,1.6-.19,3.2-.23,4.8,0,.08-.1,.13-.16,.08-2.75-2.19-5.29-3.36-6.69-3.87,.12-1.85,.2-3.69,.35-5.54,1.49,.65,4.1,2.03,6.69,4.46,.02,.02,.04,.05,.03,.08Z" />
					<path d="M1041.1,131.65l-.74,4.77c-.01,.08-.11,.12-.17,.06-2.45-2.47-4.88-3.92-6.21-4.59,.18-1.13,.34-2.25,.57-3.37l4.35,.78c.72,.66,1.44,1.4,2.19,2.26,.02,.02,.03,.06,.03,.09Z" />
					<path d="M1031.6,156.69l.07-2.15c1.05,.41,2.61,1.14,4.35,2.3l-4.41-.15Z" />
					<path d="M1039.34,145.22c-.13,1.6-.32,3.19-.39,4.79,0,.08-.1,.13-.17,.07-2.68-2.29-5.15-3.54-6.54-4.11,.18-1.85,.3-3.7,.55-5.54,1.47,.71,3.99,2.18,6.52,4.7,.02,.02,.03,.05,.03,.09Z" />
				</g>
				<g>
					<path d="M1049.53,144.24l.54-4.52s.02-.06,.05-.07c2.94-1.78,5.83-2.39,7.41-2.62l-.37,2.41-.29,2.42c-1.45,.22-4.19,.8-7.18,2.48-.07,.04-.16-.01-.15-.09Z" />
					<path d="M1048.66,157.17c.03-1.52,.12-3.04,.21-4.56,0-.03,.02-.06,.04-.08,2.85-1.99,5.59-2.82,7.19-3.17-.14,1.63-.2,3.27-.31,4.9-1.44,.32-4.13,1.1-6.98,2.99-.07,.05-.16,0-.16-.08Z" />
					<path d="M1050.31,137.82l.7-4.5s.02-.06,.05-.07c.95-.53,1.92-.93,2.81-1.25l4.35,.78c-.2,.97-.34,1.95-.49,2.93-1.46,.16-4.19,.64-7.27,2.21-.07,.04-.16-.02-.15-.1Z" />
					<path d="M1055.71,155.6l-.06,1.91-4.41-.15c1.8-.96,3.4-1.49,4.47-1.76Z" />
					<path d="M1048.99,150.7c.07-1.52,.25-3.03,.37-4.55,0-.03,.02-.06,.04-.07,2.89-1.89,5.7-2.61,7.3-2.9-.22,1.61-.33,3.24-.48,4.87-1.44,.27-4.19,.95-7.08,2.75-.07,.04-.16,0-.16-.09Z" />
				</g>
			</g>
			<g>
				<path d="M1056.41,106.4s.04,.08,.02,.13c-.49,1.41-1.01,2.81-1.48,4.23-.03,.09-.15,.12-.22,.04l-.51-.56c-.16-.18-.43-.19-.61-.04l-.48,.43c-.17,.16-.18,.43-.02,.61l1.22,1.3s.04,.08,.03,.13c-.43,1.43-.92,2.85-1.28,4.3-.03,.1-.15,.13-.22,.05l-.54-.53c-.17-.17-.44-.17-.61,0l-.46,.45c-.17,.17-.17,.44,0,.61l1.27,1.25s.05,.08,.03,.12l-.58,2.17-.51,2.19c-.02,.1-.14,.13-.22,.06l-.56-.51c-.18-.16-.45-.16-.61,.02l-.44,.47c-.16,.17-.15,.44,.02,.61l1.32,1.19s.05,.08,.04,.12c-.36,1.45-.61,2.93-.9,4.4-.02,.1-.14,.14-.21,.07l-.57-.49c-.18-.15-.45-.14-.61,.04l-.43,.49c-.16,.18-.14,.45,.05,.6l.95,.8-5.85-1.05,1.18-.43c.23-.08,.35-.33,.27-.56l-.22-.63c-.08-.23-.32-.35-.55-.27l-.72,.25c-.1,.03-.19-.05-.17-.15,.3-1.54,.57-3.07,.94-4.6,.01-.05,.04-.08,.09-.1l1.71-.55c.23-.07,.36-.32,.29-.55l-.19-.64c-.07-.23-.31-.36-.54-.29l-.72,.23c-.1,.03-.19-.06-.17-.16l.53-2.29,.61-2.27s.05-.08,.09-.1l1.73-.48c.23-.06,.37-.3,.31-.54l-.17-.64c-.06-.23-.3-.38-.53-.32l-.73,.2c-.1,.03-.19-.07-.16-.16,.38-1.52,.89-3,1.34-4.5,.01-.05,.05-.08,.1-.09l1.74-.4c.23-.05,.38-.29,.33-.52l-.14-.65c-.05-.24-.28-.39-.52-.34l-.74,.16c-.1,.02-.18-.07-.15-.17,.49-1.49,1.03-2.96,1.55-4.44,.02-.05,.05-.08,.1-.09l.53-.1,5.58,2.04,.35,.41Z" />
				<g>
					<path d="M1045.77,111.61c-.5,1.54-.91,3.1-1.32,4.66-.02,.08-.13,.1-.18,.04-2.15-2.77-4.42-4.52-5.63-5.34l1.67-5.4c1.31,1,3.51,2.98,5.45,5.96,.02,.03,.02,.06,.01,.09Z" />
					<path d="M1042.36,124.91l-.91,4.74c-.02,.08-.12,.11-.17,.05-2.36-2.57-4.73-4.12-6.05-4.84,.33-1.84,.81-3.66,1.21-5.49,1.39,.88,3.74,2.65,5.91,5.44,.02,.02,.03,.06,.02,.09Z" />
					<path d="M1047.9,105.07c-.54,1.52-1.08,3.05-1.53,4.6-.02,.08-.13,.1-.18,.03-2.06-2.87-4.18-4.71-5.38-5.59l1.15-3.27,4.15,1.52c.6,.77,1.2,1.65,1.78,2.62,.02,.03,.02,.06,0,.09Z" />
					<path d="M1034.54,128.52l.41-2.14c.97,.56,2.41,1.52,3.94,2.93l-4.35-.78Z" />
					<path d="M1043.93,118.23c-.43,1.56-.74,3.14-1.11,4.71-.02,.08-.12,.11-.18,.05-2.26-2.67-4.58-4.32-5.86-5.09,.46-1.82,.96-3.63,1.44-5.45,1.35,.94,3.63,2.82,5.69,5.7,.02,.03,.02,.06,.02,.09Z" />
				</g>
				<g>
					<path d="M1054.17,118.87c.39-1.46,.77-2.92,1.24-4.36,0-.03,.03-.05,.06-.06,3.24-1.25,6.1-1.38,7.71-1.34l-1.42,4.59c-1.5-.03-4.23,.1-7.45,1.29-.08,.03-.16-.04-.14-.12Z" />
					<path d="M1051.35,131.45l.86-4.46s.03-.05,.05-.07c3.15-1.52,5.99-1.91,7.6-2.01-.34,1.58-.76,3.13-1.04,4.73-1.47,.1-4.21,.47-7.33,1.92-.07,.03-.16-.03-.14-.1Z" />
					<path d="M1055.96,112.69c.42-1.45,.92-2.87,1.43-4.29,0-.03,.03-.05,.06-.06,1.04-.35,2.04-.59,2.97-.75l4.15,1.52-.97,2.76c-1.48-.1-4.27-.11-7.51,.94-.08,.02-.15-.05-.13-.12Z" />
					<path d="M1058.58,130.93l-.35,1.85-4.35-.78c1.93-.68,3.6-.96,4.7-1.07Z" />
					<path d="M1052.63,125.12c.35-1.47,.64-2.96,1.04-4.42,0-.03,.03-.05,.06-.07,3.2-1.39,6.05-1.65,7.66-1.68l-1.23,4.66c-1.47,.04-4.21,.29-7.39,1.61-.08,.03-.16-.03-.14-.11Z" />
				</g>
			</g>
			<g>
				<path d="M1067.55,83.02s.02,.09,0,.13c-.8,1.25-1.5,2.57-2.23,3.87-.05,.09-.17,.09-.23,0l-.39-.64c-.12-.2-.39-.27-.59-.15l-.56,.33c-.2,.12-.27,.38-.15,.59l.94,1.5s.03,.09,0,.13c-.73,1.3-1.35,2.66-2.04,3.98-.04,.09-.17,.09-.23,.01l-.42-.62c-.13-.2-.4-.25-.6-.12l-.55,.36c-.2,.13-.25,.4-.12,.59l1,1.45s.03,.09,0,.13l-1.83,4.08c-.04,.09-.17,.1-.23,.02l-.45-.6c-.14-.19-.41-.23-.61-.09l-.53,.38c-.19,.14-.24,.41-.09,.59l1.09,1.41s.04,.09,.02,.13l-1.66,4.16c-.04,.09-.16,.11-.23,.03l-.48-.58c-.15-.18-.42-.21-.61-.07l-.51,.41c-.18,.15-.2,.42-.05,.61l.81,.95-5.58-2.04,1.22-.22c.23-.04,.39-.27,.35-.51l-.09-.65c-.03-.24-.25-.4-.49-.36l-.76,.13c-.1,.02-.18-.08-.14-.18l1.74-4.37s.06-.08,.1-.08l1.77-.24c.24-.03,.42-.25,.39-.48l-.07-.66c-.02-.24-.24-.41-.48-.38l-.76,.09c-.1,.01-.18-.09-.13-.18l1.93-4.3s.06-.07,.11-.08l1.8-.14c.24-.02,.42-.23,.41-.47l-.04-.66c-.01-.24-.22-.42-.46-.41l-.76,.06c-.1,0-.17-.1-.12-.19,.72-1.4,1.37-2.83,2.14-4.2,.02-.04,.07-.07,.11-.07l1.8-.06c.24,0,.43-.21,.43-.45v-.66c0-.24-.21-.43-.45-.43l-.76,.02c-.1,0-.17-.11-.12-.2,.77-1.37,1.51-2.76,2.35-4.08,.03-.04,.07-.07,.12-.07h.54s5.08,3.09,5.08,3.09l.26,.47Z" />
				<g>
					<path d="M1056.1,86.1l-2.18,4.35c-.04,.08-.15,.08-.18,0-1.57-3.11-3.44-5.25-4.5-6.3,.82-1.71,1.77-3.36,2.69-5.02,1.1,1.24,2.83,3.59,4.17,6.88,.01,.03,.01,.06,0,.09Z" />
					<path d="M1050.33,98.63c-.58,1.51-1.24,2.99-1.75,4.52-.03,.08-.13,.09-.18,.02-1.94-2.97-3.92-4.89-5.1-5.83,.71-1.75,1.37-3.53,2.17-5.25,1.2,1.12,3.15,3.28,4.85,6.44,.01,.03,.02,.06,0,.09Z" />
					<path d="M1059.44,80.06l-2.37,4.25c-.04,.07-.15,.07-.18,0-1.43-3.19-3.19-5.43-4.2-6.53,.55-1.02,1.17-2.01,1.78-3l3.78,2.29c.43,.87,.84,1.85,1.21,2.91,.01,.03,0,.06-.01,.09Z" />
					<path d="M1041.97,100.84c.24-.69,.48-1.39,.77-2.07,.85,.72,2.08,1.92,3.38,3.58l-4.15-1.52Z" />
					<path d="M1053.07,92.3c-.66,1.48-1.34,2.95-1.98,4.44-.03,.08-.14,.09-.18,.01-1.74-3.04-3.68-5.07-4.8-6.07l1.17-2.59,1.27-2.55c1.17,1.19,2.96,3.42,4.52,6.67,.01,.03,.01,.06,0,.09Z" />
				</g>
				<g>
					<path d="M1062.99,94.79l2.02-4.03s.04-.05,.07-.05c3.41-.61,6.28-.17,7.85,.18-.77,1.39-1.56,2.76-2.25,4.2-1.44-.3-4.17-.7-7.58-.15-.08,.01-.15-.07-.11-.14Z" />
					<path d="M1058.03,106.61c.47-1.43,1.09-2.81,1.63-4.21,.01-.03,.03-.05,.06-.06,3.3-.96,6.2-.8,7.81-.6-.68,1.44-1.23,2.94-1.83,4.42-1.45-.15-4.28-.32-7.55,.59-.08,.02-.15-.05-.13-.13Z" />
					<path d="M1065.91,89.09l2.2-3.94s.04-.04,.07-.05c1.1-.14,2.13-.17,3.08-.14l3.78,2.29c-.51,.82-1.02,1.64-1.48,2.5-1.42-.37-4.12-.91-7.54-.52-.08,0-.14-.07-.1-.14Z" />
					<path d="M1065.22,107.36c-.24,.57-.44,1.16-.65,1.75l-4.15-1.52c1.99-.35,3.68-.32,4.79-.23Z" />
					<path d="M1060.36,100.64l1.84-4.13s.04-.05,.07-.05c3.33-.81,6.26-.48,7.84-.21l-1.06,2.13-.97,2.18c-1.45-.23-4.22-.51-7.58,.22-.08,.02-.15-.06-.12-.13Z" />
				</g>
			</g>
			<g>
				<path d="M1083.11,62.34s0,.09-.03,.13l-1.52,1.65-1.46,1.7c-.06,.07-.19,.05-.22-.04l-.26-.71c-.08-.22-.33-.35-.55-.27l-.61,.21c-.22,.08-.34,.32-.26,.55l.62,1.66s0,.09-.02,.13c-.98,1.12-1.86,2.33-2.8,3.49-.06,.08-.19,.06-.22-.03l-.29-.69c-.09-.22-.34-.33-.56-.24l-.61,.24c-.22,.09-.33,.34-.24,.55l.7,1.63s.01,.09-.01,.13l-2.63,3.61c-.06,.08-.18,.07-.22-.02l-.32-.68c-.1-.21-.36-.31-.58-.21l-.59,.28c-.21,.1-.3,.36-.19,.57l.79,1.6s.02,.09,0,.13c-.82,1.24-1.68,2.46-2.44,3.74-.05,.08-.18,.08-.22-.01l-.36-.66c-.11-.21-.38-.29-.58-.18l-.57,.3c-.21,.11-.29,.37-.17,.58l.6,1.08-5.08-3.08,1.25,.02c.24,0,.44-.19,.45-.43l.02-.67c0-.24-.18-.45-.42-.45l-.76-.02c-.1,0-.16-.12-.11-.2,.8-1.36,1.71-2.64,2.57-3.95,.03-.04,.07-.06,.12-.06l1.79,.12c.24,.02,.45-.17,.46-.41l.06-.66c.03-.24-.15-.45-.39-.47l-.76-.06c-.1,0-.15-.12-.1-.21l2.78-3.81s.07-.06,.12-.05l1.78,.21c.24,.03,.46-.14,.5-.37l.1-.65c.04-.24-.13-.46-.37-.49l-.76-.1c-.1-.01-.15-.13-.08-.21,.99-1.22,1.92-2.49,2.95-3.68,.03-.04,.08-.05,.13-.05l1.78,.3c.24,.04,.46-.12,.51-.35l.12-.65c.05-.24-.11-.47-.34-.51l-.75-.13c-.1-.02-.14-.14-.07-.22l1.54-1.79,1.59-1.73s.08-.05,.13-.04l.53,.11,4.36,4.03,.17,.51Z" />
				<g>
					<path d="M1071.27,63.06l-3.01,3.82c-.05,.07-.15,.05-.18-.03-.92-3.37-2.31-5.83-3.14-7.08,1.16-1.51,2.41-2.94,3.64-4.38,.82,1.43,2.06,4.09,2.71,7.58,0,.03,0,.06-.02,.09Z" />
					<path d="M1063.11,74.2c-.93,1.33-1.75,2.73-2.6,4.11-.04,.07-.15,.07-.18-.01-1.27-3.27-2.93-5.61-3.87-6.75,1.06-1.58,2.11-3.17,3.19-4.74,.94,1.33,2.49,3.89,3.48,7.3,0,.03,0,.06-.01,.09Z" />
					<path d="M1075.77,57.83c-1.11,1.18-2.12,2.45-3.18,3.67-.06,.07-.16,.04-.18-.04-.77-3.42-2.04-5.96-2.82-7.24,.77-.87,1.56-1.71,2.34-2.57l3.24,3c.25,.94,.47,1.98,.62,3.1,0,.03,0,.06-.03,.09Z" />
					<path d="M1054.46,74.77l1.16-1.9c.7,.88,1.69,2.31,2.61,4.19l-3.78-2.29Z" />
					<path d="M1067.06,68.53c-.95,1.31-1.93,2.61-2.81,3.97-.05,.07-.15,.06-.18-.02-1.15-3.36-2.61-5.72-3.51-6.92,1.12-1.54,2.22-3.09,3.42-4.56,.89,1.39,2.23,3.96,3.1,7.45,0,.03,0,.06-.02,.09Z" />
				</g>
				<g>
					<path d="M1076.28,72.96l2.79-3.54s.05-.04,.08-.04c3.47,.09,6.18,1.09,7.65,1.75-1.03,1.21-2.08,2.4-3.04,3.66-1.35-.58-3.95-1.53-7.39-1.67-.08,0-.13-.1-.08-.16Z" />
					<path d="M1069.1,83.54c.79-1.28,1.55-2.57,2.41-3.81,.02-.02,.04-.04,.07-.04,3.46-.26,6.21,.44,7.75,.96l-2.65,3.94c-1.41-.46-4.06-1.12-7.49-.9-.08,0-.14-.08-.1-.15Z" />
					<path d="M1080.29,67.96c.99-1.13,1.92-2.31,2.96-3.41,.02-.02,.05-.03,.08-.03,1.1,.08,2.12,.25,3.04,.47l3.24,3c-.65,.72-1.33,1.42-1.97,2.15-1.32-.65-3.86-1.71-7.28-2.01-.08,0-.12-.1-.07-.16Z" />
					<path d="M1076,85.69l-.96,1.57-3.78-2.29c2.04,.07,3.68,.42,4.74,.72Z" />
					<path d="M1072.56,78.16c.81-1.26,1.73-2.46,2.61-3.68,.02-.02,.05-.04,.08-.04,3.43-.11,6.21,.77,7.71,1.36-1.01,1.23-1.92,2.53-2.85,3.8-1.37-.51-4.06-1.36-7.45-1.29-.08,0-.13-.09-.09-.15Z" />
				</g>
			</g>
			<g>
				<path d="M1102.4,45.01s-.01,.09-.05,.12c-1.18,.91-2.41,1.77-3.55,2.73-.08,.06-.19,.02-.21-.08l-.12-.75c-.04-.24-.26-.4-.49-.37l-.64,.1c-.23,.04-.39,.26-.35,.49l.3,1.75s0,.09-.04,.12c-1.17,.93-2.3,1.91-3.42,2.89-.07,.06-.19,.02-.21-.08l-.15-.74c-.05-.23-.27-.39-.51-.34l-.64,.12c-.23,.04-.38,.27-.34,.5l.37,1.73s0,.09-.04,.12c-1.11,1-2.18,2.04-3.28,3.05-.07,.07-.19,.03-.22-.07l-.18-.73c-.06-.23-.29-.37-.52-.32l-.64,.15c-.23,.05-.38,.28-.31,.52l.47,1.73s0,.09-.03,.13c-1.04,1.07-2.12,2.11-3.14,3.19-.07,.07-.19,.04-.22-.05l-.23-.72c-.07-.23-.31-.36-.53-.29l-.62,.19c-.23,.07-.35,.31-.28,.54l.38,1.19-4.36-4.03,1.22,.27c.23,.05,.47-.1,.52-.33l.15-.65c.06-.24-.09-.47-.32-.53l-.74-.17c-.1-.02-.13-.14-.06-.22,1.07-1.14,2.2-2.24,3.3-3.36,.03-.03,.08-.05,.13-.04l1.72,.46c.23,.06,.48-.07,.54-.3l.19-.63c.07-.23-.06-.47-.29-.54l-.73-.21c-.1-.03-.13-.15-.05-.22,1.15-1.07,2.28-2.16,3.44-3.21,.04-.03,.09-.04,.13-.03l1.72,.56c.23,.08,.48-.05,.56-.27l.22-.63c.08-.23-.04-.48-.27-.55l-.72-.24c-.1-.03-.12-.16-.04-.22,1.18-1.04,2.36-2.07,3.59-3.04,.04-.03,.09-.04,.13-.02l1.68,.63c.22,.08,.48-.03,.56-.25l.24-.62c.09-.23-.02-.48-.24-.57l-.71-.27c-.09-.04-.11-.16-.03-.23,1.2-1.01,2.49-1.91,3.73-2.86,.04-.03,.09-.04,.13-.02l.5,.21,3.54,4.77,.07,.53Z" />
				<g>
					<path d="M1090.64,43.49c-1.24,1.04-2.44,2.12-3.67,3.18-.06,.06-.16,.02-.17-.07-.27-3.48-1.17-6.17-1.75-7.55,1.43-1.24,2.92-2.4,4.38-3.59,.54,1.56,1.28,4.42,1.25,7.96,0,.03-.01,.06-.04,.08Z" />
					<path d="M1080.51,52.82l-1.71,1.73-1.65,1.78c-.06,.06-.16,.03-.17-.05-.63-3.48-1.76-6.06-2.46-7.36,1.35-1.33,2.64-2.73,4.04-4,.67,1.49,1.65,4.25,1.99,7.82,0,.03,0,.06-.03,.09Z" />
					<path d="M1096.03,39.21c-1.3,.96-2.56,1.97-3.81,3-.07,.05-.16,.01-.17-.07-.12-3.51-.91-6.25-1.41-7.63l2.76-2.08,2.63,3.55c.07,.97,.1,2.04,.04,3.16,0,.03-.02,.06-.04,.08Z" />
					<path d="M1071.93,51.65l1.5-1.63c.51,1,1.21,2.6,1.74,4.63l-3.24-3Z" />
					<path d="M1085.47,48.04c-1.17,1.11-2.38,2.2-3.52,3.34-.06,.06-.16,.03-.17-.06-.45-3.49-1.44-6.1-2.09-7.46l2.08-1.93,2.14-1.86c.62,1.54,1.45,4.32,1.61,7.89,0,.03-.01,.06-.03,.08Z" />
				</g>
				<g>
					<path d="M1093.67,54.17c1.14-.98,2.26-1.99,3.42-2.96,.02-.02,.05-.03,.08-.02,3.4,.75,5.86,2.22,7.19,3.14-1.23,1.02-2.5,1.99-3.71,3.04-1.21-.83-3.58-2.24-6.93-3.03-.08-.02-.11-.12-.05-.17Z" />
					<path d="M1084.53,63.16l1.53-1.66,1.59-1.6s.05-.03,.08-.03c3.42,.4,6.02,1.65,7.44,2.46-1.19,1.07-2.26,2.25-3.4,3.36-1.3-.72-3.8-1.92-7.18-2.37-.08-.01-.12-.11-.07-.17Z" />
					<path d="M1098.57,50.02c1.17-.96,2.34-1.91,3.56-2.8,.02-.02,.06-.02,.08-.02,1.06,.28,2.03,.63,2.9,1.01l2.63,3.55-2.34,1.77c-1.19-.9-3.49-2.41-6.78-3.34-.08-.02-.1-.12-.04-.17Z" />
					<path d="M1090.86,66.62l-1.26,1.37-3.24-3c1.98,.46,3.52,1.13,4.5,1.63Z" />
					<path d="M1088.98,58.55c1.07-1.06,2.19-2.07,3.28-3.11,.02-.02,.05-.03,.08-.02,3.4,.56,5.97,1.96,7.32,2.81l-1.81,1.57-1.75,1.63c-1.24-.77-3.71-2.1-7.07-2.72-.08-.01-.11-.11-.06-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1124.53,31.4s-.03,.09-.07,.11l-3.99,2.06c-.09,.05-.19-.02-.19-.12l.02-.75c0-.24-.18-.44-.42-.45h-.65c-.24-.02-.43,.17-.43,.41l-.02,1.78s-.02,.09-.06,.11c-1.3,.73-2.62,1.44-3.89,2.24-.08,.05-.19-.01-.2-.11l-.02-.75c0-.24-.2-.43-.44-.43h-.65c-.24,.01-.42,.21-.42,.45l.06,1.78s-.02,.09-.06,.11c-1.29,.75-2.53,1.6-3.78,2.41-.08,.05-.19,0-.2-.1l-.05-.75c-.02-.24-.22-.42-.46-.41l-.65,.03c-.24,.01-.42,.21-.4,.45l.13,1.76s-.02,.09-.05,.12l-3.67,2.58c-.08,.06-.2,0-.21-.1l-.08-.74c-.03-.24-.24-.41-.47-.39l-.65,.06c-.23,.03-.4,.24-.37,.48l.15,1.24-3.54-4.77,1.15,.49c.22,.09,.48,0,.57-.23l.28-.6c.1-.22,0-.47-.21-.57l-.7-.31c-.09-.04-.1-.17-.02-.23l3.85-2.7s.09-.03,.13-.01l1.62,.79c.22,.11,.48,.02,.59-.19l.3-.59c.11-.21,.03-.48-.19-.58l-.68-.34c-.09-.04-.1-.17-.01-.23,1.32-.85,2.61-1.73,3.96-2.52,.04-.02,.09-.03,.13,0l1.58,.85c.21,.11,.48,.04,.59-.17l.32-.58c.12-.21,.04-.48-.17-.59l-.66-.36c-.09-.05-.09-.18,0-.23,1.33-.83,2.71-1.58,4.07-2.34,.04-.02,.09-.02,.13,0l1.54,.91c.21,.12,.47,.05,.6-.15l.35-.57c.13-.2,.07-.47-.14-.6l-.65-.39c-.09-.05-.08-.18,0-.23l4.17-2.16s.09-.02,.13,0l.45,.29,2.65,5.32-.03,.54Z" />
				<g>
					<path d="M1113.23,27.85c-1.42,.77-2.78,1.63-4.17,2.46-.07,.04-.16-.01-.16-.09,.35-3.48-.07-6.3-.39-7.75l4.93-2.75c.25,1.62,.49,4.58-.16,8.06,0,.03-.03,.06-.05,.07Z" />
					<path d="M1101.6,35.17c-1.31,.94-2.67,1.82-3.94,2.82-.07,.05-.16,0-.16-.08,.02-3.54-.6-6.24-1.06-7.67,1.54-1.09,3.08-2.17,4.67-3.18,.41,1.61,.84,4.45,.54,8.03,0,.03-.02,.06-.05,.08Z" />
					<path d="M1119.28,24.59c-1.43,.74-2.88,1.47-4.27,2.27-.07,.04-.16-.02-.15-.1,.48-3.5,.22-6.28-.05-7.75l3.06-1.58,1.97,3.95c-.1,.97-.25,2.03-.5,3.13,0,.03-.03,.06-.06,.07Z" />
					<path d="M1093.4,32.42c.58-.44,1.17-.89,1.77-1.3,.31,1.07,.69,2.75,.86,4.85l-2.63-3.55Z" />
					<path d="M1107.33,31.37l-4.06,2.65c-.07,.05-.16,0-.16-.09,.21-3.48-.32-6.26-.72-7.71,1.56-1.05,3.2-1.98,4.81-2.96,.33,1.61,.68,4.54,.18,8.04,0,.03-.02,.06-.05,.08Z" />
				</g>
				<g>
					<path d="M1114.31,38.9c1.3-.77,2.57-1.59,3.9-2.3,.03-.01,.06-.02,.09,0,3.21,1.33,5.37,3.19,6.52,4.34l-4.21,2.35c-1.05-1.04-3.13-2.84-6.28-4.21-.08-.03-.09-.14-.02-.18Z" />
					<path d="M1103.64,46.07c1.19-.93,2.46-1.75,3.68-2.63,.02-.02,.06-.02,.08-.01,3.28,1,5.65,2.75,6.88,3.77-1.36,.86-2.67,1.77-3.98,2.7-1.12-.92-3.41-2.6-6.64-3.65-.08-.03-.1-.13-.03-.18Z" />
					<path d="M1119.87,35.67c1.31-.76,2.66-1.43,4-2.13,.03-.01,.06-.01,.09,0,1,.46,1.89,.97,2.67,1.49l1.97,3.95-2.62,1.35c-1-1.08-3.03-3.02-6.1-4.49-.07-.04-.08-.14-.01-.18Z" />
					<path d="M1109.24,50.65c-.52,.35-1.01,.73-1.51,1.11l-2.63-3.55c1.85,.81,3.25,1.76,4.14,2.44Z" />
					<path d="M1108.88,42.36l3.79-2.47s.06-.02,.09,0c3.28,1.21,5.53,2.99,6.72,4.08-1.36,.85-2.77,1.63-4.1,2.53-1.09-.99-3.26-2.71-6.47-3.95-.08-.03-.09-.13-.03-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1148.69,21.81s-.04,.08-.09,.09l-4.28,1.37c-.09,.03-.19-.05-.17-.15l.15-.73c.05-.23-.1-.46-.34-.51l-.64-.14c-.23-.05-.46,.1-.5,.34l-.32,1.76s-.04,.08-.08,.1c-1.41,.52-2.83,.99-4.22,1.54-.09,.04-.19-.04-.17-.14l.11-.75c.04-.24-.12-.46-.36-.5l-.64-.1c-.23-.04-.45,.13-.49,.36l-.25,1.76s-.04,.08-.08,.1l-2.09,.83-2.06,.89c-.09,.04-.19-.04-.18-.14l.08-.75c.03-.24-.14-.45-.38-.48l-.65-.08c-.24-.03-.45,.14-.47,.38l-.18,1.77s-.03,.09-.08,.1c-1.38,.59-2.71,1.27-4.07,1.9-.09,.04-.19-.03-.19-.13l.05-.75c.02-.24-.16-.45-.4-.47l-.65-.05c-.24-.02-.44,.16-.46,.39l-.07,1.24-2.65-5.32,1.05,.69c.2,.13,.47,.08,.61-.11l.38-.54c.14-.2,.09-.47-.11-.6l-.63-.42c-.08-.06-.07-.18,.02-.23,1.42-.66,2.82-1.37,4.25-1.99,.04-.02,.09-.01,.13,.01l1.46,1.05c.19,.14,.47,.1,.61-.09l.4-.53c.14-.19,.11-.47-.09-.61l-.61-.45c-.08-.06-.07-.19,.03-.23l2.15-.93,2.18-.87s.09-.01,.13,.02l1.41,1.11c.19,.15,.46,.12,.61-.07l.42-.52c.15-.19,.13-.46-.06-.61l-.59-.47c-.08-.06-.06-.19,.04-.23,1.46-.57,2.94-1.07,4.41-1.61,.05-.02,.1,0,.13,.02l1.36,1.16c.18,.15,.46,.14,.62-.04l.44-.49c.16-.18,.15-.45-.03-.61l-.57-.5c-.08-.07-.05-.19,.05-.22l4.47-1.43s.1,0,.13,.03l.4,.37,1.7,5.69-.12,.52Z" />
				<g>
					<path d="M1138.17,16.39l-2.28,.82-2.25,.89c-.08,.03-.16-.04-.14-.12,.93-3.4,1.01-6.22,.94-7.69,1.77-.62,3.52-1.31,5.32-1.86-.03,1.63-.31,4.56-1.53,7.91-.01,.03-.04,.05-.06,.06Z" />
					<path d="M1125.45,21.57l-4.37,2.08c-.08,.04-.16-.02-.15-.11,.66-3.43,.49-6.24,.29-7.73,1.69-.83,3.42-1.56,5.14-2.31,.12,1.65,.08,4.57-.86,7.99,0,.03-.03,.06-.06,.07Z" />
					<path d="M1144.68,14.2c-1.53,.5-3.08,.97-4.6,1.51-.08,.03-.16-.04-.13-.12,1.08-3.35,1.29-6.13,1.27-7.63,1.1-.34,2.18-.72,3.29-1.03l1.27,4.23c-.27,.92-.6,1.94-1.03,2.99-.01,.03-.04,.05-.07,.06Z" />
					<path d="M1117.87,17.43c.65-.33,1.32-.63,1.98-.95,.13,1.12,.21,2.82,0,4.9l-1.97-3.95Z" />
					<path d="M1131.75,18.84c-1.5,.59-2.96,1.27-4.45,1.9-.08,.03-.16-.03-.14-.11,.8-3.4,.75-6.24,.61-7.73,1.73-.72,3.49-1.39,5.23-2.09,.05,1.65-.1,4.6-1.19,7.97,0,.03-.03,.05-.06,.07Z" />
				</g>
				<g>
					<path d="M1137.33,27.45l2.11-.83,2.14-.77s.06,0,.09,0c2.93,1.83,4.75,4.06,5.69,5.4-1.54,.47-3.03,1.07-4.55,1.6-.86-1.21-2.61-3.36-5.47-5.23-.07-.04-.06-.15,.01-.18Z" />
					<path d="M1125.56,32.66l4.09-1.95s.06-.01,.09,0c3.08,1.59,5.1,3.68,6.13,4.91-1.47,.64-2.96,1.27-4.4,1.98-.95-1.12-2.89-3.12-5.91-4.76-.07-.04-.07-.14,0-.18Z" />
					<path d="M1143.37,25.21c1.42-.51,2.87-.94,4.31-1.41,.03,0,.06,0,.08,.01,.9,.62,1.69,1.27,2.38,1.93l1.27,4.23c-.95,.26-1.87,.59-2.81,.89-.8-1.22-2.47-3.48-5.25-5.47-.07-.05-.06-.15,.02-.18Z" />
					<path d="M1130.29,38.17c-.56,.27-1.13,.53-1.69,.81l-1.97-3.95c1.69,1.13,2.91,2.33,3.66,3.14Z" />
					<path d="M1131.39,29.94c1.39-.59,2.76-1.23,4.17-1.78,.03-.01,.06,0,.09,0,3.02,1.74,4.93,3.87,5.91,5.15l-4.48,1.79c-.91-1.16-2.74-3.22-5.69-4.99-.07-.04-.07-.15,0-.18Z" />
				</g>
			</g>
			<g>
				<path d="M1174.13,16.5s-.06,.07-.1,.08c-1.49,.14-2.96,.4-4.44,.61-.1,.01-.17-.08-.14-.18l.27-.7c.09-.22-.02-.47-.24-.56l-.6-.24c-.22-.09-.47,.02-.55,.24l-.62,1.66s-.05,.08-.1,.08l-4.42,.8c-.1,.02-.18-.08-.15-.17l.24-.71c.08-.22-.04-.47-.27-.55l-.62-.22c-.22-.07-.47,.05-.54,.28l-.54,1.7s-.05,.08-.09,.09c-1.46,.33-2.93,.6-4.38,.99-.1,.02-.18-.07-.15-.16l.21-.73c.07-.23-.06-.47-.29-.54l-.62-.18c-.23-.07-.46,.07-.53,.3l-.47,1.71s-.05,.08-.09,.09c-1.46,.34-2.89,.76-4.33,1.18-.09,.03-.18-.06-.16-.16l.18-.73c.06-.23-.08-.47-.31-.53l-.63-.16c-.23-.06-.46,.08-.52,.31l-.28,1.2-1.7-5.69,.92,.86c.17,.17,.45,.16,.62-.01l.46-.47c.17-.17,.17-.45,0-.61l-.55-.52c-.07-.07-.04-.19,.06-.22,1.5-.43,3.01-.87,4.53-1.23,.05-.01,.1,0,.13,.04l1.26,1.28c.17,.17,.44,.18,.62,0l.48-.46c.18-.17,.18-.44,.02-.61l-.53-.54c-.07-.07-.03-.19,.07-.22,1.51-.4,3.06-.69,4.58-1.03,.05-.01,.1,0,.13,.04l1.2,1.32c.16,.18,.44,.19,.62,.03l.5-.43c.18-.15,.2-.43,.05-.61l-.5-.57c-.07-.08-.03-.2,.08-.21l4.62-.84s.09,0,.12,.04l1.15,1.39c.15,.19,.43,.22,.62,.07l.52-.41c.19-.15,.22-.42,.07-.61l-.48-.59c-.06-.08-.02-.2,.09-.21,1.55-.21,3.1-.49,4.66-.64,.05,0,.09,.01,.12,.05l.33,.43,.69,5.9-.21,.49Z" />
				<g>
					<path d="M1164.69,9.34c-1.59,.3-3.18,.54-4.76,.91-.08,.02-.15-.06-.11-.14,1.5-3.21,2.07-5.91,2.25-7.41,1.85-.34,3.7-.68,5.57-.93-.31,1.63-1.1,4.4-2.87,7.52-.02,.03-.04,.05-.07,.05Z" />
					<path d="M1151.27,12.28l-4.66,1.32c-.08,.02-.16-.05-.13-.13,1.23-3.27,1.55-6.08,1.6-7.58,1.8-.52,3.64-.94,5.46-1.4-.16,1.64-.69,4.54-2.21,7.74-.01,.03-.04,.05-.07,.06Z" />
					<path d="M1171.48,8.28l-4.79,.7c-.08,.01-.15-.07-.11-.15,1.65-3.07,2.33-5.82,2.57-7.3,1.14-.18,2.28-.32,3.42-.44l.52,4.39c-.42,.88-.93,1.8-1.54,2.74-.02,.03-.05,.05-.08,.05Z" />
					<path d="M1144.51,6.91l2.11-.6c-.07,1.12-.27,2.83-.84,4.84l-1.27-4.23Z" />
					<path d="M1157.95,10.67c-1.58,.32-3.15,.72-4.71,1.11-.08,.02-.15-.06-.12-.14,1.37-3.23,1.8-6.05,1.92-7.52,1.84-.39,3.67-.82,5.52-1.17-.24,1.6-.89,4.51-2.54,7.65-.01,.03-.04,.05-.07,.05Z" />
				</g>
				<g>
					<path d="M1161.97,20.09c1.47-.34,2.97-.56,4.45-.85,.03,0,.06,0,.08,.02,2.57,2.28,3.98,4.85,4.67,6.3-1.59,.21-3.17,.5-4.75,.79-.64-1.31-2-3.79-4.5-6.1-.06-.06-.04-.16,.04-.17Z" />
					<path d="M1149.49,23.24l4.36-1.24s.06,0,.08,.02c2.77,2.12,4.39,4.48,5.21,5.88-1.56,.41-3.13,.75-4.67,1.2-.75-1.26-2.31-3.55-5.01-5.69-.06-.05-.05-.15,.03-.18Z" />
					<path d="M1168.3,18.91l4.48-.66s.06,0,.08,.03c.78,.78,1.45,1.58,2.01,2.34l.52,4.39c-.97,.1-1.95,.22-2.92,.37-.58-1.35-1.83-3.82-4.23-6.3-.06-.06-.03-.16,.05-.17Z" />
					<path d="M1153.21,29.46l-1.8,.52-1.27-4.23c1.48,1.42,2.47,2.79,3.07,3.72Z" />
					<path d="M1155.7,21.54c1.47-.37,2.93-.75,4.41-1.04,.03,0,.06,0,.08,.02,2.67,2.22,4.18,4.63,4.94,6.08-1.58,.3-3.14,.67-4.72,1-.7-1.32-2.15-3.64-4.76-5.88-.06-.05-.04-.16,.04-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1200.06,15.77s-.07,.06-.11,.06l-2.23-.14-2.24-.07c-.1,0-.16-.11-.1-.2l.39-.64c.12-.2,.06-.47-.14-.6l-.55-.34c-.2-.13-.46-.06-.59,.14l-.91,1.52s-.07,.06-.11,.06c-1.49-.05-2.99,0-4.48,0-.1,0-.16-.11-.11-.2l.36-.66c.11-.21,.04-.47-.16-.59l-.57-.32c-.21-.12-.47-.05-.58,.16l-.84,1.57s-.06,.07-.11,.07l-4.48,.2c-.1,0-.17-.1-.12-.19l.33-.68c.11-.21,.02-.48-.19-.58l-.58-.28c-.21-.1-.47-.01-.57,.2l-.76,1.61s-.06,.07-.11,.07c-1.49,.12-2.98,.21-4.47,.4-.1,.01-.17-.09-.13-.18l.3-.69c.1-.22,0-.48-.22-.57l-.59-.26c-.22-.1-.47,0-.56,.22l-.49,1.14-.69-5.9,.75,1c.14,.19,.42,.23,.61,.09l.54-.39c.2-.14,.24-.41,.1-.61l-.45-.61c-.06-.08,0-.2,.09-.21,1.56-.2,3.12-.3,4.68-.42,.05,0,.09,.02,.12,.06l1.02,1.47c.13,.2,.41,.25,.61,.11l.55-.37c.2-.13,.26-.4,.13-.6l-.42-.63c-.06-.08,0-.2,.1-.21l4.7-.21s.09,.02,.12,.06l.95,1.52c.13,.21,.4,.28,.6,.15l.57-.33c.21-.12,.28-.39,.15-.59l-.4-.65c-.05-.09,0-.2,.11-.2,1.57,0,3.14-.06,4.7,0,.05,0,.09,.03,.11,.07l.88,1.57c.12,.21,.38,.29,.59,.18l.59-.31c.21-.11,.3-.38,.18-.59l-.36-.67c-.05-.09,.02-.2,.12-.19l2.35,.07,2.35,.15s.09,.03,.11,.07l.24,.48-.39,5.93-.29,.45Z" />
				<g>
					<path d="M1192.07,7.01l-4.85,.04c-.09,0-.14-.09-.09-.16,2.05-2.83,3.09-5.45,3.54-6.88,1.89-.04,3.78,.03,5.67,.09-.6,1.54-1.88,4.17-4.19,6.87-.02,.02-.05,.04-.08,.04Z" />
					<path d="M1178.32,7.56c-1.61,.1-3.21,.32-4.82,.49-.08,0-.14-.08-.1-.15,1.78-3.02,2.58-5.75,2.89-7.21l5.64-.43c-.45,1.58-1.48,4.36-3.53,7.25-.02,.03-.05,.04-.08,.04Z" />
					<path d="M1198.96,7.22c-1.61-.12-3.23-.11-4.85-.17-.09,0-.13-.1-.08-.16,2.18-2.74,3.35-5.33,3.86-6.74,1.16,.05,2.31,.14,3.46,.21l-.29,4.41c-.58,.79-1.25,1.61-2.02,2.43-.02,.02-.05,.03-.08,.03Z" />
					<path d="M1172.58,1.1l2.18-.24c-.26,1.09-.76,2.75-1.67,4.63l-.52-4.39Z" />
					<path d="M1185.19,7.14c-1.61,.07-3.23,.12-4.84,.27-.09,0-.14-.08-.09-.15,1.91-2.97,2.84-5.61,3.22-7.05,1.89-.07,3.77-.19,5.66-.17-.52,1.56-1.68,4.23-3.86,7.07-.02,.02-.05,.04-.08,.04Z" />
				</g>
				<g>
					<path d="M1187.49,17.1l4.52-.04s.06,.01,.08,.04c2.11,2.75,3.04,5.5,3.45,7.05-1.6-.04-3.19-.11-4.79-.07-.39-1.42-1.29-4.04-3.33-6.81-.05-.06,0-.16,.07-.16Z" />
					<path d="M1174.67,18.04c1.5-.16,3-.37,4.51-.46,.03,0,.06,0,.08,.03,2.36,2.56,3.53,5.15,4.09,6.68l-4.79,.36c-.52-1.38-1.65-3.88-3.94-6.45-.05-.06-.02-.16,.06-.17Z" />
					<path d="M1193.91,17.1c1.51,.06,3.02,.05,4.52,.16,.03,0,.06,.02,.07,.04,.63,.91,1.14,1.8,1.55,2.66l-.29,4.41c-.97-.06-1.94-.14-2.92-.18-.32-1.43-1.1-4.08-3.01-6.93-.05-.07,0-.16,.08-.16Z" />
					<path d="M1177.25,24.8l-1.86,.21-.52-4.39c1.21,1.65,1.95,3.16,2.37,4.18Z" />
					<path d="M1181.07,17.44c1.5-.14,3.01-.19,4.52-.25,.03,0,.06,.01,.08,.03,2.24,2.61,3.29,5.33,3.78,6.87-1.6-.02-3.2,.09-4.79,.15-.46-1.4-1.48-4.01-3.64-6.64-.05-.06-.01-.16,.07-.17Z" />
				</g>
			</g>
			<g>
				<path d="M1225.38,20.37s-.08,.04-.12,.03l-4.26-1.25c-.09-.03-.12-.15-.06-.22l.52-.53c.17-.17,.17-.44,0-.62-.15-.16-.3-.32-.46-.46-.17-.16-.44-.15-.61,.02l-1.22,1.31s-.08,.05-.12,.04l-4.33-.99c-.1-.02-.14-.13-.07-.21l.49-.57c.15-.18,.14-.45-.04-.61l-.49-.42c-.18-.15-.45-.13-.6,.06l-1.14,1.38s-.08,.05-.12,.05c-1.46-.24-2.92-.52-4.39-.72-.1-.01-.14-.13-.08-.21l.46-.61c.14-.19,.11-.46-.07-.6l-.51-.39c-.19-.14-.46-.1-.6,.09l-1.06,1.44s-.07,.06-.12,.05c-1.48-.15-2.94-.35-4.43-.47-.1,0-.15-.12-.1-.2l.42-.63c.13-.2,.09-.47-.11-.6l-.53-.36c-.2-.13-.46-.08-.59,.12l-.69,1.04,.39-5.93,.56,1.12c.11,.21,.37,.3,.59,.2l.6-.29c.22-.11,.31-.37,.21-.58l-.33-.68c-.04-.09,.03-.2,.13-.19,1.57,.12,3.14,.33,4.7,.5,.05,0,.09,.04,.11,.08l.72,1.64c.09,.22,.35,.32,.58,.22l.62-.26c.23-.1,.33-.35,.24-.57l-.3-.69c-.04-.09,.04-.19,.14-.18,1.56,.22,3.12,.51,4.68,.77,.05,0,.09,.04,.1,.08l.62,1.68c.08,.22,.33,.34,.56,.26l.63-.23c.23-.07,.35-.31,.27-.55l-.25-.72c-.03-.09,.05-.19,.15-.17l4.62,1.06s.08,.05,.1,.09l.52,1.71c.07,.23,.31,.36,.55,.29,.22-.07,.43-.12,.64-.17,.23-.06,.37-.29,.31-.53l-.21-.74c-.03-.1,.06-.19,.16-.16l4.55,1.33s.08,.05,.09,.1l.13,.52-1.78,5.67-.39,.37Z" />
				<g>
					<path d="M1219.56,10.06c-1.59-.36-3.18-.76-4.8-1-.09-.01-.12-.12-.05-.17,2.62-2.42,4.22-4.69,4.98-5.99,1.89,.45,3.79,.84,5.66,1.35-.93,1.32-2.82,3.66-5.69,5.8-.02,.02-.06,.03-.09,.02Z" />
					<path d="M1205.85,7.78l-2.43-.27c-.81-.07-1.62-.11-2.42-.17-.09,0-.13-.1-.07-.17,1.15-1.33,2.05-2.63,2.74-3.76,.69-1.11,1.16-2.08,1.46-2.76l5.75,.64c-.77,1.47-2.35,4-4.93,6.46-.02,.02-.06,.03-.09,.03Z" />
					<path d="M1226.3,11.82c-1.58-.44-3.13-.97-4.73-1.3-.08-.02-.11-.12-.04-.18,1.38-1.13,2.51-2.24,3.4-3.2,.88-.97,1.53-1.84,1.94-2.46l3.42,1-1.33,4.21c-.76,.64-1.61,1.28-2.56,1.91-.03,.02-.06,.02-.09,.01Z" />
					<path d="M1201.35,.35l2.2,.14c-.46,1.03-1.26,2.57-2.5,4.26l.29-4.41Z" />
					<path d="M1212.73,8.71l-2.42-.41c-.81-.13-1.62-.19-2.43-.29-.09,0-.12-.11-.06-.17,2.47-2.57,3.93-4.96,4.6-6.29,1.91,.33,3.81,.63,5.72,.99-.85,1.4-2.59,3.85-5.32,6.14-.02,.02-.06,.03-.09,.02Z" />
				</g>
				<g>
					<path d="M1212.94,18.95c1.48,.21,2.92,.58,4.37,.91,.03,0,.05,.03,.07,.05,1.44,3.14,1.72,5.93,1.74,7.58-1.49-.41-3.01-.71-4.52-1.07-.07-1.46-.34-4.28-1.76-7.33-.03-.07,.03-.16,.1-.15Z" />
					<path d="M1200.32,17.38c.75,.05,1.51,.09,2.25,.15l2.23,.25s.06,.02,.07,.05c1.81,2.95,2.43,5.72,2.66,7.3l-4.65-.51c-.12-.73-.34-1.77-.74-3.01-.41-1.22-1.02-2.61-1.91-4.07-.04-.07,0-.16,.09-.16Z" />
					<path d="M1219.14,20.28c1.46,.29,2.87,.79,4.3,1.18,.03,0,.05,.03,.06,.06,.4,1.02,.67,2,.87,2.91l-1.33,4.21-2.72-.79c.02-.74,0-1.8-.18-3.08-.17-1.3-.5-2.78-1.12-4.35-.03-.08,.04-.16,.11-.14Z" />
					<path d="M1201.61,24.48l-1.86-.12,.29-4.41c.89,1.83,1.33,3.45,1.56,4.52Z" />
					<path d="M1206.66,17.99c.74,.09,1.49,.14,2.22,.27l2.21,.38s.05,.02,.07,.05c1.63,3.05,2.08,5.83,2.21,7.45l-4.59-.79c-.15-1.47-.61-4.24-2.21-7.2-.04-.07,.02-.16,.1-.15Z" />
				</g>
			</g>
			<g>
				<path d="M1248.69,31.09s-.09,.02-.13,0c-1.22-.84-2.54-1.51-3.82-2.26-.08-.05-.08-.17,0-.23l.64-.39c.2-.12,.27-.39,.16-.59l-.32-.56c-.12-.21-.38-.27-.58-.15l-1.5,.93s-.09,.03-.13,0c-1.3-.71-2.64-1.33-3.96-2-.09-.04-.09-.17-.01-.23l.61-.43c.19-.13,.25-.4,.12-.6l-.36-.54c-.13-.2-.4-.25-.59-.11l-1.43,1.02s-.09,.03-.13,.02l-4.08-1.73c-.09-.04-.1-.16-.03-.23l.58-.46c.19-.15,.22-.42,.08-.61l-.39-.52c-.14-.19-.41-.23-.59-.08-.45,.36-.92,.76-1.38,1.14-.04,.03-.08,.04-.13,.02l-4.18-1.49c-.09-.03-.11-.16-.04-.22l.55-.5c.18-.16,.2-.43,.04-.61l-.42-.5c-.15-.19-.43-.19-.6-.03l-.91,.86,1.78-5.67,.28,1.21c.05,.23,.29,.37,.52,.34l.65-.12c.24-.04,.39-.27,.34-.51l-.16-.75c-.02-.1,.07-.18,.17-.15l4.47,1.6s.08,.05,.09,.1c.1,.59,.21,1.16,.3,1.77,.04,.24,.26,.41,.5,.38l.66-.08c.24-.03,.41-.25,.37-.49l-.11-.76c-.02-.1,.09-.18,.18-.14l4.37,1.86s.08,.06,.08,.11l.17,1.81c.02,.24,.23,.43,.47,.41l.66-.04c.24-.01,.42-.22,.4-.46l-.06-.76c0-.1,.1-.17,.19-.13,1.41,.71,2.85,1.38,4.24,2.14,.04,.02,.07,.07,.07,.12l.05,1.81c0,.24,.21,.44,.45,.44h.66c.24,0,.44-.19,.43-.43l-.02-.76c0-.1,.11-.17,.2-.11,1.37,.8,2.78,1.52,4.09,2.42,.04,.03,.06,.07,.06,.12l-.02,.55-3.17,5.02-.47,.25Z" />
				<g>
					<path d="M1245.69,19.61l-4.41-2.18c-.08-.04-.08-.15,0-.18,3.11-1.59,5.26-3.45,6.32-4.51,.87,.44,1.75,.85,2.6,1.32l2.54,1.44c-1.26,1.09-3.63,2.76-6.96,4.12-.03,.01-.06,.01-.09,0Z" />
					<path d="M1232.92,14c-1.55-.53-3.07-1.15-4.64-1.6-.08-.02-.1-.13-.03-.18,2.9-2.05,4.77-4.09,5.69-5.3,1.83,.67,3.68,1.26,5.46,2.07-1.1,1.22-3.24,3.2-6.38,5-.03,.02-.06,.02-.09,0Z" />
					<path d="M1251.78,23.01l-4.27-2.43c-.08-.04-.07-.15,0-.18,3.21-1.4,5.48-3.12,6.61-4.12l1.55,.89,1.5,.97-2.36,3.74c-.89,.41-1.86,.8-2.94,1.15-.03,.01-.06,0-.09,0Z" />
					<path d="M1230.28,5.69c.73,.2,1.44,.44,2.15,.7-.7,.88-1.85,2.13-3.48,3.51l1.33-4.21Z" />
					<path d="M1239.39,16.6c-1.51-.63-3.01-1.3-4.53-1.9-.08-.03-.09-.13-.02-.18,3.01-1.81,5.03-3.77,6.01-4.91l2.69,1.15c.89,.4,1.75,.85,2.63,1.27-.59,.57-1.46,1.33-2.57,2.12-.56,.4-1.18,.8-1.86,1.21-.69,.42-1.44,.84-2.25,1.24-.03,.01-.06,.01-.09,0Z" />
				</g>
				<g>
					<path d="M1237.05,26.55l4,1.98s.04,.04,.05,.07c.61,3.38,.1,6.26-.27,7.81l-2.01-1.15c-.67-.38-1.38-.69-2.06-1.04,.31-1.44,.72-4.16,.16-7.56-.01-.08,.07-.15,.14-.11Z" />
					<path d="M1225.25,21.99c1.43,.41,2.81,.98,4.22,1.46,.03,0,.05,.03,.06,.06,1.05,3.24,.92,6.15,.75,7.76-1.4-.65-2.88-1.1-4.32-1.64,.11-1.44,.2-4.28-.82-7.51-.02-.08,.05-.15,.12-.13Z" />
					<path d="M1242.69,29.41l3.88,2.2s.04,.04,.05,.07c.12,1.1,.12,2.13,.07,3.08l-2.36,3.74-1.18-.77-1.23-.7c.4-1.41,.98-4.09,.63-7.52,0-.08,.07-.14,.14-.1Z" />
					<path d="M1224.76,29.2c-.56-.21-1.13-.41-1.71-.56l1.33-4.21c.42,1.95,.43,3.67,.38,4.77Z" />
					<path d="M1231.22,24.08c1.38,.55,2.74,1.16,4.11,1.73,.03,.01,.05,.04,.05,.06,.21,.83,.35,1.63,.43,2.38,.08,.77,.1,1.5,.1,2.17-.01,1.34-.15,2.46-.29,3.25-.69-.33-1.37-.7-2.08-1.01l-2.13-.9c.21-1.45,.46-4.22-.33-7.56-.02-.08,.06-.15,.13-.12Z" />
				</g>
			</g>
			<g>
				<path d="M1268.55,47.37s-.09,0-.12-.03l-3.11-3.17c-.07-.07-.04-.19,.06-.22l.72-.21c.23-.07,.36-.3,.3-.54l-.18-.62c-.07-.22-.31-.35-.54-.28l-1.7,.54s-.09,0-.13-.03c-1.11-.98-2.15-2.04-3.31-2.97-.08-.06-.05-.18,.04-.22l.71-.27c.23-.08,.34-.33,.27-.55l-.22-.6c-.08-.22-.33-.33-.55-.25l-1.66,.64s-.09,0-.13-.02c-1.15-.94-2.27-1.9-3.49-2.74-.08-.06-.06-.18,.03-.22l.69-.31c.22-.1,.32-.35,.23-.56l-.25-.59c-.09-.22-.35-.31-.56-.22l-1.61,.74s-.09,.02-.13-.01c-.6-.43-1.19-.88-1.8-1.3l-1.86-1.2c-.08-.05-.07-.18,.02-.22l.67-.35c.21-.11,.3-.37,.19-.58l-.29-.58c-.11-.21-.36-.29-.57-.18l-1.09,.58,3.17-5.02-.04,1.26c0,.24,.18,.45,.42,.46l.67,.04c.24,.01,.45-.17,.46-.41l.03-.76c0-.1,.12-.16,.21-.1l1.99,1.29c.65,.45,1.28,.93,1.92,1.39,.04,.03,.06,.08,.06,.12l-.17,1.79c-.02,.24,.15,.45,.39,.48l.66,.08c.24,.03,.46-.14,.49-.38l.08-.75c.01-.1,.13-.15,.21-.09,1.3,.91,2.51,1.93,3.73,2.93,.04,.03,.06,.08,.05,.13l-.28,1.77c-.04,.24,.12,.46,.36,.5l.66,.12c.24,.04,.47-.11,.51-.35l.12-.74c.02-.1,.14-.14,.22-.08,1.23,.99,2.35,2.11,3.53,3.17,.04,.03,.05,.08,.04,.13l-.39,1.75c-.05,.23,.1,.47,.33,.52l.65,.16c.23,.06,.47-.07,.53-.31l.18-.74c.02-.1,.15-.13,.22-.06l3.32,3.38s.05,.08,.03,.13l-.15,.52-4.32,4.08-.52,.13Z" />
				<g>
					<path d="M1268.51,35.52l-1.81-1.65-1.9-1.56c-.07-.05-.04-.16,.04-.18,3.45-.81,6.01-2.07,7.28-2.81,1.43,1.31,2.88,2.61,4.28,3.94-1.47,.71-4.26,1.83-7.8,2.28-.03,0-.06,0-.09-.03Z" />
					<path d="M1257.62,26.83c-1.33-.95-2.74-1.8-4.1-2.7-.07-.05-.06-.15,.02-.18,3.3-1.2,5.69-2.78,6.87-3.7,1.62,1.08,3.16,2.27,4.74,3.4-1.38,.91-3.99,2.33-7.43,3.2-.03,0-.06,0-.09-.02Z" />
					<path d="M1273.52,40.35c-1.15-1.16-2.27-2.36-3.5-3.44-.06-.06-.04-.16,.05-.17,3.52-.61,6.09-1.65,7.42-2.34l2.48,2.54-3.21,3.03c-.97,.19-2.03,.34-3.16,.42-.03,0-.06,0-.09-.03Z" />
					<path d="M1257.16,18.14l1.91,1.23c-.9,.68-2.36,1.63-4.26,2.5l2.36-3.74Z" />
					<path d="M1263.2,31c-1.24-1.07-2.6-1.98-3.92-2.96-.07-.05-.05-.16,.03-.18,3.38-1,5.87-2.43,7.1-3.28l4.51,3.69c-1.44,.82-4.14,2.09-7.63,2.75-.03,0-.06,0-.09-.02Z" />
				</g>
				<g>
					<path d="M1258.38,40.06l1.73,1.41,1.65,1.51s.03,.05,.03,.08c-.28,3.45-1.41,6.02-2.18,7.46-1.12-1.07-2.28-2.09-3.42-3.15,.68-1.33,1.76-3.86,2.03-7.24,0-.08,.1-.13,.16-.08Z" />
					<path d="M1248.14,32.63c1.24,.82,2.52,1.59,3.73,2.45,.02,.02,.04,.05,.04,.08,.13,3.48-.69,6.21-1.26,7.71-1.26-.89-2.47-1.85-3.76-2.7,.48-1.38,1.23-4.02,1.1-7.44,0-.08,.08-.14,.15-.09Z" />
					<path d="M1263.15,44.24c1.12,.98,2.14,2.07,3.19,3.13,.02,.02,.03,.05,.03,.08-.15,1.08-.39,2.07-.66,2.97l-3.21,3.03-1.99-2.03c.73-1.26,2.03-3.79,2.48-7.12,.01-.08,.11-.12,.17-.07Z" />
					<path d="M1245.83,39.48l-1.51-.98,2.36-3.74c-.12,2.03-.52,3.66-.85,4.71Z" />
					<path d="M1253.38,36.18c1.19,.89,2.43,1.71,3.56,2.69,.02,.02,.04,.05,.04,.08-.08,3.47-1.06,6.13-1.72,7.59l-3.58-2.94c.57-1.35,1.48-3.92,1.56-7.34,0-.08,.09-.13,.16-.09Z" />
				</g>
			</g>
			<g>
				<path d="M1283.93,68.02s-.09-.02-.11-.06c-.37-.64-.73-1.3-1.11-1.93l-1.2-1.88c-.05-.08,0-.19,.11-.2l.75-.04c.24-.01,.43-.21,.42-.45l-.02-.65c0-.24-.21-.42-.45-.4l-1.77,.11s-.09-.02-.11-.06c-.77-1.27-1.69-2.44-2.53-3.66-.06-.08,0-.19,.1-.2l.75-.08c.24-.03,.41-.24,.39-.47l-.06-.65c-.02-.24-.23-.41-.47-.38l-1.75,.21s-.09-.01-.12-.05c-.89-1.19-1.84-2.33-2.75-3.5-.06-.08-.01-.19,.08-.21l.74-.12c.23-.04,.4-.26,.36-.49l-.09-.65c-.03-.24-.25-.4-.48-.36-.58,.1-1.15,.2-1.74,.32-.05,0-.09,0-.12-.04l-2.94-3.34c-.06-.07-.02-.19,.07-.22l.73-.17c.23-.05,.38-.28,.33-.52l-.13-.64c-.04-.24-.27-.38-.51-.32l-1.21,.3,4.32-4.08-.34,1.19c-.06,.23,.07,.47,.29,.55l.62,.21c.23,.08,.47-.05,.54-.28l.23-.73c.03-.1,.15-.12,.22-.05l3.13,3.55s.04,.09,.03,.13c-.19,.56-.4,1.13-.61,1.7-.08,.23,.03,.48,.25,.57l.61,.25c.22,.09,.48-.02,.56-.25l.27-.72c.04-.1,.16-.11,.23-.03,.97,1.24,1.98,2.46,2.92,3.72,.03,.04,.03,.09,.02,.13l-.71,1.66c-.1,.22,0,.48,.22,.58l.6,.28c.22,.1,.48,0,.58-.21l.31-.7c.04-.09,.17-.1,.23-.02,.9,1.3,1.87,2.54,2.69,3.89,.03,.04,.03,.09,0,.13l-.8,1.62c-.11,.22-.02,.48,.19,.59l.59,.31c.21,.11,.48,.03,.59-.18l.34-.68c.05-.09,.17-.09,.23,0l1.27,1.99c.41,.67,.79,1.36,1.18,2.05,.02,.04,.02,.09,0,.13l-.27,.47-5.15,2.97h-.53Z" />
				<g>
					<path d="M1286.71,56.47l-2.84-3.99c-.05-.07,0-.17,.08-.16,3.49,.08,6.27-.54,7.71-.98,1.15,1.55,2.13,3.2,3.18,4.82-1.61,.36-4.53,.77-8.05,.36-.03,0-.06-.02-.08-.05Z" />
					<path d="M1278.24,45.46c-1.09-1.21-2.13-2.48-3.27-3.65-.06-.06-.03-.16,.06-.17,3.52-.38,6.15-1.26,7.54-1.87,1.28,1.45,2.57,2.89,3.74,4.42-1.57,.55-4.36,1.24-7.98,1.31-.03,0-.06-.01-.08-.04Z" />
					<path d="M1290.43,62.32c-.83-1.4-1.74-2.76-2.61-4.14-.05-.07,0-.17,.09-.16,3.5,.27,6.31-.19,7.77-.55,.65,.98,1.19,2.03,1.78,3.04l-3.83,2.21c-.97-.05-2.03-.15-3.13-.34-.03,0-.06-.02-.07-.05Z" />
					<path d="M1279.98,36.93c.54,.53,1.04,1.09,1.53,1.66-1.04,.43-2.64,.96-4.74,1.38l3.21-3.03Z" />
					<path d="M1282.63,50.84l-3.04-3.84c-.05-.07-.01-.16,.07-.17,3.48-.12,6.21-.89,7.62-1.41,.6,.76,1.21,1.51,1.79,2.28l1.68,2.35c-1.6,.46-4.44,.99-8.03,.83-.03,0-.06-.02-.08-.04Z" />
				</g>
				<g>
					<path d="M1275.78,58.46l2.6,3.65s.02,.05,.01,.08c-1.11,3.3-2.86,5.58-3.92,6.79-.86-1.31-1.64-2.67-2.58-3.91,.95-1.12,2.6-3.32,3.72-6.58,.03-.08,.13-.1,.17-.03Z" />
					<path d="M1267.65,48.72c1.04,1.06,1.99,2.22,2.99,3.33,.02,.02,.03,.05,.02,.08-.65,3.34-2.18,5.86-3.09,7.17-.94-1.25-1.98-2.39-3.01-3.56,.8-1.21,2.24-3.65,2.92-6.97,.02-.08,.12-.11,.17-.06Z" />
					<path d="M1279.41,63.69c.79,1.27,1.63,2.5,2.39,3.79,.01,.03,.02,.06,0,.09-.41,1.03-.88,1.95-1.36,2.76l-3.83,2.21c-.49-.82-.92-1.68-1.45-2.48,1.01-1.06,2.77-3.17,4.07-6.35,.03-.08,.13-.09,.18-.02Z" />
					<path d="M1263.71,54.79c-.4-.46-.79-.91-1.23-1.33l3.21-3.03c-.58,1.91-1.41,3.43-1.98,4.36Z" />
					<path d="M1271.87,53.45l2.78,3.51s.02,.05,.02,.08c-.86,3.32-2.53,5.74-3.52,6.99l-1.36-1.91c-.47-.62-.97-1.22-1.45-1.83,.88-1.17,2.41-3.47,3.35-6.8,.02-.08,.12-.11,.17-.04Z" />
				</g>
			</g>
			<g>
				<path d="M1294.37,91.63s-.08-.04-.1-.08l-1.43-4.23c-.03-.09,.05-.19,.15-.17l.73,.13c.23,.04,.46-.11,.51-.35l.13-.64c.04-.23-.12-.45-.36-.49l-1.77-.27s-.08-.04-.1-.08l-1.64-4.15c-.04-.09,.04-.19,.14-.18l.75,.08c.23,.03,.46-.14,.48-.37l.07-.64c.03-.23-.15-.44-.38-.47l-1.78-.18s-.09-.03-.1-.07c-.63-1.35-1.17-2.74-1.87-4.05-.04-.09,.03-.19,.13-.18l.76,.04c.24,.01,.45-.16,.46-.4l.04-.64c.02-.23-.17-.44-.41-.45l-1.78-.08s-.09-.03-.11-.07c-.67-1.33-1.33-2.66-2.09-3.94-.05-.08,.02-.19,.12-.19h.75c.24,0,.44-.19,.44-.42v-.65c.01-.24-.18-.43-.42-.43h-1.24s5.15-2.96,5.15-2.96l-.62,1.09c-.12,.21-.05,.48,.16,.6l.57,.34c.21,.12,.48,.06,.6-.15l.38-.66c.05-.09,.18-.08,.23,0,.81,1.35,1.51,2.77,2.22,4.17,.02,.04,.02,.09,0,.13l-.97,1.51c-.13,.2-.07,.47,.13,.61l.56,.37c.2,.14,.47,.08,.61-.11l.41-.63c.06-.08,.18-.07,.23,.02,.73,1.4,1.31,2.86,1.98,4.29,.02,.04,.02,.09-.01,.13l-1.05,1.45c-.14,.19-.1,.47,.1,.61l.54,.4c.2,.15,.46,.11,.61-.08l.45-.61c.06-.08,.19-.06,.23,.03l1.73,4.39s.01,.1-.02,.13l-1.12,1.39c-.15,.19-.12,.46,.07,.61l.49,.43c.18,.16,.45,.14,.61-.05l.49-.59c.07-.08,.19-.05,.22,.04l1.51,4.47s0,.1-.03,.13l-.36,.4-5.66,1.79-.53-.11Z" />
				<g>
					<path d="M1299.55,80.98c-.59-1.51-1.17-3.03-1.86-4.51-.04-.08,.03-.16,.11-.14,3.46,.82,6.25,.84,7.74,.73,.68,1.79,1.44,3.55,2.05,5.36-1.62,0-4.59-.23-7.97-1.38-.03-.01-.05-.03-.07-.06Z" />
					<path d="M1293.81,68.38c-.71-1.47-1.57-2.85-2.36-4.28-.04-.07,.02-.16,.1-.15,3.49,.46,6.33,.15,7.8-.13l2.6,5.13c-1.65,.18-4.61,.27-8.07-.52-.03,0-.06-.03-.07-.06Z" />
					<path d="M1301.91,87.49c-.54-1.53-.99-3.09-1.61-4.6-.03-.08,.04-.16,.12-.13,3.41,1,6.16,1.18,7.67,1.14l1.12,3.32-4.21,1.33c-.95-.25-1.97-.57-3.03-.99-.03-.01-.05-.04-.06-.07Z" />
					<path d="M1297.47,60.51l1.11,1.95c-1.11,.18-2.84,.36-4.94,.26l3.83-2.21Z" />
					<path d="M1296.85,74.61c-.34-.74-.66-1.49-1.01-2.22l-1.1-2.18c-.04-.08,.02-.16,.11-.15,3.47,.64,6.32,.49,7.78,.31l2.34,5.25c-1.64,.09-4.63,.03-8.05-.94-.03,0-.06-.03-.07-.06Z" />
				</g>
				<g>
					<path d="M1288.47,80.5c.64,1.35,1.17,2.76,1.72,4.15,.01,.03,0,.06,0,.09-1.76,2.97-3.93,4.8-5.26,5.77-.5-1.49-1.13-2.94-1.69-4.41,1.17-.89,3.32-2.7,5.06-5.6,.04-.07,.15-.07,.18,0Z" />
					<path d="M1282.73,69.12c.73,1.31,1.52,2.58,2.17,3.93,.01,.03,.01,.06,0,.09-1.46,3.15-3.43,5.22-4.62,6.3l-2.13-4.2c1.07-1,2.94-3,4.4-6.1,.03-.07,.14-.08,.18-.01Z" />
					<path d="M1290.88,86.41c.57,1.39,.98,2.83,1.49,4.25,0,.03,0,.06-.01,.08-.61,.91-1.25,1.7-1.88,2.39l-4.21,1.33-.93-2.74c1.2-.82,3.47-2.53,5.37-5.33,.05-.07,.15-.06,.18,.02Z" />
					<path d="M1277.52,74.12l-.91-1.59,3.83-2.21c-1.04,1.75-2.15,3.01-2.92,3.79Z" />
					<path d="M1285.76,74.73l1.02,2c.32,.68,.62,1.37,.93,2.05,.01,.03,.01,.06,0,.09-1.62,3.06-3.68,5.01-4.93,6.03l-1.92-4.31c1.15-.96,3.13-2.86,4.74-5.86,.04-.07,.14-.07,.18,0Z" />
				</g>
			</g>
			<g>
				<path d="M1299.6,116.95s-.07-.06-.08-.1c-.11-1.49-.33-2.96-.54-4.44-.01-.1,.09-.17,.18-.14l.7,.28c.22,.09,.48-.02,.56-.23l.25-.6c.09-.22-.02-.47-.24-.55l-1.65-.64s-.07-.05-.08-.1c-.17-1.48-.52-2.93-.77-4.4-.02-.1,.08-.18,.17-.15l.71,.24c.23,.08,.47-.04,.55-.26l.22-.61c.08-.22-.04-.47-.26-.54l-1.68-.55s-.08-.05-.08-.1c-.3-1.46-.67-2.9-1.01-4.35-.02-.1,.07-.18,.17-.15l.72,.2c.23,.06,.47-.06,.54-.29l.19-.62c.07-.23-.06-.47-.29-.53l-1.7-.46s-.08-.05-.09-.09l-1.23-4.29c-.03-.09,.06-.18,.16-.16l.73,.17c.23,.05,.47-.09,.52-.32l.16-.63c.06-.23-.08-.46-.31-.51l-1.22-.26,5.66-1.79-.84,.93c-.16,.18-.16,.46,.01,.62l.47,.46c.17,.17,.45,.16,.61-.02l.52-.56c.07-.08,.19-.04,.22,.06l1.3,4.54s0,.1-.03,.13l-1.29,1.27c-.17,.17-.18,.45-.02,.62l.45,.48c.16,.18,.44,.18,.61,.02l.55-.53c.07-.07,.19-.03,.22,.07,.35,1.53,.75,3.06,1.06,4.6,0,.05,0,.1-.04,.13l-1.35,1.2c-.18,.16-.2,.44-.04,.62l.43,.5c.16,.18,.43,.21,.61,.05l.57-.5c.08-.07,.2-.02,.21,.08,.27,1.55,.63,3.08,.82,4.65,0,.05-.01,.1-.05,.13l-1.4,1.13c-.19,.15-.22,.43-.07,.62l.41,.53c.15,.19,.42,.23,.61,.08l.6-.47c.08-.06,.2-.01,.21,.09,.22,1.56,.45,3.11,.57,4.68,0,.05-.02,.09-.05,.12l-.44,.32-5.91,.59-.49-.21Z" />
				<g>
					<path d="M1306.91,107.6l-.9-4.79c-.02-.08,.07-.15,.14-.11,3.14,1.53,5.92,2.12,7.4,2.32,.39,1.87,.6,3.76,.88,5.65-1.62-.33-4.43-1.16-7.47-2.99-.03-.02-.05-.04-.05-.08Z" />
					<path d="M1303.92,94.1c-.45-1.56-.88-3.13-1.36-4.68-.03-.08,.05-.16,.13-.13,3.3,1.19,6.09,1.51,7.59,1.55l.79,2.75c.25,.92,.45,1.85,.68,2.78-1.66-.16-4.46-.72-7.76-2.2-.03-.01-.05-.04-.06-.07Z" />
					<path d="M1307.87,114.44c-.2-1.61-.44-3.21-.66-4.82-.01-.09,.07-.15,.15-.11,3.06,1.69,5.81,2.42,7.29,2.69,.18,1.15,.26,2.31,.38,3.47l-4.4,.44c-.87-.44-1.78-.97-2.71-1.59-.03-.02-.04-.05-.05-.08Z" />
					<path d="M1309.21,87.22c.25,.7,.42,1.42,.64,2.13-1.13-.06-2.81-.26-4.85-.8l4.21-1.33Z" />
					<path d="M1305.59,100.81l-1.12-4.74c-.02-.08,.06-.15,.14-.12,3.2,1.37,6,1.82,7.49,1.94,.21,.93,.45,1.85,.64,2.79l.53,2.81c-1.64-.25-4.45-.94-7.62-2.6-.03-.01-.05-.04-.06-.07Z" />
				</g>
				<g>
					<path d="M1296.16,104.82l.83,4.43s0,.06-.02,.08c-2.37,2.55-4.92,3.91-6.38,4.57-.23-1.57-.4-3.14-.73-4.69,1.33-.62,3.75-1.95,6.13-4.44,.06-.06,.16-.03,.17,.04Z" />
					<path d="M1292.97,92.45c.44,1.43,.83,2.88,1.25,4.32,0,.03,0,.06-.02,.08-2.01,2.75-4.48,4.39-5.86,5.2-.19-.77-.35-1.54-.56-2.3l-.65-2.28c1.25-.76,3.56-2.33,5.66-5.05,.05-.06,.15-.05,.18,.02Z" />
					<path d="M1297.27,111.12l.61,4.46s0,.06-.03,.08c-.8,.77-1.6,1.42-2.38,1.96l-4.4,.44c-.09-.96-.15-1.93-.31-2.89,1.36-.56,3.84-1.75,6.33-4.12,.06-.06,.16-.03,.17,.05Z" />
					<path d="M1286.78,96.23c-.18-.59-.32-1.19-.52-1.76l4.21-1.33c-1.37,1.48-2.77,2.5-3.69,3.1Z" />
					<path d="M1294.73,98.59l1.04,4.38s0,.06-.02,.08c-2.19,2.65-4.71,4.16-6.13,4.89l-.44-2.33c-.16-.78-.36-1.54-.54-2.31,1.3-.69,3.65-2.14,5.91-4.75,.05-.06,.16-.04,.17,.03Z" />
				</g>
			</g>
			<g>
				<path d="M1299.62,142.82s-.06-.07-.06-.12c.12-1.48,.31-2.97,.39-4.45,0-.1,.12-.15,.2-.1l.64,.41c.2,.13,.47,.08,.6-.12l.35-.54c.13-.2,.07-.46-.13-.59l-1.51-.96s-.06-.07-.06-.11c.05-1.49,.19-2.98,.15-4.47,0-.1,.11-.16,.2-.11l.66,.38c.21,.12,.47,.06,.59-.15l.33-.56c.12-.2,.05-.47-.16-.58l-1.55-.88s-.07-.06-.07-.11c0-1.49,.02-2.98-.08-4.47,0-.1,.1-.16,.19-.12l.67,.35c.21,.11,.47,.03,.58-.18l.3-.57c.11-.21,.03-.47-.19-.58l-1.59-.8s-.07-.06-.07-.11l-.1-2.23-.21-2.23c0-.1,.09-.17,.19-.13l.69,.31c.22,.1,.48,0,.58-.21l.27-.59c.1-.21,0-.47-.21-.57l-1.13-.5,5.91-.59-1.02,.74c-.2,.14-.24,.41-.1,.61l.38,.55c.14,.2,.41,.25,.6,.11l.62-.44c.08-.06,.2,0,.21,.1l.22,2.35,.11,2.36s-.02,.09-.06,.12l-1.5,.98c-.2,.13-.26,.4-.13,.61l.36,.56c.13,.21,.4,.27,.6,.14l.64-.41c.08-.05,.2,0,.2,.11,.1,1.57,.08,3.14,.08,4.71,0,.05-.02,.09-.07,.12l-1.55,.91c-.21,.12-.28,.39-.16,.6l.33,.58c.12,.21,.38,.29,.59,.17l.65-.38c.09-.05,.2,.02,.2,.12,.04,1.57-.11,3.14-.16,4.71,0,.05-.03,.09-.07,.11l-1.59,.83c-.21,.11-.3,.37-.19,.59l.3,.6c.11,.22,.37,.31,.58,.2l.67-.34c.09-.05,.2,.03,.19,.13-.08,1.57-.27,3.13-.41,4.7,0,.05-.03,.09-.08,.11l-.49,.23-5.91-.61-.44-.31Z" />
				<g>
					<path d="M1308.64,135.13c.1-1.62,.1-3.24,.09-4.86,0-.09,.09-.13,.16-.08,2.8,2.13,5.43,3.27,6.81,3.76l-.27,5.7c-1.52-.65-4.15-2.03-6.77-4.43-.02-.02-.04-.05-.03-.08Z" />
					<path d="M1308.47,121.32c-.07-1.62-.28-3.23-.41-4.85,0-.09,.08-.14,.15-.1,2.98,1.84,5.7,2.71,7.16,3.06,.14,1.9,.2,3.8,.29,5.7-1.58-.49-4.32-1.6-7.15-3.73-.02-.02-.04-.05-.04-.08Z" />
					<path d="M1308.2,142.02l.23-2.42,.12-2.43c0-.09,.1-.13,.17-.07,1.35,1.13,2.66,2.02,3.82,2.69,1.13,.67,2.1,1.12,2.79,1.41l-.32,3.47-4.39-.45c-.77-.61-1.57-1.31-2.36-2.11-.02-.02-.03-.05-.03-.09Z" />
					<path d="M1315.02,115.67l.21,2.21c-1.09-.28-2.74-.82-4.6-1.77l4.4-.44Z" />
					<path d="M1308.73,128.22c.03-1.62-.1-3.24-.16-4.86,0-.09,.09-.14,.16-.09,2.89,1.99,5.57,2.99,7,3.41v5.71c-1.55-.57-4.24-1.82-6.96-4.08-.02-.02-.04-.05-.04-.08Z" />
				</g>
				<g>
					<path d="M1298.67,130.24c0,1.5,.01,3.01-.08,4.51,0,.03-.02,.06-.04,.08-2.82,2.02-5.55,2.85-7.12,3.2l.22-4.75c1.46-.34,4.07-1.15,6.87-3.11,.07-.05,.16,0,.16,.08Z" />
					<path d="M1298.05,117.47c.12,1.5,.32,2.99,.38,4.49,0,.03-.01,.06-.03,.08-2.62,2.29-5.26,3.39-6.79,3.9-.09-1.58-.13-3.17-.24-4.75,1.38-.49,3.92-1.56,6.52-3.79,.06-.05,.16-.02,.17,.06Z" />
					<path d="M1298.5,136.64l-.11,2.25-.21,2.24s-.02,.06-.05,.07c-.93,.6-1.84,1.07-2.7,1.45l-4.39-.45,.27-2.89c.73-.13,1.77-.37,2.99-.8,1.2-.43,2.59-1.05,4.03-1.96,.07-.04,.16,0,.16,.09Z" />
					<path d="M1291.25,119.91l-.17-1.84,4.4-.44c-1.67,1.17-3.2,1.88-4.22,2.28Z" />
					<path d="M1298.52,123.85c.06,1.5,.18,3,.15,4.5,0,.03-.01,.06-.04,.08-2.73,2.16-5.42,3.12-6.97,3.56v-4.75c1.41-.42,3.98-1.36,6.69-3.45,.06-.05,.16-.01,.16,.07Z" />
				</g>
			</g>
			<g>
				<path d="M1294.6,168.29s-.05-.08-.03-.13l1.17-4.33c.03-.1,.15-.13,.22-.06l.54,.52c.17,.17,.44,.16,.61,0l.47-.46c.17-.17,.17-.44,0-.6l-1.27-1.22s-.05-.08-.03-.12c.41-1.44,.62-2.93,.94-4.39,.02-.1,.14-.13,.21-.07l.57,.5c.18,.16,.45,.15,.61-.03l.43-.48c.16-.18,.14-.45-.04-.61l-1.35-1.17s-.05-.08-.04-.12l.88-4.4c.02-.1,.14-.14,.22-.08l.57,.47c.18,.15,.46,.13,.61-.06,.15-.16,.29-.33,.42-.5,.15-.18,.11-.45-.08-.6l-1.41-1.1s-.06-.07-.05-.12l.62-4.43c0-.1,.12-.15,.21-.09l.62,.44c.19,.14,.47,.1,.6-.09l.38-.52c.14-.19,.09-.46-.1-.6l-1.02-.72,5.91,.61-1.13,.52c-.22,.1-.31,.36-.21,.58l.27,.61c.1,.22,.35,.33,.57,.23l.69-.31c.09-.04,.19,.04,.18,.14l-.65,4.67s-.04,.09-.08,.11l-1.66,.66c-.22,.09-.33,.34-.24,.57,.08,.21,.16,.42,.23,.62,.08,.23,.32,.34,.55,.26l.72-.27c.1-.04,.19,.04,.17,.15l-.92,4.6s-.04,.09-.09,.1l-1.7,.59c-.23,.08-.35,.33-.27,.55l.21,.63c.08,.23,.32,.36,.55,.28l.72-.24c.1-.03,.19,.05,.17,.15-.33,1.53-.56,3.08-.98,4.59-.01,.05-.05,.08-.09,.1l-1.74,.51c-.23,.07-.37,.31-.31,.54l.17,.64c.06,.23,.3,.37,.53,.3l.74-.21c.1-.03,.19,.06,.16,.16l-1.23,4.53s-.05,.08-.09,.09l-.52,.14-5.7-1.66-.38-.38Z" />
				<g>
					<path d="M1304.92,162.4l.95-4.74c.02-.08,.12-.11,.17-.05,2.33,2.62,4.68,4.22,5.98,4.97-.32,1.85-.85,3.66-1.34,5.47-1.38-.91-3.62-2.72-5.75-5.56-.02-.03-.03-.06-.02-.09Z" />
					<path d="M1307.42,148.88c.23-1.61,.47-3.21,.59-4.83,0-.09,.11-.12,.17-.07,2.61,2.41,5.03,3.79,6.38,4.42-.28,1.88-.5,3.78-.83,5.64-1.4-.78-3.92-2.43-6.28-5.08-.02-.02-.03-.06-.03-.09Z" />
					<path d="M1303.16,169.04l1.26-4.67c.02-.08,.13-.11,.18-.04,2.17,2.71,4.43,4.4,5.69,5.2l-.9,3.33-4.24-1.23c-.65-.73-1.32-1.56-1.97-2.49-.02-.03-.02-.06-.02-.09Z" />
					<path d="M1314.99,144.66l-.21,2.21c-1.01-.5-2.53-1.36-4.18-2.66l4.39,.45Z" />
					<path d="M1306.26,155.67c.3-1.59,.68-3.15,.87-4.77,.01-.08,.11-.12,.17-.06,2.5,2.55,4.79,4.01,6.13,4.71l-1.1,5.52c-1.41-.85-3.81-2.58-6.04-5.32-.02-.02-.03-.06-.02-.09Z" />
				</g>
				<g>
					<path d="M1296,155.69l-.89,4.45s-.03,.05-.05,.07c-3.15,1.46-6.07,1.77-7.68,1.83,.42-1.55,.88-3.09,1.15-4.68,1.46-.07,4.2-.38,7.33-1.78,.07-.03,.16,.03,.14,.11Z" />
					<path d="M1298,143.02c-.11,1.5-.34,2.99-.55,4.47,0,.03-.02,.06-.05,.07-3.01,1.73-5.76,2.27-7.41,2.48,.28-1.58,.46-3.14,.69-4.71,1.46-.2,4.22-.73,7.17-2.4,.07-.04,.16,.01,.15,.09Z" />
					<path d="M1294.66,161.98l-1.19,4.38s-.03,.05-.06,.06c-1.01,.42-1.99,.73-2.9,.96l-4.24-1.23,.77-2.84c1.48,0,4.24-.19,7.48-1.43,.08-.03,.16,.04,.14,.11Z" />
					<path d="M1290.87,144.05l.17-1.84,4.39,.45c-1.85,.82-3.48,1.2-4.57,1.39Z" />
					<path d="M1297.19,149.37c-.17,1.49-.53,2.97-.81,4.45,0,.03-.03,.05-.05,.07-3.1,1.59-5.93,2.04-7.54,2.18l.95-4.74c1.45-.13,4.3-.55,7.31-2.06,.07-.04,.16,.02,.15,.1Z" />
				</g>
			</g>
			<g>
				<path d="M1285.24,192.52s-.03-.09,0-.13c.65-1.35,1.39-2.65,1.96-4.04,.04-.09,.16-.1,.22-.02l.45,.61c.14,.19,.41,.24,.6,.1l.52-.38c.19-.14,.23-.41,.08-.6l-1.08-1.43s-.03-.09-.02-.13l1.74-4.14c.04-.09,.16-.11,.23-.03l.46,.59c.15,.19,.42,.22,.61,.08l.52-.4c.19-.14,.23-.41,.08-.6l-1.1-1.37s-.04-.08-.02-.13c.54-1.39,.99-2.82,1.48-4.23,.03-.09,.16-.12,.22-.04l.5,.57c.16,.18,.43,.2,.61,.05l.49-.42c.18-.15,.2-.43,.04-.6l-1.18-1.33s-.04-.08-.03-.13c.47-1.42,1.02-2.81,1.42-4.26,.03-.1,.15-.12,.22-.05l.53,.55c.17,.17,.44,.18,.61,.02l.46-.45c.17-.16,.17-.44,0-.61l-.88-.9,5.7,1.66-1.2,.31c-.23,.06-.37,.3-.31,.53l.16,.65c.06,.24,.29,.38,.52,.33l.73-.18c.1-.02,.19,.07,.16,.17-.42,1.51-.99,2.97-1.48,4.46-.02,.05-.05,.08-.1,.09l-1.76,.36c-.24,.05-.39,.28-.34,.51l.12,.65c.04,.24,.27,.39,.51,.35l.74-.15c.1-.02,.18,.08,.15,.17-.52,1.48-.99,2.97-1.56,4.43-.02,.04-.06,.08-.11,.08l-1.79,.28c-.24,.04-.41,.26-.38,.49l.08,.65c.03,.24,.25,.4,.49,.37l.76-.11c.1-.02,.18,.08,.14,.18l-1.82,4.33s-.06,.07-.1,.08l-1.77,.21c-.24,.03-.4,.24-.38,.49l.08,.67c.03,.24,.24,.42,.48,.39l.75-.08c.1-.01,.17,.09,.13,.19-.6,1.45-1.37,2.82-2.05,4.23-.02,.04-.06,.07-.11,.07l-.54,.04-5.33-2.62-.3-.44Z" />
				<g>
					<path d="M1296.38,188.49l1.87-4.47c.03-.08,.14-.09,.18-.02,1.79,2.97,3.81,4.94,4.95,5.9l-2.19,5.2c-1.2-1.14-3.19-3.35-4.81-6.53-.01-.03-.02-.06,0-.09Z" />
					<path d="M1301.21,175.63l.79-2.29,.64-2.33c.02-.08,.12-.1,.18-.03,1.07,1.41,2.16,2.57,3.13,3.48,.95,.9,1.79,1.56,2.39,1.99l-1.83,5.34c-1.29-1.03-3.43-3.05-5.29-6.06-.02-.03-.02-.06,0-.09Z" />
					<path d="M1293.57,194.77l1.07-2.17,.94-2.23c.03-.08,.14-.08,.18-.01,.87,1.54,1.8,2.83,2.65,3.86,.84,1.03,1.57,1.78,2.12,2.29l-1.54,3.09-3.96-1.95c-.51-.83-1.01-1.76-1.46-2.78-.01-.03-.01-.06,0-.09Z" />
					<path d="M1309.39,172.86l-.58,2.12c-.92-.67-2.26-1.78-3.67-3.35l4.24,1.23Z" />
					<path d="M1298.97,182.13l1.58-4.58c.03-.08,.13-.1,.18-.03,1.96,2.9,4.08,4.79,5.27,5.7-.31,.89-.6,1.78-.94,2.66l-1.09,2.6c-.61-.54-1.43-1.34-2.3-2.38-.88-1.05-1.76-2.32-2.69-3.89-.02-.03-.02-.06,0-.09Z" />
				</g>
				<g>
					<path d="M1288.88,180.38l-1.76,4.18s-.04,.05-.06,.06c-3.34,.9-6.18,.73-7.77,.52l1.86-4.44c1.46,.19,4.23,.37,7.61-.44,.08-.02,.15,.06,.12,.13Z" />
					<path d="M1292.98,168.19l-.59,2.19-.74,2.14s-.03,.05-.06,.06c-3.29,1.18-6.16,1.25-7.77,1.17l1.57-4.55c.74,.03,1.81,.03,3.11-.11,1.27-.15,2.75-.45,4.35-1.02,.08-.03,.16,.04,.13,.12Z" />
					<path d="M1286.38,186.31l-.88,2.09-1.01,2.03s-.04,.05-.07,.05c-1.08,.23-2.11,.36-3.06,.42l-3.96-1.95,1.32-2.63c.73,.13,1.8,.27,3.09,.29,1.28,.01,2.79-.09,4.45-.43,.08-.02,.15,.06,.12,.14Z" />
					<path d="M1285.79,167.96l.49-1.81,4.24,1.23c-1.96,.48-3.63,.58-4.73,.58Z" />
					<path d="M1291.02,174.32l-1.47,4.29s-.03,.05-.06,.06c-1.6,.54-3.21,.76-4.55,.86-1.35,.09-2.48,.04-3.29-.04l.94-2.22c.29-.75,.53-1.52,.79-2.27,1.46,.12,4.22,.17,7.51-.8,.08-.02,.15,.05,.13,.12Z" />
				</g>
			</g>
			<g>
				<path d="M1271.89,214.84s-.01-.09,.01-.13c.91-1.19,1.68-2.48,2.53-3.71,.05-.08,.18-.07,.22,.02l.34,.68c.11,.21,.36,.31,.58,.2l.58-.28c.21-.1,.3-.36,.19-.58l-.8-1.59s-.02-.09,0-.13c.82-1.26,1.69-2.48,2.46-3.76,.05-.09,.17-.08,.22,0l.37,.67c.12,.21,.38,.29,.58,.18l.56-.31c.21-.11,.28-.38,.16-.59l-.88-1.56s-.02-.09,0-.13l2.26-3.88c.05-.09,.18-.09,.23,0l.39,.64c.12,.2,.39,.27,.59,.16l.57-.33c.21-.12,.28-.38,.16-.58l-.92-1.5s-.02-.09,0-.13c.73-1.31,1.35-2.67,2.03-4,.04-.09,.17-.09,.23-.01l.42,.63c.13,.2,.4,.26,.6,.13l.54-.35c.2-.13,.25-.4,.12-.59l-.69-1.03,5.33,2.62-1.25,.09c-.24,.02-.42,.22-.41,.46l.04,.66c.01,.24,.22,.43,.46,.41l.76-.05c.1,0,.17,.1,.12,.19-.71,1.39-1.37,2.82-2.13,4.19-.02,.04-.07,.07-.12,.07l-1.81,.04c-.24,0-.44,.2-.44,.44v.66c0,.24,.19,.43,.43,.43h.76c.1-.01,.17,.1,.11,.18l-2.36,4.06s-.07,.07-.11,.07l-1.78-.02c-.24,0-.43,.19-.44,.43v.67c-.01,.24,.18,.45,.41,.45h.75c.1,.01,.16,.13,.11,.22-.81,1.34-1.72,2.61-2.57,3.93-.03,.04-.07,.06-.12,.06l-1.79-.1c-.24-.01-.45,.17-.46,.41l-.05,.66c-.02,.24,.16,.45,.4,.47l.76,.05c.1,0,.16,.12,.1,.21-.89,1.29-1.69,2.63-2.64,3.87-.03,.04-.08,.06-.12,.05l-.54-.05-4.82-3.47-.22-.48Z" />
				<g>
					<path d="M1283.53,212.73l1.34-2.01c.42-.68,.82-1.39,1.23-2.08,.04-.07,.15-.07,.18,.01,.67,1.64,1.43,3.04,2.13,4.16,.68,1.11,1.33,1.96,1.81,2.53l-3.11,4.68c-.98-1.32-2.55-3.82-3.58-7.19,0-.03,0-.06,.01-.09Z" />
					<path d="M1290.5,200.92l2.16-4.33c.04-.08,.14-.08,.18,0,1.57,3.13,3.42,5.28,4.48,6.34-.43,.84-.82,1.69-1.28,2.51l-1.41,2.44c-1.1-1.24-2.78-3.55-4.14-6.86-.01-.03-.01-.06,0-.09Z" />
					<path d="M1279.73,218.44l2.68-4.02c.05-.07,.15-.06,.18,.02,1.17,3.3,2.74,5.67,3.66,6.85-.63,.95-1.26,1.91-1.96,2.81l-3.58-2.58c-.37-.9-.67-1.87-.99-2.98,0-.03,0-.06,.01-.09Z" />
					<path d="M1299,199.59l-.98,1.96c-.78-.81-1.9-2.13-2.98-3.91l3.96,1.95Z" />
					<path d="M1287.11,206.88l2.43-4.18c.04-.07,.15-.07,.18,0,1.4,3.17,3.14,5.39,4.15,6.49l-2.83,4.87c-1.05-1.29-2.73-3.73-3.94-7.09-.01-.03,0-.06,0-.09Z" />
				</g>
				<g>
					<path d="M1277.48,203.44c-.38,.65-.75,1.31-1.15,1.96l-1.26,1.89s-.04,.04-.08,.04c-3.48,.3-6.27-.35-7.82-.83l2.69-4.03c.71,.22,1.74,.49,3.04,.68,1.27,.18,2.78,.27,4.48,.14,.08,0,.14,.08,.1,.15Z" />
					<path d="M1283.65,192.14l-2.02,4.06s-.04,.05-.07,.05c-3.39,.62-6.32,.14-7.88-.2l1.22-2.09c.39-.7,.72-1.43,1.09-2.15,1.43,.31,4.16,.72,7.55,.19,.08-.01,.15,.07,.11,.14Z" />
					<path d="M1274.02,208.87l-2.52,3.78s-.04,.04-.07,.04c-1.07,.08-2.15,0-3.09-.09l-3.58-2.58c.62-.78,1.16-1.61,1.7-2.43,1.39,.48,4.04,1.23,7.47,1.12,.08,0,.14,.09,.09,.15Z" />
					<path d="M1276.57,190.63l.84-1.68,3.96,1.95c-2.04,.12-3.71-.08-4.8-.27Z" />
					<path d="M1280.72,197.87l-2.28,3.92s-.04,.04-.07,.05c-3.42,.47-6.22-.06-7.77-.48l2.42-4.18c1.43,.37,4.15,.91,7.6,.54,.08,0,.14,.08,.1,.14Z" />
				</g>
			</g>
			<g>
				<path d="M1255.11,234.81s0-.09,.03-.13l3.09-3.29c.07-.07,.19-.04,.22,.05l.23,.72c.07,.23,.31,.36,.54,.29l.63-.19c.23-.07,.36-.31,.28-.53l-.54-1.68s0-.09,.03-.13c1.06-1.07,1.97-2.27,2.96-3.4,.06-.08,.19-.05,.22,.04l.26,.71c.08,.23,.33,.35,.55,.27l.61-.22c.22-.08,.34-.33,.26-.55l-.62-1.67s0-.09,.02-.13l2.9-3.45c.06-.08,.18-.06,.22,.03l.29,.7c.08,.22,.34,.33,.56,.25l.6-.24c.22-.09,.32-.34,.23-.57l-.69-1.66s-.01-.09,.02-.13l2.71-3.59c.06-.08,.19-.06,.23,.03l.3,.68c.1,.22,.35,.32,.57,.23l.6-.25c.22-.09,.32-.34,.22-.56l-.51-1.12,4.82,3.47-1.26-.13c-.24-.03-.46,.15-.49,.38l-.08,.65c-.03,.24,.14,.45,.38,.48l.76,.08c.1,.01,.15,.13,.09,.21l-2.82,3.74s-.08,.06-.12,.05l-1.77-.23c-.24-.03-.45,.14-.48,.38l-.09,.66c-.03,.24,.12,.46,.36,.5l.75,.1c.1,.01,.15,.13,.08,.21l-3.01,3.58s-.08,.05-.12,.05l-1.77-.31c-.24-.04-.46,.12-.51,.35l-.12,.65c-.04,.24,.11,.47,.34,.51l.74,.13c.1,.02,.14,.14,.07,.22-1.02,1.18-1.98,2.42-3.07,3.53-.03,.03-.08,.05-.13,.04l-1.77-.38c-.24-.05-.47,.09-.53,.33l-.15,.64c-.06,.23,.09,.47,.32,.52l.74,.16c.1,.02,.14,.14,.07,.22l-3.2,3.41s-.08,.05-.13,.04l-.52-.13-4.26-4.14-.15-.52Z" />
				<g>
					<path d="M1266.97,234.52l3.11-3.68c.05-.06,.16-.04,.18,.04,.78,3.41,2.08,5.94,2.87,7.21-1.17,1.45-2.49,2.75-3.74,4.11-.77-1.45-1.94-4.12-2.43-7.6,0-.03,0-.06,.02-.09Z" />
					<path d="M1275.64,223.93l2.9-3.85c.05-.07,.16-.05,.18,.03,1,3.32,2.47,5.73,3.34,6.94l-3.38,4.46c-.87-1.39-2.27-4.02-3.06-7.49,0-.03,0-.06,.02-.09Z" />
					<path d="M1262.31,239.51l3.29-3.51c.06-.06,.16-.03,.18,.05,.63,3.41,1.84,5.97,2.57,7.26l-2.33,2.47-3.17-3.08c-.24-.95-.44-1.99-.57-3.11,0-.03,0-.06,.03-.09Z" />
					<path d="M1284.29,224.09l-1.31,1.74c-.63-.92-1.51-2.4-2.28-4.32l3.58,2.58Z" />
					<path d="M1271.39,229.3c1.04-1.23,2.09-2.44,3.03-3.75,.05-.07,.15-.05,.18,.03,.96,3.43,2.29,5.85,3.11,7.09l-3.6,4.26c-.83-1.42-2.11-4.08-2.74-7.55,0-.03,0-.06,.02-.09Z" />
				</g>
				<g>
					<path d="M1262.4,224.35l-2.94,3.48s-.05,.04-.08,.03c-3.5-.24-6.19-1.31-7.65-2.02,1.1-1.2,2.28-2.35,3.3-3.62,1.32,.64,3.88,1.67,7.3,1.96,.08,0,.12,.1,.07,.16Z" />
					<path d="M1270.38,214.2l-2.74,3.63s-.05,.04-.08,.04c-3.46,.05-6.17-.79-7.68-1.4l2.93-3.88c1.37,.54,4.01,1.4,7.48,1.45,.08,0,.13,.09,.08,.16Z" />
					<path d="M1258.15,229.24l-3.13,3.33s-.05,.03-.08,.03c-1.09-.1-2.1-.28-3.02-.51l-3.17-3.08,2.05-2.19c1.31,.69,3.84,1.82,7.28,2.25,.08,.01,.12,.11,.07,.17Z" />
					<path d="M1263.62,211.53l1.14-1.51,3.58,2.58c-2.05-.22-3.68-.7-4.72-1.07Z" />
					<path d="M1266.5,219.35c-.88,1.23-1.88,2.38-2.86,3.54-.02,.02-.05,.04-.08,.03-3.49-.1-6.19-1.06-7.67-1.71l3.15-3.73c1.35,.59,4.01,1.59,7.38,1.71,.08,0,.13,.1,.08,.16Z" />
				</g>
			</g>
			<g>
				<path d="M1235.71,252.38s.01-.09,.05-.12l3.53-2.83c.08-.06,.2-.02,.21,.08l.13,.74c.04,.23,.27,.39,.5,.36,.22-.03,.43-.07,.64-.11,.23-.04,.38-.27,.34-.51l-.34-1.76s0-.09,.04-.12l3.4-2.98c.07-.07,.19-.02,.21,.07l.16,.74c.05,.23,.28,.39,.51,.34l.64-.13c.23-.05,.38-.27,.34-.51l-.37-1.73s0-.09,.04-.12c1.16-.97,2.21-2.05,3.3-3.09,.07-.07,.19-.03,.22,.07l.18,.73c.06,.23,.29,.38,.52,.32l.63-.15c.23-.06,.37-.29,.31-.52l-.44-1.73s0-.09,.04-.12l3.25-3.13c.07-.07,.19-.04,.22,.06l.2,.72c.06,.23,.3,.37,.53,.31,.22-.05,.42-.11,.63-.17,.23-.07,.35-.31,.29-.54l-.35-1.2,4.26,4.14-1.21-.29c-.23-.06-.46,.09-.52,.32-.05,.22-.1,.43-.17,.64-.07,.23,.07,.47,.31,.53l.74,.19c.1,.02,.13,.15,.06,.22l-3.36,3.24s-.08,.05-.13,.03l-1.72-.49c-.23-.07-.47,.07-.54,.3l-.19,.64c-.07,.23,.06,.47,.29,.54l.73,.21c.1,.03,.13,.15,.05,.22-1.13,1.07-2.22,2.19-3.41,3.19-.04,.03-.09,.04-.13,.03l-1.72-.55c-.23-.07-.48,.05-.55,.28l-.21,.62c-.08,.23,.04,.47,.27,.55l.72,.24c.1,.03,.12,.16,.04,.22l-3.51,3.08s-.08,.04-.13,.03l-1.68-.58c-.22-.08-.47,.04-.55,.27-.07,.21-.15,.42-.23,.62-.09,.22,.03,.47,.25,.55l.72,.26c.1,.03,.12,.16,.04,.22l-3.63,2.92s-.09,.04-.13,.02l-.51-.19-3.72-4.63-.09-.53Z" />
				<g>
					<path d="M1247.5,253.58l3.6-3.16c.06-.06,.16-.02,.17,.07,.3,3.46,1.26,6.11,1.87,7.47-1.39,1.2-2.75,2.44-4.16,3.61-.57-1.5-1.43-4.36-1.51-7.91,0-.03,.01-.06,.04-.08Z" />
					<path d="M1257.52,244.36c1.14-1.12,2.33-2.19,3.41-3.39,.06-.06,.16-.03,.17,.05,.59,3.51,1.64,6.03,2.34,7.36l-3.99,3.84c-.68-1.5-1.69-4.26-1.96-7.78,0-.03,0-.06,.03-.08Z" />
					<path d="M1242.29,257.96c1.23-1.01,2.51-1.97,3.69-3.05,.06-.06,.16-.02,.17,.07,.25,3.54,1.08,6.14,1.66,7.53l-2.62,2.1-2.77-3.44c-.12-.96-.19-2.01-.17-3.13,0-.03,.01-.06,.04-.08Z" />
					<path d="M1266.02,245.78c-.5,.52-.97,1.07-1.5,1.56-.48-.99-1.16-2.59-1.67-4.63l3.17,3.08Z" />
					<path d="M1252.61,249.09l3.46-3.33c.06-.06,.16-.02,.17,.06,.43,3.47,1.47,6.11,2.13,7.45-1.31,1.31-2.74,2.46-4.1,3.69-.63-1.51-1.54-4.29-1.69-7.79,0-.03,.01-.06,.03-.08Z" />
				</g>
				<g>
					<path d="M1244.36,242.95l-3.44,3.02s-.05,.03-.08,.02c-3.41-.64-5.9-2-7.29-2.9,1.27-1.05,2.49-2.17,3.73-3.25,1.24,.81,3.65,2.17,7.03,2.94,.08,.02,.11,.12,.05,.17Z" />
					<path d="M1253.72,233.96c-1.02,1.13-2.15,2.15-3.24,3.22-.02,.02-.05,.03-.08,.03-3.46-.45-6.06-1.68-7.47-2.48l3.55-3.42c1.27,.72,3.84,2,7.18,2.48,.08,.01,.12,.11,.06,.17Z" />
					<path d="M1239.47,247.24c-1.13,1.03-2.36,1.95-3.54,2.92-.02,.02-.06,.03-.08,.02-1.08-.24-2.07-.55-2.96-.89l-2.76-3.44,2.37-1.9c1.2,.83,3.64,2.33,6.94,3.13,.08,.02,.11,.12,.05,.17Z" />
					<path d="M1247.44,230.38c.47-.43,.88-.92,1.32-1.38l3.17,3.08c-1.97-.49-3.49-1.17-4.49-1.7Z" />
					<path d="M1249.09,238.51l-3.29,3.17s-.05,.03-.08,.02c-3.47-.59-6.04-1.91-7.43-2.75,1.22-1.1,2.52-2.13,3.67-3.3,1.25,.77,3.7,2.05,7.08,2.68,.08,.02,.11,.11,.06,.17Z" />
				</g>
			</g>
			<g>
				<path d="M1214.44,267.77s.02-.09,.06-.11c1.29-.79,2.51-1.69,3.77-2.52,.08-.06,.19,0,.2,.1l.06,.75c.02,.24,.22,.42,.46,.4l.65-.05c.24-.02,.42-.22,.4-.46l-.14-1.78s.02-.09,.06-.12l3.75-2.55c.08-.06,.19,0,.21,.1l.07,.75c.02,.24,.23,.41,.47,.39l.65-.06c.23-.02,.41-.24,.38-.48l-.19-1.78s.01-.09,.05-.12l3.64-2.69c.08-.06,.2,0,.21,.09l.09,.75c.03,.24,.24,.41,.48,.38l.65-.07c.24-.03,.41-.24,.38-.48l-.22-1.76s.01-.09,.05-.12c1.23-.88,2.38-1.85,3.56-2.79,.08-.06,.19-.02,.21,.08l.12,.75c.04,.24,.25,.4,.49,.37l.64-.1c.23-.04,.4-.26,.36-.49l-.19-1.23,3.72,4.63-1.17-.45c-.22-.09-.48,.02-.56,.25l-.24,.62c-.09,.22,.02,.48,.24,.56l.7,.27c.09,.04,.11,.16,.03,.23-1.22,.96-2.4,1.97-3.67,2.87-.04,.03-.09,.03-.13,.02l-1.66-.7c-.22-.09-.48,0-.57,.23l-.26,.6c-.1,.22,0,.47,.23,.57l.7,.3c.09,.04,.11,.17,.03,.23l-3.75,2.77s-.09,.03-.13,.02l-1.63-.72c-.22-.1-.47,0-.57,.22l-.27,.6c-.1,.22,0,.47,.21,.57l.69,.32c.09,.04,.1,.17,.02,.23l-3.84,2.62s-.09,.03-.13,.01l-1.62-.77c-.22-.1-.48-.01-.58,.2l-.29,.59c-.11,.22-.02,.48,.2,.58l.68,.33c.09,.04,.1,.17,.02,.23-1.29,.86-2.54,1.78-3.86,2.59-.04,.03-.09,.03-.13,0l-.48-.25-3.23-4.98-.03-.53Z" />
				<g>
					<path d="M1226.02,270.17c1.31-.9,2.64-1.77,3.91-2.74,.07-.05,.16,0,.16,.08-.04,3.53,.6,6.21,1.06,7.63l-4.51,3.07c-.43-1.58-.98-4.46-.67-7.98,0-.03,.02-.06,.05-.08Z" />
					<path d="M1236.99,262.21l3.73-3c.07-.05,.16,0,.16,.07,.12,3.49,.94,6.2,1.47,7.6-1.41,1.18-2.92,2.2-4.37,3.3-.5-1.56-1.18-4.4-1.03-7.9,0-.03,.02-.06,.04-.08Z" />
					<path d="M1220.42,273.99l3.94-2.69c.07-.05,.16,0,.16,.09-.14,3.49,.47,6.24,.9,7.67-.91,.63-1.84,1.25-2.78,1.83l-2.4-3.71c-.02-.97,.02-2,.14-3.12,0-.03,.02-.06,.05-.08Z" />
					<path d="M1245.19,264.61l-1.67,1.34c-.37-1.06-.85-2.71-1.1-4.78l2.77,3.44Z" />
					<path d="M1231.54,266.24l3.84-2.84c.07-.05,.16,0,.16,.08,.01,3.47,.75,6.19,1.24,7.59-1.47,1.08-2.93,2.18-4.41,3.24-.46-1.55-1.09-4.45-.88-7.99,0-.03,.02-.06,.04-.08Z" />
				</g>
				<g>
					<path d="M1224.06,259.26c-1.22,.93-2.5,1.77-3.77,2.64-.02,.02-.06,.02-.09,.01-3.34-1.02-5.72-2.66-6.99-3.67l4.14-2.82c1.14,.93,3.44,2.6,6.67,3.66,.08,.03,.1,.13,.03,.18Z" />
					<path d="M1234.43,251.37l-3.58,2.87s-.05,.03-.08,.02c-3.41-.87-5.87-2.4-7.18-3.36,1.32-1,2.71-1.94,3.97-3,1.18,.87,3.52,2.36,6.83,3.29,.08,.02,.1,.12,.04,.17Z" />
					<path d="M1218.7,262.99l-3.8,2.59s-.06,.02-.09,.01c-1.05-.34-2.01-.77-2.87-1.19l-2.4-3.71c.87-.54,1.72-1.1,2.56-1.68,1.12,.96,3.34,2.62,6.57,3.8,.08,.03,.09,.13,.03,.18Z" />
					<path d="M1228.62,247.05l1.51-1.21,2.76,3.44c-1.91-.73-3.36-1.61-4.27-2.23Z" />
					<path d="M1229.31,255.39l-3.7,2.73s-.06,.02-.08,.02c-3.35-.92-5.74-2.47-7.04-3.48,1.36-.97,2.68-1.97,4.02-2.95,1.17,.91,3.47,2.47,6.77,3.51,.08,.02,.1,.13,.03,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1191.77,281.15s.03-.09,.07-.11l3.99-2.18c.09-.05,.2,.02,.19,.12v.75c-.01,.24,.18,.44,.42,.44l.66,.02c.24,0,.44-.18,.44-.42l.02-1.77s.03-.09,.07-.11c1.32-.74,2.62-1.53,3.93-2.29,.09-.05,.2,.01,.2,.11v.76c0,.24,.2,.43,.44,.43h.65c.24,0,.43-.2,.43-.44l-.02-1.78s.02-.09,.06-.11c1.3-.77,2.62-1.52,3.91-2.31,.08-.05,.19,0,.2,.11l.02,.76c0,.24,.21,.43,.44,.42l.65-.02c.24,0,.42-.21,.41-.45l-.06-1.79s.02-.09,.06-.12l3.84-2.42c.08-.05,.2,0,.2,.11l.04,.75c.01,.24,.21,.42,.45,.41l.66-.03c.24,0,.42-.21,.41-.45l-.06-1.24,3.23,4.98-1.12-.57c-.21-.11-.48-.03-.59,.18l-.31,.58c-.11,.21-.03,.47,.18,.58l.68,.35c.09,.05,.1,.17,0,.23l-3.93,2.48s-.09,.03-.13,0l-1.57-.84c-.21-.11-.47-.03-.59,.18l-.31,.59c-.11,.21-.04,.48,.17,.59l.66,.36c.09,.05,.09,.17,0,.23-1.32,.81-2.67,1.57-4,2.36-.04,.02-.09,.02-.13,0l-1.56-.88c-.21-.12-.47-.04-.59,.16l-.33,.57c-.12,.21-.05,.47,.16,.59l.66,.37c.09,.05,.09,.18,0,.23-1.34,.78-2.66,1.58-4.01,2.33-.04,.02-.09,.02-.13,0l-1.55-.92c-.21-.12-.47-.06-.6,.15l-.34,.56c-.12,.2-.06,.47,.15,.59l.65,.39c.09,.05,.08,.18,0,.23l-4.07,2.23s-.09,.02-.13,0l-.46-.28-2.81-5.23v-.54Z" />
				<g>
					<path d="M1203.12,284.56l4.1-2.41c.07-.04,.16,.01,.15,.1-.37,3.46,.06,6.25,.4,7.7-1.55,.93-3.13,1.8-4.72,2.66-.3-1.62-.59-4.48,0-7.97,0-.03,.02-.06,.05-.07Z" />
					<path d="M1214.71,277.61l4.03-2.54c.07-.04,.16,0,.16,.09-.24,3.46,.31,6.22,.7,7.65l-4.6,2.9c-.36-1.6-.79-4.51-.34-8.03,0-.03,.02-.06,.05-.08Z" />
					<path d="M1197.21,287.82l4.17-2.28c.07-.04,.16,.02,.15,.1-.45,3.44-.07,6.22,.24,7.67l-2.9,1.59-2.09-3.89c.05-.97,.17-2.01,.38-3.12,0-.03,.02-.06,.05-.07Z" />
					<path d="M1222.63,280.89l-1.79,1.13c-.27-1.08-.58-2.77-.62-4.84l2.4,3.71Z" />
					<path d="M1208.94,281.14c1.37-.8,2.74-1.6,4.07-2.47,.07-.04,.16,.01,.15,.09-.28,3.51,.19,6.25,.55,7.69l-4.68,2.74c-.33-1.61-.69-4.51-.15-7.99,0-.03,.02-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M1202.12,273.49l-3.98,2.34s-.06,.02-.09,0c-3.23-1.28-5.5-3.15-6.67-4.26,1.48-.8,2.96-1.61,4.4-2.48,1.06,1.03,3.17,2.83,6.33,4.22,.08,.03,.08,.14,.01,.18Z" />
					<path d="M1213.27,266.63l-3.9,2.46s-.06,.02-.09,0c-3.28-1.15-5.58-2.88-6.8-3.95l4.25-2.68c1.1,.99,3.29,2.71,6.52,3.98,.08,.03,.09,.13,.02,.18Z" />
					<path d="M1196.45,276.78l-4.05,2.22s-.06,.02-.09,0c-1.02-.43-1.94-.91-2.75-1.4l-2.09-3.89,2.71-1.48c1.04,1.05,3.13,2.9,6.27,4.37,.07,.04,.08,.14,0,.18Z" />
					<path d="M1207.88,261.74l1.65-1.04,2.4,3.71c-1.84-.93-3.21-1.95-4.06-2.66Z" />
					<path d="M1207.73,270.12c-1.29,.84-2.62,1.61-3.95,2.39-.03,.02-.06,.02-.09,0-3.27-1.24-5.55-3.03-6.74-4.12l4.35-2.55c1.08,1.01,3.25,2.8,6.41,4.1,.08,.03,.09,.14,.02,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1168.13,292.86s.03-.09,.08-.11c1.39-.62,2.77-1.24,4.14-1.91,.09-.04,.19,.03,.19,.13l-.06,.76c-.02,.24,.16,.45,.39,.47l.65,.05c.24,.02,.44-.16,.46-.4l.14-1.78s.03-.09,.07-.11l4.11-1.97c.09-.04,.19,.03,.19,.13l-.05,.75c-.02,.24,.16,.45,.4,.46l.65,.05c.24,.02,.45-.16,.46-.4l.11-1.77s.03-.09,.07-.11l4.05-2.07c.09-.05,.19,.02,.19,.12l-.04,.75c-.01,.24,.17,.44,.41,.46l.65,.04c.24,.01,.44-.17,.45-.41l.08-1.78s.03-.09,.07-.11c1.34-.7,2.72-1.35,4.04-2.1,.09-.05,.19,.02,.19,.12l-.02,.76c0,.24,.18,.44,.42,.45l.65,.02c.24,0,.44-.18,.44-.42l.03-1.25,2.81,5.23-1.06-.65c-.2-.12-.47-.06-.6,.15l-.35,.56c-.13,.21-.06,.47,.14,.6l.64,.4c.09,.05,.08,.18,0,.23-1.35,.76-2.74,1.42-4.11,2.14-.04,.02-.09,.02-.13,0l-1.51-.97c-.2-.13-.47-.07-.6,.13l-.36,.55c-.13,.2-.07,.47,.13,.6l.64,.41c.08,.05,.08,.18-.01,.23l-4.13,2.11s-.09,.02-.13,0l-1.49-1c-.2-.13-.47-.08-.61,.11l-.37,.54c-.14,.2-.08,.47,.12,.6l.63,.42c.08,.06,.08,.18-.02,.23l-4.18,2s-.09,.02-.13,0l-1.47-1.01c-.2-.14-.47-.09-.6,.11l-.38,.54c-.14,.2-.09,.47,.11,.6l.62,.43c.08,.06,.07,.18-.02,.23-1.39,.68-2.8,1.31-4.2,1.94-.04,.02-.09,.01-.13-.01l-.44-.31-2.46-5.41,.05-.54Z" />
				<g>
					<path d="M1179.2,297.02l4.28-2.05c.08-.04,.16,.03,.14,.11-.63,3.41-.39,6.2-.16,7.67l-4.85,2.32c-.19-1.63-.31-4.55,.53-7.98,0-.03,.03-.06,.06-.07Z" />
					<path d="M1191.26,291.01c1.42-.71,2.81-1.46,4.2-2.23,.07-.04,.16,.02,.15,.1-.49,3.46-.16,6.27,.12,7.71l-4.8,2.45c-.24-1.62-.45-4.53,.29-7.97,0-.03,.03-.06,.05-.07Z" />
					<path d="M1173.12,299.9c1.45-.63,2.86-1.35,4.29-2.02,.08-.04,.16,.03,.14,.11-.67,3.42-.47,6.24-.26,7.71l-2.99,1.35-1.83-4.02c.12-.96,.31-1.98,.59-3.06,0-.03,.03-.06,.06-.07Z" />
					<path d="M1198.87,294.9l-1.85,1.01c-.18-1.11-.36-2.82-.25-4.9l2.09,3.89Z" />
					<path d="M1185.26,294.08l4.23-2.16c.07-.04,.16,.02,.15,.11-.56,3.43-.28,6.23-.02,7.7-1.6,.82-3.2,1.64-4.83,2.4-.22-1.63-.35-4.5,.42-7.97,0-.03,.03-.06,.05-.07Z" />
				</g>
				<g>
					<path d="M1179.03,285.96l-4.18,2s-.06,.01-.09,0c-3.15-1.52-5.26-3.49-6.36-4.68l4.58-2.19c.99,1.1,2.98,3.06,6.04,4.7,.07,.04,.07,.14,0,.18Z" />
					<path d="M1190.69,279.92c-1.35,.75-2.7,1.48-4.08,2.17-.03,.01-.06,.01-.09,0-3.2-1.43-5.38-3.34-6.52-4.5l4.51-2.3c1.03,1.08,3.07,2.95,6.17,4.45,.07,.04,.08,.14,0,.18Z" />
					<path d="M1173.1,288.79c-1.4,.66-2.77,1.36-4.19,1.97-.03,.01-.06,.01-.09,0-.99-.5-1.88-1.05-2.67-1.6l-1.83-4.02,2.84-1.28c.97,1.11,2.92,3.09,5.94,4.74,.07,.04,.07,.14,0,.18Z" />
					<path d="M1185.74,274.65l1.72-.94,2.09,3.89c-1.75-1.06-3.03-2.17-3.82-2.95Z" />
					<path d="M1184.88,282.97l-4.12,2.1s-.06,.01-.09,0c-3.15-1.43-5.32-3.43-6.44-4.6,1.54-.72,3.04-1.48,4.54-2.26,1,1.09,3.02,3,6.1,4.57,.07,.04,.08,.14,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1143.79,303.15s.04-.09,.08-.1l4.24-1.69c.09-.04,.19,.04,.18,.14l-.1,.75c-.03,.24,.13,.46,.37,.49l.65,.09c.24,.03,.46-.13,.49-.37l.24-1.76s.04-.09,.08-.1c1.42-.55,2.82-1.15,4.22-1.74,.09-.04,.19,.04,.18,.14l-.09,.75c-.03,.24,.14,.45,.37,.49l.65,.08c.24,.03,.45-.14,.48-.38l.22-1.77s.04-.09,.08-.1l4.2-1.78c.09-.04,.19,.04,.18,.14l-.09,.75c-.03,.24,.14,.45,.38,.48l.65,.08c.24,.03,.45-.14,.48-.38l.19-1.78s.03-.09,.08-.11l4.16-1.87c.09-.04,.19,.03,.18,.13l-.07,.75c-.02,.24,.15,.45,.39,.48l.65,.07c.24,.02,.45-.15,.47-.39l.12-1.24,2.46,5.41-1.02-.73c-.2-.14-.47-.1-.61,.1l-.39,.53c-.14,.19-.1,.47,.1,.61l.62,.44c.08,.06,.07,.18-.02,.23l-4.22,1.9s-.09,.01-.13-.01l-1.44-1.05c-.19-.14-.47-.1-.61,.09l-.39,.52c-.14,.19-.1,.46,.09,.61l.61,.45c.08,.06,.07,.19-.03,.22l-4.26,1.8s-.09,.01-.13-.02l-1.43-1.08c-.19-.14-.46-.11-.61,.08l-.4,.53c-.15,.19-.11,.46,.08,.61l.6,.46c.08,.06,.06,.19-.03,.23-1.42,.6-2.84,1.21-4.27,1.77-.04,.02-.09,.01-.13-.02l-1.42-1.1c-.19-.15-.46-.12-.61,.07l-.41,.52c-.15,.19-.12,.46,.07,.61l.6,.47c.08,.06,.06,.19-.03,.22l-4.29,1.71s-.09,.01-.13-.02l-.42-.33-2.16-5.53,.08-.53Z" />
				<g>
					<path d="M1154.62,307.95l4.36-1.84c.08-.03,.16,.04,.14,.12-.82,3.39-.74,6.2-.59,7.68-1.64,.7-3.31,1.33-4.96,2-.1-1.63-.05-4.55,.99-7.9,0-.03,.03-.05,.06-.07Z" />
					<path d="M1166.98,302.66l4.32-1.94c.08-.03,.16,.03,.14,.11-.73,3.4-.57,6.2-.38,7.68-1.64,.72-3.26,1.49-4.91,2.17-.14-1.62-.16-4.53,.77-7.95,0-.03,.03-.06,.06-.07Z" />
					<path d="M1148.38,310.44l4.39-1.75c.08-.03,.16,.04,.14,.12-.87,3.36-.82,6.17-.69,7.65l-3.04,1.21-1.61-4.11c.17-.95,.41-1.98,.75-3.04,0-.03,.03-.05,.06-.07Z" />
					<path d="M1174.3,307.05l-1.9,.86c-.11-1.11-.18-2.82,.07-4.88l1.83,4.02Z" />
					<path d="M1160.81,305.33c1.45-.62,2.91-1.22,4.36-1.86,.08-.03,.16,.03,.14,.11-.77,3.4-.66,6.18-.49,7.66l-4.94,2.09c-.12-1.63-.11-4.56,.88-7.94,0-.03,.03-.05,.06-.07Z" />
				</g>
				<g>
					<path d="M1155.08,296.84l-4.27,1.8s-.06,0-.09,0c-3.07-1.71-5.07-3.81-6.11-5.06,1.58-.64,3.17-1.25,4.74-1.91,.92,1.16,2.79,3.21,5.75,5,.07,.04,.06,.15-.01,.18Z" />
					<path d="M1167.14,291.56l-4.23,1.9s-.06,.01-.09,0c-3.09-1.59-5.15-3.64-6.23-4.87,1.58-.65,3.1-1.38,4.66-2.06,.95,1.13,2.88,3.14,5.89,4.86,.07,.04,.07,.15,0,.18Z" />
					<path d="M1148.99,299.36l-4.31,1.72s-.06,0-.09,0c-.96-.55-1.82-1.13-2.57-1.72l-1.61-4.11,2.9-1.16c.91,1.17,2.76,3.25,5.69,5.1,.07,.04,.06,.15-.01,.18Z" />
					<path d="M1162.52,285.96l1.81-.81,1.83,4.02c-1.69-1.18-2.9-2.38-3.64-3.21Z" />
					<path d="M1161.14,294.26c-1.41,.62-2.85,1.21-4.27,1.82-.03,.01-.06,.01-.09,0-3.08-1.66-5.11-3.72-6.16-4.96l4.7-1.99c.93,1.14,2.85,3.2,5.83,4.95,.07,.04,.07,.15,0,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1118.94,312.24s.04-.08,.09-.1l4.32-1.48c.09-.03,.19,.05,.17,.15l-.14,.74c-.04,.24,.11,.46,.34,.51l.64,.12c.23,.04,.46-.11,.5-.35l.32-1.76s.04-.08,.08-.1l4.3-1.54c.09-.03,.19,.05,.17,.15l-.13,.74c-.04,.24,.11,.46,.35,.5l.65,.12c.23,.04,.46-.11,.5-.35l.31-1.75s.04-.08,.08-.1c1.44-.5,2.85-1.07,4.28-1.6,.09-.04,.19,.04,.17,.14l-.12,.75c-.04,.24,.12,.46,.35,.5l.65,.11c.24,.04,.46-.12,.5-.36l.28-1.76s.04-.08,.08-.1l4.27-1.61c.09-.04,.19,.04,.18,.14l-.11,.74c-.04,.24,.12,.46,.36,.5l.65,.1c.24,.04,.45-.13,.49-.37l.18-1.24,2.16,5.53-.97-.77c-.19-.15-.46-.12-.61,.07l-.41,.51c-.15,.19-.12,.46,.06,.61l.59,.48c.08,.06,.06,.19-.04,.22l-4.32,1.63s-.09,0-.13-.02l-1.39-1.13c-.19-.15-.46-.12-.61,.06l-.42,.51c-.15,.19-.13,.46,.06,.61l.58,.48c.08,.06,.06,.19-.04,.22-1.44,.54-2.88,1.11-4.33,1.62-.04,.02-.09,0-.13-.02l-1.37-1.15c-.18-.15-.46-.13-.61,.05l-.42,.5c-.15,.18-.13,.46,.05,.61l.58,.49c.08,.07,.06,.19-.04,.22l-4.35,1.56s-.09,0-.13-.02l-1.36-1.16c-.18-.16-.46-.13-.61,.05l-.43,.5c-.16,.18-.14,.46,.05,.61l.57,.49c.08,.07,.05,.19-.04,.22l-4.37,1.5s-.09,0-.13-.03l-.41-.36-1.92-5.62,.1-.53Z" />
				<g>
					<path d="M1129.53,317.51l4.45-1.59c.08-.03,.15,.04,.13,.12-.98,3.33-1.03,6.13-.95,7.61l-5.02,1.8c-.03-1.64,.15-4.56,1.33-7.88,.01-.03,.03-.05,.06-.06Z" />
					<path d="M1142.12,312.87c1.47-.56,2.95-1.1,4.41-1.7,.08-.03,.16,.04,.13,.12-.9,3.38-.9,6.16-.78,7.64l-5,1.89c-.06-1.63,.05-4.55,1.17-7.89,0-.03,.03-.05,.06-.06Z" />
					<path d="M1123.2,319.73c1.49-.5,2.98-1.01,4.46-1.55,.08-.03,.15,.05,.13,.12-1.01,3.34-1.09,6.14-1.03,7.62l-3.08,1.05-1.43-4.18c.21-.94,.49-1.95,.88-2.99,.01-.03,.03-.05,.06-.06Z" />
					<path d="M1149.18,317.66c-.65,.25-1.29,.52-1.93,.76-.05-1.11-.03-2.83,.32-4.87l1.61,4.11Z" />
					<path d="M1135.84,315.24l4.42-1.67c.08-.03,.15,.04,.13,.12-.94,3.35-.96,6.16-.86,7.64-1.66,.64-3.34,1.23-5.01,1.83-.04-1.63,.1-4.54,1.26-7.86,.01-.03,.03-.05,.06-.06Z" />
				</g>
				<g>
					<path d="M1130.53,306.47l-4.38,1.57s-.06,0-.09,0c-2.98-1.83-4.89-4-5.86-5.3l4.83-1.73c.87,1.2,2.65,3.35,5.52,5.29,.07,.05,.06,.15-.02,.18Z" />
					<path d="M1142.87,301.81c-1.43,.59-2.89,1.11-4.34,1.67-.03,.01-.06,0-.09,0-3.02-1.77-4.98-3.91-5.98-5.19l4.79-1.81c.89,1.18,2.73,3.31,5.63,5.16,.07,.04,.06,.15-.01,.18Z" />
					<path d="M1124.32,308.69c-1.46,.53-2.92,1.03-4.39,1.53-.03,0-.06,0-.09-.01-.94-.59-1.77-1.22-2.5-1.84l-1.43-4.18,2.97-1.01c.86,1.21,2.62,3.39,5.46,5.34,.07,.05,.06,.15-.02,.18Z" />
					<path d="M1138.56,295.97c.62-.23,1.23-.49,1.85-.73l1.61,4.11c-1.62-1.26-2.76-2.51-3.46-3.38Z" />
					<path d="M1136.71,304.16l-4.35,1.64s-.06,0-.09,0c-3-1.81-4.94-3.98-5.93-5.27,1.61-.58,3.23-1.14,4.82-1.76,.88,1.19,2.68,3.31,5.57,5.21,.07,.05,.06,.15-.02,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1093.72,320.36s.04-.08,.09-.09l4.37-1.35c.1-.03,.19,.06,.17,.15l-.17,.74c-.05,.23,.09,.47,.33,.52l.64,.15c.23,.05,.46-.09,.52-.33l.39-1.74s.04-.08,.09-.1l4.36-1.36c.1-.03,.19,.05,.17,.15l-.16,.74c-.05,.23,.1,.47,.33,.52l.64,.14c.23,.05,.46-.1,.51-.33l.37-1.75s.04-.08,.09-.1l4.35-1.41c.1-.03,.19,.05,.17,.15l-.15,.74c-.05,.23,.1,.46,.33,.51l.64,.14c.23,.05,.46-.1,.51-.33l.36-1.74s.04-.08,.09-.1l4.33-1.47c.1-.03,.19,.05,.17,.15l-.15,.74c-.05,.23,.11,.46,.34,.51l.64,.13c.23,.05,.46-.11,.51-.34l.24-1.22,1.92,5.62-.94-.82c-.18-.16-.45-.14-.61,.04l-.43,.49c-.16,.18-.14,.45,.04,.61l.57,.5c.08,.07,.05,.19-.04,.22l-4.37,1.48s-.09,0-.13-.03l-1.34-1.19c-.18-.16-.45-.15-.61,.03l-.44,.49c-.16,.18-.15,.45,.03,.61l.56,.51c.08,.07,.05,.19-.05,.22l-4.39,1.42s-.09,0-.13-.03l-1.32-1.2c-.18-.16-.45-.15-.61,.03l-.44,.49c-.16,.18-.15,.45,.03,.61l.56,.51c.07,.07,.05,.19-.05,.22l-4.4,1.38s-.09,0-.13-.03l-1.32-1.21c-.18-.16-.45-.15-.61,.02l-.45,.48c-.16,.18-.15,.45,.02,.61l.56,.51c.07,.07,.05,.19-.05,.22l-4.41,1.36s-.09,0-.13-.03l-.39-.37-1.72-5.69,.12-.52Z" />
				<g>
					<path d="M1104.11,326.01c1.5-.46,3-.94,4.5-1.43,.08-.03,.15,.05,.13,.13-1.1,3.3-1.26,6.11-1.24,7.58l-5.07,1.57c.03-1.63,.31-4.54,1.62-7.8,.01-.03,.04-.05,.06-.06Z" />
					<path d="M1116.85,321.89l4.47-1.52c.08-.03,.15,.05,.13,.13-1.05,3.31-1.15,6.12-1.1,7.6-1.68,.58-3.36,1.13-5.05,1.67,0-1.64,.24-4.52,1.49-7.82,.01-.03,.03-.05,.06-.06Z" />
					<path d="M1097.71,327.99l4.51-1.4c.08-.02,.15,.05,.12,.13-1.14,3.28-1.31,6.08-1.3,7.57l-3.1,.95-1.28-4.23c.24-.93,.56-1.93,.98-2.97,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1123.68,326.96l-1.96,.67c0-1.12,.08-2.83,.53-4.85l1.43,4.18Z" />
					<path d="M1110.49,323.97l4.49-1.46c.08-.03,.15,.05,.13,.13-1.08,3.29-1.21,6.09-1.17,7.58l-5.06,1.64c.02-1.64,.27-4.55,1.55-7.83,.01-.03,.04-.05,.06-.06Z" />
				</g>
				<g>
					<path d="M1105.53,315c-1.47,.48-2.95,.96-4.44,1.41-.03,0-.06,0-.09-.01-2.91-1.95-4.75-4.2-5.68-5.53l4.92-1.52c.82,1.24,2.52,3.45,5.31,5.49,.07,.05,.05,.15-.03,.18Z" />
					<path d="M1118.08,310.85l-4.4,1.5s-.06,0-.09-.01c-2.94-1.89-4.82-4.12-5.77-5.44,1.63-.52,3.26-1.06,4.88-1.62,.84,1.22,2.58,3.39,5.41,5.39,.07,.05,.05,.15-.02,.18Z" />
					<path d="M1099.23,316.99l-4.45,1.38s-.06,0-.09-.01c-.92-.62-1.73-1.27-2.44-1.92l-1.28-4.23,3.01-.92c.81,1.24,2.5,3.46,5.27,5.53,.07,.05,.05,.15-.03,.18Z" />
					<path d="M1114.02,304.83l1.89-.64,1.43,4.18c-1.57-1.34-2.66-2.64-3.32-3.54Z" />
					<path d="M1111.82,312.96l-4.43,1.44s-.06,0-.09-.01c-2.93-1.92-4.78-4.15-5.72-5.47l4.89-1.59c.83,1.23,2.55,3.42,5.37,5.46,.07,.05,.05,.15-.03,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1068.23,327.67s.05-.08,.09-.09l4.41-1.22c.1-.03,.19,.06,.16,.16l-.19,.73c-.06,.23,.08,.47,.31,.53l.64,.17c.23,.06,.47-.08,.53-.31l.44-1.73s.05-.08,.09-.09l4.4-1.25c.1-.03,.19,.06,.16,.16l-.18,.73c-.06,.23,.08,.47,.31,.53l.64,.16c.23,.06,.47-.08,.52-.31l.43-1.73s.05-.08,.09-.09l4.4-1.27c.1-.03,.19,.06,.16,.16l-.18,.74c-.06,.23,.09,.47,.32,.52l.64,.15c.23,.06,.47-.09,.52-.32l.42-1.74s.04-.08,.09-.1l4.39-1.3c.1-.03,.19,.06,.17,.16l-.17,.73c-.05,.23,.09,.47,.32,.52l.64,.15c.23,.06,.47-.09,.52-.32l.28-1.21,1.72,5.69-.91-.86c-.17-.16-.45-.16-.61,.02l-.45,.48c-.16,.17-.16,.45,.02,.61l.55,.52c.07,.07,.04,.19-.05,.22l-4.42,1.31s-.09,0-.13-.03l-1.29-1.23c-.17-.16-.45-.16-.61,.02l-.45,.48c-.17,.17-.16,.45,.01,.61l.55,.52c.07,.07,.04,.19-.05,.22l-4.43,1.27s-.09,0-.13-.03l-1.29-1.24c-.17-.17-.45-.16-.61,.01l-.46,.47c-.17,.17-.16,.45,0,.61l.54,.53c.07,.07,.04,.19-.06,.22l-4.43,1.26s-.09,0-.13-.03l-1.28-1.25c-.17-.17-.45-.17-.61,0l-.46,.47c-.17,.17-.17,.44,0,.61l.54,.53c.07,.07,.04,.19-.06,.22l-4.44,1.22s-.09,0-.13-.03l-.38-.38-1.56-5.73,.14-.52Z" />
				<g>
					<path d="M1078.45,333.62l4.53-1.29c.08-.02,.15,.05,.12,.13-1.21,3.26-1.44,6.05-1.46,7.54l-5.09,1.42c.08-1.63,.44-4.51,1.84-7.74,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1091.3,329.91l4.52-1.34c.08-.02,.15,.05,.12,.13-1.16,3.27-1.36,6.06-1.36,7.54l-5.08,1.51c.06-1.63,.38-4.54,1.73-7.78,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1072,335.41l4.54-1.25c.08-.02,.15,.06,.12,.13-1.23,3.24-1.48,6.03-1.51,7.52l-3.12,.86-1.16-4.26c.26-.93,.61-1.92,1.06-2.94,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1097.94,335.24l-1.98,.59c.03-1.11,.18-2.82,.7-4.82l1.28,4.23Z" />
					<path d="M1084.88,331.79c1.51-.43,3.02-.87,4.52-1.32,.08-.02,.15,.05,.12,.13-1.18,3.27-1.4,6.07-1.41,7.55l-5.09,1.45c.07-1.63,.41-4.53,1.79-7.76,.01-.03,.04-.05,.07-.06Z" />
				</g>
				<g>
					<path d="M1080.21,322.66l-4.48,1.28s-.06,0-.09-.01c-2.85-2.03-4.63-4.34-5.53-5.7l4.98-1.39c.78,1.26,2.42,3.52,5.15,5.65,.07,.05,.05,.15-.03,.18Z" />
					<path d="M1092.91,318.94l-4.47,1.33s-.06,0-.09-.01c-2.88-1.99-4.68-4.26-5.59-5.61l4.95-1.47c.8,1.25,2.47,3.49,5.23,5.59,.07,.05,.05,.15-.03,.18Z" />
					<path d="M1073.84,324.47l-4.5,1.24s-.06,0-.09-.02c-.9-.65-1.7-1.32-2.39-1.99l-1.16-4.26,3.05-.84c.78,1.26,2.4,3.53,5.12,5.69,.06,.05,.05,.15-.03,.18Z" />
					<path d="M1089.05,312.77l1.92-.57,1.28,4.23c-1.52-1.39-2.57-2.74-3.2-3.66Z" />
					<path d="M1086.57,320.82c-1.49,.45-2.98,.88-4.48,1.3-.03,0-.06,0-.09-.02-2.87-2.02-4.66-4.3-5.56-5.66l4.97-1.42c.79,1.26,2.45,3.51,5.19,5.61,.07,.05,.05,.15-.03,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1042.55,334.4s.05-.08,.09-.09l4.44-1.13c.1-.02,.19,.07,.16,.16l-.2,.73c-.06,.23,.07,.47,.3,.53l.63,.18c.23,.06,.47-.07,.53-.3l.48-1.72s.05-.08,.09-.09l4.44-1.15c.1-.03,.19,.06,.16,.16l-.2,.73c-.06,.23,.07,.47,.3,.53l.63,.18c.23,.06,.47-.07,.53-.3l.47-1.72s.05-.08,.09-.09l4.43-1.17c.1-.03,.19,.06,.16,.16l-.2,.73c-.06,.23,.07,.47,.3,.53l.63,.17c.23,.06,.47-.07,.53-.31l.46-1.73s.05-.08,.09-.09l4.43-1.18c.1-.03,.19,.06,.16,.16l-.19,.73c-.06,.23,.08,.47,.31,.53l.63,.17c.23,.06,.47-.08,.53-.31l.32-1.21,1.56,5.73-.89-.88c-.17-.17-.44-.17-.61,0l-.46,.47c-.17,.17-.17,.44,0,.61l.54,.53c.07,.07,.04,.19-.06,.22l-4.45,1.19s-.09,0-.13-.03l-1.26-1.27c-.17-.17-.44-.17-.61,0l-.47,.46c-.17,.17-.17,.44,0,.61l.53,.54c.07,.07,.04,.19-.06,.22l-4.45,1.18s-.09,0-.13-.03l-1.26-1.27c-.17-.17-.44-.17-.61,0l-.47,.46c-.17,.17-.17,.44,0,.61l.53,.54c.07,.07,.04,.19-.06,.22l-4.46,1.16s-.09,0-.13-.04l-1.25-1.28c-.17-.17-.44-.17-.61,0l-.47,.46c-.17,.17-.17,.44,0,.61l.53,.54c.07,.07,.04,.19-.06,.22l-4.46,1.14s-.09,0-.13-.04l-.38-.39-1.45-5.76,.15-.52Z" />
				<g>
					<path d="M1052.64,340.56l4.55-1.18c.08-.02,.15,.06,.12,.14-1.28,3.23-1.57,6.01-1.62,7.5l-5.11,1.31c.11-1.63,.53-4.51,1.99-7.7,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1065.56,337.16l4.54-1.23c.08-.02,.15,.06,.12,.13-1.24,3.25-1.51,6.03-1.55,7.51l-5.1,1.36c.09-1.63,.48-4.52,1.92-7.72,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1046.17,342.21l4.56-1.16c.08-.02,.15,.06,.12,.14-1.29,3.22-1.6,6-1.66,7.49l-3.12,.79-1.08-4.28c.28-.92,.65-1.9,1.12-2.91,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1072.04,342.66l-1.98,.54c.06-1.11,.25-2.81,.82-4.81l1.16,4.26Z" />
					<path d="M1059.1,338.88l4.55-1.21c.08-.02,.15,.06,.12,.13-1.26,3.23-1.54,6.02-1.59,7.51l-5.1,1.34c.1-1.63,.5-4.51,1.96-7.71,.01-.03,.04-.05,.07-.06Z" />
				</g>
				<g>
					<path d="M1054.65,329.65l-4.52,1.18s-.06,0-.09-.02c-2.81-2.09-4.54-4.43-5.41-5.81l5.02-1.29c.76,1.27,2.35,3.57,5.03,5.76,.06,.05,.04,.16-.04,.18Z" />
					<path d="M1067.45,326.23l-4.51,1.22s-.06,0-.09-.02c-2.83-2.07-4.58-4.38-5.46-5.75l5.01-1.34c.77,1.27,2.39,3.56,5.08,5.71,.06,.05,.04,.15-.03,.18Z" />
					<path d="M1048.23,331.31l-4.53,1.15s-.06,0-.09-.02c-.89-.67-1.67-1.36-2.35-2.04l-1.08-4.28,3.07-.78c.75,1.28,2.33,3.58,5,5.79,.06,.05,.04,.16-.04,.18Z" />
					<path d="M1063.77,319.98l1.94-.53,1.16,4.26c-1.48-1.43-2.5-2.79-3.1-3.73Z" />
					<path d="M1061.06,327.95l-4.51,1.2s-.06,0-.09-.02c-2.83-2.08-4.56-4.41-5.44-5.78l5.02-1.31c.76,1.27,2.36,3.56,5.05,5.74,.06,.05,.04,.15-.04,.18Z" />
				</g>
			</g>
			<g>
				<path d="M1016.74,340.63s.05-.08,.1-.09l4.46-1.04c.1-.02,.18,.07,.16,.17l-.22,.72c-.07,.23,.06,.47,.29,.54l.63,.19c.23,.07,.47-.06,.54-.29l.51-1.71s.05-.08,.09-.09l4.46-1.06c.1-.02,.18,.07,.16,.16l-.21,.72c-.07,.23,.06,.47,.29,.54l.63,.19c.23,.07,.47-.06,.54-.29l.5-1.72s.05-.08,.09-.09l4.45-1.08c.1-.02,.19,.07,.16,.16l-.21,.73c-.07,.23,.06,.47,.29,.54l.63,.19c.23,.07,.47-.06,.54-.29l.49-1.72s.05-.08,.09-.09l4.45-1.11c.1-.02,.19,.07,.16,.16l-.21,.73c-.07,.23,.07,.47,.3,.54l.63,.18c.23,.07,.47-.07,.53-.3l.34-1.2,1.45,5.76-.87-.9c-.17-.17-.44-.18-.61-.01l-.47,.46c-.17,.17-.18,.44-.01,.61l.53,.54c.07,.07,.04,.19-.06,.22l-4.47,1.11s-.09,0-.13-.04l-1.24-1.29c-.17-.17-.44-.18-.61-.01l-.48,.45c-.17,.17-.18,.44-.01,.61l.52,.55c.07,.07,.03,.19-.06,.22l-4.47,1.09s-.09,0-.13-.04l-1.23-1.29c-.16-.17-.44-.18-.61-.01l-.48,.45c-.17,.16-.18,.44-.02,.61l.52,.55c.07,.07,.03,.19-.06,.22l-4.48,1.06s-.09,0-.13-.04l-1.23-1.3c-.16-.17-.44-.18-.61-.02l-.48,.45c-.17,.16-.18,.44-.02,.61l.52,.55c.07,.07,.03,.19-.07,.22l-4.48,1.05s-.09,0-.13-.04l-.37-.39-1.34-5.79,.16-.52Z" />
				<g>
					<path d="M1026.72,346.99l4.57-1.1c.08-.02,.15,.06,.12,.14-1.34,3.21-1.68,5.98-1.76,7.46l-5.13,1.22c.14-1.63,.61-4.5,2.13-7.67,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1039.69,343.83l4.56-1.14c.08-.02,.15,.06,.12,.14-1.31,3.21-1.63,5.99-1.69,7.48l-5.12,1.27c.12-1.62,.57-4.5,2.06-7.69,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1020.22,348.52l4.58-1.08c.08-.02,.15,.06,.12,.14-1.35,3.2-1.71,5.98-1.8,7.46l-3.14,.73-1-4.3c.3-.91,.68-1.89,1.17-2.89,.01-.03,.04-.05,.07-.06Z" />
					<path d="M1046.06,349.47l-1.99,.5c.08-1.11,.31-2.8,.91-4.78l1.08,4.28Z" />
					<path d="M1033.21,345.43l4.57-1.11c.08-.02,.15,.06,.12,.14-1.32,3.21-1.66,5.99-1.73,7.47l-5.13,1.24c.13-1.63,.59-4.51,2.1-7.68,.01-.03,.04-.05,.07-.06Z" />
				</g>
				<g>
					<path d="M1028.93,336.11l-4.54,1.09s-.06,0-.09-.02c-2.77-2.14-4.46-4.51-5.3-5.9l5.05-1.2c.73,1.29,2.28,3.62,4.92,5.86,.06,.05,.04,.16-.04,.18Z" />
					<path d="M1041.8,332.94l-4.53,1.13s-.06,0-.09-.02c-2.79-2.11-4.5-4.46-5.35-5.85l5.03-1.25c.75,1.28,2.32,3.59,4.98,5.82,.06,.05,.04,.16-.04,.18Z" />
					<path d="M1022.48,337.66l-4.55,1.07s-.06,0-.09-.02c-.88-.69-1.65-1.39-2.31-2.08l-1-4.3,3.09-.72c.73,1.29,2.27,3.62,4.89,5.88,.06,.05,.04,.16-.04,.18Z" />
					<path d="M1038.23,326.61l1.96-.49,1.08,4.28c-1.46-1.46-2.45-2.85-3.03-3.8Z" />
					<path d="M1035.37,334.54l-4.54,1.11s-.06,0-.09-.02c-2.78-2.13-4.48-4.48-5.33-5.88l5.04-1.22c.74,1.28,2.3,3.61,4.95,5.84,.06,.05,.04,.16-.04,.18Z" />
				</g>
			</g>
			<g>
				<path d="M990.82,346.4s.05-.08,.1-.09l4.48-.96c.1-.02,.18,.07,.15,.17l-.23,.72c-.07,.23,.05,.47,.28,.55l.62,.2c.23,.07,.47-.05,.54-.28l.54-1.7s.05-.08,.1-.09l4.48-.98c.1-.02,.18,.07,.15,.17l-.23,.72c-.07,.23,.05,.47,.28,.54l.63,.2c.23,.07,.47-.05,.54-.28l.53-1.7s.05-.08,.1-.09l4.47-1c.1-.02,.18,.07,.15,.17l-.22,.72c-.07,.23,.06,.47,.28,.54l.63,.2c.23,.07,.47-.06,.54-.29l.53-1.71s.05-.08,.1-.09l4.47-1.02c.1-.02,.18,.07,.15,.17l-.22,.72c-.07,.23,.06,.47,.29,.54l.63,.19c.23,.07,.47-.06,.54-.29l.36-1.19,1.34,5.79-.85-.91c-.16-.17-.44-.18-.61-.02l-.48,.45c-.18,.16-.19,.44-.02,.61l.52,.55c.07,.07,.03,.19-.07,.22l-4.49,1.03s-.09,0-.13-.04l-1.22-1.31c-.16-.18-.44-.19-.61-.02l-.48,.45c-.18,.16-.19,.44-.02,.61l.51,.56c.07,.07,.03,.19-.07,.22l-4.49,1.01s-.09,0-.13-.04l-1.21-1.32c-.16-.18-.44-.19-.61-.03l-.48,.44c-.18,.16-.19,.44-.03,.61l.51,.56c.07,.07,.03,.19-.07,.22l-4.5,.99s-.09,0-.13-.04l-1.2-1.32c-.16-.18-.44-.19-.61-.03l-.49,.44c-.18,.16-.19,.43-.03,.61l.51,.56c.07,.07,.03,.19-.07,.22l-4.5,.97s-.09,0-.12-.04l-.36-.4-1.24-5.81,.17-.51Z" />
				<g>
					<path d="M1000.68,352.94l4.59-1.01c.08-.02,.15,.06,.11,.14-1.4,3.18-1.79,5.95-1.9,7.43l-5.15,1.12c.17-1.62,.69-4.48,2.28-7.62,.01-.03,.04-.05,.07-.05Z" />
					<path d="M1013.71,350.02l4.58-1.06c.08-.02,.15,.06,.11,.14-1.37,3.19-1.74,5.97-1.83,7.45l-5.14,1.17c.15-1.63,.65-4.49,2.21-7.64,.01-.03,.04-.05,.07-.05Z" />
					<path d="M994.15,354.35l4.6-.99c.08-.02,.15,.06,.11,.14-1.41,3.17-1.82,5.94-1.93,7.42l-3.15,.68-.92-4.32c.32-.91,.72-1.88,1.22-2.87,.01-.03,.04-.05,.07-.05Z" />
					<path d="M1019.97,355.77l-2,.46c.1-1.11,.36-2.8,1-4.77l1,4.3Z" />
					<path d="M1007.2,351.5l4.59-1.04c.08-.02,.15,.06,.11,.14-1.38,3.18-1.77,5.96-1.86,7.44l-5.15,1.14c.16-1.62,.67-4.49,2.24-7.63,.01-.03,.04-.05,.07-.05Z" />
				</g>
				<g>
					<path d="M1003.09,342.1l-4.56,1.01s-.06,0-.09-.02c-2.73-2.19-4.38-4.6-5.19-6.01l5.07-1.1c.71,1.3,2.22,3.65,4.81,5.95,.06,.05,.04,.16-.04,.17Z" />
					<path d="M1016.02,339.17l-4.55,1.05s-.06,0-.09-.02c-2.76-2.17-4.42-4.55-5.25-5.96l5.06-1.15c.72,1.29,2.25,3.63,4.87,5.9,.06,.05,.04,.16-.04,.17Z" />
					<path d="M996.61,343.53l-4.57,.98s-.06,0-.09-.02c-.86-.7-1.62-1.42-2.27-2.12l-.92-4.32,3.1-.67c.7,1.3,2.2,3.66,4.79,5.97,.06,.05,.04,.16-.04,.17Z" />
					<path d="M1012.57,332.78l1.96-.46,1,4.3c-1.43-1.48-2.39-2.89-2.96-3.85Z" />
					<path d="M1009.56,340.65l-4.56,1.03s-.06,0-.09-.02c-2.75-2.19-4.4-4.58-5.22-5.98l5.06-1.12c.72,1.3,2.23,3.64,4.84,5.92,.06,.05,.04,.16-.04,.17Z" />
				</g>
			</g>
			<g>
				<path d="M964.8,351.69s.05-.08,.1-.09l4.5-.88c.1-.02,.18,.08,.15,.17l-.24,.72c-.08,.23,.04,.47,.27,.55l.62,.21c.23,.08,.47-.04,.55-.27l.57-1.69s.05-.08,.1-.09l4.49-.9c.1-.02,.18,.07,.15,.17l-.24,.72c-.08,.23,.05,.47,.27,.55l.62,.21c.23,.08,.47-.05,.55-.27l.56-1.7s.05-.08,.1-.09l4.49-.92c.1-.02,.18,.07,.15,.17l-.24,.72c-.08,.23,.05,.47,.27,.55l.62,.21c.23,.08,.47-.05,.55-.28l.56-1.7s.05-.08,.1-.09l4.48-.94c.1-.02,.18,.07,.15,.17l-.23,.72c-.07,.23,.05,.47,.28,.55l.62,.2c.23,.07,.47-.05,.55-.28l.38-1.18,1.24,5.81-.84-.93c-.16-.18-.43-.19-.61-.03l-.49,.44c-.18,.16-.19,.43-.03,.61l.51,.56c.07,.08,.03,.2-.07,.22l-4.51,.95s-.09,0-.12-.04l-1.19-1.33c-.16-.18-.43-.19-.61-.04l-.49,.44c-.18,.16-.19,.43-.04,.61l.5,.57c.07,.08,.03,.2-.07,.22l-4.51,.92s-.09,0-.12-.04l-1.18-1.34c-.16-.18-.43-.2-.61-.04l-.49,.44c-.18,.16-.2,.43-.04,.61l.5,.57c.07,.08,.03,.2-.07,.22l-4.52,.9s-.09,0-.12-.04l-1.18-1.34c-.16-.18-.43-.2-.61-.04l-.49,.43c-.18,.16-.2,.43-.04,.61l.5,.57c.07,.08,.03,.2-.07,.22l-4.52,.88s-.09,0-.12-.04l-.35-.41-1.13-5.83,.18-.51Z" />
				<g>
					<path d="M974.54,358.4l4.61-.93c.08-.02,.15,.07,.11,.14-1.45,3.16-1.9,5.92-2.03,7.39l-5.17,1.03c.2-1.62,.77-4.48,2.41-7.58,.01-.03,.04-.05,.07-.05Z" />
					<path d="M987.62,355.73l4.6-.97c.08-.02,.15,.07,.11,.14-1.43,3.16-1.85,5.93-1.97,7.41l-5.16,1.08c.18-1.62,.73-4.48,2.35-7.61,.01-.03,.04-.05,.07-.05Z" />
					<path d="M967.99,359.7l4.61-.91c.08-.02,.15,.07,.11,.14-1.47,3.15-1.93,5.91-2.07,7.39l-3.16,.61-.84-4.34c.33-.9,.75-1.86,1.28-2.84,.01-.03,.04-.05,.07-.05Z" />
					<path d="M993.78,361.59l-2.01,.42c.12-1.11,.41-2.79,1.09-4.74l.92,4.32Z" />
					<path d="M981.08,357.08l4.61-.95c.08-.02,.15,.07,.11,.14-1.44,3.16-1.88,5.92-2,7.4l-5.17,1.06c.19-1.62,.75-4.48,2.38-7.6,.01-.03,.04-.05,.07-.05Z" />
				</g>
				<g>
					<path d="M977.15,347.62l-4.58,.92s-.06,0-.09-.02c-2.69-2.24-4.29-4.67-5.08-6.09l5.09-1.01c.69,1.31,2.15,3.7,4.7,6.03,.06,.06,.03,.16-.05,.17Z" />
					<path d="M990.13,344.92l-4.57,.96s-.06,0-.09-.02c-2.71-2.22-4.33-4.63-5.14-6.05l5.08-1.06c.7,1.31,2.18,3.68,4.76,6,.06,.06,.04,.16-.04,.17Z" />
					<path d="M970.65,348.92l-4.58,.9s-.06,0-.09-.02c-.85-.72-1.59-1.45-2.23-2.17l-.84-4.34,3.11-.6c.68,1.32,2.13,3.7,4.68,6.05,.06,.06,.03,.16-.05,.17Z" />
					<path d="M986.8,338.46l1.97-.41,.92,4.32c-1.4-1.51-2.34-2.94-2.89-3.91Z" />
					<path d="M983.64,346.29l-4.58,.94s-.06,0-.09-.02c-2.7-2.23-4.31-4.65-5.11-6.07l5.08-1.04c.69,1.31,2.17,3.69,4.73,6.01,.06,.06,.04,.16-.05,.17Z" />
				</g>
			</g>
			<g>
				<path d="M938.69,356.51s.05-.08,.1-.08l4.51-.8c.1-.02,.18,.08,.15,.17l-.26,.71c-.08,.22,.03,.47,.26,.56l.62,.22c.22,.08,.47-.03,.55-.26l.6-1.68s.05-.08,.1-.08l4.51-.82c.1-.02,.18,.08,.15,.17l-.25,.71c-.08,.23,.04,.47,.26,.55l.62,.22c.23,.08,.47-.04,.55-.26l.6-1.68s.05-.08,.1-.08l4.5-.84c.1-.02,.18,.08,.15,.17l-.25,.71c-.08,.23,.04,.47,.26,.55l.62,.22c.23,.08,.47-.04,.55-.27l.59-1.69s.05-.08,.1-.09l4.5-.86c.1-.02,.18,.08,.15,.17l-.25,.71c-.08,.23,.04,.47,.27,.55l.62,.21c.23,.08,.47-.04,.55-.27l.41-1.18,1.13,5.83-.82-.94c-.16-.18-.43-.2-.61-.04l-.5,.43c-.18,.16-.2,.43-.04,.61l.5,.57c.07,.08,.02,.2-.07,.21l-4.52,.86s-.09,0-.12-.04l-1.17-1.36c-.16-.18-.43-.2-.61-.05l-.5,.43c-.18,.16-.2,.43-.05,.61l.49,.57c.07,.08,.02,.2-.08,.21l-4.53,.84s-.09,0-.12-.04l-1.16-1.36c-.16-.18-.43-.2-.61-.05l-.5,.43c-.18,.16-.21,.43-.05,.61l.49,.58c.07,.08,.02,.2-.08,.21l-4.53,.83s-.09,0-.12-.04l-1.15-1.37c-.15-.18-.43-.21-.61-.05l-.5,.42c-.18,.15-.21,.43-.05,.61l.49,.58c.06,.08,.02,.2-.08,.21l-4.53,.8s-.09,0-.12-.04l-.35-.41-1.02-5.85,.18-.51Z" />
				<g>
					<path d="M948.3,363.4l4.63-.85c.08-.01,.14,.07,.11,.14-1.51,3.12-2.01,5.88-2.17,7.36l-5.19,.93c.23-1.62,.85-4.45,2.55-7.53,.02-.03,.04-.05,.07-.05Z" />
					<path d="M961.43,360.96l4.62-.89c.08-.02,.15,.07,.11,.14-1.48,3.14-1.96,5.9-2.1,7.38l-5.18,.98c.21-1.62,.81-4.47,2.48-7.56,.01-.03,.04-.05,.07-.05Z" />
					<path d="M941.73,364.57l4.63-.82c.08-.01,.14,.07,.11,.14-1.53,3.11-2.04,5.87-2.2,7.34l-3.17,.56-.76-4.35c.35-.9,.78-1.85,1.33-2.83,.01-.03,.04-.05,.07-.05Z" />
					<path d="M967.48,366.93l-2.01,.39c.14-1.11,.46-2.79,1.17-4.73l.84,4.34Z" />
					<path d="M954.87,362.19l4.62-.87c.08-.02,.14,.07,.11,.14-1.5,3.13-1.98,5.89-2.14,7.37l-5.19,.96c.22-1.62,.83-4.46,2.52-7.54,.01-.03,.04-.05,.07-.05Z" />
				</g>
				<g>
					<path d="M951.11,352.66l-4.6,.84s-.06,0-.08-.02c-2.65-2.29-4.21-4.75-4.97-6.19l5.11-.92c.66,1.32,2.08,3.73,4.59,6.12,.06,.06,.03,.16-.05,.17Z" />
					<path d="M964.14,350.2l-4.59,.88s-.06,0-.08-.02c-2.67-2.27-4.25-4.71-5.03-6.14l5.1-.96c.67,1.32,2.11,3.71,4.65,6.07,.06,.06,.03,.16-.05,.17Z" />
					<path d="M944.58,353.85l-4.6,.82s-.06,0-.08-.02c-.84-.73-1.57-1.48-2.19-2.2l-.76-4.35,3.12-.55c.66,1.33,2.06,3.74,4.57,6.14,.06,.06,.03,.16-.05,.17Z" />
					<path d="M960.92,343.68l1.98-.38,.84,4.34c-1.38-1.54-2.29-2.98-2.82-3.95Z" />
					<path d="M957.62,351.44l-4.59,.86s-.06,0-.08-.02c-2.66-2.29-4.23-4.73-5-6.17l5.1-.94c.67,1.32,2.1,3.72,4.62,6.1,.06,.06,.03,.16-.05,.17Z" />
				</g>
			</g>
			<g>
				<path d="M912.48,360.78s.06-.08,.1-.08l4.52-.74c.1-.02,.18,.08,.14,.18l-.27,.71c-.09,.22,.03,.47,.25,.56l.61,.23c.22,.08,.47-.03,.56-.25l.63-1.67s.06-.08,.1-.08l4.52-.74c.1-.02,.18,.08,.14,.18l-.27,.71c-.08,.22,.03,.47,.25,.56l.61,.23c.22,.08,.47-.03,.56-.25l.63-1.67s.05-.08,.1-.08l4.52-.74c.1-.02,.18,.08,.14,.18l-.26,.71c-.08,.22,.03,.47,.25,.56l.62,.23c.22,.08,.47-.03,.56-.25l.62-1.67s.05-.08,.1-.08l4.52-.74c.1-.02,.18,.08,.15,.18l-.26,.71c-.08,.22,.03,.47,.26,.56l.62,.23c.22,.08,.47-.03,.56-.25l.43-1.17,1.02,5.85-.8-.96c-.15-.19-.43-.21-.61-.06l-.51,.42c-.18,.15-.21,.42-.06,.61l.49,.58c.06,.08,.02,.2-.08,.21l-4.54,.74s-.09-.01-.12-.05l-1.14-1.38c-.15-.19-.43-.21-.61-.06l-.51,.42c-.18,.15-.21,.42-.06,.61l.48,.58c.06,.08,.02,.2-.08,.21l-4.54,.74s-.09-.01-.12-.05l-1.14-1.38c-.15-.19-.43-.21-.61-.06l-.51,.42c-.19,.15-.21,.42-.06,.61l.48,.59c.06,.08,.02,.2-.08,.21l-4.54,.74s-.09-.01-.12-.05l-1.13-1.38c-.15-.19-.42-.21-.61-.06l-.51,.42c-.19,.15-.21,.42-.06,.61l.48,.59c.06,.08,.02,.2-.08,.21l-4.54,.74s-.09-.01-.12-.05l-.34-.42-.92-5.87,.19-.51Z" />
				<g>
					<path d="M921.96,367.81l4.64-.76c.08-.01,.14,.07,.11,.15-1.57,3.09-2.11,5.84-2.3,7.31l-5.2,.85c.26-1.61,.93-4.45,2.69-7.5,.02-.03,.04-.05,.07-.05Z" />
					<path d="M935.14,365.67l4.64-.76c.08-.01,.14,.07,.11,.15-1.54,3.09-2.07,5.83-2.24,7.3l-5.2,.85c.24-1.61,.9-4.44,2.62-7.49,.02-.03,.04-.05,.07-.05Z" />
					<path d="M915.38,368.89l4.64-.76c.08-.01,.14,.07,.1,.15-1.58,3.09-2.14,5.84-2.33,7.31l-3.18,.52-.68-4.36c.36-.89,.81-1.84,1.38-2.81,.02-.03,.04-.05,.07-.05Z" />
					<path d="M941.09,371.8l-2.02,.33c.16-1.1,.51-2.77,1.26-4.68l.76,4.35Z" />
					<path d="M928.55,366.74l4.64-.76c.08-.01,.14,.07,.11,.15-1.56,3.09-2.09,5.83-2.27,7.3l-5.2,.85c.25-1.61,.91-4.45,2.65-7.49,.02-.03,.04-.05,.07-.05Z" />
				</g>
				<g>
					<path d="M924.96,357.14l-4.61,.75s-.06,0-.08-.02c-2.61-2.34-4.12-4.82-4.86-6.27l5.12-.83c.64,1.34,2.01,3.77,4.48,6.2,.06,.06,.03,.16-.05,.17Z" />
					<path d="M938.05,355l-4.61,.75s-.06,0-.08-.02c-2.64-2.33-4.17-4.81-4.92-6.26l5.12-.83c.65,1.33,2.05,3.77,4.54,6.19,.06,.06,.03,.16-.05,.17Z" />
					<path d="M918.42,358.2l-4.61,.75s-.06,0-.08-.02c-.83-.74-1.54-1.5-2.15-2.23l-.68-4.36,3.13-.51c.63,1.34,2,3.77,4.45,6.21,.06,.06,.03,.16-.05,.17Z" />
					<path d="M934.95,348.41l1.99-.33,.76,4.35c-1.35-1.57-2.23-3.04-2.75-4.03Z" />
					<path d="M931.51,356.07l-4.61,.75s-.06,0-.08-.02c-2.62-2.34-4.14-4.81-4.89-6.26l5.12-.83c.65,1.34,2.03,3.77,4.51,6.2,.06,.06,.03,.16-.05,.17Z" />
				</g>
			</g>
			<g>
				<path d="M886.21,364.63s.06-.07,.1-.08l4.54-.63c.1-.01,.18,.09,.14,.18l-.28,.7c-.09,.22,.02,.47,.24,.56l.61,.25c.22,.09,.47-.02,.56-.24l.66-1.66s.06-.07,.1-.08l4.54-.63c.1-.01,.18,.09,.14,.18l-.28,.7c-.09,.22,.02,.47,.24,.56l.61,.25c.22,.09,.47-.02,.56-.24l.66-1.65s.06-.07,.1-.08l4.54-.63c.1-.01,.18,.08,.14,.18l-.28,.7c-.09,.22,.02,.47,.25,.56l.61,.24c.22,.09,.47-.03,.56-.25l.65-1.67s.06-.08,.1-.08l4.52-.74c.1-.02,.18,.08,.14,.18l-.27,.71c-.09,.22,.02,.47,.25,.56l.61,.23c.22,.08,.47-.03,.56-.25l.45-1.17,.92,5.87-.78-.97c-.15-.19-.42-.21-.61-.06l-.51,.42c-.19,.15-.22,.42-.07,.61l.47,.59c.06,.08,.02,.2-.08,.21l-4.54,.74s-.09-.01-.12-.05l-1.12-1.39c-.15-.19-.42-.21-.61-.06l-.51,.41c-.19,.15-.22,.42-.07,.61l.47,.59c.06,.08,.02,.2-.08,.21l-4.56,.63s-.09-.01-.12-.05l-1.11-1.41c-.15-.19-.42-.22-.61-.08l-.52,.4c-.19,.15-.22,.42-.07,.61l.47,.6c.06,.08,.02,.2-.08,.21l-4.56,.63s-.09-.01-.12-.05l-1.1-1.41c-.15-.19-.42-.22-.61-.08l-.52,.4c-.19,.15-.22,.42-.07,.61l.47,.6c.06,.08,.02,.2-.08,.21l-4.56,.63s-.09-.01-.12-.05l-.33-.43-.81-5.88,.2-.5Z" />
				<g>
					<path d="M895.57,371.88l4.66-.65c.08-.01,.14,.08,.1,.15-1.63,3.05-2.22,5.78-2.43,7.25l-5.22,.73c.29-1.61,1.01-4.42,2.82-7.43,.02-.03,.04-.04,.07-.05Z" />
					<path d="M908.79,369.96l4.64-.76c.08-.01,.14,.07,.1,.15-1.59,3.1-2.16,5.85-2.36,7.32l-5.21,.84c.27-1.6,.97-4.45,2.75-7.5,.02-.03,.04-.05,.07-.05Z" />
					<path d="M888.96,372.8l4.66-.65c.08-.01,.14,.08,.1,.15-1.64,3.05-2.24,5.79-2.46,7.25l-3.19,.44-.61-4.38c.38-.88,.85-1.82,1.43-2.77,.02-.03,.04-.04,.07-.05Z" />
					<path d="M914.61,376.11l-2.02,.33c.18-1.1,.56-2.77,1.34-4.69l.68,4.36Z" />
					<path d="M902.18,370.96c1.55-.22,3.11-.41,4.65-.68,.08-.01,.14,.07,.1,.15-1.6,3.11-2.19,5.81-2.4,7.28l-5.22,.73c.28-1.6,.99-4.42,2.79-7.42,.02-.03,.04-.04,.07-.05Z" />
				</g>
				<g>
					<path d="M898.76,361.28l-4.63,.64s-.06,0-.08-.03c-2.57-2.4-4.04-4.91-4.75-6.37l5.14-.71c.62,1.35,1.95,3.81,4.38,6.3,.06,.06,.03,.16-.06,.17Z" />
					<path d="M911.87,359.27l-4.61,.75s-.06,0-.08-.02c-2.59-2.35-4.07-4.81-4.8-6.29l5.12-.82c.63,1.34,1.98,3.78,4.42,6.21,.06,.06,.03,.16-.05,.17Z" />
					<path d="M892.2,362.19l-4.63,.64s-.06,0-.08-.03c-.81-.76-1.51-1.53-2.11-2.28l-.61-4.38,3.14-.44c.61,1.35,1.93,3.82,4.35,6.31,.06,.06,.03,.16-.06,.17Z" />
					<path d="M908.9,352.65l1.99-.32,.68,4.36c-1.32-1.58-2.17-3.05-2.67-4.04Z" />
					<path d="M905.33,360.33c-1.53,.27-3.08,.46-4.62,.68-.03,0-.06,0-.08-.03-2.58-2.39-4.06-4.9-4.78-6.37l5.14-.71c.62,1.34,1.97,3.83,4.4,6.26,.06,.06,.03,.16-.05,.17Z" />
				</g>
			</g>
			<g>
				<path d="M859.88,368.06s.06-.07,.1-.08l4.55-.53c.1-.01,.18,.09,.14,.18l-.29,.69c-.09,.22,0,.47,.23,.57l.6,.26c.22,.1,.47,0,.57-.23l.69-1.64s.06-.07,.1-.08c1.52-.18,3.04-.33,4.55-.56,.1-.01,.18,.09,.14,.18l-.29,.7c-.09,.22,.01,.48,.23,.57l.6,.25c.22,.09,.47-.02,.56-.24l.68-1.66s.06-.07,.1-.08l4.54-.63c.1-.01,.18,.09,.14,.18l-.29,.7c-.09,.22,.01,.47,.23,.57l.61,.25c.22,.09,.47-.02,.56-.24l.68-1.66s.06-.07,.1-.08l4.54-.63c.1-.01,.18,.09,.14,.18l-.28,.7c-.09,.22,.02,.47,.24,.56l.61,.25c.22,.09,.47-.02,.56-.24l.47-1.16,.81,5.88-.77-.98c-.15-.19-.42-.22-.61-.08l-.52,.4c-.19,.15-.22,.42-.08,.61l.46,.6c.06,.08,.01,.2-.09,.21l-4.56,.63s-.09-.01-.12-.05l-1.09-1.41c-.15-.19-.42-.22-.61-.08l-.52,.4c-.19,.15-.23,.42-.08,.61l.46,.6c.06,.08,.01,.2-.09,.21l-4.56,.63s-.09-.01-.12-.05l-1.08-1.41c-.15-.19-.42-.22-.61-.08l-.52,.4c-.19,.15-.23,.42-.08,.61l.46,.6c.06,.08,.01,.2-.09,.21-1.52,.23-3.05,.38-4.57,.56-.05,0-.09-.01-.12-.05l-1.08-1.43c-.14-.19-.42-.23-.61-.09l-.52,.39c-.19,.14-.23,.41-.08,.61l.46,.61c.06,.08,.01,.2-.09,.21l-4.57,.53s-.09-.01-.12-.05l-.32-.43-.71-5.9,.21-.5Z" />
				<g>
					<path d="M869.12,375.52c1.56-.16,3.11-.4,4.66-.61,.08-.01,.14,.08,.1,.15-1.67,3.06-2.31,5.8-2.55,7.26l-5.24,.61c.31-1.6,1.09-4.39,2.95-7.36,.02-.03,.04-.04,.07-.05Z" />
					<path d="M882.35,373.72l4.66-.65c.08-.01,.14,.08,.1,.15-1.65,3.06-2.27,5.79-2.49,7.26l-5.22,.73c.3-1.61,1.05-4.43,2.88-7.44,.02-.03,.04-.04,.07-.05Z" />
					<path d="M862.48,376.28l4.67-.54c.08,0,.14,.08,.1,.15-1.69,3.01-2.35,5.73-2.59,7.19l-3.2,.37-.53-4.39c.4-.87,.88-1.8,1.48-2.74,.02-.03,.04-.04,.07-.05Z" />
					<path d="M888.06,380l-2.03,.28c.2-1.1,.61-2.76,1.43-4.66l.61,4.38Z" />
					<path d="M875.73,374.64l4.66-.65c.08-.01,.14,.08,.1,.15-1.66,3.06-2.29,5.79-2.52,7.26l-5.22,.73c.31-1.61,1.07-4.43,2.91-7.44,.02-.03,.04-.04,.07-.05Z" />
				</g>
				<g>
					<path d="M872.49,364.93c-1.54,.21-3.08,.44-4.63,.61-.03,0-.06,0-.08-.03-2.53-2.45-3.95-5-4.64-6.48l5.15-.6c.59,1.37,1.88,3.83,4.26,6.33,.06,.06,.02,.16-.06,.17Z" />
					<path d="M885.63,363.11l-4.63,.64s-.06,0-.08-.03c-2.55-2.4-3.99-4.91-4.69-6.38l5.14-.71c.6,1.35,1.91,3.82,4.32,6.31,.06,.06,.02,.16-.06,.17Z" />
					<path d="M865.91,365.76l-4.64,.54s-.06,0-.08-.03c-.8-.78-1.49-1.57-2.07-2.33l-.53-4.39,3.15-.36c.59,1.36,1.86,3.86,4.24,6.4,.06,.06,.02,.16-.06,.17Z" />
					<path d="M882.77,356.43l2-.28,.61,4.38c-1.29-1.61-2.12-3.1-2.6-4.1Z" />
					<path d="M879.06,364.02l-4.63,.64s-.06,0-.08-.03c-2.54-2.4-3.96-4.92-4.66-6.39l5.14-.71c.6,1.35,1.9,3.82,4.29,6.31,.06,.06,.02,.16-.06,.17Z" />
				</g>
			</g>
			<g>
				<path d="M833.5,371.07s.06-.07,.11-.08c1.52-.13,3.04-.32,4.56-.49,.1-.01,.17,.09,.13,.18l-.31,.69c-.1,.22,0,.48,.22,.57l.6,.26c.22,.1,.47,0,.57-.23l.72-1.64s.06-.07,.11-.08l4.55-.53c.1-.01,.17,.09,.13,.18l-.3,.69c-.1,.22,0,.48,.22,.57l.6,.26c.22,.1,.47,0,.57-.23l.71-1.64s.06-.07,.11-.08l4.55-.53c.1-.01,.17,.09,.13,.18l-.3,.69c-.09,.22,0,.47,.22,.57l.6,.26c.22,.1,.47,0,.57-.23l.71-1.64s.06-.07,.1-.08l4.55-.53c.1-.01,.18,.09,.14,.18l-.3,.69c-.09,.22,0,.47,.23,.57l.6,.26c.22,.1,.47,0,.57-.23l.49-1.14,.71,5.9-.75-1c-.14-.19-.42-.23-.61-.09l-.53,.39c-.19,.14-.23,.41-.09,.61l.45,.61c.06,.08,.01,.2-.09,.21l-4.57,.53s-.09-.01-.12-.05l-1.07-1.44c-.14-.19-.41-.23-.61-.09l-.53,.39c-.19,.14-.23,.41-.09,.61l.45,.61c.06,.08,0,.2-.09,.21l-4.57,.53s-.09-.01-.12-.05l-1.06-1.44c-.14-.19-.41-.23-.61-.09l-.53,.39c-.19,.14-.23,.41-.09,.61l.45,.61c.06,.08,0,.2-.09,.21l-4.57,.53s-.09-.01-.12-.05l-1.05-1.44c-.14-.19-.41-.23-.6-.09l-.53,.39c-.19,.14-.24,.41-.1,.61l.44,.61c.06,.08,0,.2-.09,.21-1.52,.17-3.05,.37-4.58,.49-.05,0-.09-.02-.12-.05l-.32-.44-.61-5.91,.22-.49Z" />
				<g>
					<path d="M842.59,378.58l4.67-.54c.08,0,.14,.08,.1,.15-1.73,3.02-2.42,5.74-2.68,7.2l-5.24,.6c.34-1.6,1.17-4.4,3.08-7.37,.02-.03,.04-.04,.08-.05Z" />
					<path d="M855.85,377.05l4.67-.54c.08,0,.14,.08,.1,.15-1.71,3.02-2.37,5.73-2.62,7.19l-5.24,.61c.33-1.6,1.13-4.4,3.02-7.36,.02-.03,.04-.04,.07-.05Z" />
					<path d="M835.96,379.35l4.67-.54c.08,0,.14,.08,.1,.15-1.74,3.02-2.44,5.74-2.71,7.2-1.07,.13-2.14,.23-3.21,.32l-.45-4.39c.41-.86,.91-1.78,1.52-2.7,.02-.03,.04-.04,.08-.05Z" />
					<path d="M861.46,383.46l-2.04,.24c.22-1.09,.66-2.74,1.51-4.62l.53,4.39Z" />
					<path d="M849.22,377.82l4.67-.54c.08,0,.14,.08,.1,.15-1.72,3.02-2.4,5.74-2.65,7.2l-5.24,.6c.33-1.6,1.15-4.4,3.05-7.37,.02-.03,.04-.04,.08-.05Z" />
				</g>
				<g>
					<path d="M846.15,368.05l-4.64,.54s-.06,0-.08-.03c-2.48-2.46-3.85-5.01-4.51-6.49l5.15-.6c.57,1.37,1.81,3.86,4.15,6.41,.06,.06,.02,.16-.06,.17Z" />
					<path d="M859.32,366.53l-4.64,.54s-.06,0-.08-.03c-2.51-2.46-3.9-5-4.58-6.49l5.15-.6c.58,1.36,1.85,3.86,4.21,6.4,.06,.06,.02,.16-.06,.17Z" />
					<path d="M839.56,368.81l-4.64,.53s-.06,0-.08-.03c-.79-.8-1.46-1.6-2.03-2.37l-.45-4.39c1.05-.1,2.11-.19,3.16-.32,.56,1.37,1.79,3.86,4.12,6.41,.06,.06,.02,.16-.06,.17Z" />
					<path d="M856.58,359.79l2-.23,.53,4.39c-1.26-1.64-2.07-3.14-2.53-4.15Z" />
					<path d="M852.74,367.29l-4.64,.54s-.06,0-.08-.03c-2.49-2.46-3.88-5-4.55-6.49l5.15-.6c.57,1.37,1.83,3.86,4.18,6.41,.06,.06,.02,.16-.06,.17Z" />
				</g>
			</g>
			<g>
				<path d="M807.05,373.51s.06-.07,.11-.08l4.56-.42c.1,0,.17,.09,.13,.19l-.32,.69c-.1,.22,0,.48,.21,.58l.59,.28c.22,.1,.47,0,.57-.21l.75-1.62s.06-.07,.11-.08l4.56-.42c.1,0,.17,.09,.13,.19l-.32,.69c-.1,.22,0,.47,.21,.58l.6,.28c.22,.1,.47,0,.57-.21l.74-1.62s.06-.07,.11-.08l4.56-.42c.1,0,.17,.09,.13,.19l-.31,.69c-.1,.22,0,.47,.21,.57l.6,.28c.22,.1,.47,0,.57-.21l.74-1.62s.06-.07,.11-.08l4.56-.42c.1,0,.17,.09,.13,.19l-.31,.69c-.1,.22,0,.47,.22,.57l.6,.27c.22,.1,.47,0,.57-.21l.51-1.13,.61,5.91-.73-1.02c-.14-.2-.41-.24-.61-.1l-.53,.38c-.19,.14-.24,.41-.1,.6l.44,.62c.06,.08,0,.2-.09,.21l-4.58,.42s-.09-.02-.12-.05l-1.04-1.46c-.14-.2-.41-.24-.6-.1l-.54,.38c-.2,.14-.24,.41-.1,.6l.44,.62c.06,.08,0,.2-.09,.21l-4.58,.42s-.09-.02-.12-.05l-1.04-1.46c-.14-.2-.41-.24-.6-.1l-.54,.38c-.2,.14-.24,.41-.1,.6l.44,.62c.06,.08,0,.2-.1,.21l-4.58,.42s-.09-.02-.12-.05l-1.03-1.46c-.14-.2-.41-.24-.6-.1l-.54,.38c-.2,.14-.24,.41-.11,.6l.43,.62c.06,.08,0,.2-.1,.21l-4.58,.42s-.09-.02-.12-.05l-.31-.44-.5-5.92,.23-.49Z" />
				<g>
					<path d="M816.01,381.19l4.68-.43c.08,0,.14,.08,.1,.15-1.78,2.98-2.52,5.68-2.81,7.13l-5.25,.48c.37-1.59,1.24-4.37,3.21-7.29,.02-.03,.05-.04,.08-.05Z" />
					<path d="M829.31,379.96l4.68-.43c.08,0,.14,.08,.1,.15-1.76,2.97-2.47,5.67-2.75,7.13l-5.25,.48c.36-1.59,1.21-4.37,3.15-7.29,.02-.03,.05-.04,.08-.05Z" />
					<path d="M809.37,381.8l4.68-.43c.08,0,.14,.08,.09,.15-1.8,2.98-2.55,5.68-2.84,7.14l-3.21,.3-.37-4.4c.43-.87,.94-1.78,1.57-2.71,.02-.03,.05-.04,.08-.05Z" />
					<path d="M834.81,386.49l-2.04,.19c.24-1.09,.71-2.72,1.59-4.58l.45,4.39Z" />
					<path d="M822.66,380.57l4.68-.43c.08,0,.14,.08,.1,.15-1.77,2.98-2.5,5.68-2.78,7.13l-5.25,.48c.36-1.59,1.23-4.37,3.18-7.29,.02-.03,.05-.04,.08-.05Z" />
				</g>
				<g>
					<path d="M819.76,370.74l-4.65,.43s-.06,0-.08-.03c-2.44-2.51-3.77-5.09-4.4-6.59l5.17-.48c.54,1.38,1.74,3.9,4.03,6.5,.05,.06,.02,.16-.06,.17Z" />
					<path d="M832.97,369.52l-4.65,.43s-.06,0-.08-.03c-2.46-2.51-3.82-5.09-4.46-6.59l5.17-.48c.55,1.38,1.78,3.9,4.09,6.5,.06,.06,.02,.16-.06,.17Z" />
					<path d="M813.16,371.35l-4.65,.43s-.06,0-.08-.03c-.77-.8-1.43-1.6-1.99-2.38l-.37-4.4,3.16-.29c.54,1.38,1.73,3.9,4,6.5,.05,.06,.02,.16-.07,.17Z" />
					<path d="M830.34,362.73l2.01-.19,.45,4.39c-1.24-1.66-2.01-3.19-2.46-4.21Z" />
					<path d="M826.36,370.13l-4.65,.43s-.06,0-.08-.03c-2.45-2.51-3.79-5.09-4.43-6.59l5.17-.48c.55,1.38,1.76,3.9,4.06,6.5,.05,.06,.02,.16-.06,.17Z" />
				</g>
			</g>
			<g>
				<path d="M780.57,375.49s.06-.07,.11-.07l4.57-.32c.1,0,.17,.1,.13,.19l-.33,.68c-.1,.21-.02,.47,.2,.58l.59,.29c.21,.11,.47,.02,.58-.2l.78-1.61s.06-.07,.11-.07l4.57-.32c.1,0,.17,.1,.13,.19l-.33,.68c-.1,.22-.01,.47,.2,.58l.59,.29c.22,.11,.47,.02,.58-.2l.77-1.61s.06-.07,.11-.07l4.57-.32c.1,0,.17,.1,.13,.19l-.32,.68c-.1,.21-.01,.47,.2,.58l.59,.29c.22,.1,.47,.02,.58-.2l.77-1.6s.06-.07,.11-.07l4.56-.42c.1,0,.17,.09,.13,.19l-.32,.69c-.1,.22,0,.48,.21,.58l.59,.28c.22,.1,.47,0,.57-.21l.53-1.13,.5,5.92-.71-1.02c-.14-.2-.41-.24-.6-.1l-.54,.38c-.2,.14-.25,.41-.11,.6l.43,.62c.06,.08,0,.2-.1,.21l-4.58,.42s-.09-.02-.12-.06l-1.02-1.48c-.14-.2-.41-.25-.6-.12l-.54,.37c-.2,.13-.25,.4-.11,.6l.43,.63c.06,.08,0,.2-.1,.2l-4.59,.32s-.09-.02-.12-.06l-1.01-1.48c-.14-.2-.4-.25-.6-.12l-.54,.37c-.2,.13-.25,.4-.11,.6l.43,.63c.06,.08,0,.2-.1,.2l-4.59,.32s-.09-.02-.12-.06l-1-1.48c-.13-.2-.4-.25-.6-.12l-.54,.37c-.2,.13-.25,.4-.12,.6l.42,.63c.06,.08,0,.2-.1,.2l-4.59,.32s-.09-.02-.12-.06l-.3-.45-.4-5.93,.24-.49Z" />
				<g>
					<path d="M789.4,383.37l4.69-.33c.08,0,.14,.09,.09,.16-1.84,2.93-2.62,5.62-2.94,7.06l-5.26,.36c.4-1.58,1.32-4.34,3.34-7.22,.02-.03,.05-.04,.08-.04Z" />
					<path d="M802.72,382.41l4.68-.43c.08,0,.14,.08,.09,.15-1.81,2.98-2.57,5.68-2.87,7.14-1.75,.17-3.5,.32-5.25,.43,.38-1.6,1.29-4.31,3.27-7.24,.02-.03,.05-.04,.08-.05Z" />
					<path d="M782.74,383.83l4.69-.32c.08,0,.14,.09,.09,.16-1.85,2.93-2.65,5.62-2.97,7.07l-3.21,.22-.3-4.41c.44-.86,.97-1.76,1.62-2.67,.02-.03,.05-.04,.08-.04Z" />
					<path d="M808.09,388.95l-2.04,.19c.26-1.09,.75-2.73,1.67-4.59l.37,4.4Z" />
					<path d="M796.06,382.91l4.69-.33c.08,0,.14,.09,.09,.16-1.82,2.93-2.6,5.62-2.91,7.06l-5.26,.36c.39-1.58,1.3-4.34,3.31-7.21,.02-.03,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M793.33,373.02l-4.66,.32s-.06-.01-.08-.03c-2.4-2.57-3.68-5.17-4.29-6.69l5.18-.36c.52,1.39,1.67,3.94,3.92,6.59,.05,.06,.02,.16-.07,.17Z" />
					<path d="M806.55,371.96l-4.65,.43s-.06,0-.08-.03c-2.42-2.5-3.73-5.15-4.35-6.65,1.73-.11,3.45-.25,5.17-.42,.53,1.38,1.71,3.9,3.97,6.51,.05,.06,.02,.16-.07,.17Z" />
					<path d="M786.71,373.47l-4.66,.32s-.06-.01-.08-.03c-.76-.82-1.4-1.63-1.95-2.42l-.3-4.41,3.16-.22c.51,1.39,1.66,3.94,3.89,6.59,.05,.06,.01,.16-.07,.17Z" />
					<path d="M804.05,365.16l2.01-.19,.37,4.4c-1.2-1.67-1.95-3.19-2.38-4.22Z" />
					<path d="M799.95,372.56l-4.66,.32s-.06-.01-.08-.03c-2.41-2.57-3.7-5.17-4.32-6.69l5.18-.36c.52,1.39,1.69,3.94,3.95,6.59,.05,.06,.02,.16-.07,.17Z" />
				</g>
			</g>
			<g>
				<path d="M754.07,377.06s.06-.07,.11-.07l4.58-.21c.1,0,.17,.1,.12,.19l-.34,.67c-.11,.21-.02,.47,.19,.58l.58,.3c.21,.11,.47,.03,.58-.19l.81-1.59s.06-.07,.11-.07l4.58-.21c.1,0,.17,.1,.12,.19l-.34,.67c-.11,.21-.02,.47,.19,.58l.59,.3c.21,.11,.47,.02,.58-.2l.8-1.61s.06-.07,.11-.07l4.57-.32c.1,0,.17,.1,.12,.19l-.34,.68c-.11,.22-.02,.47,.19,.58l.59,.29c.21,.11,.47,.02,.58-.2l.79-1.61s.06-.07,.11-.07l4.57-.32c.1,0,.17,.1,.13,.19l-.33,.68c-.11,.22-.02,.47,.2,.58l.59,.29c.21,.11,.47,.02,.58-.2l.55-1.12,.4,5.93-.69-1.04c-.13-.2-.4-.25-.6-.12l-.55,.37c-.2,.13-.25,.4-.12,.6l.42,.63c.06,.08,0,.2-.1,.2l-4.59,.32s-.09-.02-.12-.06l-.99-1.48c-.13-.2-.4-.25-.6-.12l-.55,.37c-.2,.13-.25,.4-.12,.6l.42,.63c.06,.08,0,.2-.1,.2l-4.59,.32s-.09-.02-.12-.06l-.98-1.48c-.13-.2-.4-.25-.6-.12l-.55,.35c-.2,.13-.26,.4-.12,.6l.42,.64c.06,.08,0,.2-.1,.2l-4.6,.21s-.09-.02-.12-.06l-.98-1.5c-.13-.2-.4-.26-.6-.13l-.55,.35c-.2,.13-.26,.4-.13,.6l.41,.64c.06,.08,0,.2-.1,.2l-4.6,.21s-.09-.02-.12-.06l-.29-.45-.3-5.93,.25-.48Z" />
				<g>
					<path d="M762.76,385.13c1.57-.08,3.13-.12,4.7-.24,.08,0,.13,.09,.09,.16-1.88,2.94-2.72,5.57-3.06,7.02l-5.27,.24c.43-1.57,1.4-4.31,3.46-7.14,.02-.02,.05-.04,.08-.04Z" />
					<path d="M776.08,384.29l4.69-.32c.08,0,.14,.09,.09,.16-1.86,2.94-2.67,5.62-3,7.07l-5.26,.36c.41-1.58,1.36-4.34,3.4-7.22,.02-.03,.05-.04,.08-.04Z" />
					<path d="M756.09,385.44l4.7-.22c.08,0,.13,.09,.09,.16-1.9,2.89-2.74,5.56-3.09,7l-3.22,.15-.22-4.41c.46-.85,1-1.73,1.66-2.63,.02-.02,.05-.04,.08-.04Z" />
					<path d="M781.34,390.95l-2.04,.14c.28-1.08,.8-2.71,1.75-4.55l.3,4.41Z" />
					<path d="M769.42,384.75l4.69-.32c.08,0,.13,.09,.09,.16-1.87,2.94-2.69,5.62-3.03,7.07-1.75,.11-3.51,.26-5.26,.34,.42-1.56,1.38-4.32,3.43-7.2,.02-.03,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M766.87,374.85c-1.55,.12-3.11,.16-4.67,.24-.03,0-.06-.01-.08-.03-2.35-2.62-3.59-5.26-4.17-6.78l5.18-.24c.49,1.39,1.61,4,3.8,6.65,.05,.06,.01,.16-.07,.17Z" />
					<path d="M780.1,373.93l-4.66,.32s-.06-.01-.08-.03c-2.37-2.57-3.63-5.18-4.23-6.69l5.18-.36c.51,1.39,1.64,3.94,3.86,6.59,.05,.06,.01,.16-.07,.17Z" />
					<path d="M760.24,375.18l-4.67,.22s-.06-.01-.08-.03c-.74-.83-1.37-1.67-1.91-2.46l-.22-4.41,3.17-.15c.49,1.4,1.59,3.98,3.78,6.68,.05,.06,.01,.16-.07,.16Z" />
					<path d="M777.71,367.07l2.01-.14,.3,4.41c-1.18-1.69-1.9-3.23-2.31-4.27Z" />
					<path d="M773.48,374.39l-4.66,.32s-.06-.01-.08-.03c-2.36-2.57-3.61-5.18-4.2-6.71,1.73-.08,3.45-.23,5.18-.34,.5,1.39,1.62,3.94,3.83,6.6,.05,.06,.01,.16-.07,.17Z" />
				</g>
			</g>
			<g>
				<path d="M727.54,378.2s.06-.07,.11-.07c1.53-.05,3.06-.05,4.58-.13,.1,0,.17,.1,.12,.19l-.35,.67c-.11,.21-.03,.47,.18,.58l.58,.3c.21,.11,.47,.03,.58-.19l.83-1.59s.06-.07,.11-.07l4.58-.21c.1,0,.17,.1,.12,.19l-.35,.67c-.11,.21-.03,.47,.18,.58l.58,.3c.21,.11,.47,.03,.58-.19l.83-1.59s.06-.07,.11-.07l4.58-.21c.1,0,.17,.1,.12,.19l-.35,.67c-.11,.21-.03,.47,.18,.58l.58,.3c.21,.11,.47,.03,.58-.19l.82-1.59s.06-.07,.11-.07l4.58-.21c.1,0,.17,.1,.12,.19l-.34,.67c-.11,.21-.03,.47,.19,.58l.58,.3c.21,.11,.47,.03,.58-.19l.57-1.11,.3,5.93-.68-1.05c-.13-.2-.4-.26-.6-.13l-.55,.35c-.2,.13-.26,.4-.13,.6l.41,.64c.05,.08,0,.2-.1,.2l-4.6,.21s-.09-.02-.12-.06l-.96-1.51c-.13-.2-.4-.26-.6-.13l-.55,.35c-.2,.13-.26,.4-.13,.6l.41,.64c.05,.08,0,.2-.1,.2l-4.6,.21s-.09-.02-.12-.06l-.96-1.51c-.13-.2-.4-.26-.6-.13l-.56,.35c-.2,.13-.26,.4-.14,.6l.4,.64c.05,.08,0,.2-.11,.2l-4.6,.21s-.09-.02-.12-.06l-.95-1.51c-.13-.2-.39-.26-.6-.13l-.56,.35c-.2,.13-.26,.4-.14,.6l.4,.64c.05,.08,0,.2-.11,.2-1.53,.08-3.07,.08-4.6,.13-.05,0-.09-.02-.12-.06l-.29-.46-.19-5.94,.25-.47Z" />
				<g>
					<path d="M736.08,386.37l4.7-.22c.08,0,.13,.09,.09,.16-1.94,2.89-2.81,5.56-3.18,7l-5.27,.24c.45-1.57,1.47-4.31,3.58-7.14,.02-.02,.05-.04,.08-.04Z" />
					<path d="M749.42,385.75l4.7-.22c.08,0,.13,.09,.09,.16-1.91,2.89-2.77,5.56-3.12,7l-5.27,.24c.44-1.57,1.43-4.31,3.52-7.14,.02-.02,.05-.04,.08-.04Z" />
					<path d="M729.41,386.63c1.57-.02,3.13-.09,4.7-.17,.08,0,.13,.09,.09,.16-1.95,2.9-2.84,5.56-3.21,6.98l-3.22,.08-.14-4.41c.47-.83,1.03-1.71,1.71-2.59,.02-.02,.05-.04,.08-.04Z" />
					<path d="M754.57,392.52l-2.05,.1c.3-1.07,.85-2.69,1.83-4.51l.22,4.41Z" />
					<path d="M742.75,386.06l4.7-.22c.08,0,.13,.09,.09,.16-1.92,2.89-2.79,5.56-3.15,7l-5.27,.24c.45-1.57,1.45-4.31,3.55-7.14,.02-.02,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M740.37,376.1l-4.67,.22s-.06-.01-.08-.03c-2.3-2.62-3.5-5.26-4.05-6.79l5.18-.24c.47,1.4,1.54,3.98,3.69,6.68,.05,.06,0,.16-.07,.16Z" />
					<path d="M753.62,375.49l-4.67,.22s-.06-.01-.08-.03c-2.33-2.62-3.54-5.26-4.11-6.78l5.18-.24c.48,1.4,1.57,3.98,3.75,6.68,.05,.06,.01,.16-.07,.16Z" />
					<path d="M733.74,376.41c-1.56,.08-3.11,.14-4.67,.17-.03,0-.06-.01-.08-.04-.73-.85-1.34-1.7-1.86-2.51l-.14-4.41,3.17-.07c.46,1.42,1.52,4,3.66,6.7,.05,.06,0,.16-.07,.16Z" />
					<path d="M751.35,368.58l2.02-.09,.22,4.41c-1.15-1.72-1.84-3.28-2.24-4.32Z" />
					<path d="M746.99,375.79l-4.67,.22s-.06-.01-.08-.03c-2.32-2.62-3.52-5.26-4.08-6.79l5.18-.24c.48,1.4,1.55,3.98,3.72,6.68,.05,.06,0,.16-.07,.16Z" />
				</g>
			</g>
			<g>
				<path d="M700.99,378.83s.07-.07,.11-.07l4.58-.11c.1,0,.17,.11,.12,.19l-.36,.66c-.12,.21-.04,.47,.17,.59l.57,.32c.21,.11,.47,.04,.59-.17l.86-1.57s.07-.07,.11-.07l4.58-.11c.1,0,.17,.11,.12,.19l-.36,.66c-.11,.21-.04,.47,.17,.59l.58,.32c.21,.11,.47,.04,.59-.17l.85-1.57s.06-.07,.11-.07l4.58-.11c.1,0,.17,.11,.12,.19l-.36,.66c-.11,.21-.04,.47,.17,.59l.58,.32c.21,.11,.47,.04,.59-.17l.85-1.57s.06-.07,.11-.07l4.58-.11c.1,0,.17,.11,.12,.19l-.36,.66c-.11,.21-.03,.47,.18,.59l.58,.32c.21,.11,.47,.04,.59-.17l.59-1.09,.19,5.94-.66-1.07c-.13-.2-.39-.27-.6-.14l-.56,.34c-.2,.12-.27,.39-.14,.6l.4,.65c.05,.09,0,.2-.11,.2l-4.6,.11s-.09-.02-.12-.06l-.94-1.53c-.13-.2-.39-.27-.6-.14l-.56,.34c-.2,.12-.27,.39-.14,.6l.4,.65c.05,.09,0,.2-.11,.2l-4.6,.11s-.09-.02-.12-.06l-.93-1.53c-.12-.2-.39-.27-.6-.14l-.56,.34c-.2,.12-.27,.39-.15,.6l.39,.65c.05,.09,0,.2-.11,.2l-4.6,.11s-.09-.02-.12-.06l-.92-1.53c-.12-.2-.39-.27-.59-.14l-.56,.34c-.21,.12-.27,.39-.15,.6l.39,.65c.05,.09,0,.2-.11,.2l-4.6,.11s-.09-.02-.11-.06l-.28-.46-.09-5.94,.26-.47Z" />
				<g>
					<path d="M709.39,387.1l4.7-.11c.08,0,.13,.09,.08,.16-1.99,2.85-2.91,5.49-3.3,6.93l-5.27,.12c.48-1.56,1.54-4.28,3.71-7.06,.02-.02,.05-.04,.08-.04Z" />
					<path d="M722.74,386.79l4.7-.11c.08,0,.13,.09,.09,.16-1.96,2.85-2.86,5.49-3.24,6.92l-5.27,.12c.47-1.56,1.51-4.28,3.65-7.06,.02-.02,.05-.04,.08-.04Z" />
					<path d="M702.72,387.26l4.7-.11c.08,0,.13,.09,.08,.16-2,2.85-2.93,5.49-3.33,6.93l-3.22,.08-.07-4.42c.48-.83,1.06-1.71,1.75-2.59,.02-.02,.05-.04,.08-.04Z" />
					<path d="M727.77,393.68l-2.05,.05c.31-1.07,.89-2.67,1.91-4.46l.14,4.41Z" />
					<path d="M716.06,386.94l4.7-.11c.08,0,.13,.09,.08,.16-1.97,2.85-2.89,5.49-3.27,6.92l-5.27,.12c.47-1.56,1.53-4.28,3.68-7.06,.02-.02,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M713.85,376.94l-4.67,.11s-.06-.01-.08-.04c-2.26-2.67-3.4-5.34-3.93-6.88l5.19-.12c.45,1.41,1.47,4.01,3.57,6.76,.05,.07,0,.16-.08,.16Z" />
					<path d="M727.11,376.62l-4.67,.11s-.06-.01-.08-.04c-2.28-2.67-3.45-5.34-3.99-6.88l5.19-.12c.46,1.41,1.5,4.01,3.63,6.76,.05,.07,0,.16-.07,.16Z" />
					<path d="M707.22,377.09l-4.67,.11s-.06-.01-.08-.04c-.71-.85-1.31-1.7-1.82-2.51l-.07-4.42,3.17-.08c.44,1.41,1.45,4.01,3.54,6.76,.05,.07,0,.16-.08,.16Z" />
					<path d="M724.97,369.67l2.02-.05,.14,4.41c-1.12-1.74-1.79-3.32-2.16-4.37Z" />
					<path d="M720.48,376.78l-4.67,.11s-.06-.01-.08-.04c-2.27-2.67-3.43-5.34-3.96-6.88l5.19-.12c.45,1.41,1.48,4.01,3.6,6.76,.05,.07,0,.16-.08,.16Z" />
				</g>
			</g>
			<g>
				<path d="M674.43,378.96s.07-.07,.11-.07h4.58c.1,0,.16,.1,.11,.19l-.38,.66c-.12,.21-.05,.47,.16,.59l.57,.33c.21,.12,.47,.05,.59-.16l.89-1.55s.07-.07,.11-.07h4.58c.1,0,.16,.1,.11,.19l-.37,.66c-.12,.21-.05,.47,.16,.59l.57,.33c.21,.12,.47,.05,.59-.16l.88-1.55s.07-.07,.11-.07h4.58c.1,0,.16,.1,.12,.19l-.37,.66c-.12,.21-.04,.47,.16,.59l.57,.33c.21,.12,.47,.05,.59-.16l.87-1.55s.07-.07,.11-.07c1.53,.01,3.05-.02,4.58-.06,.1,0,.17,.11,.12,.19l-.37,.66c-.12,.21-.04,.47,.17,.59l.57,.32c.21,.11,.47,.04,.59-.17l.61-1.1,.09,5.94-.64-1.07c-.12-.2-.39-.27-.59-.14l-.56,.34c-.21,.12-.27,.39-.15,.6l.39,.65c.05,.09-.01,.2-.11,.2-1.53,.04-3.07,.07-4.6,.06-.05,0-.09-.02-.11-.07l-.91-1.55c-.12-.21-.39-.28-.59-.16l-.57,.33c-.21,.12-.27,.39-.15,.59l.38,.65c.05,.09-.01,.2-.11,.2h-4.6s-.09-.02-.11-.06l-.9-1.55c-.12-.21-.39-.28-.59-.16l-.57,.33c-.21,.12-.28,.39-.16,.59l.38,.65c.05,.09-.01,.2-.11,.2h-4.6s-.09-.02-.11-.06l-.9-1.55c-.12-.21-.39-.28-.59-.16l-.57,.33c-.21,.12-.28,.39-.16,.59l.38,.65c.05,.09-.01,.2-.11,.2h-4.6s-.09-.02-.11-.06l-.27-.47v-5.94s.28-.47,.28-.47Z" />
				<g>
					<path d="M682.69,387.42h4.7c.08,0,.13,.09,.08,.16-2.03,2.8-3,5.43-3.42,6.85h-5.27c.51-1.55,1.62-4.24,3.83-6.97,.02-.02,.05-.04,.08-.04Z" />
					<path d="M696.04,387.4c1.57-.01,3.13-.07,4.7-.1,.08,0,.13,.09,.08,.16-2.01,2.85-2.96,5.49-3.36,6.93-1.76,.04-3.51,.01-5.27,.02,.49-1.55,1.58-4.24,3.77-6.97,.02-.02,.05-.04,.08-.04Z" />
					<path d="M676.02,387.43h4.7c.08,0,.13,.09,.08,.16-2.05,2.8-3.03,5.43-3.45,6.85h-3.22s0-4.41,0-4.41c.5-.82,1.09-1.69,1.8-2.55,.02-.02,.05-.04,.08-.04Z" />
					<path d="M700.95,394.31l-2.05,.05c.33-1.07,.94-2.67,1.98-4.47l.07,4.42Z" />
					<path d="M689.37,387.41h4.7c.08,0,.13,.09,.08,.16-2.02,2.8-2.98,5.43-3.39,6.85h-5.27c.5-1.55,1.6-4.24,3.8-6.97,.02-.02,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M687.33,377.36h-4.67s-.06,0-.08-.03c-2.21-2.73-3.31-5.41-3.81-6.96h5.19c.42,1.42,1.4,4.04,3.45,6.84,.05,.07,0,.16-.08,.16Z" />
					<path d="M700.59,377.25c-1.56,.03-3.11,.09-4.67,.1-.03,0-.06-.01-.08-.04-2.24-2.72-3.36-5.41-3.87-6.96,1.73,0,3.46,.01,5.19-.02,.43,1.41,1.43,4.01,3.51,6.76,.05,.07,0,.16-.08,.16Z" />
					<path d="M680.7,377.36h-4.67s-.06,0-.08-.03c-.7-.87-1.28-1.73-1.78-2.55v-4.42s3.18,0,3.18,0c.41,1.42,1.38,4.04,3.42,6.84,.05,.07,0,.16-.08,.16Z" />
					<path d="M698.57,370.29l2.02-.05,.07,4.42c-1.09-1.74-1.73-3.32-2.08-4.37Z" />
					<path d="M693.96,377.35h-4.67s-.06,0-.08-.03c-2.22-2.72-3.33-5.41-3.84-6.96h5.19c.43,1.42,1.42,4.04,3.48,6.84,.05,.07,0,.16-.08,.16Z" />
				</g>
			</g>
			<g>
				<path d="M647.88,378.68s.07-.06,.11-.06l4.58,.1c.1,0,.16,.11,.11,.2l-.39,.65c-.12,.2-.06,.47,.15,.6l.56,.34c.21,.12,.47,.06,.59-.15l.91-1.53s.07-.06,.11-.06l4.58,.1c.1,0,.16,.11,.11,.2l-.38,.65c-.12,.2-.05,.47,.15,.6l.56,.34c.21,.12,.47,.06,.59-.15l.91-1.53s.07-.06,.11-.06h4.58c.1,0,.16,.12,.11,.2l-.38,.66c-.12,.21-.05,.47,.15,.59l.57,.33c.21,.12,.47,.05,.59-.16l.9-1.55s.07-.07,.11-.07h4.58c.1,0,.16,.1,.11,.19l-.38,.66c-.12,.21-.05,.47,.16,.59l.57,.33c.21,.12,.47,.05,.59-.16l.62-1.08v5.94s-.63-1.08-.63-1.08c-.12-.21-.38-.28-.59-.16l-.57,.33c-.21,.12-.28,.39-.16,.59l.38,.65c.05,.09-.01,.2-.11,.2h-4.6s-.09-.02-.11-.06l-.88-1.55c-.12-.21-.38-.28-.59-.16l-.57,.33c-.21,.12-.28,.39-.16,.59l.37,.65c.05,.09-.01,.2-.12,.2h-4.6s-.09-.03-.11-.08l-.88-1.57c-.12-.21-.38-.29-.59-.17l-.57,.32c-.21,.12-.28,.38-.17,.59l.37,.66c.05,.09-.02,.2-.12,.19l-4.6-.1s-.09-.03-.11-.07l-.87-1.57c-.12-.21-.38-.29-.59-.17l-.57,.32c-.21,.12-.29,.38-.17,.59l.37,.66c.05,.09-.02,.2-.12,.19l-4.6-.1s-.09-.03-.11-.07l-.26-.47,.12-5.94,.28-.46Z" />
				<g>
					<path d="M655.99,387.32l4.7,.1c.08,0,.13,.1,.08,.16-2.08,2.76-3.1,5.36-3.54,6.77l-5.27-.11c.53-1.54,1.69-4.21,3.95-6.88,.02-.02,.05-.04,.08-.04Z" />
					<path d="M669.34,387.43h4.7c.08,0,.13,.09,.08,.16-2.06,2.8-3.05,5.43-3.48,6.85h-5.27c.52-1.55,1.65-4.24,3.89-6.97,.02-.02,.05-.04,.08-.04Z" />
					<path d="M649.32,387.17l4.7,.1c.08,0,.13,.1,.08,.16-2.1,2.76-3.12,5.36-3.57,6.77l-3.22-.07,.09-4.42c.51-.81,1.12-1.66,1.84-2.51,.02-.02,.05-.04,.08-.04Z" />
					<path d="M674.13,394.43h-2.05c.35-1.06,.99-2.65,2.06-4.42v4.42Z" />
					<path d="M662.67,387.44h4.7c.08,0,.13,.09,.08,.16-2.07,2.8-3.08,5.43-3.51,6.85-1.76,.02-3.51-.02-5.27-.06,.53-1.55,1.67-4.19,3.92-6.9,.02-.02,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M660.8,377.36l-4.67-.1s-.06-.02-.08-.04c-2.16-2.77-3.22-5.49-3.69-7.05l5.19,.11c.4,1.43,1.33,4.07,3.34,6.92,.05,.07,0,.16-.08,.16Z" />
					<path d="M674.07,377.37h-4.67s-.06,0-.08-.03c-2.19-2.72-3.27-5.41-3.75-6.96h5.19c.41,1.42,1.36,4.04,3.4,6.84,.05,.07,0,.16-.08,.16Z" />
					<path d="M654.17,377.22l-4.67-.1s-.06-.02-.08-.04c-.68-.88-1.25-1.76-1.73-2.59l.09-4.42,3.17,.07c.39,1.43,1.31,4.07,3.31,6.92,.05,.07,0,.16-.08,.16Z" />
					<path d="M672.16,370.37h2.02s0,4.41,0,4.41c-1.06-1.77-1.67-3.36-2.01-4.41Z" />
					<path d="M667.43,377.38h-4.67s-.06,0-.08-.03c-2.18-2.74-3.24-5.48-3.72-7.03,1.73,.04,3.46,.08,5.19,.06,.4,1.42,1.35,4.04,3.37,6.84,.05,.07,0,.16-.08,.16Z" />
				</g>
			</g>
			<g>
				<path d="M621.33,377.98s.07-.06,.12-.06l4.58,.2c.1,0,.16,.12,.11,.2l-.4,.64c-.13,.2-.06,.47,.14,.6l.56,.35c.2,.13,.47,.07,.6-.13l.94-1.53s.07-.06,.12-.06l4.58,.1c.1,0,.16,.11,.11,.2l-.4,.65c-.13,.2-.06,.47,.14,.6l.56,.34c.2,.12,.47,.06,.6-.15l.93-1.53s.07-.06,.12-.06l4.58,.1c.1,0,.16,.11,.11,.2l-.39,.65c-.12,.2-.06,.47,.14,.6l.56,.34c.2,.12,.47,.06,.59-.15l.93-1.53s.07-.06,.11-.06l4.58,.1c.1,0,.16,.11,.11,.2l-.39,.65c-.12,.2-.06,.47,.15,.6l.56,.34c.2,.12,.47,.06,.59-.15l.64-1.07-.12,5.94-.6-1.09c-.12-.21-.38-.29-.59-.17l-.58,.32c-.21,.12-.29,.38-.17,.59l.37,.66c.05,.09-.02,.2-.12,.19l-4.6-.1s-.09-.03-.11-.07l-.86-1.57c-.11-.21-.38-.29-.59-.17l-.58,.32c-.21,.12-.29,.38-.17,.59l.36,.66c.05,.09-.02,.2-.12,.19l-4.6-.1s-.09-.03-.11-.07l-.85-1.57c-.11-.21-.38-.29-.59-.17l-.58,.32c-.21,.12-.29,.38-.18,.59l.36,.66c.05,.09-.02,.2-.12,.19l-4.6-.1s-.09-.03-.11-.07l-.84-1.57c-.11-.21-.38-.29-.59-.18l-.58,.3c-.21,.11-.29,.37-.18,.58l.36,.67c.05,.09-.02,.2-.12,.19l-4.6-.2s-.09-.03-.11-.07l-.25-.48,.22-5.94,.29-.46Z" />
				<g>
					<path d="M629.3,386.74l4.7,.1c.08,0,.13,.1,.08,.16-2.13,2.75-3.19,5.35-3.66,6.77-1.76-.05-3.51-.05-5.27-.15,.56-1.52,1.76-4.16,4.07-6.85,.02-.02,.05-.04,.08-.04Z" />
					<path d="M642.64,387.03l4.7,.1c.08,0,.13,.1,.08,.16-2.11,2.76-3.14,5.36-3.6,6.77l-5.27-.11c.55-1.54,1.73-4.2,4.01-6.88,.02-.02,.05-.04,.08-.04Z" />
					<path d="M622.62,386.51l4.7,.19c.08,0,.13,.1,.08,.16-2.14,2.74-3.21,5.29-3.68,6.7l-3.22-.14,.16-4.41c.53-.8,1.15-1.64,1.88-2.47,.02-.02,.05-.04,.08-.03Z" />
					<path d="M647.31,394.14l-2.05-.04c.37-1.05,1.03-2.63,2.13-4.37l-.09,4.42Z" />
					<path d="M635.97,386.89l4.7,.1c.08,0,.13,.1,.08,.16-2.12,2.75-3.17,5.36-3.63,6.77l-5.27-.11c.55-1.54,1.75-4.2,4.04-6.88,.02-.02,.05-.04,.08-.04Z" />
				</g>
				<g>
					<path d="M634.28,376.79l-4.67-.1s-.06-.02-.08-.04c-2.12-2.76-3.12-5.5-3.57-7.08,1.73,.1,3.46,.09,5.19,.14,.37,1.43,1.26,4.07,3.22,6.92,.05,.07,0,.16-.08,.16Z" />
					<path d="M647.54,377.08l-4.67-.1s-.06-.02-.08-.04c-2.14-2.77-3.17-5.49-3.63-7.05l5.19,.11c.38,1.43,1.29,4.07,3.28,6.92,.05,.07,0,.16-.08,.16Z" />
					<path d="M627.65,376.65l-4.67-.19s-.06-.02-.08-.04c-.67-.9-1.22-1.78-1.69-2.63l.16-4.41,3.17,.14c.37,1.43,1.24,4.12,3.19,6.98,.05,.07,0,.16-.08,.16Z" />
					<path d="M645.76,370.03l2.02,.04-.09,4.42c-1.02-1.79-1.61-3.39-1.93-4.46Z" />
					<path d="M640.91,376.93l-4.67-.1s-.06-.02-.08-.04c-2.13-2.77-3.15-5.49-3.6-7.05l5.19,.11c.38,1.43,1.28,4.07,3.25,6.92,.05,.07,0,.16-.08,.16Z" />
				</g>
			</g>
			<g>
				<path d="M594.8,376.81s.07-.06,.12-.06l4.58,.2c.1,0,.16,.12,.1,.2l-.41,.64c-.13,.2-.07,.47,.13,.6l.55,.35c.2,.13,.47,.07,.6-.13l.97-1.51s.07-.06,.12-.06l4.58,.2c.1,0,.16,.12,.1,.2l-.41,.64c-.13,.2-.07,.47,.13,.6l.55,.35c.2,.13,.47,.07,.6-.13l.96-1.51s.07-.06,.12-.06l4.58,.2c.1,0,.16,.12,.1,.2l-.4,.64c-.13,.2-.07,.47,.13,.6l.55,.35c.2,.13,.47,.07,.6-.13l.95-1.51s.07-.06,.12-.06l4.58,.2c.1,0,.16,.12,.11,.2l-.4,.64c-.13,.2-.07,.47,.14,.6l.56,.35c.2,.13,.47,.07,.6-.13l.66-1.05-.22,5.94-.58-1.11c-.11-.21-.37-.3-.59-.18l-.58,.3c-.21,.11-.29,.37-.18,.58l.35,.67c.05,.09-.02,.2-.12,.19l-4.6-.2s-.09-.03-.11-.07l-.83-1.59c-.11-.21-.37-.3-.59-.18l-.58,.3c-.21,.11-.29,.37-.18,.58l.35,.67c.05,.09-.02,.2-.12,.19l-4.6-.2s-.09-.03-.11-.07l-.82-1.59c-.11-.21-.37-.29-.58-.18l-.58,.3c-.21,.11-.3,.37-.19,.58l.35,.67c.05,.09-.02,.2-.12,.19l-4.6-.2s-.09-.03-.11-.07l-.82-1.59c-.11-.21-.37-.29-.58-.18l-.59,.3c-.21,.11-.3,.37-.19,.58l.35,.67c.05,.09-.02,.2-.12,.19l-4.6-.2s-.09-.03-.11-.07l-.25-.48,.32-5.93,.29-.45Z" />
				<g>
					<path d="M602.62,385.63l4.7,.21c.08,0,.12,.1,.07,.16-2.18,2.71-3.28,5.28-3.77,6.69l-5.27-.23c.59-1.53,1.84-4.16,4.18-6.79,.02-.02,.05-.04,.08-.03Z" />
					<path d="M615.96,386.21l4.7,.21c.08,0,.13,.1,.07,.16-2.15,2.71-3.24,5.29-3.71,6.69l-5.27-.23c.57-1.53,1.8-4.17,4.12-6.79,.02-.02,.05-.04,.08-.03Z" />
					<path d="M595.95,385.33l4.7,.21c.08,0,.12,.1,.07,.16-2.19,2.71-3.31,5.28-3.8,6.68l-3.22-.14,.24-4.41c.54-.8,1.18-1.63,1.93-2.47,.02-.02,.05-.04,.08-.03Z" />
					<path d="M620.5,393.43l-2.05-.09c.39-1.04,1.08-2.6,2.21-4.32l-.16,4.41Z" />
					<path d="M609.29,385.92l4.7,.21c.08,0,.13,.1,.07,.16-2.17,2.71-3.26,5.28-3.74,6.69l-5.27-.23c.58-1.53,1.82-4.16,4.15-6.79,.02-.02,.05-.04,.08-.03Z" />
				</g>
				<g>
					<path d="M607.77,375.78l-4.67-.21s-.06-.02-.08-.04c-2.07-2.82-3.03-5.56-3.45-7.13l5.19,.23c.35,1.44,1.19,4.1,3.1,6.99,.05,.07,0,.16-.09,.16Z" />
					<path d="M621.02,376.37l-4.67-.21s-.06-.02-.08-.04c-2.09-2.82-3.08-5.56-3.51-7.13l5.19,.23c.36,1.44,1.22,4.1,3.16,6.99,.05,.07,0,.16-.09,.16Z" />
					<path d="M601.15,375.49l-4.67-.21s-.06-.02-.08-.04c-.65-.9-1.19-1.78-1.64-2.63l.24-4.41,3.17,.14c.34,1.44,1.17,4.1,3.07,6.99,.04,.07,0,.16-.09,.16Z" />
					<path d="M619.36,369.28l2.02,.09-.16,4.41c-.99-1.81-1.55-3.43-1.85-4.5Z" />
					<path d="M614.4,376.08l-4.67-.21s-.06-.02-.08-.04c-2.08-2.82-3.05-5.56-3.48-7.13l5.18,.23c.35,1.44,1.21,4.1,3.13,6.99,.05,.07,0,.16-.09,.16Z" />
				</g>
			</g>
			<g>
				<path d="M568.3,375.1s.07-.06,.12-.06l4.57,.3c.1,0,.16,.12,.1,.2l-.42,.63c-.13,.2-.08,.47,.12,.6l.54,.37c.2,.13,.47,.08,.6-.12l.99-1.49s.07-.06,.12-.06l4.57,.3c.1,0,.16,.12,.1,.2l-.42,.63c-.13,.2-.08,.47,.12,.6l.55,.37c.2,.13,.47,.08,.6-.12l.99-1.49s.07-.06,.12-.06l4.57,.3c.1,0,.16,.12,.1,.2l-.41,.63c-.13,.2-.08,.47,.12,.6l.55,.37c.2,.13,.47,.08,.6-.12l.98-1.49s.07-.06,.12-.06l4.57,.29c.1,0,.16,.12,.1,.2l-.41,.64c-.13,.2-.07,.47,.13,.6l.55,.35c.2,.13,.47,.07,.6-.13l.68-1.05-.32,5.93-.56-1.11c-.11-.21-.37-.29-.58-.18l-.59,.3c-.21,.11-.3,.37-.19,.58l.34,.67c.05,.09-.02,.2-.12,.19l-4.59-.29s-.09-.03-.11-.07l-.8-1.6c-.11-.21-.37-.3-.58-.2l-.59,.29c-.21,.11-.3,.37-.19,.58l.34,.68c.05,.09-.02,.2-.12,.19l-4.59-.31s-.09-.03-.11-.07l-.8-1.6c-.11-.21-.37-.3-.58-.2l-.59,.29c-.21,.11-.3,.37-.2,.58l.34,.68c.04,.09-.03,.2-.13,.19l-4.59-.31s-.09-.03-.11-.07l-.79-1.6c-.11-.21-.37-.3-.58-.2l-.59,.29c-.21,.11-.3,.37-.2,.58l.33,.68c.04,.09-.03,.2-.13,.19l-4.59-.31s-.09-.03-.11-.07l-.24-.48,.42-5.92,.3-.45Z" />
				<g>
					<path d="M575.96,384.09l4.69,.31c.08,0,.12,.1,.07,.17-2.22,2.66-3.37,5.21-3.88,6.6l-5.26-.35c.61-1.51,1.91-4.12,4.3-6.7,.02-.02,.05-.03,.08-.03Z" />
					<path d="M589.28,384.98c1.56,.11,3.13,.21,4.69,.27,.08,0,.12,.1,.07,.16-2.2,2.72-3.33,5.26-3.83,6.65l-5.26-.35c.6-1.51,1.87-4.13,4.24-6.7,.02-.02,.05-.03,.08-.03Z" />
					<path d="M569.3,383.65l4.69,.31c.08,0,.12,.1,.07,.17-2.24,2.66-3.4,5.21-3.91,6.6l-3.21-.21,.31-4.41c.56-.79,1.21-1.61,1.97-2.43,.02-.02,.05-.03,.08-.03Z" />
					<path d="M593.7,392.24l-2.05-.09c.4-1.04,1.12-2.6,2.29-4.32l-.24,4.41Z" />
					<path d="M582.62,384.54l4.69,.31c.08,0,.12,.1,.07,.17-2.21,2.66-3.35,5.21-3.85,6.6l-5.26-.35c.61-1.51,1.89-4.12,4.27-6.7,.02-.02,.05-.03,.08-.03Z" />
				</g>
				<g>
					<path d="M581.28,374.37l-4.66-.31s-.06-.02-.08-.04c-2.02-2.87-2.93-5.62-3.33-7.2l5.18,.34c.32,1.45,1.12,4.13,2.98,7.06,.04,.07,0,.16-.09,.16Z" />
					<path d="M594.52,375.2c-1.56-.06-3.11-.15-4.66-.26-.03,0-.06-.02-.08-.04-2.04-2.87-2.98-5.63-3.39-7.21l5.18,.35c.33,1.45,1.15,4.14,3.04,7.01,.04,.07,0,.16-.09,.16Z" />
					<path d="M574.66,373.93l-4.66-.31s-.06-.02-.08-.04c-.64-.91-1.16-1.81-1.6-2.66l.31-4.41,3.16,.21c.32,1.45,1.1,4.13,2.95,7.06,.04,.07,0,.16-.09,.16Z" />
					<path d="M592.98,368.12l2.02,.09-.24,4.41c-.96-1.81-1.5-3.43-1.78-4.5Z" />
					<path d="M587.9,374.81l-4.66-.31s-.06-.02-.08-.04c-2.03-2.87-2.96-5.63-3.36-7.21l5.18,.34c.33,1.45,1.13,4.13,3,7.06,.04,.07,0,.16-.09,.16Z" />
				</g>
			</g>
			<g>
				<path d="M541.83,372.98s.07-.06,.12-.06l4.56,.41c.1,0,.15,.12,.1,.21l-.43,.62c-.14,.2-.09,.47,.11,.6l.54,.38c.2,.14,.47,.09,.6-.11l1.02-1.46s.07-.06,.12-.06l4.56,.41c.1,0,.15,.12,.1,.21l-.43,.62c-.14,.2-.09,.47,.11,.6l.54,.38c.2,.14,.47,.09,.6-.11l1.01-1.46s.07-.06,.12-.06c1.52,.14,3.04,.27,4.57,.36,.1,0,.16,.12,.1,.2l-.43,.63c-.13,.2-.08,.47,.11,.6l.54,.37c.2,.13,.47,.08,.6-.12l1.01-1.48s.07-.06,.12-.06l4.57,.3c.1,0,.16,.12,.1,.2l-.42,.63c-.13,.2-.08,.47,.12,.6l.54,.37c.2,.13,.47,.08,.6-.12l.7-1.04-.42,5.92-.55-1.12c-.1-.21-.36-.3-.58-.2l-.59,.29c-.22,.11-.31,.37-.2,.58l.33,.68c.04,.09-.03,.2-.13,.19l-4.59-.31s-.09-.03-.11-.07l-.78-1.6c-.1-.21-.36-.3-.58-.2l-.59,.29c-.22,.11-.31,.37-.2,.58l.33,.68c.04,.09-.03,.2-.13,.19-1.53-.09-3.06-.22-4.59-.36-.05,0-.09-.03-.11-.08l-.77-1.62c-.1-.22-.36-.31-.58-.21l-.59,.28c-.22,.1-.31,.36-.21,.58l.32,.69c.04,.09-.03,.2-.13,.19l-4.59-.41s-.09-.03-.11-.08l-.76-1.62c-.1-.22-.36-.31-.58-.21l-.59,.28c-.22,.1-.31,.36-.21,.58l.32,.69c.04,.09-.03,.19-.13,.19l-4.59-.41s-.09-.03-.11-.08l-.23-.49,.52-5.92,.31-.44Z" />
				<g>
					<path d="M549.33,382.15l4.68,.42c.08,0,.12,.11,.07,.17-2.27,2.61-3.46,5.14-3.99,6.52l-5.25-.47c.64-1.5,1.98-4.08,4.41-6.6,.02-.02,.05-.03,.08-.03Z" />
					<path d="M562.64,383.21l4.69,.31c.08,0,.12,.1,.07,.17-2.25,2.66-3.42,5.21-3.94,6.6l-5.26-.35c.63-1.51,1.94-4.12,4.36-6.69,.02-.02,.05-.03,.08-.03Z" />
					<path d="M542.68,381.55l4.68,.42c.08,0,.12,.11,.07,.17-2.28,2.61-3.48,5.13-4.02,6.51l-3.21-.29,.39-4.4c.57-.78,1.23-1.58,2.01-2.38,.02-.02,.05-.03,.08-.03Z" />
					<path d="M566.93,390.51l-2.04-.14c.42-1.03,1.17-2.58,2.36-4.27l-.31,4.41Z" />
					<path d="M555.98,382.74c1.56,.15,3.13,.22,4.69,.34,.08,0,.12,.1,.07,.17-2.26,2.66-3.44,5.2-3.97,6.6l-5.25-.46c.63-1.5,1.96-4.08,4.38-6.6,.02-.02,.05-.03,.08-.03Z" />
				</g>
				<g>
					<path d="M554.82,372.54l-4.65-.42s-.06-.02-.08-.05c-1.97-2.91-2.83-5.69-3.2-7.28l5.17,.46c.3,1.45,1.04,4.15,2.85,7.13,.04,.07-.01,.16-.09,.15Z" />
					<path d="M568.05,373.49l-4.66-.31s-.06-.02-.08-.04c-2-2.87-2.89-5.62-3.27-7.2l5.18,.34c.31,1.45,1.08,4.13,2.92,7.05,.04,.07,0,.16-.09,.16Z" />
					<path d="M548.21,371.95l-4.65-.42s-.06-.02-.08-.05c-.62-.92-1.13-1.84-1.55-2.7l.39-4.4,3.16,.28c.29,1.45,1.03,4.15,2.82,7.12,.04,.07-.01,.16-.09,.15Z" />
					<path d="M566.63,366.37l2.01,.13-.31,4.41c-.93-1.84-1.44-3.46-1.7-4.54Z" />
					<path d="M561.43,373.04c-1.55-.11-3.11-.18-4.66-.33-.03,0-.06-.02-.08-.05-1.98-2.91-2.86-5.69-3.23-7.28l5.17,.46c.3,1.45,1.06,4.12,2.89,7.05,.04,.07,0,.16-.09,.16Z" />
				</g>
			</g>
			<g>
				<path d="M515.4,370.45s.07-.06,.12-.05l4.55,.51c.1,.01,.15,.13,.09,.21l-.44,.61c-.14,.19-.1,.46,.1,.61l.53,.39c.19,.14,.46,.1,.6-.09l1.04-1.44s.07-.06,.12-.05c1.52,.18,3.04,.28,4.56,.43,.1,0,.15,.12,.09,.21l-.44,.62c-.14,.2-.09,.47,.1,.6l.53,.38c.19,.14,.46,.09,.6-.11l1.04-1.46s.07-.06,.12-.06l4.56,.41c.1,0,.15,.12,.09,.21l-.44,.62c-.14,.2-.09,.47,.1,.6l.53,.38c.2,.14,.47,.09,.6-.11l1.03-1.46s.07-.06,.12-.06l4.56,.41c.1,0,.15,.12,.1,.21l-.43,.62c-.14,.2-.09,.47,.1,.6l.54,.38c.2,.14,.46,.09,.6-.11l.71-1.02-.52,5.92-.53-1.13c-.1-.22-.36-.31-.58-.21l-.6,.28c-.22,.1-.31,.36-.21,.58l.32,.69c.04,.09-.03,.19-.13,.19l-4.59-.41s-.09-.03-.11-.08l-.75-1.62c-.1-.22-.36-.31-.57-.21l-.6,.28c-.22,.1-.31,.36-.21,.58l.32,.69c.04,.09-.03,.19-.13,.19l-4.59-.41s-.09-.03-.11-.08l-.74-1.62c-.1-.22-.36-.31-.57-.21l-.6,.28c-.22,.1-.31,.36-.22,.58l.31,.69c.04,.09-.03,.19-.13,.19-1.53-.15-3.06-.25-4.58-.43-.05,0-.09-.03-.11-.08l-.73-1.64c-.1-.22-.35-.32-.57-.22l-.6,.26c-.22,.1-.32,.35-.22,.57l.31,.69c.04,.09-.03,.19-.13,.18l-4.58-.51s-.09-.03-.11-.08l-.22-.5,.62-5.91,.32-.44Z" />
				<g>
					<path d="M522.74,379.77l4.68,.42c.08,0,.12,.11,.06,.17-2.32,2.6-3.55,5.13-4.11,6.51-1.75-.14-3.5-.35-5.24-.54,.66-1.49,2.04-4.03,4.52-6.51,.02-.02,.05-.03,.08-.03Z" />
					<path d="M536.03,380.96l4.68,.42c.08,0,.12,.11,.07,.17-2.29,2.61-3.51,5.13-4.05,6.51l-5.25-.47c.65-1.5,2.01-4.08,4.47-6.6,.02-.02,.05-.03,.08-.03Z" />
					<path d="M516.1,379.04l4.67,.52c.08,0,.12,.11,.06,.17-2.32,2.56-3.57,5.06-4.13,6.43l-3.2-.36,.46-4.39c.58-.76,1.26-1.55,2.05-2.34,.02-.02,.05-.03,.08-.03Z" />
					<path d="M540.2,388.36l-2.04-.18c.44-1.02,1.21-2.55,2.43-4.22l-.39,4.4Z" />
					<path d="M529.38,380.37l4.68,.42c.08,0,.12,.11,.06,.17-2.3,2.61-3.53,5.13-4.08,6.51l-5.25-.47c.66-1.5,2.03-4.08,4.5-6.59,.02-.02,.05-.03,.08-.03Z" />
				</g>
				<g>
					<path d="M528.39,370.18l-4.65-.41s-.06-.02-.08-.05c-1.92-2.94-2.73-5.75-3.07-7.35,1.72,.19,3.44,.4,5.16,.54,.27,1.45,.98,4.15,2.74,7.12,.04,.07-.01,.16-.1,.15Z" />
					<path d="M541.6,371.36l-4.65-.41s-.06-.02-.08-.05c-1.95-2.91-2.79-5.69-3.14-7.28l5.17,.46c.29,1.45,1.01,4.15,2.79,7.12,.04,.07-.01,.16-.09,.15Z" />
					<path d="M521.79,369.56l-4.64-.52s-.06-.02-.08-.05c-.6-.94-1.1-1.86-1.5-2.73l.46-4.39,3.15,.35c.27,1.46,.96,4.18,2.7,7.19,.04,.07-.01,.16-.1,.15Z" />
					<path d="M540.31,364.21l2.01,.18-.39,4.4c-.9-1.86-1.38-3.49-1.62-4.58Z" />
					<path d="M535,370.77l-4.65-.41s-.06-.02-.08-.05c-1.93-2.91-2.76-5.68-3.11-7.27l5.17,.46c.28,1.45,.99,4.15,2.77,7.12,.04,.07-.01,.16-.09,.15Z" />
				</g>
			</g>
			<g>
				<path d="M489,367.49s.07-.06,.12-.05l4.55,.51c.1,.01,.15,.13,.09,.21l-.45,.61c-.14,.19-.1,.46,.09,.61l.53,.39c.19,.14,.46,.1,.61-.09l1.07-1.44s.07-.06,.12-.05l4.55,.51c.1,.01,.15,.13,.09,.21l-.45,.61c-.14,.19-.1,.46,.09,.61l.53,.39c.19,.14,.46,.1,.61-.09l1.06-1.44s.07-.06,.12-.05l4.55,.51c.1,.01,.15,.13,.09,.21l-.45,.61c-.14,.19-.1,.46,.09,.61l.53,.39c.19,.14,.46,.1,.6-.09l1.06-1.44s.07-.06,.12-.05l4.55,.51c.1,.01,.15,.13,.09,.21l-.44,.61c-.14,.19-.1,.46,.09,.61l.53,.39c.19,.14,.46,.1,.6-.09l.73-1.01-.62,5.91-.51-1.14c-.1-.22-.35-.32-.57-.22l-.6,.26c-.22,.1-.32,.35-.22,.57l.31,.69c.04,.09-.03,.19-.13,.18l-4.58-.51s-.09-.03-.11-.08l-.72-1.64c-.1-.22-.35-.32-.57-.22l-.6,.26c-.22,.1-.32,.35-.22,.57l.3,.69c.04,.09-.03,.19-.13,.18l-4.58-.51s-.09-.03-.11-.08l-.71-1.64c-.1-.22-.35-.32-.57-.22l-.6,.26c-.22,.1-.32,.35-.23,.57l.3,.69c.04,.09-.04,.19-.14,.18l-4.58-.51s-.09-.03-.11-.08l-.71-1.64c-.09-.22-.35-.32-.57-.22l-.6,.26c-.22,.1-.32,.35-.23,.57l.3,.69c.04,.09-.04,.19-.14,.18l-4.58-.51s-.09-.03-.1-.08l-.21-.49,.73-5.9,.32-.43Z" />
				<g>
					<path d="M496.2,376.82l4.67,.52c.08,0,.12,.11,.06,.17-2.36,2.55-3.64,5.05-4.22,6.42l-5.24-.59c.69-1.48,2.12-4.03,4.64-6.49,.02-.02,.05-.03,.08-.03Z" />
					<path d="M509.47,378.3l4.67,.52c.08,0,.12,.11,.06,.17-2.33,2.56-3.59,5.05-4.16,6.42l-5.24-.59c.68-1.49,2.08-4.03,4.58-6.5,.02-.02,.05-.03,.08-.03Z" />
					<path d="M489.56,376.08l4.67,.52c.08,0,.12,.11,.06,.17-2.37,2.55-3.66,5.05-4.25,6.41l-3.2-.36,.54-4.38c.6-.76,1.29-1.55,2.09-2.33,.02-.02,.05-.03,.08-.03Z" />
					<path d="M513.5,385.8l-2.04-.23c.46-1.01,1.25-2.52,2.5-4.17l-.46,4.39Z" />
					<path d="M502.83,377.56l4.67,.52c.08,0,.12,.11,.06,.17-2.35,2.55-3.61,5.05-4.19,6.42l-5.24-.59c.68-1.48,2.1-4.03,4.61-6.5,.02-.02,.05-.03,.08-.03Z" />
				</g>
				<g>
					<path d="M502.01,367.35l-4.64-.52s-.06-.02-.07-.05c-1.87-2.95-2.64-5.75-2.95-7.34l5.16,.58c.25,1.46,.9,4.17,2.61,7.18,.04,.07-.02,.16-.1,.15Z" />
					<path d="M515.2,368.82l-4.64-.52s-.06-.02-.07-.05c-1.89-2.96-2.69-5.75-3.01-7.35l5.16,.58c.26,1.46,.94,4.18,2.67,7.19,.04,.07-.01,.16-.1,.15Z" />
					<path d="M495.42,366.61l-4.64-.52s-.06-.02-.07-.05c-.59-.94-1.07-1.86-1.46-2.73l.54-4.38,3.15,.35c.24,1.46,.89,4.17,2.58,7.18,.04,.07-.02,.16-.1,.15Z" />
					<path d="M514.03,361.64l2.01,.22-.46,4.39c-.87-1.88-1.31-3.53-1.54-4.62Z" />
					<path d="M508.61,368.08l-4.64-.52s-.06-.02-.07-.05c-1.88-2.95-2.66-5.75-2.98-7.34l5.16,.58c.25,1.46,.92,4.17,2.64,7.18,.04,.07-.02,.16-.1,.15Z" />
				</g>
			</g>
			<g>
				<path d="M462.68,363.96s.07-.06,.12-.05l4.54,.61c.1,.01,.15,.13,.09,.21l-.46,.6c-.15,.19-.11,.46,.08,.61l.52,.4c.19,.15,.46,.11,.61-.08l1.09-1.42s.07-.06,.12-.05l4.54,.61c.1,.01,.15,.13,.09,.21l-.46,.6c-.15,.19-.11,.46,.08,.61l.52,.4c.19,.15,.46,.11,.61-.08l1.09-1.42s.07-.06,.12-.05l4.54,.61c.1,.01,.15,.13,.09,.21l-.46,.6c-.15,.19-.11,.46,.08,.61l.52,.4c.19,.15,.46,.11,.61-.08l1.08-1.42s.07-.06,.12-.05l4.54,.61c.1,.01,.15,.13,.09,.21l-.45,.6c-.14,.19-.11,.46,.08,.61l.52,.4c.19,.15,.46,.11,.6-.08l.75-.99-.73,5.9-.49-1.15c-.09-.22-.35-.33-.57-.24l-.6,.25c-.22,.09-.32,.34-.23,.57l.29,.7c.04,.09-.04,.19-.14,.18l-4.56-.61s-.09-.04-.1-.08l-.69-1.66c-.09-.22-.35-.33-.57-.24l-.61,.25c-.22,.09-.32,.34-.23,.57l.29,.7c.04,.09-.04,.19-.14,.18l-4.56-.61s-.09-.04-.1-.08l-.68-1.65c-.09-.22-.34-.33-.57-.24l-.61,.25c-.22,.09-.33,.34-.24,.57l.29,.7c.04,.09-.04,.19-.14,.18l-4.56-.61s-.09-.04-.1-.08l-.68-1.65c-.09-.22-.34-.33-.57-.24l-.61,.25c-.22,.09-.33,.34-.24,.57l.29,.7c.04,.09-.04,.19-.14,.18l-4.56-.61s-.09-.04-.1-.08l-.2-.5,.83-5.88,.33-.43Z" />
				<g>
					<path d="M469.71,373.45l4.66,.63c.08,.01,.12,.11,.06,.17-2.4,2.5-3.72,4.97-4.33,6.32l-5.22-.7c.71-1.47,2.18-3.98,4.75-6.39,.02-.02,.05-.03,.08-.03Z" />
					<path d="M482.94,375.23l4.66,.63c.08,0,.12,.11,.06,.17-2.38,2.51-3.68,4.98-4.27,6.33l-5.22-.7c.7-1.47,2.15-3.99,4.69-6.4,.02-.02,.05-.03,.08-.03Z" />
					<path d="M463.09,372.56l4.66,.63c.08,.01,.12,.11,.06,.17-2.41,2.5-3.74,4.97-4.36,6.32l-3.19-.43,.61-4.37c.61-.75,1.31-1.52,2.13-2.29,.02-.02,.05-.03,.08-.03Z" />
					<path d="M486.85,382.82l-2.03-.27c.47-1.01,1.29-2.49,2.57-4.11l-.54,4.38Z" />
					<path d="M476.33,374.34l4.66,.63c.08,.01,.12,.11,.06,.17-2.39,2.5-3.7,4.97-4.3,6.33l-5.22-.7c.71-1.47,2.17-3.99,4.72-6.4,.02-.02,.05-.03,.08-.03Z" />
				</g>
				<g>
					<path d="M475.69,364.11l-4.63-.62s-.06-.02-.07-.05c-1.82-3-2.54-5.81-2.82-7.41l5.14,.69c.22,1.47,.83,4.19,2.49,7.24,.04,.07-.02,.16-.1,.15Z" />
					<path d="M488.83,365.87l-4.63-.62s-.06-.02-.07-.05c-1.84-3-2.59-5.81-2.88-7.42l5.14,.69c.23,1.47,.86,4.2,2.54,7.25,.04,.07-.02,.16-.1,.15Z" />
					<path d="M469.11,363.22l-4.63-.62s-.06-.02-.07-.05c-.57-.95-1.04-1.88-1.41-2.76l.61-4.37,3.14,.42c.22,1.46,.81,4.19,2.46,7.24,.04,.07-.02,.16-.1,.15Z" />
					<path d="M487.79,358.67l2,.27-.54,4.38c-.83-1.89-1.25-3.56-1.46-4.65Z" />
					<path d="M482.26,364.99l-4.63-.62s-.06-.02-.07-.05c-1.83-3-2.56-5.81-2.85-7.41l5.14,.69c.23,1.47,.85,4.2,2.51,7.25,.04,.07-.02,.16-.1,.15Z" />
				</g>
			</g>
			<g>
				<path d="M436.42,360.02s.08-.05,.12-.05l4.53,.71c.1,.02,.14,.13,.08,.21l-.47,.59c-.15,.19-.12,.46,.07,.61l.51,.41c.19,.15,.46,.12,.61-.06l1.12-1.39s.08-.05,.12-.05l4.53,.71c.1,.02,.15,.13,.08,.21l-.47,.59c-.15,.19-.12,.46,.07,.61l.51,.41c.19,.15,.46,.12,.61-.06l1.11-1.39s.08-.05,.12-.05l4.53,.71c.1,.01,.15,.13,.08,.21l-.47,.59c-.15,.19-.12,.46,.07,.61l.52,.4c.19,.15,.46,.11,.61-.08l1.11-1.41s.08-.06,.12-.05l4.54,.61c.1,.01,.15,.13,.08,.21l-.47,.6c-.15,.19-.11,.46,.07,.61l.52,.4c.19,.15,.46,.11,.61-.08l.77-.99-.83,5.88-.47-1.15c-.09-.22-.34-.33-.56-.24l-.61,.25c-.22,.09-.33,.34-.24,.56l.28,.7c.04,.09-.04,.19-.14,.18l-4.56-.61s-.09-.04-.1-.08l-.66-1.65c-.09-.22-.34-.33-.56-.24l-.61,.25c-.22,.09-.33,.34-.24,.56l.28,.7c.04,.09-.04,.19-.14,.18l-4.55-.72s-.09-.04-.1-.08l-.65-1.67c-.09-.22-.34-.34-.56-.25l-.61,.24c-.22,.09-.33,.34-.25,.56l.28,.71c.04,.09-.04,.19-.14,.18l-4.55-.72s-.09-.04-.1-.08l-.65-1.67c-.09-.22-.34-.33-.56-.25l-.61,.24c-.22,.09-.33,.34-.25,.56l.27,.71c.04,.09-.04,.19-.14,.18l-4.55-.72s-.08-.04-.1-.08l-.19-.5,.93-5.87,.34-.42Z" />
				<g>
					<path d="M443.28,369.67l4.65,.73c.08,.01,.11,.11,.06,.17-2.44,2.45-3.8,4.89-4.43,6.23l-5.21-.82c.74-1.45,2.25-3.94,4.85-6.29,.02-.02,.05-.03,.08-.03Z" />
					<path d="M456.48,371.67l4.66,.63c.08,.01,.11,.11,.06,.17-2.42,2.5-3.77,4.96-4.39,6.32l-5.22-.71c.73-1.45,2.22-3.98,4.81-6.38,.02-.02,.05-.03,.08-.03Z" />
					<path d="M436.69,368.63l4.65,.73c.08,.01,.11,.11,.06,.17-2.45,2.45-3.83,4.89-4.46,6.23l-3.18-.5,.69-4.36c.62-.74,1.34-1.49,2.17-2.24,.02-.02,.05-.03,.08-.03Z" />
					<path d="M460.26,379.25l-2.03-.27c.49-1,1.34-2.49,2.65-4.1l-.61,4.37Z" />
					<path d="M449.87,370.71c1.55,.24,3.09,.5,4.65,.7,.08,.01,.11,.11,.06,.17-2.44,2.51-3.79,4.92-4.41,6.27l-5.21-.82c.73-1.45,2.23-3.94,4.82-6.29,.02-.02,.05-.03,.08-.03Z" />
				</g>
				<g>
					<path d="M449.41,360.46l-4.62-.73s-.06-.02-.07-.05c-1.77-3.04-2.44-5.87-2.69-7.48l5.13,.81c.2,1.47,.76,4.21,2.36,7.3,.04,.07-.02,.16-.1,.15Z" />
					<path d="M462.54,362.34l-4.63-.62s-.06-.02-.07-.05c-1.8-3-2.5-5.79-2.77-7.41l5.14,.7c.21,1.46,.8,4.19,2.43,7.23,.04,.07-.02,.16-.1,.15Z" />
					<path d="M442.86,359.42l-4.61-.73s-.06-.02-.07-.05c-.56-.96-1-1.91-1.36-2.8l.69-4.36,3.13,.49c.19,1.47,.74,4.21,2.33,7.3,.04,.07-.02,.16-.1,.15Z" />
					<path d="M461.61,355.14l2,.27-.61,4.37c-.8-1.89-1.2-3.55-1.39-4.64Z" />
					<path d="M455.97,361.45c-1.55-.19-3.08-.45-4.62-.69-.03,0-.06-.02-.07-.05-1.78-3.04-2.46-5.87-2.72-7.48l5.13,.81c.2,1.47,.77,4.24,2.39,7.27,.04,.07-.02,.16-.1,.15Z" />
				</g>
			</g>
			<g>
				<path d="M410.23,355.67s.08-.05,.12-.04l4.51,.81c.1,.02,.14,.14,.08,.21l-.48,.58c-.15,.18-.13,.46,.06,.61l.5,.42c.18,.15,.46,.13,.61-.05l1.14-1.37s.08-.05,.12-.04c1.5,.27,3,.56,4.52,.78,.1,.02,.14,.13,.08,.21l-.48,.59c-.15,.19-.13,.46,.06,.61l.51,.41c.18,.15,.46,.12,.61-.06l1.14-1.39s.08-.05,.12-.05l4.53,.71c.1,.02,.14,.13,.08,.21l-.48,.59c-.15,.19-.12,.46,.06,.61l.51,.41c.19,.15,.46,.12,.61-.06l1.13-1.39s.08-.05,.12-.05l4.53,.71c.1,.02,.14,.13,.08,.21l-.48,.59c-.15,.19-.12,.46,.06,.61l.51,.41c.19,.15,.46,.12,.61-.06l.78-.97-.93,5.87-.45-1.16c-.09-.22-.34-.33-.56-.25l-.61,.24c-.22,.09-.34,.34-.25,.56l.27,.71c.04,.09-.04,.19-.14,.18l-4.55-.72s-.09-.04-.1-.08l-.64-1.67c-.08-.22-.33-.33-.56-.25l-.62,.24c-.22,.09-.34,.34-.25,.56l.27,.71c.04,.09-.04,.19-.14,.18l-4.55-.72s-.08-.04-.1-.08l-.63-1.67c-.08-.22-.33-.33-.56-.25l-.62,.24c-.22,.09-.34,.34-.25,.56l.27,.7c.04,.09-.05,.19-.15,.18-1.52-.22-3.03-.51-4.54-.78-.05,0-.08-.04-.1-.08l-.62-1.68c-.08-.23-.33-.34-.56-.26l-.62,.22c-.22,.08-.34,.33-.26,.56l.26,.71c.03,.1-.05,.19-.15,.17l-4.53-.82s-.08-.04-.1-.08l-.19-.51,1.03-5.85,.35-.41Z" />
				<g>
					<path d="M416.91,365.48c1.54,.29,3.09,.52,4.64,.77,.08,.01,.11,.11,.05,.17-2.49,2.44-3.9,4.88-4.55,6.21l-5.19-.94c.76-1.44,2.32-3.89,4.96-6.18,.02-.02,.05-.03,.08-.02Z" />
					<path d="M430.09,367.59l4.65,.73c.08,.01,.11,.11,.05,.17-2.46,2.45-3.85,4.88-4.49,6.22l-5.21-.82c.75-1.45,2.29-3.93,4.91-6.28,.02-.02,.05-.03,.08-.03Z" />
					<path d="M410.34,364.3l4.63,.84c.08,.01,.11,.12,.05,.17-2.49,2.39-3.91,4.8-4.56,6.13l-3.17-.57,.76-4.35c.63-.72,1.36-1.47,2.21-2.19,.02-.02,.05-.03,.08-.02Z" />
					<path d="M433.74,375.26l-2.02-.32c.51-.99,1.38-2.46,2.71-4.04l-.69,4.36Z" />
					<path d="M423.5,366.55l4.65,.73c.08,.01,.11,.11,.05,.17-2.48,2.44-3.87,4.88-4.52,6.22l-5.21-.82c.76-1.45,2.31-3.93,4.94-6.27,.02-.02,.06-.03,.09-.03Z" />
				</g>
				<g>
					<path d="M423.2,356.33c-1.54-.25-3.08-.47-4.61-.76-.03,0-.06-.02-.07-.05-1.71-3.08-2.33-5.93-2.56-7.54l5.11,.92c.17,1.48,.69,4.21,2.24,7.29,.04,.07-.02,.16-.11,.15Z" />
					<path d="M436.31,358.39l-4.62-.73s-.06-.02-.07-.05c-1.74-3.04-2.39-5.86-2.64-7.47l5.13,.81c.18,1.47,.72,4.21,2.3,7.29,.04,.07-.02,.16-.1,.15Z" />
					<path d="M416.67,355.22l-4.6-.83s-.06-.02-.07-.05c-.54-.98-.97-1.93-1.31-2.83l.76-4.35,3.12,.56c.17,1.48,.67,4.23,2.2,7.35,.04,.07-.02,.16-.11,.14Z" />
					<path d="M435.51,351.17l1.99,.31-.69,4.36c-.77-1.91-1.13-3.58-1.3-4.68Z" />
					<path d="M429.76,357.36l-4.62-.73s-.06-.02-.07-.05c-1.73-3.03-2.37-5.86-2.61-7.46l5.13,.81c.18,1.47,.71,4.21,2.27,7.29,.04,.07-.02,.16-.1,.15Z" />
				</g>
			</g>
			<g>
				<path d="M384.09,350.93s.08-.05,.12-.04l4.51,.83c.1,.02,.14,.14,.07,.21l-.49,.58c-.16,.18-.14,.46,.04,.61l.5,.42c.18,.15,.45,.13,.61-.05l1.17-1.36s.08-.05,.12-.04l4.51,.81c.1,.02,.14,.14,.08,.21l-.49,.58c-.16,.18-.13,.46,.05,.61l.5,.42c.18,.15,.46,.13,.61-.05l1.16-1.36s.08-.05,.12-.04l4.51,.81c.1,.02,.14,.14,.08,.21l-.49,.58c-.15,.18-.13,.46,.05,.61l.5,.42c.18,.15,.46,.13,.61-.05l1.15-1.37s.08-.05,.12-.04l4.51,.81c.1,.02,.14,.14,.08,.21l-.49,.58c-.15,.18-.13,.46,.05,.61l.5,.42c.18,.15,.46,.13,.61-.05l.8-.95-1.03,5.85-.43-1.18c-.08-.23-.33-.34-.56-.26l-.62,.22c-.22,.08-.34,.33-.26,.55l.26,.71c.03,.1-.05,.19-.15,.17l-4.53-.82s-.08-.04-.1-.08l-.61-1.68c-.08-.23-.33-.34-.55-.26l-.62,.22c-.23,.08-.34,.33-.26,.55l.26,.71c.03,.09-.05,.19-.15,.17l-4.53-.82s-.08-.04-.1-.08l-.6-1.68c-.08-.23-.33-.34-.55-.26l-.62,.22c-.23,.08-.34,.33-.26,.55l.25,.71c.03,.09-.05,.19-.15,.17l-4.53-.82s-.08-.04-.1-.08l-.59-1.68c-.08-.22-.33-.34-.55-.26l-.62,.22c-.23,.08-.35,.33-.27,.55l.25,.71c.03,.09-.05,.19-.15,.17l-4.52-.83s-.08-.04-.1-.09l-.18-.51,1.12-5.83,.35-.41Z" />
				<g>
					<path d="M390.63,360.74l4.63,.84c.08,.01,.11,.12,.05,.17-2.53,2.39-3.98,4.79-4.65,6.12l-5.19-.94c.79-1.43,2.39-3.88,5.08-6.16,.02-.02,.06-.03,.09-.02Z" />
					<path d="M403.77,363.11l4.63,.84c.08,.01,.11,.12,.05,.17-2.5,2.39-3.93,4.8-4.59,6.13l-5.19-.94c.78-1.44,2.35-3.88,5.02-6.17,.02-.02,.05-.03,.09-.02Z" />
					<path d="M384.06,359.55l4.63,.84c.08,.01,.11,.12,.05,.17-2.54,2.39-4,4.79-4.68,6.11l-3.15-.59,.83-4.34c.64-.71,1.38-1.45,2.24-2.17,.02-.02,.06-.03,.09-.02Z" />
					<path d="M407.28,370.86l-2.02-.36c.53-.98,1.42-2.43,2.78-3.99l-.76,4.35Z" />
					<path d="M397.2,361.92l4.63,.84c.08,.01,.11,.12,.05,.17-2.52,2.39-3.95,4.8-4.62,6.12l-5.19-.94c.78-1.43,2.37-3.88,5.05-6.17,.02-.02,.06-.03,.09-.02Z" />
				</g>
				<g>
					<path d="M397.09,351.68l-4.6-.83s-.06-.02-.07-.05c-1.66-3.07-2.24-5.91-2.44-7.52l5.11,.92c.15,1.47,.62,4.22,2.11,7.34,.04,.07-.03,.16-.11,.14Z" />
					<path d="M410.14,354.04l-4.6-.83s-.06-.02-.07-.05c-1.69-3.08-2.29-5.92-2.5-7.53l5.11,.92c.16,1.47,.65,4.23,2.17,7.35,.04,.07-.03,.16-.11,.14Z" />
					<path d="M390.56,350.5l-4.6-.83s-.06-.03-.07-.05c-.53-.98-.94-1.94-1.27-2.84l.83-4.34,3.13,.59c.14,1.47,.6,4.22,2.08,7.33,.04,.07-.03,.16-.11,.14Z" />
					<path d="M409.46,346.8l1.99,.36-.76,4.35c-.74-1.93-1.07-3.61-1.22-4.71Z" />
					<path d="M403.61,352.86l-4.6-.83s-.06-.02-.07-.05c-1.68-3.07-2.26-5.91-2.47-7.53l5.11,.92c.15,1.47,.63,4.22,2.14,7.34,.04,.07-.03,.16-.11,.14Z" />
				</g>
			</g>
			<g>
				<path d="M357.97,345.89s.08-.05,.12-.04l4.51,.88c.1,.02,.14,.14,.07,.21l-.5,.57c-.16,.18-.14,.45,.04,.61l.5,.43c.18,.16,.45,.14,.61-.04l1.17-1.35s.08-.05,.12-.04l4.51,.87c.1,.02,.14,.14,.07,.21l-.49,.57c-.16,.18-.14,.45,.04,.61l.5,.43c.18,.16,.45,.14,.61-.04l1.17-1.35s.08-.05,.12-.04l4.51,.87c.1,.02,.14,.14,.07,.21l-.49,.57c-.16,.18-.14,.45,.04,.61l.5,.43c.18,.16,.45,.14,.61-.04l1.17-1.35s.08-.05,.12-.04l4.51,.87c.1,.02,.14,.14,.07,.21l-.49,.57c-.16,.18-.14,.45,.04,.61l.5,.43c.18,.16,.45,.14,.61-.04l.82-.94-1.12,5.83-.41-1.18c-.08-.23-.32-.35-.55-.27l-.62,.22c-.23,.08-.35,.33-.27,.55l.25,.71c.03,.1-.05,.19-.15,.17l-4.51-.87s-.08-.04-.1-.09l-.58-1.69c-.08-.23-.32-.35-.55-.27l-.62,.22c-.23,.08-.35,.33-.27,.55l.25,.71c.03,.1-.05,.19-.15,.17l-4.51-.87s-.08-.04-.1-.09l-.58-1.69c-.08-.23-.32-.35-.55-.27l-.62,.21c-.23,.08-.35,.33-.27,.55l.25,.71c.03,.1-.05,.19-.15,.17l-4.51-.87s-.08-.04-.1-.09l-.58-1.69c-.08-.23-.32-.35-.55-.27l-.62,.21c-.23,.08-.35,.32-.27,.55l.25,.71c.03,.1-.05,.19-.15,.17l-4.51-.88s-.08-.04-.1-.09l-.18-.51,1.13-5.83,.35-.41Z" />
				<g>
					<path d="M364.46,355.78l4.61,.89c.08,.02,.11,.12,.05,.17-2.54,2.36-3.99,4.75-4.67,6.07l-5.14-.99c.79-1.43,2.39-3.86,5.08-6.12,.02-.02,.06-.03,.09-.02Z" />
					<path d="M377.53,358.29l4.6,.88c.08,.02,.11,.12,.05,.17-2.54,2.36-3.99,4.76-4.67,6.07l-5.14-.99c.79-1.43,2.38-3.86,5.07-6.12,.02-.02,.06-.03,.09-.02Z" />
					<path d="M357.92,354.51l4.61,.89c.08,.02,.11,.12,.05,.17-2.54,2.36-4,4.75-4.68,6.07l-3.15-.61,.84-4.34c.64-.71,1.39-1.45,2.24-2.16,.02-.02,.06-.03,.09-.02Z" />
					<path d="M380.91,366.08l-2-.38c.54-.97,1.45-2.42,2.83-3.95l-.83,4.34Z" />
					<path d="M370.99,357.04l4.6,.89c.08,.02,.11,.12,.05,.17-2.54,2.36-3.99,4.76-4.67,6.07l-5.14-.99c.79-1.43,2.39-3.86,5.07-6.12,.02-.02,.06-.03,.09-.02Z" />
				</g>
				<g>
					<path d="M370.96,346.79l-4.6-.89s-.06-.03-.07-.05c-1.65-3.1-2.23-5.95-2.42-7.56l5.13,.99c.14,1.48,.6,4.24,2.08,7.37,.04,.07-.03,.16-.11,.14Z" />
					<path d="M384.03,349.3l-4.6-.88s-.06-.03-.07-.05c-1.66-3.1-2.23-5.94-2.44-7.56l5.14,.99c.14,1.48,.6,4.23,2.08,7.37,.04,.07-.03,.16-.11,.14Z" />
					<path d="M364.43,345.53l-4.6-.89s-.06-.03-.07-.05c-.52-.98-.94-1.94-1.26-2.85l.84-4.34,3.13,.61c.14,1.48,.59,4.23,2.07,7.37,.04,.07-.03,.16-.11,.14Z" />
					<path d="M383.45,342.06l2,.38-.83,4.34c-.71-1.94-1.03-3.62-1.16-4.72Z" />
					<path d="M377.5,348.04l-4.6-.88s-.06-.03-.07-.05c-1.65-3.1-2.23-5.95-2.43-7.56l5.13,.99c.14,1.48,.6,4.23,2.08,7.37,.04,.07-.03,.16-.11,.14Z" />
				</g>
			</g>
			<g>
				<path d="M331.89,340.72s.08-.05,.12-.04l4.49,.91c.1,.02,.14,.14,.07,.21l-.5,.57c-.16,.18-.14,.45,.04,.61l.49,.43c.18,.16,.45,.14,.61-.04l1.18-1.34s.08-.05,.12-.04l4.5,.9c.1,.02,.14,.14,.07,.22l-.5,.57c-.16,.18-.14,.45,.04,.61l.49,.43c.18,.16,.45,.14,.61-.04l1.18-1.34s.08-.05,.12-.04l4.5,.89c.1,.02,.14,.14,.07,.21l-.5,.57c-.16,.18-.14,.45,.04,.61l.49,.43c.18,.16,.45,.14,.61-.04l1.17-1.35s.08-.05,.12-.04l4.5,.88c.1,.02,.14,.14,.07,.21l-.5,.57c-.16,.18-.14,.45,.04,.61l.49,.43c.18,.16,.45,.14,.61-.04l.82-.94-1.13,5.83-.41-1.18c-.08-.23-.32-.35-.55-.27l-.62,.21c-.23,.08-.35,.32-.27,.55l.25,.72c.03,.1-.05,.19-.15,.17l-4.51-.88s-.08-.04-.1-.09l-.58-1.69c-.08-.23-.32-.35-.55-.27l-.62,.21c-.23,.08-.35,.32-.27,.55l.24,.72c.03,.1-.05,.19-.15,.17l-4.51-.89s-.08-.04-.1-.09l-.58-1.69c-.08-.23-.32-.35-.55-.27l-.62,.21c-.23,.08-.35,.32-.27,.55l.24,.72c.03,.1-.05,.19-.15,.17l-4.51-.9s-.08-.04-.1-.09l-.57-1.69c-.08-.23-.32-.35-.55-.27l-.62,.21c-.23,.08-.35,.32-.27,.55l.24,.72c.03,.1-.05,.19-.15,.17l-4.51-.92s-.08-.04-.1-.09l-.17-.51,1.18-5.82,.36-.4Z" />
				<g>
					<path d="M338.31,350.65l4.6,.92c.08,.02,.11,.12,.05,.17-2.55,2.35-4.03,4.73-4.72,6.04l-5.15-1.03c.8-1.42,2.42-3.84,5.13-6.08,.02-.02,.06-.03,.09-.02Z" />
					<path d="M351.38,353.23l4.61,.9c.08,.02,.11,.12,.05,.17-2.55,2.36-4.01,4.74-4.69,6.06l-5.15-1.01c.8-1.43,2.4-3.85,5.1-6.1,.02-.02,.06-.03,.09-.02Z" />
					<path d="M331.77,349.34l4.6,.93c.08,.02,.11,.12,.05,.17-2.56,2.34-4.04,4.73-4.73,6.03l-3.15-.64,.88-4.33c.65-.71,1.4-1.43,2.26-2.14,.02-.02,.06-.03,.09-.02Z" />
					<path d="M354.75,361.03l-2-.39c.54-.97,1.46-2.41,2.85-3.95l-.84,4.34Z" />
					<path d="M344.84,351.95l4.61,.91c.08,.02,.11,.12,.05,.17-2.55,2.35-4.01,4.74-4.7,6.05l-5.15-1.03c.8-1.42,2.41-3.84,5.11-6.09,.02-.02,.06-.03,.09-.02Z" />
				</g>
				<g>
					<path d="M344.87,341.7l-4.59-.91s-.06-.03-.07-.05c-1.63-3.11-2.18-5.96-2.37-7.58l5.11,1.02c.13,1.48,.57,4.24,2.03,7.38,.03,.07-.03,.16-.11,.14Z" />
					<path d="M357.91,344.26l-4.59-.9s-.06-.03-.07-.05c-1.64-3.1-2.21-5.95-2.4-7.57l5.12,1c.14,1.48,.59,4.24,2.06,7.38,.04,.07-.03,.16-.11,.14Z" />
					<path d="M338.35,340.4l-4.59-.92s-.06-.03-.07-.05c-.51-.99-.92-1.95-1.24-2.86l.88-4.33,3.12,.64c.12,1.48,.56,4.24,2.01,7.38,.03,.07-.03,.16-.11,.14Z" />
					<path d="M357.35,337.01l1.99,.39-.84,4.34c-.71-1.94-1.01-3.62-1.15-4.72Z" />
					<path d="M351.38,342.99l-4.59-.9s-.06-.03-.07-.05c-1.64-3.11-2.19-5.96-2.38-7.58l5.11,1.02c.13,1.48,.58,4.24,2.05,7.38,.04,.07-.03,.16-.11,.14Z" />
				</g>
			</g>
			<g>
				<path d="M305.89,335.26s.08-.05,.12-.04l4.48,.97c.1,.02,.14,.14,.07,.22l-.51,.56c-.16,.18-.15,.45,.03,.61l.49,.44c.18,.16,.45,.15,.61-.03l1.2-1.33s.08-.05,.12-.04l4.49,.95c.1,.02,.14,.14,.07,.22l-.5,.56c-.16,.18-.15,.45,.03,.61l.49,.44c.18,.16,.45,.15,.61-.03l1.19-1.33s.08-.05,.12-.04l4.49,.94c.1,.02,.14,.14,.07,.22l-.5,.57c-.16,.18-.14,.45,.04,.61l.49,.44c.18,.16,.45,.14,.61-.04l1.19-1.34s.08-.05,.12-.04l4.49,.92c.1,.02,.14,.14,.07,.22l-.5,.57c-.16,.18-.14,.45,.04,.61l.49,.44c.18,.16,.45,.14,.61-.04l.83-.93-1.18,5.82-.4-1.18c-.08-.23-.32-.35-.55-.27l-.62,.21c-.23,.08-.35,.32-.27,.55l.24,.72c.03,.1-.05,.19-.15,.17l-4.51-.92s-.08-.04-.1-.09l-.56-1.7c-.08-.23-.32-.35-.55-.27l-.62,.21c-.23,.08-.35,.32-.28,.55l.24,.72c.03,.1-.05,.19-.15,.17l-4.5-.94s-.08-.04-.1-.09l-.56-1.7c-.07-.23-.32-.35-.55-.28l-.62,.2c-.23,.07-.35,.32-.28,.55l.23,.72c.03,.1-.05,.19-.15,.17l-4.5-.95s-.08-.04-.1-.09l-.55-1.7c-.07-.23-.32-.35-.55-.28l-.63,.2c-.23,.07-.35,.32-.28,.55l.23,.72c.03,.1-.05,.19-.15,.17l-4.5-.98s-.08-.04-.1-.09l-.16-.51,1.26-5.8,.36-.4Z" />
				<g>
					<path d="M312.18,345.27l4.6,.97c.08,.02,.11,.12,.05,.17-2.58,2.32-4.08,4.68-4.79,5.98l-5.15-1.09c.82-1.41,2.48-3.81,5.21-6.02,.02-.02,.06-.03,.09-.02Z" />
					<path d="M325.24,348l4.6,.94c.08,.02,.11,.12,.05,.17-2.57,2.33-4.05,4.71-4.75,6.02l-5.15-1.05c.81-1.42,2.45-3.83,5.17-6.06,.02-.02,.06-.03,.09-.02Z" />
					<path d="M305.65,343.88l4.6,.99c.08,.02,.11,.12,.04,.17-2.59,2.31-4.1,4.68-4.82,5.97l-3.15-.69,.94-4.32c.66-.7,1.42-1.41,2.29-2.11,.02-.02,.06-.03,.09-.02Z" />
					<path d="M328.54,355.83l-2-.41c.55-.97,1.48-2.4,2.88-3.92l-.88,4.33Z" />
					<path d="M318.7,346.65l4.6,.95c.08,.02,.11,.12,.05,.17-2.58,2.33-4.07,4.7-4.77,6.01l-5.15-1.09c.82-1.41,2.46-3.81,5.19-6.03,.02-.02,.06-.03,.09-.02Z" />
				</g>
				<g>
					<path d="M318.84,336.4l-4.57-.97s-.06-.03-.07-.05c-1.59-3.12-2.1-5.98-2.26-7.6l5.08,1.07c.11,1.48,.51,4.24,1.93,7.41,.03,.08-.03,.16-.11,.14Z" />
					<path d="M331.84,339.09l-4.58-.94s-.06-.03-.07-.05c-1.61-3.11-2.14-5.97-2.32-7.59l5.1,1.04c.12,1.48,.54,4.24,1.99,7.39,.03,.08-.03,.16-.11,.14Z" />
					<path d="M312.35,335.03l-4.57-.98s-.06-.03-.07-.05c-.5-1-.89-1.96-1.2-2.87l.94-4.32,3.1,.68c.1,1.48,.5,4.24,1.91,7.4,.03,.08-.03,.16-.11,.14Z" />
					<path d="M331.35,331.84l1.98,.41-.88,4.33c-.69-1.95-.98-3.63-1.1-4.73Z" />
					<path d="M325.34,337.76l-4.58-.95s-.06-.03-.07-.05c-1.6-3.13-2.12-5.98-2.29-7.61l5.08,1.07c.12,1.48,.53,4.24,1.96,7.39,.03,.08-.03,.16-.11,.14Z" />
				</g>
			</g>
			<g>
				<path d="M280,329.38s.08-.05,.12-.04l4.46,1.06c.1,.02,.13,.14,.07,.22l-.52,.55c-.16,.18-.16,.45,.02,.61l.48,.45c.17,.16,.45,.15,.61-.02l1.22-1.31s.08-.05,.12-.04l4.46,1.03c.1,.02,.14,.14,.07,.22l-.51,.55c-.16,.18-.15,.45,.02,.61l.48,.45c.17,.16,.45,.15,.61-.02l1.21-1.31s.08-.05,.12-.04l4.47,1.01c.1,.02,.14,.14,.07,.22l-.51,.56c-.16,.18-.15,.45,.03,.61l.48,.44c.18,.16,.45,.15,.61-.03l1.21-1.32s.08-.05,.12-.04l4.48,.98c.1,.02,.14,.14,.07,.22l-.51,.56c-.16,.18-.15,.45,.03,.61l.48,.44c.18,.16,.45,.15,.61-.03l.84-.92-1.26,5.8-.38-1.19c-.07-.23-.32-.35-.54-.28l-.63,.2c-.23,.07-.35,.32-.28,.54l.23,.72c.03,.1-.05,.19-.15,.17l-4.5-.99s-.08-.04-.1-.09l-.54-1.7c-.07-.23-.32-.35-.54-.28l-.63,.2c-.23,.07-.36,.32-.28,.54l.23,.72c.03,.1-.06,.19-.15,.17l-4.49-1.02s-.08-.04-.1-.09l-.53-1.71c-.07-.23-.31-.36-.54-.29l-.63,.19c-.23,.07-.36,.31-.29,.54l.22,.72c.03,.1-.06,.19-.15,.17l-4.49-1.03s-.08-.04-.1-.09l-.52-1.71c-.07-.23-.31-.36-.54-.29l-.63,.19c-.23,.07-.36,.31-.29,.54l.22,.72c.03,.1-.06,.19-.16,.17l-4.48-1.06s-.08-.05-.1-.09l-.15-.52,1.38-5.78,.37-.39Z" />
				<g>
					<path d="M286.1,339.5l4.59,1.05c.08,.02,.11,.12,.04,.18-2.62,2.27-4.17,4.61-4.9,5.9l-5.14-1.18c.85-1.4,2.55-3.77,5.33-5.93,.02-.02,.06-.03,.09-.02Z" />
					<path d="M299.13,342.44l4.59,1.01c.08,.02,.11,.12,.04,.17-2.6,2.29-4.12,4.65-4.84,5.94l-5.15-1.13c.83-1.4,2.51-3.79,5.27-5.98,.02-.02,.06-.03,.09-.02Z" />
					<path d="M279.6,337.99l4.58,1.07c.08,.02,.1,.12,.04,.17-2.64,2.27-4.19,4.6-4.93,5.89l-3.14-.76,1.02-4.3c.68-.68,1.45-1.38,2.34-2.06,.02-.02,.06-.03,.09-.02Z" />
					<path d="M302.33,350.32l-2-.44c.57-.96,1.52-2.38,2.94-3.88l-.94,4.32Z" />
					<path d="M292.61,340.99l4.59,1.03c.08,.02,.11,.12,.04,.17-2.61,2.29-4.15,4.64-4.87,5.93l-5.14-1.18c.84-1.4,2.53-3.77,5.29-5.93,.02-.02,.06-.03,.09-.02Z" />
				</g>
				<g>
					<path d="M292.92,330.75l-4.55-1.05s-.06-.03-.07-.06c-1.53-3.15-1.99-6.01-2.12-7.64l5.05,1.16c.08,1.48,.44,4.25,1.8,7.44,.03,.08-.03,.16-.11,.14Z" />
					<path d="M305.87,333.63l-4.56-1s-.06-.03-.07-.05c-1.56-3.14-2.05-6-2.2-7.62l5.07,1.11c.1,1.48,.48,4.25,1.87,7.42,.03,.08-.03,.16-.11,.14Z" />
					<path d="M286.46,329.26l-4.55-1.06s-.06-.03-.07-.06c-.48-1.01-.85-1.98-1.14-2.9l1.02-4.3,3.08,.74c.08,1.48,.42,4.25,1.77,7.43,.03,.08-.03,.16-.11,.14Z" />
					<path d="M305.48,326.37l1.97,.43-.94,4.32c-.66-1.96-.92-3.64-1.03-4.75Z" />
					<path d="M299.39,332.2l-4.56-1.02s-.06-.03-.07-.06c-1.55-3.15-2.01-6.02-2.16-7.64l5.05,1.16c.09,1.48,.46,4.24,1.84,7.42,.03,.08-.03,.16-.11,.14Z" />
				</g>
			</g>
			<g>
				<path d="M254.26,322.94s.08-.05,.13-.03c1.47,.4,2.95,.78,4.43,1.16,.1,.02,.13,.15,.06,.22l-.53,.54c-.17,.17-.17,.45,0,.61l.47,.46c.17,.17,.44,.16,.61,0l1.25-1.28s.08-.05,.13-.04l4.44,1.13c.1,.02,.13,.15,.06,.22l-.52,.54c-.17,.17-.16,.45,0,.61l.47,.46c.17,.17,.44,.16,.61,0l1.24-1.28s.08-.05,.13-.04c1.48,.38,2.96,.75,4.44,1.1,.1,.02,.13,.14,.06,.22l-.52,.55c-.17,.17-.16,.45,.01,.61l.47,.45c.17,.16,.45,.16,.61-.02l1.23-1.3s.08-.05,.13-.04l4.45,1.07c.1,.02,.13,.14,.06,.22l-.52,.55c-.16,.17-.16,.45,.02,.61l.48,.45c.17,.16,.45,.16,.61-.02l.85-.91-1.38,5.78-.36-1.2c-.07-.23-.31-.36-.54-.29l-.63,.19c-.23,.07-.36,.31-.29,.54l.22,.73c.03,.1-.06,.19-.16,.16l-4.48-1.08s-.08-.05-.09-.09l-.5-1.71c-.07-.23-.31-.36-.54-.29l-.63,.19c-.23,.07-.36,.31-.3,.54l.21,.72c.03,.1-.06,.19-.16,.16-1.49-.35-2.98-.73-4.47-1.11-.05-.01-.08-.05-.09-.09l-.49-1.72c-.07-.23-.31-.36-.54-.3l-.63,.18c-.23,.06-.36,.3-.3,.53l.21,.73c.03,.1-.06,.19-.16,.16l-4.47-1.14s-.08-.05-.09-.09l-.48-1.72c-.06-.23-.3-.36-.53-.3l-.63,.18c-.23,.06-.37,.3-.3,.53l.2,.73c.03,.1-.06,.19-.16,.16-1.49-.37-2.98-.76-4.46-1.16-.05-.01-.08-.05-.09-.09l-.14-.52,1.52-5.74,.38-.38Z" />
				<g>
					<path d="M260.13,333.2l4.56,1.16c.08,.02,.1,.12,.04,.18-2.67,2.21-4.27,4.51-5.03,5.78l-5.13-1.3c.88-1.38,2.64-3.7,5.47-5.8,.02-.02,.06-.02,.09-.02Z" />
					<path d="M273.1,336.42l4.58,1.1c.08,.02,.1,.12,.04,.18-2.65,2.24-4.22,4.56-4.96,5.84l-5.14-1.24c.87-1.39,2.59-3.74,5.39-5.87,.02-.02,.06-.03,.09-.02Z" />
					<path d="M253.65,331.54l4.56,1.17c.08,.02,.1,.12,.04,.18-2.69,2.2-4.31,4.5-5.07,5.77l-3.13-.83,1.13-4.27c.69-.67,1.48-1.35,2.39-2,.02-.02,.06-.02,.09-.02Z" />
					<path d="M276.15,344.36l-2-.48c.58-.95,1.57-2.35,3.02-3.82l-1.02,4.3Z" />
					<path d="M266.61,334.84c1.52,.39,3.05,.74,4.57,1.12,.08,.02,.1,.12,.04,.18-2.66,2.24-4.25,4.55-5,5.84l-5.13-1.3c.87-1.38,2.61-3.71,5.43-5.81,.02-.02,.06-.02,.09-.02Z" />
				</g>
				<g>
					<path d="M267.14,324.6l-4.52-1.15s-.05-.03-.07-.06c-1.46-3.18-1.84-6.06-1.94-7.68l5.01,1.27c.05,1.48,.34,4.26,1.63,7.48,.03,.08-.04,.16-.12,.14Z" />
					<path d="M280.01,327.74l-4.54-1.09s-.06-.03-.07-.06c-1.5-3.17-1.92-6.03-2.03-7.66l5.03,1.21c.07,1.48,.39,4.26,1.72,7.46,.03,.08-.03,.16-.11,.14Z" />
					<path d="M260.72,322.97l-4.52-1.16s-.05-.03-.07-.06c-.45-1.02-.8-2-1.06-2.93l1.13-4.27,3.05,.81c.04,1.48,.31,4.25,1.59,7.47,.03,.08-.04,.16-.12,.14Z" />
					<path d="M279.77,320.48l1.96,.47-1.02,4.3c-.62-1.97-.85-3.66-.93-4.77Z" />
					<path d="M273.57,326.19c-1.51-.37-3.03-.72-4.53-1.11-.03,0-.05-.03-.07-.06-1.47-3.19-1.88-6.07-1.98-7.69l5.01,1.27c.06,1.48,.37,4.25,1.68,7.45,.03,.08-.04,.16-.12,.14Z" />
				</g>
			</g>
			<g>
				<path d="M228.73,315.81s.08-.04,.13-.03c1.45,.45,2.93,.85,4.39,1.27,.1,.03,.13,.15,.05,.22l-.54,.53c-.17,.17-.18,.44-.01,.61l.45,.47c.17,.17,.44,.18,.61,0l1.28-1.24s.08-.04,.13-.03l4.4,1.26c.1,.03,.13,.15,.06,.22l-.54,.53c-.17,.17-.17,.44,0,.61l.46,.47c.17,.17,.44,.18,.61,0l1.27-1.25s.08-.05,.13-.03c1.46,.43,2.94,.82,4.41,1.21,.1,.03,.13,.15,.06,.22l-.54,.53c-.17,.17-.17,.44,0,.61l.46,.46c.17,.17,.44,.17,.61,0l1.27-1.26s.08-.05,.13-.03l4.42,1.19c.1,.03,.13,.15,.06,.22l-.53,.54c-.17,.17-.17,.44,0,.61l.46,.46c.17,.17,.44,.17,.61,0l.88-.88-1.52,5.74-.33-1.21c-.06-.23-.3-.37-.53-.31l-.63,.17c-.23,.06-.37,.3-.31,.53l.2,.73c.03,.1-.06,.19-.16,.16l-4.45-1.2s-.08-.05-.09-.09l-.46-1.73c-.06-.23-.3-.37-.53-.31l-.64,.17c-.23,.06-.37,.3-.31,.53l.19,.73c.03,.1-.06,.19-.16,.16-1.48-.4-2.97-.79-4.45-1.22-.05-.01-.08-.05-.09-.09l-.44-1.74c-.06-.23-.3-.37-.53-.32l-.64,.16c-.23,.06-.37,.29-.31,.53l.19,.73c.02,.1-.07,.19-.16,.16l-4.44-1.27s-.08-.05-.09-.09l-.43-1.73c-.06-.23-.29-.37-.53-.31l-.64,.16c-.23,.06-.38,.29-.32,.52l.18,.73c.02,.1-.07,.19-.16,.16-1.48-.43-2.96-.83-4.43-1.29-.04-.01-.08-.05-.09-.1l-.13-.53,1.69-5.69,.39-.37Z" />
				<g>
					<path d="M234.3,326.23l4.53,1.29c.08,.02,.1,.13,.03,.18-2.73,2.13-4.4,4.38-5.19,5.64l-5.1-1.46c.92-1.35,2.75-3.62,5.64-5.64,.03-.02,.06-.02,.09-.01Z" />
					<path d="M247.19,329.8l4.55,1.22c.08,.02,.1,.12,.04,.18-2.7,2.17-4.33,4.45-5.11,5.71l-5.12-1.38c.9-1.36,2.69-3.67,5.55-5.72,.02-.02,.06-.02,.09-.02Z" />
					<path d="M227.86,324.39l4.53,1.29c.08,.02,.1,.13,.03,.18-2.75,2.13-4.44,4.37-5.25,5.62-1.04-.29-2.08-.6-3.11-.92l1.26-4.24c.71-.65,1.52-1.29,2.45-1.93,.03-.02,.06-.02,.09-.01Z" />
					<path d="M250.05,337.83l-1.99-.53c.61-.93,1.63-2.3,3.12-3.74l-1.13,4.27Z" />
					<path d="M240.73,328.06l4.55,1.23c.08,.02,.1,.12,.04,.18-2.72,2.17-4.37,4.44-5.16,5.7-1.71-.45-3.4-.96-5.1-1.44,.91-1.35,2.72-3.63,5.59-5.65,.03-.02,.06-.02,.09-.01Z" />
				</g>
				<g>
					<path d="M241.54,317.83l-4.48-1.28s-.05-.03-.07-.06c-1.36-3.23-1.66-6.11-1.71-7.73l4.96,1.42c0,1.48,.21,4.27,1.42,7.52,.03,.08-.04,.16-.12,.13Z" />
					<path d="M254.31,321.3l-4.5-1.21s-.05-.03-.07-.06c-1.41-3.2-1.76-6.08-1.83-7.71l4.99,1.34c.03,1.48,.28,4.26,1.53,7.5,.03,.08-.04,.16-.12,.13Z" />
					<path d="M235.18,316.02l-4.48-1.28s-.05-.03-.07-.06c-.42-1.03-.74-2.03-.97-2.96l1.26-4.24c1,.31,2.01,.61,3.02,.89,0,1.48,.19,4.26,1.37,7.51,.03,.08-.04,.16-.12,.13Z" />
					<path d="M254.26,314.03l1.94,.52-1.13,4.27c-.57-1.98-.76-3.68-.81-4.79Z" />
					<path d="M247.92,319.58l-4.5-1.21s-.05-.03-.07-.06c-1.38-3.23-1.7-6.12-1.76-7.75,1.66,.46,3.3,.96,4.97,1.4,.02,1.48,.25,4.26,1.48,7.49,.03,.08-.04,.16-.12,.13Z" />
				</g>
			</g>
			<g>
				<path d="M203.46,307.86s.08-.04,.13-.03l4.35,1.41c.1,.03,.12,.15,.05,.22l-.56,.51c-.18,.16-.19,.43-.03,.61l.44,.49c.16,.18,.43,.19,.61,.03l1.33-1.2s.08-.04,.13-.03l4.35,1.41c.1,.03,.12,.15,.05,.22l-.56,.51c-.18,.16-.19,.44-.03,.61l.44,.49c.16,.18,.43,.19,.61,.03l1.31-1.2s.08-.04,.13-.03c1.45,.47,2.91,.89,4.37,1.34,.1,.03,.12,.15,.05,.22l-.55,.52c-.18,.16-.19,.44-.02,.61l.45,.48c.16,.17,.44,.18,.61,.02l1.3-1.22s.08-.04,.13-.03l4.38,1.33c.1,.03,.12,.15,.05,.22l-.55,.52c-.17,.16-.18,.44-.02,.61l.45,.48c.16,.17,.44,.18,.61,.02l.9-.86-1.69,5.69-.29-1.22c-.06-.23-.29-.38-.52-.32l-.64,.15c-.23,.05-.38,.29-.32,.52l.18,.74c.02,.1-.07,.18-.17,.16l-4.41-1.34s-.08-.05-.09-.1l-.4-1.74c-.05-.23-.29-.38-.52-.32l-.64,.15c-.23,.05-.38,.29-.33,.52l.17,.74c.02,.1-.07,.18-.17,.15-1.47-.45-2.95-.88-4.41-1.35-.04-.01-.08-.05-.09-.1l-.39-1.75c-.05-.23-.28-.38-.52-.33l-.64,.14c-.23,.05-.38,.28-.33,.52l.16,.74c.02,.1-.07,.18-.17,.15l-4.39-1.42s-.08-.05-.09-.1l-.37-1.75c-.05-.23-.28-.38-.52-.33l-.64,.14c-.23,.05-.39,.28-.34,.51l.16,.74c.02,.1-.07,.18-.17,.15l-4.39-1.43s-.08-.05-.09-.1l-.11-.53,1.89-5.63,.4-.36Z" />
				<g>
					<path d="M208.67,318.45l4.49,1.45c.08,.03,.1,.13,.03,.18-2.8,2.04-4.55,4.23-5.38,5.45l-5.06-1.64c.97-1.32,2.88-3.52,5.84-5.43,.03-.02,.06-.02,.09-.01Z" />
					<path d="M221.45,322.45l4.51,1.37c.08,.02,.1,.13,.03,.18-2.77,2.09-4.47,4.31-5.29,5.55l-5.08-1.54c.95-1.33,2.81-3.58,5.73-5.54,.03-.02,.06-.02,.09-.01Z" />
					<path d="M202.29,316.39l4.49,1.45c.08,.03,.09,.13,.03,.18-2.83,2.03-4.59,4.21-5.44,5.43-1.03-.34-2.06-.65-3.09-1.02l1.41-4.19c.73-.61,1.57-1.24,2.52-1.85,.03-.02,.06-.02,.09-.01Z" />
					<path d="M224.07,330.57l-1.98-.6c.64-.91,1.7-2.25,3.23-3.64l-1.26,4.24Z" />
					<path d="M215.04,320.5l4.51,1.37c.08,.02,.1,.13,.03,.18-2.79,2.08-4.51,4.3-5.34,5.53-1.7-.5-3.38-1.05-5.07-1.6,.96-1.33,2.84-3.53,5.78-5.47,.03-.02,.06-.02,.09-.01Z" />
				</g>
				<g>
					<path d="M216.18,310.31l-4.43-1.43s-.05-.03-.06-.06c-1.25-3.27-1.45-6.16-1.43-7.79l4.89,1.58c-.04,1.48,.07,4.27,1.16,7.57,.03,.08-.05,.15-.12,.13Z" />
					<path d="M228.83,314.18l-4.46-1.35s-.05-.03-.06-.06c-1.31-3.25-1.56-6.13-1.58-7.76l4.93,1.5c-.02,1.48,.14,4.27,1.29,7.55,.03,.08-.04,.16-.12,.13Z" />
					<path d="M209.9,308.28l-4.43-1.43s-.05-.03-.06-.06c-.39-1.03-.67-2.03-.87-2.98l1.41-4.19c.99,.35,1.99,.65,2.98,.98-.06,1.48,.03,4.26,1.1,7.55,.03,.08-.05,.15-.13,.13Z" />
					<path d="M229,306.91l1.92,.58-1.26,4.24c-.51-2-.64-3.7-.66-4.82Z" />
					<path d="M222.5,312.26l-4.46-1.35s-.05-.03-.06-.06c-1.28-3.26-1.5-6.17-1.5-7.8,1.63,.53,3.26,1.06,4.91,1.55-.03,1.48,.11,4.26,1.24,7.53,.03,.08-.04,.15-.12,.13Z" />
				</g>
			</g>
			<g>
				<path d="M178.54,298.92s.09-.04,.13-.02l4.28,1.59c.09,.03,.11,.16,.04,.22l-.58,.48c-.18,.15-.21,.43-.06,.61l.42,.51c.15,.18,.42,.21,.61,.06l1.37-1.14s.08-.04,.13-.02l4.28,1.59c.09,.03,.12,.16,.04,.22l-.58,.48c-.18,.15-.21,.43-.05,.61l.42,.51c.15,.18,.42,.21,.61,.06l1.36-1.15s.08-.04,.13-.02l4.32,1.5c.09,.03,.12,.16,.04,.22l-.57,.5c-.18,.16-.2,.43-.05,.61l.43,.5c.16,.18,.43,.2,.61,.04l1.35-1.17s.08-.04,.13-.03l4.32,1.5c.09,.03,.12,.16,.04,.22l-.57,.5c-.18,.16-.2,.43-.04,.61l.43,.5c.16,.18,.43,.2,.61,.04l.93-.82-1.89,5.63-.25-1.23c-.05-.24-.28-.39-.51-.34l-.64,.12c-.23,.05-.39,.27-.34,.51l.15,.74c.02,.1-.08,.18-.17,.15l-4.36-1.51s-.08-.05-.09-.1l-.34-1.76c-.04-.23-.27-.39-.51-.34l-.65,.12c-.24,.05-.39,.27-.35,.51l.14,.74c.02,.1-.08,.18-.17,.15l-4.36-1.51s-.08-.05-.09-.1l-.32-1.77c-.04-.24-.27-.4-.5-.36l-.65,.11c-.24,.04-.39,.26-.35,.5l.13,.75c.02,.1-.08,.18-.17,.15l-4.33-1.61s-.08-.05-.08-.1l-.3-1.76c-.04-.24-.26-.39-.5-.36l-.65,.11c-.24,.04-.4,.26-.36,.5l.13,.75c.02,.1-.08,.18-.18,.14l-4.33-1.61s-.08-.05-.08-.1l-.09-.53,2.13-5.54,.42-.34Z" />
				<g>
					<path d="M183.31,309.72l4.43,1.64c.08,.03,.09,.13,.02,.18-2.88,1.92-4.71,4.04-5.6,5.22l-5-1.86c1.03-1.27,3.03-3.4,6.06-5.18,.03-.02,.06-.02,.09,0Z" />
					<path d="M195.95,314.21l4.46,1.55c.08,.03,.09,.13,.03,.18-2.84,1.98-4.63,4.14-5.49,5.34l-5.03-1.74c1-1.3,2.95-3.47,5.95-5.31,.03-.02,.06-.02,.09,0Z" />
					<path d="M177.02,307.38l4.43,1.64c.08,.03,.09,.13,.02,.18-2.91,1.91-4.77,4.01-5.67,5.2l-3.06-1.14,1.58-4.12c.77-.59,1.63-1.19,2.6-1.75,.03-.02,.06-.02,.09,0Z" />
					<path d="M198.28,322.43l-1.96-.68c.67-.89,1.78-2.18,3.36-3.51l-1.41,4.19Z" />
					<path d="M189.61,312.02l4.46,1.55c.08,.03,.09,.13,.02,.18-2.87,1.97-4.68,4.12-5.55,5.32-1.68-.58-3.36-1.16-5.02-1.79,1.01-1.29,2.98-3.39,6-5.24,.03-.02,.06-.02,.09,0Z" />
				</g>
				<g>
					<path d="M191.14,301.9l-4.36-1.62s-.05-.03-.06-.06c-1.11-3.32-1.18-6.22-1.1-7.84l4.81,1.78c-.1,1.48-.11,4.27,.85,7.62,.02,.08-.05,.15-.13,.12Z" />
					<path d="M203.62,306.23l-4.4-1.52s-.05-.03-.06-.06c-1.18-3.3-1.32-6.19-1.28-7.82l4.85,1.68c-.07,1.48-.02,4.28,1.01,7.59,.02,.08-.05,.15-.13,.13Z" />
					<path d="M184.96,299.6l-4.36-1.62s-.05-.03-.06-.06c-.34-1.05-.58-2.06-.75-3l1.58-4.12,2.94,1.09c-.12,1.47-.15,4.26,.77,7.59,.02,.08-.05,.15-.13,.12Z" />
					<path d="M204.05,298.96l1.89,.65-1.41,4.19c-.43-2.02-.5-3.73-.48-4.84Z" />
					<path d="M197.38,304.07l-4.4-1.52s-.05-.03-.06-.06c-1.16-3.27-1.25-6.22-1.19-7.84,1.6,.61,3.21,1.17,4.83,1.73-.09,1.48-.06,4.26,.94,7.57,.02,.08-.05,.15-.13,.13Z" />
				</g>
			</g>
			<g>
				<path d="M154.1,288.84s.09-.03,.13-.02l4.19,1.8c.09,.04,.11,.16,.03,.22l-.6,.45c-.19,.14-.23,.41-.09,.61l.39,.53c.14,.19,.41,.23,.6,.09l1.43-1.07s.09-.03,.13-.02l4.19,1.8c.09,.04,.11,.17,.03,.23l-.6,.46c-.19,.14-.23,.42-.08,.61l.4,.53c.14,.19,.41,.23,.6,.09l1.41-1.1s.09-.04,.13-.02l4.24,1.69c.09,.04,.11,.16,.03,.22l-.59,.47c-.19,.15-.22,.42-.07,.61l.4,.51c.15,.19,.42,.22,.61,.07l1.4-1.11s.09-.04,.13-.02l4.24,1.69c.09,.04,.11,.16,.03,.23l-.59,.47c-.19,.15-.22,.42-.07,.61l.41,.52c.15,.19,.42,.22,.6,.07l.97-.78-2.13,5.54-.19-1.24c-.04-.24-.26-.4-.49-.37l-.65,.1c-.24,.03-.4,.26-.36,.49l.12,.75c.02,.1-.08,.18-.18,.14l-4.29-1.71s-.07-.06-.08-.1l-.26-1.77c-.03-.24-.25-.4-.49-.37l-.65,.09c-.24,.03-.4,.25-.37,.49l.11,.75c.01,.1-.08,.18-.18,.14l-4.29-1.71s-.07-.06-.08-.1l-.24-1.77c-.03-.24-.25-.41-.48-.38l-.65,.08c-.24,.03-.4,.25-.37,.48l.1,.75c.01,.1-.09,.18-.18,.14l-4.25-1.82s-.07-.06-.08-.11l-.22-1.78c-.03-.24-.24-.41-.48-.38l-.65,.08c-.24,.03-.41,.24-.38,.48l.09,.75c.01,.1-.09,.18-.18,.14l-4.25-1.82s-.07-.06-.08-.1l-.06-.53,2.41-5.43,.44-.32Z" />
				<g>
					<path d="M158.33,299.87l4.35,1.86c.08,.03,.08,.14,.01,.18-2.98,1.8-4.9,3.81-5.85,4.95l-4.92-2.11c1.09-1.22,3.2-3.24,6.31-4.88,.03-.01,.06-.02,.09,0Z" />
					<path d="M170.77,304.94l4.39,1.75c.08,.03,.09,.13,.02,.18-2.92,1.85-4.8,3.92-5.72,5.09l-4.96-1.98c1.06-1.25,3.11-3.33,6.18-5.04,.03-.02,.06-.02,.09,0Z" />
					<path d="M152.16,297.22l4.35,1.87c.08,.03,.08,.14,.01,.18-3,1.76-4.96,3.77-5.92,4.91l-3.01-1.29,1.79-4.04c.8-.55,1.69-1.11,2.69-1.62,.03-.01,.06-.02,.09,0Z" />
					<path d="M172.74,313.27l-1.93-.77c.71-.86,1.88-2.1,3.52-3.36l-1.58,4.12Z" />
					<path d="M164.53,302.46l4.39,1.75c.08,.03,.09,.14,.02,.18-2.95,1.84-4.86,3.9-5.79,5.06-1.65-.67-3.32-1.29-4.95-2.02,1.07-1.23,3.14-3.26,6.25-4.97,.03-.01,.06-.02,.09,0Z" />
				</g>
				<g>
					<path d="M166.52,292.43l-4.27-1.82s-.05-.04-.06-.07c-.95-3.37-.88-6.27-.71-7.89l4.69,2.01c-.17,1.47-.32,4.28,.48,7.64,.02,.08-.06,.15-.14,.12Z" />
					<path d="M178.77,297.31l-4.31-1.72s-.05-.04-.06-.06c-1.03-3.35-1.04-6.24-.92-7.87l4.75,1.89c-.14,1.48-.21,4.27,.67,7.63,.02,.08-.06,.15-.13,.12Z" />
					<path d="M160.46,289.84l-4.27-1.83s-.05-.04-.06-.07c-.29-1.07-.48-2.08-.59-3.03l1.79-4.04,2.87,1.23c-.19,1.47-.37,4.24,.39,7.62,.02,.08-.06,.15-.14,.12Z" />
					<path d="M179.53,290.07l1.85,.73-1.58,4.12c-.34-2.03-.34-3.75-.27-4.86Z" />
					<path d="M172.64,294.87l-4.31-1.72s-.05-.04-.06-.06c-1.01-3.32-.96-6.24-.82-7.87,1.56,.69,3.16,1.29,4.74,1.93-.15,1.47-.25,4.25,.59,7.6,.02,.08-.06,.15-.13,.12Z" />
				</g>
			</g>
			<g>
				<path d="M130.26,277.47s.09-.03,.13,0l4.08,2.03c.09,.04,.1,.17,.01,.23l-.63,.42c-.2,.13-.26,.4-.12,.6l.36,.55c.13,.2,.4,.25,.6,.12l1.48-.99s.09-.03,.13,0l4.08,2.03c.09,.04,.1,.17,.02,.23l-.62,.42c-.2,.13-.25,.4-.12,.6l.36,.55c.13,.2,.4,.25,.6,.11l1.47-1.02s.09-.03,.13-.01l4.14,1.91c.09,.04,.1,.17,.02,.23l-.62,.44c-.2,.14-.24,.41-.11,.6l.38,.54c.14,.2,.41,.24,.6,.1l1.45-1.03s.09-.03,.13-.01l4.14,1.91c.09,.04,.1,.17,.02,.23l-.61,.44c-.19,.14-.24,.41-.1,.61l.38,.54c.14,.2,.41,.24,.6,.1l1.01-.74-2.41,5.43-.14-1.23c-.03-.23-.24-.41-.48-.39l-.65,.06c-.24,.02-.41,.23-.39,.47l.08,.76c.01,.1-.09,.17-.18,.13l-4.2-1.94s-.07-.06-.08-.11l-.17-1.78c-.02-.24-.23-.41-.47-.39l-.66,.06c-.24,.02-.42,.23-.39,.47l.07,.75c0,.1-.09,.17-.19,.13l-4.2-1.94s-.07-.06-.08-.11l-.15-1.77c-.02-.24-.23-.41-.47-.4l-.65,.04c-.24,.02-.42,.22-.4,.46l.06,.76c0,.1-.1,.17-.19,.13l-4.14-2.07s-.07-.06-.07-.11l-.12-1.79c-.02-.24-.22-.42-.46-.41l-.66,.04c-.24,.01-.42,.22-.41,.46l.05,.76c0,.1-.1,.17-.19,.12l-4.14-2.07s-.07-.06-.07-.11l-.03-.54,2.72-5.28,.45-.3Z" />
				<g>
					<path d="M133.86,288.72c1.42,.69,2.82,1.43,4.25,2.09,.08,.04,.08,.14,0,.18-3.08,1.66-5.11,3.53-6.12,4.63l-4.81-2.4c1.16-1.16,3.38-3.05,6.58-4.5,.03-.01,.06-.01,.09,0Z" />
					<path d="M146.02,294.47c1.44,.65,2.86,1.34,4.31,1.97,.08,.03,.08,.14,0,.18-3.03,1.73-5,3.68-5.98,4.8l-4.87-2.25c1.12-1.19,3.29-3.15,6.44-4.7,.03-.01,.06-.01,.09,0Z" />
					<path d="M127.83,285.72l4.24,2.12c.07,.04,.07,.14,0,.18-3.1,1.58-5.18,3.48-6.2,4.56l-2.94-1.47,2.02-3.93c.83-.51,1.76-1.01,2.79-1.46,.03-.01,.06-.01,.09,0Z" />
					<path d="M147.59,302.88c-.64-.27-1.27-.56-1.9-.85,.76-.83,1.98-1.98,3.69-3.18l-1.79,4.04Z" />
					<path d="M139.92,291.65l4.3,1.99c.08,.03,.08,.14,0,.18-3.05,1.68-5.07,3.63-6.06,4.74-1.62-.76-3.26-1.47-4.86-2.28,1.13-1.16,3.32-3.09,6.52-4.63,.03-.01,.06-.01,.09,0Z" />
				</g>
				<g>
					<path d="M142.45,281.74c-1.41-.64-2.77-1.37-4.16-2.04-.03-.01-.05-.04-.05-.07-.75-3.42-.51-6.31-.25-7.91l4.55,2.27c-.25,1.45-.57,4.27,.06,7.65,.01,.08-.07,.15-.14,.11Z" />
					<path d="M154.41,287.24c-1.42-.62-2.81-1.29-4.22-1.93-.03-.01-.05-.04-.06-.07-.85-3.4-.7-6.29-.49-7.9l4.63,2.14c-.21,1.46-.44,4.27,.28,7.65,.02,.08-.06,.15-.14,.12Z" />
					<path d="M136.55,278.83l-4.15-2.07s-.05-.04-.05-.07c-.23-1.08-.35-2.11-.41-3.06l2.02-3.93,2.78,1.39c-.28,1.45-.61,4.21-.05,7.62,.01,.08-.07,.15-.14,.11Z" />
					<path d="M155.52,280.06c.6,.28,1.2,.56,1.81,.81l-1.79,4.04c-.25-2.02-.15-3.75-.02-4.85Z" />
					<path d="M148.42,284.5l-4.21-1.94s-.05-.04-.06-.07c-.82-3.38-.63-6.26-.39-7.89,1.51,.77,3.08,1.44,4.61,2.16-.23,1.46-.49,4.23,.18,7.62,.02,.08-.07,.15-.14,.11Z" />
				</g>
			</g>
			<g>
				<path d="M107.22,264.64s.09-.02,.13,0l3.93,2.3c.09,.05,.08,.18,0,.23l-.66,.38c-.21,.12-.28,.38-.16,.59l.32,.57c.12,.21,.38,.28,.59,.16l1.54-.89s.09-.02,.13,0l3.93,2.3c.09,.05,.09,.18,0,.23l-.65,.38c-.2,.12-.27,.39-.15,.6l.33,.57c.12,.2,.39,.27,.6,.14l1.53-.93s.09-.03,.13,0l4.01,2.16c.09,.05,.09,.17,0,.23l-.64,.4c-.2,.13-.27,.39-.14,.6l.34,.56c.12,.2,.39,.27,.59,.14l1.51-.94s.09-.03,.13,0l4.01,2.16c.09,.05,.09,.17,0,.23l-.63,.4c-.2,.13-.26,.4-.13,.6l.35,.56c.13,.2,.4,.25,.6,.12l1.05-.68-2.72,5.28-.07-1.24c-.01-.24-.22-.42-.46-.41l-.65,.02c-.24,0-.42,.21-.41,.45l.03,.76c0,.1-.1,.17-.19,.12l-4.08-2.2s-.07-.07-.07-.11l-.06-1.79c0-.24-.21-.43-.45-.42l-.66,.02c-.24,0-.43,.21-.42,.44l.02,.76c0,.1-.1,.17-.19,.12l-4.08-2.2s-.07-.06-.07-.11l-.04-1.78c0-.24-.2-.43-.45-.42h-.65c-.24,0-.43,.19-.43,.43v.76c0,.1-.1,.17-.19,.11l-4-2.34s-.07-.07-.07-.11v-1.79c0-.24-.19-.44-.43-.44h-.66c-.24,0-.44,.19-.44,.43v.76c0,.1-.11,.16-.2,.11l-4-2.34s-.07-.07-.06-.11v-.54s3.08-5.08,3.08-5.08l.47-.27Z" />
				<g>
					<path d="M110.06,276.1c1.38,.78,2.72,1.63,4.12,2.36,.07,.04,.07,.14,0,.18-3.19,1.47-5.33,3.19-6.41,4.23l-4.66-2.72c1.23-1.08,3.58-2.82,6.87-4.05,.03-.01,.06,0,.09,0Z" />
					<path d="M121.86,282.61c1.4,.74,2.77,1.53,4.19,2.22,.07,.04,.07,.14,0,.18-3.14,1.57-5.22,3.37-6.27,4.44l-4.74-2.56c1.2-1.12,3.48-2.94,6.73-4.29,.03-.01,.06-.01,.09,0Z" />
					<path d="M104.24,272.7l4.1,2.4c.07,.04,.07,.15-.01,.18-3.2,1.37-5.4,3.12-6.5,4.13l-2.85-1.67,2.29-3.78c.86-.45,1.82-.88,2.88-1.27,.03-.01,.06,0,.09,0Z" />
					<path d="M122.94,291.11c-.62-.31-1.25-.62-1.86-.96,.8-.77,2.1-1.87,3.88-2.97l-2.02,3.93Z" />
					<path d="M115.93,279.41l4.18,2.25c.07,.04,.07,.15,0,.18-3.15,1.48-5.29,3.31-6.35,4.35-1.57-.86-3.17-1.68-4.73-2.58,1.2-1.08,3.53-2.88,6.81-4.21,.03-.01,.06,0,.09,0Z" />
				</g>
				<g>
					<path d="M119.08,269.68c-1.37-.71-2.67-1.54-4.01-2.3-.03-.02-.04-.04-.05-.07-.52-3.47-.09-6.33,.28-7.91l4.37,2.56c-.34,1.43-.86,4.23-.44,7.62,0,.08-.08,.14-.15,.1Z" />
					<path d="M130.66,275.89c-1.39-.67-2.72-1.45-4.09-2.17-.03-.01-.05-.04-.05-.07-.64-3.45-.31-6.32,0-7.92l4.47,2.41c-.3,1.44-.71,4.25-.18,7.64,.01,.08-.07,.14-.15,.11Z" />
					<path d="M113.39,266.4l-3.99-2.33s-.04-.04-.05-.07c-.15-1.09-.21-2.12-.2-3.07l2.28-3.78,2.67,1.56c-.38,1.43-.9,4.16-.56,7.6,0,.08-.08,.14-.15,.1Z" />
					<path d="M132.2,268.8c.57,.32,1.16,.62,1.76,.91l-2.02,3.93c-.13-2.03,.07-3.73,.27-4.83Z" />
					<path d="M124.86,272.8l-4.07-2.2s-.05-.04-.05-.07c-.6-3.42-.23-6.27,.12-7.88,1.46,.85,2.97,1.62,4.45,2.43-.32,1.44-.75,4.19-.3,7.62,.01,.08-.07,.14-.15,.1Z" />
				</g>
			</g>
			<g>
				<path d="M85.2,250.18s.09-.02,.13,.01l3.74,2.58c.08,.06,.07,.18-.02,.23l-.68,.33c-.22,.1-.31,.36-.21,.58l.28,.59c.1,.22,.36,.31,.57,.2l1.61-.77s.09-.02,.13,0l3.74,2.58c.08,.06,.07,.18-.02,.23l-.67,.33c-.21,.11-.3,.37-.2,.58l.28,.6c.11,.21,.37,.29,.58,.18l1.6-.82s.09-.02,.13,0l3.84,2.44c.08,.05,.08,.18-.01,.23l-.67,.35c-.21,.11-.29,.37-.19,.58l.3,.58c.11,.21,.37,.29,.58,.18l1.57-.83s.09-.02,.13,0l3.84,2.44c.08,.05,.08,.18,0,.23l-.66,.36c-.21,.11-.29,.38-.18,.59l.31,.58c.12,.21,.38,.28,.59,.16l1.09-.61-3.07,5.08,.02-1.24c0-.24-.19-.43-.43-.44l-.65-.03c-.24,0-.44,.18-.44,.42l-.02,.76c0,.1-.11,.16-.2,.11l-3.92-2.49s-.06-.07-.06-.12l.07-1.79c0-.24-.18-.44-.42-.45l-.66-.03c-.24-.01-.44,.17-.45,.41l-.03,.76c0,.1-.12,.16-.2,.1l-3.92-2.49s-.06-.07-.06-.12l.09-1.78c.01-.24-.17-.44-.41-.45l-.65-.05c-.24-.02-.44,.16-.46,.4l-.05,.76c0,.1-.12,.16-.2,.1l-3.82-2.64s-.06-.07-.06-.12l.14-1.79c.02-.24-.16-.45-.4-.47l-.66-.05c-.24-.02-.45,.16-.47,.4l-.06,.75c0,.1-.12,.15-.21,.1l-3.82-2.64s-.06-.07-.06-.12l.05-.54,3.46-4.83,.49-.23Z" />
				<g>
					<path d="M87.16,261.83c1.32,.89,2.59,1.84,3.94,2.67,.07,.05,.06,.15-.02,.18-3.3,1.24-5.56,2.78-6.71,3.74l-4.46-3.08c1.31-.98,3.79-2.53,7.17-3.52,.03,0,.06,0,.09,.01Z" />
					<path d="M98.48,269.19c1.35,.83,2.66,1.73,4.04,2.51,.07,.04,.06,.15-.01,.18-3.25,1.36-5.44,3-6.56,3.99l-4.57-2.9c1.27-1.03,3.69-2.68,7.02-3.8,.03,0,.06,0,.09,.01Z" />
					<path d="M81.61,257.99l3.92,2.71c.07,.05,.05,.15-.02,.18-3.29,1.13-5.63,2.7-6.8,3.62l-2.73-1.88,2.57-3.59c.9-.38,1.89-.74,2.98-1.04,.03,0,.06,0,.09,.01Z" />
					<path d="M98.98,277.74c-.6-.35-1.21-.7-1.8-1.09,.85-.71,2.23-1.72,4.08-2.69l-2.28,3.78Z" />
					<path d="M92.79,265.57l4.01,2.55c.07,.04,.06,.15-.02,.18-3.25,1.26-5.52,2.92-6.65,3.89-1.51-.98-3.06-1.91-4.55-2.93,1.28-.98,3.73-2.61,7.12-3.69,.03,0,.06,0,.09,.01Z" />
				</g>
				<g>
					<path d="M96.63,256.09c-1.31-.81-2.54-1.73-3.82-2.59-.02-.02-.04-.05-.04-.08-.26-3.49,.4-6.31,.88-7.86l4.15,2.86c-.45,1.39-1.18,4.16-1.01,7.57,0,.08-.09,.14-.16,.09Z" />
					<path d="M107.72,263.09c-1.34-.76-2.61-1.63-3.92-2.44-.03-.02-.04-.04-.05-.07-.4-3.48,.15-6.32,.57-7.89l4.26,2.71c-.4,1.41-1.02,4.2-.71,7.6,0,.08-.08,.14-.15,.1Z" />
					<path d="M91.21,252.4l-3.8-2.62s-.04-.05-.04-.08c-.07-1.1-.04-2.13,.04-3.07l2.57-3.59,2.53,1.75c-.48,1.39-1.22,4.07-1.15,7.53,0,.08-.09,.13-.16,.09Z" />
					<path d="M109.75,256.13c.55,.36,1.12,.69,1.68,1.02l-2.28,3.78c0-2.03,.33-3.71,.6-4.8Z" />
					<path d="M102.17,259.61l-3.9-2.48s-.04-.04-.05-.07c-.35-3.45,.23-6.26,.7-7.84,1.38,.95,2.83,1.82,4.25,2.73-.43,1.41-1.06,4.12-.85,7.57,0,.08-.08,.14-.15,.09Z" />
				</g>
			</g>
			<g>
				<path d="M64.48,233.97s.09,0,.13,.02l3.5,2.89c.08,.06,.06,.19-.04,.22l-.71,.27c-.22,.08-.34,.33-.26,.56l.23,.61c.08,.22,.33,.34,.55,.25l1.67-.63s.09,0,.13,.02l3.5,2.89c.08,.06,.06,.19-.04,.23l-.7,.28c-.22,.09-.33,.34-.25,.56l.23,.62c.08,.23,.33,.33,.56,.24l1.66-.69s.09-.01,.13,.02l3.63,2.73c.08,.06,.06,.18-.03,.22l-.69,.3c-.22,.09-.32,.35-.23,.57l.25,.6c.09,.22,.35,.32,.57,.23l1.64-.71s.09-.01,.13,.02l3.63,2.73c.08,.06,.07,.19-.03,.23l-.68,.31c-.22,.1-.32,.35-.22,.57l.26,.61c.09,.22,.36,.31,.57,.21l1.14-.53-3.46,4.83,.11-1.23c.02-.24-.16-.44-.39-.47l-.65-.08c-.24-.03-.45,.14-.48,.38l-.08,.76c-.01,.1-.13,.15-.21,.09l-3.71-2.8s-.06-.07-.05-.12l.21-1.78c.03-.24-.14-.45-.38-.48l-.66-.08c-.24-.03-.46,.14-.49,.38l-.09,.75c-.01,.1-.13,.15-.21,.09l-3.71-2.8s-.06-.07-.05-.12l.24-1.76c.03-.23-.14-.45-.37-.49l-.65-.11c-.23-.04-.46,.12-.49,.36l-.11,.76c-.02,.1-.13,.15-.21,.08l-3.59-2.96s-.05-.08-.05-.12l.29-1.77c.04-.24-.12-.46-.36-.5l-.65-.11c-.24-.04-.46,.12-.5,.35l-.12,.75c-.02,.1-.14,.14-.21,.08l-3.59-2.96s-.05-.08-.05-.12l.09-.53,3.87-4.5,.51-.19Z" />
				<g>
					<path d="M65.44,245.74c1.24,1,2.43,2.05,3.7,3.01,.07,.05,.05,.15-.03,.18-3.38,.94-5.77,2.3-7.01,3.14l-4.2-3.47c1.4-.86,4-2.19,7.46-2.88,.03,0,.06,0,.09,.02Z" />
					<path d="M76.14,254.02c1.28,.94,2.51,1.94,3.83,2.83,.07,.05,.05,.15-.03,.18-3.34,1.1-5.67,2.55-6.86,3.45l-4.34-3.27c1.36-.92,3.9-2.37,7.32-3.21,.03,0,.06,0,.09,.02Z" />
					<path d="M60.22,241.43l3.68,3.03c.06,.05,.04,.16-.04,.18-3.38,.84-5.85,2.2-7.1,3.01l-2.57-2.12,2.88-3.35c.93-.3,1.95-.57,3.06-.78,.03,0,.06,0,.09,.02Z" />
					<path d="M75.97,262.61c-.58-.39-1.15-.8-1.71-1.23,.91-.64,2.35-1.53,4.28-2.36l-2.57,3.59Z" />
					<path d="M70.74,249.95l3.8,2.87c.07,.05,.05,.15-.03,.18-3.34,.99-5.74,2.46-6.96,3.33-1.43-1.11-2.92-2.14-4.31-3.31,.69-.44,1.66-1,2.91-1.55,1.25-.57,2.77-1.12,4.5-1.52,.03,0,.06,0,.09,.02Z" />
				</g>
				<g>
					<path d="M75.35,240.83c-1.23-.92-2.38-1.94-3.57-2.91-.02-.02-.04-.05-.04-.08,.04-3.5,.94-6.24,1.56-7.74l3.87,3.19c-.57,1.35-1.53,4.03-1.66,7.46,0,.08-.1,.13-.16,.08Z" />
					<path d="M85.82,248.68c-1.28-.86-2.47-1.83-3.71-2.74-.02-.02-.04-.05-.04-.08-.11-3.5,.66-6.28,1.21-7.81l4.01,3.03c-.51,1.37-1.35,4.1-1.32,7.52,0,.08-.09,.13-.16,.09Z" />
					<path d="M70.28,236.7l-3.55-2.93s-.04-.05-.04-.08c.03-1.1,.14-2.12,.31-3.05l2.88-3.35,2.37,1.95c-.6,1.34-1.57,3.95-1.8,7.39,0,.08-.1,.13-.16,.07Z" />
					<path d="M88.4,241.9c.51,.4,1.05,.78,1.59,1.14l-2.57,3.59c.17-2.02,.63-3.68,.98-4.73Z" />
					<path d="M80.57,244.77l-3.68-2.78s-.04-.05-.04-.08c-.03-1.73,.16-3.29,.42-4.59,.28-1.32,.65-2.4,.94-3.16,1.27,1.08,2.66,2.04,3.98,3.06-.54,1.37-1.39,4.02-1.47,7.47,0,.08-.09,.13-.16,.08Z" />
				</g>
			</g>
			<g>
				<path d="M45.38,215.93s.09,0,.13,.03l3.21,3.2c.07,.07,.04,.19-.06,.22l-.73,.2c-.23,.06-.37,.3-.31,.53l.17,.63c.06,.23,.3,.37,.53,.3l1.72-.47s.09,0,.13,.03l3.21,3.2c.07,.07,.04,.19-.06,.22l-.72,.21c-.23,.07-.36,.31-.3,.54l.17,.64c.06,.23,.3,.37,.53,.3l1.72-.53s.09,0,.13,.03l3.36,3.04c.07,.07,.05,.19-.05,.22l-.72,.23c-.23,.07-.35,.32-.28,.54l.2,.62c.07,.23,.31,.35,.54,.28l1.69-.56s.09,0,.13,.03l3.36,3.04c.07,.07,.05,.19-.05,.22l-.71,.24c-.22,.08-.35,.32-.27,.55l.2,.63c.08,.23,.31,.35,.54,.27l1.18-.43-3.87,4.5,.22-1.22c.04-.23-.11-.47-.34-.52l-.64-.14c-.23-.05-.46,.1-.51,.34l-.15,.75c-.02,.1-.14,.14-.21,.07l-3.45-3.12s-.05-.08-.04-.13l.37-1.75c.05-.23-.1-.47-.33-.52l-.65-.14c-.24-.05-.47,.1-.52,.33l-.16,.74c-.02,.1-.14,.14-.22,.07l-3.45-3.12s-.05-.08-.04-.12l.4-1.73c.06-.24-.08-.47-.31-.54l-.63-.17c-.23-.06-.47,.08-.52,.31l-.18,.74c-.02,.1-.15,.13-.22,.06l-3.3-3.29s-.05-.08-.04-.13l.45-1.73c.06-.23-.08-.47-.31-.53l-.64-.17c-.23-.06-.47,.07-.53,.3l-.19,.73c-.03,.1-.15,.13-.22,.06l-3.29-3.29s-.05-.08-.03-.13l.14-.52,4.29-4.1,.53-.14Z" />
				<g>
					<path d="M45.22,227.73c1.13,1.12,2.24,2.26,3.4,3.36,.06,.06,.03,.16-.05,.17-3.43,.59-5.96,1.73-7.27,2.45l-3.87-3.86c1.47-.72,4.2-1.8,7.71-2.15,.03,0,.06,0,.08,.03Z" />
					<path d="M55.12,236.98c1.19,1.06,2.34,2.16,3.56,3.18,.06,.05,.04,.16-.04,.17-3.41,.77-5.87,2.02-7.14,2.81l-4.05-3.66c1.44-.79,4.11-2,7.59-2.53,.03,0,.06,0,.09,.03Z" />
					<path d="M40.42,222.94l3.38,3.37c.06,.06,.03,.16-.06,.17-3.45,.51-6.05,1.63-7.37,2.31l-2.36-2.36,3.19-3.05c.96-.21,2-.38,3.13-.48,.03,0,.06,0,.08,.03Z" />
					<path d="M54.19,245.54c-.55-.44-1.07-.92-1.59-1.4,.97-.57,2.47-1.29,4.47-1.95l-2.88,3.35Z" />
					<path d="M50.1,232.43l3.54,3.2c.06,.06,.03,.16-.05,.17-3.42,.68-5.96,1.92-7.24,2.67-1.34-1.24-2.73-2.41-3.99-3.72,1.45-.74,4.11-1.86,7.66-2.36,.03,0,.06,0,.09,.03Z" />
				</g>
				<g>
					<path d="M55.53,223.78c-1.11-1.06-2.18-2.16-3.27-3.24-.02-.02-.03-.05-.03-.08,.38-3.48,1.54-6.12,2.3-7.55l3.53,3.53c-.7,1.3-1.89,3.86-2.37,7.28-.01,.08-.11,.12-.17,.06Z" />
					<path d="M65.24,232.53c-1.17-.99-2.28-2.05-3.43-3.07-.02-.02-.03-.05-.03-.08,.21-3.49,1.24-6.19,1.92-7.66l3.71,3.36c-.63,1.33-1.71,3.95-2.01,7.38,0,.08-.1,.12-.17,.07Z" />
					<path d="M50.9,219.19l-3.26-3.25s-.03-.05-.03-.08c.13-1.09,.35-2.09,.6-3l3.19-3.05,2.16,2.15c-.73,1.28-1.95,3.77-2.5,7.17-.01,.08-.11,.12-.17,.06Z" />
					<path d="M68.42,226c.48,.43,.95,.88,1.46,1.28l-2.88,3.35c.34-1.99,.97-3.62,1.42-4.63Z" />
					<path d="M60.38,228.17l-3.41-3.09s-.03-.05-.03-.08c.23-3.43,1.36-6.13,2.1-7.59,1.15,1.2,2.43,2.28,3.66,3.41-.67,1.31-1.76,3.86-2.15,7.29,0,.08-.11,.12-.17,.07Z" />
				</g>
			</g>
			<g>
				<path d="M28.24,196.07s.09,.01,.12,.05l2.86,3.51c.06,.08,.02,.19-.08,.21l-.75,.12c-.24,.04-.4,.26-.36,.49l.1,.64c.04,.23,.26,.39,.49,.36l1.76-.29s.09,0,.12,.05l2.86,3.51c.06,.08,.02,.2-.08,.21l-.74,.13c-.23,.04-.39,.27-.35,.5l.11,.65c.04,.24,.26,.39,.49,.35l1.75-.34s.09,0,.12,.04l3.04,3.36c.07,.07,.03,.19-.07,.21l-.74,.16c-.23,.05-.38,.28-.34,.51l.13,.64c.05,.23,.28,.38,.51,.33l1.74-.38s.09,0,.12,.04l3.04,3.36c.07,.07,.03,.19-.07,.22l-.73,.17c-.23,.05-.38,.29-.33,.52l.14,.64c.05,.23,.28,.38,.51,.33l1.21-.3-4.29,4.1,.35-1.2c.07-.23-.06-.48-.28-.55l-.62-.2c-.23-.07-.47,.06-.54,.29l-.22,.73c-.03,.1-.15,.13-.22,.05l-3.13-3.45s-.04-.08-.03-.13l.54-1.71c.07-.23-.05-.47-.28-.55l-.63-.2c-.23-.07-.48,.05-.55,.28l-.23,.72c-.03,.1-.15,.12-.22,.05l-3.13-3.45s-.04-.08-.03-.13l.59-1.7c.08-.23-.03-.48-.26-.56l-.61-.23c-.22-.08-.47,.03-.55,.26l-.26,.72c-.03,.1-.16,.12-.22,.04l-2.94-3.61s-.04-.09-.02-.13l.63-1.68c.08-.22-.03-.48-.25-.56l-.62-.24c-.23-.09-.48,.02-.56,.25l-.27,.71c-.04,.09-.16,.11-.23,.03l-2.94-3.61s-.04-.09-.02-.13l.2-.5,4.7-3.63,.54-.08Z" />
				<g>
					<path d="M26.86,207.77l3.02,3.71c.05,.06,.02,.16-.07,.17-3.45,.21-6.1,1.1-7.47,1.68l-3.47-4.25c1.54-.56,4.37-1.35,7.9-1.34,.03,0,.06,.01,.08,.04Z" />
					<path d="M35.79,217.99l3.22,3.53c.06,.06,.02,.16-.06,.17-3.45,.41-6.04,1.42-7.38,2.07l-3.68-4.06c1.51-.64,4.29-1.58,7.81-1.75,.03,0,.06,.01,.08,.03Z" />
					<path d="M22.57,202.51l3.02,3.71c.05,.07,.01,.16-.07,.17-3.49,.15-6.19,.99-7.58,1.53l-2.11-2.6,3.5-2.7c.97-.1,2.04-.16,3.17-.14,.03,0,.06,.02,.08,.04Z" />
					<path d="M34.01,226.44c-.5-.51-.96-1.05-1.44-1.57,1.02-.46,2.6-1.06,4.63-1.49l-3.19,3.05Z" />
					<path d="M31.23,212.96l3.21,3.54c.06,.06,.02,.16-.06,.17-3.48,.33-6.13,1.31-7.49,1.93-1.22-1.35-2.47-2.69-3.6-4.13,1.53-.6,4.26-1.4,7.86-1.55,.03,0,.06,.01,.08,.04Z" />
				</g>
				<g>
					<path d="M37.51,204.92l-2.9-3.56s-.03-.06-.02-.08c.73-3.42,2.17-5.92,3.07-7.26l3.15,3.86c-.84,1.22-2.27,3.63-3.12,7-.02,.08-.12,.11-.17,.05Z" />
					<path d="M46.28,214.58l-3.09-3.4s-.03-.05-.02-.08c.55-3.45,1.85-6.03,2.68-7.42l3.35,3.69c-.77,1.26-2.08,3.75-2.74,7.15-.01,.08-.11,.11-.17,.06Z" />
					<path d="M33.39,199.86l-2.9-3.56s-.03-.05-.02-.08c.25-1.07,.56-2.04,.91-2.92l3.5-2.7,1.92,2.36c-.86,1.19-2.33,3.54-3.24,6.86-.02,.08-.12,.1-.17,.04Z" />
					<path d="M50.1,208.38c.44,.48,.86,.97,1.31,1.43l-3.19,3.05c.57-1.97,1.34-3.52,1.88-4.48Z" />
					<path d="M41.89,209.75l-3.09-3.4s-.03-.05-.02-.08c.57-3.38,2.01-5.98,2.88-7.34,1.02,1.31,2.16,2.52,3.27,3.75-.8,1.24-2.14,3.66-2.87,7.03-.02,.08-.12,.11-.17,.05Z" />
				</g>
			</g>
			<g>
				<path d="M13.36,174.47s.09,.02,.12,.06l2.46,3.8c.05,.08,0,.19-.1,.2l-.76,.04c-.24,.01-.42,.21-.41,.45l.03,.65c.01,.24,.21,.42,.45,.41l1.78-.1s.09,.02,.12,.06l2.46,3.8c.05,.08,0,.2-.1,.2l-.75,.05c-.24,.02-.42,.22-.41,.46l.03,.66c.01,.24,.21,.42,.45,.4l1.76-.14s.09,.02,.12,.05l2.67,3.66c.06,.08,0,.19-.09,.21l-.75,.08c-.24,.03-.41,.24-.39,.47l.07,.65c.02,.24,.24,.41,.47,.38l1.77-.2s.09,.01,.12,.05l2.67,3.66c.06,.08,0,.2-.09,.21l-.74,.09c-.23,.03-.4,.25-.38,.48l.07,.66c.03,.24,.24,.41,.47,.38l1.22-.16-4.7,3.63,.49-1.17c.09-.22,0-.48-.23-.58l-.6-.26c-.22-.1-.47,0-.57,.23l-.3,.71c-.04,.09-.16,.11-.22,.03l-2.75-3.76s-.03-.09-.01-.13l.72-1.64c.1-.22,0-.48-.22-.57l-.6-.27c-.22-.1-.48,0-.58,.22l-.31,.69c-.04,.09-.17,.1-.23,.02l-2.75-3.77s-.03-.09-.01-.13l.78-1.63c.11-.22,.02-.48-.19-.59l-.58-.3c-.21-.11-.47-.02-.58,.2l-.33,.69c-.04,.09-.17,.1-.23,.01l-2.53-3.91s-.03-.09,0-.13l.81-1.6c.11-.21,.02-.48-.19-.59l-.59-.3c-.21-.11-.48-.03-.59,.18l-.34,.67c-.05,.09-.17,.1-.23,0l-2.53-3.91s-.03-.09,0-.13l.25-.47,5.07-3.09,.54-.02Z" />
				<g>
					<path d="M10.71,185.95l2.6,4.01c.05,.07,0,.16-.09,.16-3.45-.17-6.18,.43-7.61,.85l-2.99-4.61c1.6-.39,4.5-.86,8-.46,.03,0,.06,.02,.08,.05Z" />
					<path d="M18.49,197.08l2.82,3.86c.05,.07,0,.16-.08,.16-3.45,.02-6.15,.77-7.55,1.27l-3.24-4.43c1.57-.48,4.44-1.11,7.96-.91,.03,0,.06,.02,.08,.04Z" />
					<path d="M7.02,180.25l2.6,4.02c.05,.07,0,.16-.09,.16-3.49-.23-6.26,.3-7.71,.69l-1.82-2.82,3.77-2.3c.98,0,2.04,.06,3.17,.2,.03,0,.06,.02,.08,.05Z" />
					<path d="M15.82,205.31l-1.26-1.72c1.06-.35,2.71-.78,4.76-.98l-3.5,2.7Z" />
					<path d="M14.49,191.6l2.82,3.87c.05,.07,0,.16-.08,.16-3.5-.04-6.24,.65-7.66,1.12l-1.62-2.22c-.54-.74-1.01-1.53-1.52-2.29,1.59-.44,4.38-.92,7.97-.68,.03,0,.06,.02,.08,.04Z" />
				</g>
				<g>
					<path d="M21.61,184.27l-2.49-3.85s-.02-.06-.01-.09c1.1-3.32,2.8-5.64,3.85-6.88l2.7,4.17c-.97,1.12-2.65,3.35-3.87,6.62-.03,.08-.13,.09-.18,.03Z" />
					<path d="M29.28,194.81l-2.7-3.71s-.02-.06-.02-.09c.92-3.37,2.48-5.79,3.46-7.08l2.93,4.02c-.9,1.18-2.46,3.5-3.5,6.82-.02,.08-.13,.1-.17,.03Z" />
					<path d="M18.06,178.8l-2.5-3.85s-.02-.06,0-.09c.36-1.03,.78-1.96,1.23-2.79l3.77-2.3,1.65,2.55c-.99,1.09-2.71,3.26-3.97,6.46-.03,.08-.13,.09-.18,.02Z" />
					<path d="M33.74,189.04l1.14,1.56-3.5,2.7c.79-1.91,1.71-3.36,2.36-4.26Z" />
					<path d="M25.44,189.54l-2.71-3.71s-.02-.06-.01-.08c.93-3.29,2.66-5.73,3.66-6.99,.46,.69,.88,1.41,1.37,2.07l1.47,2.01c-.93,1.14-2.52,3.4-3.61,6.67-.03,.08-.13,.1-.18,.03Z" />
				</g>
			</g>
		</svg>
	)
})

TiresFull.displayName = "TiresFull"
